import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { locale } from 'devextreme/localization';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CodeCombo, CodesFunc, CodeWarehouseEditModel } from 'src/app/services/codes.service';
import { ActualTask, LoginUser } from 'src/app/services/webapiclient.service';
import { PatientService } from '../patient.service';

@Component({
  selector: 'app-patient-tasks',
  templateUrl: './patient-tasks.component.html',
  styleUrls: ['./patient-tasks.component.scss']
})
export class PatientTasksComponent implements OnInit, OnDestroy {
  loading = false;
  dataSource: any;
  taskSource: any;
  taskSheetSource: any;
  exerciseSource: any;
  frequencySource: any;
  dateFormat: string;
  currentUser: LoginUser;
  error: string;
  message: string;
  taskRoots: string[] = ['/games/memory/classic',
    '/games/memory/semantic',
    '/games/memory/mathematical',
    '/games/word-guess',
    '/games/shape-recall',
    '/games/sdmt-test/sdmt',
    '/games/word-list-recall',
    '/games/color-recognition/foreColorRecognition',
    '/games/color-recognition/backColorRecognition',
    '/games/clock-test',
    '/games/tmt-test',
    '/games/shape-copying-test',
    '/games/letter-recognition',
    '/games/repeat-sentences',
    '/games/ica-test',
    '/games/repeat-numbers',
    '/games/count-down-test',
    '/games/picture-recognition',
    '/games/fluencia',
    '/games/delayed-recall',
    '/games/bvmt-r',
    '/games/dsst-test',
    '/games/sdmt-test/ldst',
    '/games/time-orientation',
    '/games/memorizing',
    '/games/spell-backwards',
    '/games/writing',
    '/games/naming-objects',
    '/games/construction',
    '/games/word-list-recall-ac',
    '/games/tmt-test-b'

  ];

  options: any[];
  option: string;


  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    private patientService: PatientService,
    private codesFunc: CodesFunc,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.options = [
      {
        "name": this.translation.translate('Task.Actual'),
        "value": "actual"
      },
      {
        "name": this.translation.translate('Task.All'),
        "value": "all"
      }
    ];
    this.option = this.options[0].value;
  }

  ngOnInit() {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });
    this.currentUser = this.authenticationService.currentUserValue;
    this.loadActualTasks(true);
  }
  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  //Aktuális vagy összes feladat megjelenítése
  onOptionChangedNew(index) {
    if (index == 1) {
      this.option = "all";
      this.loadActualTasks(false);
    } else {
      this.option = "actual";
      this.loadActualTasks(true);
    }
  }

  //Aktuális vagy összes feladat megjelenítése
  onOptionChanged(e) {
    this.option = e.value;
    if (this.option == "all") {
      this.loadActualTasks(false);
    } else {
      this.loadActualTasks(true);
    }
  }

  //Aktuális feladatok betöltése
  loadActualTasks(actual) {
    this.patientService.getActualTaskList(this.currentUser.id, actual).then(
      result => {
        this.dataSource = <ActualTask[]>result;
        this.loadCodesSources();
      }
    ).catch(err => {
      this.error = err;
    });

  }

  //Kódok betöltése
  loadCodesSources() {
    this.codesFunc.getCodesData('Games').then(
      result => {
        this.taskSource = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
    this.codesFunc.getCodesData('CognitiveTest').then(
      result => {
        this.taskSheetSource = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });

    this.codesFunc.getKbExercises().then(
      result => {
        this.exerciseSource = <CodeCombo[]>result;
      }
    ).catch(err => {
      this.error = err;
    });

    this.codesFunc.getCodesData('TaskFrequency').then(
      result => {
        this.frequencySource = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
  }

  startTask(id) {

    const item = this.dataSource.filter(f => f.id == id)[0];
    if (item.physicalExercise) {
      this.patientService.startActualTask(this.currentUser.id, id).then(
        result => {
          this.dataSource = <ActualTask[]>result;
          this.loadCodesSources();
          this.router.navigate(['/kb/kb-view', item.kbWebId]);
        }
      ).catch(err => {
        this.error = err;
      });
    } else if (item.taskSheet){
      this.router.navigate(['/games/task-sheet-execution', item.taskId, id]);

    } else {
      this.router.navigate([this.taskRoots[item.rootIndex], id]);
    }

    // this.patientService.startActualTask(this.currentUser.id, id).then(
    //   result => {
    //     this.loadActualTasks();
    //     this.loading = false;
    //     if (item.physicalExercise) {
    //       this.router.navigate(['/kb/kb-view', item.kbWebId]);
    //     } else {
    //       this.router.navigate([this.taskRoots[item.rootIndex], id]);
    //     }
    //   }
    // ).catch(err => {
    //   this.error = err;
    //   this.loading = false;
    // });

  }

  toggleTask(i) {
    this.dataSource[i].collapse = !this.dataSource[i].collapse;
  }

  onShown() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  }

  onHidden() {
    //this.employeeInfo = this.employee;
  }
}

