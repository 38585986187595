<div *ngIf="!needProfileData">
    <div class="container">
        <div class="m-3">
            <div class="d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                    <div class="set-icons-sm mb-3 mb-lg-0 me-2">
                        <img src="../../../assets/images/set-provider-links.svg">
                    </div>
                    <h1 class="display-6 d-block m-0">{{ 'Project.ProviderLinks' | translate:locale.language }}</h1>
                </div>
            </div>
            <hr>

            <!-- <div class="blockquote" *ngIf="!displayedProvider">
                {{ 'Project.NoProvider' | translate:locale.language }}
            </div> -->
            <div *ngIf="displayedProvider">
                <h3 class="mb-3">{{ 'Project.SelectedProvider' | translate:locale.language }}</h3>
                <ng-container *ngTemplateOutlet="providerDetails"></ng-container>
            </div>
            <div *ngIf="displayedProvider">
                <!-- <div *ngIf="!displayedDoctor">
                    <div class="blockquote">Nincs kiválasztott szakorvos!</div>
                </div> -->
                <div *ngIf="displayedDoctor">
                    <h3 class="mb-3">{{ 'Project.SelectedDoctor' | translate:locale.language }}</h3>
                    <div class="d-md-flex  align-items-center">
                        <div class="d-flex flex-column p-4">
                            <div class="avatar-image-lg"
                                [ngStyle]="{backgroundImage: 'url(' + uploadUrl + displayedDoctor.avatarName + ')'}">
                            </div>
                        </div>
                        <div class="d-flex flex-column flex-fill">
                            <div class="row row-cols-1 g-3 my-3 w-50">
                                <div class="col">
                                    <div class="h4 fw-light">{{displayedDoctor.userName}}</div>
                                </div>
                                <div class="col">
                                    <button *ngIf="selectedDoctor" class="btn btn-primary w-100"
                                    (click)="onChangeDoctor()">{{ 'Project.ChangeDoctor' | translate:locale.language }}
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div *ngIf="needProfileData">
                {{ 'Project.PleaseFillProfile' | translate:locale.language }}
            </div>
            <!-- SZOLGÁLTATÓ LISTA -->
            <div *ngIf="!displayedProvider">
                <div class="d-md-flex justify-content-between align-items-center mb-3">
                    <h3 class="d-block">{{ 'Project.SelectProvider' | translate:locale.language }}</h3>
                    <!-- Szolgáltató felvétele gomb nem látszik, de lehet hogy szükség van rá ha admin szerepkörrel valaki fel akarja vinni -->
                    <!--  <div>
                    <button type="button" class="btn btn-success" (click)="clickNewAdmission()">
                        <i class="bi bi-plus"></i>{{ 'Project.NewAdmission' | translate:locale.language }}
                    </button>
                </div> -->
                </div>
                <div class="input-group w-100 mb-3">
                    <input type="search" [(ngModel)]="searchText" class="form-control p-2"
                        placeholder="{{'Project.ProviderSearch' | translate:locale.language }}" #searchInput>
                </div>

                <div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-3">
                    <div class="col"
                        *ngFor="let provider of providerList  | hcFilter: searchText: filteredCount; index as i">
                        <ng-container *ngIf="actualPageSize > i">
                            <div class="card h-100">
                                <div
                                    class="card-body d-flex flex-column align-items-center justify-content-between  position-relative">
                                    <span class="info-circle">
                                        <a href="javascript:void(0);" (click)="onInfoClick(provider)"><i
                                                class="bi bi-info-circle-fill"></i></a>
                                    </span>
                                    <div class="avatar-image-sm"
                                    [ngStyle]="{backgroundImage: 'url(' + uploadUrl + provider.avatarName + ')'}"></div>
         
                                    <div class="my-2 h4 fw-light text-center">
                                        {{provider.name}}
                                    </div>
                                    <div class="my-2 w-100">
                                        <button type="button" class="btn btn-primary w-100"
                                            (click)="onSelect(provider)">
                                            {{'Project.Select' | translate:locale.language }}</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="row g-3">
                    <div class="col-12 col-md-8 col-lg-6">
                        <button *ngIf="providerList && filteredCount.count > actualPageSize" type="button"
                            class="btn btn-secondary w-100" (click)="clickMore()">
                            {{ 'Project.ProviderCatalogueMore' | translate:locale.language }} <i
                                class="bi bi-three-dots"></i>
                        </button>
                    </div>
                </div>

            </div>

            <!-- SZAKORVOS LISTA -->
            <div *ngIf="displayedProvider && doctorSource && !displayedDoctor">
                <div class="d-md-flex justify-content-between align-items-center mb-3">
                    <h3 class="d-block">{{ 'Project.SelectNeurologist' | translate:locale.language }}</h3>
                </div>
                <div class="input-group w-100 mb-3">
                    <input type="search" [(ngModel)]="searchTextDoctor" class="form-control p-2"
                        placeholder="{{'Project.NeurologistSearch' | translate:locale.language }}" #searchInputDoctor>
                </div>

                <div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-3">
                    <div class="col" *ngFor="let doctor of doctorSource  | doctorFilter: searchTextDoctor: filteredCountDoctor; index as i">
                        <ng-container *ngIf="actualPageSizeDoctor > i">
                            <div class="card h-100">
                                <div
                                    class="card-body d-flex flex-column align-items-center justify-content-between  position-relative">
                                    <!-- <span class="info-circle">
                                    <a href="#"><i class="bi bi-info-circle-fill"></i></a>
                                </span> -->
                                    <div class="avatar-image-sm"
                                        [ngStyle]="{backgroundImage: 'url(' + uploadUrl + doctor.avatarName + ')'}">
                                    </div>

                                    <div class="my-2 h4 fw-light text-center">
                                        {{doctor.userName}}
                                    </div>
                                    <div class="my-2 w-100">
                                        <button type="button" class="btn btn-primary w-100"
                                            (click)="onSelectDoctor(doctor)">
                                            {{'Project.Select' | translate:locale.language }}</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="row g-3">
                    <div class="col-12 col-md-8 col-lg-6">
                        <button *ngIf="doctorSource && filteredCountDoctor.count > actualPageSizeDoctor" type="button"
                            class="btn btn-secondary w-100" (click)="clickMoreDoctor()">
                            {{ 'Project.NeurologistCatalogueMore' | translate:locale.language }} <i
                                class="bi bi-three-dots"></i>
                        </button>
                    </div>
                </div>

            </div>
            <div *ngIf="error" class="alert alert-danger my-3">{{error | translate:locale.language}}</div>

            <hr class="mb-4">
            <div *ngIf="!selectedProvider && displayedProvider || (!selectedDoctor && displayedDoctor)">
                <div class="text-center">
                    <span>{{ 'Core.AcceptStatement' | translate:locale.language }} </span>
                    <span>
                        <a href="javascript:void(0);" (click)="showStatement();">{{ 'Core.Statement' |
                            translate:locale.language }}</a>
                    </span>
                </div>
                <div class="d-flex justify-content-center my-3">
                    <button class="btn btn-primary w-25 me-3" (click)="onSave()">
                        {{ 'Core.EditSave' | translate:locale.language }}
                    </button>
                    <button class="btn btn-neutral w-25" (click)="onCancel()">
                        {{ 'Core.EditCancel' | translate:locale.language }}
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>
<ng-template #statementTmpl>
    <div class="modal-header">
        <h5 class="modal-title"> {{ 'Core.Statement' | translate:locale.language }} </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <div class="p-3">
            <p class="pb-4">
                Elfogadom, hogy a PreDEM rendszer felhasználói adatbázisában tárolt alábbi személyes adataim átadásra
                kerüljenek az általam választott szolgáltató, mint adatkezelő részére.
                A továbbított adatok köre: név, e-mail cím, telefonszám, születési idő, születéskori nem, legmagasabb
                iskolai végzettség és családi kórtörténet. Az adattovábbítás célja: demencia és
                Parkinson-kór korai detektálásához kutatási adatok gyűjtése a folyamatos figyelmet, koncentrációt és
                feldolgozási sebességet mérő tesztek végrehajtásával.
            </p>

            <p>
                By clicking the Redirect button the Customer accepts PreDEM at the following personal data stored in its
                user database to the service provider of my choice as data controller.
                Provided data: full name, e-mail address, telephone number, date of birth, sex at birth, highest level
                of education and family medical history.
                The purpose of data transmission: to collect research data for the early detection of dementia and
                Parkinson's disease by performing tests measuring continuous attention, concentration and processing
                speed.
            </p>
        </div>
    </div>
</ng-template>

<ng-template #providerDetails>
    <div class="mb-3">
        <div class="d-md-flex p-ifinmodal-3">
            <div class="p-4">
                <div class="avatar-image-lg" [ngStyle]="{backgroundImage: 'url(' + uploadUrl + provider.avatarName + ')'}"></div>
            </div>
            <div class="flex-grow-1">
                <div class="d-flex justify-content-between align-items-start">
                    <div class="d-flex flex-column flex-fill">
                        <div class="my-2 h3 fw-light">{{provider.name }}</div>
                        <h5>{{ 'Project.ProviderAddress' | translate:locale.language}}</h5>
                        <div>{{provider.postalCode}}, {{provider.city}}, {{provider.address}} |
                            <a href="{{provider.mapLink}}" target="_blank"><i class="bi bi-geo-alt-fill"></i> {{
                                'Project.MapLink' |
                                translate:locale.language }}</a>
                        </div>
                        <div>
                            <a href="{{provider.webUrl}}" target="_blank"><i class="bi bi-globe2"></i>
                                {{'Project.WebUrl' | translate:locale.language }}</a>
                        </div>
                        <div class="mb-2">
                            <a href="mailto:{{provider.email}}" target="_blank"><i class="bi bi-envelope-fill"></i>
                                {{provider.email}}</a>
                        </div>
                        <h5>{{ 'Project.OpeningHours' | translate:locale.language }}
                        </h5>
                        <div class="mb-2">{{provider.openingHours}}</div>
                        <h5>További információ</h5>
                        <span [ngStyle]="{'font-weight': (provider.needTermin) ? 'bold' : 'normal'}"
                            class="form-value">{{termin}}</span> <br>
                        <span [ngStyle]="{'font-weight': (provider.needReferral) ? 'bold' : 'normal'}"
                            class="form-value">{{referral}}</span>

                        <div class="row row-cols-1 row-cols-xl-2 g-3 my-3 w-50" *ngIf="selectedProvider">
                            <div class="col">
                                <button class="btn btn-primary w-100" (click)="onChangeProvider()">
                                    {{ 'Project.ChangeProvider' | translate:locale.language }}
                                </button>
                            </div>
                            <div class="col">
                                <button class="btn btn-secondary w-100" (click)="onDeleteProvider()">
                                    {{ 'Core.EditDelete' | translate:locale.language }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #providerDetailsModal>
    <div class="modal-header pb-0 border-bottom-0">
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>

    <div class="modal-body">
        <ng-container *ngTemplateOutlet="providerDetails"></ng-container>
    </div>

</ng-template>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loadingVisible"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" (onShown)="onShown()"
    (onHidden)="onHidden()">
</dx-load-panel>