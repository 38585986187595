import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { PatientService } from 'src/app/module-patient/patient.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BloodOxygen } from 'src/app/services/webapiclient.service';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { loadMessages, locale } from 'devextreme/localization';
import { CorrectDate } from 'src/app/utils';
import { BoolResult, StringResult, ThryveService } from 'src/app/module-thryve/thryve.service';

type NewType = Subscription;

@Component({
  selector: 'app-blood-oxygen-chart',
  templateUrl: './blood-oxygen-chart.component.html',
  styleUrls: ['./blood-oxygen-chart.component.scss']
})
export class BloodOxygenChartComponent implements OnInit, OnDestroy {
  @Input() inpPatientId: string;
  @Input() startDate: Date;
  @Input() endDate: Date;

  modalRef: BsModalRef;
  dataSource: BloodOxygen[];
  filteredDataSource: BloodOxygen[];
  private translSubs$: NewType;
  patientId: any;
  currentUser: any;
  showAll: boolean = true;
  showSpo2: boolean = true;
  showPulse: boolean = true;

  ifDoctor: string;


  dateTimeFormat: string;
  customDate: boolean = false;

  error: any;
  chartInstance: any;
  seriesCollection: any = [];

  thryveConnected = false;
  boolResult: BoolResult = {} as BoolResult;
  stringResult: StringResult = {} as StringResult;
  lastRefreshDate: string = null as string;
  loading: boolean = false;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private patientService: PatientService,
    private translation: L10nTranslationService,
    private thryveService: ThryveService,
    private datePipe: DatePipe,
    private modalService: BsModalService,
    private authenticationService: AuthenticationService
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

  }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateTimeFormat = this.translation.translate('Core.DateTimeFormat');
      });

    if (this.inpPatientId) {
      //Katalógusból hívjuk
      this.patientId = this.inpPatientId;
      this.ifDoctor = "if-doctor";
    }

    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      if (!this.patientId) this.patientId = this.currentUser.id;
      //Megnézzük, hogy van-e thryve kapcsolat
      //Ha van frissítünk
      this.thryveService.checkThryveConnection(this.patientId)
        .subscribe(
          (data: BoolResult) => {
            if (this.boolResult.error) {
              this.thryveConnected = false;
              this.loading= true;
              this.loadDataSource();
            } else {
              this.thryveConnected = data.result;
              this.refresh();
            }
          }
        );
    }

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.startDate) this.startDate = changes.startDate.currentValue;
    if (changes.endDate) this.endDate = changes.endDate.currentValue;
    if(this.patientId){
      this.loadDataSource();
    }
    // You can also use categoryId.previousValue and 
    // categoryId.firstChange for comparing old and new values

  }


  refresh(): void {
    this.loading = true;
    this.thryveService.refresh()
      .subscribe(
        (data: StringResult) => {
          if (this.stringResult.error) {
            this.lastRefreshDate = null;
            this.loading = false;
          } else {
            this.loadDataSource();
            this.stringResult.result = data.result;
            this.lastRefreshDate = this.stringResult.result;
          }
        }
      );
  }


  loadDataSource() {
    this.patientService.getBloodOxygenList(this.patientId, this.startDate.toDateString(), this.endDate.toDateString(), false).then(
      result => {
        this.dataSource = <BloodOxygen[]>result;
        this.setFilteredDataSource();
        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });

  }

  legendClick(e: any) {
    const series = e.target;
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
    this.setFilter(series.index, series.isVisible());
    this.setFilteredDataSource();
  }
  setFilter(index, visible) {
    switch (index) {
      case 0:
        this.showSpo2 = visible;
        break;
      case 1:
        this.showPulse = visible;
        break;
    }

  }
  
  setFilteredDataSource() {
    //minden mutatjuk
    this.filteredDataSource = this.dataSource;
  }
  //Szöveg az időpont tengelyhez
  customizeTimeText = (info: any) => {
    if (info.valueText == null) return;
    let ret: Date;
    ret = new Date(info.valueText);
    return this.datePipe.transform(ret, 'MM.dd. HH:mm');
  }

  onShowAll(e) {
    this.seriesCollection = this.chartInstance.getAllSeries();
    this.seriesCollection.forEach((serie, index) => {
      if (!e.value) {
        serie.hide();
      } else {
        serie.show();
      }
    });

    this.showAll = e.value;
    this.showSpo2 = e.value;
    this.showPulse = e.value;
    this.setFilteredDataSource();
  }

  onShowSpo2(e) {
    this.showSpo2 = e.value;
    this.setFilteredDataSource();
  }

  onShowPulse(e) {
    this.showPulse = e.value;
    this.setFilteredDataSource();
  }
  onTasksChanged(e) {
    this.loadDataSource();
    this.setFilteredDataSource();
  }
  onQuery() {
    this.loadDataSource();
  }

  initChart(e) {
    this.chartInstance = e.component;
  }
  closeModal() {
    this.modalRef.hide();
  }
  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

}
