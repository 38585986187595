<div class="container">

  <div class="display-6 d-block">{{ 'Subscription.subscription' | translate:locale.language }}</div>

  <dx-data-grid id="gridContainer" [dataSource]="dataSource" [remoteOperations]="true" [rowAlternationEnabled]="true"
    [showBorders]="true" [masterDetail]="{ enabled: true, template: 'detail' }">
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [visible]="true" [allowedPageSizes]="true" [showPageSizeSelector]="true" [showInfo]="true"
      [showNavigationButtons]="true">
    </dxo-pager>

    <dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="false" [allowAdding]="false" [useIcons]="false">
    </dxo-editing>
    <dxo-filter-row [visible]="true"> </dxo-filter-row>

    <dxi-column dataField="UserName" caption="{{ 'Core.Entity_UserUserName' | translate: locale.language }}"
      dataType="string">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="DisplayName" caption="{{ 'Core.Entity_UserName' | translate: locale.language }}"
      dataType="string">
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="Email" caption="{{ 'Core.Account_Register_Email' | translate: locale.language }}"
      dataType="string">
      <dxi-validation-rule type="required"></dxi-validation-rule>
      <dxi-validation-rule type="email"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="Role" caption="{{ 'Core.Role' | translate: locale.language }}" dataType="string">
      <dxo-lookup [dataSource]="rolesData" valueExpr="name" displayExpr="translation"></dxo-lookup>
      <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="ProviderId" caption="{{ 'Project.Provider' | translate: locale.language }}"
      dataType="string">
      <dxo-lookup [dataSource]="providersData" valueExpr="id" displayExpr="name"></dxo-lookup>
    </dxi-column>
    <dxi-column dataField="Active" caption="{{ 'Core.EditActive' | translate: locale.language }}" dataType="boolean">
    </dxi-column>
    <div *dxTemplate="let transaction of 'detail'">
      <!-- <div class="master-detail-caption">{{code.data.codeName}}</div> -->
      <dx-data-grid [dataSource]="getDetailsDS(transaction.key)" [showBorders]="true" [columnAutoWidth]="true"
        (onContentReady)="onContentReady_code($event)" (onInitNewRow)="onInitNewRow_detail($event,transaction.key)">

        <dxo-editing mode="popup" [allowUpdating]="false" [allowDeleting]="false" [allowAdding]="true"
          [useIcons]="true">
          <dxo-popup title="Új GOLD előfizetés" [showTitle]="true" [width]="500" [height]="260">
          </dxo-popup>
          <dxo-form>
            <dxi-item dataField="item" caption="{{ 'Subscription.subscription' | translate:locale.language }}"
              itemType="string"></dxi-item>
            <dxi-item dataField="month" caption="Hány hónapra adjuk" itemType="numeric" alignment="right"></dxi-item>
          </dxo-form>
        </dxo-editing>

        <dxo-filter-row [visible]="true">
        </dxo-filter-row>


        <dxi-column dataField="item" caption="{{ 'Subscription.subscription' | translate:locale.language }}"
          dataType="string">
        </dxi-column>
        <dxi-column dataField="eventDate" dataType="date" [allowEditing]="false"></dxi-column>
        <dxi-column dataField="price" dataType="numeric" alignment="right" [allowEditing]="false"></dxi-column>
        <dxi-column dataField="month" caption="Hány hónapra adjuk" dataType="numeric" alignment="right"
          [visible]="false">
          <dxi-validation-rule type="range" min="1" max="12" message="Hónapnak 1 és 12 között kell lenni"></dxi-validation-rule>  
        
        </dxi-column>
        <dxi-column dataField="currency" dataType="string" [allowEditing]="false"></dxi-column>
        <dxi-column dataField="expireDate" caption="{{ 'Core.Valid' | translate:locale.language }}" dataType="date"
          [allowEditing]="false"></dxi-column>
        <dxi-column dataField="transactionId" dataType="string" [allowEditing]="false"></dxi-column>

      </dx-data-grid>

    </div>

  </dx-data-grid>
</div>