import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { L10nTranslationService } from "angular-l10n";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CouponModel, Packages, SubscriptionEditModel, SubscriptionInfo } from "./subscription-models";
import { BoolResult } from "../module-thryve/thryve.service";
import { first } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  baseUri: string;

  constructor(private http: HttpClient,
    public translation: L10nTranslationService
  ) {
    if(window.location.href.includes('predemplatform')){
      this.baseUri = `${environment.apiUrl}`;
    }else{
      this.baseUri = `${environment.apiUrlKog}`;
    }
  
  }

  /**
   * Előfizetés.
   */
  subscription(): Observable<SubscriptionEditModel> {
    return this.http.get<SubscriptionEditModel>(this.baseUri + 'Subscription/Subscription/');
  }

  async getSubscription() {
    let model: SubscriptionEditModel = {} as SubscriptionEditModel;
    return new Promise((resolve, reject) => {
      this.subscription().pipe(first()).subscribe(
        (p) => {
          model = p;
        },
        (e) => {
          reject(e.message);
        },
        () => {
          resolve(model);
        }
      );
    });
  }

  isUserNameAvailable(userName: string): Observable<BoolResult> {
    return this.http.get<BoolResult>(this.baseUri + 'AccountFunc/IsUserNameAvailable?userName=' + encodeURIComponent(userName));
  }

  checkCouponCode(couponCode: string): Observable<BoolResult> {
    return this.http.get<BoolResult>(this.baseUri + 'Subscription/CheckCouponCode?couponCode=' + encodeURIComponent(couponCode));
  }

  postSubscription(data: SubscriptionEditModel): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };
    return this.http.post(this.baseUri + 'Subscription/SubscriptionPost', data, options);
  }

  addFreeGoldSubscription(value: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'Subscription/AddFreeGoldSubscription', JSON.stringify(value), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  useCoupon(data: CouponModel): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'Subscription/UseCoupon', data, { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  /**
   * Fizetés eredménye, amit a SimplePay visszadott.
   */
  getPaymentResult(r: string, s: string): Observable<SubscriptionEditModel> {
    return this.http.get<SubscriptionEditModel>(this.baseUri + 'Subscription/PaymentResult?r=' + encodeURIComponent(r) + '&s=' + encodeURIComponent(s));
  }

  /**
    * Előfizetési tranzakciók.
    */
  getTransactions(): Observable<SubscriptionInfo[]> {
    return this.http.get<SubscriptionInfo[]>(this.baseUri + 'Subscription/GetTransactions');
  }

  /**
    * Előfizetési tranzakciók (admin).
    */
  getTransactionsAdmin(userId: string): Observable<SubscriptionInfo[]> {
    return this.http.get<SubscriptionInfo[]>(this.baseUri + 'Subscription/GetTransactionsAdmin?userId=' + encodeURIComponent(userId));
  }

  extendSubscription(userId): Observable<BoolResult> {
    return this.http.get<BoolResult>(this.baseUri + 'Subscription/ExtendPlatinumSubscription?userId=' + encodeURIComponent(userId));
  }

  /**
    * Előfizetési csomagok.
    */
  getPackages(lang: string): Observable<Packages> {
    return this.http.get<Packages>(this.baseUri + 'Subscription/GetProducts?lang=' + encodeURIComponent(lang));
  }


  async extendPlatinumSubscription(userId) {
    let model: BoolResult = {} as BoolResult;
    return new Promise((resolve, reject) => {
      this.extendSubscription(userId).pipe(first()).subscribe(
        (p) => {
          model = p;
        },
        (e) => {
          reject(e.message);
        },
        () => {
          resolve(model);
        }
      );
    });
  }

  addSubscription(userId): Observable<BoolResult> {
    return this.http.get<BoolResult>(this.baseUri + 'Subscription/AddPlatinumSubscription?userId=' + encodeURIComponent(userId));
  }

  async addPlatinumSubscription(userId) {
    let model: BoolResult = {} as BoolResult;
    return new Promise((resolve, reject) => {
      this.addSubscription(userId).pipe(first()).subscribe(
        (p) => {
          model = p;
        },
        (e) => {
          reject(e.message);
        },
        () => {
          resolve(model);
        }
      );
    });
  }

}  