<!-- <ul class="nav nav-underline">
    <li class="nav-item">
        <a class="nav-link active" aria-current="page" href="#"><span>Aktuális feladatok</span><span> (Xdb)</span></a>
    </li>
    <li class="nav-item">
        <a class="nav-link" href="#"><span>Lejárt feladatok</span><span> (Xdb)</span></a>
    </li>
    <li class="nav-item">
        <a class="nav-link" href="#"><span>Jövőbeli feladatok</span><span> (Xdb)</span></a>
    </li>
</ul>
<hr>
Aktuális feladatok
<div class="card rounded mb-2">
    <div class="card-body d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <button class="btn btn-primary btn-rounded me-3"><i class="bi bi-play-fill"></i> Kezdés</button>
            <span class="h5 m-0">SDMT teszt</span>
        </div>
        <div class="d-flex align-items-center">
            <span class="h4 m-0 text-danger"><i class="bi bi-bell"></i></span>
            <span class="h4 m-0 text-danger"><i class="bi bi-bell"></i></span>
            <span class="d-none d-md-inline mx-2 text-danger">Esedékes: 2022.02.22.</span>

        </div>
    </div>
</div>
<div class="card rounded mb-2">
    <div class="card-body d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <button class="btn btn-primary btn-rounded me-3"><i class="bi bi-play-fill"></i> Kezdés</button>
            <span class="h5 m-0">Mondat felismerő</span>
        </div>
        <div class="d-flex align-items-center">
            <span class="d-none d-md-inline mx-2">Esedékes: 2023.03.01.</span>

        </div>
    </div>
</div>
Lejárt feladatok
<div class="card rounded mb-2">
    <div class="card-body d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <button class="btn btn-light btn-rounded me-3"><i class="bi bi-play-fill"></i> Pótlás</button>
            <h5 class="m-0 me-2">SDMT teszt</h5>
            <span class="badge bg-secondary text-white pill rounded">2022.01.11. - 2022.05.30</span>
        </div>
        <div class="d-flex align-items-center">
            <span class="d-none d-md-inline mx-2 text-danger">Elmulasztva: 3 alkalommal</span>

        </div>
    </div>
</div>
<div class="card rounded mb-2">
    <div class="card-body d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <button class="btn btn-light btn-rounded me-3"><i class="bi bi-play-fill"></i> Pótlás</button>
            <h5 class="m-0 me-2">Alakzat felismerő</h5>
            <span class="badge bg-secondary text-white pill rounded">2022.01.11. - 2022.05.30</span>
        </div>
        <div class="d-flex align-items-center">
            <span class="d-none d-md-inline mx-2 text-danger">Elmulasztva:10 alkalommal</span>

        </div>
    </div>
</div>
Teljesített lejárt feladatok
<div class="card rounded mb-2 minH57">
    <div class="card-body d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <h3 class=" text-success m-0 me-3 rounded-pill"><i class="bi bi-check-lg"></i></h3>
            <h5 class="m-0 me-2">Klasszikus Memória </h5>
            <span class="badge bg-success text-white pill rounded">2022.01.11. - 2022.05.30</span>
        </div>
        <div class="d-flex align-items-center">
            <span class="d-none d-md-inline mx-2 text-success">Teljesítve</span>

        </div>
    </div>
</div>
Jövőbeli feladatok
<div class="card rounded mb-2">
    <div class="card-header">
        <span class="h5 m-0 ms-2">SDMT teszt</span>
    </div>
    <div class="card-body d-flex justify-content-between">
        <div class="d-flex align-items-center">
            <span class="badge bg-secondary mx-2">2023.03.22.</span>
            <span class="badge bg-secondary mx-2">2023.04.22.</span>
            <span class="badge bg-secondary mx-2">2023.05.22.</span>
            <span class="badge bg-secondary mx-2">2023.06.22.</span>
        </div>
    </div>
</div> -->

<div *ngIf="dataSource && dataSource.length!=0">
    <div class="d-md-flex justify-content-between align-items-center">
        <h1 class="display-6 d-block">{{ 'Task.MyTasks' | translate:locale.language }}</h1>
        <div class="me-2">
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a [ngClass]="option == 'all' ? 'nav-link' : 'nav-link active'" (click)="onOptionChangedNew(0)"
                        aria-current="page" href="javascript:void(0)"><span>{{ 'Task.Actual' | translate:locale.language
                            }}</span></a>
                </li>
                <li class="nav-item">
                    <a [ngClass]="option != 'all' ? 'nav-link' : 'nav-link active'" (click)="onOptionChangedNew(1)"
                        href="javascript:void(0)"><span>{{ 'Task.All' | translate:locale.language }}</span></a>
                </li>
            </ul>
        </div>
    </div>
    <hr>

    <div *ngFor="let item of dataSource; index as i" class="card rounded mb-2">
        <!-- Fizikai gyakorlatok -->
        <div *ngIf="item.physicalExercise" class="card-body">
                <div class="d-flex justify-content-between align-items-start w-100">
                    <div>
                        <button *ngIf="item.startable" (click)="startTask(item.id)" class="btn btn-primary btn-rounded"><i class="bi bi-play-fill"></i>{{ 'Task.Start' | translate:locale.language }}</button>
                    </div>
                    <div class="d-flex flex-column w-100">
                        <div class="d-md-flex justify-content-between align-items-center mx-2">
                            <div class="h5 m-0" style="line-height: 35px" *ngIf="item.id !=0">{{ item.taskId | codeTranslate:exerciseSource}}</div>
                            <div class="h5 m-0 text-neutral" style="line-height: 35px" *ngIf="item.id ==0">{{ item.taskId|codeTranslate:exerciseSource}}</div>
                            <div *ngIf="item.id !=0 " [ngClass]="item.expired ? 'text-md-end text-danger' : 'text-md-end text-muted'"> {{
                                'Task.DueDate' | translate:locale.language }}: {{
                                item.dueDate |
                                date: dateFormat }}
                                <i *ngIf="item.expired" class="text-nowrap"> {{ 'Task.NotCompleted' | translate:locale.language }}</i>
                            </div>
                        </div>
                        <div class="collapse text-start text-md-end mx-2" id="c{{item.id}}">
                            <span class="me-2 mb-2 badge bg-secondary fs-6">
                                <i class="bi bi-arrow-repeat"></i>
                                {{ 'Task.Frequency' | translate:locale.language }}: {{ item.frequencyId |
                                    codeTranslate:frequencySource }}
                            </span>
                            <span class="mb-2 badge bg-secondary fs-6">
                                <i class="bi bi-calendar-check"></i>
                                {{ 'Task.End' | translate:locale.language }}: {{ item.endDate | date: dateFormat }}
                            </span>
                        </div>
                    
                    </div>
                    <div>
                        <button class="ms-3 btn btn-light circle" data-bs-toggle="collapse" [attr.data-bs-target]="'#c'+item.id" aria-expanded="false" aria-controls="physicalExerciseCollapse"><i class="bi bi-three-dots"></i></button>
                    </div>
                </div> 
            </div>
        <!-- Feladatlapok -->
        <div *ngIf="item.taskSheet" class="card-body">
            <div class="d-flex justify-content-between align-items-start w-100">
                <div>
                    <button *ngIf="item.startable" (click)="startTask(item.id)" class="btn btn-primary btn-rounded"><i class="bi bi-play-fill"></i>{{ 'Task.Start' | translate:locale.language }}</button>
                </div>
                <div class="d-flex flex-column w-100">
                    <div class="d-md-flex justify-content-between align-items-center mx-2">
                        <div class="h5 m-0" style="line-height: 35px" *ngIf="item.id !=0">{{ item.taskId | codeTranslate:taskSheetSource}}</div>
                        <div class="h5 m-0 text-neutral" style="line-height: 35px" *ngIf="item.id ==0">{{ item.taskId |codeTranslate:taskSheetSource}}</div>
                        <div *ngIf="item.id !=0 " [ngClass]="item.expired ? 'text-md-end text-danger' : 'text-md-end text-muted'"> {{
                            'Task.DueDate' | translate:locale.language }}: {{
                            item.dueDate |
                            date: dateFormat }}
                            <i *ngIf="item.expired" class="text-nowrap"> {{ 'Task.NotCompleted' | translate:locale.language }}</i>
                        
                        </div>
                    </div>
                    <div class="collapse text-start text-md-end mx-2" id="c{{item.id}}">
                        <span class="me-2 mb-2 badge bg-secondary fs-6">
                            <i class="bi bi-arrow-repeat"></i>
                            {{ 'Task.Frequency' | translate:locale.language }}: {{ item.frequencyId |
                                codeTranslate:frequencySource }}
                        </span>
                        <span class="mb-2 badge bg-secondary fs-6">
                            <i class="bi bi-calendar-check"></i>
                            {{ 'Task.End' | translate:locale.language }}: {{ item.endDate | date: dateFormat }}
                        </span>
                    </div>
                   
                </div>
                <div>
                    <button  class="ms-3 btn btn-light circle"  data-bs-toggle="collapse" [attr.data-bs-target]="'#c'+item.id" aria-expanded="false" aria-controls="testSheetCollapse"><i class="bi bi-three-dots"></i></button>
                 </div>
            </div>          
        </div>
        <!-- Tesztek, játékok -->
        <div *ngIf="!item.physicalExercise && !item.taskSheet" class="card-body">
            <div class="d-flex justify-content-between align-items-start w-100">
                <div>
                    <button *ngIf="item.startable" (click)="startTask(item.id)" class="btn btn-primary btn-rounded"><i class="bi bi-play-fill"></i>{{ 'Task.Start' | translate:locale.language }}</button>
                </div>
                <div class="d-flex flex-column w-100">
                    <div class="d-md-flex justify-content-between align-items-center mx-2">
                        <div class="h5 m-0" style="line-height: 35px" *ngIf="item.id !=0">{{ item.taskId | codeTranslate:taskSource}}</div>
                        <div class="h5 m-0 text-neutral" style="line-height: 35px" *ngIf="item.id ==0">{{ item.taskId |codeTranslate:taskSource}}</div>
                        <div *ngIf="item.id !=0 " [ngClass]="item.expired ? 'text-md-end text-danger' : 'text-md-end text-muted'"> {{
                            'Task.DueDate' | translate:locale.language }}: {{
                            item.dueDate |
                            date: dateFormat }}
                            <i *ngIf="item.expired" class="text-nowrap"> {{ 'Task.NotCompleted' | translate:locale.language }}</i>
                        </div>
                    </div>
                    <div class="collapse text-start text-md-end mx-2" id="c{{item.id}}">
                        <span class="me-2 mb-2 badge bg-secondary fs-6">
                            <i class="bi bi-arrow-repeat"></i>
                            {{ 'Task.Frequency' | translate:locale.language }}: {{ item.frequencyId |
                            codeTranslate:frequencySource }}
                        </span>
                        <span class="mb-2 badge bg-secondary fs-6">
                            <i class="bi bi-calendar-check"></i>
                            {{ 'Task.End' | translate:locale.language }}: {{ item.endDate | date: dateFormat }}
                        </span>
                    </div>
                   
                </div>
                <div>
                    <button  class="ms-3 btn btn-light circle"  data-bs-toggle="collapse" [attr.data-bs-target]="'#c'+item.id" aria-expanded="false" aria-controls="singleTestCollapse"><i class="bi bi-three-dots"></i></button>
                 </div>
            </div>
        </div>
    </div>

    <!-- <div class="d-md-flex justify-content-between align-items-center">
        <h1 class="display-6 d-block">{{ 'Task.MyTasks' | translate:locale.language }}</h1>
        <div class="me-2">
            <dx-radio-group [items]="options" [value]="option" displayExpr="name" valueExpr="value" layout="horizontal"
                (onValueChanged)="onOptionChanged($event)">
            </dx-radio-group>
        </div>

    </div>
    <hr>

    <div class="card mb-3" *ngFor="let item of dataSource; index as i">
        <div class="card-body">
            <div class="d-md-flex justify-content-between align-items-center">
                <h5 class="card-title mb-1" *ngIf="item.physicalExercise">
                    <span *ngIf="item.id !=0">{{ item.taskId | codeTranslate:exerciseSource}}</span>
                    <span *ngIf="item.id ==0" class="text-neutral">{{ item.taskId |
                        codeTranslate:exerciseSource}}</span> <br>
                    <small *ngIf="item.id !=0" [ngClass]="item.expired ? ' text-danger' : 'text-muted'"> {{
                        'Task.DueDate' | translate:locale.language }}: {{
                        item.dueDate |
                        date: dateFormat }}
                        <i *ngIf="item.expired"> {{ 'Task.NotCompleted' | translate:locale.language }}</i></small>
                </h5>

                <h5 class="card-title mb-1" *ngIf="item.taskSheet">
                    <span *ngIf="item.id !=0">{{ item.taskId | codeTranslate:taskSheetSource}}</span>
                    <span *ngIf="item.id ==0" class="text-neutral">{{ item.taskId |
                        codeTranslate:taskSheetSource}}</span>
                    <br>
                    <small *ngIf="item.id !=0 " [ngClass]="item.expired ? ' text-danger' : 'text-muted'"> {{
                        'Task.DueDate' | translate:locale.language }}: {{
                        item.dueDate |
                        date: dateFormat }}
                        <i *ngIf="item.expired"> {{ 'Task.NotCompleted' | translate:locale.language }}</i>
                    </small>
                </h5>
                <h5 class="card-title mb-1" *ngIf="!item.physicalExercise && !item.taskSheet">
                    <span *ngIf="item.id !=0">{{ item.taskId | codeTranslate:taskSource}}</span>
                    <span *ngIf="item.id ==0" class="text-neutral">{{ item.taskId | codeTranslate:taskSource}}</span>
                    <br>
                    <small *ngIf="item.id !=0 " [ngClass]="item.expired ? ' text-danger' : 'text-muted'"> {{
                        'Task.DueDate' | translate:locale.language }}: {{
                        item.dueDate |
                        date: dateFormat }}
                        <i *ngIf="item.expired"> {{ 'Task.NotCompleted' | translate:locale.language }}</i>
                    </small>
                </h5>
                <div>
                    <button class="btn btn-flat btn-primary" data-bs-toggle="collapse" (click)="toggleTask(i)"
                        role="button" aria-expanded="false" aria-controls="collapseOne">
                        <i class="bi bi-info-circle-fill"></i> {{ 'Core.Details' | translate:locale.language }}
                    </button>
                    <button class="btn btn-primary ms-3" *ngIf="item.startable" (click)="startTask(item.id)">
                        <i class="bi bi-play"></i> {{ 'Task.Start' | translate:locale.language }}
                    </button>
                </div>
            </div>
            <div id="taskDone">
                <div id="taskDone{{i}}" [ngClass]="{'collapse': !item.collapse}" aria-labelledby="headingOne"
                    data-bs-parent="#taskDone">
                    <span class="me-2 my-3 badge bg-secondary">
                        <i class="bi bi-arrow-repeat"></i>
                        {{ 'Task.Frequency' | translate:locale.language }}: {{ item.frequencyId |
                        codeTranslate:frequencySource }}
                    </span>
                    <span class="me-2 my-3 badge bg-secondary">
                        <i class="bi bi-calendar-check"></i>
                        {{ 'Task.End' | translate:locale.language }}: {{ item.endDate | date: dateFormat }}
                    </span>
                    <ul class="list-group">
                        <li class="list-group-item">
                            <h5>{{ 'Task.Achievements' | translate:locale.language }}</h5>
                        </li>
                        <li class="list-group-item" *ngFor="let p of item.performedTasks; index as j">
                            {{ 'Task.Completion' | translate:locale.language }}: {{ p.startDate | date: dateFormat}}
                            ({{ 'Task.DueDate' | translate:locale.language }}: {{ p.dueDate | date:
                            dateFormat}})
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div> -->
</div>

<div *ngIf="dataSource && dataSource.length==0">
    <div class="d-md-flex justify-content-between align-items-center">
        <h1 class="display-6 d-block">{{ 'Task.MyTasks' | translate:locale.language }}</h1>
        <div class="me-2">
            <ul class="nav nav-pills">
                <li class="nav-item">
                    <a [ngClass]="option == 'all' ? 'nav-link' : 'nav-link active'" (click)="onOptionChangedNew(0)"
                        aria-current="page" href="javascript:void(0)"><span>{{ 'Task.Actual' | translate:locale.language
                            }}</span></a>
                </li>
                <li class="nav-item">
                    <a [ngClass]="option != 'all' ? 'nav-link' : 'nav-link active'" (click)="onOptionChangedNew(1)"
                        href="javascript:void(0)"><span>{{ 'Task.All' | translate:locale.language }}</span></a>
                </li>
            </ul>
        </div>
    </div>
    <hr>
    <div class="blockquote mb-3">
        {{ 'Task.NoTasks' | translate:locale.language }}
    </div>
</div>

<ng-template #performedTasks let-item='item'>
    <tr>
        <table class="table table-bordered text-center bg-white">
            <thead>
                <tr>
                    <th>{{ 'Task.Achievements' | translate:locale.language }}</th>
                    <th>{{ 'Core.Date' | translate:locale.language }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let p of item.performedTasks; index as j">
                    <td style="width: 90px;"></td>
                    <td>{{ p.startDate | date: dateFormat}}</td>
                </tr>
            </tbody>
        </table>
    </tr>
</ng-template>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" (onShown)="onShown()"
    (onHidden)="onHidden()">
</dx-load-panel>