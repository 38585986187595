import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

export function getBaseUrl() {
   // const url = `${window.location.protocol}//${window.location.hostname}`;
   // return document.getElementsByTagName('base')[0].href;
   const url = `https://${window.location.hostname}`;
   return url;
}
// getBaseUrlWithoutPort
export function getBasePort() {
   // https://stackoverflow.com/questions/49106569/angular-cli-get-current-port-used
   const port = `${window.location.port}`;
   return port;
}

export function getHost() {
   // https://stackoverflow.com/questions/49106569/angular-cli-get-current-port-used
   const port = `${window.location.hostname}`;
   return port;
}

const providers = [
   { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
   { provide: 'BASE_URL_PORT', useFactory: getBasePort, deps: []},
   { provide: 'BASE_HOST', useFactory: getHost, deps: []  }
];

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.error(err));
