import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { PatientService } from '../patient.service';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { loadMessages, locale } from 'devextreme/localization';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoginUser, MsData } from 'src/app/services/webapiclient.service';
import { ActivatedRoute } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmDialogService } from 'src/app/confirm-dialog/confirm-dialog-service';
import { Subscription } from 'rxjs';
import { PoolDevicesViewModel, SmartDevices, StringResult, ThryveDailyData, ThryveResult, ThryveService } from 'src/app/module-thryve/thryve.service';
import { CodesFunc, CodeWarehouseEditModel } from 'src/app/services/codes.service';
import { first } from 'rxjs/operators';

/**
  * SmartSet eszközből átvett mérések
  * Vérnyomás, vércukor, testsúly, véroxygén
  * Orvos tudja indítani a katalógusból kiválasztva a pácienst
  */

@Component({
  selector: 'app-smart-set',
  templateUrl: './smart-set.component.html',
  styleUrls: ['./smart-set.component.scss']
})
export class SmartSetComponent implements OnInit, OnDestroy {
  @Input() inpPatientId: string;

  @ViewChild('lastMeasurements', { static: true }) lastMeasurements: TemplateRef<any>;

  modalRef: BsModalRef;
  stylingMode = "filled"
  dataSource: any;
  currentUser: LoginUser;
  error: any;
  addMode: boolean = false;
  editItem: any;
  maxDate: Date = new Date();
  patientId: any;
  measureTypes: any;

  loading: boolean = false;
  needRefresh: boolean = false;

  lastMeasurement: ThryveDailyData[] = {} as ThryveDailyData[];
  codeData: CodeWarehouseEditModel[] = {} as CodeWarehouseEditModel[];
  smartDevices: SmartDevices[] = {} as SmartDevices[];
  lastRefreshDate: any;

  poolDevices: any;
  showPoolDevices: boolean = false;

  selectAll: boolean = true;

  //technikai user, a hibás méréseket erre pakoljuk
  mySignalsUser: string = 'b4810697-3b3f-4800-a33a-7e271acf8cea';

  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private patientService: PatientService,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private thryveService: ThryveService,
    private codesFunc: CodesFunc,

    private route: ActivatedRoute,
    private modalService: BsModalService,
    private confirmDialogService: ConfirmDialogService,
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });
  }


  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });
    this.maxDate.setDate(this.maxDate.getDate() + 1);
    if (this.inpPatientId) this.patientId = this.inpPatientId;
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      if (!this.patientId) this.patientId = this.currentUser.id;
      this.getSmartDevicesData();
    }
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  loadLastMeasurements() {
    this.loading = true;
    this.thryveService.getLastMeasurement(this.currentUser.id)
      .subscribe(
        (data: ThryveResult) => {
          if (!data.error) {
            this.lastMeasurement = data.thryveDataList;
            this.lastMeasurement.forEach((currentValue, index) => {
              currentValue.selected = true;
            });
          }
          this.loading = false;
        }
      );
  }

  getSmartDevicesData() {
    return new Promise((resolve, reject) => {
      this.thryveService.getSmartDevices().pipe(first()).subscribe(
        (p) => {
          this.smartDevices = p;
        },
        (e) => {
          //this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.smartDevices);
        }
      );
    });
  }

  clickEdit(index) {

    this.editItem = <MsData>{};
    this.editItem = this.simpleClone(this.dataSource[index]);

    //this.selectedIndex = index; 
    //ez akkor kell, ha dátum ellenőrzés van a formon
    //át kell alakítani dátumra, másképp hibára fut az ellenőrzés
    this.editItem.measurementDate = new Date(this.editItem.measurementDate);
    //this.modalRef = this.modalService.show(this.lastMeasurements, {class: 'modal-lg', backdrop: 'static', keyboard: false});
  }

  clickNew() {
    this.loading = true;
    this.selectAll = true;
    this.loadLastMeasurements();
    this.modalRef = this.modalService.show(this.lastMeasurements, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  refresh(): void {
    this.loading = true;
    this.thryveService.refresh()
      .subscribe(
        (data: StringResult) => {
          if (data.error) {
            this.lastRefreshDate = null;
          } else {
            this.lastRefreshDate = data.result;
            this.loadLastMeasurements();
          }
        }
      );
  }

  clickSelectAll(e) {
    this.lastMeasurement.forEach((currentValue, index) => {
      currentValue.selected = e.value;
    });
  }

  //Törlés igaziból nincs, csak hozzárendeljük a mérést egy fiktív felhasználóhoz
  //így legközelebb már nem jön fel.
  clickDelete(id, index) {
    this.error = "";
    const that = this;
    this.confirmDialogService.confirmThis(this.translation.translate('Core.ConfirmDeleting'), function () {
      that.loading = true;
      that.thryveService.saveSelectedMeasurements(that.mySignalsUser, JSON.stringify(that.lastMeasurement.filter(x => x.id == id))).then(
        result => {
          if (result == "OK") {
            that.lastMeasurement.splice(index, 1);
            that.error = "";
            that.loading = false;
          } else {
            that.error = result;
            that.loading = false;
          }
        }
      ).catch(err => {
        that.error = err;
        that.loading = false;
      });
    }, function () {
      //alert("No clicked");  
    })
  }

  closeModal() {
    //főoldalon lévő thryve adatok frissítéséhez kell
    this.needRefresh = !this.needRefresh;
    this.modalRef.hide();
    if (this.addMode) {
      //Új bevitelt szakítunk meg
      this.addMode = false;
    }
  }
  clickSave() {
    this.error = "";
    this.loading = true;
    //csak a kiválasztottakat mentjük
    this.lastMeasurement = this.lastMeasurement.filter(function (item) {
      return item.selected;
    });
    this.thryveService.saveSelectedMeasurements(this.patientId, JSON.stringify(this.lastMeasurement)).then(
      result => {
        if (result == "OK") {
          notify({
            message: "Sikeres mentés!",
            position: {
              my: "center top",
              at: "center top"
            }
          }, "success", 3000);
          this.loading = false;
          this.closeModal();
        } else {
          this.error = result;
          this.loading = false;
        }
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });
  }

  simpleClone(obj: any) {
    return Object.assign({}, obj);
  }
  onShown() {
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  }

  onHidden() {
    //this.employeeInfo = this.employee;
  }

  //Páciensnek kiadható, és a már nála lévő eszközök listája
  devices() {
    this.loading = true;
    this.thryveService.getPoolDevicesList(this.currentUser.id, this.patientId)
      .subscribe(
        (data: PoolDevicesViewModel[]) => {
          this.poolDevices = data;
          this.showPoolDevices = true;
          this.loading = false;
        }
      );

  }
  //Eszköz kiadás
  issueDevice(device) {
    this.error = "";
    this.loading = true;
    this.thryveService.issueSelectedDevice(this.currentUser.id, this.patientId, JSON.stringify(device)).then(
      result => {
        if (result == "OK") {
          notify({
            message: "Sikeres mentés!",
            position: {
              my: "center top",
              at: "center top"
            }
          }, "success", 3000);
          this.loading = false;
          this.devices();
        } else {
          this.error = result;
          this.loading = false;
        }
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });
  }

  //Eszköz visszaadása
  takeBackDevice(device) {
    this.error = "";
    this.loading = true;
    this.thryveService.takeBackSelectedDevice(this.currentUser.id, this.patientId, JSON.stringify(device)).then(
      result => {
        if (result == "OK") {
          notify({
            message: "Sikeres mentés!",
            position: {
              my: "center top",
              at: "center top"
            }
          }, "success", 3000);
          this.loading = false;
          this.devices();
        } else {
          this.error = result;
          this.loading = false;
        }
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });
  }

  backToMeasurements() {
    this.showPoolDevices = false;
  }
}
