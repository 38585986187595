import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';

import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { formatMessage, loadMessages, locale } from 'devextreme/localization';
import CustomStore from 'devextreme/data/custom_store';
import { ThryveService, Spo2ViewModel } from '../thryve.service';
import { AuthenticationService } from '../../services/authentication.service';
import { LoginUser } from '../../services/webapiclient.service';
import { Subscription } from 'rxjs';

/**
 * Thryve spo2 adatok megjelítése. 
 * Tesztelési célra van.
 */

@Component({
  selector: 'app-thryve-spo2',
  templateUrl: './thryve-spo2.component.html',
  styleUrls: ['./thryve-spo2.component.scss']
})
export class ThryveSpo2Component implements OnInit, OnDestroy {

  thryveConnected = false;
  currentUser: LoginUser;
  lastRefreshDate: string = null as string;
  spo2ViewModel: Spo2ViewModel[] = {} as Spo2ViewModel[];

  dataSource: any;
  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    private thryveService: ThryveService,
    private authenticationService: AuthenticationService
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

    this.dataSource = new CustomStore({
      loadMode: "raw",
      key: ["patientDataId", "dayAt", "SleepType"],
      load: () => this.getSpo2Data()
    });

    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });

  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  getSpo2Data() {
    return new Promise((resolve, reject) => {
      this.thryveService.getSpo2Data(this.currentUser.id).pipe(first()).subscribe(
        (p) => {
          this.spo2ViewModel = p;
        },
        (e) => {
          //this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.spo2ViewModel);
        }
      );
    });
  }


}
