import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BloodPressure } from 'src/app/services/webapiclient.service';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { Subscription } from 'rxjs';
import { loadMessages, locale } from 'devextreme/localization';
import { CorrectDate } from 'src/app/utils';
import { GamesReportService } from '../services/games-report.service';
import { GameResult, GameResultSummaryPivot } from '../shared/game-chart-model';
import { Role, RoleGUD, RoleRD, RoleSRD } from 'src/app/models/role';
import { Router } from '@angular/router';

type NewType = Subscription;
@Component({
  selector: 'app-game-result-trends',
  templateUrl: './game-result-trends.component.html',
  styleUrls: ['./game-result-trends.component.scss']
})
export class GameResultTrendsComponent implements OnInit, OnDestroy {
  @ViewChild('detailsTemplate', { static: true }) detailsTemplate: TemplateRef<any>;
  @Input() inpPatientId: string;

  modalRef: BsModalRef;
  dataSource: GameResultSummaryPivot[];
  detailSource: GameResult[];
  filteredDataSource: GameResultSummaryPivot[];
  private translSubs$: NewType;
  currentUser: any;
  allocatedTasks: boolean = false;
  selectedGameType: string;

  options: any[];
  option: string;
  selectedOption: any;
  ifDoctor: string;


  stylingMode = "filled";
  maxDate: Date = new Date();
  startDate: Date = new Date(new Date().setMonth(new Date().getMonth() - 1));
  endDate: Date = new Date();
  dateTimeFormat: string;
  chartInstance: any;
  seriesCollection: any = [];

  startDateInstance: any;
  endDateInstance: any;
  error: any;
  customDate: boolean = false;
  patientId: any;
  compareMessage: string;

  doctorUser = false;
  roleGUD: boolean = false;

  bgColor: string[] = ['gainsboro', '#FAAAA0', '#CAFAA0', 'white'];

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private gamesReportService: GamesReportService,
    private translation: L10nTranslationService,
    private datePipe: DatePipe,
    private modalService: BsModalService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });
    //Periódus választó
    this.options = [
      {
        "name": this.translation.translate('Game.Month'),
        "value": 30
      },
      {
        "name": this.translation.translate('Game.QY'),
        "value": 90
      },
      {
        "name": this.translation.translate('Game.HY'),
        "value": 180
      },
      {
        "name": this.translation.translate('Game.Year'),
        "value": 365
      },
      {
        "name": this.translation.translate('Core.Custom'),
        "value": 0
      }
    ];
    this.option = this.options[0].value;
    this.selectedOption = this.options[0];

  }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateTimeFormat = this.translation.translate('Core.DateTimeFormat');
        //Start és end dátum összehasonlító hibaüzenet
        this.compareMessage = this.translation.translate('Core.ValidationDateGreaterError')
          .replace('{0}', this.translation.translate('Task.End'))
          .replace('{1}', this.translation.translate('Task.Start'));
      });


    this.currentUser = this.authenticationService.currentUserValue;
    
    if (this.currentUser && this.currentUser.role) {
      this.roleGUD = RoleGUD.includes(this.currentUser.role as Role)
    }

    if(!this.roleGUD){
      //Nincs jogosultsága, átirányítjuk az előfizetési csomagokhoz
      this.router.navigate(['subscription/packages']);
    }

    if (this.inpPatientId) {
      //Katalógusból hívjuk
      this.patientId = this.inpPatientId;
      this.ifDoctor = "if-doctor";
    }

    if (this.currentUser) {
      if (!this.patientId){
        this.patientId = this.currentUser.id;
        this.doctorUser = this.currentUser.role == Role.Doctor;
      } 
      this.loadDataSource();
    }

  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  loadDataSource() {
    this.gamesReportService.getGameResultTrends(this.patientId, this.startDate.toDateString(), this.endDate.toDateString(), this.allocatedTasks, this.doctorUser).subscribe((data) => {
      this.dataSource = data;
      this.setFilteredDataSource();
    });

  }
  legendClick(e: any) {
    const series = e.target;
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
    this.setFilteredDataSource();
  }

  checkComparison = () => {
    return this.startDate;
  };

  goVirtualPatient(patientId){
    this.router.navigate(['patient-edit', patientId]);
  } 

  setFilteredDataSource() {
    //kitöltjük a háttérszíneket
    this.dataSource.forEach(ds => {
      ds.wordGuessBg = this.setBgColor(ds.wordGuess_Trend, ds.wordGuess_Db);
      ds.memoryGameClassicBg = this.setBgColor(ds.memoryGameClassic_Trend, ds.memoryGameClassic_Db);
      ds.memoryGameMathematicalBg = this.setBgColor(ds.memoryGameMathematical_Trend, ds.memoryGameMathematical_Db);
      ds.memoryGameSemanticBg = this.setBgColor(ds.memoryGameSemantic_Trend, ds.memoryGameSemantic_Db);
      ds.shapeRecallBg = this.setBgColor(ds.shapeRecall_Trend, ds.shapeRecall_Db);
      ds.wordRecallBg = this.setBgColor(ds.wordRecall_Trend, ds.wordRecall_Db);
      ds.sdmtBg = this.setBgColor(ds.sdmT_Trend, ds.sdmT_Db);
      ds.dsstBg = this.setBgColor(ds.dssT_Trend, ds.dssT_Db);
      ds.ldstBg = this.setBgColor(ds.ldsT_Trend, ds.ldsT_Db);
      ds.icaTestBg = this.setBgColor(ds.icaTest_Trend, ds.icaTest_Db);
      ds.colorRecognitionBg = this.setBgColor(ds.colorRecognition_Trend, ds.colorRecognition_Db);
      ds.tmtTestABg = this.setBgColor(ds.tmtTestA_Trend, ds.tmtTestA_Db);
      ds.tmtTestBBg = this.setBgColor(ds.tmtTestB_Trend, ds.tmtTestB_Db);
      ds.wordListRecallAcBg = this.setBgColor(ds.wordListRecallAc_Trend, ds.wordListRecallAc_Db);
    });

    //minden mutatjuk
    this.filteredDataSource = this.dataSource;
  }

  getDetails(patientId,gameType){
    this.selectedGameType = gameType;
    this.gamesReportService.getGameDetails(patientId, gameType, this.startDate.toDateString(), this.endDate.toDateString(), this.allocatedTasks).subscribe((data) => {
      this.detailSource = data;
      this.modalRef = this.modalService.show(this.detailsTemplate, {  backdrop: 'static', keyboard: false });
   });
  }

   setBgColor(trend, db){
    //kissebb a játékok szám mint 6, nem tudunk trendet számolni
    if(db<5) return 0;
    //romló tendencia
    if(trend<-0.2) return 1;
    //javuló tendencia
    if(trend>0.2) return 2;
    //nincs változás
    return 3;
  }

  onQuery() {
    this.loadDataSource();
  }

  onTasksChanged(e) {
    this.loadDataSource();
  }

  onOptionChanged(days, index) {
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - days);
    this.selectedOption = this.options[index];
    this.customDate = false;
    this.loadDataSource();
  }
  onOptionChangedCustom() {
    this.customDate = true;
    this.selectedOption = this.options[4];
  }

  startDateFocusOut(s) {
    CorrectDate(this.startDateInstance);
  }

  initStartDate(e) {
    this.startDateInstance = e.component;
  }

  endDateFocusOut(s) {
    CorrectDate(this.endDateInstance);
  }

  initEndDate(e) {
    this.endDateInstance = e.component;
  }
  initChart(e) {
    this.chartInstance = e.component;
  }
  closeModal() {
    this.modalRef.hide();
  }
}
