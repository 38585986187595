import { Component, OnInit, Inject, Input, OnDestroy } from '@angular/core';
import { first } from 'rxjs/operators';

import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { formatMessage, loadMessages, locale } from 'devextreme/localization';
import CustomStore from 'devextreme/data/custom_store';
import {
  CodesEditModel,
  CodeWarehouseEditModel,
  CodesFunc,
  SelectList
} from '../../services/codes.service';
import { ThryveService, BoolResult, StringResult, ThryveResult, ThryveDailyData, SmartDevices } from '../thryve.service';
import { AuthenticationService } from '../../services/authentication.service';
import { LoginUser } from '../../services/webapiclient.service';
import { Subscription } from 'rxjs';
import { Role } from 'src/app/models/role';

/**
 * Thryve adatok megjelítése. 
 * mySignals oldalról is meghívjuk, adatmegjelenítésre.
 * Tesztelési célra van.
 */

@Component({
  selector: 'app-thryve-data',
  templateUrl: './thryve-data.component.html',
  styleUrls: ['./thryve-data.component.scss']
})
export class ThryveDataComponent implements OnInit, OnDestroy {
  //mySignals oldalon is használjuk adat megjelenítésre
  @Input() inpPatientId: string;
  @Input() needRefresh: boolean;

  thryveConnected = false;
  boolResult: BoolResult = {} as BoolResult;
  stringResult: StringResult = {} as StringResult;
  currentUser: LoginUser;
  doctorUser = false;

  lastRefreshDate: string = null as string;
  thryveDailyData: ThryveDailyData[] = {} as ThryveDailyData[];
  codeData: CodeWarehouseEditModel[] = {} as CodeWarehouseEditModel[];
  smartDevices: SmartDevices[] = {} as SmartDevices[];

  dataSource: any;
  codesDataSource: any;
  smartDevicesSource: any;
  gridInstance: any;

  patientId: any;
  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    private thryveService: ThryveService,
    private codesFunc: CodesFunc,
    private authenticationService: AuthenticationService
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

    this.dataSource = new CustomStore({
      loadMode: "raw",
      key: "id",
      load: () => this.getUserData()
    });

    this.codesDataSource = new CustomStore({
      key: "id",
      loadMode: "raw",
      cacheRawData: false,
      load: () => this.getCodesData()
    });

    this.smartDevicesSource = new CustomStore({
      key: "id",
      loadMode: "raw",
      cacheRawData: false,
      load: () => this.getSmartDevicesData()
    });

    //this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });

    if (this.inpPatientId) this.patientId = this.inpPatientId;
    this.currentUser = this.authenticationService.currentUserValue;
    this.doctorUser = this.currentUser.role == Role.Doctor;

    if (this.currentUser) {
      if (!this.patientId) this.patientId = this.currentUser.id;
      //Megnézzük, hogy van-e thryve kapcsolat
      //Ha van frissítünk
      this.thryveService.checkThryveConnection(this.patientId)
        .subscribe(
          (data: BoolResult) => {
            if (this.boolResult.error) {
              this.thryveConnected = false;
            } else {
              this.thryveConnected = data.result;
              this.refresh();
            }
          }
        );

    }

  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  initGrid(e) {
    this.gridInstance = e.component;
  }

  ngOnChanges() {
    //mySignals oldalról frissítünk
    if (this.patientId) {
      this.getUserData();
      this.gridInstance.refresh();
    }
  }

  getUserData() {
    return new Promise((resolve, reject) => {
      this.thryveService.getUserData(this.patientId).pipe(first()).subscribe(
        (p) => {
          this.thryveDailyData = p.thryveDataList;
        },
        (e) => {
          //this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.thryveDailyData);
        }
      );
    });
  }

  getCodesData() {
    return new Promise((resolve, reject) => {
      this.codesFunc.getCodeDetails("Thryve").pipe(first()).subscribe(
        (p) => {
          this.codeData = p;
        },
        (e) => {
          //this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.codeData);
        }
      );
    });
  }

  getSmartDevicesData() {
    return new Promise((resolve, reject) => {
      this.thryveService.getSmartDevices().pipe(first()).subscribe(
        (p) => {
          this.smartDevices = p;
        },
        (e) => {
          //this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.smartDevices);
        }
      );
    });
  }

  refresh(): void {
    this.thryveService.refresh()
      .subscribe(
        (data: StringResult) => {
          if (this.stringResult.error) {
            this.lastRefreshDate = null;
          } else {
            this.stringResult.result = data.result;
            this.lastRefreshDate = this.stringResult.result;
          }
        }
      );
  }
}
