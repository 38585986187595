<div class="container-narrow">
    <div class="my-3">
        <div class="d-md-flex justify-content-between align-items-center">
            <div class="d-md-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                    <div class="set-icons-sm mb-3 mb-lg-0 me-2">
                        <img src="../../../assets/images/set-delete-registration.svg">
                    </div>
                    <h1 class="display-6 d-block m-0">{{ 'Core.Unregister' | translate:locale.language }}</h1>
                </div>
            </div>
        </div>
        <hr>
        <div class="card">
        <div class="card-body">
            <p> {{ 'Core.ConfirmUnregister' | translate:locale.language }}</p>
            <form (submit)="onFormSubmit($event)">
            <div class="form-group">
                    <div class="form-label">{{ 'Core.UnregisterPassword' | translate:locale.language }}
                    </div>
                    <dx-text-box
                        type="string"
                        [stylingMode]="stylingMode"
                        mode="password"
                        [(value)]="password">
                        <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                            <dxi-validation-rule type="stringLength" min="4" message="{{ 'Core.PasswordTooShort' | translate:locale.language }}"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
                <div>
                <dx-button 
                        class="w-100"
                        stylingMode="contained"
                        text="{{ 'Core.Unregister' | translate:locale.language }}"
                        type="danger"
                        useSubmitBehavior=true>
                </dx-button>

                </div>
            </form>
            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
        </div>
    </div>
</div>
</div>
<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#reg' }"
    [(visible)]="loadingVisible"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
    (onShown)="onShown()"
    (onHidden)="onHidden()">
</dx-load-panel>
