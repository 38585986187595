import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodeTranslatePipe } from './pipes/code-description';
import { ReferenceCategoryPipe } from './pipes/reference-category';
import { ReferenceService } from '../services/reference.service';
import { BitConversionPipe } from './pipes/bit-conversion';
import { JwPaginationComponent } from './jw-pagination/jw-pagination.component';
import { SanitizeHtmlPipe } from './pipes/sanitize-html';
import { ByPassSecurityPipe } from './pipes/by-pass-security';
import { ReplayRecallWordsComponent } from './replay-recall-words/replay-recall-words.component';
import { DxChartModule, DxDataGridModule, DxProgressBarModule } from 'devextreme-angular';
import { FreehandDrawingDirective } from '../drawing/freehand-drawing.directive';
import { ReplayRecallWordsAcComponent } from './replay-recall-words-ac/replay-recall-words-ac.component';
import { FilterPipe } from './pipes/filter-pipe';
import { TaskSheetComponent } from './task-sheet/task-sheet.component';
import { L10nIntlModule, L10nTranslationModule } from 'angular-l10n';
import { InstructionComponent } from './instruction/instruction.component';
import { HcFilterPipe } from './pipes/hc-filter-pipe';
import { FilterDoctorPipe } from './pipes/filter-doctor-pipe';
import { GdprComponent } from './gdpr/gdpr.component';
import { StatementComponent } from './statement/statement.component';
import { TuseComponent } from './tuse/tuse.component';

@NgModule({
  declarations: [
    CodeTranslatePipe,
    ReferenceCategoryPipe,
    BitConversionPipe,
    JwPaginationComponent,
    SanitizeHtmlPipe,
    ByPassSecurityPipe,
    ReplayRecallWordsComponent,
    FreehandDrawingDirective,
    ReplayRecallWordsAcComponent,
    FilterPipe,
    HcFilterPipe,
    FilterDoctorPipe,
    TaskSheetComponent,
    InstructionComponent,
    GdprComponent,
    StatementComponent,
    TuseComponent
  ],
  exports: [
    CodeTranslatePipe,
    ReferenceCategoryPipe,
    BitConversionPipe,
    JwPaginationComponent,
    SanitizeHtmlPipe,
    ByPassSecurityPipe,
    ReplayRecallWordsComponent,
    FreehandDrawingDirective,
    ReplayRecallWordsAcComponent,
    FilterPipe,
    HcFilterPipe,
    FilterDoctorPipe,
    TaskSheetComponent,
    InstructionComponent,
    GdprComponent,
    TuseComponent,
    StatementComponent
  ],
  imports: [
    CommonModule,
    DxChartModule,
    DxProgressBarModule,
    DxDataGridModule,
    L10nTranslationModule,
    L10nIntlModule
  ],
  providers: [
    ReferenceService
  ]
})
/**
 * Megosztott funkciókat tartalmazó modul (pipe-ok)
 */

export class SharedModule { }
