import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GameResultDetails, WordRecallResult } from 'src/app/games/shared/gameResult';
import { GameResult } from '../shared/game-chart-model';

import { environment } from 'src/environments/environment';
import { GameResultData, GameResultSummaryPivot } from '../shared/game-chart-model';

@Injectable({
  providedIn: 'root'
})
export class GamesReportService {
  baseUri = `${environment.apiUrl}`;

  constructor(private http: HttpClient) { }

  /**
   * Összesített játék eredmények (trend figyeléssel)
   */
  getGameResultTrends(userId: string, startDate: string, endDate: string, allocatedTask: boolean, doctorUser: boolean): Observable<GameResultSummaryPivot[]> {
    return this.http.get<GameResultSummaryPivot[]>(this.baseUri + 'Games/GetTaskSummaryPivot?userId=' + encodeURIComponent(userId) + '&startDate=' + encodeURIComponent(startDate) + '&endDate=' + encodeURIComponent(endDate) + '&allocatedTask=' + encodeURIComponent(allocatedTask)+ '&doctorUser=' + encodeURIComponent(doctorUser));
  }

  /**
    * Eredmény részletek a trend figyeléshez
    */
  getGameDetails(patientId: string, gameType: string, startDate: string, endDate: string, allocatedTask: boolean): Observable<GameResult[]> {
    return this.http.get<GameResult[]>(this.baseUri + 'Games/GetGameDetails?patientId=' + encodeURIComponent(patientId) + '&gameType=' + encodeURIComponent(gameType) + '&startDate=' + encodeURIComponent(startDate) + '&endDate=' + encodeURIComponent(endDate) + '&allocatedTask=' + encodeURIComponent(allocatedTask));
  }

  /**
   * A játékok eredményét kérdezi le.
   */
  getGameResults(patientId: string, startDate: string, endDate: string, zScore: number = 0): Observable<GameResultData[]> {
    return this.http.get<GameResultData[]>(this.baseUri + 'Games/GetGameResultReport?patientId=' + encodeURIComponent(patientId) + '&startDate=' + encodeURIComponent(startDate) + '&endDate=' + encodeURIComponent(endDate)+ '&zScore=' + encodeURIComponent(zScore));
  }
  /**
  * A játékok részletes eredményét kérdezi le.
  */
  getGameResultDetails(resultId: number): Observable<GameResultDetails> {
    return this.http.get<GameResultDetails>(this.baseUri + 'Games/GetSdmtResultDetails?resultId=' + encodeURIComponent(resultId));
  }

  getGameResultDetails1(patientId: string, taskId: number, gameType: string, subType: string): Observable<GameResultDetails> {
    return this.http.get<GameResultDetails>(this.baseUri + 'Games/GetResultDetails1?patientId=' + encodeURIComponent(patientId) + '&taskId=' + encodeURIComponent(taskId) + '&gameType=' + encodeURIComponent(gameType)+ '&subType=' + encodeURIComponent(subType));
  }


  /**
   * A szó visszaidéző játékok részletes eredményét kérdezi le.
   */
  getWordRecallResult(resultId: number): Observable<WordRecallResult> {
    return this.http.get<WordRecallResult>(this.baseUri + 'Games/GetWordRecallResult?resultId=' + encodeURIComponent(resultId));
  }

}
