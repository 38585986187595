<!-- Megjelenítés -->
<div class="container">
    <div class="m-3">
        <div class="d-xl-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <div  class="general-icons-sm me-2">
                    <img src="../../../assets/images/tasks.svg">
                </div>
                <h1  class="display-6 m-0">{{ 'Task.Tasks' | translate:locale.language }}</h1>
            </div>
            <div class="mt-2 mt-xl-0">
                <button type="button" class="btn btn-success me-2" (click)="clickNewExercise()">
                    <i class="bi bi-plus"></i> {{ 'Task.NewExercise' | translate:locale.language }}
                </button>
                <button type="button" class="btn btn-success me-2" (click)="clickNewTask()">
                    <i class="bi bi-plus"></i> {{ 'Task.NewTask' | translate:locale.language }}
                </button>
                <button  *ngIf="currentUser && currentUser.role != 'GP'" type="button" class="btn btn-success" (click)="clickNewTaskSheet()">
                    <i class="bi bi-plus"></i> {{ 'Task.NewTaskSheet' | translate:locale.language }}
                </button>
            </div>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <table class="table text-center">
            <thead>
                <tr>
                    <th>{{ 'Project.Type' | translate:locale.language }}</th>
                    <th>{{ 'Task.Start' | translate:locale.language }}</th>
                    <th>{{ 'Task.End' | translate:locale.language }}</th>
                    <th>{{ 'Task.Frequency' | translate:locale.language }}</th>
                    <th style="width: 90px;"></th>
                </tr>
            </thead>
            <tbody class="bg-white">
                <tr *ngFor="let item of dataSource; index as i">
                    <td *ngIf="item.physicalExercise">{{ item.taskId | codeTranslate:exerciseSource }}</td>
                    <td *ngIf="item.taskSheet">{{ item.taskId | codeTranslate:taskSheetSource }}</td>
                    <td *ngIf="!item.physicalExercise && !item.taskSheet">{{ item.taskId | codeTranslate:taskSource }}</td>
                    <td>{{ item.startDate | date: dateFormat }}</td>
                    <td>{{ item.endDate | date: dateFormat}}</td>
                    <td>{{ item.frequencyId | codeTranslate:frequencySourceAll }}</td>
                    <td class="text-start">
                        <a *ngIf="item.allowDelete" (click)="clickDelete(i)"
                            class="text-danger h5 mx-2 my-0 pointer"><i class="bi bi-trash"></i></a>
                    </td>

                </tr>
            </tbody>
        </table>
    </div>

</div>
<!-- Új Task Modal -->
<ng-template #newTask>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'Task.NewTask' | translate:locale.language }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">
            <div class="mb-3">
                <div class="form-label">{{ 'Task.Task' | translate:locale.language }}</div>
                <dx-select-box [dataSource]="taskSource" valueExpr="id" stylingMode="filled"
                    displayExpr="descriptionTranslated" [searchEnabled]="true" [(value)]="editItem.taskId">
                    <dx-validator>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dx-validator>
                </dx-select-box>
            </div>
            <ng-container *ngTemplateOutlet="fieldsTemplate"></ng-container>
            <div class="modal-footer">
                <dx-button stylingMode="contained" text="{{ 'Core.EditSave' | translate:locale.language }}"
                    type="default" useSubmitBehavior=true>
                </dx-button>
                <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
                    {{ 'Core.EditCancel' | translate:locale.language }}
                </button>
            </div>
        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</ng-template>

<!-- Új TaskSheet Modal -->
<ng-template #newTaskSheet>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'Task.NewTaskSheet' | translate:locale.language }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">
            <div class="mb-3">
                <div class="form-label">{{ 'Task.TaskSheet' | translate:locale.language }}</div>
                <dx-select-box [dataSource]="taskSheetSource" valueExpr="id" stylingMode="filled"
                    displayExpr="descriptionTranslated" [searchEnabled]="true" [(value)]="editItem.taskId">
                    <dx-validator>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dx-validator>
                </dx-select-box>
            </div>
            <ng-container *ngTemplateOutlet="fieldsTemplate"></ng-container>
            <div class="modal-footer">
                <dx-button stylingMode="contained" text="{{ 'Core.EditSave' | translate:locale.language }}"
                    type="default" useSubmitBehavior=true>
                </dx-button>
                <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
                    {{ 'Core.EditCancel' | translate:locale.language }}
                </button>
            </div>
        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</ng-template>

<!-- Új Exercise Modal -->
<ng-template #newExercise>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'Task.NewExercise' | translate:locale.language }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">
            <div class="mb-3">
                <div class="form-label">{{ 'Task.Task' | translate:locale.language }}</div>
                <dx-select-box [dataSource]="exerciseSource" valueExpr="id" stylingMode="filled"
                    displayExpr="descriptionTranslated" [searchEnabled]="true" [(value)]="editItem.taskId">
                    <dx-validator>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dx-validator>
                </dx-select-box>
            </div>
            <ng-container *ngTemplateOutlet="fieldsTemplate"></ng-container>
            <div class="modal-footer">
                <dx-button stylingMode="contained" text="{{ 'Core.EditSave' | translate:locale.language }}"
                    type="default" useSubmitBehavior=true>
                </dx-button>
                <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
                    {{ 'Core.EditCancel' | translate:locale.language }}
                </button>
            </div>
        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</ng-template>

<ng-template #fieldsTemplate>
    <div class="mb-3">
        <div class="form-label">{{ 'Task.Start' | translate:locale.language }}</div>
        <dx-date-box type="date" stylingMode="filled" (onFocusOut)="startDateFocusOut($event)"
            (onInitialized)="initStartDate($event)" [(value)]="editItem.startDate">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxi-validation-rule type="range" [min]="minDate"></dxi-validation-rule>
            </dx-validator>
        </dx-date-box>
    </div>
    <div class="mb-3">
        <div class="form-label">{{ 'Task.Frequency' | translate:locale.language }}</div>
        <dx-select-box [dataSource]="frequencySource" valueExpr="id" stylingMode="filled"
            displayExpr="descriptionTranslated" [(value)]="editItem.frequencyId">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
        </dx-select-box>
    </div>
    <div class="mb-3">
        <div class="form-label">{{ 'Task.End' | translate:locale.language }}</div>
        <dx-date-box type="date" stylingMode="filled" (onFocusOut)="endDateFocusOut($event)"
            (onInitialized)="initEndDate($event)" [(value)]="editItem.endDate">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxi-validation-rule type="range" [min]="minDate"></dxi-validation-rule>
                <dxi-validation-rule type="compare" [comparisonTarget]="checkComparison" comparisonType=">"
                    [message]="compareMessage"></dxi-validation-rule>
            </dx-validator>
        </dx-date-box>
    </div>

</ng-template>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" (onShown)="onShown()">
</dx-load-panel>