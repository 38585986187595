<div class="container mb-5">
    <div class="m-3">
        <div class="d-flex align-items-center">
            <div class="general-icons-sm me-2">
                <img src="../../../assets/images/analytics-clinical.svg">
            </div>
            <h1 class="display-6 m-0">{{ 'Project.AnalyticsClinical' | translate:locale.language }}</h1>
        </div>
        <hr>
        <div class="row row-col-1 row-cols-md-2 g-3">
            <!-- <div class="form-label">{{ 'Task.Task' | translate:locale.language }}</div> -->
            <div>
                <dx-select-box [dataSource]="taskSource" valueExpr="id" stylingMode="filled"
                    (onValueChanged)="onValueChanged($event)" displayExpr="descriptionTranslated" [searchEnabled]="true"
                    [(value)]="selectedGameId"
                    placeholder="{{ 'Core.DensityTestPlaceholder' | translate:locale.language }}">
                    <dx-validator>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dx-validator>
                </dx-select-box>
            </div>
            <div>
                <dx-select-box [dataSource]="patientSource" valueExpr="patientId" stylingMode="filled"
                    (onValueChanged)="onPatientChanged($event)" displayExpr="displayName" [searchEnabled]="true"
                    [(value)]="selectedPatientId"
                    placeholder="{{ 'Core.DensityPatientPlaceholder' | translate:locale.language }}">
                </dx-select-box>
            </div>
        </div>

        <div *ngIf="dataSource">
            <dx-chart palette="violet" [dataSource]="dataSource"
                title="{{ 'Core.DensityChartTitle' | translate:locale.language }}">
                <dxi-series argumentField="zscore" ignoreEmptyPoints="true" valueField="densityPraxis"
                    name="{{ 'Core.DensityDataPraxis' | translate:locale.language }}" color="#e5a60a">
                    <dxo-point color="#e5a60a"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="zscore" ignoreEmptyPoints="true" valueField="densityUser"
                    name="{{ 'Core.DensityDataPatient' | translate:locale.language }}" color="#07a0d6">
                    <dxo-point color="#07a0d6"></dxo-point>
                </dxi-series>
                <dxi-series type="bar" argumentField="zscore" ignoreEmptyPoints="true" argumentScaleType="numeric"
                    valueField="densitySelected" name="{{ 'Core.DensityDataSelected' | translate:locale.language }}"
                    color="#1873a0">
                    <dxo-point color="#1873a0"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="zscore" ignoreEmptyPoints="true" argumentScaleType="numeric"
                    valueField="density" name="{{ 'Core.DensityDataReference' | translate:locale.language }}"
                    color="#97c95c">
                    <dxo-point color="#97c95c"></dxo-point>
                </dxi-series>
                <dxo-common-series-settings type="spline">
                </dxo-common-series-settings>
                <dxo-margin [bottom]="20"></dxo-margin>
                <dxo-common-axis-settings>
                    <dxo-grid [visible]="true"></dxo-grid>
                </dxo-common-axis-settings>
                <dxi-value-axis>
                    <dxo-title text="{{ 'Core.Density' | translate:locale.language }}"></dxo-title>
                    <dxo-label [customizeText]="customizeLabelText"></dxo-label>

                </dxi-value-axis>
                <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
                </dxo-legend>
                <dxo-argument-axis>
                    <dxo-title text="z-score"></dxo-title>
                    <dxo-label>
                        <dxo-format type="decimal"></dxo-format>
                    </dxo-label>
                </dxo-argument-axis>
                <dxo-export [enabled]="false"></dxo-export>
                <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip">
                </dxo-tooltip>
            </dx-chart>
        </div>
    </div>
</div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" (onShown)="onShown()">
</dx-load-panel>