import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CanDeactivate } from '@angular/router';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog-service';
export interface FormComponent {
   formg: FormGroup;
   hasChanged: boolean;
 }

@Injectable()
export class NavPreventerService implements CanDeactivate<FormComponent> {

  constructor(
    private confirmDialogService: ConfirmDialogService  

  ) {
  }

  canDeactivate(component: FormComponent) {
    if(component.hasChanged){
      return confirm(
        'Biztos, hogy mentés nélkül el akarja hagyni a beviteli képernyőt?'
      );
    }
    return true;
   }
}