<div class="container">
    <h1 class="display-6 d-block">{{ 'Core.Role' | translate:locale.language }}</h1>
    
    <dx-data-grid id="gridContainer"
    [dataSource]="dataSource"
    [remoteOperations]="false"
    [rowAlternationEnabled]="true"
    [showBorders]="true"

    > 
    <dxo-editing 
    mode="row"
    [allowUpdating]="true"
    [allowDeleting]="true"
    [allowAdding]="true"
    [useIcons]="true">
   </dxo-editing>

    <!-- <dxi-column dataField="id" dataType="string"></dxi-column> -->
    <dxi-column dataField="name" caption= "{{ 'Core.Role' | translate:locale.language }}" dataType="string">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="name" [allowEditing]="false" caption= "{{ 'Core.Entity_Name' | translate:locale.language }}" dataType="string" cellTemplate="textTemplate">
    </dxi-column>
    <div *dxTemplate="let d of 'textTemplate'">
            <p class="form-value">{{'Role.' + d.value  | translate:locale.language}}</p>
    </div>
</dx-data-grid>
</div>
