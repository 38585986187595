<div class="card">
    <div class="card-body">
        <form action="your-action" (submit)="onFormSubmit($event)">
            <div class="row">
                <div class="col-md-6 mb-3 align-self-end">
                    <div>
                        <div class="form-label">{{ 'Core.Entity_UserUserName' | translate:locale.language }}
                        </div>
                        <dx-text-box type="string" [stylingMode]="stylingMode"
                            [(value)]="patientDataViewModel.userName">
                            <dx-validator>
                                <dxi-validation-rule type="stringLength" [max]="25"
                                    message="Felhasználó név max. 25 karakter lehet!"></dxi-validation-rule>
                                <dxi-validation-rule type="pattern" [pattern]="userNamePattern"
                                    message="{{ 'Subscription.SpecialCharactersNotAllowed' | translate:locale.language }}"></dxi-validation-rule>
                                <dxi-validation-rule type="async" ignoreEmptyValue="true"
                                    [validationCallback]="asyncNameValidation" width="40">
                                </dxi-validation-rule>

                                <dxi-validation-rule type="required"></dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                    </div>
                </div>
                <div class="col-md-6 mb-3 align-self-end">
                    <div>
                        <div class="form-label">{{ 'Core.Entity_UserName' | translate:locale.language }}
                        </div>
                        <dx-text-box type="string" [stylingMode]="stylingMode"
                            [(value)]="patientDataViewModel.displayName">
                            <dx-validator>
                                <dxi-validation-rule type="required"></dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 mb-3 align-self-end">
                    <div>
                        <div class="form-label">{{ 'Core.Account_Register_Email' | translate:locale.language }}
                        </div>
                        <dx-text-box type="string" [stylingMode]="stylingMode" [(value)]="patientDataViewModel.email">
                            <dx-validator>
                                <dxi-validation-rule type="required"></dxi-validation-rule>
                                <dxi-validation-rule type="email"></dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                    </div>
                </div>
                <div class="col-md-6 mb-3 align-self-end">
                    <div>
                        <div class="form-label">{{ 'Project.BirthDate' | translate:locale.language }}
                        </div>
                        <dx-date-box type="date" [stylingMode]="stylingMode" (onFocusOut)="birthDateFocusOut($event)"
                            (onEnterKey)="birthDateFocusOut($event)" (onInitialized)="initBirthDate($event)"
                            [(value)]="patientDataViewModel.birthDate" [min]="min" [max]="now">
                            <dx-validator>
                                <dxi-validation-rule type="required"></dxi-validation-rule>
                             </dx-validator>
                        </dx-date-box>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3 mb-3 align-self-end">
                    <div>
                        <div class="form-label">{{ 'Project.TAJ' | translate:locale.language }}
                        </div>
                        <dx-text-box *ngIf="locale.language.toLowerCase() == 'hu-hu'" type="string"
                            [stylingMode]="stylingMode" [(value)]="patientDataViewModel.taj" mask="000 000 000"
                            [useMaskedValue]="true">
                            <dx-validator>
                                <dxi-validation-rule type="required"></dxi-validation-rule>
                                <dxi-validation-rule type="custom" [validationCallback]="validateTaj"
                                    message="{{ 'Project.InvalidTaj' | translate:locale.language }}">
                                </dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                        <dx-text-box *ngIf="locale.language.toLowerCase() != 'hu-hu'" type="string"
                            [stylingMode]="stylingMode" [(value)]="patientDataViewModel.taj" mask="000 000 0000"
                            [useMaskedValue]="true">
                            <dx-validator>
                                <dxi-validation-rule type="required"></dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                    </div>
                </div>

                <div class="col-md-3 mb-3 align-self-end">
                    <div>
                        <div class="form-label">{{ 'Project.Sex' | translate:locale.language }}
                        </div>
                        <dx-select-box [dataSource]="sexCodes" valueExpr="id" displayExpr="descriptionTranslated"
                            [(value)]="patientDataViewModel.sexCodeId" [stylingMode]="stylingMode">
                            <dx-validator>
                                <dxi-validation-rule type="required"></dxi-validation-rule>
                            </dx-validator>
                        </dx-select-box>
                    </div>
                </div>
                <div class="col-md-6 mb-3 align-self-end">
                    <div>
                        <div class="form-label">{{ 'Project.HighestEducation' | translate:locale.language }}
                        </div>
                        <dx-select-box [dataSource]="educationCodes" valueExpr="id" displayExpr="descriptionTranslated"
                            [(value)]="patientDataViewModel.highestEducationId" [stylingMode]="stylingMode">
                            <dx-validator>
                                <dxi-validation-rule type="required"></dxi-validation-rule>
                            </dx-validator>
                        </dx-select-box>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 mb-3 align-self-end">
                    <div>
                        <div class="form-label">{{ 'Project.ClinicalFamilyHistory' | translate:locale.language }}
                        </div>
                        <dx-select-box [dataSource]="familyHistoryCodes" valueExpr="id"
                            displayExpr="descriptionTranslated" [(value)]="patientDataViewModel.familyHistoryId"
                            [stylingMode]="stylingMode">
                            <dx-validator>
                                <dxi-validation-rule type="required"></dxi-validation-rule>
                            </dx-validator>
                        </dx-select-box>
                    </div>
                </div>
                <div class="col-md-4 mb-3 align-self-end">
                    <div>
                        <div class="form-label">{{ 'Core.Entity_ContactPhone' | translate:locale.language }}
                        </div>
                        <dx-text-box type="string" [stylingMode]="stylingMode" [(value)]="patientDataViewModel.phone">
                        </dx-text-box>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end mx-3 py-3 border-top">
                <div class="me-2">
                    <dx-button text="{{ 'Core.EditSave' | translate:locale.language }}" type="default"
                        useSubmitBehavior=true>
                    </dx-button>
                </div>
                <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
                    {{ 'Core.EditCancel' | translate:locale.language }}
                </button>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" (onShown)="onShown()"
    (onHidden)="onHidden()">
</dx-load-panel>