import { Component, ElementRef, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { PatientService } from 'src/app/module-patient/patient.service';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { loadMessages, locale } from 'devextreme/localization';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Catalogue, DoctorDashboard, ExtendedDoctorDashboard, LastMeasuredData, LoginUser } from 'src/app/services/webapiclient.service';
import { CodeWarehouseEditModel, CodesFunc } from 'src/app/services/codes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SubscriptionService } from 'src/app/module-subscription/subscription-service';
import { BoolResult } from 'src/app/module-thryve/thryve.service';
import notify from 'devextreme/ui/notify';
import { first } from 'rxjs/operators';
import { Ads, AdsService } from 'src/app/services/ads.service';

/**
  * Páciens katalógus.
  * Doktor felhasználók nyitó oldala
  */

@Component({
  selector: 'app-patient-catalogue',
  templateUrl: './patient-catalogue.component.html',
  styleUrls: ['./patient-catalogue.component.scss']
})
export class PatientCatalogueComponent implements OnInit, OnDestroy {
  dataSource: any;
  catalogueSource: Catalogue[];
  doctorDashboard: DoctorDashboard;
  extendedDashboard: ExtendedDoctorDashboard;
  filteredDataSource: Catalogue[];
  pageSize: number = 8;
  actualPageSize: number;
  filteredCount = { count: 0 };

  //szúrő feltétel összes=all; új felvétel=new; kiértékelésre vár=eva
  filterOption: string = "all";
  codesSource: any;
  currentUser: LoginUser;
  loadingVisible = false;
  error = '';
  gridInstance: any;
  selectedPatient: string;
  selectedItem: any;
  loading: boolean = false;
  searchText: string = "";
  needEvaluation: number = 0;
  needActivation: number = 0;
  newPatients: number = 0;
  activePatientPercent: number = 0;
  taskSheetPercent: number = 0;
  gamePercent: number = 0;
  totalTests: number = 0;
  dateFormat: string;

  uploadUrl = `${environment.uploadUrl}`;
  taskCodes: CodeWarehouseEditModel[] = {} as CodeWarehouseEditModel[];

  adSrc1: Ads;
  adSrc2: Ads;
  adSrc3: Ads;

  testTypes: any[] = [{
    type: 'TaskSheet',
    val: 0,
  }, {
    type: 'Test',
    val: 0,
  }];

  @ViewChild('newAdmission', { static: true }) newAdmission: TemplateRef<any>;
  @ViewChild('denyTreatment', { static: true }) denyTreatment: TemplateRef<any>;
  @ViewChild('infoPanel', { static: true }) infoPanel: TemplateRef<any>;
  @ViewChild('subscriptionInfoPanel', { static: true }) subscriptionInfoPanel: TemplateRef<any>;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChild('taskSheets', { static: true }) taskSheets: TemplateRef<any>;

  modalRef: BsModalRef;
  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private patientService: PatientService,
    private subscriptionService: SubscriptionService,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private codesFunc: CodesFunc,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private router: Router,
    private adsService: AdsService

  ) {
    this.customizeText = this.customizeText.bind(this);

    const huMessages = JSON.stringify(messagesHu.hu);
    loadMessages({
      hu: JSON.parse(huMessages)
    });
    codesFunc.getCodesData('CognitiveTest').then(
      result => {
        this.taskCodes = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });

  }

  ngOnInit(): void {
    this.actualPageSize = this.pageSize;
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
        this.getAdSrc1(x.language);
        this.getAdSrc2(x.language);
        this.getAdSrc3(x.language);
  });

    this.currentUser = this.authenticationService.currentUserValue;
    this.getCatalogueSource();
    this.getDoctorDashboardData();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    })
  }
  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  getAdSrc1(lang) {
    return new Promise((resolve, reject) => {
       this.adsService.getActualAdSrc(lang, 1, true).pipe(first()).subscribe(
          (p) => {
             this.adSrc1 = p;
          },
          (e) => {
             //this.errorService.changeErrorMessage(e.message );
             reject(e.message);
          },
          () => {
             resolve(this.adSrc1);
          }
       );
    });
 }
 getAdSrc2(lang) {
    return new Promise((resolve, reject) => {
       this.adsService.getActualAdSrc(lang, 2, true).pipe(first()).subscribe(
          (p) => {
             this.adSrc2 = p;
          },
          (e) => {
             //this.errorService.changeErrorMessage(e.message );
             reject(e.message);
          },
          () => {
             resolve(this.adSrc2);
          }
       );
    });
 }
 getAdSrc3(lang) {
    return new Promise((resolve, reject) => {
       this.adsService.getActualAdSrc(lang, 3, true).pipe(first()).subscribe(
          (p) => {
             this.adSrc3 = p;
          },
          (e) => {
             //this.errorService.changeErrorMessage(e.message );
             reject(e.message);
          },
          () => {
             resolve(this.adSrc3);
          }
       );
    });
 }


  //Előfizetés megadása
  addPlatinum(item) {
    this.loadingVisible = true;
    this.selectedPatient = item.id;

    this.subscriptionService.addPlatinumSubscription(this.selectedPatient).then(
      result => {
        let ret = <BoolResult>result;

        if (ret.result) {
          notify({
            message: this.translation.translate('Subscription.SuccessAdd'),
            position: {
              my: "center top",
              at: "center top"
            }
          }, "success", 3000);
          this.getCatalogueSource();
        } else {
          this.error = ret.error;
        }
        this.loadingVisible = false;
      }
    ).catch(err => {
      this.loadingVisible = false;
    }
    );

  }

  //Előfizetés meghosszabbítás
  extendPlatinum() {
    this.loadingVisible = true;

    this.subscriptionService.extendPlatinumSubscription(this.selectedPatient).then(
      result => {
        let ret = <BoolResult>result;

        if (ret.result) {
          this.closeModal(true);
          notify({
            message: this.translation.translate('Subscription.SuccessExtend'),
            position: {
              my: "center top",
              at: "center top"
            }
          }, "success", 3000);
          this.getCatalogueSource();
        } else {
          this.error = ret.error;
        }
        this.loadingVisible = false;
      }
    ).catch(err => {
      this.loadingVisible = false;
    }
    );

  }

  clickMore() {
    this.actualPageSize = this.actualPageSize + this.pageSize;
  }

  getCatalogueSource() {
    this.loading = true;
    this.patientService.getCatalogue(this.currentUser.id, false).then(
      result => {
        this.catalogueSource = <Catalogue[]>result;
        this.setFilteredDataSource();
        this.needEvaluation = this.catalogueSource.filter(f => f.needEvaluation).length;
        this.needActivation = this.catalogueSource.filter(f => !f.emailConfirmed).length;
        this.newPatients = this.catalogueSource.filter(f => f.hasDoctor == 1).length;
        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });
  }
  getDoctorDashboardData() {
    this.loading = true;
    this.patientService.getDoctorDashboardData(this.currentUser.id).then(
      result => {
        this.extendedDashboard = <ExtendedDoctorDashboard>result;
        this.doctorDashboard = this.extendedDashboard.dashBoard;
        if (this.doctorDashboard.sumPatient > 0) {
          this.activePatientPercent = this.doctorDashboard.activePatient / (this.doctorDashboard.sumPatient / 100);
        }
        if (this.doctorDashboard.taskSheetsPrev > 0) {
          this.taskSheetPercent = this.doctorDashboard.taskSheetsAct / (this.doctorDashboard.taskSheetsPrev / 100);
        }
        if (this.doctorDashboard.gamesPrev > 0) {
          this.gamePercent = this.doctorDashboard.gamesAct / (this.doctorDashboard.gamesPrev / 100);
        }
        this.testTypes[0].val = this.doctorDashboard.taskSheetsAct;
        this.testTypes[1].val = this.doctorDashboard.gamesAct;
        this.testTypes[0].type = this.translation.translate('Task.TaskSheet');
        this.testTypes[1].type = this.translation.translate('Task.Task');

        this.totalTests = this.doctorDashboard.taskSheetsAct + this.doctorDashboard.gamesAct;
        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });
  }

  customizeText(pointInfo: any) {
    if (pointInfo.pointIndex == 0) return pointInfo.pointName + ' (' + this.doctorDashboard.taskSheetsAct + ')';
    return pointInfo.pointName + ' (' + this.doctorDashboard.gamesAct + ')';
  }

  setFilteredDataSource() {
    if (this.filterOption == "all") {
      this.filteredDataSource = this.catalogueSource.filter(f => f.hasDoctor == 2);
    } else if (this.filterOption == "new") {
      //új betegek, felvételre várnak
      this.filteredDataSource = this.catalogueSource.filter(f => f.hasDoctor == 1);
    } else if (this.filterOption == "wact") {
      //Regisztráció visszaigazolást még nem végezték el
      this.filteredDataSource = this.catalogueSource.filter(f => !f.emailConfirmed);
    } else {
      //kiértékelásre váró betegek
      this.filteredDataSource = this.catalogueSource.filter(f => f.needEvaluation);
    }
    this.filteredCount.count = this.filteredDataSource.length;
  }

  onOptionChangedNew(index) {
    if (index == 0) {
      this.filterOption = "all";
    } else if (index == 1) {
      this.filterOption = "eva";
    } else if (index == 3) {
      this.filterOption = "wact";
    } else {
      this.filterOption = "new";
    }
    this.setFilteredDataSource();
  }


  //Doktor magához veszi a pácienst, erről email is megy a betegnek
  onClickStatus(data) {
    this.loadingVisible = true;
    data.doctorId = this.currentUser.id;

    this.patientService.saveProviderConfirmation(this.currentUser.id, JSON.stringify(data)).then(
      result => {
        if (result === 'OK') {
          data.hasDoctor = 2;
          this.getCatalogueSource();
        }
        this.loadingVisible = false;
      }
    ).catch(err => {
      this.error = err;
      this.loadingVisible = false;
    });
  }
  //Doktor elutasítja a pácienst, erről email is megy a betegnek
  onDeny(data) {
    this.selectedPatient = data.id;
    this.modalRef = this.modalService.show(this.denyTreatment, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }
  //Páciens info panel
  onInfoClick(data) {
    this.selectedPatient = data.id;
    this.modalRef = this.modalService.show(this.infoPanel, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  //Előfizetés info panel
  onSubscriptionInfoClick(data) {
    this.selectedPatient = data.id;
    this.selectedItem = data;
    this.modalRef = this.modalService.show(this.subscriptionInfoPanel, { class: 'modal-md modal-dialog-centered', backdrop: 'static', keyboard: false });
  }

  //Páciens kiválasztás, a doktor elkezd foglakozni vele. (vizsgálatok, adatfelvételek)
  onClickSelect(data) {
    this.selectedPatient = data.id;
    // this.router.navigate(['/patient-edit'], { queryParams: { patientId: data.id } });
    this.router.navigate(['/patient-edit/' + data.id]);
  }

  //Páciens kiválasztás, a doktor elkezd foglakozni vele. (vizsgálatok, adatfelvételek)
  selectPatient(id) {
    this.selectedPatient = id;
    // this.router.navigate(['/patient-edit'], { queryParams: { patientId: data.id } });
    this.router.navigate(['/patient-edit/' + id]);
  }
  selectKpis(mode) {
    this.router.navigate(['/kpi-s/' + mode]);
  }
  selectAnalytics() {
    this.router.navigate(['/statistics/density']);
  }


  onShown() {
    // setTimeout(() => {
    //     this.loadingVisible = false;
    // }, 15000);
  }

  onHidden() {
    // this.employeeInfo = this.employee;
  }

  //Új betegfelvétel
  clickNewAdmission() {
    this.getCatalogueSource();
    this.modalRef = this.modalService.show(this.newAdmission, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  //Saját tesztlapok kezelése
  clickTaskSheets() {
    this.modalRef = this.modalService.show(this.taskSheets, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  closeNewAdmissionModal(e) {
    this.modalRef.hide();
  }
  closeDenyTreatmentModal(e) {
    this.getCatalogueSource();
    this.modalRef.hide();
  }
  closeModal(e) {
    this.modalRef.hide();
  }
  startTask(root) {
    this.router.navigate([root, 0]);
  }
  //Feladatlap áttekinése
  reviewTaskSheet(name) {
    let taskSheetId = this.taskCodes.filter(f => f.applicationReference == name)[0].id;
    let root = '/games/task-sheet-execution/' + taskSheetId
    this.router.navigate([root, 0]);
  }

}


