<!-- Megjelenítés -->
<div *ngIf="onlyInfo=='NO'" class="container">
    <div class="m-3">
      <div class="d-md-flex justify-content-between align-items-center">
        <h1 class="display-6 d-block">{{ 'Core.EndUserLicenseAgreement' | translate:locale.language }}</h1>
        <div>
            <button type="button" class="btn btn-success" (click)="clickNew()"> 
              <i class="bi bi-plus"></i> {{ 'Core.EditEdit' | translate:locale.language }}
            </button>
        </div>    
      </div>
      <hr>
    </div>
    <div class="m-3">
      <table class="table table-bordered text-center bg-white">
        <thead>
        <tr>
          <th style="width: 90px;"></th>
          <th>Id</th>
          <th>{{ 'Core.ValidFrom' | translate:locale.language }}</th>
          <th>{{ 'Core.IsNewAcceptNeed' | translate:locale.language }}</th>
          <!-- <th>{{ 'Core.Entity_CreatedBy' | translate:locale.language }}</th> -->
          <th>{{ 'Core.Entity_ModificationDate' | translate:locale.language }}</th>
          <th>{{ 'Core.Language' | translate:locale.language }}</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of dataSource; index as i">
          <td class="text-start">
             <a (click)="clickInfo(i)" class="text-primary h5 mx-2 my-0 pointer"><i class="bi bi-info-circle"></i></a> 
          </td>
          <td>{{ item.id }}</td>
          <td>{{item.validFrom | date: dateFormat }}</td>
          <td>
            <input class="form-check-input" type="checkbox" [checked]="item.acceptNeed" [disabled]="true">              
          </td>
          <!-- <td>{{ item.createdBy }}</td> -->
          <td>{{ item.createdOn | date: dateFormat}}</td>
          <td>{{ item.lang }} </td>
          
        </tr>
      </tbody>
      </table>
    </div>
</div>

<!-- Új Modal -->
<ng-template #newTerm>
  <div class="modal-header">
  <h5 class="modal-title" >{{ 'Core.CreateDocument' | translate:locale.language }}</h5>
  <button type="button" 
          class="btn-close" 
          data-bs-dismiss="modal"
          (click)="closeModal()" 
          aria-label="Close">
  </button>
  </div>
  <div class="modal-body">
       <form (submit)="onFormSubmit($event)">
          <dx-html-editor height="725px" [(value)]="editItem.terms">
              <dxo-toolbar [multiline]="true">
                  <dxi-item name="undo"></dxi-item>
                  <dxi-item name="redo"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="size" [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"></dxi-item>
                  <dxi-item name="font" [acceptedValues]="['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana']"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="bold"></dxi-item>
                  <dxi-item name="italic"></dxi-item>
                  <dxi-item name="strike"></dxi-item>
                  <dxi-item name="underline"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="alignLeft"></dxi-item>
                  <dxi-item name="alignCenter"></dxi-item>
                  <dxi-item name="alignRight"></dxi-item>
                  <dxi-item name="alignJustify"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="orderedList"></dxi-item>
                  <dxi-item name="bulletList"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="color"></dxi-item>
                  <dxi-item name="background"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="link"></dxi-item>
                  <dxi-item name="image"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="clear"></dxi-item>
                  <dxi-item name="codeBlock"></dxi-item>
                  <dxi-item name="blockquote"></dxi-item>
                  <dxi-item name="separator"></dxi-item>
                  <dxi-item name="insertTable"></dxi-item>
                  <dxi-item name="deleteTable"></dxi-item>
                  <dxi-item name="insertRowAbove"></dxi-item>
                  <dxi-item name="insertRowBelow"></dxi-item>
                  <dxi-item name="deleteRow"></dxi-item>
                  <dxi-item name="insertColumnLeft"></dxi-item>
                  <dxi-item name="insertColumnRight"></dxi-item>
                  <dxi-item name="deleteColumn"></dxi-item>
              </dxo-toolbar>
              <dxo-media-resizing
                  [enabled]=true>
              </dxo-media-resizing>
          </dx-html-editor>

          <br>
          
          <div class="row">
              <div class="col-6 col-xl-4 mb-2"> 
                  <div class="form-label">{{ 'Core.ValidFrom' | translate:locale.language }}</div>
                  <dx-date-box
                  type="date"
                  [stylingMode]="stylingMode"
                  (onFocusOut)="dateFocusOut($event)"
                  (onEnterKey)="dateFocusOut($event)"
                  (onInitialized)="initDate($event)"
                  [(value)]="editItem.validFrom">
                      <dx-validator>
                          <dxi-validation-rule type="required"></dxi-validation-rule>
                      </dx-validator>
                  </dx-date-box>
              </div>
              <div class="form-group checkbox col-6 col-xl-4 mb-2">
                <div class="form-label">{{ 'Core.IsNewAcceptNeed' | translate:locale.language }}</div>
                <dx-check-box [(value)]="editItem.acceptNeed"></dx-check-box>
             </div>
          </div>
        
          <div class="modal-footer">
              <dx-button
                      stylingMode="contained"
                      text="{{ 'Core.EditSave' | translate:locale.language }}"
                      type="default"
                      useSubmitBehavior=true>
              </dx-button>

              <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
                  {{ 'Core.EditCancel' | translate:locale.language }}
              </button>
          </div>
      </form>
      <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
  </div>
</ng-template>

<!-- Infó -->
<ng-template #termInfo>
    <div class="modal-header">
    <!-- <h5 class="modal-title" id="NewMeasurementLabel">{{ 'Core.EndUserLicenseAgreement' | translate:locale.language }}</h5> -->
    <button type="button" 
            class="btn-close" 
            data-bs-dismiss="modal"
            (click)="closeModal()" 
            aria-label="Close">
    </button>
    </div>
    <div innerHTML="{{editItem.terms}}" class="modal-body"></div>
    <div class="modal-footer">
        <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
            {{ 'Core.Close' | translate:locale.language }}
        </button>
    </div>
</ng-template>

<!-- Csak megtekintéshez -->
<div *ngIf="onlyInfo=='YES' && editItem" class="container">
  <div class="m-3">
    <div class="d-md-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
          <div class="set-icons-sm mb-3 mb-lg-0 me-2">
              <img src="../../../assets/images/set-eula.svg">
          </div>
          <h1 class="display-6 d-block m-0">{{ 'Core.EndUserLicenseAgreement' | translate:locale.language }}</h1>
      </div>
    </div>
    <hr>
    <div innerHTML="{{editItem.terms}}"></div>
  </div>
</div>  



