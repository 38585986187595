import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ErrorService } from '../services/error.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { loadMessages, locale } from 'devextreme/localization';
import CustomStore from 'devextreme/data/custom_store';

import { first } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Ads, AdsService } from '../services/ads.service';
import { RolesFunc } from '../services/webapiclient.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { convertBlobToBase64 } from '../shared/utils';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog-service';

@Component({
  selector: 'app-ads',
  templateUrl: './ads.component.html',
  styleUrls: ['./ads.component.scss']
})
export class AdsComponent implements OnInit, OnDestroy {
  @ViewChild('editFormtmpl', { static: true }) editFormtmpl: TemplateRef<any>;

  modalRef: BsModalRef;

  stylingMode = "filled"
  dateFormat: string;
  urlPattern = "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})"

  adsList: Ads[] = {} as Ads[];
  editItem: Ads = {} as Ads;
  error: string;
  baseUri: string = `${environment.apiUrl}`;
  uploadUrl = `${environment.uploadUrl}`;
  dataSource: any;
  private translSubs$: Subscription;

  //Kép tárolása
  blobFileSmall: Blob;
  blobUrlSmall: string;
  blobFileBig: Blob;
  blobUrlBig: string;
  pictureDataSmall: any;
  pictureDataBig: any;
  pictureExtension: string;
  loading: boolean = false;
  addNew: boolean=false;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private adsService: AdsService,
    private rolesFunc: RolesFunc,
    private modalService: BsModalService,
    private errorService: ErrorService,
    private translation: L10nTranslationService,
    private http: HttpClient,
    private confirmDialogService: ConfirmDialogService
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

  }


  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });
    this.getAll();
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  clickNew() {
    this.editItem = <Ads>{};
    this.editItem.forDoctor = false;
    this.blobUrlSmall = '';
    this.blobUrlBig = '';
    this.addNew = true;
    this.modalRef = this.modalService.show(this.editFormtmpl, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }


  closeModal() {
    this.modalRef.hide();
  }

  clickEdit(index) {
     this.editItem = this.adsList[index];
     this.blobUrlSmall = this.uploadUrl + this.editItem.imgSmall;
     this.blobUrlBig = this.uploadUrl + this.editItem.imgBig;
     this.addNew = false;
     this.modalRef = this.modalService.show(this.editFormtmpl, { class: 'modal-lg', backdrop: 'static', keyboard: false });
    }

  clickDelete(id) {
    const that = this;
    this.confirmDialogService.confirmThis(this.translation.translate('Core.ConfirmDeleting'), function () {
      that.adsService.deleteData(id).pipe(first()).subscribe(
        (p) => {
          that.getAll();
        }

      );

    }, function () {
      //alert("No clicked");  
    })
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.adsService.getAll().pipe(first()).subscribe(
        (p) => {
          this.adsList = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(this.adsList);
        }
      );
    });
  }

  onFormSubmit = function (e) {
    if (!this.pictureDataBig && this.addNew && this.editItem.pos == 0) {
      this.error = "Kérem töltse fel a nagy képet!";
      this.loading = false;
      return;
    }
    if (!this.pictureDataSmall && this.addNew && this.editItem.pos != 0) {
      this.error = "Kérem töltse fel a kis képet!";
      this.loading = false;
      return;
    }
    this.error = "";
    this.loading = true;
    this.editItem.imgSmall = this.pictureDataSmall;
    this.editItem.imgBig = this.pictureDataBig;

    if(this.addNew){
      this.adsService.insert(JSON.stringify(this.editItem)).then(
        result => {
          if (result == "OK") {
            this.closeModal();
          } else {
            this.error = result;
          }
          this.loading = false;
          this.blobUrlBig = "";
          this.blobFileSmall = "";
          this.pictureDataSmall = undefined;
          this.pictureDataBig = undefined;
          this.getAll();
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    }else{
      this.adsService.update(this.editItem.id, JSON.stringify(this.editItem)).then(
        result => {
          if (result == "OK") {
            this.closeModal();
          } else {
            this.error = result;
          }
          this.loading = false;
          this.blobUrlBig = "";
          this.blobFileSmall = "";
          this.pictureDataSmall = undefined;
          this.pictureDataBig = undefined;
          this.getAll();
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });

    }
    

    e.preventDefault();
  }

  async onFileChangeSmall(event: any) {
    const files = event.target.files as FileList;

    if (files.length > 0) {
      const _file = URL.createObjectURL(files[0]);
      this.blobUrlSmall = _file;
      this.blobFileSmall = await fetch(this.blobUrlSmall).then(r => r.blob());
      this.pictureDataSmall = await convertBlobToBase64(this.blobFileSmall);
      //this.editItem.avatarName = files[0].name;
      this.resetInputSmall();
    }
  }

  resetInputSmall() {
    const input = document.getElementById('small-picture') as HTMLInputElement;
    if (input) {
      input.value = "";
    }
  }
  async onFileChangeBig(event: any) {
    const files = event.target.files as FileList;

    if (files.length > 0) {
      const _file = URL.createObjectURL(files[0]);
      this.blobUrlBig = _file;
      this.blobFileBig = await fetch(this.blobUrlBig).then(r => r.blob());
      this.pictureDataBig = await convertBlobToBase64(this.blobFileBig);
      //this.editItem.avatarName = files[0].name;
      this.resetInputBig();
    }
  }

  resetInputBig() {
    const input = document.getElementById('big-picture') as HTMLInputElement;
    if (input) {
      input.value = "";
    }
  }
  onShown() {
    setTimeout(() => {
      this.loading = false;
    }, 15000);
  }

}
