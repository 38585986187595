import { APP_INITIALIZER, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { BadgesViewModel, GameResult, Img2Post } from '../shared/gameResult';
import { AgeAndDegree, ColorRecognitionSettingsDTO, MemoryGameSettingsDTO, SDMTSettingsDTO, ShapeRecallSettingsDTO, TypoCorrector, WordRecallSettingsDTO, WordsGuessDTO, WordsGuessSettingsDTO } from '../shared/constants';
import { L10nTranslationService } from 'angular-l10n';
import { StringResult } from 'src/app/module-thryve/thryve.service';
import { DensityDTO, DensityData, TaskEvaluation } from 'src/app/services/webapiclient.service';
import { first } from 'rxjs/operators';
import { ErrorService } from 'src/app/services/error.service';

export interface resultObject {
  name: string;
  style: string;
}

@Injectable({
  providedIn: 'root'
})
export class GameService {
  baseUri = `${environment.apiUrl}`;
  resultObjects: resultObject[];


  constructor(private http: HttpClient,
    public translation: L10nTranslationService,
    private errorService: ErrorService

  ) {
    this.resultObjects = [
      {
        "name": this.translation.translate('Game.Weak'),
        "style": 'bg-danger text-white'
      },
      {
        "name": this.translation.translate('Game.Medium'),
        "style": 'bg-info'
      },
      {
        "name": this.translation.translate('Game.Excellent'),
        "style": 'bg-success'
      },
      {
        "name": this.translation.translate('Game.Problem'),
        "style": 'bg-danger text-white'
      },
      {
        "name": this.translation.translate('Game.Mild'),
        "style": 'bg-info'
      },
      {
        "name": this.translation.translate('Game.Normal'),
        "style": 'bg-success'
      }
    ];

  }


  public get GameResultObjects(): resultObject[] {
    return this.resultObjects;
  }

  /**
   * Feladatlap nyomtatása.
   *
   * @param taskId 
   */
  printTaskSheet(taskId: number): Observable<any> {
    return this.http.get(this.baseUri + 'Games/GetTaskSheetReport?taskId=' + encodeURIComponent(taskId),{ responseType: "arraybuffer" });
  }

  /**
   * Elküldjük a játék eredményét a szerverre.
   *
   * @param data A játék eredménye
   *
   * @returns Observable
   */
  send(data: GameResult): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };
    return this.http.post(this.baseUri + 'Games/SaveResult', data, options);
  }

  /**
   * Csak az img2 módosítása a játék eredményénél.
   *
   * @param data A teszt png képének elérési útvonala
   *
   * @returns Observable
   */
  saveImg2(data: Img2Post): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };
    return this.http.post(this.baseUri + 'Games/SaveImg2', data, options);
  }  

  /**
   * A szókitaláló számára lekérdezzük a használható szavakat.
   */
  getWords(type: string): Observable<WordsGuessDTO[]> {
    return this.http.get<WordsGuessDTO[]>(this.baseUri + 'Games/GetWords/' + type);
  }

  /**
   * A szókitaláló számára lekérdezzük a használható betűket.
   */
  getLetters(type: string, mode: string): Observable<string[]> {
    return this.http.get<string[]>(this.baseUri + 'Games/GetLetters/' + type + '/' + mode);
  }

  /**
   * A szókitaláló számára lekérdezzük a használható betűket.
   *
   * @param type A játék típusa (WordGuess)
   */
  getWordGuessSettings(type: string): Observable<WordsGuessSettingsDTO> {
    return this.http.get<WordsGuessSettingsDTO>
      (this.baseUri + 'Games/GetWordGuessSettings/' + type);
  }

  /**
   * A szó visszaidéző számára lekérdezzük a beállításokat.
   *
   * @param type A játék típusa (WordRecall)
   */
  getWordRecallSettings(type: string): Observable<WordRecallSettingsDTO> {
    return this.http.get<WordRecallSettingsDTO>
      (this.baseUri + 'Games/GetWordRecallSettings/' + type);
  }

  /**
    * A szó visszaidéző számára lekérdezzük a gépelési hibák javításához
    * sükséges szópárokat
    * 
    */
  getTypoCorrector(): Observable<TypoCorrector[]> {
    return this.http.get<TypoCorrector[]>
      (this.baseUri + 'Games/GetTypoCorrector');
  }

  /**
    * A szó visszaidéző számára lekérdezzük a gépelési hibák javításához
    * sükséges szópárokat
    * 
    */
  correctWord(word: string): Observable<StringResult> {
    return this.http.get<StringResult>
      (this.baseUri + 'Games/CorrectWord?word=' + encodeURIComponent(word));
  }

  /**
   * A szókitaláló számára lekérdezzük a használható betűket.
   *
   * @param type A játék típusa (WordGuess)
   */
  getShapeRecallSettings(): Observable<ShapeRecallSettingsDTO> {
    return this.http.get<ShapeRecallSettingsDTO>
      (this.baseUri + 'Games/GetShapeRecallSettings/ShapeRecall');
  }

  /**
   * A szókitaláló számára lekérdezzük a használható betűket.
   *
   * @param type A játék típusa (WordGuess)
   */
  getSdmtSettings(): Observable<SDMTSettingsDTO> {
    return this.http.get<SDMTSettingsDTO>(this.baseUri + 'Games/GetSdmtSettings/SDMT');
  }

  /**
   * Szín felismerő számára lekérdezzük a beállításokat.
   *
   * @param type A játék típusa (ColorRecognition)
   */
  getColorRecognitionSettings(): Observable<ColorRecognitionSettingsDTO> {
    return this.http.get<ColorRecognitionSettingsDTO>(this.baseUri + 'Games/GetColorRecognitionSettings/ColorRecognition');
  }


  /**
   * A szókitaláló számára lekérdezzük a használható betűket.
   *
   * @param type A játék típusa (WordGuess)
   */
  getMemoryGameSettings(): Observable<MemoryGameSettingsDTO> {
    return this.http.get<MemoryGameSettingsDTO>(this.baseUri + 'Games/GetMemoryGameSettings/MemoryGame');
  }

  /**
   * Randomize array in-place using Durstenfeld shuffle algorithm.
   * https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
   */
  shuffleArray(array: any) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  }

  /**
   * Kiszámoljuk a szókitaláló eredményét.
   */
  getWordGuessResult(wordWeight: number, wordUniqueLetters: number,
    correctGuess: number, wrongGuess: number,
    elapsedTime: number, maxTime: number) {
    // Idő
    // A felnemhasznált idő osztva a maximális idővel.
    const time = (maxTime - elapsedTime) / maxTime;

    // Hatékonyság
    const efficiency = correctGuess / (correctGuess + wrongGuess);

    // Pontosság
    let accuracy = 0;
    if (correctGuess > 0) {
      accuracy = correctGuess / wordUniqueLetters;
    }

    // Ha a pontosság nulla (nincs eltalált betű), akkor az eredmény is nulla.
    const result = accuracy === 0 ? 0 : (wordWeight + 1) * (efficiency + 1) * (accuracy + 1);

    return result;
  }
  /**
   * Az sdmt teszthez szükséges alapadatok (age, degree).
   */
  getAgeAndDegree(patientId: string): Observable<AgeAndDegree> {
    return this.http.get<AgeAndDegree>(this.baseUri + 'Games/GetAgeAndDegree?patientId=' + encodeURIComponent(patientId));
  }
  /**
    * Feladatlapnál melyik az utolsó befejezett feladat
    * Ha van ilyen, akkor a következőtól kell folytatni a kitöltést
    */
  getLastTaskOfSheet(taskId: number): Observable<StringResult> {
    return this.http.get<StringResult>(this.baseUri + 'Games/GetLastTaskOfSheet?taskId=' + encodeURIComponent(taskId));
  }

  /**
   * Visszaadja a következő elvégzendő feladat dátumát, ha van
   * ha nincs akkor null
   */
  getNextDueDate(taskId: number): Observable<Date> {
    return this.http.get<Date>(this.baseUri + 'Games/GetNextDueDate?taskId=' + encodeURIComponent(taskId));
  }

  /**
    * Egy feladatlaphoz tartozó játékok eredményét kérdezi le.
    */
  getTaskResults(taskId: number): Observable<TaskEvaluation[]> {
    return this.http.get<TaskEvaluation[]>(this.baseUri + 'Games/GetTaskResults?taskId=' + encodeURIComponent(taskId));
  }

  upload(myFile: FormData, Id: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'Games/Upload?Id=' + Id, myFile, {
      observe: 'response', responseType: 'text'
    });
  }

  async uploadFile(myFile: FormData, Id: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.upload(myFile, Id).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === 'OK') {
            resolve('OK');
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  getDensity(gameType: string, doctorId: string, patientId?: string): Observable<DensityData> {
    return this.http.get<DensityData>(this.baseUri + 'Analysis/GetDensity?gameType=' + encodeURIComponent(gameType) + '&doctorId=' + doctorId + '&patientId=' + patientId);
  }

  getBadges(patientId: string): Observable<BadgesViewModel> {
    return this.http.get<BadgesViewModel>
      (this.baseUri + 'Games/GetBadges?patientId=' + encodeURIComponent(patientId));
  }


}
