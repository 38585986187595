<!-- Megjelenítés -->
<div class="container">
    <div class="m-3">
    <div class="d-md-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
            <div class="measurement-icons-sm mb-3 mb-lg-0 me-2">
                <img src="../../../assets/images/measurement-heart.svg">
            </div>
            <h3 class="display-6 d-block m-0">{{ 'Project.BloodPressureData' | translate:locale.language }}</h3>
        </div>
        
        <div>
            <button type="button" class="btn btn-success" (click)="clickNew()">
                <i class="bi bi-plus"></i> {{ 'Project.NewMeasurement' | translate:locale.language }}
            </button>
        </div>
    </div>
    <hr>
    <!-- Szelekciók -->
    <div class="d-lg-flex justify-content-between mb-3">
        <ul class="nav nav-pills rounded-selector mb-3 mb-lg-0">
            <li class="nav-item">
                <a [ngClass]="selectedOption.value == 30 ? 'nav-link active' : 'nav-link '"
                    (click)="onOptionChanged(30,0)" aria-current="page" href="javascript:void(0)"><span>30 {{
                        'Core.Days' | translate:locale.language
                        }}</span></a>
            </li>
            <li class="nav-item">
                <a [ngClass]="selectedOption.value == 90 ? 'nav-link active position-relative' : 'nav-link position-relative'"
                    (click)="onOptionChanged(90,1)" href="javascript:void(0)">
                    <span>90 {{ 'Core.Days' | translate:locale.language }}</span>
                </a>
            </li>
            <li class="nav-item">
                <a [ngClass]="selectedOption.value == 180 ? 'nav-link active position-relative' : 'nav-link position-relative'"
                    (click)="onOptionChanged(180,2)" href="javascript:void(0)">
                    <span>180 {{ 'Core.Days' | translate:locale.language }}</span>
                </a>
            </li>
            <li class="nav-item">
                <a [ngClass]="selectedOption.value == 365 ? 'nav-link active position-relative' : 'nav-link position-relative'"
                    (click)="onOptionChanged(365,3)" href="javascript:void(0)">
                    <span>365 {{ 'Core.Days' | translate:locale.language }}</span>
                </a>
            </li>
            <li class="nav-item">
                <a [ngClass]="selectedOption.value == 0 ? 'nav-link active position-relative' : 'nav-link position-relative'"
                    role="button" aria-expanded="false" aria-controls="customDate" (click)="onOptionChangedCustom()"
                    href="javascript:void(0)">
                    {{ 'Core.Custom' | translate:locale.language }}
                </a>
            </li>
        </ul>
        <!-- <div class="d-flex">
            <ul class="nav nav-pills rounded-selector me-2">
                <li class="nav-item d-flex align-items-center px-2">
                    <div class="me-1"> {{ 'Core.Allx' | translate:locale.language }}</div>
                    <dx-check-box [(value)]="showAll" (onValueChanged)="onShowAll($event)"></dx-check-box>

                </li>
            </ul>
        </div> -->
    </div>
    <div *ngIf="customDate">
        <div class="d-md-flex justify-content-between align-items-top">
            <div class="me-3 mb-2">
                <dx-date-box type="date" [stylingMode]="stylingMode" (onFocusOut)="startDateFocusOut($event)"
                    (onEnterKey)="startDateFocusOut($event)" (onInitialized)="initStartDate($event)"
                    [(value)]="startDate">
                    <dx-validator>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                        <dxi-validation-rule type="range" [max]="maxDate"></dxi-validation-rule>
                    </dx-validator>
                </dx-date-box>
            </div>

            <div class="me-3 mb-2">
                <dx-date-box type="date" [stylingMode]="stylingMode" (onFocusOut)="endDateFocusOut($event)"
                    (onEnterKey)="endDateFocusOut($event)" (onInitialized)="initEndDate($event)" [(value)]="endDate">
                    <dx-validator>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                        <dxi-validation-rule type="range" [max]="maxDate"></dxi-validation-rule>
                    </dx-validator>
                </dx-date-box>
            </div>

            <button type="button" class="btn btn-primary mb-2" (click)="onQuery()">
                <i class="bi bi-search"></i> {{ 'Core.Query' | translate:locale.language }}
            </button>

        </div>
    </div>

    <!-- Chart Megjelenítés -->
    <app-blood-pressure-chart [inpPatientId]="patientId" [startDate]="startDate" [endDate]="endDate"></app-blood-pressure-chart>

    <div class="mt-3">
        <!-- <small *ngIf="thryveConnected && !inpPatientId" class="fw-bold text-success">{{ 'Thryve.LastRefreshDate' |
            translate:locale.language }}: {{lastRefreshDate}}</small> -->
        <table class="table text-center ">
            <thead>
                <tr>
                    <th style="min-width:100px"></th>
                    <th>{{ 'Core.Date' | translate:locale.language }}</th>
                    <th>{{ 'Project.BloodPressure' | translate:locale.language }}</th>
                    <th>{{ 'Project.Pulse' | translate:locale.language }}</th>
                    <th>{{ 'Thryve.Source' | translate:locale.language }}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody class="bg-white">
                <tr *ngFor="let item of dataSource; index as i">
                    <td class="text-start">
                        <a *ngIf="item.dataSource == 'Manual'" (click)="clickDelete(i)" class="h4 text-danger me-4 me-lg-3 pointer">
                            <i class="bi bi-trash"></i>
                        </a>
                        <a *ngIf="item.dataSource == 'Manual'" (click)="clickEdit(i)" class="h4 text-primary pointer">
                            <i class="bi bi-pencil"></i>
                        </a>
                    </td>
                    <td>{{item.measurementDate | date:'yyyy-MM-dd HH:mm'}}</td>
                    <td>{{ item.bloodPressureValue }}</td>
                    <td>{{ item.pulse }}</td>
                    <td *ngIf="item.dataSource == 'Manual'">{{ 'Category.' + item.dataSource | translate:locale.language}} </td>
                    <td *ngIf="item.dataSource != 'Manual'">{{ item.dataSource }} </td>
                    <td *ngIf="item.category == 'low'" class="bg-info p-2">
                        {{ 'Category.' + item.category | translate:locale.language}}</td>
                    <td *ngIf="item.category == 'high'" class="bg-danger text-white p-2" >
                        {{ 'Category.' + item.category | translate:locale.language}}</td>
                    <td *ngIf="item.category == 'Normal'"></td>
                    
                   
                </tr>
            </tbody>
        </table>
    </div>
    </div>
</div>
<!-- Új Modal -->
<ng-template #newMeasurement>
    <div class="modal-header">
        <h5 class="modal-title" id="NewMeasurementLabel">{{ 'Project.NewMeasurement' | translate:locale.language }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <form action="your-action" (submit)="onFormSubmit($event,0)">
            <div class="row">
                <div class="col-md-4 mb-3 align-self-end">
                    <div>
                        <div class="form-label">{{ 'Core.Date' | translate:locale.language }}
                        </div>
                        <dx-date-box type="datetime" [stylingMode]="stylingMode" [(value)]="editItem.measurementDate">
                            <dx-validator>
                                <dxi-validation-rule type="required"></dxi-validation-rule>
                                <dxi-validation-rule type="range" [max]="maxDate"></dxi-validation-rule>
                            </dx-validator>
                        </dx-date-box>
                    </div>
                </div>
                <ng-container *ngTemplateOutlet="fieldsTemplate"></ng-container>
            </div>
        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</ng-template>

<!-- Szerkesztés Modal -->
<ng-template #editMeasurement>
    <div class="modal-header">
        <h5 class="modal-title" id="EditMeasurementLabel">{{ 'Core.DataEdit' | translate:locale.language }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <form action="your-action" (submit)="onFormSubmit($event,0)">
            <div class="row">
                <ng-container *ngTemplateOutlet="fieldsTemplate"></ng-container>
            </div>
        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</ng-template>


<ng-template #fieldsTemplate>
    <div class="col-md-3 mb-3 align-self-end">
        <div>
            <div class="form-label">{{ 'Project.BloodPressure' | translate:locale.language }}
            </div>
            <dx-text-box type="string" [stylingMode]="stylingMode" [(value)]="editItem.bloodPressureValue"
                mask="009/009" [useMaskedValue]="true">
                <dx-validator>
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </div>
    </div>
    <div class="col-md-2 mb-3 align-self-end">
        <div>
            <div class="form-label">{{ 'Project.Pulse' | translate:locale.language }}
            </div>
            <dx-number-box [stylingMode]="stylingMode" [(value)]="editItem.pulse">
                <dx-validator>
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dx-validator>
            </dx-number-box>
        </div>
    </div>

    <div class="modal-footer">
        <dx-button stylingMode="contained" text="{{ 'Core.EditSave' | translate:locale.language }}" type="default"
            useSubmitBehavior=true>
        </dx-button>

        <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
            {{ 'Core.EditCancel' | translate:locale.language }}
        </button>
    </div>

</ng-template>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>