import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { JwtInterceptor } from '../helpers/jwt.interceptor';
import { ErrorInterceptor } from '../helpers/error.interceptor';

import { l10nConfig, initL10n, LocaleValidation, AppStorage, HttpTranslationLoader } from '../l10n-config';
import { L10nTranslationModule, L10nIntlModule } from 'angular-l10n';
import {PatientService} from './patient.service';

import { DxMenuModule, DxSelectBoxModule, DxCheckBoxModule, DxValidatorModule, DxValidationSummaryModule, DxTextBoxModule, DxFormModule, DxLoadPanelModule, DxRadioGroupModule, DxSparklineModule, DxValidationGroupModule, DxFileUploaderModule, DxPopupModule } from 'devextreme-angular';
import { DxDataGridModule,DxTextAreaModule,DxButtonModule,
   DxBulletModule,
   DxTemplateModule, DxListModule, DxSpeedDialActionModule, DxDateBoxModule, DxNumberBoxModule } from 'devextreme-angular';

import { PatientProfileComponent } from './patient-profile/patient-profile.component';
import { PatientHealthDataComponent } from './patient-health-data/patient-health-data.component';
import { PatientAddictionsComponent } from './patient-addictions/patient-addictions.component';
import { PatientDetailsComponent } from './patient-details/patient-details.component';
import { ThryveModule } from '../module-thryve/thryve.module';
import { SharedModule } from '../shared/shared.module';
import { MeasuredDataComponent } from './measured-data/measured-data.component';
import { LabDataComponent } from './lab-data/lab-data.component';
import { SmartSetComponent } from './smart-set/smart-set.component';
import { PatientTasksComponent } from './patient-tasks/patient-tasks.component';
import { EvaluationComponent } from '../module-clinic/evaluation/evaluation.component';
import { NotesComponent } from './notes/notes.component';
import { StatisticsModule } from '../statistics/statistics.module';
import { BloodOxygenComponent } from './blood-oxygen/blood-oxygen.component';
//import dxFileUploader from 'devextreme/ui/file_uploader';


@NgModule({
  declarations: [
    PatientProfileComponent,
    PatientHealthDataComponent,
    PatientAddictionsComponent,
    PatientDetailsComponent,
    MeasuredDataComponent,
    LabDataComponent,
    SmartSetComponent,
    PatientTasksComponent,
    EvaluationComponent,
    NotesComponent,
    BloodOxygenComponent
  ],
  exports: [
    PatientDetailsComponent,
    PatientProfileComponent,
    MeasuredDataComponent,
    LabDataComponent,
    SmartSetComponent,
    PatientTasksComponent,
    EvaluationComponent,
    NotesComponent,
    BloodOxygenComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    L10nTranslationModule.forRoot(
      l10nConfig,
      {
          storage: AppStorage,
          translationLoader: HttpTranslationLoader
      }
    ),
    L10nIntlModule,
    DxMenuModule,
    DxListModule,
    DxTextAreaModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    DxRadioGroupModule,
    DxDateBoxModule,
    DxButtonModule,
    DxFileUploaderModule,
    DxNumberBoxModule,
    DxValidatorModule,
    DxPopupModule,
    DxValidationGroupModule,
    DxSpeedDialActionModule,
    DxValidationSummaryModule,
    DxTextBoxModule,
    DxFormModule,
    DxLoadPanelModule,
    DxSparklineModule,
    SharedModule,
    ThryveModule,
    StatisticsModule
  ],
  providers: [
     { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
     { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
     PatientService
   ]

})
/**
 * Péciens adatainak kezelését tartalmazó modul
 */

export class PatientModule { }
