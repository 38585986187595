import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import {
  DiagnosisFunc,
  Diagnosis,
  MicResultLink
} from '../services/webapiclient.service';
import { ErrorService } from '../services//error.service';

@Injectable({
  providedIn: 'root'
})
export class ClinicService {

  constructor(
    private diagnosisFunc: DiagnosisFunc,
    private errorService: ErrorService

  ) { }

  async getAll(id: string) {
    let diagnosis: Diagnosis[] = {} as Diagnosis[];
    return new Promise((resolve, reject) => {
      this.diagnosisFunc.getAll(id).pipe(first()).subscribe(
        (p) => {

          diagnosis = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(diagnosis);
        }
      );
    });
  }


  async updateDiagnosis(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.diagnosisFunc.update(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  async insertDiagnosis(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.diagnosisFunc.insert(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  //mic links
  async getLinks(id: string) {
    let micResultLink: MicResultLink[] = {} as MicResultLink[];
    return new Promise((resolve, reject) => {
      this.diagnosisFunc.getLinks(id).pipe(first()).subscribe(
        (p) => {

          micResultLink = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(micResultLink);
        }
      );
    });
  }

  async insertLink(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.diagnosisFunc.insertLink(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  async deleteLink(micResultId: string, diagnosisId: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.diagnosisFunc.deleteLink(micResultId, diagnosisId).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

}
