import { Component, Inject, OnInit } from '@angular/core';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { GameService } from 'src/app/games/services/game.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CodeWarehouseEditModel, CodesFunc } from 'src/app/services/codes.service';
import { DensityDTO, DensityData, ZscoreDTO } from 'src/app/services/webapiclient.service';

@Component({
  selector: 'app-density',
  templateUrl: './density.component.html',
  styleUrls: ['./density.component.scss']
})
export class DensityComponent implements OnInit {

  dataSource: DensityDTO[];
  patientSource: ZscoreDTO[];
  densityData: DensityData;
  taskSource: CodeWarehouseEditModel[];
  loading: boolean = false;
  selectedGameId: number;
  selectedPatientId: string = '';
  selectedPatient: ZscoreDTO;
  gameType: string;
  currentUser: any;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public gameService: GameService,
    private codesFunc: CodesFunc,
    private authenticationService: AuthenticationService

  ) {
  }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;
    this.loadCodesSources();
  }

  loadDataSource() {
    this.loading = true;
    this.gameService.getDensity(this.gameType, this.currentUser.id, this.selectedPatientId).subscribe((data) => {
      this.densityData = data;
      this.dataSource = this.densityData.densityList;
      this.patientSource = this.densityData.zscoreList;
      this.loading = false;
    });

  }

  //Kódok betöltése
  loadCodesSources() {
    this.loading = true;
    this.codesFunc.getCodesData('Games').then(
      result => {
        this.taskSource = <CodeWarehouseEditModel[]>result;
        this.taskSource = this.taskSource.filter(f => f.active == true && f.customerModified);
        this.loading = false;
      }
    ).catch(err => {
      this.loading = false;
      //this.error = err;
    });



  }

  customizeTooltip = (info: any) => {
    return {
      html: "<div><div class='tooltip-header'>" +
      info.argument.toFixed(2) + "</div></div>"
    };
  }

  onShown() {
    setTimeout(() => {
      this.loading = false;
    }, 15000);
  }

  onValueChanged(e: any) {
    let item = this.taskSource.find(f => f.id == e.value);
    this.gameType = item.applicationReference;
    switch (this.gameType) {
      case 'SDMT-GAME': {
        this.gameType = 'SDMT';
        break;
      }
      case 'ClassicPair': {
        this.gameType = 'MemoryGameClassic';
        break;
      }
      case 'SemanticPair': {
        this.gameType = 'MemoryGameSemantic';
        break;
      }
      case 'MathematicalPair': {
        this.gameType = 'MemoryGameMathematical';
        break;
      }
    }
    this.loadDataSource();
  }

  onPatientChanged(e: any) {
    this.loadDataSource();
  }  
}
