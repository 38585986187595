import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'app-gdpr',
  templateUrl: './gdpr.component.html',
  styleUrls: ['./gdpr.component.scss']
})
export class GdprComponent implements OnInit {
  infoPanel: SafeHtml;
  htmlString: string;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private http: HttpClient,
    private sanitizer: DomSanitizer
    
  ) { }

  ngOnInit(): void {
    this.getGdprContent();
  }

  getGdprContent() {
    let fileName = "gdpr";
    fileName += '.' + this.locale.language + '.html';
    const path = 'assets/gdpr/' + fileName;
    this.http.get(path, { responseType: 'text' }).subscribe(
      data => {
        this.htmlString = data;
        this.infoPanel = this.sanitizer.bypassSecurityTrustHtml(this.htmlString);
      });
  }  
}
