import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';

export interface BoolResult {
  result?: boolean;
  error?: string;
}
export interface StringResult {
  result?: string;
  error?: string;
}

export interface ThryveResult {
  thryveDataList: ThryveDailyData[];
  error?: string;
}
export interface ThryveDailyData {
  id: string;
  PatientDataId: string;
  DataSource: string;
  CreatedAt: Date;
  DayAt: Date;
  StartTime?: Date;
  EndTime?: Date;
  CreatedOn: Date;
  ActivityTypeCodeId: number;
  Code: string;
  Value: number;
  selected: boolean;
  active: boolean;
}
export interface SmartDevices {
  Id: string;
  Description: string;
}
export interface Spo2ViewModel {
  patientDataId: string;
  DataSource: string;
  dayAt: Date;
  startTime: Date;
  endTime: Date;
  sleepType: string;
  spo2: number;
}

export interface SleepAndSpo2ViewModel {
  patientDataId: string;
  phaseStart: Date;
  sleepType: string;
  rem: number;
  deep: number;
  mid: number;
  light: number;
  awake: number;
  spo2: number;
}
export interface SleepViewModel {
  patientDataId: string;
  dayAt: string;
  sleepType: string;
  sleepDuration: number;
  sleepDurationHour: number;
  formattedTime: string;
  sleepTypeTxt: string;
}
export interface PoolDevicesViewModel {
  deviceId: number;
  deviceName: string;
  ownerId: string;
  providerId: number;
  IssueStart?: Date;
  IssueEnd?: Date;
}

export interface ThryveCodesAssign {
  id: number;
  patientId: string;
  codeId: number;
  deviceId: number;
  active: boolean;
}
export interface ThryveCodesAssignEditModel {
  codeId: number;
  thryveDevices: ThryveDevice[];
}
export interface ThryveDevice {
  deviceId: number;
  device: string;
  active: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ThryveService {
  baseUri: string = `${environment.apiUrl}`;
  constructor(private http: HttpClient,
    private errorService: ErrorService
  ) {
  }

  checkThryveConnection(id: string): Observable<BoolResult> {
    return this.http.get<BoolResult>(this.baseUri + 'Thryve/CheckThryveConnection?id=' + encodeURIComponent(id));
  }

  thryveConnect(): Observable<StringResult> {
    return this.http.get<StringResult>(this.baseUri + 'Thryve/ThryveConnect');
  }

  thryveSources(): Observable<StringResult> {
    return this.http.get<StringResult>(this.baseUri + 'Thryve/ThryveSources');
  }

  syncDeviceAssign(): Observable<StringResult> {
    return this.http.get<StringResult>(this.baseUri + 'Thryve/SyncDeviceAssign');
  }

  refresh(): Observable<StringResult> {
    return this.http.get<StringResult>(this.baseUri + 'Thryve/Refresh');
  }

  getUserData(userId: string): Observable<ThryveResult> {
    return this.http.get<ThryveResult>(this.baseUri + 'Thryve/GetUserData?userId=' + encodeURIComponent(userId));
  }

  getSmartDevices(): Observable<SmartDevices[]> {
    return this.http.get<SmartDevices[]>(this.baseUri + 'Thryve/GetSmartDevices');
  }
  getSpo2Data(id: string): Observable<Spo2ViewModel[]> {
    return this.http.get<Spo2ViewModel[]>(this.baseUri + 'Thryve/GetSpo2Data?userId=' + encodeURIComponent(id));
  }
  getSleepAndSpo2Data(id: string, day: string): Observable<SleepAndSpo2ViewModel[]> {
    return this.http.get<SleepAndSpo2ViewModel[]>(this.baseUri + 'Thryve/GetSleepAndSpo2Data?userId=' + encodeURIComponent(id) + '&day=' + encodeURIComponent(day));
  }
  getSleepData(id: string, startDate: string, endDate: string): Observable<SleepViewModel[]> {
    return this.http.get<SleepViewModel[]>(this.baseUri + 'Thryve/GetSleepData?userId=' + encodeURIComponent(id) + '&startDate=' + encodeURIComponent(startDate)  + '&endDate=' + encodeURIComponent(endDate));
  }

  checkDeviceSharing(userId: string): Observable<BoolResult> {
    return this.http.get<BoolResult>(this.baseUri + 'Thryve/CheckDeviceSharing?userId=' + encodeURIComponent(userId));
  }
  setProvider(userId: string, share: boolean): Observable<BoolResult> {
    return this.http.get(this.baseUri + 'Thryve/setProvider?userId=' + encodeURIComponent(userId) + '&share=' + encodeURIComponent(share));
  }
  getLastMeasurement(userId: string): Observable<ThryveResult> {
    return this.http.get<ThryveResult>(this.baseUri + 'Thryve/GetLastMeasurement?userId=' + encodeURIComponent(userId));
  }

  saveMeasurements(userId: string, values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'Thryve/SaveMeasurements?userId=' + encodeURIComponent(userId), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  getPoolDevicesList(doctorId: string, patientId: string): Observable<PoolDevicesViewModel[]> {
    return this.http.get<PoolDevicesViewModel[]>(this.baseUri + 'Thryve/GetPoolDevicesList?doctorId=' + encodeURIComponent(doctorId) + '&patientId=' + encodeURIComponent(patientId));
  }

  issueDevice(doctorId: string, patientId: string, values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'Thryve/IssueDevice?doctorId=' + encodeURIComponent(doctorId) + '&patientId=' + encodeURIComponent(patientId), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  takeBackDevice(doctorId: string, patientId: string, values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'Thryve/TakeBackDevice?doctorId=' + encodeURIComponent(doctorId) + '&patientId=' + encodeURIComponent(patientId), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  getThryveCodesAssign(patientId: string): Observable<ThryveCodesAssignEditModel[]> {
    return this.http.get<ThryveCodesAssignEditModel[]>(this.baseUri + 'Thryve/getThryveCodesAssign?userId=' + encodeURIComponent(patientId));
  }

  setCodesAssign(id: string, values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'Thryve/SetThryveCodesAssign?userId=' + encodeURIComponent(id), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }


  //////////////////////////

  async saveSelectedMeasurements(userId: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.saveMeasurements(userId, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  async issueSelectedDevice(doctorId: string, patientId: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.issueDevice(doctorId, patientId, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  async takeBackSelectedDevice(doctorId: string, patientId: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.takeBackDevice(doctorId, patientId, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  //Thryve kategória hozzárendelések eszközökhöz

  async getThryveCodesAssignList(patientId: string) {
    let thryveCodesAssign: ThryveCodesAssignEditModel[] = {} as ThryveCodesAssignEditModel[];
    return new Promise((resolve, reject) => {
      this.getThryveCodesAssign(patientId).pipe(first()).subscribe(
        (p) => {
          thryveCodesAssign = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(thryveCodesAssign);
        }
      );
    });
  }

  async setThryveCodesAssign(id: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.setCodesAssign(id, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

}
