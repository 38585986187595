import { Component, Input, OnInit } from '@angular/core';
import { L10nTranslationService } from 'angular-l10n';
import { RecallHeatMapResult, WordRecallResult } from 'src/app/games/shared/gameResult';

@Component({
  selector: 'app-replay-recall-words',
  templateUrl: './replay-recall-words.component.html',
  styleUrls: ['./replay-recall-words.component.scss']
})
export class ReplayRecallWordsComponent implements OnInit {

  /**
  * Játék eredményei.
  */
  @Input() wrResult: WordRecallResult;

  /**
  * Fordulók száma.
  */
  @Input() roundCount: number;

  heatMap: RecallHeatMapResult[] = <RecallHeatMapResult[]>{};
  showHeatMap: boolean = false;
  initHeatMap: boolean = true;

  myInterval: any; // A visszaszámolás (setInterval) változója.
  pageOfItems: Array<any>;

  roundItems: number[];

  chartDataSource: any[] = [];

  bgColor: string[] = ['white', '#DEEFE9', '#B4F1DB', '#82F2C9', '#3AEDA8', '#0EDB8C'];

  //Progressbar változók
  //Kitalált szavak száma
  correctWordsCount: number;
  //Éppen hányadikat írjuk ki
  actualCount: number;

  label: string;

  constructor(
    private translation: L10nTranslationService
  ) { }

  ngOnInit(): void {
    this.roundItems = Array.from({ length: this.roundCount }, (v, k) => k + 1);
    this.label = this.translation.translate('Task.NumberOfHits');
    let index = 0;
    this.wrResult.recallRounds.forEach(round => {
      index++;
      let item = {
        round: index,
        result: round.correctHits
      };
      this.chartDataSource.push(item);
    })
    this.rePlay(1);
  }

  ngOnDestroy() {
    this.clearTimerInterval();
  }

  rePlay(r) {
    this.initHeatMap = true;
    //inicalizáljuk a heatMap-et a kitalálandó szavakkal
    this.heatMap = [];
    this.wrResult.words.forEach(word => {
      let item: RecallHeatMapResult = <RecallHeatMapResult>{};
      item.word = word;
      item.correctHits = 0;
      item.correct = false;
      this.heatMap.push(item);
    });
    this.showHeatMap = true;

    if (r > 1) {
      //nem az első fordulót töltjük be
      let ix = 0;
      for (const round of this.wrResult.recallRounds) {
        ix++
        if (ix == r) break;
        for (const item of round.guessWords) {
          const index = this.heatMap.findIndex(hm => hm.word === item.correctedWord);
          if (index != -1) {
            this.heatMap[index].correctHits++;
          }

        }
      }

    }

    //aktuális kör ereményeinek betöltése

    this.actualCount = 0;
    let round = this.wrResult.recallRounds[r - 1].guessWords;
    this.correctWordsCount = round.length;
    this.myInterval = setInterval(() => {
      if (this.actualCount === round.length) {
        this.clearTimerInterval();
        this.initHeatMap = false;
      } else {
        const index = this.heatMap.findIndex(hm => hm.word === round[this.actualCount].correctedWord);
        if (index != -1) {
          this.heatMap[index].correct = true;
          this.heatMap[index].correctHits++;
        }
        this.actualCount++;
      }
    }, 1000); // 1 másodpercenként.

  }

  clearTimerInterval() {
    if (this.myInterval !== null) {
      clearInterval(this.myInterval);
    }
  }

  //Lapozás
  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
    if (!this.initHeatMap) this.rePlay(this.pageOfItems[0]);
  }
}
