import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  CodesEditModel,
  CodeWarehouseEditModel,
  CodesFunc,
  SelectList
} from '../services/codes.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';

import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import {  loadMessages, locale } from 'devextreme/localization';

import { ErrorService } from '../services/error.service';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import CustomStore from 'devextreme/data/custom_store';
import * as $ from 'jquery';
import { Subscription } from 'rxjs/internal/Subscription';

/**
 * Kódtár karbantaró.
 */

@Component({
  selector: 'app-codes',
  templateUrl: './codes.component.html',
  styleUrls: ['./codes.component.scss']
})
export class CodesComponent implements OnInit, OnDestroy {

  codesList: CodesEditModel[] = {} as CodesEditModel[];
  selectList: SelectList[] = {} as SelectList[];
  codeDetails: CodeWarehouseEditModel[] = {} as CodeWarehouseEditModel[];
  error: string;
  baseUri: string = `${environment.apiUrl}`;
  dataSource: any;
  detailDataSource: any;
  parentCodeData: any;
  parentCodeDetail: any;
  codeName: string;

  gridInstance: any;

  /**
   * A nyelvválasztó feliratkozáshoz.
   */
  private translSubs$: Subscription;


  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private codesFunc: CodesFunc,
    private errorService: ErrorService,
    private translation: L10nTranslationService,
    private http: HttpClient
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

    //Codes adatforrás a gridhez (master)
    this.dataSource = new CustomStore({
      key: "codeName",
      load: () => this.getAll(),
      insert: (values) => this.insertCodeData(JSON.stringify(values)),
      update: (key, values) => this.updateCodeData(key, JSON.stringify(values)),
      remove: (key) => this.deleteCodeData(key).then()
    });

    //CodesWarehouse adatforrás a gridhez (details)
    this.detailDataSource = new CustomStore({
      key: "warehouseWebId",
      load: () => this.getDetails(this.codeName),
      insert: (values) => this.insertCodeDetail(JSON.stringify(values)),
      update: (key, values) => this.updateCodeDetail(key, JSON.stringify(values)),
      remove: (key) => this.deleteCodeDetail(key).then()
    });

    //Szülő code adatok, hierarchia esetén
    this.parentCodeData = new CustomStore({
      key: "codeName",
      loadMode: "raw",
      cacheRawData: false,
      load: () => this.getAll()
    });

    //Szülő codewarehouse adatok, hierarchia esetén
    this.parentCodeDetail = new CustomStore({
      key: "codeName",
      loadMode: "raw",
      cacheRawData: false,
      load: () => this.getParentCodeDetail(this.codeName)
    });


  }


  ngOnInit(): void {

    // Feliratkozás a nyelv változtatására.
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });
  }
  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  //grid detail template adatforrása
  getDetailsDS(key) {
    this.codeName = key;
    return this.detailDataSource;
  }

  onRowExpanding(e) {
    e.component.collapseAll(-1);
  }

  //módosításnál a kódnév nem módosítható
  editorPreparing(e) {
    if (e.parentType === "dataRow" && e.dataField !== "codeName") {
      return;
    }
    if (e.row && !e.row.isNewRow) {
      e.editorOptions.disabled = true;
    }

  }

  editorPrepared(e) {
    if (e.parentType === "dataRow" && e.dataField !== "codeName" && e.dataField !== "codeNameDefault") {
      return;
    }
    if (e.row && !e.row.isNewRow && e.dataField === "codeName") {
      e.editorElement.getElementsByTagName('input')[0].style.background = "#fbe8b6" //"#E0E0E0"; //A Kód nem módosítható, ezért a háttér szürke
    } else if (e.row && !e.row.isNewRow && e.dataField === "codeNameDefault") {
      setTimeout(function () {
        // A Kód nem módosítható, ezért a szöveg mező lesz az aktív
        e.component.focus(e.editorElement);
      }, 100);
    }
  }

  onContentReady_code(e) {
    this.moveEditColumnToLeft(e.component); // A command column bal oldalon legyen. 
  }

  moveEditColumnToLeft(dataGrid) {
    dataGrid.columnOption("command:edit", {
      visibleIndex: -1
    });
  }

  onInitNewRow_detail(e, parentID) {
    e.data.CodeName = parentID;
  }

  saveGridInstance(e) {
    this.gridInstance = e.component;
    //this.gridInstance.addRow();
    //this.gridInstance.refresh();
    // let dataGridDataSource = this.dataGridInstance.getDataSource();
    //  dataGridDataSource.load();
  }


  //Master part

  getAll() {
    return new Promise((resolve, reject) => {
      this.codesFunc.getCodes().pipe(first()).subscribe(
        (p) => {
          this.codesList = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.codesList);
        }
      );
    });
  }

  insertCodeData(code: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.codesFunc.insertCodeData(code).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  updateCodeData(key: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.codesFunc.updateCodeData(key, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  deleteCodeData(key: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.codesFunc.deleteCodeData(key).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(this.translation.translate('Core.ExceptionDeleteIsNotPossible'));
          }
        }
      );
    });
  }

  //Details part

  getDetails(codeName: string) {
    return new Promise((resolve, reject) => {
      this.codesFunc.getCodeDetails(codeName).pipe(first()).subscribe(
        (p) => {
          this.codeDetails = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.codeDetails);
        }
      );
    });
  }

  getParentCodeDetail(codeName: string) {
    return new Promise((resolve, reject) => {
      this.codesFunc.getParentCodeDetail(codeName).pipe(first()).subscribe(
        (p) => {
          this.selectList = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.selectList);
        }
      );
    });
  }

  insertCodeDetail(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.codesFunc.insertCodeDetail(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  updateCodeDetail(key: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.codesFunc.updateCodeDetail(key, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  deleteCodeDetail(key: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.codesFunc.deleteCodeDetail(key).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(this.translation.translate('Core.ExceptionDeleteIsNotPossible'));
          }
        }
      );
    });
  }

}

