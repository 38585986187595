import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { JwtInterceptor } from '../helpers/jwt.interceptor';
import { ErrorInterceptor } from '../helpers/error.interceptor';

import { l10nConfig, initL10n, LocaleValidation, AppStorage, HttpTranslationLoader } from '../l10n-config';
import { L10nTranslationModule, L10nIntlModule } from 'angular-l10n';

import {
  DxMenuModule, DxSelectBoxModule, DxCheckBoxModule, DxValidatorModule,
  DxValidationSummaryModule, DxTextBoxModule, DxFormModule, DxLoadPanelModule,
  DxRadioGroupModule, DxDropDownBoxModule, DxPieChartModule
} from 'devextreme-angular';
import {
  DxDataGridModule, DxTextAreaModule, DxButtonModule,
  DxBulletModule,
  DxTemplateModule, DxListModule, DxSpeedDialActionModule, DxDateBoxModule, DxNumberBoxModule
} from 'devextreme-angular';

import { PatientCatalogueComponent } from './patient-catalogue/patient-catalogue.component';
import { PatientService } from '../module-patient/patient.service';
import { NewAdmissionComponent } from './new-admission/new-admission.component';
import { PatientEditComponent } from './patient-edit/patient-edit.component';
import { PatientModule } from '../module-patient/patient.module';
import { ClinicService } from './clinic.service';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { SharedModule } from '../shared/shared.module';
import { CancelTreatmentComponent } from './cancel-treatment/cancel-treatment.component';
import { AllocatedTasksComponent } from './allocated-tasks/allocated-tasks.component';
import { StatisticsModule } from '../statistics/statistics.module';
import { DenyTreatmentComponent } from './deny-treatment/deny-treatment.component';
import { FormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { KpiSComponent } from './kpi-s/kpi-s.component';
import { ActualConditionComponent } from './actual-condition/actual-condition.component';
import { PatientCatalogueGpComponent } from './patient-catalogue-gp/patient-catalogue-gp.component';
import { PatientEditGpComponent } from './patient-edit-gp/patient-edit-gp.component';
// import { ClinicRoutingModule } from './clinic-routing.module';

@NgModule({
  declarations: [
    PatientCatalogueComponent,
    NewAdmissionComponent,
    PatientEditComponent,
    DiagnosisComponent,
    CancelTreatmentComponent,
    AllocatedTasksComponent,
    DenyTreatmentComponent,
    KpiSComponent,
    ActualConditionComponent,
    PatientCatalogueGpComponent,
    PatientEditGpComponent],
  exports: [
    PatientCatalogueComponent,
    PatientCatalogueGpComponent
  ],
  imports: [
    // ClinicRoutingModule,
    CommonModule,
    HttpClientModule,
    L10nTranslationModule,
    L10nIntlModule,
    DxMenuModule,
    DxListModule,
    DxTextAreaModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    DxRadioGroupModule,
    DxDateBoxModule,
    DxButtonModule,
    DxNumberBoxModule,
    DxValidatorModule,
    DxDropDownBoxModule,
    DxSpeedDialActionModule,
    DxValidationSummaryModule,
    DxTextBoxModule,
    DxFormModule,
    DxPieChartModule,
    DxLoadPanelModule,
    PatientModule,
    StatisticsModule,
    SharedModule,
    FormsModule,
    TabsModule.forRoot()

  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    PatientService,
    ClinicService
  ]
})
/**
 * Klinikai funkciókat tartalmazó modul orvosok részére
 */

export class ClinicModule { }
