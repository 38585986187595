<!-- Regisztrált felhasználónak mutatjuk  -->
<div *ngIf="currentUser && !doctorUser && currentUser.role != 'PraxisAdmin' && currentUser.role != 'GP' && !pendingUser && currentUser.displayName != null"
    class="container mt-3">
    <!-- Aktuális feladatok     -->
    <div *ngIf="currentUser.role == 'PlatinumUser'" class="row mb-4">
        <app-patient-tasks></app-patient-tasks>
    </div>
   <!-- Játékok     -->
    <div class="row">
        <div class="col-12">
            <h1 class="display-6 d-block">{{ 'Game.Games' | translate:locale.language }}</h1>
            <hr>
        </div>

        <div class="col-12 col-md-6 col-xl-4 mb-3">
            <div class="card position-relative h-100">
                <img class="card-img" src="../assets/images/sdmt/img/sdmt-bg2.png">
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center h-100">
                    <h2 class="card-title text-uppercase text-center m-0">
                        <span class="fw-bold">{{ 'Game.SDMT' | translate:locale.language }} </span>
                        <span class="fw-light">{{ 'Game.TypeGames' | translate:locale.language }}</span>
                    </h2>
                    <div class="d-flex justify-content-center w-100">
                        <div class="w-25 m-3 pointer">
                            <img src="../assets/images/sdmt/img/sdmt-icon.svg"
                                (click)="startTask('/games/sdmt-test/sdmt')">
                        </div>
                        <div class="w-25 m-3 pointer">
                            <img src="../assets/images/sdmt/img/ldst-icon.svg"
                                (click)="startTask('/games/sdmt-test/ldst')">
                        </div>
                        <div class="w-25 m-3 pointer">
                            <img src="../assets/images/dsst/img/dsst-icon.svg" (click)="startTask('/games/dsst-test')">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mb-3">
            <div class="card text-white position-relative h-100">
                <img class="card-img" src="../assets/images/memory/memory-bg-2.png">
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center h-100">
                    <h2 class="card-title text-uppercase text-center">
                        <span class="fw-bold">{{ 'Game.MemoryGame' | translate:locale.language }}</span>
                    </h2>
                    <div class="d-flex justify-content-center w-100">
                        <div class="w-25 m-1 pointer">
                            <img title="{{ 'Game.Classic' | translate:locale.language }}"
                                src="../assets/images/memory/memory-classic-icon.svg"
                                (click)="startTask('/games/memory/classic')">
                        </div>
                        <div class="w-25 m-1 pointer">
                            <img title="{{ 'Game.Semantic' | translate:locale.language }}"
                                src="../assets/images/memory/memory-semantic-icon.svg"
                                (click)="startTask('/games/memory/semantic')">
                        </div>
                        <div class="w-25 m-1 pointer">
                            <img title="{{ 'Game.Mathematical' | translate:locale.language }}"
                                src="../assets/images/memory/memory-math-icon.svg"
                                (click)="startTask('/games/memory/mathematical')">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mb-3">
            <div class="card text-white position-relative h-100">
                <img class="card-img" src="../assets/images/color-recognition/color-recognition-bg-bg.png">
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center h-100">
                    <h2 class="card-title text-uppercase text-center">
                        <span class="fw-bold">{{ 'Game.ColorRecognition' | translate:locale.language }}</span>
                    </h2>
                    <div class="d-flex justify-content-center w-100">
                        <div class="w-25 m-1 pointer">
                            <img title="{{ 'Game.BackColor' | translate:locale.language }}"
                                src="{{ 'Assets.color-recognition-bg-icon' | translate:locale.language }}"
                                (click)="startTask('/games/color-recognition/backColorRecognition')">
                        </div>
                        <div class="w-25 m-1 pointer">
                            <img title="{{ 'Game.ForeColor' | translate:locale.language }}"
                                src="{{ 'Assets.color-recognition-text-icon' | translate:locale.language }}"
                                (click)="startTask('/games/color-recognition/foreColorRecognition')">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mb-3">
            <div class="card text-white pointer position-relative" (click)="startTask('/games/shape-recall')">
                <img class="card-img" src="../assets/images/shape-recall/shape-recall-bg.png">
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center">
                    <h2 class="card-title text-uppercase text-center">
                        <span class="fw-bold">{{ 'Game.Shape' | translate:locale.language }}</span><br>
                        <span class="fw-light">{{ 'Game.Recall' | translate:locale.language }}</span>
                    </h2>
                    <img class="card-img" style="width:25%" src="../assets/images/shape-recall/shape-recall-icon.svg">
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mb-3">
            <div class="card text-white pointer position-relative" (click)="startTask('/games/ica-test')">
                <img class="card-img" src="../assets/images/ica/imci-bg.png">
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center">
                    <h2 class="card-title text-uppercase fw-bold">{{ 'Game.Imci' | translate:locale.language }}</h2>
                    <span>{{ 'Game.ExperimentalGame' | translate:locale.language }}</span>
                    <img class="card-img" src="../assets/images/ica/imci-icon.svg">
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mb-3">
            <div class="card text-white pointer position-relative" (click)="startTask('/games/tmt-test-b')">
                <img class="card-img" src="../assets/images/tmt-bg.png">
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center">
                    <h2 class="card-title text-uppercase text-center">
                        <span class="fw-bold">{{ 'Game.Tmt' | translate:locale.language }} </span>
                        <span class="fw-light">{{ 'Game.Test' | translate:locale.language }}</span>
                    </h2>
                    <img class="card-img" src="../assets/images/tmt-icon.svg">
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mb-3">
            <div class="card pointer position-relative" (click)="startTask('/games/word-guess')">
                <img class="card-img" src="../assets/images/word-guess/word-guess-bg.png">
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center">
                    <h2 class="card-title text-uppercase text-center">
                        <span class="fw-bold">{{ 'Game.WordGuess' | translate:locale.language }}</span><br>
                    </h2>
                    <img class="card-img" style="width:35%" src="../assets/images/word-guess/word-guess-icon.svg">
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mb-3">
            <div class="card text-white pointer position-relative" (click)="startTask('/games/word-list-recall')">
                <img class="card-img" src="{{ 'Assets.word-list-recall-bg' | translate:locale.language }}">
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center">
                    <h2 class="card-title text-uppercase text-center">
                        <span class="fw-bold">{{ 'Game.Word' | translate:locale.language }}</span><br>
                        <span class="fw-light">{{ 'Game.Recall' | translate:locale.language }}</span>
                    </h2>
                    <img class="card-img" style="width:25%"
                        src="../assets/images/word-list-recall/word-list-recall-icon.svg">
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-xl-4 mb-3">
            <div class="card text-white pointer position-relative" (click)="startTask('/games/word-list-recall-ac')">
                <img class="card-img" src="{{ 'Assets.word-list-recall-ac-bg' | translate:locale.language }}">
                <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center">
                    <h2 class="card-title text-uppercase text-center">
                        <span class="fw-bold">{{ 'Game.Word' | translate:locale.language }}</span><br>
                        <span class="fw-light">{{ 'Game.Recognition' | translate:locale.language }}</span>
                    </h2>
                    <img class="card-img" style="width:25%"
                        src="../assets/images/word-list-recall/word-list-recall-ac-icon.svg">
                </div>
            </div>
        </div>
    </div>
     <!-- Reklám helye -->
    <div class="row">
        <div class="col-12">
            <hr class="mt-0">
        </div>
         <div *ngIf="adSrc1 && adSrc1.imgSmall" class="col-12 col-md-6 col-xl-4 mb-3">
            <div class="card text-white pointer position-relative">
               <img class="card-img" [src]="uploadUrl + adSrc1.imgSmall">
               <a class="card-img-overlay d-flex flex-column align-items-center justify-content-center" [href]="adSrc1.link" target="_blank">
               </a>
            </div>
         </div>
         <div *ngIf="adSrc2 && adSrc2.imgSmall" class="col-12 col-md-6 col-xl-4 mb-3">
            <div class="card text-white pointer position-relative">
               <img class="card-img" [src]="uploadUrl + adSrc2.imgSmall">
               <a class="card-img-overlay d-flex flex-column align-items-center justify-content-center" [href]="adSrc2.link" target="_blank">
               </a>
            </div>
         </div>
         <div *ngIf="adSrc3 && adSrc3.imgSmall" class="col-12 col-md-6 col-xl-4 mb-3">
            <div class="card text-white pointer position-relative">
               <img class="card-img" [src]="uploadUrl + adSrc3.imgSmall">
               <a class="card-img-overlay d-flex flex-column align-items-center justify-content-center" [href]="adSrc3.link" target="_blank">
               </a>
            </div>
         </div>
    </div>

</div>

<!-- Orvos felhasználónak a katalógust mutatjuk -->
<div *ngIf="doctorUser && currentUser && currentUser.displayName != null">
    <app-patient-catalogue></app-patient-catalogue>
</div>

<!-- Háziorvos felhasználónak a katalógust mutatjuk -->
<div *ngIf="currentUser && currentUser.displayName != null && currentUser.role == 'GP'">
    <app-patient-catalogue-gp></app-patient-catalogue-gp>
</div>

<!-- Regisztrációját törölt felhasználónak mutatjuk -->
<div *ngIf="pendingUser && currentUser && currentUser.displayName != null">
    <br>
    <div class="col-md-4 offset-md-3">
        <h2> {{ 'Core.CancelUnregister' | translate:locale.language }}</h2>
        <p> {{ message }}</p>
    </div>
    <div class="col-md-4 offset-md-3">

        <div class="modal-footer">
            <dx-button stylingMode="contained" text="{{ 'Core.Restore' | translate:locale.language }}" type="default"
                (onClick)="cancelUnregister($event)">
            </dx-button>

        </div>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>

    </div>

</div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" (onShown)="onShown()"
    (onHidden)="onHidden()">
</dx-load-panel>