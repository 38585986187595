import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { StringResult } from '../module-thryve/thryve.service';
import { ErrorService } from './error.service';
import { TermsAndConditions, TermsFunc, UnregisterFunc } from './webapiclient.service';

@Injectable({
  providedIn: 'root'
})
export class UnregisterService {

  constructor(
    private unregisterFunc: UnregisterFunc,
    private errorService: ErrorService

  ) { }
async unregister(value: string) {
    let resultString;
 return new Promise((resolve, reject) => {
     this.unregisterFunc.unregister(value).pipe(first()).subscribe(
         (p) => {
             resultString = p;
         },
         (e) => {
             this.errorService.changeErrorMessage(e);
             reject(e);
         },
         () => {
             if (resultString.body === "OK") {
                 resolve(resultString.body);
             } else {
                 this.errorService.changeErrorMessage(resultString.body);
                 reject(resultString.body);
             }
         }
     );
 });
 }

 async getUnregisterDate(id: string) {
    let result: StringResult = {} as StringResult;
    return new Promise((resolve, reject) => {
        this.unregisterFunc.getUnregisterDate(id).pipe(first()).subscribe(
            (p) => {
  
                result = p;
            },
            (e) => {
                this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
                reject(e.message + e.error.ExceptionMessage);
            },
            () => {
                    resolve(result.result);
             }
        );
    });
  }

async cancelUnregister() {
    let result: StringResult = {} as StringResult;
    return new Promise((resolve, reject) => {
        this.unregisterFunc.cancelUnregister().pipe(first()).subscribe(
            (p) => {
  
                result = p;
            },
            (e) => {
                this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
                reject(e.message + e.error.ExceptionMessage);
            },
            () => {
                    resolve(result.result);
             }
        );
    });
  }

}
