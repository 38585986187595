import { Component, OnInit, Inject, ViewChild, TemplateRef, ElementRef, OnDestroy } from '@angular/core';
import {
  HealthcareProvider,
  HealthcareProviderFunc
} from '../services/webapiclient.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';

import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { formatMessage, loadMessages, locale } from 'devextreme/localization';

import { ErrorService } from '../services/error.service';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';

//import {} from 'googlemaps';


/**
 * Egészségügyi szolgáltatók törzsadat kezelés.
 */

@Component({
  selector: 'app-hc-provider',
  templateUrl: './hc-provider.component.html',
  styleUrls: ['./hc-provider.component.scss']
})
export class HcProviderComponent implements OnInit, OnDestroy {

  healthcareProviders: HealthcareProvider[] = {} as HealthcareProvider[];
  error: string;
  baseUri: string = `${environment.apiUrl}`;
  dataSource: any;
  cityPattern = "^[a-zA-ZáéőöóüíűúÁÉŐÖÍŰÚÍÓ ,.'-]+$";
  postalCodePattern = "^[0-9]{4}$";
  phonePattern = "^(?:\\+?(?:36|\\(36\\)))[ -\\/]?(?:(?:(?:(?!1|20|21|30|31|70|90)[2-9][0-9])[ -\\/]?\\d{3}[ -\\/]?\\d{3})|(?:(?:1|20|21|30|31|70|90)[ -\\/]?\\d{3}[ -\\/]?\\d{2}[ -\\/]?\\d{2}))$";
  urlPattern = "(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})"

  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private healthcareProviderFunc: HealthcareProviderFunc,
    private errorService: ErrorService,
    private translation: L10nTranslationService
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

    this.dataSource = new CustomStore({
      key: "id",
      load: () => this.getAll(),
      insert: (values) => this.insert(JSON.stringify(values)),
      update: (key, values) => this.update(key, JSON.stringify(values)),
      remove: (key) => this.delete(key).then()
    });
  }


  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });
    // const mapProperties = {
    //   center: new google.maps.LatLng(35.2271, -80.8431),
    //   zoom: 15,
    //   mapTypeId: google.maps.MapTypeId.ROADMAP
    // };
    // this.map = new google.maps.Map(this.mapElement.nativeElement,mapProperties);
  }
  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.healthcareProviderFunc.getAll().pipe(first()).subscribe(
        (p) => {
          this.healthcareProviders = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.healthcareProviders);
        }
      );
    });
  }

  insert(provider: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.healthcareProviderFunc.insert(provider).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  update(key: number, provider: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.healthcareProviderFunc.update(key, provider).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  delete(key: number) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.healthcareProviderFunc.delete(key).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(this.translation.translate('Core.ExceptionDeleteIsNotPossible'));
          }
        }
      );
    });
  }
}
