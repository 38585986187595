import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { loadMessages, locale } from 'devextreme/localization';
import messagesHu from 'devextreme/localization/messages/hu.json';
import notify from 'devextreme/ui/notify';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { NavPreventerService } from '../helpers/nav-preventer';

import { AccountFunc, ChangePasswordViewModel } from '../services/webapiclient.service';

/**
 * Jelszó módosítása.
 */

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  changePasswordViewModel: ChangePasswordViewModel = {} as ChangePasswordViewModel;
  error = '';
  stylingMode = 'filled';

  /**
   * A nyelvválasztó feliratkozáshoz.
   */
  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private route: ActivatedRoute,
    private router: Router,
    private accountFunc: AccountFunc,
    private translation: L10nTranslationService

  ) {
    const huMessages = JSON.stringify(messagesHu.hu);
    loadMessages({
      hu: JSON.parse(huMessages)
    });

  }

  ngOnInit(): void {
    // Feliratkozás a nyelv változtatására.
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  passwordComparison = () => {
    return this.changePasswordViewModel.newPassword;
  }

  onFormSubmit = function(e) {
    this.error = '';

    let result: any;
    return new Promise((resolve, reject) => {
      this.accountFunc.changePassword(JSON.stringify(this.changePasswordViewModel)).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.error = e;
          reject(e);
        },
        () => {
          if (result.body == 'OK') {
            notify({
              message: 'Sikeres jelszó változtatás!',
              position: {
                my: 'center top',
                at: 'center top'
              }
            }, 'success', 3000);

            this.router.navigate(['/login']);
            resolve('OK');
          } else {
            this.error = result.body;
            resolve(result.body);
            // reject(this.translation.translate('Core.' + result.errors[0].code));
          }
        }
      );
    });

  };

}
