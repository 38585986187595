import { Component, Inject, OnInit } from '@angular/core';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';
import { DensityDTO } from '../services/webapiclient.service';
import { GameService } from '../games/services/game.service';


@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  dataSource: DensityDTO[];

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    public gameService: GameService

  ) {
  }

  ngOnInit(): void {
    this.loadDataSource();
  }

  loadDataSource() {
    // this.gameService.getDensity().subscribe((data) => {
    //   this.dataSource = (data as DensityDTO[]);
    // });

  }

}
