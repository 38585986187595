import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  User,
  UserResult,
  UsersFunc,
  RoleResult,
  RolesFunc,
  HealthcareProviderFunc,
  HealthcareProvider,
  LoginUser
} from '../services/webapiclient.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';

import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { formatMessage, loadMessages, locale } from 'devextreme/localization';

import { ErrorService } from '../services/error.service';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import CustomStore from 'devextreme/data/custom_store';
import { LoggingService } from '../services/logging.service';
import { AuthenticationService } from '../services/authentication.service';
import { Role } from '../models/role';
import { Subscription } from 'rxjs';

/**
 * Felhasználók kezelése (admin joggal).
 * SysAdmin csak sys vagy praxis adminokat tud felvenni.
 * Praxis admin az adott praxishoz tartozó orvosokat tud felvenni.
 */

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {

  userResult: UserResult = {} as UserResult;
  roleResult: RoleResult = {} as RoleResult;
  healthcareProviders: HealthcareProvider[] = {} as HealthcareProvider[];
  error: string;
  baseUri: string = `${environment.apiUrl}`;
  dataSource: any;
  rolesData: any;
  providersData: any;
  currentUser: LoginUser;
  sysAdmin: boolean;

  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private usersFunc: UsersFunc,
    private rolesFunc: RolesFunc,
    private errorService: ErrorService,
    private authenticationService: AuthenticationService,
    private translation: L10nTranslationService,
    private healthcareProviderFunc: HealthcareProviderFunc,
    private log: LoggingService
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

    //Felhasználók
    this.dataSource = new CustomStore({
      key: "id",
      load: () => this.getAll(),
      insert: (values) => this.insert(JSON.stringify(values)),
      update: (key, values) => this.update(key, JSON.stringify(values)),
      remove: (key) => this.delete(key).then()
    });

    //Szerepkörök
    this.rolesData = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: () => this.getRoles()
    });

    //Szolgáltatók
    this.providersData = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: () => this.getProviders()
    });

  }


  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });
    this.currentUser = this.authenticationService.currentUserValue;
    this.sysAdmin = this.currentUser.role == Role.SysAdmin;
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  getRoles() {
    return new Promise((resolve, reject) => {
      this.rolesFunc.getAll().pipe(first()).subscribe(
        (p) => {
          this.roleResult = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          if (this.roleResult.error === null) {
            for (let role of this.roleResult.roles) {
              role.translation = this.translation.translate('Role.' + role.name);
            }
            resolve(this.roleResult.roles);
          } else {
            this.errorService.changeErrorMessage(this.roleResult.error);
            reject(this.roleResult.error);
          }
        }
      );
    });
  }

  getProviders() {
    return new Promise((resolve, reject) => {
      this.healthcareProviderFunc.getAll().pipe(first()).subscribe(
        (p) => {
          this.healthcareProviders = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.healthcareProviders);
        }
      );
    });

  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.usersFunc.getAll().pipe(first()).subscribe(
        (p) => {
          this.userResult = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          if (this.userResult.error === null) {
            resolve(this.userResult.users);
          } else {
            this.errorService.changeErrorMessage(this.userResult.error);
            reject(this.userResult.error);
          }
        }
      );
    });
  }

  insert(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.usersFunc.insert(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.succeeded) {
            resolve("OK");
          } else {
            //reject(result.errors[0].description);
            reject(this.translation.translate('Core.' + result.errors[0].code));
          }
        }
      );
    });
  }

  update(key: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.usersFunc.update(key, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.succeeded) {
            resolve("OK");
          } else {
            reject(this.translation.translate('Core.' + result.errors[0].code));
          }
        }
      );
    });
  }

  delete(key: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.usersFunc.delete(key).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.succeeded) {
            resolve("OK");
          } else {
            reject(this.translation.translate('Core.ErrorMessage_DeleteNotPossible'));
          }
        }
      );
    });
  }
}
