import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { loadMessages, locale } from 'devextreme/localization';
import { Subscription } from 'rxjs';
import { GamesReportService } from '../services/games-report.service';
import { GameResultData } from '../shared/game-chart-model';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DatePipe } from '@angular/common';
import { CorrectDate } from 'src/app/utils';
import { GameResultDetails, WordRecallResult } from 'src/app/games/shared/gameResult';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GameService, resultObject } from 'src/app/games/services/game.service';

@Component({
  selector: 'app-games-report',
  templateUrl: './games-report.component.html',
  styleUrls: ['./games-report.component.scss']
})
export class GamesReportComponent implements OnInit, OnDestroy {

  @Input() inpPatientId: string;
  @ViewChild('detailsTemplate', { static: true }) detailsTemplate: TemplateRef<any>;
  @ViewChild('playTemplate', { static: true }) playTemplate: TemplateRef<any>;
  @ViewChild('playTemplateAc', { static: true }) playTemplateAc: TemplateRef<any>;

  modalRef: BsModalRef;
  dataSource: GameResultData[];
  filteredDataSource: GameResultData[];
  resultDetails: GameResultDetails;
  wrResult: WordRecallResult;
  private translSubs$: Subscription;
  patientId: any;
  currentUser: any;
  loading: boolean = false;
  showAll: boolean = true;
  showSdmt: boolean = true;
  showLdst: boolean = true;
  showDsst: boolean = true;
  showIca: boolean = true;
  showWordGuess: boolean = true;
  showShapeRecall: boolean = true;
  showWordRecall: boolean = true;
  showMemoryClassic: boolean = true;
  showMemorySemantic: boolean = true;
  showMemoryMathematical: boolean = true;
  showColorRecognition: boolean = true;
  showTmt: boolean = true;
  showWordListRecallAc: boolean = true;
  allocatedTasks: boolean = false;
  roundCount: number;
  zScore: boolean = false;

  kbSearchState: boolean = true;
  options: any[];
  option: string;
  selectedOption: any;
  ifDoctor: string;
  isDoctor: boolean = false;
  customDate: boolean = false;
  compareMessage: string;
  constantLineColor: string = "white";


  stylingMode = "filled";
  maxDate: Date = new Date();
  startDate: Date = new Date(new Date().setMonth(new Date().getMonth() - 1));
  endDate: Date = new Date();
  dateTimeFormat: string;
  //emojis: string[] = ['&#x1F615;','&#x1F614;','&#x1F613;','&#x1F600;','&#x1F602;','&#x1F604;','&#x1F606;'];
  imagePath: string[] = ['assets/images/mood-form/face-3.png',
    'assets/images/mood-form/face-2.png',
    'assets/images/mood-form/face-1.png',
    'assets/images/mood-form/face0.png',
    'assets/images/mood-form/face1.png',
    'assets/images/mood-form/face2.png',
    'assets/images/mood-form/face3.png'];


  startDateInstance: any;
  endDateInstance: any;
  chartInstance: any;
  seriesCollection: any = [];

  resultObject: resultObject[]; //Itt tároljuk az eredmény megnevezéseket, és stílusokat

  constructor(private gamesReportService: GamesReportService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    private datePipe: DatePipe,
    private modalService: BsModalService,
    private authenticationService: AuthenticationService,
    public gameService: GameService
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });
    //Periódus választó
    this.options = [
      {
        "name": this.translation.translate('Game.Month'),
        "value": 30
      },
      {
        "name": this.translation.translate('Game.QY'),
        "value": 90
      },
      {
        "name": this.translation.translate('Game.HY'),
        "value": 180
      },
      {
        "name": this.translation.translate('Game.Year'),
        "value": 365
      },
      {
        "name": this.translation.translate('Core.Custom'),
        "value": 0
      }
    ];
    this.option = this.options[0].value;
    this.selectedOption = this.options[0];

  }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateTimeFormat = this.translation.translate('Core.DateTimeFormat');
        //Start és end dátum összehasonlító hibaüzenet
        this.compareMessage = this.translation.translate('Core.ValidationDateGreaterError')
          .replace('{0}', this.translation.translate('Task.End'))
          .replace('{1}', this.translation.translate('Task.Start'));
      });

    this.resultObject = this.gameService.GameResultObjects;

    if (this.inpPatientId) {
      //Katalógusból hívjuk
      this.patientId = this.inpPatientId;
      this.ifDoctor = "if-doctor";
      this.isDoctor = true;
    }

    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      if (!this.patientId) this.patientId = this.currentUser.id;
      this.loadDataSource();
    }

  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  loadDataSource() {
    this.loading = true;
    let zc = this.zScore ? 1:0;
    this.gamesReportService.getGameResults(this.patientId, this.startDate.toDateString(), this.endDate.toDateString(),zc).subscribe((data) => {
      this.dataSource = data;
      this.setFilteredDataSource();
      this.loading = false;
    });
  }

  legendClick(e: any) {
    const series = e.target;
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
    this.setFilter(series.index, series.isVisible());
    this.setFilteredDataSource();
  }

  checkComparison = () => {
    return this.startDate;
  };

  setFilter(index, visible) {
    switch (index) {
      case 0:
        this.showSdmt = visible;
        break;
      case 1:
        this.showLdst = visible;
        break;
      case 2:
        this.showDsst = visible;
        break;
      case 3:
        this.showMemoryClassic = visible;
        break;
      case 4:
        this.showMemorySemantic = visible;
        break;
      case 5:
        this.showMemoryMathematical = visible;
        break;
      case 6:
        this.showColorRecognition = visible;
        break;
      case 7:
        this.showShapeRecall = visible;
        break;
      case 8:
        this.showIca = visible;
        break;
      case 9:
      case 10:
        this.showTmt = visible;
        break;
      case 11:
        this.showWordGuess = visible;
        break;
      case 12:
        this.showWordRecall = visible;
        break;
      case 13:
        this.showWordListRecallAc = visible;
        break;
    }

  }


  setFilteredDataSource() {
    if (this.allocatedTasks) {
      //csak az előírt feladatokat, játékokat mutatjuk
      this.filteredDataSource = this.dataSource.filter(f => f.taskId > 0);
    } else {
      //minden mutatjuk
      this.filteredDataSource = this.dataSource;
    }
    //Játék típusa szerinti szűrések
    if (!this.showSdmt) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'SDMTtest');
    if (!this.showDsst) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'DSSTtest');
    if (!this.showLdst) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'LDSTtest');
    if (!this.showIca) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'IcaTest');
    if (!this.showMemoryClassic) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'MemoryGameClassic');
    if (!this.showMemorySemantic) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'MemoryGameSemantic');
    if (!this.showMemoryMathematical) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'MemoryGameMathematical');
    if (!this.showShapeRecall) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'ShapeRecall');
    if (!this.showWordRecall) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'WordRecall');
    if (!this.showWordGuess) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'WordGuess');
    if (!this.showColorRecognition) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'ColorRecognition');
    if (!this.showTmt) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'TmtTestA' && f.gameType != 'TmtTestB');
    if (!this.showWordListRecallAc) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'WordListRecallAc');
  }
  //Szöveg az időpont tengelyhez
  customizeTimeText = (info: any) => {
    if (info.valueText == null) return;
    let ret: Date;
    ret = new Date(info.valueText);
    return this.datePipe.transform(ret, 'MM.dd.');
  }

  customizeTooltip = (info: any) => {
    return {
      html: "<div><div class='tooltip-header'>" +
      info.value.toFixed(2) + "</div></div>"
    };
  }


  onShowSdmt(e) {
    this.showSdmt = e.value;
    this.setFilteredDataSource();
  }
  onShowDsst(e) {
    this.showDsst = e.value;
    this.setFilteredDataSource();
  }
  onShowLdst(e) {
    this.showLdst = e.value;
    this.setFilteredDataSource();
  }
  onShowIca(e) {
    this.showIca = e.value;
    this.setFilteredDataSource();
  }

  onShowAll(e) {
    this.seriesCollection = this.chartInstance.getAllSeries();
    this.seriesCollection.forEach((serie, index) => {
      if (!e.value) {
        serie.hide();
      } else {
        serie.show();
      }
    });

    this.showAll = e.value;
    this.showSdmt = e.value;
    this.showDsst = e.value;
    this.showLdst = e.value;
    this.showIca = e.value;
    this.showShapeRecall = e.value;
    this.showWordRecall = e.value;
    this.showWordGuess = e.value;
    this.showMemoryClassic = e.value;
    this.showMemorySemantic = e.value;
    this.showMemoryMathematical = e.value;
    this.showColorRecognition = e.value;
    this.showTmt = e.value;
    this.showWordListRecallAc = e.value;
    this.setFilteredDataSource();
  }

  onShown() {
    setTimeout(() => {
      this.loading = false;
    }, 15000);
  }  

  onShowColorRecognition(e) {
    this.showColorRecognition = e.value;
    this.setFilteredDataSource();
  }

  onShowTmt(e) {
    this.showTmt = e.value;
    this.setFilteredDataSource();
  }

  onShowWordListRecallAc(e) {
    this.showWordListRecallAc = e.value;
    this.setFilteredDataSource();
  }

  onShowShapeRecall(e) {
    this.showShapeRecall = e.value;
    this.setFilteredDataSource();
  }
  onShowWordRecall(e) {
    this.showWordRecall = e.value;
    this.setFilteredDataSource();
  }
  onShowWordGuess(e) {
    this.showWordGuess = e.value;
    this.setFilteredDataSource();
  }
  onShowMemoryClassic(e) {
    this.showMemoryClassic = e.value;
    this.setFilteredDataSource();
  }
  onShowMemorySemantic(e) {
    this.showMemorySemantic = e.value;
    this.setFilteredDataSource();
  }
  onShowMemoryMathematical(e) {
    this.showMemoryMathematical = e.value;
    this.setFilteredDataSource();
  }

  onTasksChanged(e) {
    this.loadDataSource();
    this.setFilteredDataSource();
  }

  onzScore(e) {
    this.constantLineColor = this.zScore ? "red" : "white";
     this.loadDataSource();
    this.setFilteredDataSource();
    this.chartInstance.refresh();
  }  

  onQuery() {
    this.loadDataSource();
  }


  //Keresési rész kinyitás, bezárás
  kbSearchToggle() {
    this.kbSearchState = !this.kbSearchState;
  }

  onOptionChanged(days, index) {
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - days);
    this.selectedOption = this.options[index];
    this.customDate = false;
    this.loadDataSource();
  }

  onOptionChangedCustom() {
    this.customDate = true;
    this.selectedOption = this.options[4];
  }

  startDateFocusOut(s) {
    CorrectDate(this.startDateInstance);
  }

  initStartDate(e) {
    this.startDateInstance = e.component;
  }

  endDateFocusOut(s) {
    CorrectDate(this.endDateInstance);
  }

  initEndDate(e) {
    this.endDateInstance = e.component;
  }

  initChart(e) {
    this.chartInstance = e.component;
  }

  clickDetails(id) {
    this.gamesReportService.getGameResultDetails(id).subscribe((data) => {
      this.resultDetails = data;
      this.modalRef = this.modalService.show(this.detailsTemplate, { class: 'modal-sm', backdrop: 'static', keyboard: false });
    });
  }
  clickPlay(id) {
    this.gamesReportService.getWordRecallResult(id).subscribe((data) => {
      this.wrResult = data;
      this.roundCount = this.wrResult.recallRounds.length;
      this.modalRef = this.modalService.show(this.playTemplate, { class: 'modal-lg', backdrop: 'static', keyboard: false });
    });
  }
  clickPlayAc(id) {
    this.gamesReportService.getWordRecallResult(id).subscribe((data) => {
      this.wrResult = data;
      this.roundCount = this.wrResult.recallRounds.length;
      this.modalRef = this.modalService.show(this.playTemplateAc, { class: 'modal-lg', backdrop: 'static', keyboard: false });
    });
  }

  closeModal() {
    this.modalRef.hide();
  }
}
