import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import {
    AuthenticateModel,
    LoginUser,
    AccountFunc,
    ApiTokens,
    ExternalAuthDto
} from './webapiclient.service';
//import {AppInjector} from './appInjector';
import { ErrorService } from './error.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<LoginUser>;
    public currentUser: Observable<LoginUser>;

    private currentTokenSubject: BehaviorSubject<ApiTokens>;
    public currentToken: Observable<ApiTokens>;

    constructor(
        private accountFunc: AccountFunc,
        private errorService: ErrorService
    ) {
        this.currentUserSubject = new BehaviorSubject<LoginUser>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();

        this.currentTokenSubject = new BehaviorSubject<ApiTokens>(null);
        this.currentToken = this.currentTokenSubject.asObservable();

    }

    public get currentUserValue(): LoginUser {
        return this.currentUserSubject.value;
    }

    async login(authenticateModel: AuthenticateModel) {
        let loginUser: LoginUser = {} as LoginUser;
        return new Promise((resolve, reject) => {
            this.accountFunc.authenticate(authenticateModel).pipe(first()).subscribe(
                (p) => {
                    loginUser = p;
                    if (loginUser.error === null) {
                        localStorage.setItem('currentUser', JSON.stringify(p));
                        this.currentUserSubject.next(p);
                        this.startRefreshTokenTimer();
                    }
                },
                (e) => {
                    //this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
                    this.errorService.changeErrorMessage(e);
                    //reject(e.message + e.error.ExceptionMessage);
                    reject(e);
                    //console.log(e);
                },
                () => {
                    if (loginUser.error === null) {
                        resolve(loginUser);
                    } else {
                        this.errorService.changeErrorMessage(loginUser.error);
                        reject(loginUser.error);
                    }
                }
            );
        });
    }

    async facebookLogin(access_token: string) {
        let loginUser: LoginUser = {} as LoginUser;
        return new Promise((resolve, reject) => {
            this.accountFunc.facebookAuthenticate(access_token).pipe(first()).subscribe(
                (p) => {
                    loginUser = p;
                    if (loginUser.error === null) {
                        localStorage.setItem('currentUser', JSON.stringify(p));
                        this.currentUserSubject.next(p);
                        this.startRefreshTokenTimer();
                    }
                },
                (e) => {
                    //this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
                    this.errorService.changeErrorMessage(e);
                    //reject(e.message + e.error.ExceptionMessage);
                    reject(e);
                    //console.log(e);
                },
                () => {
                    if (loginUser.error === null) {
                        resolve(loginUser);
                    } else {
                        this.errorService.changeErrorMessage(loginUser.error);
                        reject(loginUser.error);
                    }
                }
            );
        });
    }
    async googleLogin(externalAuth: ExternalAuthDto) {
        let loginUser: LoginUser = {} as LoginUser;
        return new Promise((resolve, reject) => {
            this.accountFunc.googleAuthenticate(externalAuth).pipe(first()).subscribe(
                (p) => {
                    loginUser = p;
                    if (loginUser.error === null) {
                        localStorage.setItem('currentUser', JSON.stringify(p));
                        this.currentUserSubject.next(p);
                        this.startRefreshTokenTimer();
                    }
                },
                (e) => {
                    //this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
                    this.errorService.changeErrorMessage(e);
                    //reject(e.message + e.error.ExceptionMessage);
                    reject(e);
                    //console.log(e);
                },
                () => {
                    if (loginUser.error === null) {
                        resolve(loginUser);
                    } else {
                        this.errorService.changeErrorMessage(loginUser.error);
                        reject(loginUser.error);
                    }
                }
            );
        });
    }

    async forgotPassword(userName: string) {
        let resultString;
        return new Promise((resolve, reject) => {
            this.accountFunc.forgotPassword(userName).pipe(first()).subscribe(
                (p) => {
                    resultString = p;
                },
                (e) => {
                    this.errorService.changeErrorMessage(e);
                    reject(e);
                },
                () => {
                    if (resultString.body === "OK") {
                        resolve(resultString.body);
                    } else {
                        this.errorService.changeErrorMessage(resultString.body);
                        reject(resultString.body);
                    }
                }
            );
        });
    }

    async changePassword(value: string) {
        let resultString;
        return new Promise((resolve, reject) => {
            this.accountFunc.forgotPassword(value).pipe(first()).subscribe(
                (p) => {
                    resultString = p;
                },
                (e) => {
                    this.errorService.changeErrorMessage(e);
                    reject(e);
                },
                () => {
                    if (resultString.body === "OK") {
                        resolve(resultString.body);
                    } else {
                        this.errorService.changeErrorMessage(resultString.body);
                        reject(resultString.body);
                    }
                }
            );
        });
    }

    async logout() {
        if(this.currentUserValue){
            let apiTokens = ({} as ApiTokens);
            apiTokens.token = this.currentUserValue.token;
            apiTokens.refreshToken = this.currentUserValue.refreshToken;
    
            // remove user from local storage to log user out
            localStorage.removeItem('currentUser');
            this.stopRefreshTokenTimer();
            this.currentUserSubject.next(null);
    
            await this.revokeToken(apiTokens).then(
                result => {
                    this.stopRefreshTokenTimer();
                }
            ).catch(err => {
                //this.error = err;
            });
        }
    }

    refreshToken() {
        let apiTokens = ({} as ApiTokens);
        // if(this.currentUserValue === null){
        //     this.currentTokenSubject.next(apiTokens);
        //     return this.currentToken.pipe(map((currentToken) => { 
        //         apiTokens.refreshToken = null;
        //         apiTokens.token = null;
        //         return apiTokens
        //      }));
        // } 
        if (this.currentUserValue === null) {
            apiTokens.token = "no";
            apiTokens.refreshToken = "no";
        } else {
            //Ha be van jelentkezve a felhasználó, frissítjük a tokent
            apiTokens.token = this.currentUserValue.token;
            apiTokens.refreshToken = this.currentUserValue.refreshToken;
        }

        return this.accountFunc.refreshToken(apiTokens)
            .pipe(map((currentToken) => {
                if (currentToken.refreshToken != null) {
                    this.currentUserValue.token = currentToken.token;
                    console.log("új token!");
                    this.currentUserValue.refreshToken = currentToken.refreshToken;
                    localStorage.setItem('currentUser', JSON.stringify(this.currentUserValue));
                    this.currentUserSubject.next(this.currentUserValue);
                    this.startRefreshTokenTimer();
                }
                return currentToken;
            }));
    }

    async revokeToken(apiTokens) {
        let resultString;
        return new Promise((resolve, reject) => {
            this.accountFunc.revokeToken(apiTokens).pipe(first()).subscribe(
                (p) => {
                    resultString = p;
                },
                (e) => {
                    this.errorService.changeErrorMessage(e);
                    reject(e);
                },
                () => {
                    if (resultString.body === "OK") {
                        resolve(resultString.body);
                    } else {
                        this.errorService.changeErrorMessage(resultString.body);
                        reject(resultString.body);
                    }
                }
            );
        });
    }

    // helper methods

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        const jwtToken = JSON.parse(atob(this.currentUserValue.token.split('.')[1]));

        // set a timeout to refresh the token a minute before it expires
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}


