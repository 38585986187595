<div class="container pt-3">
    <small *ngIf="lastRefreshDate" class="fw-bold text-success">{{ 'Thryve.LastRefreshDate' | translate:locale.language }}: {{lastRefreshDate}}</small>
    <dx-data-grid id="gridContainer"
        [dataSource]="dataSource"
        [remoteOperations]="false"
        [rowAlternationEnabled]="true"
        (onInitialized)="initGrid($event)"
        [showBorders]="true"
        > 
        <dxo-editing 
        mode="row"
        [allowUpdating]="false"
        [allowDeleting]="false"
        [allowAdding]="false"
        [useIcons]="true">
    </dxo-editing>

    <dxo-filter-row 
            [visible]="true">
        </dxo-filter-row>

        <dxo-header-filter 
            [visible]="false">
        </dxo-header-filter>

        <dxi-column dataField="dataSource" caption="{{ 'Thryve.Device' | translate:locale.language }}" dataType="string">
            <dxo-lookup [dataSource]="smartDevicesSource" valueExpr="id" displayExpr="description"></dxo-lookup>
        </dxi-column>

        <dxi-column dataField="dayAt" caption= "{{ 'Core.Date' | translate:locale.language }}" dataType="date">
        </dxi-column>
        <dxi-column dataField="startTime" sortOrder="desc" caption= "{{ 'Thryve.StartTime' | translate:locale.language }}" dataType="datetime">
        </dxi-column>
        <dxi-column dataField="activityTypeCodeId" caption="{{ 'Core.CodeName' | translate:locale.language }}" dataType="string">
            <dxo-lookup [dataSource]="codesDataSource" valueExpr="id" displayExpr="descriptionTranslated"></dxo-lookup>
        </dxi-column>
        <dxi-column dataField="value" caption= "{{ 'Core.Value' | translate:locale.language }}" dataType="number">
        </dxi-column>
        <dxi-column dataField="createdAt" caption= "{{ 'Core.Created' | translate:locale.language }}" dataType="datetime">
        </dxi-column>
        <dxi-column dataField="active" caption= "{{ 'Core.EditActive' | translate:locale.language }}" dataType="boolean">
        </dxi-column>
    
    </dx-data-grid>
</div>