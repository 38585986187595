import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

/**
  * Hibát vagy infót tudunk megjeleníteni.
  * Nem lekezelt hiba esetén (pl. nem megy a backend) kiírjuk, hogy a szolgáltatás nem elérhető
  */

@Component({
  selector: 'app-info-page',
  templateUrl: './info-page.component.html',
  styleUrls: ['./info-page.component.scss']
})
export class InfoPageComponent implements OnInit {
  error = '';
  info = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.error = this.route.snapshot.paramMap.get('errorMessage');
    this.info = this.route.snapshot.paramMap.get('infoMessage');
    setTimeout(() => {
      this.router.navigate(['/login'], { queryParams: { returnUrl: "/error" } });

    }, 5000);

  }

}
