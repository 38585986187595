import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  User,
  UserResult,
  UsersFunc,
  RoleResult,
  RolesFunc,
  HealthcareProviderFunc,
  HealthcareProvider,
  LoginUser
} from '../services/webapiclient.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';

import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { loadMessages, locale } from 'devextreme/localization';

import { ErrorService } from '../services/error.service';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import CustomStore from 'devextreme/data/custom_store';
import { LoggingService } from '../services/logging.service';
import { AuthenticationService } from '../services/authentication.service';
import { Role } from '../models/role';
import { Subscription } from 'rxjs';
import { SubscriptionService } from '../module-subscription/subscription-service';
import * as AspNetData from 'devextreme-aspnet-data-nojquery';

/**
 * Előfizetések kezelése (SysAdmin joggal).
 */
@Component({
  selector: 'app-subscription-admin',
  templateUrl: './subscription-admin.component.html',
  styleUrls: ['./subscription-admin.component.scss']
})
export class SubscriptionAdminComponent implements OnInit, OnDestroy {

  userResult: UserResult = {} as UserResult;
  roleResult: RoleResult = {} as RoleResult;
  healthcareProviders: HealthcareProvider[] = {} as HealthcareProvider[];
  error: string;
  baseUri: string = `${environment.apiUrl}`;
  dataSource: any;
  detailDataSource: any;
  rolesData: any;
  providersData: any;
  currentUser: LoginUser;
  sysAdmin: boolean;

  userId: string;
  transactions: any;


  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private usersFunc: UsersFunc,
    private rolesFunc: RolesFunc,
    private errorService: ErrorService,
    private authenticationService: AuthenticationService,
    private translation: L10nTranslationService,
    private healthcareProviderFunc: HealthcareProviderFunc,
    private subscriptionService: SubscriptionService,
    private log: LoggingService
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

    //Felhasználók (master)
    this.dataSource = AspNetData.createStore({
      key: 'Id',
      loadUrl: `${environment.apiUrl}UsersFunc/GetAllUsers`,
      onBeforeSend(method, ajaxOptions) {
        //ajaxOptions.xhrFields = { withCredentials: true };
      },
    });


    // this.dataSource = new CustomStore({
    //   key: "id",
    //   load: (loadOptions) => this.getAll(loadOptions)
    // });

    //Előfizetések adatforrás a gridhez (details)
    this.detailDataSource = new CustomStore({
      key: ["id"],
      load: () => this.getDetails(this.userId),
      insert: (values) => this.insertDetail(JSON.stringify(values))
      // remove: (key) => this.deleteCodeDetail(key).then()
    });

    //Szerepkörök
    this.rolesData = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: () => this.getRoles()
    });

    //Szolgáltatók
    this.providersData = new CustomStore({
      key: "id",
      loadMode: "raw",
      load: () => this.getProviders()
    });

  }


  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });
    this.currentUser = this.authenticationService.currentUserValue;
    this.sysAdmin = this.currentUser.role == Role.SysAdmin;
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  getRoles() {
    return new Promise((resolve, reject) => {
      this.rolesFunc.getAll(true).pipe(first()).subscribe(
        (p) => {
          this.roleResult = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          if (this.roleResult.error === null) {
            for (let role of this.roleResult.roles) {
              role.translation = this.translation.translate('Role.' + role.name);
            }
            resolve(this.roleResult.roles);
          } else {
            this.errorService.changeErrorMessage(this.roleResult.error);
            reject(this.roleResult.error);
          }
        }
      );
    });
  }

  getProviders() {
    return new Promise((resolve, reject) => {
      this.healthcareProviderFunc.getAll().pipe(first()).subscribe(
        (p) => {
          this.healthcareProviders = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.healthcareProviders);
        }
      );
    });

  }

  getAll(loadOptions) {
    return new Promise((resolve, reject) => {
      this.usersFunc.getAllUsers(loadOptions).pipe(first()).subscribe(
        (p) => {
          this.userResult.users = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          if (this.userResult.error === null || this.userResult.error === undefined) {
            resolve(this.userResult.users);
          } else {
            this.errorService.changeErrorMessage(this.userResult.error);
            reject(this.userResult.error);
          }
        }
      );
    });
  }

  //Details part

  getDetails(userId: string) {
    return new Promise((resolve, reject) => {
      this.subscriptionService.getTransactionsAdmin(userId).pipe(first()).subscribe(
        (p) => {
          this.transactions = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.transactions);
        }
      );
    });
  }

  //grid detail template adatforrása
  getDetailsDS(key) {
    this.userId = key;
    return this.detailDataSource;
  }

  onInitNewRow_detail(e, parentId) {
    e.data.userId = parentId;
    e.data.item = "PreDEM GOLD előfizetés";
    e.data.month = 12;
  }

  onContentReady_code(e) {
    this.moveEditColumnToLeft(e.component); // A command column bal oldalon legyen. 
  }

   moveEditColumnToLeft(dataGrid) {
      dataGrid.columnOption("command:edit", {
        visibleIndex: -1
      });
    }

  insert(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.usersFunc.insert(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.succeeded) {
            resolve("OK");
          } else {
            //reject(result.errors[0].description);
            reject(this.translation.translate('Core.' + result.errors[0].code));
          }
        }
      );
    });
  }

  update(key: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.usersFunc.update(key, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.succeeded) {
            resolve("OK");
          } else {
            reject(this.translation.translate('Core.' + result.errors[0].code));
          }
        }
      );
    });
  }

  delete(key: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.usersFunc.delete(key).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.succeeded) {
            resolve("OK");
          } else {
            reject(this.translation.translate('Core.ErrorMessage_DeleteNotPossible'));
          }
        }
      );
    });
  }

  insertDetail(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.subscriptionService.addFreeGoldSubscription(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

}
