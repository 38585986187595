import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { locale } from 'devextreme/localization';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { interval, Observable, Subject, Subscription } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { LoginUser, NotificationViewModel } from '../services/webapiclient.service';
import { NotificationService } from './notification-service.service';
import { DatePipe } from '@angular/common';

/**
 * Értesítések megjelenítése.
 * Ha be van jelentkezve a felhasználó, akkor az app komponensbe van betöltve
 */


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy {
  @ViewChild('actualNewsTmpl', { static: true }) actualNewsTmpl: TemplateRef<any>;
  @ViewChild('importantTmpl', { static: true }) importantTmpl: TemplateRef<any>;
  @ViewChild('badgeTmpl', { static: true }) badgeTmpl: TemplateRef<any>;

  infoPanel: SafeHtml;
  badgePanel: SafeHtml;
  htmlString: string;
  actualNews: boolean = false;
  badge: any;
  importantWarnings: boolean = false;
  newsIndex: number;
  modalOpen: boolean = false;
  modalOpenBadge: boolean = false;

  currentUser: LoginUser;
  error: any;
  dataSource: any;
  newNotifications: any;
  displayItems: any;
  displayImportantItems: any;
  messagesCount = 0;
  notImportantMessagesCount = 0;
  newMessagesCount?: number = null;
  timeStamp: Date = new Date(1900, 1, 1);
  onToggle = false;
  dateFormat: string;
  badgeLevel: number;
  badgeMonth: string = "1";

  subscribe: Subscription;
  @ViewChild('notifications', { static: true }) notifications: TemplateRef<any>;

  modalRef: BsModalRef;
  private translSubs$: Subscription;

  constructor(
    private notificationService: NotificationService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private http: HttpClient,
    private datePipe: DatePipe,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser) {
        this.loadNotifications();
      } else {
        this.dataSource = [];
        if (this.subscribe) {
          this.subscribe.unsubscribe();
        }

      }
    });

    this.getInfoPanelContent();
  }

  ngOnDestroy(): void {
    if (this.subscribe) {
      this.subscribe.unsubscribe();
    }
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }
  closeModal() {
    this.modalRef.hide();
    this.modalOpen = false;
    this.modalOpenBadge = false;
  }

  private getInfoPanelContent() {
    let fileName = "actualNews";
    fileName += '.' + this.locale.language.toLowerCase() + '.html';
    const path = 'assets/info/' + fileName;
    this.http.get(path, { responseType: 'text' }).subscribe(
      data => {
        this.htmlString = data;
        this.infoPanel = this.sanitizer.bypassSecurityTrustHtml(this.htmlString);
      });
  }
  private getBadgePanelContent() {
    let fileName = "badgeContent";
    fileName += '.' + this.locale.language.toLowerCase() + '.html';
    const path = 'assets/badge/' + fileName;
    this.http.get(path, { responseType: 'text' }).subscribe(
      data => {
        this.badgeLevel = this.badge[0].content;
        this.badgeMonth = this.badgeLevel == 4 ? "3" : "1";
        this.htmlString = data.replace('#', this.badge[0].content).replace('%', this.badgeMonth);
        this.badgePanel = this.sanitizer.bypassSecurityTrustHtml(this.htmlString);
      });
  }
  //Betöltjük az összes értesítést
  loadNotifications() {
    this.notificationService.getAll(this.currentUser.id, this.timeStamp.toDateString()).then(
      result => {
        this.dataSource = (result as NotificationViewModel[]);
        //Van-e aktuális hír
        this.actualNews = this.dataSource.filter(x => x.message == 'Notification.ActualNews').length > 0;
        if (this.actualNews) {
          //Vannak aktuális hírek , megmutatjuk
          this.modalRef = this.modalService.show(this.actualNewsTmpl, { class: 'modal-lg', backdrop: 'static', keyboard: false });
        }
        //Van-e megszerzett badge
        this.badge = this.dataSource.filter(x => x.message == 'Notification.BadgeReminder');
        if (this.badge && this.badge.length > 0 && !this.modalOpenBadge) {
          this.getBadgePanelContent();
          //Van megszerzett badge, megmutatjuk
          this.modalRef = this.modalService.show(this.badgeTmpl, { class: 'modal-lg', backdrop: 'static', keyboard: false });
          this.modalOpenBadge = true;
        }

        //Vanak-e fontos figyelmeztetések
        this.importantWarnings = this.dataSource.filter(x => x.type == 'important_warning').length > 0;
        if (this.importantWarnings && !this.modalOpen) {
          //Vannak fontos figyelmeztetések, megmutatjuk
          this.onShowImportant();
          this.modalRef = this.modalService.show(this.importantTmpl, { backdrop: 'static', keyboard: false });
          this.modalOpen = true;
        }
        //új értesítések száma
        this.newMessagesCount = this.dataSource.filter(x => x.newMessage && x.type != 'important_warning').length;
        if (this.newMessagesCount == 0) { this.newMessagesCount = null; }
        //Összes értesítés száma
        this.messagesCount = this.dataSource.length;
        //Nem fontos értesítés száma
        this.notImportantMessagesCount = this.dataSource.filter(x => x.type != 'important_warning').length;
        if (this.dataSource.length) {
          this.timeStamp = new Date(this.dataSource.reduce((a, b) => (a.ts > b.ts ? a : b)).ts);
        }
        this.loadNewNotifications();
        // console.log(val);
      }
    ).catch(err => {
      this.error = err;
    });
  }

  //Csak az új értesítéseket töltjük be a már betöltöttekhez képest
  loadNewNotifications() {
    const source = interval(100000); // 100mp-enként frissítjük az értesítéseket
    this.subscribe = source.subscribe(val => {
      this.newNotifications = [];
      let ts = this.datePipe.transform(this.timeStamp, 'yyyy-MM-dd  HH:mm:ss.SSS');

      this.notificationService.getAll(this.currentUser.id, ts).then(
        result => {
          this.newNotifications = (result as NotificationViewModel[]);
          for (const item of this.newNotifications) {
            this.dataSource.push(item);
          }
          if (this.newMessagesCount == null) {
            this.newMessagesCount = this.newNotifications.filter(x => x.newMessage && x.type != 'important_warning').length;
          } else {
            this.newMessagesCount = this.newMessagesCount + this.newNotifications.filter(x => x.newMessage && x.type != 'important_warning').length;
          }
          if (this.newMessagesCount === 0) { this.newMessagesCount = null; }
          this.messagesCount = this.dataSource.length;
          //Vanak-e fontos figyelmeztetések
          this.importantWarnings = this.dataSource.filter(x => x.type == 'important_warning').length > 0;
          if (this.importantWarnings && !this.modalOpen) {
            //Vannak fontos figyelmeztetések, megmutatjuk
            this.onShowImportant();
            this.modalRef = this.modalService.show(this.importantTmpl, { backdrop: 'static', keyboard: false });
            this.modalOpen = true;
          }

          //Van-e megszerzett badge
          this.badge = this.dataSource.filter(x => x.message == 'Notification.BadgeReminder');
          if (this.badge && this.badge.length > 0 && !this.modalOpenBadge) {
            this.getBadgePanelContent();
            //Van megszerzett badge, megmutatjuk
            this.modalRef = this.modalService.show(this.badgeTmpl, { class: 'modal-lg', backdrop: 'static', keyboard: false });
            this.modalOpenBadge = true;
          }

          //Nem fontos értesítés száma
          this.notImportantMessagesCount = this.dataSource.filter(x => x.type != 'important_warning').length;
          //Beállítjuk az aktuális timestamp-et a legfrissebbre
          if (this.dataSource.length) { this.timeStamp = new Date(this.dataSource.reduce((a, b) => (a.ts > b.ts ? a : b)).ts); }
          // console.log(val);
        }
      ).catch(err => {
        this.error = err;
        if (this.subscribe) this.subscribe.unsubscribe();
      });
    });
  }

  simpleClone(obj: any) {
    return Object.assign({}, obj);
  }

  //Értesítési ablak ki-be kapcsolása
  onOpen() {
    if (this.onToggle) {
      this.onToggle = false;
    } else {
      this.onToggle = true;
    }
  }
  //Fontos értesítések megtekinése
  onShowImportant() {
    this.displayImportantItems = [];
    for (const item of this.dataSource.filter(x => x.type == 'important_warning')) {
      this.displayImportantItems.push(item);
    }
  }


  //Értesítések megtekinése
  onShow() {
    this.displayItems = [];
    for (const item of this.dataSource.filter(x => x.type != 'important_warning')) {
      this.displayItems.push(item);
    }
    this.onOpen();
    // this.modalRef = this.modalService.show(this.notifications, {class: 'modal-lg', backdrop: 'static', keyboard: false});
  }

  //Értesítések bezárása
  closeNotification() {
    this.unSetNewMessages();
    this.onOpen();
  }
  //Megjelöljük az értesítés, hogy már olvastuk, nem új
  unSetNewMessages() {
    this.notificationService.unsetNewMessages(JSON.stringify(this.displayItems)).then(
      result => {
        if (result == 'OK') {
          this.newMessagesCount = null;
        } else {
          this.error = result;
          // this.loading = false;
        }
      }
    ).catch(err => {
      this.error = err;
      // this.loading = false;
    });

  }

  //Aktuális hírek deaktiválása (ne jelenjen meg többet)
  deactivateNews() {
    let index = this.dataSource.findIndex(x => x.message == 'Notification.ActualNews');
    this.notificationService.deactivate(JSON.stringify(this.dataSource[index])).then(
      result => {
        if (result == 'OK') {
          this.dataSource.splice(index, 1);
          //Összes értesítés száma
          this.messagesCount = this.dataSource.length;
        } else {
          this.error = result;
          // this.loading = false;
        }
      }
    ).catch(err => {
      this.error = err;
      // this.loading = false;
    });
    this.closeModal();
  }
  //Aktuális hírek deaktiválása (ne jelenjen meg többet)
  deactivateBadge() {
    let index = this.dataSource.findIndex(x => x.message == 'Notification.BadgeReminder');
    this.notificationService.deactivate(JSON.stringify(this.dataSource[index])).then(
      result => {
        if (result == 'OK') {
          this.dataSource.splice(index, 1);
          //Összes értesítés száma
          this.messagesCount = this.dataSource.length;
        } else {
          this.error = result;
          // this.loading = false;
        }
      }
    ).catch(err => {
      this.error = err;
      // this.loading = false;
    });
    this.closeModal();
  }

  //Fontos figyelmeztetések deaktiválása (ne jelenjen meg többet)
  deactivateImportantWarning() {
    this.notificationService.deactivateAll(JSON.stringify(this.displayImportantItems)).then(
      result => {
        if (result == 'OK') {
          this.displayImportantItems.forEach((currentValue, index) => {
            let ix = this.dataSource.findIndex(x => x.subscriptionId === currentValue.subscriptionId);
            if (ix != -1) {
              this.dataSource.splice(ix, 1);
            }
          });
          // //Összes értesítés száma
          this.messagesCount = this.dataSource.length;
        } else {
          this.error = result;
          // this.loading = false;
        }
      }
    ).catch(err => {
      this.error = err;
      // this.loading = false;
    });
    this.closeModal();
  }

  //Megjelöljük az értesítést, hogy legközelebb már ne jelenjen meg
  //Deaktiválás
  deactivate(index) {
    this.notificationService.deactivate(JSON.stringify(this.displayItems[index])).then(
      result => {
        if (result == 'OK') {
          this.displayItems.splice(index, 1);
          this.dataSource.splice(index, 1);
          //Összes értesítés száma
          this.messagesCount = this.dataSource.length;
        } else {
          this.error = result;
          // this.loading = false;
        }
      }
    ).catch(err => {
      this.error = err;
      // this.loading = false;
    });

  }
}
