import { Component, OnInit, Inject } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { ThryveService, BoolResult, StringResult, ThryveCodesAssign, ThryveCodesAssignEditModel } from '../thryve.service';
import { AuthenticationService } from '../../services/authentication.service';
import { LoginUser } from '../../services/webapiclient.service';
import { Role } from 'src/app/models/role';
import { CodesFunc, CodeWarehouseEditModel } from 'src/app/services/codes.service';

/**
 * Thryve eszközök csatlakoztatása, illetve 
 * csatlakoztatás megszüntetése
 */

@Component({
  selector: 'app-thryve-connection',
  templateUrl: './thryve-connection.component.html',
  styleUrls: ['./thryve-connection.component.scss']
})
export class ThryveConnectionComponent implements OnInit {
  thryveConnected = false;
  boolResult: BoolResult = {} as BoolResult;
  stringResult: StringResult = {} as StringResult;
  codesAssignData: ThryveCodesAssignEditModel[] = {} as ThryveCodesAssignEditModel[];
  codesSource: any;
  doctorUser = false;
  shareDevices = false;
  currentUser: LoginUser;
  error: string;
  loading= false;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    private thryveService: ThryveService,
    private codesFunc: CodesFunc,
    private authenticationService: AuthenticationService
  ) {
    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    if (this.currentUser) {
      this.doctorUser = this.currentUser.role == Role.Doctor;
      //Megnézzük, hogy van-e a felhasználónak csatlakoztatva eszköze
      this.thryveService.checkThryveConnection(this.currentUser.id)
        .subscribe(
          (data: BoolResult) => {
            if (data.error) {
              this.thryveConnected = false;
            } else {
              this.thryveConnected = data.result;
              if (this.thryveConnected) {
                //Van thryve csatlakoztatásunk, lekérjük az eszköz-kód hozzárendeléseket
                this.loadCodesAssignData();
              }
              //console.log(this.doctorUser);
              if (this.doctorUser) this.checkDevicesShare();
            }
          }
        );
    }
  }

  checkDevicesShare() {
    this.thryveService.checkDeviceSharing(this.currentUser.id)
      .subscribe(
        (data: BoolResult) => {
          if (this.boolResult.error) {
            this.shareDevices = false;
          } else {
            this.shareDevices = data.result;
          }
        }
      );
  }

  //Eszköz megosztás checkbox
  onValueChanged(e) {
    this.thryveService.setProvider(this.currentUser.id, e.value)
      .subscribe(
        (data: BoolResult) => {
          if (this.boolResult.error) {
            this.error = this.boolResult.error;
          }
        }
      );
  }

  //Még nincs csatlakoztatva eszköz, csatlakoztatunk
  thryveConnect() {
    if (this.currentUser) {
      this.thryveService.thryveConnect()
        .subscribe(
          (data: StringResult) => {
            if (this.stringResult.error) {
              this.thryveConnected = false;
            } else {
              this.stringResult.result = data.result;
              this.openDataSources(data.result);
            }
          }
        );
    }
  }
  //Elkérjük az eszközök kiválasztási képernyő url-jét
  thryveSources() {
    if (this.currentUser) {
      this.thryveService.thryveSources()
        .subscribe(
          (data: StringResult) => {
            if (this.stringResult.error) {
              this.thryveConnected = false;
            } else {
              this.stringResult.result = data.result;
              this.openDataSources(data.result);
            }
          }
        );
    }
  }

  //Megnyitjuk az eszközök kiválasztási képernyőt
  openDataSources(url) {
    // Okos eszközök kezelése 
    window.open(url, "_blank", "location=yes,toolbar=yes,scrollbars=yes,resizable=yes,width=800,height=500");
  }

  //Eszközökm kategóriák összerendelés szinkronizáció
  syncDeviceCodeAssign() {
    if (this.currentUser) {
      this.loading=true;
      this.thryveService.syncDeviceAssign()
        .subscribe(
          (data: StringResult) => {
            if (this.stringResult.error) {
              this.error = this.stringResult.error;
              this.loading=false;
            }else{
              this.loadCodesAssignData();
            }
          }
        );
    }
  }


  setActive(codeId, deviceId) {
    let category = this.codesAssignData.find(f => f.codeId == codeId);
    category.thryveDevices.forEach((currentValue, index) => {
      if (currentValue.deviceId == deviceId) {
        currentValue.active = true;
      } else {
        currentValue.active = false;
      }

    });

    let model: ThryveCodesAssign = {} as ThryveCodesAssign;
    model.active = true;
    model.codeId = codeId;
    model.deviceId = deviceId;
    model.patientId = this.currentUser.id;
    model.id = 0;
    this.thryveService.setThryveCodesAssign(this.currentUser.id, JSON.stringify(model)).then(
      result => {
        if (result != "OK") {
          this.error = <string>result;
        }
      }
    ).catch(err => {
      this.error = err;
    });

  }

  loadCodesAssignData() {
    this.thryveService.getThryveCodesAssignList(this.currentUser.id).then(
      result => {
        this.codesAssignData = <ThryveCodesAssignEditModel[]>result;
        this.loadCodesSources();
        this.loading=false;
      }
    ).catch(err => {
      this.error = err;
      this.loading=false;
    });

  }
  //Kódok betöltése
  loadCodesSources() {
    this.codesFunc.getCodesData('ThryveCategory').then(
      result => {
        this.codesSource = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
  }

}
