import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
//import { strict } from 'assert';
//import { Router } from '@angular/router';

export interface LogModel {
  logLevel: number;
  eventId?: number;
  module?: string;
  message?: string;
  exception?: string;
}

export enum LogLevel {
  Trace = 0,
  Debug = 1,
  Information = 2,
  Warning = 3,
  Error = 4,
  Critical = 5,
  None = 6
}

@Injectable()
export class LoggingService {

  baseUri = `${environment.apiUrl}`;
  route: any;

  constructor(private http: HttpClient) { }

  send(data: LogModel): Observable<any> {
    const options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' })
    };
    // console.log('LoggingService', data);
    // if ( typeof data.exception === 'object') {
    //   console.log('LoggingService ---- OBJECT');
    // }
    return this.http.post(this.baseUri + 'LoggingFunc', data, options);
  }

  callSend(logLevel: number,
    errorMessage: string,
    eventId?: number,
    exception?: string,
    module = 'app.module'): any {

    const logModel = {} as LogModel;
    let ret: any;

    logModel.logLevel = logLevel;
    logModel.message = errorMessage;
    logModel.eventId = eventId;
    logModel.exception = exception;
    logModel.module = module;

    this.send(logModel)
      .subscribe(
        response => {
          ret = response;
          return ret;
        }
      );

  }

  error(errorMessage: string,
    eventId?: number,
    exception?: string,
    module = 'app.module'): any {

    return this.callSend(LogLevel.Error,
      errorMessage,
      eventId,
      exception,
      module
    );

  }
  warning(errorMessage: string,
    eventId?: number,
    exception?: string,
    module = 'app.module'): any {

    return this.callSend(LogLevel.Warning,
      errorMessage,
      eventId,
      exception,
      module
    );

  }
  information(errorMessage: string,
    eventId?: number,
    exception?: string,
    module = 'app.module'): any {

    return this.callSend(LogLevel.Information,
      errorMessage,
      eventId,
      exception,
      module
    );

  }

}
