<div class="container mt-3">
    <div class="m-3">
        <div class="d-flex align-items-center">
            <div class="general-icons-sm me-2">
                <img src="../../../assets/images/game-results.svg">
            </div>
            <h1 class="display-6 m-0">{{ 'Game.GameResults' | translate:locale.language }}</h1>
        </div>
        <hr>
        <div class="d-lg-flex justify-content-between mb-3">
            <ul class="nav nav-pills rounded-selector mb-3 mb-lg-0">
                <li class="nav-item">
                    <a [ngClass]="selectedOption.value == 30 ? 'nav-link active' : 'nav-link '"
                        (click)="onOptionChanged(30,0)" aria-current="page" href="javascript:void(0)"><span>30 {{
                            'Core.Days' | translate:locale.language
                            }}</span></a>
                </li>
                <li class="nav-item">
                    <a [ngClass]="selectedOption.value == 90 ? 'nav-link active position-relative' : 'nav-link position-relative'"
                        (click)="onOptionChanged(90,1)" href="javascript:void(0)">
                        <span>90 {{ 'Core.Days' | translate:locale.language }}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [ngClass]="selectedOption.value == 180 ? 'nav-link active position-relative' : 'nav-link position-relative'"
                        (click)="onOptionChanged(180,2)" href="javascript:void(0)">
                        <span>180 {{ 'Core.Days' | translate:locale.language }}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [ngClass]="selectedOption.value == 365 ? 'nav-link active position-relative' : 'nav-link position-relative'"
                        (click)="onOptionChanged(365,3)" href="javascript:void(0)">
                        <span>365 {{ 'Core.Days' | translate:locale.language }}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [ngClass]="selectedOption.value == 0 ? 'nav-link active position-relative' : 'nav-link position-relative'"
                        role="button" aria-expanded="false" aria-controls="customDate" (click)="onOptionChangedCustom()"
                        href="javascript:void(0)">
                        {{ 'Core.Custom' | translate:locale.language }}
                    </a>
                </li>
            </ul>
            <div class="d-flex">
                <ul *ngIf="isDoctor" class="nav nav-pills rounded-selector me-2">
                    <li class="nav-item d-flex align-items-center px-2">
                        <div class="me-1"> zScore</div>
                        <dx-check-box [(value)]="zScore" (onValueChanged)="onzScore($event)"></dx-check-box>

                    </li>
                </ul>
                <ul class="nav nav-pills rounded-selector me-2">
                    <li class="nav-item d-flex align-items-center px-2">
                        <div class="me-1"> {{ 'Core.Allx' | translate:locale.language }}</div>
                        <dx-check-box [(value)]="showAll" (onValueChanged)="onShowAll($event)"></dx-check-box>

                    </li>
                </ul>
                <ul class="nav nav-pills rounded-selector">
                    <li class="nav-item d-flex  align-items-center px-2">
                        <div class="me-1"> {{ 'Task.AllocatedTasks' | translate:locale.language }}</div>
                        <dx-check-box [(value)]="allocatedTasks"
                            (onValueChanged)="onTasksChanged($event)"></dx-check-box>
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="customDate">
            <div class="d-md-flex justify-content-between align-items-top">
                <div class="me-3 mb-2">
                    <dx-date-box type="date" [stylingMode]="stylingMode" (onFocusOut)="startDateFocusOut($event)"
                        (onEnterKey)="startDateFocusOut($event)" (onInitialized)="initStartDate($event)"
                        [(value)]="startDate">
                        <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                            <dxi-validation-rule type="range" [max]="maxDate"></dxi-validation-rule>
                        </dx-validator>
                    </dx-date-box>
                </div>

                <div class="me-3 mb-2">
                    <dx-date-box type="date" [stylingMode]="stylingMode" (onFocusOut)="endDateFocusOut($event)"
                        (onEnterKey)="endDateFocusOut($event)" (onInitialized)="initEndDate($event)"
                        [(value)]="endDate">
                        <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                            <dxi-validation-rule type="range" [max]="maxDate"></dxi-validation-rule>
                            <dxi-validation-rule type="compare" [comparisonTarget]="checkComparison" comparisonType=">"
                                [message]="compareMessage" [reevaluate]="true"></dxi-validation-rule>
                        </dx-validator>
                    </dx-date-box>
                </div>

                <button type="button" class="btn btn-primary mb-2" (click)="onQuery()">
                    <i class="bi bi-search"></i> {{ 'Core.Query' | translate:locale.language }}
                </button>

            </div>
        </div>
        <div class="chart-size-fix">
            <dx-chart id="chart" [dataSource]="filteredDataSource" class="{{ifDoctor}}"
                (onLegendClick)="legendClick($event)" (onInitialized)="initChart($event)">

                <dxo-zoom-and-pan valueAxis="both" argumentAxis="both" [dragToZoom]="true" [allowMouseWheel]="true"
                    panKey="shift"></dxo-zoom-and-pan>

                <dxi-series argumentField="sdmtDate" valueField="sdmtResult" name="SDMT" color="#5aa6f2">
                    <dxo-point color="#5aa6f2"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="ldstDate" valueField="ldstResult" name="LDST" color="#b673dd">
                    <dxo-point color="#b673dd"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="dsstDate" valueField="dsstResult" name="DSST" color="#58e8ae">
                    <dxo-point color="#58e8ae"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="memoryClassicDate" valueField="memoryClassicResult"
                    name="M. {{ 'Game.Classic' | translate:locale.language }}" color="#a00707">
                    <dxo-point symbol="circle" color="#a00707"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="memorySemanticDate" valueField="memorySemanticResult"
                    name="M. {{ 'Game.Semantic' | translate:locale.language }}" color="#f97615">
                    <dxo-point symbol="circle" color="#f97615"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="memoryMathematicalDate" valueField="memoryMathematicalResult"
                    name="M. {{ 'Game.Mathematical' | translate:locale.language }}" color="#f4d62a">
                    <dxo-point symbol="circle" color="#f4d62a"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="colorRecognitionDate" valueField="colorRecognitionResult"
                    name="{{ 'Game.ColorRecognition' | translate:locale.language }}" color="#75188c">
                    <dxo-point symbol="circle" color="#75188c"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="shapeRecallDate" valueField="shapeRecallResult"
                    name="{{ 'Game.ShapeRecall' | translate:locale.language }}" color="#fc3535">
                    <dxo-point symbol="circle" color="#fc3535"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="icaDate" valueField="icaResult" name="IMCI" color="black">
                    <dxo-point color="black"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="tmtDateA" valueField="tmtResultA" name="TMT-A" color="#751F8c">
                    <dxo-point symbol="circle" color="#751F8c"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="tmtDateB" valueField="tmtResultB" name="TMT-B" color="#751FFc">
                    <dxo-point symbol="circle" color="#751FFc"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="wordGuessDate" valueField="wordGuessResult"
                    name="{{ 'Game.WordGuess' | translate:locale.language }}" color="#8e8876">
                    <dxo-point symbol="circle" color="#8e8876"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="wordRecallDate" valueField="wordRecallResult"
                    name="{{ 'Game.WordListRecall' | translate:locale.language }}" color="#28c649">
                    <dxo-point symbol="circle" color="#28c649"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="wordListRecallAcDate" valueField="wordListRecallAc"
                    name="{{ 'Game.WordListRecallAc' | translate:locale.language }}" color="#75FF8c">
                    <dxo-point symbol="circle" color="#75FF8c"></dxo-point>
                </dxi-series>

                <dxi-value-axis type="logarithmic" [linearThreshold]="0">
                    <dxi-constant-line [value]="-1.5" [color]="constantLineColor" dashStyle="dash" extendAxis="false">
                        <dxo-label [visible]="false">

                        </dxo-label>
                    </dxi-constant-line>
                </dxi-value-axis>


                <dxo-common-series-settings type="scatter"></dxo-common-series-settings>

                <dxo-argument-axis [workdaysOnly]="false" [tickInterval]="1">
                    <dxo-label [customizeText]="customizeTimeText"></dxo-label>
                    <dxo-grid [visible]="true"></dxo-grid>
                    <dxo-minor-grid [visible]="true"></dxo-minor-grid>
                </dxo-argument-axis>

                <dxo-legend [visible]="true" verticalAlignment="bottom" horizontalAlignment="center">
                </dxo-legend>

                <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeTooltip">
                </dxo-tooltip>

                <dxo-common-pane-settings backgroundColor="#ffffff">
                    <dxo-border [visible]="true"></dxo-border>
                </dxo-common-pane-settings>
            </dx-chart>
        </div>


        <hr>
        <!-- Eredmény táblázat -->

        <table class="table text-center ">
            <thead>
                <tr>
                    <th style="width: 90px;"></th>
                    <th>{{ 'Core.Date' | translate:locale.language }}</th>
                    <th>{{ 'Project.Type' | translate:locale.language }}</th>
                    <th>{{ 'Core.Result' | translate:locale.language }}</th>
                    <th>{{ 'Game.Mood' | translate:locale.language }}</th>
                    <th>{{ 'Task.AllocatedTask' | translate:locale.language }}</th>
                    <th>{{ 'Project.Pulse' | translate:locale.language }}</th>
                </tr>
            </thead>
            <tbody class="bg-white">
                <tr *ngFor="let item of filteredDataSource; index as i">
                    <td class="text-start">
                        <a (click)="clickDetails(item.id)" class="h5 mx-2 my-0 pointer"><i
                                class="bi bi-info-circle"></i></a>
                        <a *ngIf="item.gameType=='WordRecall'" (click)="clickPlay(item.id)"
                            class="h5 mx-2 my-0 pointer"><i class="bi bi-play-circle"></i></a>
                        <a *ngIf="item.gameType=='WordListRecallAc'" (click)="clickPlayAc(item.id)"
                            class="h5 mx-2 my-0 pointer"><i class="bi bi-play-circle"></i></a>
                    </td>
                    <td>{{item.startTime | date: dateTimeFormat}}</td>
                    <td *ngIf="item.gameType != 'ColorRecognition'">{{ 'Game.' + item.gameType |
                        translate:locale.language }}</td>
                    <td *ngIf="item.gameType == 'ColorRecognition'">{{ 'Game.' + item.subType |
                        translate:locale.language }}</td>
                    <td *ngIf="item.gameType != 'SDMTtest' && item.gameType != 'DSSTtest' && item.gameType != 'LDSTtest'"
                        [ngClass]="resultObject[item.resultCodeId].style">{{
                        item.resultNumeric | number : '1.2-2'
                        }}</td>
                    <td *ngIf="item.gameType == 'SDMTtest' || item.gameType == 'LDSTtest' || item.gameType == 'DSSTtest'"
                        class="bg-info">{{ item.resultNumeric | number : '1.2-2'
                        }}</td>
                    <td><img [src]='imagePath[item.moodLevel+3]'>
                    </td>
                    <td>
                        <h3 class="text-primary m-0"><i *ngIf="item.taskId > 0" class="bi bi-check"></i></h3>
                    </td>
                    <td><span *ngIf="item.pulseDiff > 0">{{item.pulseDiff}}% </span></td>
                </tr>
            </tbody>
        </table>


    </div>
</div>

<!-- Eredmény részletek -->
<ng-template #detailsTemplate>
    <div *ngIf="resultDetails.gameType!='SDMT' && resultDetails.gameType!='LDST' && resultDetails.gameType!='DSST'"
        [ngClass]="resultObject[resultDetails.resultCodeId].style" class="modal-header">
        <div>
            <h5>{{ 'Game.' + resultDetails.gameType | translate:locale.language}}</h5>
            <h6 class="modal-title" id="NewMeasurementLabel">{{ 'Core.Result' | translate:locale.language }} -
                {{resultObject[resultDetails.resultCodeId].name}}</h6>
        </div>

        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div *ngIf="resultDetails.gameType=='SDMT'" class="modal-header bg-info">
        <h5 class="modal-title" id="NewMeasurementLabel">SDMT - {{ 'Core.Result' | translate:locale.language }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div *ngIf="resultDetails.gameType=='LDST'" class="modal-header bg-info">
        <h5 class="modal-title" id="NewMeasurementLabel">LDST - {{ 'Core.Result' | translate:locale.language }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div *ngIf="resultDetails.gameType=='DSST'" class="modal-header bg-info">
        <h5 class="modal-title" id="NewMeasurementLabel">DSST - {{ 'Core.Result' | translate:locale.language }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">

        <!-- sdmt részletek -->
        <ul *ngIf="resultDetails.gameType=='SDMT' || resultDetails.gameType=='LDST' || resultDetails.gameType=='DSST'"
            class="list-group list-group-flush mb-3">
            <li class="list-group-item d-flex justify-content-between align-items-start">
                <span>{{ 'Game.TotalShapes' | translate:locale.language }}:</span>
                <b>{{resultDetails.allItems}}</b>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start">
                <span>{{ 'Game.GoodAnswers' | translate:locale.language }}:</span>
                <b>{{resultDetails.correctAnswers}}</b>
            <li class="list-group-item d-flex justify-content-between align-items-start">
                <span>{{ 'Game.WrongAnswers' | translate:locale.language }}:</span>
                <b>{{resultDetails.errors}}</b>
            <li class="list-group-item d-flex justify-content-between align-items-start">
                <span>{{ 'Game.MissingAnswers' | translate:locale.language }}:</span>
                <b>{{resultDetails.empty}}</b>
        </ul>
        <!-- ica részletek -->
        <ul *ngIf="resultDetails.gameType=='IcaTest'" class="list-group list-group-flush mb-3">
            <li class="list-group-item d-flex justify-content-between align-items-start">
                <span>{{ 'Game.AllPictures' | translate:locale.language }}:</span>
                <b>{{resultDetails.allItems}}</b>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start">
                <span>{{ 'Game.GoodAnswers' | translate:locale.language }}:</span>
                <b>{{resultDetails.correctAnswers}}</b>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start">
                <span>{{ 'Game.WrongAnswers' | translate:locale.language }}:</span>
                <b>{{resultDetails.errors}}</b>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start">
                <span>{{ 'Game.MissingAnswers' | translate:locale.language }}:</span>
                <b>{{resultDetails.empty}}</b>
            </li>
        </ul>
        <div *ngFor="let detail of resultDetails.details; index as r">
            <ul class="list-group mt-3 ">
                <li class="list-group-item">
                    <span>{{ 'Game.Round' | translate:locale.language }}: </span>
                    <b>{{r+1}}</b>
                </li>
                <li class="list-group-item">
                    <span>Állat?: </span>
                    <b>{{detail.animal}}</b>
                </li>
                <li class="list-group-item">
                    <span>Kezdés: </span>
                    <b>{{detail.start}}</b>
                </li>
                <li class="list-group-item">
                    <span>Befejezés:</span>
                    <b>{{detail.end}}</b>
                </li>
                <li class="list-group-item">
                    <span>Válasz időpont:</span>
                    <b>{{detail.answer_time}}</b>
                </li>
                <li class="list-group-item">
                    <span>Jó válasz?:</span>
                    <b>{{detail.answer}}</b>
                </li>

            </ul>
        </div>

        <!-- memory részletek -->
        <ul *ngIf="resultDetails.gameType=='MemoryGameClassic' || resultDetails.gameType=='MemoryGameSemantic'  || resultDetails.gameType=='MemoryGameMathematical'"
            class="list-group list-group-flush mb-3">
            <li class="list-group-item d-flex justify-content-between align-items-start">
                <span>{{ 'Game.TotalPairs' | translate:locale.language }}:</span>
                <b>{{resultDetails.cardPairs}}</b>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start">
                <span>{{ 'Game.FoundPairs' | translate:locale.language }}:</span>
                <b>{{resultDetails.matchedPairs}}</b>
            <li class="list-group-item d-flex justify-content-between align-items-start">
                <span>{{ 'Game.WrongShapes' | translate:locale.language }}:</span>
                <b>{{resultDetails.wrongPairs}}</b>
        </ul>
        <!-- word guess részletek -->
        <ul *ngIf="resultDetails.gameType=='WordGuess'" class="list-group list-group-flush mb-3">
            <li class="list-group-item d-flex justify-content-between align-items-start">
                <span>{{ 'Game.TotalLetters' | translate:locale.language }}:</span>
                <b>{{resultDetails.uniqueStringLength}}</b>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start">
                <span>{{ 'Game.FoundLetters' | translate:locale.language }}:</span>
                <b>{{resultDetails.correctLetters}}</b>
            <li class="list-group-item d-flex justify-content-between align-items-start">
                <span>{{ 'Game.WrongLetters' | translate:locale.language }}:</span>
                <b>{{resultDetails.wrongLetters}}</b>
        </ul>
        <!-- shape recall részletek -->
        <ul *ngIf="resultDetails.gameType=='ShapeRecall'" class="list-group list-group-flush mb-3">
            <ul class="list-group list-group-flush mb-3">
                <li class="list-group-item list-group-item-success d-flex justify-content-between align-items-start">
                    <span>{{ 'Game.TotalShapes' | translate:locale.language }}:</span>
                    <b>{{resultDetails.itemCount - resultDetails.additionalShapeCount}}</b>
                </li>
                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <span>{{ 'Game.FoundShapes' | translate:locale.language }}:</span>
                    <b>{{resultDetails.correctHits}}</b>
                <li class="list-group-item d-flex justify-content-between align-items-start">
                    <span>{{ 'Game.WrongShapes' | translate:locale.language }}:</span>
                    <b>{{resultDetails.wrongHits}}</b>
            </ul>
        </ul>
        <!-- word-list recall részletek -->
        <div *ngIf="resultDetails.gameType=='WordRecall'">
            <div class="list-group-item list-group-item-success mb-3">
                <span>{{ 'Game.TotalWords' | translate:locale.language }}: </span>
                <b>{{resultDetails.itemCount}}</b>
            </div>
            <div *ngFor="let round of resultDetails.recallRounds; index as r" class="mb-3">
                <ul class="list-group">
                    <li class="list-group-item">
                        <span>{{ 'Game.Round' | translate:locale.language }}: </span>
                        <b>{{r+1}}</b>
                    </li>
                    <li class="list-group-item">
                        <span>{{ 'Game.FoundWords' | translate:locale.language }}: </span>
                        <b>{{round.correctHits}}</b>
                    </li>
                    <li class="list-group-item">
                        <span>{{ 'Game.Score' | translate:locale.language }}:</span>
                        <b>{{round.roundResult | number : '1.2-2'}}</b>
                    </li>
                    <li class="list-group-item">
                        <ul class="result-details">
                            <li *ngFor="let word of round.guessWords"
                                [ngClass]="word.correct ? 'correct-list-item' : 'wrong-list-item'">
                                {{word.guessWord}}
                            </li>
                        </ul>
                    </li>

                </ul>
            </div>

        </div>
        <!-- color-recognition részletek -->
        <div *ngIf="resultDetails.gameType=='ColorRecognition'">
            <div class="list-group-item list-group-item-success mb-3">
                <span>{{ 'Game.ColorsToBeFound' | translate:locale.language }}: </span>
                <b>{{resultDetails.itemCount}}</b>
            </div>
            <div class="mb-3">
                <ul class="list-group">
                    <li class="list-group-item">
                        <span>{{ 'Game.GoodAnswers' | translate:locale.language }}: </span>
                        <b>{{resultDetails.correctHits}}</b>
                    </li>
                    <li class="list-group-item">
                        <span>{{ 'Game.WrongAnswers' | translate:locale.language }}: </span>
                        <b>{{resultDetails.itemCount - resultDetails.correctHits}}</b>
                    </li>
                    <li class="list-group-item">
                        <ul *ngIf="resultDetails.subType=='foreColorRecognition'" class="result-details">
                            <li *ngFor="let word of resultDetails.guessColors" class="{{word.foreColor}} text"
                                title="{{ word.timeOut ? 'Időtúllépés' : ''}}"
                                [ngClass]="{'correct-list-item': word.correct == word.answer,'wrong-list-item' : word.correct != word.answer && !word.timeOut,'wrong-list-item-timeout' : word.correct != word.answer && word.timeOut}">
                                {{'Colors.' + word.color | translate:locale.language}}
                            </li>
                        </ul>
                        <ul *ngIf="resultDetails.subType=='backColorRecognition'" class="result-details">
                            <li *ngFor="let word of resultDetails.guessColors" class="{{word.foreColor}} bg"
                                title="{{ word.timeOut ? 'Időtúllépés' : ''}}"
                                [ngClass]="{'correct-list-item': word.correct == word.answer,'wrong-list-item' : word.correct != word.answer && !word.timeOut,'wrong-list-item-timeout' : word.correct != word.answer && word.timeOut}">
                                {{'Colors.' + word.color | translate:locale.language}}
                            </li>
                        </ul>
                    </li>

                </ul>
            </div>

        </div>
        <!-- TMT részletek -->
        <div *ngIf="resultDetails.gameType=='TmtTestB' || resultDetails.gameType=='TmtTestA'">
            <div class="list-group-item list-group-item-success mb-3">
                <span>{{ 'Project.CompletionTime' | translate:locale.language }}: </span>
                <b>{{resultDetails.result1}} mp</b>
            </div>
        </div>
        <!-- WordListRecallAc részletek -->
        <div *ngIf="resultDetails.gameType=='WordListRecallAc'">
            <div class="list-group-item list-group-item-success mb-3">
                <span>{{ 'Game.GoodAnswers' | translate:locale.language }}: </span>
                <b>{{resultDetails.correctAnswers}}</b>
            </div>

        </div>
    </div>
</ng-template>

<!-- Szó visszaidéző visszajátszás -->
<ng-template #playTemplate>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'Game.FoundWords' | translate:locale.language }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <app-replay-recall-words [wrResult]="wrResult" [roundCount]="roundCount"></app-replay-recall-words>
    </div>
</ng-template>

<!-- Szó felidéző visszajátszás -->
<ng-template #playTemplateAc>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'Game.FoundWords' | translate:locale.language }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <app-replay-recall-words-ac [wrResult]="wrResult" [roundCount]="roundCount"></app-replay-recall-words-ac>
    </div>
</ng-template>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" (onShown)="onShown()">
</dx-load-panel>