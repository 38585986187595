import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';

@Component({
  selector: 'app-predem-info-page',
  templateUrl: './predem-info-page.component.html',
  styleUrls: ['./predem-info-page.component.scss']
})
export class PredemInfoPageComponent implements OnInit {
  infoPanel: SafeHtml;
  htmlString: string;
 
  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.getInfoPanelContent();
  }
  private getInfoPanelContent() {
    let fileName = "preDemInfo";
    fileName += '.' + this.locale.language.toLowerCase() + '.html';
    const path = 'assets/info/' + fileName;
    this.http.get(path, { responseType: 'text' }).subscribe(
      data => {
        this.htmlString = data;
        this.infoPanel = this.sanitizer.bypassSecurityTrustHtml(this.htmlString);
      });
  }

}
