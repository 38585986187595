import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import {
  ActualTask,
  AllocatedTask,
  BloodOxygen,
  BloodPressure,
  BloodPressureAndWeight,
  Catalogue,
  DoctorDashboard,
  ExtendedDoctorDashboard,
  LabResult,
  LabResultsEditModel,
  LabSumDataItem,
  LastMeasuredData,
  MsData,
  NormalBloodPressure,
  PatientData,
  PatientDataDetails,
  PatientDataDetailsModel,
  PatientDataFunc,
  SendMessageModel,
  TaskEvaluation
} from '../services/webapiclient.service';
import { ErrorService } from '../services//error.service';
import { BoolResult } from '../module-thryve/thryve.service';
import { isNgTemplate } from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(
    private patientDataFunc: PatientDataFunc,
    private errorService: ErrorService

  ) { }


  async getPatient(id: string) {
    let patientData: PatientData = {} as PatientData;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.get(id).pipe(first()).subscribe(
        (p) => {

          patientData = p;
        },
        (e) => {
          console.log('P1');
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(patientData);
        }
      );
    });
  }
  async getAllPatient(id: string) {
    let patientDataList: PatientData[] = {} as PatientData[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getAll().pipe(first()).subscribe(
        (p) => {

          patientDataList = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(patientDataList);
        }
      );
    });
  }
  async upsertPatient(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.upsert(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }
  async updatePatient(id: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.update(id, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }
  async saveDoctorAndProviderId(id: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.saveDoctorAndProviderId(id, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }
  async saveProviderConfirmation(id: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.saveProviderConfirmation(id, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }
  async getCatalogue(id: string, isGP: boolean) {
    let catalogue: Catalogue[] = {} as Catalogue[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getCatalogue(id, isGP).pipe(first()).subscribe(
        (p) => {
          catalogue = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(catalogue);
        }
      );
    });
  }

  async getDoctorDashboardData(id: string) {
    let dashBoard: ExtendedDoctorDashboard = {} as ExtendedDoctorDashboard;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getDoctorDashboardData(id).pipe(first()).subscribe(
        (p) => {
          dashBoard = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(dashBoard);
        }
      );
    });
  }

  async getLastMeasuredData(id: string) {
    let measuredData: LastMeasuredData[] = {} as LastMeasuredData[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getLastMeasuredData(id).pipe(first()).subscribe(
        (p) => {
          measuredData = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(measuredData);
        }
      );
    });
  }

  //Háziorvosi kapcsolat megszüntetése a beteg részéröl
  async cancelGpConnection(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.cancelGpConn(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }


  //Kezelés megszüntetése
  async cancelTreatment(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.cancelTreatment(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }
  //Kezelés elutasítása
  async denyTreatment(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.denyTreatment(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }
 
  //Üzenet küldése a betegnek vagy jegyzetek
  async sendMessage(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.sendMessage(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  //Jegyzetek beolvasása
  async getNotes(doctorId: string, patinetId: string) {
    let sendMessageModel: SendMessageModel[] = {} as SendMessageModel[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getNotes(doctorId, patinetId).pipe(first()).subscribe(
        (p) => {
          sendMessageModel = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(sendMessageModel);
        }
      );
    });
  }

  async deleteNote(userId: string, id: number) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.deleteNote(userId, id).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }


  //details

  async getDetails(id: string, code: string) {
    let patientDataDetails: PatientDataDetails[] = {} as PatientDataDetails[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getDetails(id, code).pipe(first()).subscribe(
        (p) => {
          patientDataDetails = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(patientDataDetails);
        }
      );
    });
  }
  async getDetailsModel(patientId: string) {
    let patientDataDetailsModel: PatientDataDetailsModel[] = {} as PatientDataDetailsModel[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getDetailsModel(patientId).pipe(first()).subscribe(
        (p) => {
          patientDataDetailsModel = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(patientDataDetailsModel);
        }
      );
    });
  }

  async insertDetail(id: string, code: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.insertDetail(id, code, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }
  async updateDetail(id: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.updateDetail(id, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }
  async deleteDetail(id: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.deleteDetail(id).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  //subDetails

  async getSubDetails(id: string) {
    let patientDataDetails: PatientDataDetails[] = {} as PatientDataDetails[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getSubDetails(id).pipe(first()).subscribe(
        (p) => {
          patientDataDetails = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(patientDataDetails);
        }
      );
    });
  }
  async insertSubDetail(patientId: string, detailId: string, code: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.insertSubDetail(patientId, detailId, code, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }
  async updateSubDetail(id: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.updateSubDetail(id, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }
  async deleteSubDetail(id: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.deleteSubDetail(id).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  //vérnyomás adatok

  async getBloodPressureList(id: string, startDate: string, endDate: string) {
    let bloodPressures: BloodPressure[] = {} as BloodPressure[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getBloodPressureList(id, startDate, endDate).pipe(first()).subscribe(
        (p) => {
          bloodPressures = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(bloodPressures);
        }
      );
    });
  }
  async getBloodPressureListAndWeight(id: string, startDate: string, endDate: string) {
    let bloodPressures: BloodPressureAndWeight[] = {} as BloodPressureAndWeight[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getBloodPressureAndWeightList(id, startDate, endDate).pipe(first()).subscribe(
        (p) => {
          bloodPressures = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(bloodPressures);
        }
      );
    });
  }
  async getNormalBloodPressure(id: string) {
    let normalBloodPressure: NormalBloodPressure = {} as NormalBloodPressure;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getNormalBloodPressure(id).pipe(first()).subscribe(
        (p) => {
          normalBloodPressure = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(normalBloodPressure);
        }
      );
    });
  }

  async addBloodPressure(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.addBloodPressure(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }
  async updateBloodPressure(id: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.updateBloodPressure(id, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }
  async deleteBloodPressure(userId: string, id: number) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.deleteBloodPressure(userId, id).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  //véroxygén

  async getBloodOxygenList(id: string, startDate: string, endDate: string, descOrder: boolean) {
    let bloodOxygen: BloodOxygen[] = {} as BloodOxygen[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getBloodOxygenList(id, startDate, endDate, descOrder).pipe(first()).subscribe(
        (p) => {
          bloodOxygen = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(bloodOxygen);
        }
      );
    });
  }
  async addBloodOxygen(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.addBloodOxygen(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }
  async updateBloodOxygen(id: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.updateBloodOxygen(id, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }
  async deleteBloodOxygen(userId: string, id: number) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.deleteBloodOxygen(userId, id).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }


  //labor eredmények

  async getLaborResults(userId: string) {
    let labResult: LabResult[] = {} as LabResult[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getLaborResults(userId).pipe(first()).subscribe(
        (p) => {
          labResult = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(labResult);
        }
      );
    });
  }
  async getLabSumData(userId: string) {
    let labResult: LabSumDataItem[] = {} as LabSumDataItem[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getLabSumData(userId).pipe(first()).subscribe(
        (p) => {
          labResult = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(labResult);
        }
      );
    });
  }

  async getLabResultsCreate(userId: string) {
    let labResult: LabResultsEditModel = {} as LabResultsEditModel;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getLabResultsCreate(userId).pipe(first()).subscribe(
        (p) => {
          labResult = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(labResult);
        }
      );
    });
  }
  async getLabResultsUpdate(userId: string, webId: string) {
    let labResult: LabResultsEditModel = {} as LabResultsEditModel;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getLabResultsUpdate(userId, webId).pipe(first()).subscribe(
        (p) => {
          labResult = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(labResult);
        }
      );
    });
  }

  async updateLabData(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.updateLabData(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  async deleteLabData(userId: string, webId: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.deleteLabData(userId, webId).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }
  async checkLabDataRange(appReference: string, selectedUnit: string, labValue: number) {
    let result: BoolResult = {} as BoolResult;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.checkLabDataRange(appReference, selectedUnit, labValue).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(result);
        }
      );
    });
  }

  //Feladatok

  async insertTask(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.insertTask(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  async getTaskList(userId: string) {
    let taskResult: AllocatedTask[] = {} as AllocatedTask[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getTasks(userId).pipe(first()).subscribe(
        (p) => {
          taskResult = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(taskResult);
        }
      );
    });
  }
  async getActualTaskList(userId: string, actual: boolean) {
    let taskResult: ActualTask[] = {} as ActualTask[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getActualTasks(userId, actual).pipe(first()).subscribe(
        (p) => {
          taskResult = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(taskResult);
        }
      );
    });
  }
  async startActualTask(userId: string, id: number) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.startTask(userId, id).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  async deleteTask(userId: string, id: number) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.deleteTask(userId, id).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  async getTasksEvaluationList(userId: string) {
    let taskResult: TaskEvaluation[] = {} as TaskEvaluation[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getTasksForEvaluation(userId).pipe(first()).subscribe(
        (p) => {
          taskResult = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(taskResult);
        }
      );
    });
  }
  async getTaskSheetList(userId: string, taskId: number) {
    let taskResult: TaskEvaluation[] = {} as TaskEvaluation[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getTaskSheet(userId, taskId).pipe(first()).subscribe(
        (p) => {
          taskResult = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(taskResult);
        }
      );
    });
  }
 
  async setTaskToEvaluated(userId: string, taskId: number, providerId: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.setTaskToEvaluated(userId, taskId, providerId).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }


  //mySignals

  async getLastMeasurement() {
    let msData: MsData[] = {} as MsData[];
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getLastMeasurement().pipe(first()).subscribe(
        (p) => {
          msData = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message);
          reject(e.message);
        },
        () => {
          resolve(msData);
        }
      );
    });
  }

  async saveMeasurements(userId: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.saveMeasurements(userId, values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  async uploadFile(myFile: FormData, Id: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.upload(myFile, Id).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === 'OK') {
            resolve('OK');
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  async getUploadedFileURL(uploadFileId: string) {
    let result: string;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.getUploadedFileUrl(uploadFileId).pipe(first()).subscribe(
        (p) => {
          result = p.result;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          resolve(result);
        }
      );
    });
  }

  async deleteUploadFile(uploadFileId: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.deleteUploadFile(uploadFileId).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === 'OK') {
            resolve('OK');
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  async setLanguage(userId: string, lang: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.patientDataFunc.setLanguage(userId, lang).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }


}
