import { Component, OnInit, Inject, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { L10N_LOCALE, L10nLocale, L10nTranslationService, L10nValidation, L10nNumberFormatOptions, L10nIntlService, L10nDateTimeFormatOptions } from 'angular-l10n';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { AuthenticationService } from '../../services/authentication.service';
import {
   AuthenticateModel, LoginUser, TermsAndConditions
} from '../../services/webapiclient.service';
import { TermsService } from '../../services/terms.service';

/**
  * Bejelentkezés.
  * Új jelszó igénylése belépési név alapján. (elfelejtett jelszó)
  * Új regisztráció indítása (átirányítás a regisztrációs komponensre)
  */
@Component({ selector: 'app-login', templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
   loginForm: FormGroup;
   loading = false;
   modalLoading = false;
   submitted = false;
   returnUrl: string;
   error = '';
   modalError = '';
   authenticateModel: AuthenticateModel = {} as AuthenticateModel;
   @ViewChild('resetPasswordtmpl', { static: true }) resetPasswordtmpl: TemplateRef<any>;
   @ViewChild('resetPasswordConfirmtmpl', { static: true }) resetPasswordConfirmtmpl: TemplateRef<any>;
   @ViewChild('termInfo', { static: true }) termInfo: TemplateRef<any>;


   modalRef: BsModalRef;
   confirmModalRef: BsModalRef;

   needAcceptance: Boolean = false;
   actualTerms: any;
   currentUser: LoginUser;

   userName = '';

   constructor(
      @Inject(L10N_LOCALE) public locale: L10nLocale,
      private formBuilder: FormBuilder,
      private route: ActivatedRoute,
      private router: Router,
      private termsService: TermsService,
      private authenticationService: AuthenticationService,
      private modalService: BsModalService
   ) {
      // // redirect to home if already logged in
      // if (this.authenticationService.currentUserValue &&
      //    this.authenticationService.currentUserValue.error === null) {
      //       this.authenticationService.logout();
      //       //this.router.navigate(['/']);
      // }
   }

   ngOnInit() {

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

      this.currentUser = this.authenticationService.currentUserValue;
      //console.log(this.currentUser);
      if (this.currentUser && this.currentUser.displayName != null) {
         //Már be van lépve a felhasználó
         //Megnézzük, hogy nem járt-e le a token
         const jwtToken = JSON.parse(atob(this.currentUser.token.split('.')[1]));
         const expires = new Date(jwtToken.exp * 1000);
         // console.log(expires,new Date());
         // console.log(expires < new Date());
         if (expires < new Date()) {
            //Lejárt a token, frissítünk
            this.authenticationService.refreshToken().subscribe(
               result => {
                  if (result && result != null && result.token != null) {
                     //Sikeres frissítés, átirányítjuk a fő oldalra
                     this.router.navigate(['/']);
                  }else{
                     this.authenticationService.logout();
                  }
               },
               error => {
                  this.authenticationService.logout();
               }
            );
         }else{
            //Jó a token
            if(this.returnUrl != "/error"){
               //Nem hiba miatt vagyunk itt
               this.router.navigate([this.returnUrl]);
            }
         }
      }


      this.loginForm = this.formBuilder.group({
         username: ['', Validators.required],
         password: ['', Validators.required],
         acceptConditions: ['']
      });

      this.f['acceptConditions'].setValue(false);

   }

   // convenience getter for easy access to form fields
   get f() { return this.loginForm.controls; }

   onSubmit() {
      this.submitted = true;

      // stop here if form is invalid
      if (this.loginForm.invalid) {
         return;
      }

      this.loading = true;
      this.authenticateModel.password = this.f.password.value;
      this.authenticateModel.username = this.f.username.value;
      this.authenticateModel.acceptConditions = this.f.acceptConditions.value;

      if(this.authenticateModel.acceptConditions == null){
         this.authenticateModel.acceptConditions = false;
      }
      this.authenticationService.login(this.authenticateModel).then(data => {
         this.router.navigate([this.returnUrl]);
      }).catch(err => {
         this.error = err;
         //Ha új ÁSZF van, akkor el kell fogadni.
         if (err == "Core.NewTerms") this.needAcceptance = true;
         this.loading = false;
      });
   }
   resetPassword() {
      this.modalRef = this.modalService.show(this.resetPasswordtmpl, { backdrop: 'static', keyboard: false });
   }

   closeModal() {
      //this.clearError();
      this.modalRef.hide();
   }

   //ÁSZF megtekintése
   getTermsInfo() {
      this.termsService.getActualTerms().then(
         result => {
            this.actualTerms = <TermsAndConditions>result;
            this.modalRef = this.modalService.show(this.termInfo, { class: 'modal-lg', backdrop: 'static', keyboard: false });
         }
      ).catch(err => {
         this.error = err;
      });

   }

   launchFbLogin() {
      this.router.navigate(['/facebook-login']);
   }
   launchGoogleLogin() {
      this.router.navigate(['/google-login']);
   }

   resetPasswordSubmit(userName: string) {
      this.modalError = "";
      this.modalLoading = true;
      this.authenticationService.forgotPassword(userName).then(
         result => {
            this.modalLoading = false;
            this.closeModal();
            this.modalRef = this.modalService.show(this.resetPasswordConfirmtmpl, { backdrop: 'static', keyboard: false });

            //this.cdr.markForCheck();
         }

      ).catch(err => {
         this.modalError = err;
         this.modalLoading = false;
         //this.cdr.markForCheck();
      });
   }

   registration() {
      this.router.navigate(['/registration']);
   }

}
