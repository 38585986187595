import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { PatientService } from 'src/app/module-patient/patient.service';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { loadMessages, locale } from 'devextreme/localization';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LastMeasuredData, LoginUser, PatientDataViewModel, TaskEvaluation } from 'src/app/services/webapiclient.service';
import { CodesFunc } from 'src/app/services/codes.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

/**
 * Háziorvos páciens klinikai adatkezelésének kezdő oldala.
 * A páciens katalógusból lehet meghívni egy páciens kiválasztása által.
 */


@Component({
  selector: 'app-patient-edit-gp',
  templateUrl: './patient-edit-gp.component.html',
  styleUrls: ['./patient-edit-gp.component.scss']
})
export class PatientEditGpComponent implements OnInit, OnDestroy {

  patientDataViewModel: PatientDataViewModel = {} as PatientDataViewModel;
  lastMeasuredData: LastMeasuredData[];
  lastMeasuredDataModel: LastMeasuredData[];

  codesSource: any;
  dataSource: any;

  currentUser: LoginUser;
  dateFormat: string;
  diagnosisDate: Date;

  efProgressionClass: string;

  loading = false;
  error = '';
  patientId: any;
  activeTab = 'ex1-tabs-1';
  submenuState: boolean = false;
  hamburgerState: boolean = false;
  showEvaluation: boolean = false;

  taskId: number;

  modalRef: BsModalRef;
  private translSubs$: Subscription;

  imagePath: string[] = ['assets/images/mood-form/face-3.png',
    'assets/images/mood-form/face-2.png',
    'assets/images/mood-form/face-1.png',
    'assets/images/mood-form/face0.png',
    'assets/images/mood-form/face1.png',
    'assets/images/mood-form/face2.png',
    'assets/images/mood-form/face3.png'];


  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private patientService: PatientService,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private codesFunc: CodesFunc,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private router: Router

  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

  }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });
    this.patientId = this.route.snapshot.paramMap.get('patientId');

//this.patientId = '22419821-2bce-4b79-8813-08dc377d2a88';

    this.getPatientData(this.patientId);
    this.getLastMeasuredData();

    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  getPatientData(id: string) {
    this.loading = true;
    this.patientService.getPatient(id).then(
      result => {
        this.patientDataViewModel = <PatientDataViewModel>result;
        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });
  }

  getLastMeasuredData() {
    this.loading = true;
    this.patientService.getLastMeasuredData(this.patientId).then(
      result => {
        let measuredData: LastMeasuredData = {} as LastMeasuredData;
        measuredData.code = 'NONE';
        let ix = -1;
        this.lastMeasuredData = <LastMeasuredData[]>result;
        ix = this.lastMeasuredData.findIndex(f => f.code == 'DiagnosisDate');
        if(ix != -1){
          this.diagnosisDate = this.lastMeasuredData[ix].startDate;
        }
        ix= -1;
        ix = this.lastMeasuredData.findIndex(f => f.code == 'ExpectedFutureProgression');
        if(ix != -1){
          this.efProgressionClass = this.lastMeasuredData[ix].info;
        }

        ix = -1;
        this.lastMeasuredDataModel = [];
        for (let i = 0; i < 6; i++) {
          switch (i) {
            case 0:
              ix = this.lastMeasuredData.findIndex(f => f.code == 'Systolic');
              break;
            case 1:
              ix = this.lastMeasuredData.findIndex(f => f.code == 'Diastolic');
              break;
            case 2:
              ix = this.lastMeasuredData.findIndex(f => f.code == 'Pulse');
              break;
            case 3:
              ix = this.lastMeasuredData.findIndex(f => f.code == 'Spo2');
              break;
            case 4:
              ix = this.lastMeasuredData.findIndex(f => f.code == 'Sleep');
              break;
            case 5:
              ix = this.lastMeasuredData.findIndex(f => f.code == 'Lab');
              break;
          }
          if (ix != -1) {
            this.lastMeasuredDataModel.push(this.lastMeasuredData[ix]);
            if(i==4){
              //alvás óraszám
              this.lastMeasuredDataModel[4].hour = Math.trunc(this.lastMeasuredDataModel[4].value / 60);
            }
          } else {
            this.lastMeasuredDataModel.push(measuredData);
          }
        }
        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });
  }

  clickBack() {
    this.router.navigate(['/patient-catalogue']);
  }

  tabClick(id) {
    this.activeTab = id;
    window.scroll(0,0);
  }
  submenuToggle() {
    this.submenuState = !this.submenuState;
  }
  hamburgerToggle() {
    this.hamburgerState = !this.hamburgerState;
  }
  clickEvent() {
    this.submenuState = !this.submenuState;
  }
  onShown() {
    // setTimeout(() => {
    //     this.loadingVisible = false;
    // }, 15000);
  }

  onHidden() {
    //this.employeeInfo = this.employee;
  }

}


