import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoginUser } from 'src/app/services/webapiclient.service';
import { Router } from '@angular/router';
import { locale } from 'devextreme/localization';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import messagesEs from 'node_modules/devextreme/localization/messages/es.json';
import { loadMessages } from 'devextreme/localization';
import { GameService } from '../games/services/game.service';
import { BadgesViewModel } from '../games/shared/gameResult';

@Component({
  selector: 'app-my-awards',
  templateUrl: './my-awards.component.html',
  styleUrls: ['./my-awards.component.scss']
})
export class MyAwardsComponent implements OnInit, OnDestroy {

  currentUser: LoginUser;
  error = '';
  loading: boolean = false;
  showSuccess: boolean = false;

  dataSource: any;
  rolePU: boolean = false;
  rolePUD: boolean = false;
  roleGU: boolean = false;
  roleU: boolean = false;
  dateFormat: string;

  couponCode: string;
  stylingMode = "filled";
  badgesModel = <BadgesViewModel>{};

  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private gameService: GameService,
    private router: Router
  ) {

    const huMessages = JSON.stringify(messagesHu['hu']);
    const esMessages = JSON.stringify(messagesEs['es']);
    loadMessages({
      'hu': JSON.parse(huMessages),
      'es': JSON.parse(esMessages)
    });
    this.currentUser = this.authenticationService.currentUserValue;

  }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });
    this.gameService.getBadges(this.currentUser.id).subscribe(
      (res) => {
        this.badgesModel = <BadgesViewModel>res;
      });

  }
  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  redirectToLogin() {
    this.router.navigate(['/login']);
  }

}
