<div class="container" *ngIf="sleepViewModel">
    <div class="m-3">
        <div class="d-md-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <div class="measurement-icons-sm mb-3 mb-lg-0 me-2">
                    <img src="../../../assets/images/measurement-sleep.svg">
                </div>
                <h1 class="display-6 d-block m-0">{{ 'Project.SleepData' | translate:locale.language }}</h1>
            </div>

            <div class="d-flex" role="group" *ngIf="false">
                <button (click)="back()" class="btn circle btn-primary" type="button"><i
                        class="bi bi-arrow-left"></i></button>
                <dx-date-box type="date" [stylingMode]="stylingMode" (onFocusOut)="dateFocusOut($event)"
                    (onEnterKey)="dateFocusOut($event)" (onInitialized)="initDate($event)" [(value)]="queryDate">
                    <dx-validator>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dx-validator>
                </dx-date-box>
                <button (click)="next()" class="btn circle btn-primary" type="button"><i
                        class="bi bi-arrow-right"></i></button>
                <button type="button" (click)="queryChart()" class="btn btn-success" data-bs-dismiss="modal">
                    {{ 'Core.Query' | translate:locale.language }}
                </button>
            </div>
        </div>
        <hr>
        <!-- szelekció -->
        <ul class="nav nav-pills rounded-selector mb-3">
            <li class="nav-item w-25">
                <a [ngClass]="selectedOption.value == 1 ? 'nav-link active' : 'nav-link '"
                    (click)="onOptionChanged(1,0)" aria-current="page" href="javascript:void(0)">
                    <span>{{options[0].name}}</span>
                </a>
            </li>
            <li class="nav-item w-25">
                <a [ngClass]="selectedOption.value == 7 ? 'nav-link active' : 'nav-link '"
                    (click)="onOptionChanged(7,1)" aria-current="page" href="javascript:void(0)">
                    <span>{{options[1].name}}</span>
                </a>
            </li>
            <li class="nav-item w-25">
                <a [ngClass]="selectedOption.value == 30 ? 'nav-link active' : 'nav-link '"
                    (click)="onOptionChanged(30,2)" aria-current="page" href="javascript:void(0)">
                    <span>{{options[2].name}}</span>
                </a>
            </li>
            <li class="nav-item w-25">
                <a [ngClass]="selectedOption.value == 180 ? 'nav-link active' : 'nav-link '"
                    (click)="onOptionChanged(180,3)" aria-current="page" href="javascript:void(0)">
                    <span>{{options[3].name}}</span>
                </a>
            </li>
        </ul>
        <!-- dátum léptetés -->
        <div class="d-flex justify-content-center align-items-center mb-3">
            <div>
                <button (click)="back()" class="btn circle btn-primary" type="button"><i
                        class="bi bi-arrow-left"></i></button>
            </div>
            <div *ngIf="selectedOption.value == 1">
                <h2 class="fw-light mb-0 mx-4">{{queryDate | date: dateFormat}}</h2>
            </div>
            <div *ngIf="selectedOption.value != 1">
                <h2 class="fw-light mb-0 mx-4">{{startDate | date: dateFormat}} - {{queryDate | date: dateFormat}}</h2>
            </div>
            <div>
                <button (click)="next()" class="btn circle btn-primary" type="button"><i
                        class="bi bi-arrow-right"></i></button>
            </div>

        </div>
        <div class="text-center w-100" *ngIf="selectedOption.value != 1 && avgSleepTime > ''">
            {{ 'Project.AvgSleepTime' | translate:locale.language }} {{avgSleepTime}}
        </div>
        <!-- 1 napos megjelnítés -->
        <ng-container *ngIf="selectedOption.value == 1">
            <div class="text-center w-100">
                <a  href="javascript:void(0)" (click)="goToday()">{{ 'Project.Today' | translate:locale.language }}</a>
            </div>
    
            <!-- pie chart és badge -->
            <div [ngClass]="totalSleepTime > '' ? '' : 'hide-all'">
                <div class="row row-cols-1 row-cols-lg-2 mb-3">
                    <div class="col mb-3 mb-lg-0">
                        <dx-pie-chart *ngIf="chartVisible" id="pie" type="doughnut" palette="Soft Pastel"
                            [customizePoint]="customizePoint" (onInitialized)="initPieChart($event)"
                            [dataSource]="pieDataSource" centerTemplate="holeTemplate">
                            <dxi-series valueField="sleepDuration" argumentField="sleepTypeTxt">
                                <dxo-label [visible]="true" backgroundColor="#737b84" format="percent">
                                    <dxo-connector [visible]="true"></dxo-connector>
                                </dxo-label>
                            </dxi-series>
                            <dxo-export [enabled]="false"></dxo-export>
                            <dxo-legend [visible]="false" [margin]="0" horizontalAlignment="right"
                                verticalAlignment="top"></dxo-legend>
                            <dxo-tooltip [enabled]="true" [customizeTooltip]="customizePieTooltip">
                            </dxo-tooltip>

                            <!-- <svg *dxTemplate="let pieChart of 'holeTemplate'">
                            <text text-anchor="middle" style="font-size: 24px" x="100" y="100">
                                <tspan x="100">{{totalSleepTime}}</tspan>
                            </text>
                        </svg> -->
                            <svg *dxTemplate="let pieChart of 'holeTemplate'">
                                <circle cx="100" cy="100" [attr.r]="pieChart.getInnerRadius() - 6" fill="#eee">
                                </circle>
                                <text text-anchor="middle" style="font-size: 42px" x="100" y="120" fill="#494949">
                                    <tspan x="100" dy="-10px" style="font-weight: 700">{{totalSleepTime}}</tspan>
                                </text>
                            </svg>

                        </dx-pie-chart>
                    </div>
                    <div class="col">
                        <div class="sleep-badge" *ngIf="missing !=''">
                            <img src="../../../assets/images/sleep-incomplete.svg">
                            <h3 class="text-warning mt-3">{{missing}} {{ 'Thryve.SleepMissing' |
                                translate:locale.language
                                }}</h3>
                        </div>
                        <div class="sleep-badge" *ngIf="missing ==''">
                            <img src="../../../assets/images/sleep-complete.svg">
                            <h3 class="text-success mt-3">{{ 'Thryve.SleepCongrats' | translate:locale.language }}</h3>
                        </div>

                    </div>
                </div>
                <!-- Alvás fázisok megoszlása vonalak -->
                <div>
                    <div class="row row-cols-4 mx-2 g-5">
                        <div class="col d-flex flex-column align-items-center">
                            <div class="min-h">{{rem}}</div>
                            <div class="phase-time-label rem"></div>
                            <div>{{ 'Thryve.SleepREM' | translate:locale.language }}</div>
                        </div>
                        <div class="col d-flex flex-column align-items-center">
                            <div class="min-h">{{deep}}</div>
                            <div class="phase-time-label deep"></div>
                            <div>{{ 'Thryve.SleepDeep' | translate:locale.language }}</div>
                        </div>
                        <div class="col d-flex flex-column align-items-center">
                            <div class="min-h">{{light}}</div>
                            <div class="phase-time-label light"></div>
                            <div>{{ 'Thryve.SleepLight' | translate:locale.language }}</div>
                        </div>
                        <div class="col d-flex flex-column align-items-center">
                            <div class="min-h">{{awake}}</div>
                            <div class="phase-time-label awake"></div>
                            <div>{{ 'Thryve.SleepAwake' | translate:locale.language }}</div>
                        </div>
                    </div>
                </div>
                <!-- Napi alvás, spo2 diagram -->
                <div class="chart-size-fix">
                    <dx-chart *ngIf="chartVisible" id="chart" class="sleep-spo2" [dataSource]="dataSource"
                        (onInitialized)="initChart($event)" (onPointClick)="pointClick($event)">
                        <dxi-series valueField="rem" axis="sleepPhases" name="REM" color="#716eff"></dxi-series>
                        <dxi-series valueField="deep" axis="sleepPhases"
                            name="{{ 'Thryve.SleepDeep' | translate:locale.language }}" color="#7d8ff9"></dxi-series>
                        <!-- <dxi-series valueField="mid" axis="sleepPhases" name="Mid" color="#80b4ff"></dxi-series> -->
                        <dxi-series valueField="light" axis="sleepPhases"
                            name="{{ 'Thryve.SleepLight' | translate:locale.language }}" color="#99d1ff"></dxi-series>
                        <dxi-series valueField="awake" axis="sleepPhases"
                            name="{{ 'Thryve.SleepAwake' | translate:locale.language }}" color="#8dff95"></dxi-series>
                        <dxi-series valueField="spo2" type="spline" axis="spo2" name="spo2"
                            color="#B10B38"></dxi-series>
                        <dxo-common-pane-settings backgroundColor="#ffffff"></dxo-common-pane-settings>
                        <dxo-common-series-settings argumentField="phaseStart" type="steparea">
                            <dxo-steparea>
                                <dxo-point [visible]="false"></dxo-point>
                            </dxo-steparea>
                        </dxo-common-series-settings>
                        <dxi-value-axis name="sleepPhases" position="left">
                            <dxo-label [customizeText]="customizePhasesText"></dxo-label>
                        </dxi-value-axis>
                        <dxi-value-axis name="spo2" position="right">
                        </dxi-value-axis>

                        <dxo-argument-axis>
                            <dxo-label [customizeText]="customizeTimeText"></dxo-label>
                        </dxo-argument-axis>
                        <dxo-tooltip [enabled]="true" [shared]="true" [customizeTooltip]="customizeTooltip">
                        </dxo-tooltip>

                        <dxo-legend verticalAlignment="bottom" horizontalAlignment="center">
                        </dxo-legend>
                        <dxo-export [enabled]="false"></dxo-export>
                    </dx-chart>
                </div>
            </div>
        </ng-container>
        <!-- ha nincs adat 1 napos -->
        <div *ngIf="selectedOption.value == 1 && dataLoaded" [ngClass]="totalSleepTime > '' ?  'hide-all' : '' "
            class="d-flex justify-content-center align-items-center my-4">
            <div style="width:140px;">
                <img src="../../../assets/images/no-sleepdata.svg">
            </div>
            <h3 class="display-6 m-3">{{ 'Project.NoMeasuredData' | translate:locale.language }}</h3>
        </div>
        <!-- ha nincs adat több napos -->
        <div *ngIf="selectedOption.value != 1  && dataLoaded" [ngClass]="avgSleepTime > '' ?  'hide-all' : '' "
            class="d-flex justify-content-center align-items-center my-4">
            <div style="width:140px;">
                <img src="../../../assets/images/no-sleepdata.svg">
            </div>
            <h3 class="display-6 m-3">{{ 'Project.NoMeasuredData' | translate:locale.language }}</h3>
        </div>
        <!-- több napos megjelnítés -->
        <ng-container *ngIf="selectedOption.value != 1">
            <div [ngClass]="avgSleepTime > '' ? '' : 'hide-all'">
                <dx-chart id="chartSleep" [dataSource]="sleepDataSource" (onInitialized)="initChartSleep($event)"
                    [customizeLabel]="customizeLabel" [customizePoint]="customizePointBarChart">
                    <dxi-series valueField="sleepDurationHour" argumentField="dayAt" type="bar">
                        <dxo-label [visible]="false" backgroundColor="#737b84">
                            <dxo-connector [visible]="false"></dxo-connector>
                            <dxo-format type="fixedPoint" [precision]="2">
                            </dxo-format>

                        </dxo-label>
                    </dxi-series>
                    <dxi-value-axis>
                        <dxi-constant-line [(value)]="goodSleep" color="red" dashStyle="dash" extendAxis="true">
                        </dxi-constant-line>
                    </dxi-value-axis>
                    <dxo-argument-axis [workdaysOnly]="false" [tickInterval]="1">
                        <dxo-label [customizeText]="customizeDateText"></dxo-label>
                        <dxo-grid [visible]="true"></dxo-grid>
                        <dxo-minor-grid [visible]="true"></dxo-minor-grid>
                    </dxo-argument-axis>
                    <dxo-tooltip [enabled]="true" [customizeTooltip]="customizeSleepTooltip">
                        <!-- <dxo-format type="fixedPoint" [precision]="2">
                    </dxo-format> -->
                    </dxo-tooltip>

                    <dxo-legend [visible]="false" verticalAlignment="bottom" horizontalAlignment="center">
                    </dxo-legend>

                    <!-- <dxi-series valueField="sleepType" name="Deep" argumentField="dayAt" type="stackedBar"></dxi-series> -->
                </dx-chart>
            </div>
        </ng-container>
    </div>
</div>