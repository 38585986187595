<!-- Megjelenítés -->
<div class="container">
    <div class="m-3">
        <div class="d-xl-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <h3 class="display-6 d-block m-0">Hirdetések</h3>
            </div>
            <div class="d-md-flex align-items-center">
                <div>
                    <button type="button" class="btn btn-success" (click)="clickNew()">
                        <i class="bi bi-plus"></i> Új hirdetés
                    </button>
                </div>

            </div>
        </div>
        <hr>
        <table *ngIf="adsList.length" class="table text-center ">
            <thead>
                <tr>
                    <th style="min-width:100px"></th>
                    <th>Megjegyzés</th>
                    <th>Nyelv</th>
                    <th>Doktor részére</th>
                    <th>Érvényesség (tól)</th>
                    <th>Érvényesség (ig)</th>
                    <th>Url</th>
                    <th>Poz.</th>
                   <th></th>
                </tr>
            </thead>
            <tbody class="bg-white">
                <tr *ngFor="let item of adsList; index as i">
                    <td class="text-start">
                        <a (click)="clickDelete(item.id)" class="h4 text-danger me-4 me-lg-3 pointer"><i
                                class="bi bi-trash"></i></a>
                        <a (click)="clickEdit(i)" class="h4 text-primary pointer"><i class="bi bi-pencil"></i></a>
                    </td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.lang }}</td>
                    <td>
                        <dx-check-box [(value)]="item.forDoctor" [disabled]="true">
                        </dx-check-box>

                    </td>
                    <td>{{item.validFrom | date:'yyyy-MM-dd'}}</td>
                    <td>{{item.validTo | date:'yyyy-MM-dd'}}</td>
                    <td>{{ item.link }}</td>
                    <td>{{ item.pos }}</td>
                    <td *ngIf="item.pos != 0">
                        <div class="avatar-image-sm"
                            [ngStyle]="{backgroundImage: 'url(' + uploadUrl + item.imgSmall + ')'}"></div>
                    </td>
                    <td *ngIf="item.pos == 0">
                        <div class="avatar-image-sm"
                            [ngStyle]="{backgroundImage: 'url(' + uploadUrl + item.imgBig + ')'}"></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<ng-template #editFormtmpl>
    <div *ngIf="editItem">
        <div class="modal-header">
            <h4> Hirdetés</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
            </button>
        </div>
        <div class="modal-body">
            <form id="myForm" action="your-action" (submit)="onFormSubmit($event)">
                <div class="row">
                    <div class="col-md-6 mb-3 align-self-end">
                        <div>
                            <div class="form-label">Megjegyzés
                            </div>
                            <dx-text-box type="string" [stylingMode]="stylingMode" [(value)]="editItem.description">
                                <dx-validator>
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                    </div>
                    <div class="col-md-4 mb-2 align-self-end">
                        <div>
                            <div class="form-label">Nyelv (hu,en,es)
                            </div>
                            <dx-text-box type="string" [stylingMode]="stylingMode" [(value)]="editItem.lang">
                                <dx-validator>
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                    </div>
                    <div class="col-md-2 mb-1 align-self-end">
                        <div>
                            <div class="form-label">Doktor részére
                            </div>
                            <dx-check-box [(value)]="editItem.forDoctor">
                            </dx-check-box>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3 align-self-end">
                        <div>
                            <div class="form-label">Érvényesség (tól)
                            </div>
                            <dx-date-box type="date" [stylingMode]="stylingMode" [(value)]="editItem.validFrom">
                                <dx-validator>
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-date-box>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3 align-self-end">
                        <div>
                            <div class="form-label">Érvényesség (ig)
                            </div>
                            <dx-date-box type="date" [stylingMode]="stylingMode" [(value)]="editItem.validTo">
                                <dx-validator>
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-date-box>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3 align-self-end">
                        <div>
                            <div class="form-label">Pozíció (a nagy képet mindig a 0.pozícióba kell betölteni)
                            </div>
                            <dx-text-box type="number" [stylingMode]="stylingMode" [(value)]="editItem.pos">
                                <dx-validator>
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                    <dxi-validation-rule type="range" [max]=3 [min]=0 message="Érték 0-3-ig lehet!">
                                    </dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3 align-self-end">
                        <div>
                            <div class="form-label">Url
                            </div>
                            <dx-text-box type="string" [stylingMode]="stylingMode" [(value)]="editItem.link">
                                <dx-validator>
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                    <dxi-validation-rule type="pattern" [pattern]="urlPattern">
                                    </dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 mb-3 align-self-end">
                        <div class="form-label">Kis kép (900x450 px)
                        </div>

                        <div class="d-flex flex-column w-auto">
                            <div class="avatar-image-lg" [ngStyle]="{backgroundImage: 'url(' + blobUrlSmall + ')'}">
                            </div>
                            <div class="d-flex justify-content-around mt-2">
                                <label for="small-picture">
                                    <div class="btn btn-primary circle" title="Feltöltés">
                                        <input type="file" class="d-none" id="small-picture" accept="image/*"
                                            (change)="onFileChangeSmall($event)" />
                                        <!-- <mat-icon class="icon" fontIcon="edit"></mat-icon> -->
                                        <i class="bi bi-pencil"></i>
                                    </div>
                                </label>
                                <!-- <div class="btn btn-danger circle" *ngIf="editItem.imgSmall > ''" title="Törlés"
                                (click)="deleteSmallImg()">
                                <i class="bi bi-trash"></i>
                            </div> -->
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6 mb-3 align-self-end">
                        <div class="form-label">Nagy kép (1200x300 px)
                        </div>

                        <div class="d-flex flex-column w-auto">
                            <div class="avatar-image-lg" [ngStyle]="{backgroundImage: 'url(' + blobUrlBig + ')'}"></div>
                            <div class="d-flex justify-content-around mt-2">
                                <label for="big-picture">
                                    <div class="btn btn-primary circle" title="Feltöltés">
                                        <input type="file" class="d-none" id="big-picture" accept="image/*"
                                            (change)="onFileChangeBig($event)" />
                                        <!-- <mat-icon class="icon" fontIcon="edit"></mat-icon> -->
                                        <i class="bi bi-pencil"></i>
                                    </div>
                                </label>
                                <!-- <div class="btn btn-danger circle" *ngIf="editItem.imgSmall > ''" title="Törlés"
                                    (click)="deleteSmallImg()">
                                    <i class="bi bi-trash"></i>
                                </div> -->
                            </div>

                        </div>
                    </div>

                </div>


                <div class="modal-footer">
                    <dx-button stylingMode="contained" text="{{ 'Core.EditSave' | translate:locale.language }}"
                        type="default" useSubmitBehavior=true>
                    </dx-button>
                    <button *ngIf="init=='NO' || inpPatientId" type="button" (click)="closeModal()"
                        class="btn btn-neutral" data-bs-dismiss="modal">
                        {{ 'Core.EditCancel' | translate:locale.language }}
                    </button>
                </div>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
            </form>
        </div>
    </div>
</ng-template>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" (onShown)="onShown()">
</dx-load-panel>