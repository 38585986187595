<!-- Tabs navs -->
<aside [ngClass]="hamburgerState ? ' sidenav-container opened ' : 'sidenav-container'">
  <div class="patient-name d-flex justify-content-between align-items-center">
    <div>
      <h5 class="mb-0">{{patientDataViewModel.displayName}}</h5>
      <span>{{'Core.VirtualPatient' | translate:locale.language }}</span>
    </div>
    <div (click)="hamburgerToggle()"
      [ngClass]="hamburgerState ? 'hamburger-menu opened d-flex d-md-none' : 'hamburger-menu d-flex d-md-none'">
      <span class="menu-line line-1"></span>
      <span class="menu-line line-2"></span>
      <span class="menu-line line-3"></span>
    </div>
  </div>

  <div class="sidenav">
    <ul class="nav" id="ex1" role="tablist">
      <li class="nav-item" role="presentation">
        <a class="nav-link active" (click)="tabClick('ex1-tabs-1'); hamburgerToggle()" id="ex1-tab-1"
          data-bs-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">
          <span class="nav-link-icon"><img src="../../../assets/images/side-icon-overview.svg"></span>
          <span class="nav-link-title">{{ 'Core.Overview' | translate:locale.language }}</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="ex1-tab-2" data-bs-toggle="tab" href="#ex1-tabs-2"
          (click)="tabClick('ex1-tabs-2'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-2"
          aria-selected="false">
          <span class="nav-link-icon"><img src="../../../assets/images/side-icon-anamnesis.svg"></span>
          <span class="nav-link-title">{{ 'Core.Anamnesis' | translate:locale.language }}</span>
        </a>
      </li>
      <li class="nav-item " role="presentation">
        <hr>
      </li>
      <li class="nav-item " role="presentation">
        <span class="nav-group-title">{{ 'Core.Results' | translate:locale.language}}</span>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="ex1-tab-21" data-bs-toggle="tab" href="#ex1-tabs-21"
          (click)="tabClick('ex1-tabs-21'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-21"
          aria-selected="false">
          <span class="nav-link-icon"><img src="../../../assets/images/side-icon-clinical-tests.svg"></span>
          <span class="nav-link-title">{{ 'Project.ClinicalTests' | translate:locale.language }}</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="ex1-tab-22" data-bs-toggle="tab" href="#ex1-tabs-22"
          (click)="tabClick('ex1-tabs-22'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-22"
          aria-selected="false">
          <span class="nav-link-icon"><img src="../../../assets/images/side-icon-game-results.svg"></span>
          <span class="nav-link-title">{{ 'Game.GameResults' | translate:locale.language }}</span>
        </a>
      </li>
      <li class="nav-item " role="presentation">
        <hr>
      </li>
      <li class="nav-item " role="presentation">
        <span class="nav-group-title">{{ 'Project.Measurements' |translate:locale.language }}</span>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="ex1-tab-31" data-bs-toggle="tab" href="#ex1-tabs-31"
          (click)="tabClick('ex1-tabs-31'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-31"
          aria-selected="false">
          <span class="nav-link-icon"><img src="../../../assets/images/side-icon-blood-pressure.svg"></span>
          <span class="nav-link-title">{{ 'Project.BloodPressureData' | translate:locale.language }}</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="ex1-tab-32" data-bs-toggle="tab" href="#ex1-tabs-32"
          (click)="tabClick('ex1-tabs-32'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-32"
          aria-selected="false">
          <span class="nav-link-icon"><img src="../../../assets/images/side-icon-spo.svg"></span>
          <span class="nav-link-title">{{ 'Project.BloodOxygenData' | translate:locale.language }}</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="ex1-tab-33" data-bs-toggle="tab" href="#ex1-tabs-33"
          (click)="tabClick('ex1-tabs-33'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-33"
          aria-selected="false">
          <span class="nav-link-icon"><img src="../../../assets/images/side-icon-sleep.svg"></span>
          <span class="nav-link-title">{{ 'Project.SleepData' | translate:locale.language }}</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="ex1-tab-34" data-bs-toggle="tab" href="#ex1-tabs-34"
          (click)="tabClick('ex1-tabs-34'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-34"
          aria-selected="false">
          <span class="nav-link-icon"><img src="../../../assets/images/side-icon-lab.svg"></span>
          <span class="nav-link-title"> {{ 'Project.LabResults' | translate:locale.language }}</span>

        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="ex1-tab-35" data-bs-toggle="tab" href="#ex1-tabs-35"
          (click)="tabClick('ex1-tabs-35'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-35"
          aria-selected="false">
          <span class="nav-link-icon"><img src="../../../assets/images/side-icon-smartset.svg"></span>
          <span class="nav-link-title">{{ 'SmartSet.Measurement' | translate:locale.language }}</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <hr>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="ex1-tab-4" data-bs-toggle="tab" href="#ex1-tabs-4"
          (click)="tabClick('ex1-tabs-4'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-4"
          aria-selected="false">
          <span class="nav-link-icon"><img src="../../../assets/images/side-icon-tasks.svg"></span>
          <span class="nav-link-title">{{ 'Task.Tasks' | translate:locale.language}}</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="ex1-tab-5" data-bs-toggle="tab" href="#ex1-tabs-5"
          (click)="tabClick('ex1-tabs-5'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-5"
          aria-selected="false">
          <span class="nav-link-icon"><img src="../../../assets/images/side-icon-diagnosis.svg"></span>
          <span class="nav-link-title">{{ 'Project.Diagnosis' |translate:locale.language }}</span>
        </a>
      </li>
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="ex1-tab-6" data-bs-toggle="tab" href="#ex1-tabs-6"
          (click)="tabClick('ex1-tabs-6'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-6"
          aria-selected="false">
          <span class="nav-link-icon"><img src="../../../assets/images/side-icon-messages.svg"></span>
          <span class="nav-link-title">{{ 'Core.Messages' | translate:locale.language }}</span>
        </a>
      </li>
      <hr class="my-4">
      <li class="nav-item" role="presentation">
        <a class="nav-link" id="ex1-tab-7" data-bs-toggle="tab" href="#ex1-tabs-7"
          (click)="tabClick('ex1-tabs-7'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-7"
          aria-selected="false">
          <span class="nav-link-icon"><img src="../../../assets/images/side-icon-prediction.svg"></span>
          <span class="nav-link-title">{{ 'Project.Prediction' | translate:locale.language }}</span>
        </a>
      </li>
      <hr class="my-4">
      <li class="nav-item" role="presentation">
        <a class="nav-link cancel-treatment text-danger" id="ex1-tab-8" data-bs-toggle="tab" href="#ex1-tabs-8"
          (click)="tabClick('ex1-tabs-8'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-8"
          aria-selected="false">
          <span class="nav-link-icon"><img src="../../../assets/images/side-icon-delete-reg.svg"></span>
          <span class="nav-link-title">{{ 'Project.CancelTreatment' | translate:locale.language }}</span>
        </a>
      </li>
    </ul>
  </div>


</aside>


<main>
  <!-- <div class="d-block d-md-flex justify-content-between">
      <div>
          <h2>{{patientDataViewModel.displayName}}</h2>
      </div>
      <div>
          <button type="button" (click)="clickBack()" class="btn btn-neutral btn-floating">
              <i class="bi bi-arrow-left"></i>
          </button> 
          {{ 'Project.PatientCatalogue' | translate:locale.language }}
       </div>
  </div>-->
  <!-- Tabs content -->

  <div class="tab-content" id="ex1-content">
    <div *ngIf="activeTab=='ex1-tabs-1'" class="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel"
      aria-labelledby="ex1-tab-1">
      <app-patient-profile *ngIf="!showEvaluation" [inpPatientId]="patientId"></app-patient-profile>
      <div *ngIf="!showEvaluation" class="container">
        <!-- Utolsó mérési adatok csempék -->
        <div *ngIf="lastMeasuredDataModel" class="row row-cols-2 row-cols-lg-4 g-3 m-2" role="tablist">
          <!--vérnyomás csempe-->
          <div class="col">
            <a class="nav-link h-100 pointer" id="xex1-tab-31" data-bs-toggle="tab" href="#ex1-tabs-31"
              (click)="tabClick('ex1-tabs-31')" role="tab" aria-controls="xex1-tabs-31" aria-selected="false">
              <div class="card h-100">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start align-items-center h-100">
                    <div class="measurement-icons">
                      <img src="../../../assets/images/measurement-heart.svg">
                    </div>
                    <div class="text-center mb-2">
                      <span class="h3 fw-light">{{ 'Project.BloodPressure' | translate:locale.language }}</span>
                    </div>
                    <div class="d-flex flex-column justify-content-start align-items-center flex-fill">
                      <div *ngIf="lastMeasuredDataModel[0].code != 'NONE'" class="row row-cols-2 g-0">
                        <div class="col text-end">
                          <span class="h3 fw-bold m-0">{{lastMeasuredDataModel[0].value}}</span>
                        </div>
                        <div class="col text-start d-flex align-items-center">
                          <span class="ms-2">sys</span>
                        </div>
                        <div class="col text-end">
                          <span class="h3 fw-bold  m-0">{{lastMeasuredDataModel[1].value}}</span>
                        </div>
                        <div class="col text-start d-flex align-items-center">
                          <span class="ms-2">dia</span>
                        </div>
                        <div class="col text-end">
                          <span class="h3 fw-bold  m-0">{{lastMeasuredDataModel[2].value}}</span>
                        </div>
                        <div class="col text-start d-flex align-items-center">
                          <span class="ms-2">pulse</span>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="lastMeasuredDataModel[0].code == 'NONE'" class="d-flex align-items-center flex-fill">
                      <h1 class="mb-4"><i class="bi bi-dash"></i></h1>
                    </div>
                    <div class="text-center mt-3">
                      <span class="small">{{lastMeasuredDataModel[0].startDate | date: dateFormat}}</span>
                    </div>
                  </div>

                </div>
              </div>
            </a>
          </div>
          <!--véroxigén csempe-->
          <div class="col">
            <a class="nav-link h-100 pointer" id="xex1-tab-32" data-bs-toggle="tab" href="#ex1-tabs-32"
              (click)="tabClick('ex1-tabs-32')" role="tab" aria-controls="xex1-tabs-32" aria-selected="false">
              <div class="card h-100">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start align-items-center h-100">
                    <div class="measurement-icons">
                      <img src="../../../assets/images/measurement-spo2.svg">
                    </div>
                    <div class="text-center mb-2">
                      <span class="h3 fw-light">{{ 'Project.BloodOxygen' | translate:locale.language }}</span>
                    </div>
                    <div *ngIf="lastMeasuredDataModel[3].code != 'NONE'"
                      class="d-flex flex-column justify-content-start align-items-center flex-fill">
                      <span class="h3 fw-bold m-0">{{lastMeasuredDataModel[3].value}}</span>
                      <span>SpO2</span>
                    </div>
                    <div *ngIf="lastMeasuredDataModel[3].code == 'NONE'" class="d-flex align-items-center flex-fill">
                      <h1 class="mb-4"><i class="bi bi-dash"></i></h1>
                    </div>
                    <div class="mt-3">
                      <span class="small">{{lastMeasuredDataModel[3].startDate | date: dateFormat}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <!--alvás csempe-->
          <div class="col">
            <a class="nav-link h-100 pointer" id="xex1-tab-33" data-bs-toggle="tab" href="#ex1-tabs-33"
              (click)="tabClick('ex1-tabs-33')" role="tab" aria-controls="xex1-tabs-33" aria-selected="false">
              <div class="card h-100">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start align-items-center h-100">
                    <div class="measurement-icons">
                      <img src="../../../assets/images/measurement-sleep.svg">
                    </div>
                    <div class="text-center mb-2">
                      <span class="h3 fw-light">{{ 'Project.SleepData' | translate:locale.language }}</span>
                    </div>
                    <div *ngIf="lastMeasuredDataModel[4].code != 'NONE'"
                      class="d-flex flex-column justify-content-start align-items-center flex-fill">
                      <span class="h3 fw-bold m-0">{{lastMeasuredDataModel[4].hour }} óra</span>
                      <span class="h3 fw-bold m-0">{{lastMeasuredDataModel[4].value % 60}} perc</span>
                    </div>
                    <div *ngIf="lastMeasuredDataModel[4].code == 'NONE'" class="d-flex align-items-center flex-fill">
                      <h1 class="mb-4"><i class="bi bi-dash"></i></h1>
                    </div>
                    <div class="mt-3">
                      <span class="small">{{lastMeasuredDataModel[4].startDate | date: dateFormat}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>

          <!--labor csempe-->
          <div class="col">
            <a class="nav-link h-100 pointer" id="xex1-tab-34" data-bs-toggle="tab" href="#ex1-tabs-34"
              (click)="tabClick('ex1-tabs-34')" role="tab" aria-controls="xex1-tabs-34" aria-selected="false">
              <div class="card h-100">
                <div class="card-body">
                  <div class="d-flex flex-column justify-content-start align-items-center h-100">
                    <div class="measurement-icons">
                      <img src="../../../assets/images/measurement-lab.svg">
                    </div>
                    <div class="text-center mb-2">
                      <span class="h3 fw-light">{{ 'Project.Lab' | translate:locale.language }}</span>
                    </div>
                    <div *ngIf="lastMeasuredDataModel[5].code != 'NONE'"
                      class="d-flex flex-column justify-content-start align-items-center flex-fill">
                      <span class="h3 fw-bold m-0">{{ 'Project.' +
                        lastMeasuredDataModel[5].category |
                        translate:locale.language }}</span>
                    </div>
                    <div *ngIf="lastMeasuredDataModel[5].code == 'NONE'" class="d-flex align-items-center flex-fill">
                      <h1 class="mb-4"><i class="bi bi-dash"></i></h1>
                    </div>
                    <div class="mt-3">
                      <span class="small">{{lastMeasuredDataModel[5].startDate | date: dateFormat}}</span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <!-- Diagnózis és ismert betegségek         -->
        <div class="row row-cols-1 row-cols-lg-2 g-3 m-2 tab-content" role="tablist">
          <div class="col">
            <a class="nav-link h-100 pointer" id="xex1-tab-2" data-bs-toggle="tab" href="#ex1-tabs-2"
              (click)="tabClick('ex1-tabs-2')" role="tab" aria-controls="xex1-tabs-2" aria-selected="false">
              <div class="card bg-warning h-100">
                <div class="card-body d-flex flex-column align-items-center align-items-lg-start ">
                  <h3>{{ 'Project.KnownDiseases' | translate:locale.language }}</h3>
                  <div class="d-flex flex-column flex-lg-row justify-content-start align-items-center h-100">
                    <div class="healthstatus-icons me-lg-3">
                      <img src="../../../assets/images/measurement-diseases.svg">
                    </div>
                    <div
                      class="d-flex flex-column justify-content-center align-items-center align-items-lg-start flex-fill">
                      <ng-container *ngFor="let item of lastMeasuredData">
                        <ng-container *ngIf="item.code=='KnownDiseases'">
                          <strong class="mb-3">{{item.category}}</strong>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col">
            <a class="nav-link h-100 pointer" id="xex1-tab-5" data-bs-toggle="tab" href="#ex1-tabs-5"
              (click)="tabClick('ex1-tabs-5')" role="tab" aria-controls="xex1-tabs-5" aria-selected="false">
              <div class="card {{efProgressionClass}} h-100">
                <div class="card-body d-flex flex-column align-items-center align-items-lg-start">
                  <div class="d-flex justify-content-between align-items-center mb-2 w-100">
                    <div>
                      <h3 class="m-0">{{ 'Project.Diagnosis' | translate:locale.language }}</h3>
                    </div>
                    <div>
                      <span class="small">{{diagnosisDate | date: dateFormat}}</span>
                    </div>
                  </div>
                  <div class="d-flex flex-column flex-lg-row justify-content-start align-items-center h-100">
                    <div class="healthstatus-icons me-lg-3">
                      <img src="../../../assets/images/measurement-diagnosis.svg">
                    </div>
                    <div
                      class="d-flex flex-column justify-content-center align-items-center align-items-lg-start flex-fill">
                      <strong>{{ 'Project.EfProgression' | translate:locale.language }}</strong>

                      <ng-container *ngIf="diagnosisDate">
                        <ng-container *ngFor="let item of lastMeasuredData">
                          <ng-container *ngIf="item.code=='ExpectedFutureProgression'">
                            <span class="mb-3">{{item.category}}</span>
                          </ng-container>
                        </ng-container>
                      </ng-container>

                      <div *ngIf="!diagnosisDate" class="d-flex align-items-center flex-fill">
                        <h1 class="mb-4"><i class="bi bi-dash"></i></h1>
                      </div>

                      <strong>{{ 'Project.MicProbability' | translate:locale.language }}</strong>

                      <ng-container *ngIf="diagnosisDate">
                        <ng-container *ngFor="let item of lastMeasuredData">
                          <ng-container *ngIf="item.code=='PerceivedMemorySymptoms' && item.startDate == diagnosisDate">
                            <span class="mb-3">{{item.category}}</span>
                          </ng-container>
                        </ng-container>
                      </ng-container>

                      <div *ngIf="!diagnosisDate" class="d-flex align-items-center flex-fill">
                        <h1 class="mb-4"><i class="bi bi-dash"></i></h1>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>

        <div class="m-3" *ngIf="dataSource && dataSource.length > 0">
          <div class="d-md-flex justify-content-between align-items-center ">
            <h1 class="display-6 d-block">{{ 'Task.TaskSheetsForEvaluation' | translate:locale.language }}</h1>
          </div>
          <hr>
          <ng-container *ngTemplateOutlet="openEvaluation"></ng-container>
        </div>

      </div>
      <!-- Kiértékelésre váró feladatlapok -->
      <app-evaluation *ngIf="showEvaluation" (closeViewEvent)="closeEvaluationEvent($event)" [patientId]="patientId"
        [taskId]="taskId" [onlyView]=false></app-evaluation>
      <!-- Állapotfigyelés   -->
      <app-game-result-trends *ngIf="!showEvaluation" [inpPatientId]="patientId"></app-game-result-trends>

    </div>
    <!-- Ball oldali menüpontok-->
    <!-- Anamnézis -->
    <div class="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
      <ng-container *ngIf="activeTab=='ex1-tabs-2'">
        <app-actual-condition [inpPatientId]="patientId"></app-actual-condition>
      </ng-container>
    </div>
    <!--eredmények-->
    <div class="tab-pane fade" id="ex1-tabs-21" role="tabpanel" aria-labelledby="ex1-tab-21">
      <app-patient-details *ngIf="activeTab=='ex1-tabs-21'" inpCode="CognitiveTest" inpSubCode="NO"
        [inpPatientId]="patientId"></app-patient-details>
    </div>
    <div class="tab-pane fade" id="ex1-tabs-22" role="tabpanel" aria-labelledby="ex1-tab-22">
      <app-games-report *ngIf="activeTab=='ex1-tabs-22'" [inpPatientId]="patientId"></app-games-report>
    </div>
    <!--mérések-->
    <div class="tab-pane fade" id="ex1-tabs-31" role="tabpanel" aria-labelledby="ex1-tab-31">
      <app-measured-data *ngIf="activeTab=='ex1-tabs-31'" [inpPatientId]="patientId"></app-measured-data>
    </div>
    <div class="tab-pane fade" id="ex1-tabs-32" role="tabpanel" aria-labelledby="ex1-tab-32">
      <app-blood-oxygen *ngIf="activeTab=='ex1-tabs-32'" [inpPatientId]="patientId"></app-blood-oxygen>
    </div>
    <!--ALVÁS kerül ide-->
    <div class="tab-pane fade" id="ex1-tabs-33" role="tabpanel" aria-labelledby="ex1-tab-33">
      <app-thryve-sleep-chart *ngIf="activeTab=='ex1-tabs-33'" [inpPatientId]="patientId"
        [inpQueryDate]="lastMeasuredDataModel[4].startDate"></app-thryve-sleep-chart>
    </div>
    <!-- Labor adatok -->
    <div class="tab-pane fade" id="ex1-tabs-34" role="tabpanel" aria-labelledby="ex1-tab-34">
      <app-lab-data *ngIf="activeTab=='ex1-tabs-34'" [inpPatientId]="patientId"></app-lab-data>
    </div>
    <!-- Smart set -->
    <div class="tab-pane fade" id="ex1-tabs-35" role="tabpanel" aria-labelledby="ex1-tab-35">
      <app-smart-set *ngIf="activeTab=='ex1-tabs-35'" [inpPatientId]="patientId"></app-smart-set>
    </div>
    <!-- Feladatok -->
    <div class="tab-pane fade" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
      <app-allocated-tasks *ngIf="activeTab=='ex1-tabs-4'" [patientId]="patientId"></app-allocated-tasks>
    </div>
    <!-- Diagnózis -->
    <div class="tab-pane fade" id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tab-5">
      <app-diagnosis *ngIf="activeTab=='ex1-tabs-5'" [patientId]="patientId"></app-diagnosis>
    </div>
    <div class="tab-pane fade" id="ex1-tabs-6" role="tabpanel" aria-labelledby="ex1-tab-6">
      <app-notes *ngIf="activeTab=='ex1-tabs-6'" [patientId]="patientId"></app-notes>
    </div>
    <div class="tab-pane fade" id="ex1-tabs-7" role="tabpanel" aria-labelledby="ex1-tab-7">
      <app-prediction *ngIf="activeTab=='ex1-tabs-7'" [patientId]="patientId"></app-prediction>
    </div>
    <div class="tab-pane fade" id="ex1-tabs-8" role="tabpanel" aria-labelledby="ex1-tab-8">
      <app-cancel-treatment *ngIf="activeTab=='ex1-tabs-8'" [patientId]="patientId"></app-cancel-treatment>
    </div>
  </div>

</main>

<ng-template #patientProfile>
  <div class="card m-3">
    <div class="card-body">
      <div class="d-flex justify-content-between align-items-center">
        <h4>{{patientDataViewModel.displayName}}</h4>
        <div>
          <button type="button" class="btn btn-danger m-1">Kezelés megszűntetése</button>
          <button type="button" class="btn btn-primary m-1"><i class="bi bi-pencil"></i> {{ 'Core.EditEdit' |
            translate:locale.language }}</button>
        </div>
      </div>
      <div class="d-block d-md-flex justify-content-between align-items-center">

        <div>
          <div><strong>{{ 'TAJ' | translate:locale.language }}:</strong> {{patientDataViewModel.taj}}</div>
          <div><strong>{{ 'Project.BirthDate' | translate:locale.language }}:</strong> {{patientDataViewModel.birthDate
            | date: dateFormat}}</div>
          <div><strong>{{ 'Core.Account_Register_Email' | translate:locale.language }}:</strong>
            {{patientDataViewModel.email}}</div>
          <div><strong>{{ 'Core.Entity_ContactPhone' | translate:locale.language }}:</strong>
            {{patientDataViewModel.phone }}</div>

        </div>
        <div>
          <div><strong>{{ 'Project.Sex' | translate:locale.language }}:</strong> {{patientDataViewModel.sexCodeId |
            codeTranslate:sexCodes}}</div>
          <div><strong>{{ 'Project.HighestEducation' | translate:locale.language }}:</strong>
            {{patientDataViewModel.highestEducationId | codeTranslate:educationCodes}}</div>
          <div><strong>{{ 'Project.ClinicalFamilyHistory' | translate:locale.language }}:</strong>
            {{patientDataViewModel.familyHistoryId | codeTranslate:familyHistoryCodes}}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #openEvaluation>
  <!-- desktop nézet -->
  <table class="open-evaluation-table d-md-table w-100 d-none">
    <thead>
      <tr>
        <td>{{ 'Core.Entity_Name' | translate:locale.language }}</td>
        <td>{{ 'Core.Date' | translate:locale.language }}</td>
        <!-- <td>{{ 'Core.ElapsedTime' | translate:locale.language }}</td> -->
        <td>{{ 'Game.Mood' | translate:locale.language }}</td>
        <td>{{ 'Game.Score1' | translate:locale.language }}</td>
        <td></td>
      </tr>
    </thead>
    <tbody *ngFor="let item of dataSource; index as i">
      <tr>
        <!-- <td>{{ 'Game.' + item.gameType | translate:locale.language }}</td> -->
        <td>{{ ('Game.' + item.gameType | translate) !== 'Game.' + item.gameType ? ('Game.' + item.gameType | translate)
          : item.gameType }}</td>
        <td>{{ item.startTime | date: dateFormat }}</td>
        <!-- <td>{{ item.elapsedTime}}</td> -->
        <td><img [src]='imagePath[item.moodLevel+3]'>
        </td>
        <td>{{ item.resultNumeric | number : '1.2-2'}}</td>
        <td><button class="w-100 btn btn-success" (click)="clickEvaluation(i)">{{ 'Task.Evaluation' |
            translate:locale.language }}</button></td>
      </tr>
    </tbody>
  </table>
  <!-- mobil nézet -->
  <div class="card mb-2 d-md-none" *ngFor="let item of dataSource; index as i">
    <div class="card-body">
      <h4>{{ 'Game.' + item.gameType | translate:locale.language }}</h4>
      <!-- <div class="d-flex justify-content-between align-items-center">
            <span>{{ 'Project.Type' | translate:locale.language }}</span>
            <strong><i class="bi bi-file-check"></i> Teszt</strong>
        </div> -->
      <div class="d-flex justify-content-between align-items-center">
        <span>{{ 'Core.Date' | translate:locale.language }}</span>
        <strong>{{ item.startTime | date: dateFormat }}</strong>
      </div>
      <!-- <div class="d-flex justify-content-between align-items-center">
        <span>{{ 'Core.ElapsedTime' | translate:locale.language }}</span>
        <strong>{{ item.elapsedTime}}</strong>
      </div> -->
      <div class="d-flex justify-content-between align-items-center">
        <span>{{ 'Game.Mood' | translate:locale.language }}</span>
        <strong><img [src]='imagePath[item.moodLevel+3]'></strong>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <span>{{ 'Game.Score1' | translate:locale.language }}</span>
        <strong>{{ item.resultNumeric | number : '1.2-2'}}</strong>
      </div>
      <button class="mt-2 w-100 btn btn-success" (click)="clickEvaluation(i)">{{ 'Task.Evaluation' |
        translate:locale.language }}</button>
    </div>
  </div>
</ng-template>