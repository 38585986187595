import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThryveConnectionComponent } from './thryve-connection/thryve-connection.component';

import { ThryveDataComponent } from './thryve-data/thryve-data.component';
import { ThryveSleepChartComponent } from '../statistics/thryve-sleep-chart/thryve-sleep-chart.component';


const routes: Routes = [
    { path: 't-connection', component: ThryveConnectionComponent },
    { path: 't-data', component: ThryveDataComponent},
    { path: 'sleep-chart', component: ThryveSleepChartComponent},
    { path: '', component: ThryveConnectionComponent }
 ];

/**
 * Routing tábla a Thryve modulnak, mert lazy loadinggal töltjük be.
 */

 @NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ThryveRoutingModule { }
