import { Component, Inject, Input, OnInit } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { PatientDataFunc, PredictionResult } from 'src/app/services/webapiclient.service';
import { DxCircularGaugeModule } from 'devextreme-angular/ui/circular-gauge';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-prediction',
  templateUrl: './prediction.component.html',
  styleUrls: ['./prediction.component.scss']
})

export class PredictionComponent implements OnInit {
  @Input() patientId: string;

  infoPanel: SafeHtml;
  htmlString: string;
  
  dataSource: PredictionResult;
  loading: boolean = false;
  showChart: boolean = false;
  noData: boolean = false;
  unanimous: number;
  bcolor: string = '#ebdd8f';
  currentUser: any;
  //customizeText: DxCircularGaugeTypes.ScaleLabel['customizeText'] = ({ valueText }) => `${valueText} %`;
  customizeText: 'sdfsdf';
  days: number = 180;
  options: any[];
  option: string;
  selectedOption: any;


  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private translation: L10nTranslationService,
    private patientDataFunc: PatientDataFunc,
    private authenticationService: AuthenticationService

  ) {
    //Periódus választó
    this.options = [
      {
        "name": this.translation.translate('Game.Month'),
        "value": 60
      },
      {
        "name": this.translation.translate('Game.QY'),
        "value": 90
      },
      {
        "name": this.translation.translate('Game.HY'),
        "value": 180
      },
      {
        "name": this.translation.translate('Game.Year'),
        "value": 365
      }
    ];
    this.option = this.options[2].value;
    this.selectedOption = this.options[2];

  }

  ngOnInit(): void {
    this.currentUser = this.authenticationService.currentUserValue;

    //this.patientId = '05a1a5aa-8fc0-4d1a-91b2-08db48810b00';
    //this.patientId = '249d5850-3337-40f1-7acf-08dc3824dc6a';
    this.loadDataSource();
  }

  getInfoContent() {
    let fileName = "noPrediction";
    fileName += '.' + this.locale.language.toLocaleLowerCase() + '.html';
    const path = 'assets/prediction/' + fileName;
    this.http.get(path, { responseType: 'text' }).subscribe(
      data => {
        this.htmlString = data.replace('#gameTypeCount#', this.dataSource.gameTypeCount.toString())
                              .replace('#totalGameCount#', this.dataSource.totalGameCount.toString())
                              .replace('#SDMTCount#', this.dataSource.sdmtCount.toString());

        this.infoPanel = this.sanitizer.bypassSecurityTrustHtml(this.htmlString);
      });
  }  


  loadDataSource() {
    this.loading = true;
    this.patientDataFunc.getPrediction(this.patientId, this.days).subscribe((data) => {
      this.dataSource = data;
      if (this.dataSource.correct) {
        this.unanimous = (this.dataSource.probabilityG - this.dataSource.probabilityF) * 1.8;
        if (this.unanimous > 95) this.unanimous = 95;
        if (this.unanimous < 5) this.unanimous = 5;
        this.showChart = true;
        this.noData = false;
      } else {
        this.getInfoContent();
        this.showChart = false;
        this.noData = true;
      }
      this.loading = false;
    });

  }

  onOptionChanged(days, index) {
    this.days = days;
    this.selectedOption = this.options[index];
    this.loadDataSource();
  }

  customizeTooltip = (info: any) => {
    return {
      html: "<div><div class='tooltip-header'>" +
        info.argument.toFixed(2) + "</div></div>"
    };
  }

  onShown() {
    setTimeout(() => {
      this.loading = false;
    }, 15000);
  }

}
