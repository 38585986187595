<div class="container">
    <div class="my-3">
    <div class="d-md-flex justify-content-between align-items-center">
        <h1 class="display-6 d-block">{{ 'Thryve.Spo2Data' | translate:locale.language }}</h1>
        <div class="btn-group">
          
          </div>  
      </div>

    <dx-data-grid  id="gridContainer"
        [dataSource]="dataSource"
        [remoteOperations]="false"
        [rowAlternationEnabled]="true"
        [showBorders]="true"
        > 
        <dxo-editing 
        mode="row"
        [allowUpdating]="false"
        [allowDeleting]="false"
        [allowAdding]="false"
        [useIcons]="true">
    </dxo-editing>

    <dxo-filter-row 
            [visible]="true">
        </dxo-filter-row>

        <dxo-header-filter 
            [visible]="false">
        </dxo-header-filter>

        <dxi-column dataField="dayAt" caption= "{{ 'Core.Date' | translate:locale.language }}" dataType="date">
        </dxi-column>
        <dxi-column dataField="startTime" caption= "{{ 'Thryve.StartTime' | translate:locale.language }}" dataType="datetime">
        </dxi-column>
        <dxi-column dataField="endTime" caption= "{{ 'Thryve.EndTime' | translate:locale.language }}" dataType="datetime">
        </dxi-column>
        <dxi-column dataField="sleepType" caption="{{ 'Project.Type' | translate:locale.language }}" dataType="string">
        </dxi-column>
        <dxi-column dataField="spo2" caption= "{{ 'Core.Value' | translate:locale.language }}" dataType="number">
        </dxi-column>
    
    </dx-data-grid>
</div>    
</div>