import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import {
   LoginUser, PatientDataViewModel
} from '../services/webapiclient.service';

import { AuthenticationService } from '../services/authentication.service';
import { Role } from '../models/role';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { UnregisterService } from '../services/unregister.service';
import { loadMessages, locale } from 'devextreme/localization';
import notify from 'devextreme/ui/notify';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PatientService } from '../module-patient/patient.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { LoggingService, LogLevel, LogModel } from '../services/logging.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { Ads, AdsService } from '../services/ads.service';
import { StringResult } from '../module-thryve/thryve.service';
import { first } from 'rxjs/operators';

/**
 * Kezdő oldal (home).
 * Szerepkörtől függően más-más oldalt mutatunk.
 * pl. Orvosoknak a páciens katalógust mutatjuk.
 */

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent implements OnInit, OnDestroy {
   infoPanel: SafeHtml;
   htmlString: string;
   loading = false;
   dateFormat: string;
   currentUser: LoginUser;
   doctorUser = false;
   pendingUser = false;
   error: string;
   adSrc1: Ads;
   adSrc2: Ads;
   adSrc3: Ads;
   uploadUrl = `${environment.uploadUrl}`;

   unregisterDate: any;
   message: string;
   private translSubs$: Subscription;
   patientDataViewModel: PatientDataViewModel = {} as PatientDataViewModel;

   logModel: LogModel = {} as LogModel;
   deviceInfo = null;
   homeUrlPre: string;
   homeUrlKog: string;
   rootUrl: string;

   constructor(
      @Inject(L10N_LOCALE) public locale: L10nLocale,
      private translation: L10nTranslationService,
      private unregisterService: UnregisterService,
      private router: Router,
      private patientService: PatientService,
      private authenticationService: AuthenticationService,
      public deviceService: DeviceDetectorService,
      private logging: LoggingService,
      private http: HttpClient,
      private sanitizer: DomSanitizer,
      private adsService: AdsService
   ) { }

   ngOnInit() {

      this.homeUrlPre = `${environment.homeUrlPre}`;
      this.homeUrlKog = `${environment.homeUrlKog}`;
      this.rootUrl = `${environment.rootUrl}`;

      this.deviceInfo = this.deviceService.getDeviceInfo();
      if (this.deviceInfo) {
         this.logModel.exception = this.deviceInfo.deviceType;
         this.logModel.message = JSON.stringify(this.deviceInfo);
         this.logModel.logLevel = LogLevel.Information;
         this.logModel.module = 'Games';
         this.logging.send(this.logModel)
            .subscribe(
               response => {
               }
            );
      }

      this.getInfoPanelContent();

      this.translSubs$ = this.translation.onChange().subscribe(
         x => {
            locale(x.language);
            this.dateFormat = this.translation.translate('Core.DateFormat');
            this.getAdSrc1(x.language);
            this.getAdSrc2(x.language);
            this.getAdSrc3(x.language);
         });
      this.currentUser = this.authenticationService.currentUserValue;

      if (!this.currentUser || this.currentUser.displayName == null) {
         this.loading = true;
         //Nincs belépve a felhsználó, átirányítjuk a landing page-re
         if (window.location.href.includes('predemplatform')) {
            window.location.href = this.homeUrlPre;
         } else {
            window.location.href = this.homeUrlKog;
         }
      }


      if (this.currentUser) {
         this.doctorUser = this.currentUser.role == Role.Doctor;
         this.pendingUser = this.currentUser.role == Role.USER_WITH_PENDING_REGISTRATION;
      } else {
         this.doctorUser = false;
         this.pendingUser = false;
      }
      if (this.pendingUser) this.loadDate();
      // redirect to login if not logged in
      // if (!(this.authenticationService.currentUserValue &&
      //    this.authenticationService.currentUserValue.error === null)) {
      //    this.router.navigate(['/login']);
      // }
      //Leellenőrizzük, hogy a belépett regisztrált user-nek ki van-e töltve a profil adata
      if (this.currentUser && (this.currentUser.role == Role.RegisteredUser || this.currentUser.role == Role.GoldUser || this.currentUser.role == Role.PlatinumUser)) {
         this.loading = true;
         this.patientService.getPatient(this.currentUser.id).then(
            result => {
               this.patientDataViewModel = <PatientDataViewModel>result;
               if (!this.patientDataViewModel.birthDate) this.router.navigate(['/patient-profile/YES']);
               this.loading = false;
            }
         ).catch(err => {
            this.router.navigate(['/patient-profile/YES']);
            this.loading = false;
         });
      }
   }
   ngOnDestroy() {
      if (this.translSubs$) {
         this.translSubs$.unsubscribe();
      }
   }

   getAdSrc1(lang) {
      return new Promise((resolve, reject) => {
         this.adsService.getActualAdSrc(lang, 1, false).pipe(first()).subscribe(
            (p) => {
               this.adSrc1 = p;
            },
            (e) => {
               //this.errorService.changeErrorMessage(e.message );
               reject(e.message);
            },
            () => {
               resolve(this.adSrc1);
            }
         );
      });
   }
   getAdSrc2(lang) {
      return new Promise((resolve, reject) => {
         this.adsService.getActualAdSrc(lang, 2, false).pipe(first()).subscribe(
            (p) => {
               this.adSrc2 = p;
            },
            (e) => {
               //this.errorService.changeErrorMessage(e.message );
               reject(e.message);
            },
            () => {
               resolve(this.adSrc2);
            }
         );
      });
   }
   getAdSrc3(lang) {
      return new Promise((resolve, reject) => {
         this.adsService.getActualAdSrc(lang, 3, false).pipe(first()).subscribe(
            (p) => {
               this.adSrc3 = p;
            },
            (e) => {
               //this.errorService.changeErrorMessage(e.message );
               reject(e.message);
            },
            () => {
               resolve(this.adSrc3);
            }
         );
      });
   }

   private getInfoPanelContent() {
      let fileName = "dementiaInfo";
      fileName += '.' + this.locale.language.toLowerCase() + '.html';
      const path = 'assets/info/' + fileName;
      this.http.get(path, { responseType: 'text' }).subscribe(
         data => {
            this.htmlString = data;
            this.infoPanel = this.sanitizer.bypassSecurityTrustHtml(this.htmlString);
         });
   }

   //Felfüggesztett regisztrációnál megmutatjuk, mikor lesz végleges.
   loadDate() {
      this.unregisterService.getUnregisterDate(this.currentUser.id).then(
         result => {
            this.unregisterDate = result;
            let t1 = this.translation.translate('Core.CancelUnregisterText');
            this.message = t1.replace('{0}', this.unregisterDate);
         }
      ).catch(err => {
         this.error = err;
      }
      );
   }

   //Meggondolta magát a user, visszaadjuk a regisztrációját
   cancelUnregister(e) {
      this.loading = true;
      this.unregisterService.cancelUnregister().then(
         result => {
            if (result == "OK") {
               notify({
                  message: this.translation.translate('Core.CancelUnregisterSaved'),
                  position: {
                     my: "center top",
                     at: "center top"
                  }
               }, "success", 3000);
            }
            this.loading = false;
            this.authenticationService.logout();
            this.router.navigate(['/login']);
         }
      ).catch(err => {
         this.error = err;
         this.loading = false;
      }
      );
   }
   onShown() {
      setTimeout(() => {
         this.loading = false;
      }, 3000);
   }

   onHidden() {
      //this.employeeInfo = this.employee;
   }

   startTask(root) {
      this.router.navigate([root, 0]);
   }
   redirectToLogin() {
      this.router.navigate(['/login']);
   }
   startSdmt() {
      this.router.navigate(['/games/sdmt-test/sdmt/0']);
   }

}
