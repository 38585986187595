import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ActualState, DoctorDashboard, ExtendedDoctorDashboard, LoginUser, PatientDataDetails, PatientDataDetailsModel } from 'src/app/services/webapiclient.service';
import { locale } from 'devextreme/localization';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import messagesEs from 'node_modules/devextreme/localization/messages/es.json';
import { loadMessages } from 'devextreme/localization';
import { PatientService } from 'src/app/module-patient/patient.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import DataSource from 'devextreme/data/data_source';
import { CodeCombo, CodeWarehouseEditModel, CodesFunc } from 'src/app/services/codes.service';
import ArrayStore from 'devextreme/data/array_store';
import notify from 'devextreme/ui/notify';
import { CorrectDate } from 'src/app/utils';
import { ConfirmDialogService } from 'src/app/confirm-dialog/confirm-dialog-service';
import { Role, RoleGUD, RolePUD } from 'src/app/models/role';
import { Router } from '@angular/router';

@Component({
  selector: 'app-actual-condition',
  templateUrl: './actual-condition.component.html',
  styleUrls: ['./actual-condition.component.scss']
})
export class ActualConditionComponent implements OnInit, OnDestroy {

  @Input() inpPatientId: string;

  @ViewChild('editFormtmpl', { static: true }) editFormtmpl: TemplateRef<any>;
  @ViewChild('newFormtmpl', { static: true }) newFormtmpl: TemplateRef<any>;
  @ViewChild('editSubFormtmpl', { static: true }) editSubFormtmpl: TemplateRef<any>;
  @ViewChild('newSubFormtmpl', { static: true }) newSubFormtmpl: TemplateRef<any>;

  currentUser: LoginUser;
  error: any;
  loading: boolean = false;
  patientId: string;
  dateFormat: string;
  codeTypes: any;
  code: string;
  ifDoctor: string;

  baseUri = `${environment.apiUrl}`;

  modalRef: BsModalRef;
  dataSource: any;
  dataSourcePivot: any;
  codesSource: any;
  frequencySource: any;
  regularitySource: any;
  unitSource: any;
  subDataSource: any;
  subCodesSource: any;
  groupedCodesSource: DataSource;
  medicationSource: any;
  subCode: string;
  detailId: any;
  addMode: boolean = false;
  selectedIndex: any;
  selectedSubIndex: any;
  editItem: any;
  tempItem: any;
  maxDate: Date = new Date();
  showSubCards: boolean = false;
  showCards: boolean = true;
  maxRange: number = 100;
  codeData: CodeCombo[] = {} as CodeCombo[];
  selectNew: boolean = false;

  startDateInstance: any;
  subDateInstance: any;
  settings: any;
  default_settings: any = {
    needFrequency: false,
    needRegularity: false,
    needStartDate: false,
    needStartDateTime: false,
    needQuantity: false,
    needResult: false,
    needUnitId: false,
    needPeriodInYear: false,
    newLabel: "?",
    codeName: "?",
    subCodeName: "?",
    subCodeNameExt: "?",
    subDateLabel: "?",
    newSubLabel: "?",
    dateLabel: "?",
    defaultUnit: null
  }
  doctorUser = false;
  roleGUD: boolean = false;

  actualState: ActualState[] = [];
  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private patientService: PatientService,
    private codesFunc: CodesFunc,
    private router: Router,
    private modalService: BsModalService,
    private confirmDialogService: ConfirmDialogService


  ) {

    const huMessages = JSON.stringify(messagesHu['hu']);
    const esMessages = JSON.stringify(messagesEs['es']);
    loadMessages({
      'hu': JSON.parse(huMessages),
      'es': JSON.parse(esMessages)
    });
    this.currentUser = this.authenticationService.currentUserValue;

  }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });
    if (this.inpPatientId) {
      this.patientId = this.inpPatientId;
    } else {
      this.patientId = this.currentUser.id;
    }
    this.loadCodesSources();
    this.loadDataSource();

    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser && this.currentUser.role) {
      this.roleGUD = RoleGUD.includes(this.currentUser.role as Role)
    }

    if(!this.roleGUD){
      //Nincs jogosultsága, átirányítjuk az előfizetési csomagokhoz
      this.router.navigate(['subscription/packages']);
    }


    if (this.currentUser) {
      this.doctorUser = this.currentUser.role == Role.Doctor;
      this.loadDataSource();
    }
  }
  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  simpleClone(obj: any) {
    return Object.assign({}, obj);
  }


  //Beállítjuk, hogy milyen adatokat kell kezelnünk az adott beviteli típusnál
  initSettings() {
    this.settings = this.simpleClone(this.default_settings);
    switch (this.code) {
      case 'PerceivedMemorySymptoms': {
        //Észlelt memóriazavarok
        this.settings.needFrequency = true;
        this.settings.needStartDate = true;
        this.settings.dateLabel = this.translation.translate('Project.RecognitionDate');
        this.settings.newLabel = this.translation.translate('Project.NewMemoryDisorder');
        this.settings.codeName = this.translation.translate('Project.MemoryDisorders');
        break;
      }
      case 'TobaccoProduct': {
        //Dohányzási szokások
        this.settings.needQuantity = true;
        this.settings.needUnitId = true;
        this.settings.needPeriodInYear = true;
        this.settings.needRegularity = true;
        this.settings.newLabel = this.translation.translate('Project.NewTobacco');
        this.settings.codeName = this.translation.translate('Project.Smoking');
        this.settings.defaultUnit = 427;
        break;
      }
      case 'AlcoholProducts': {
        //Alkohol fogyasztási szokások
        this.settings.needQuantity = true;
        this.settings.needUnitId = true;
        this.settings.needPeriodInYear = true;
        this.settings.needRegularity = true;
        this.settings.newLabel = this.translation.translate('Project.NewAlcohol');
        this.settings.codeName = this.translation.translate('Project.Alcohol');
        this.settings.defaultUnit = 428;
        break;
      }
      case 'HarmfulDrugs': {
        //Drog használat
        this.settings.needQuantity = true;
        this.settings.needUnitId = true;
        this.settings.needPeriodInYear = true;
        this.settings.needRegularity = true;
        this.settings.newLabel = this.translation.translate('Project.NewDrug');
        this.settings.codeName = this.translation.translate('Project.DrugUse');
        this.settings.defaultUnit = 427;
        break;
      }
      case 'CoffeeTypes': {
        //Kávé fogyasztási szokások
        this.settings.needQuantity = true;
        this.settings.needUnitId = true;
        this.settings.needPeriodInYear = true;
        this.settings.needRegularity = true;
        this.settings.newLabel = this.translation.translate('Project.NewCoffee');
        this.settings.codeName = this.translation.translate('Project.CoffeeUse');
        this.settings.defaultUnit = 428;
        break;
      }
      case 'KnownDiseases': {

        //Ismert betegségekhez
        this.settings.needStartDate = true;
        this.settings.dateLabel = this.translation.translate('Project.RecognitionDate');
        this.settings.newLabel = this.translation.translate('Project.NewKnownDiseases');
        this.settings.codeName = this.translation.translate('Project.KnownDiseases');
        //Szedett gyógyszerekhez
        this.settings.subCodeName = this.translation.translate('Project.Medications');
        this.settings.subCodeNameExt = this.translation.translate('Project.MedicationUsed');
        this.settings.subDateLabel = this.translation.translate('Project.DrogUsageStart');
        this.settings.newSubLabel = this.translation.translate('Project.NewMedication');
        break;
      }
      case 'Drug': {
        //Szedett gyógyszerekhez
        this.settings.needStartDate = true;
        this.settings.codeName = this.translation.translate('Project.Medications');
        this.settings.codeNameExt = this.translation.translate('Project.MedicationUsed');
        this.settings.dateLabel = this.translation.translate('Project.DrogUsageStart');
        this.settings.newLabel = this.translation.translate('Project.NewMedication');
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }


  //Adatok betöltése
  loadDataSource() {
    this.patientService.getDetailsModel(this.patientId).then(
      result => {
        this.dataSource = [];
        this.actualState = [];
        this.dataSource = <PatientDataDetailsModel[]>result;
        //Előforduló Code típusok feltöltése
        this.codeTypes = this.dataSource.map(function (item) {
          return item.code;
        }).filter(function (value, index, self) {
          return self.indexOf(value) === index; // remove duplicates
        });

        this.codeTypes.forEach((currentValue, index) => {
          let state: ActualState = <ActualState>{};
          state.code = currentValue;
          state.details = this.dataSource.filter(f => f.code == currentValue);
          this.actualState.push(state);
        });

        //this.loadCodesSources();
      }
    ).catch(err => {
      this.error = err;
    });

    //console.log(this.actualState);

  }

  addRow(codeId, parentId) {
    this.codesFunc.getCodesData(this.code).then(
      result => {
        this.codesSource = <CodeWarehouseEditModel[]>result;
        this.editItem = <PatientDataDetails>{};
        this.editItem.patientId = this.patientId;
        this.editItem.code = this.code;
        this.editItem.codeId = codeId;
        this.editItem.parentId = parentId;
        this.selectedIndex = this.dataSource.length - 1;
        this.addMode = true;
        this.modalRef = this.modalService.show(this.newFormtmpl, { backdrop: 'static', keyboard: false });
      }
    ).catch(err => {
      this.error = err;
    });
  }

  clickEdit(code, id) {
    this.code = code;
    this.initSettings();

    this.codesFunc.getCodesData(this.code).then(
      result => {
        this.codesSource = <CodeWarehouseEditModel[]>result;
        this.editItem = <PatientDataDetails>{};
        let state = this.actualState.filter(f => f.code == code);
        let detail = state[0].details.filter(f => f.id == id);
        this.editItem = this.simpleClone(detail[0]);

        this.selectedIndex = 0;
        //ez akkor kell, ha dátum ellenőrzés van a formon
        //át kell alakítani dátumra, másképp hibára fut az ellenőrzés
        this.editItem.startDate = new Date(this.editItem.startDate);
        this.modalRef = this.modalService.show(this.editFormtmpl, { backdrop: 'static', keyboard: false });
      }
    ).catch(err => {
      this.error = err;
    });

  }
  clickEditDrug(parentId, id) {
    this.code = 'Drug';
    this.initSettings();

    this.codesFunc.getCodesData(this.code).then(
      result => {
        this.codesSource = <CodeWarehouseEditModel[]>result;
        this.editItem = <PatientDataDetails>{};
        let state = this.actualState.filter(f => f.code == 'KnownDiseases');
        let detail = state[0].details.filter(f => f.id == parentId);
        let subDetails = detail[0].details.filter(f=>f.id == id);
        this.editItem = this.simpleClone(subDetails[0]);

        this.selectedIndex = 0;
        //ez akkor kell, ha dátum ellenőrzés van a formon
        //át kell alakítani dátumra, másképp hibára fut az ellenőrzés
        this.editItem.startDate = new Date(this.editItem.startDate);
        this.modalRef = this.modalService.show(this.editFormtmpl, { backdrop: 'static', keyboard: false });
      }
    ).catch(err => {
      this.error = err;
    });

  }

  clickDelete() {
    const that = this;
    this.confirmDialogService.confirmThis(this.translation.translate('Core.ConfirmDeleting'), function () {
      if (that.editItem.id) {
        that.patientService.deleteDetail(that.editItem.id).then(
          result => {
            if (result == "OK") {
              that.loadDataSource();
              that.closeModal();
            } else {
              that.error = result;
            }
          }
        ).catch(err => {
          that.error = err;
        });
      }
    }, function () {
      //alert("No clicked");
    })
  }

  //Kódok betöltése
  loadCodesSources() {
    this.codesFunc.getCodesData('FrequencyOfSymptoms').then(
      result => {
        this.frequencySource = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
    this.codesFunc.getCodesData('Frequency').then(
      result => {
        this.regularitySource = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
    this.codesFunc.getCodesData('UnitOfQuantity').then(
      result => {
        this.unitSource = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
    if (this.subCode != 'NO') {
      //Van alkód, azt is betöltjük
      this.codesFunc.getCodesCombo(this.subCode).then(
        result => {
          this.subCodesSource = <CodeCombo[]>result;
          this.groupedCodesSource = new DataSource({
            store: new ArrayStore({
              data: this.subCodesSource,
              key: "id"
            }),
            group: "category"
          });
        }
      ).catch(err => {
        this.error = err;
      });

    }
  }

  newAnamnesis(code) {
    this.code = code;
    this.initSettings();
    this.addRow(0,null);
  }

  newMedication(code, parentId) {
    this.code = code;
    this.initSettings();
    this.addRow(0,parentId);
  }

  selectionScreen(){
    this.selectNew = true;
  }
  closeModal() {
    this.modalRef.hide();
    if (this.addMode) {
      //Új bevitelt szakítunk meg
      this.addMode = false;
    }
    this.selectNew = false;
  }
  startDateFocusOut(s) {
    CorrectDate(this.startDateInstance);
  }

  initStartDate(e) {
    this.startDateInstance = e.component;
  }

  subDateFocusOut(s) {
    CorrectDate(this.subDateInstance);
  }

  initSubDate(e) {
    this.subDateInstance = e.component;
  }
  onFormSubmit = function (e, index) {
    this.error = "";
    this.loading = true;
    //Ha csak dátumot kérünk be a devextreme date-box-nál, akkor 0 órát ad vissza
    //hozzá kell adni 1 órát, hogy ne az előző napra konvertája a JSON.stringify
    if (this.editItem.startDate && !this.settings.needStartDateTime) this.editItem.startDate.setHours(6);
    //timezone miatt korrigálni kell
    if (this.editItem.startDate && this.settings.needStartDateTime) this.editItem.startDate.setMinutes(this.editItem.startDate.getMinutes() - this.editItem.startDate.getTimezoneOffset())
    if (!this.addMode) {
      //módosítás
      //this.dataSource[index] = this.simpleClone(this.editItem);

      this.patientService.updateDetail(this.editItem.id, JSON.stringify(this.editItem)).then(
        result => {
          if (result == "OK") {
            this.loadDataSource();
            notify({
              message: "Sikeres mentés!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.closeModal();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    } else {
      //új
      this.patientService.insertDetail(this.patientId, this.code, JSON.stringify(this.editItem)).then(
        result => {
          if (result == "OK") {
            notify({
              message: "Sikeres mentés!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.addMode = false;
            this.closeModal();
            this.loadDataSource();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    }
    //let result: any;

    e.preventDefault();
  }

}
