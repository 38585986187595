import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { PatientService } from '../patient.service';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import messagesEs from 'devextreme/localization/messages/es.json';
import { loadMessages, locale } from 'devextreme/localization';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoginUser, PatientDataDetails } from 'src/app/services/webapiclient.service';
import { CodeCombo, CodeOrder, CodesFunc, CodeWarehouseEditModel } from 'src/app/services/codes.service';
import { ActivatedRoute } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmDialogService } from 'src/app/confirm-dialog/confirm-dialog-service';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { CorrectDate } from 'src/app/shared/utils';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GameService } from 'src/app/games/services/game.service';

/**
 * Páciens egészségügyi adatainak kezelése.
 * Addikciók, Észlelt memóriazavarok, Kognitív tesztek eredményei
 * Ismert betegségek, gyógyszerezések
 */


@Component({
  selector: 'app-patient-details',
  templateUrl: './patient-details.component.html',
  styleUrls: ['./patient-details.component.scss']
})
export class PatientDetailsComponent implements OnInit, OnDestroy {

  @Input() inpCode: string;
  @Input() inpSubCode: string;
  @Input() inpPatientId: string;

  @ViewChild('editFormtmpl', { static: true }) editFormtmpl: TemplateRef<any>;
  @ViewChild('newFormtmpl', { static: true }) newFormtmpl: TemplateRef<any>;
  @ViewChild('editSubFormtmpl', { static: true }) editSubFormtmpl: TemplateRef<any>;
  @ViewChild('newSubFormtmpl', { static: true }) newSubFormtmpl: TemplateRef<any>;

  baseUri = `${environment.apiUrl}`;

  modalRef: BsModalRef;
  dataSource: any;
  dataSourcePivot: any;
  codesSource: any;
  frequencySource: any;
  regularitySource: any;
  unitSource: any;
  subDataSource: any;
  subCodesSource: any;
  groupedCodesSource: DataSource;
  medicationSource: any;
  currentUser: LoginUser;
  code: string;
  subCode: string;
  detailId: any;
  error: any;
  addMode: boolean = false;
  selectedIndex: any;
  selectedSubIndex: any;
  editItem: any;
  tempItem: any;
  maxDate: Date = new Date();
  showSubCards: boolean = false;
  showCards: boolean = true;
  patientId: any;
  maxRange: number = 100;
  codeData: CodeCombo[] = {} as CodeCombo[];
  dateFormat: string;

  startDateInstance: any;
  subDateInstance: any;

  //pivot adatok a kognitív teszt eredmények ábrázolásához
  days: any;
  testTypes: any;
  codeOrder: CodeOrder[] = {} as CodeOrder[];
  pivotData: Array<{ testType: number, tendency: any, data: Array<{ id: number, result: number, testDate: any, testType: number, resultCategory: string }>, spData: Array<{ result: number, testDate: any }> }> = [];
  data: Array<{ id: number, result: number, testDate: any, testType: number, resultCategory: string }> = [];
  sparkLineData: Array<{ testType: number, spData: Array<{ result: number, testDate: any }> }> = [];
  spData: Array<{ result: number, testDate: any }> = [];

  uploadVisible = false;
  showUploadedDocument = false;
  uploadFileId: string;
  uploadFileURL: string;
  files: any[] = [];

  showTaskSheet: boolean = false;

  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private patientService: PatientService,
    private gameService: GameService,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private codesFunc: CodesFunc,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private confirmDialogService: ConfirmDialogService
  ) {
    this.validateStartDate = this.validateStartDate.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    const huMessages = JSON.stringify(messagesHu['hu']);
    const esMessages = JSON.stringify(messagesEs['es']);
    loadMessages({
      'hu': JSON.parse(huMessages),
      'es': JSON.parse(esMessages),
    });

  }

  settings: any = {
    needFrequency: false,
    needRegularity: false,
    needStartDate: false,
    needStartDateTime: false,
    needQuantity: false,
    needResult: false,
    needUnitId: false,
    needPeriodInYear: false,
    newLabel: "?",
    codeName: "?",
    subCodeName: "?",
    subCodeNameExt: "?",
    subDateLabel: "?",
    newSubLabel: "?",
    dateLabel: "?",
    defaultUnit: null
  }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });

  this.maxDate.setHours(this.maxDate.getHours() + 1);      

    if (this.inpCode) {
      //Katalógusból hívjuk
      this.code = this.inpCode;
      this.subCode = this.inpSubCode;
      this.patientId = this.inpPatientId;
    } else {
      this.code = this.route.snapshot.paramMap.get('code');
      this.subCode = this.route.snapshot.paramMap.get('subCode');
    }
    this.initSettings();

    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      if (!this.patientId) this.patientId = this.currentUser.id;
      this.loadDataSource();
    }

  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  simpleClone(obj: any) {
    return Object.assign({}, obj);
  }

  arrayClone(obj: any) {
    return Object.assign([], obj);
  }

  //Beállítjuk, hogy milyen adatokat kell kezelnünk az adott beviteli típusnál
  initSettings() {
    switch (this.code) {
      case 'PerceivedMemorySymptoms': {
        //Észlelt memóriazavarok
        this.settings.needFrequency = true;
        this.settings.needStartDate = true;
        this.settings.dateLabel = this.translation.translate('Project.RecognitionDate');
        this.settings.newLabel = this.translation.translate('Project.NewMemoryDisorder');
        this.settings.codeName = this.translation.translate('Project.MemoryDisorders');
        break;
      }
      case 'CognitiveTest': {
        //Kognitív tesztek eredményei
        this.settings.needFrequency = false;
        this.settings.needStartDate = false;
        this.settings.needStartDateTime = true;
        this.settings.needResult = true;
        this.settings.dateLabel = this.translation.translate('Core.Date');
        this.settings.newLabel = this.translation.translate('Project.NewTestResult');
        this.settings.codeName = this.translation.translate('Project.Type');
        this.showCards = false;
        break;
      }
      case 'TobaccoProduct': {
        //Dohányzási szokások
        this.settings.needQuantity = true;
        this.settings.needUnitId = true;
        this.settings.needPeriodInYear = true;
        this.settings.needRegularity = true;
        this.settings.newLabel = this.translation.translate('Project.NewTobacco');
        this.settings.codeName = this.translation.translate('Project.Smoking');
        this.settings.defaultUnit = 427;
        break;
      }
      case 'AlcoholProducts': {
        //Alkohol fogyasztási szokások
        this.settings.needQuantity = true;
        this.settings.needUnitId = true;
        this.settings.needPeriodInYear = true;
        this.settings.needRegularity = true;
        this.settings.newLabel = this.translation.translate('Project.NewAlcohol');
        this.settings.codeName = this.translation.translate('Project.Alcohol');
        this.settings.defaultUnit = 428;
        break;
      }
      case 'HarmfulDrugs': {
        //Drog használat
        this.settings.needQuantity = true;
        this.settings.needUnitId = true;
        this.settings.needPeriodInYear = true;
        this.settings.needRegularity = true;
        this.settings.newLabel = this.translation.translate('Project.NewDrug');
        this.settings.codeName = this.translation.translate('Project.DrugUse');
        this.settings.defaultUnit = 427;
        break;
      }
      case 'CoffeeTypes': {
        //Kávé fogyasztási szokások
        this.settings.needQuantity = true;
        this.settings.needUnitId = true;
        this.settings.needPeriodInYear = true;
        this.settings.needRegularity = true;
        this.settings.newLabel = this.translation.translate('Project.NewCoffee');
        this.settings.codeName = this.translation.translate('Project.CoffeeUse');
        this.settings.defaultUnit = 428;
        break;
      }
      case 'KnownDiseases': {

        //Ismert betegségekhez
        this.settings.needStartDate = true;
        this.settings.dateLabel = this.translation.translate('Project.RecognitionDate');
        this.settings.newLabel = this.translation.translate('Project.NewKnownDiseases');
        this.settings.codeName = this.translation.translate('Project.KnownDiseases');
        //Szedett gyógyszerekhez
        this.settings.subCodeName = this.translation.translate('Project.Medications');
        this.settings.subCodeNameExt = this.translation.translate('Project.MedicationUsed');
        this.settings.subDateLabel = this.translation.translate('Project.DrogUsageStart');
        this.settings.newSubLabel = this.translation.translate('Project.NewMedication');
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  }

  filterByType(type) {
    let typeText = this.codesSource.filter(f=>f.id == type)[0].applicationReference;
    let ret = this.dataSource.filter( x => x.codeId == type);
    let prevResult = 0;
    ret.forEach((element, index) => {
      if(index == 0){
        element.tendency = 0;
      }else{
        if(prevResult < element.result){
          element.tendency = 1;
        }else if(prevResult > element.result){
          element.tendency = -1;
        }else{
          element.tendency = 0;
        }
      }

      if(typeText == "ADAS-Cog"){
        //adas-cog-nál fordítva van az eredményesség
        element.tendency = element.tendency * -1;
      }
      prevResult = element.result;
    });
    let retOrdered = ret.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());
    return retOrdered
  }  

  //Adatok betöltése
  loadDataSource() {
    this.patientService.getDetails(this.patientId, this.code).then(
      result => {
        this.dataSource = <PatientDataDetails[]>result;
        this.dataSource = this.dataSource.filter(f=>f.providerId == null || f.providerId == this.currentUser.providerId);
        this.dataSourcePivot = this.dataSource.map(x => Object.assign({}, x));

        this.loadCodesSources();
      }
    ).catch(err => {
      this.error = err;
    });

  }

  //Pivot tábla megjelítéshez átkonvertáljuk a bejövő PatientDataDetails[]-t
  converToPivot() {
    this.pivotData = [];
    this.sparkLineData = [];
    this.days = [];
    this.testTypes = [];

    // get the list of unique dates
    this.days = this.dataSourcePivot.map(function (item) {
      item.startDate = new Date(item.startDate).toDateString();
      return item.startDate;
    }).filter(function (value, index, self) {
      return self.indexOf(value) === index; // remove duplicates
    });
    //dátumok rendezése
    this.days.sort((a, b) => {
      return new Date(b).getTime() - new Date(a).getTime();
    });

    // get the list of unique test types

    // Itt minden típus látszik, akkor is, ha még nincs adat
    
    // this.testTypes = this.codesSource.map(function (item) {
    //   return item.id;
    // }).filter(function (value, index, self) {
    //   return self.indexOf(value) === index; // remove duplicates
    // });

    //Itt csak azok a sorok látszanak, ahol van adat
    this.testTypes = this.dataSourcePivot.map(function (item) {
      return item.codeId;
    }).filter(function (value, index, self) {
      return self.indexOf(value) === index; // remove duplicates
    });

    //Beállítjuk a sorrendet
    this.codeOrder = [];
    for (let type of this.testTypes) {
      let code = this.codesSource.find(f=>f.id == type);
      let co: CodeOrder = {} as CodeOrder;
      co.id = code.id;
      co.order = code.order;
      this.codeOrder.push(co);
    }

    this.codeOrder.sort((a,b)=>a.order - b.order);
    this.testTypes = [];
    for (let co of this.codeOrder) {
      this.testTypes.push(co.id);
    }
 

    //Eredmények kitöltése a pivotba
    for (let type of this.testTypes) {
      this.data = [];
      this.spData = [];
      for (let day of this.days) {
        let result = this.getResult(type, day);
        if (result.length > 0) this.spData.push({ result: result, testDate: day });
        this.data.push({ id: this.getResultId(type, day), result: result, testDate: day, testType: type, resultCategory: this.getResultCategory(type, day) });
      }
      this.spData.sort((a, b) => {
        return new Date(a.testDate).getTime() - new Date(b.testDate).getTime();
      });
      this.pivotData.push({ testType: type, tendency: this.calculateTendency(type), data: this.data, spData: this.spData });
    }

    //Sparkline-hoz adatok
    // for (let type of this.testTypes) {
    //   this.spData=[];
    //   for (let day of this.days) {
    //     let result = this.getResult(type,day);
    //     if(result.length>0) this.spData.push({result: result, testDate: day});
    //   }
    //   this.spData.sort((a,b) => {
    //       return new Date(a.testDate).getTime() - new Date(b.testDate).getTime();
    //   });
    //   this.sparkLineData.push({testType: type, spData: this.spData });
    // }

  }

  //Tendenciák kiszámítása
  calculateTendency(type) {
    //Még nincs eredmény, vagy csak 1 van
    if (this.spData.length < 2) return 0;
    let sum = 0;
    for (var i = 0; i < this.spData.length - 1; i++) {
      sum += this.spData[i].result[0];
    }
    //előző eredméynek átlaga
    let avg = sum / (this.spData.length - 1);
    //az aktuális eredmény
    let act = this.spData[this.spData.length - 1].result[0];
    //eltérés
    let diff = act / (avg / 100);
    let ret = 0;
    if (diff < 80) ret = -1;
    if (diff > 120) ret = 1;
    //Ennél a típusnál a kis eredmény a jó, ezért megfordítjuk (ADAS-Cog)
    if (type == 431) ret = ret * -1;
    return ret;
  }

  //Háttérszínek beállítása tendencia alapján
  tendencyBackground(tendency) {
    if (tendency == -1) return "#ffd8de";
    if (tendency == 1) return "#c2fdc2";
    return "whith";
  }
  //Teszt eredmények típus és nap alapján
  getResult(testType, day) {
    return this.dataSourcePivot.filter(function (item) {
      return item.startDate === day && item.codeId === testType;
    }).map(function (item) {
      return item.result;
    });
  }
  //Teszt eredmények kategória típus és nap alapján
  getResultCategory(testType, day) {
    return this.dataSourcePivot.filter(function (item) {
      return item.startDate === day && item.codeId === testType;
    }).map(function (item) {
      if (item.resultCategory) return 'Category.' + item.resultCategory;
      return '??';
    });
  }
  //Teszt eredmények azonosítója típus és nap alapján
  getResultId(testType, day) {
    return this.dataSourcePivot.filter(function (item) {
      return item.startDate === day && item.codeId === testType;
    }).map(function (item) {
      return item.id;
    });
  }

  //Kódok betöltése
  loadCodesSources() {
    this.codesFunc.getCodesData(this.code).then(
      result => {
        this.codesSource = <CodeWarehouseEditModel[]>result;
        this.converToPivot();
      }
    ).catch(err => {
      this.error = err;
    });
    this.codesFunc.getCodesData('FrequencyOfSymptoms').then(
      result => {
        this.frequencySource = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
    this.codesFunc.getCodesData('Frequency').then(
      result => {
        this.regularitySource = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
    this.codesFunc.getCodesData('UnitOfQuantity').then(
      result => {
        this.unitSource = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
    if (this.subCode != 'NO') {
      //Van alkód, azt is betöltjük
      this.codesFunc.getCodesCombo(this.subCode).then(
        result => {
          this.subCodesSource = <CodeCombo[]>result;
          this.groupedCodesSource = new DataSource({
            store: new ArrayStore({
              data: this.subCodesSource,
              key: "id"
            }),
            group: "category"
          });
        }
      ).catch(err => {
        this.error = err;
      });

    }
  }

  //Ha van aladat akkor azt is betöltjük
  //pl. Ismert betegségek -> a rá szedett gyógyszerek
  loadSubDataSource() {
    this.patientService.getSubDetails(this.detailId).then(
      result => {
        this.subDataSource = <PatientDataDetails[]>result;
      }
    ).catch(err => {
      this.error = err;
    });

  }

  //Csooportosított kódelemek
  //pl. gyógyszerek kategorizálva
  getGroupedCodesSource(): CodeCombo[] {
    this.codesFunc.getCodesCombo(this.subCode).then(
      result => {
        this.subCodesSource = <CodeCombo[]>result;
        return this.subCodesSource;
      }
    ).catch(err => {
      this.error = err;
      return null;
    });
    return this.subCodesSource;
  }

  //Tesztlap megtekintés vége
  closeViewEvent(e) {
    setTimeout(() => {                           // <<<---using ()=> syntax
      this.loadDataSource();
    }, 500);
    this.showTaskSheet = false;
  }

  //Felmérések pivot eredményére kattintottunk, ez az id-adja vissza
  clickEditPivot(id) {
    let index = this.dataSource.findIndex(x => x.id == id);
    this.clickEdit(index);
  }

  //Tesztlap nyomtatása
  clickPrint(id) {
    let index = this.dataSource.findIndex(x => x.id == id);
    let taskId = this.dataSource[index].taskId;

    this.gameService.printTaskSheet(taskId).subscribe((response) => {
      let array = new Uint8Array(response);

      //service returns an array buffer so convert array buffer to blob
      let file = new Blob([array], { type: 'application/pdf' });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
 });
  }

  clickEdit(index) {

    this.editItem = <PatientDataDetails>{};
    this.editItem = this.simpleClone(this.dataSource[index]);

    //TODO
    //Itt, ha van taskId, akkor a kiértékelésekhez kell ugranunk!

    if (this.editItem.taskId != null) {
      this.showTaskSheet = true;
      return;
    }

    //Ha a kódtár érték ki van töltve, akkor azt lesz a teszt eredmények max értéke
    //Betesszük a validáláshoz
    this.setMaxRange(this.editItem.codeId);

    this.selectedIndex = index;
    //ez akkor kell, ha dátum ellenőrzés van a formon
    //át kell alakítani dátumra, másképp hibára fut az ellenőrzés
    this.editItem.startDate = new Date(this.editItem.startDate);
    this.modalRef = this.modalService.show(this.editFormtmpl, { backdrop: 'static', keyboard: false });
  }
  clickDelete(index) {
    const that = this;
    this.confirmDialogService.confirmThis(this.translation.translate('Core.ConfirmDeleting'), function () {
      if (that.dataSource[index].id) {
        that.patientService.deleteDetail(that.dataSource[index].id).then(
          result => {
            if (result == "OK") {
              that.closeModal();
              that.dataSource.splice(index, 1);
              that.converToPivot();
            } else {
              that.error = result;
            }
          }
        ).catch(err => {
          that.error = err;
        });
      }
    }, function () {
      //alert("No clicked");
    })
  }
  changeCode(e) {
    //a teszt eredmények max értéke a kódtár érték mezejében van, így hozzá ellenőrzünk
    this.setMaxRange(e.value);
  }

  setMaxRange(codeId) {
    //Ha a kódtár érték ki van töltve, akkor azt lesz a teszt eredmények max értéke
    //Betesszük a validáláshoz
    let cw = this.codesSource.find(x => x.id == codeId);
    if (cw.value) {
      this.maxRange = cw.value;
    } else {
      this.maxRange = 99999999;
    }

  }

  addRow(codeId) {
    if (codeId != 0) this.setMaxRange(codeId);
    this.editItem = <PatientDataDetails>{};
    this.editItem.patientId = this.patientId;
    this.editItem.code = this.code;
    this.editItem.codeId = codeId;
    this.selectedIndex = this.dataSource.length - 1;
    this.addMode = true;
    this.modalRef = this.modalService.show(this.newFormtmpl, { backdrop: 'static', keyboard: false });
  }

  //Alkártyák eseményei
  clickSubCards(index) {
    this.selectedIndex = index;
    this.detailId = this.dataSource[index].id;
    this.loadSubDataSource();
    this.showSubCards = true;
    this.showCards = false;
  }
  clickBack() {
    this.showSubCards = false;
    this.showCards = true;
  }
  clickSubEdit(index) {
    this.editItem = <PatientDataDetails>{};
    this.editItem = this.simpleClone(this.subDataSource[index]);

    this.selectedSubIndex = index;

    //ez akkor kell, ha dátum ellenőrzés van a formon
    //át kell alakítani dátumra, másképp hibára fut az ellenőrzés
    this.editItem.startDate = new Date(this.editItem.startDate);
    this.modalRef = this.modalService.show(this.editSubFormtmpl, { backdrop: 'static', keyboard: false });
  }

  addSubRow() {
    this.editItem = <PatientDataDetails>{};
    this.editItem.patientId = this.patientId;
    this.editItem.code = this.subCode;
    this.editItem.parentId = this.detailId;
    this.addMode = true;
    this.modalRef = this.modalService.show(this.newSubFormtmpl, { backdrop: 'static', keyboard: false });
  }

  clickSubDelete(index) {
    const that = this;
    this.confirmDialogService.confirmThis(this.translation.translate('Core.ConfirmDeleting'), function () {
      if (that.subDataSource[index].id) {
        that.patientService.deleteDetail(that.subDataSource[index].id).then(
          result => {
            if (result == "OK") {
              that.closeModal();
              that.subDataSource.splice(index, 1);
            } else {
              that.error = result;
            }
          }
        ).catch(err => {
          that.error = err;
        });
      }
    }, function () {
      //alert("No clicked");
    })
  }

  closeModal() {
    this.modalRef.hide();
    if (this.addMode) {
      //Új bevitelt szakítunk meg
      this.addMode = false;
    }
  }

  //Egy napra csak 1 felmérési eredmény lehet típusonként
  validateStartDate(e) {
    var copiedDate = new Date(this.editItem.startDate.getTime());
    let ix = this.dataSource.findIndex(f => f.id != this.editItem.id && f.codeId == this.editItem.codeId && new Date(f.startDate).setHours(0, 0, 0, 0) == copiedDate.setHours(0, 0, 0, 0));
    return ix == -1;
  }

  //Dokumentum csatolása a felmérési eredményhez
  showUploadPopUp(id) {
    this.uploadFileId = this.patientId + '_' + id;
    this.patientService.getUploadedFileURL(this.uploadFileId).then(
      result => {
        if (result != "NO") {
          this.uploadFileURL = this.baseUri + <string>result;
          this.uploadVisible = false;
          this.showUploadedDocument = true;
        } else {
          this.files = [];
          this.uploadVisible = true;
          this.showUploadedDocument = false;
        }
      }
    ).catch(err => {
      this.error = err;
    });
  }

  uploadFile(file, uploadInfoCallback) {
    let formData = new FormData();
    formData.append("file", file, file.name);
    uploadInfoCallback(100);
    this.patientService.uploadFile(formData, this.uploadFileId.toString()).then(
      result => {
        //this.insertPicture(this.uploadUrl + file.name);
      }
    ).catch(err => {
      this.error = err;
    });

    // return this.kbService
    //   .uploadFile(formData)
  }

  showDocument() {
    this.showUploadedDocument = false;
    window.open(this.uploadFileURL, "_blank", 'noreferrer');
  }

  deleteDocument() {
    const that = this;
    this.showUploadedDocument = false;
    this.confirmDialogService.confirmThis(this.translation.translate('Core.ConfirmDeleting'), function () {
      that.patientService.deleteUploadFile(that.uploadFileId).then(
        result => {
          that.showUploadedDocument = false;
        }
      ).catch(err => {
        that.error = err;
      });
    }, function () {
      that.showUploadedDocument = true;
    })
  }

  onFormSubmit = function (e, index) {
    this.error = "";
    this.loading = true;
    //Ha csak dátumot kérünk be a devextreme date-box-nál, akkor 0 órát ad vissza
    //hozzá kell adni 1 órát, hogy ne az előző napra konvertája a JSON.stringify
    if (this.editItem.startDate && !this.settings.needStartDateTime) this.editItem.startDate.setHours(6);
    //timezone miatt korrigálni kell
    if (this.editItem.startDate && this.settings.needStartDateTime) this.editItem.startDate.setMinutes(this.editItem.startDate.getMinutes() - this.editItem.startDate.getTimezoneOffset())
    if (!this.addMode) {
      //módosítás
      this.dataSource[index] = this.simpleClone(this.editItem);

      this.patientService.updateDetail(this.dataSource[index].id, JSON.stringify(this.dataSource[index])).then(
        result => {
          if (result == "OK") {
            this.loadDataSource();
            notify({
              message: "Sikeres mentés!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.closeModal();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    } else {
      //új
      this.patientService.insertDetail(this.patientId, this.code, JSON.stringify(this.editItem)).then(
        result => {
          if (result == "OK") {
            notify({
              message: "Sikeres mentés!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.addMode = false;
            this.closeModal();
            this.loadDataSource();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    }
    //let result: any;

    e.preventDefault();
  }

  onSubFormSubmit = function (e, index) {
    this.error = "";
    this.loading = true;
    if (!this.addMode) {
      //módosítás
      this.subDataSource[index] = this.simpleClone(this.editItem);
      this.patientService.updateSubDetail(this.subDataSource[index].id, JSON.stringify(this.subDataSource[index])).then(
        result => {
          if (result == "OK") {
            notify({
              message: "Sikeres mentés!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.closeModal();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    } else {
      //új
      this.patientService.insertSubDetail(this.patientId, this.detailId, this.subCode, JSON.stringify(this.editItem)).then(
        result => {
          if (result == "OK") {
            notify({
              message: "Sikeres mentés!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.addMode = false;
            this.closeModal();
            this.loadSubDataSource();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    }
    //let result: any;

    e.preventDefault();
  }

  startDateFocusOut(s) {
    CorrectDate(this.startDateInstance);
  }

  initStartDate(e) {
    this.startDateInstance = e.component;
  }

  subDateFocusOut(s) {
    CorrectDate(this.subDateInstance);
  }

  initSubDate(e) {
    this.subDateInstance = e.component;
  }

}

