import { Injectable } from '@angular/core';
import { Role, RolePU, RoleSR, RoleSRC, RoleSRCD, RoleU } from '../models/role';

export class Menu {
  id: string;
  name: string;
  name_code: string;
  icon?: string;
  route?: string;
  roles?: string[];
  disabled?: boolean;
  items?: Menu[];
}

let menu: Menu[] = [
  {
  id: '1',
  name_code: 'Admin',
  name: 'Admin',
  roles: [Role.SysAdmin],
  disabled: false,
  items: [{
    id: '1_1',
    name_code: 'Core.Roles',
    name: '',
    roles: [Role.SysAdmin],
    route: '/role'
    // icon: "images/products/1.png"
  },
  {
    id: '1_2',
    name_code: 'Core.UserUsers',
    name: '',
    roles: [Role.SysAdmin, Role.PraxisAdmin],
    route: '/user'

    // icon: "images/products/2.png"
  },
  {
    id: '1_3',
    name_code: 'Core.Code', 
    name: '',
    roles: [Role.SysAdmin],
    route: '/codes'
    // icon: "images/products/2.png"
  },
  {
    id: '1_35',
    name_code: 'Task.TaskSheets',
    name: '',
    roles: [Role.SysAdmin],
    route: '/task-sheet'
    // icon: "images/products/2.png"
  },
  {
    id: '1_4',
    name_code: 'Project.Provider',
    name: '',
    roles: [Role.SysAdmin],
    route: '/hc-provider'
    // icon: "images/products/2.png"
  },
  {
    id: '1_5',
    name_code: 'Core.EndUserLicenseAgreement',
    name: '',
    roles: [Role.SysAdmin],
    route: '/terms/NO'
    // icon: "images/products/2.png"
  },
  {
    id: '1_6',
    name_code: 'Subscription.subscription',
    name: '',
    roles: [Role.SysAdmin],
    route: '/subscription-admin'
  },
  {
    id: '1_7',
    name_code: 'Hirdetések',
    name: '',
    roles: [Role.SysAdmin],
    route: '/ads'
  },
  {
    id: '1_8',
    name_code: 'Teszt',
    name: '',
    roles: [Role.SysAdmin],
    //route: '/test'
    route: '/games/tmt-test-x',
  }

  ]
},
{
  id: '2',
  name_code: 'Game.GameResults',
  name: 'Game.GameResults',
  route: '/statistics/games-report',
  roles: RoleSR,
  items: []
},
{
  id: '3',
  name_code: 'Project.TrendWatchPatient',
  name: 'Project.TrendWatchPatient',
  route: '/statistics/game-result-trends',
  roles: RoleSR,
  items: []
},
//  {
//   id: '2',
//   name_code: 'Game.Games',
//   name: 'Game.Games',
//   roles: [Role.SysAdmin, Role.RegisteredUser],
//   items: [{
//     id: '2_1',
//     name_code: 'Game.SDMTtest',
//     // roles: [Role.SysAdmin, Role.RegisteredUser, Role.Doctor],
//     name: 'Game.SDMTtest',
//     route: '/games/sdmt-test/sdmt/0'
//     // icon: "images/products/7.png"
//   }, {
//     id: '2_11',
//     name_code: 'Game.LDSTtest',
//     // roles: [Role.SysAdmin, Role.RegisteredUser, Role.Doctor],
//     name: 'Game.LDSTtest',
//     route: '/games/sdmt-test/ldst/0'
//     // icon: "images/products/7.png"
//   }, {
//     id: '2_12',
//     name_code: 'Game.DSSTtest',
//     name: 'Game.DSSTtest',
//     route: '/games/dsst-test/0'
//   },
//    {
//     id: '2_13',
//     name_code: 'Game.IcaTest',
//     roles: [Role.RegisteredUser],
//     name: 'Game.IcaTest',
//     route: '/games/ica-test/0'
//   },
//   {
//     id: '2_14',
//     name_code: 'Game.TmtTest',
//     roles: [Role.RegisteredUser],
//     name: 'Game.TmtTest',
//     route: '/games/tmt-test-b/0'
//   },
//   {
//     id: '2_2',
//     name_code: 'Game.MemoryGameClassic',
//     roles: [Role.RegisteredUser],
//     name: 'Game.MemoryGameClassic',
//     route: '/games/memory/classic/0'
//   },
//   {
//     id: '2_3',
//     roles: [Role.RegisteredUser],
//     name_code: 'Game.MemoryGameSemantic',
//     name: 'Game.MemoryGameSemantic',
//     route: '/games/memory/semantic/0'
//   },
//   {
//     id: '2_4',
//     roles: [Role.RegisteredUser],
//     name_code: 'Game.MemoryGameMathematical',
//     name: 'Game.MemoryGameMathematical',
//     route: '/games/memory/mathematical/0'
//   },
//   {
//     id: '2_5',
//     roles: [Role.RegisteredUser],
//     name_code: 'Game.WordGuess',
//     name: 'Game.WordGuess',
//     route: '/games/word-guess/0'
//   },
//   {
//     id: '2_6',
//     roles: [Role.RegisteredUser],
//     name_code: 'Game.WordListRecall',
//     name: 'Game.WordListRecall',
//     route: '/games/word-list-recall/0'
//   },
//   {
//     id: '2_65',
//     roles: [Role.RegisteredUser],
//     name_code: 'Game.WordListRecallAc',
//     name: 'Game.WordListRecallAc',
//     route: '/games/word-list-recall-ac/0'
//   },
//   {
//     id: '2_7',
//     roles: [Role.RegisteredUser],
//     name_code: 'Game.ShapeRecall',
//     name: 'Game.ShapeRecall',
//     route: '/games/shape-recall/0'
//   },
//   {
//     id: '2_8',
//     roles: [Role.RegisteredUser],
//     name_code: 'Game.ForeColorRecognition',
//     name: 'Game.ForeColorRecognition',
//     route: '/games/color-recognition/foreColorRecognition/0'
//   },
//   {
//     id: '2_9',
//     roles: [Role.RegisteredUser],
//     name_code: 'Game.BackColorRecognition',
//     name: 'Game.BackColorRecognition',
//     route: '/games/color-recognition/backColorRecognition/0'
//   }
//   ]
// },
 {
  id: '3',
  name_code: 'Thryve.ThryveSources',
  name: 'Thryve.ThryveSources',
  roles: [Role.SysAdmin],
  items: [{
    id: '3_1',
    name_code: 'Project.Settings',
    name: 'Project.Settings',
    route: 't-connection',
    roles: [Role.SysAdmin]
  },
  {
    id: '3_2',
    name_code: 'Project.Data',
    name: 'Project.Data',
    route: 't-data',
    roles: [Role.SysAdmin]
  },
  {
    id: '3_3',
    name_code: 'Spo2',
    name: 'Spo2',
    route: 'thryve-spo2',
    roles: [Role.SysAdmin]
  }
  ]
}, {
  id: '4',
  name_code: 'Project.Measures',
  name: 'Project.Measures',
  route: '/measures',
  roles: RoleSR,
   items: []

}, {
  id: '5',
  name_code: 'KB.Kb',
  name: 'KB.Kb',
  route: '/kb/kb-view/0',
  roles: RoleSRCD,
  items: [{
    id: '5_1',
    name_code: 'KB.ContentManagement',
    name: 'KB.ContentManagement',
    route: '/kb/kb-edit',
    roles: [Role.SysAdmin, Role.ContentEditor]
  },
  {
    id: '5_2',
    name_code: 'KB.Kb',
    name: 'KB.Kb',
    route: '/kb/kb-view/0',
    // roles: [Role.SysAdmin, Role.ContentEditor, Role.RegisteredUser, Role.Doctor]
  }
  ]
},
//  {
//   id: '6',
//   name_code: 'Project.Settings',
//   name: 'Project.Settings',
//   roles: [Role.SysAdmin, Role.ContentEditor, Role.RegisteredUser, Role.Doctor],
//   items: [
//     {
//       id: '6_1',
//       name_code: 'Project.ProviderLinks',
//       roles: [Role.SysAdmin, Role.RegisteredUser],
//       name: 'Project.ProviderLinks',
//       route: '/hc-provider-select'
//       // icon: "images/products/5.png"
//     }, {
//       id: '6_2',
//       name_code: 'Notification.NotifSetting',
//       roles: [Role.SysAdmin, Role.RegisteredUser, Role.Doctor, Role.PraxisAdmin],
//       name: 'Notification.NotifSetting',
//       route: '/notification-setting'
//       // icon: "images/products/5.png"
//     }, {
//       id: '6_3',
//       name_code: 'Core.EditPassword',
//       roles: [Role.SysAdmin, Role.RegisteredUser, Role.Doctor, Role.PraxisAdmin, Role.ContentEditor],
//       name: 'Core.EditPassword',
//       route: '/change-password'
//       // icon: "images/products/5.png"
//     },
//     {
//       id: '6_4',
//       name_code: 'Core.Unregister',
//       name: '',
//       roles: [Role.SysAdmin, Role.RegisteredUser, Role.Doctor, Role.PraxisAdmin],
//       route: '/unregister'
//       // icon: "images/products/2.png"
//     },
//     {
//       id: '6_5',
//       name_code: 'Core.EndUserLicenseAgreement',
//       name: '',
//       roles: [Role.SysAdmin, Role.RegisteredUser, Role.Doctor, Role.PraxisAdmin],
//       route: '/terms-info/YES'
//       // icon: "images/products/2.png"
//     }
//   ]
// },
//  {
//   id: '7',
//   name_code: 'Project.Statistics',
//   name: 'Project.Statistics',
//   roles: [Role.RegisteredUser, Role.Doctor],
//   items: [
//     {
//       id: '7_1',
//       roles: [Role.RegisteredUser],
//       name_code: 'Game.GameResults',
//       name: 'Game.GameResults',
//       route: '/statistics/games-report'
//     },
//     {
//       id: '7_2',
//       name_code: 'Project.TrendWatch',
//       roles: [Role.RegisteredUser, Role.Doctor],
//       name: 'Project.TrendWatch',
//       route: '/statistics/game-result-trends'
//       // icon: "images/products/5.png"
//     },
//     {
//       id: '7_3',
//       name_code: 'Project.BloodPressureData',
//       roles: [Role.RegisteredUser, Role.Doctor],
//       name: 'Project.BloodPressureData',
//       route: '/statistics/blood-pressure-chart'
//       // icon: "images/products/5.png"
//     },
//     {
//       id: '7_4',
//       name_code: 'Project.BloodOxygenData',
//       roles: [Role.RegisteredUser],
//       name: 'Project.BloodOxygenData',
//       route: '/statistics/blood-oxygen-chart'
//       // icon: "images/products/5.png"
//     },

//     {
//       id: '7_5',
//       name_code: 'Project.SleepAndSpo2',
//       name: 'Project.SleepAndSpo2',
//       route: 'thryve-sleep-chart',
//       roles: [Role.SysAdmin, Role.RegisteredUser, Role.Doctor]
//     }
//   ]

// },
 {
  id: '8',
  name_code: 'Core.Messages',
  name: '',
  roles: RoleSR,
  route: '/notes',
  items: []
},
{
  id: '9',
  name_code: 'Core.UserUsers',
  name: 'Core.UserUsers',
  roles: [Role.SysAdmin, Role.PraxisAdmin],
  route: '/user',
  disabled: false,
  items: []
},
{
  id: '10',
  name_code: 'Subscription.subscription',
  name: 'Subscription.subscription',
  roles: [Role.GoldUser, Role.PlatinumUser,Role.RegisteredUser], 
  //roles: [Role.GoldUser, Role.PlatinumUser], 
  route: '/subscription/subscription/YES',
  disabled: false,
  items: []
}
// ,
// {
//   id: '11',
//   name_code: 'Subscription.CouponUsage',
//   name: 'Subscription.CouponUsage',
//   roles: [Role.GoldUser, Role.PlatinumUser,Role.RegisteredUser], 
//   //roles: [Role.GoldUser, Role.PlatinumUser], 
//   route: '/subscription/coupon-usage',
//   disabled: false,
//   items: []
// }
];

@Injectable({ providedIn: 'root' })
export class MenuService {

  getMenu(userRole: string): Menu[] {
    const retMenu = [];
    for (const el of menu) {
      if (el.roles == null || el.roles.indexOf(userRole) > -1) {
        const copyEl = Object.assign({}, el);
        const subMenu = [];
        for (let sub_el of copyEl.items) {
          if (sub_el.roles == null || sub_el.roles.indexOf(userRole) > -1) {
            subMenu.push(sub_el);
          }
        }
        copyEl.items = subMenu;
        retMenu.push(copyEl);
      }
    }
    return retMenu;
  }

}
