import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CodeCombo } from './codes.service';
import { BoolResult, StringResult } from '../module-thryve/thryve.service';

export interface AuthenticateModel {
  password?: string;
  username?: string;
  acceptConditions?: boolean;
}
export interface ChangePasswordAfterActivationEditModel extends ChangePasswordBase {
  AcceptConditionsLogin?: boolean;
  Code?: string;
  ConfirmError?: boolean;
  EmailConfirmed?: boolean;
  Id?: string;
}

export interface ChangePasswordViewModel extends ChangePasswordBase {
  oldPassword?: string;
}

export interface ChangePasswordBase {
  confirmPassword?: string;
  newPassword?: string;
  StatusMessage?: string;
}

export interface Role {
  id: string;
  name: string;
  translation: string;
}

export interface RoleResult extends ResultBase {
  roles: Role[];
}

export interface UserResult extends ResultBase {
  users: User[];
}

export interface User {
  displayName?: string;
  email?: string;
  id?: string;
  userName?: string;
  role?: string;
  providerId?: string;
  active?: boolean;
  doctorId?: string;
}

export interface LoginUser extends ResultBase {
  displayName?: string;
  email?: string;
  id?: string;
  token?: string;
  refreshToken?: string;
  username?: string;
  role?: string;
  providerId?: string;
}

export interface ResultBase {
  error?: string;
}

export interface BaseClass {
  CreatedBy?: string;
  CreatedOn?: string;
  ModifiedBy?: string;
  ModifiedOn?: string;
}

export interface ResetPasswordViewModel {
  Code?: string;
  ConfirmPassword?: string;
  DisplayName?: string;
  Password?: string;
  UserName?: string;
}

export interface HealthcareProvider extends BaseClass {
  id?: number;
  webid?: string;
  name?: string;
  address?: string;
  city?: string;
  postalCode?: string;
  email?: string;
  phone?: string;
  webUrl?: string;
  mapLink?: string;
  openingHours?: string;
  remark?: string;
  needTermin?: boolean;
  needReferral?: boolean;
  active?: boolean;
  userName?: string;
  role?: string;
  avatarName?: String;
}

export interface HcProviderLink {
  userId?: number;
  providerId?: number;
  healthcareProvider?: HealthcareProvider;
}

export interface Doctor {
  id: string;
  userName: string;
  providerId: number;
  avatarName?: string;
}

export interface PatientData extends BaseClass {
  PatientDataId?: string;
  taj?: string;
  doctorId?: string;
  sexCodeId?: number;
  birthDate?: Date;
  highestEducationId?: number;
  familyHistoryId?: number;
  smoking?: boolean;
  alcohol?: boolean;
  phone?: string;
  picture?: string;
  avatarName?: string;
}
export interface PatientDataViewModel extends PatientData {
  displayName?: string;
  userName?: string;
  email?: string;
  providerId?: string;
  gpId?: string;
  gpName?: string;
  gpAvatarName?: string;
}

export interface PatientDataDetails extends BaseClass {
  id?: number;
  parentId?: number;
  patientId?: string;
  code?: string;
  codeId?: number;
  startDate?: Date;
  frequencyId?: number;
  periodInYear?: number;
  quantity?: number;
  unitId?: number;
  result?: number;
  resultCategory?: string;
  comment?: string;
  taskId?: number;
  providerId?: number;
  tendency?: number;
}

export interface ActualState{
  code: string;
  details: PatientDataDetailsModel[];
}

export interface PatientDataDetailsModel {
  id?: number;
  parentId?: number;
  code?: string;
  codeId?: number;
  codeDescription?: string;
  startDate?: Date;
  frequencyId?: number;
  frequencyDescription?: string;
  periodInYear?: number;
  quantity?: number;
  unitId?: number;
  result?: number;
  resultCategory?: string;
  comment?: string;
  taskId?: number;
  providerId?: number;
  tendency?: number;
  details?: PatientDataDetailsModel[];
}

export interface BloodPressureAndWeight {
  id?: number;
  patientId?: string;
  measurementDate?: Date;
  systolic?: number;
  diastolic?: number;
  pulse?: number;
  weight?: number;
  weightDate?: Date;
}

export interface BloodPressure {
  id?: number;
  patientId?: string;
  measurementDate?: Date;
  bloodPressureValue?: string;
  systolic: number;
  diastolic: number;
  pulse?: number;
  dataSource: string;
  category: string;
}

export interface NormalBloodPressure {
  systolic: number;
  diastolic: number;
 }

export interface BloodOxygen {
  id?: number;
  patientId?: string;
  measurementDate?: Date;
  spo2: number;
  pulse?: number;
  dataSource: string;
  category: string;
}

export interface DensityDTO {
  zscore: number;
  density?: number;
  densityUser?: number;
  densityPraxis?: number;
  densitySelected?: number;
}

export interface ZscoreDTO {
  patientId: string;
  displayName: string;
  zscore: number;
  density?: number;
}

export interface DensityData {
  zscoreList: ZscoreDTO[];
  densityList: DensityDTO[];
}

export interface PredictionResult {
  probabilityG: number;
  probabilityL: number;
  probabilityF: number;
  avgResult: number;
  correct: boolean;
  gameTypeCount: number;
  sdmtCount: number;
  totalGameCount: number;
}

export interface Catalogue {
  id?: string;
  taj?: string;
  displayName?: string;
  email?: string;
  phone?: string;
  avatarName?: string;
  birthDate?: Date;
  hasDoctor?: number;
  doctorId?: string;
  providerId?: string;
  emailConfirmed: boolean;
  needEvaluation: boolean;
  expireDate?: Date;
  status: string;
  doctorConfirmed?: Date;
}

export interface DoctorDashboard {
  doctorId: string;
  sumPatient: number;
  activePatient: number;
  taskSheetsAct: number;
  taskSheetsPrev: number;
  mostPopularName: string;
  mostPopularDb: number;
  gamesAct: number;
  gamesPrev: number;
}
export interface ExtendedDoctorDashboard {
  dashBoard: DoctorDashboard;
  gameList: Types[];
  topGamers: TopGamer[];
  taskSheets: Types[];
  patientsByNeuro: Types[];
}
export interface TopGamer {
  userId: string;
  displayName: string;
  avatarName: string;
  db: number;
}
export interface Types {
  typeName: string;
  db: number;
  percent: number;
}
export interface LastMeasuredData {
  id: number;
  code: string;
  startDate: Date;
  value: number;
  hour: number;
  category: string;
  info: string;
}

export interface Diagnosis {
  id?: string;
  patientId?: string;
  diagnosisDate?: Date;
  micProgressionId?: number;
  selectedMics?: number[];
  imgTestResult?: string;
  skullCtResult?: string;
  ctDate?: Date;
  mrDate?: Date;
  physiotherapy?: string;
  medication?: string;
  medicationId?: number;
  comment?: string;
}

export interface MicResultLink {
  micResultId?: number;
  diagnosisId?: number;
}

export interface NotificationViewModel {
  subscriptionId: number;
  notificationId: number;
  type: string;
  eventDate: Date;
  message: string;
  taskName: string;
  content?: string;
  route?: string;
  userId: string;
  repeat: number;
  displayName: string;
  subscriberId: string;
  newMessage: boolean;
  ts: Date;
}

export interface NotificationCategory {
  code: string;
  type: string;
  message: string;
  selected: boolean;
}

export interface LabResult {
  patientDataId: string;
  measuredOn: Date;
  webId: string;
  labDataList: LabDataItem[];
}

export interface LabDataItem {
  appReference: string;
  codeWarehouseId: number;
  labTranslation: string;
  value: number;
  patientUnit: string;
  gFRHigherThan90: boolean;
  outOfReference: boolean;
  lastValue: boolean;
}

export interface LabSumDataItem {
  id: number;
  measuredOn?: Date;
  labTranslation: string;
  value?: number;
  outOfReference: boolean;
  patientUnit: string;
}

export interface LabResultsEditModel {
  patientDataId: string;
  measuredOn: Date;
  webId: string;
  labDataList: LabDataItemEditModel[];
}

export interface LabDataItemEditModel {
  labCodeGuid: string;
  labCodeId: number;
  appReference: string;
  order: number;
  type: string;
  labTranslation: string;
  value: number;
  gFRHigherThan90: boolean;
  allSelectableUnits: CodeCombo[];
  outOfReference: boolean;
  error: string;
  selectedUnit: string;
}

export interface TermsAndConditions {
  id: number;
  terms: string;
  acceptNeed: boolean;
  validFrom: Date;
  createdBy: string;
  createdOn: Date;
  lang: string;
}
export interface UnregisterViewModel {
  password: string;
  statusMessage: string;
}

export interface MsData {
  id: number;
  value: string;
  ts: string;
  sensor_id: string;
  member_id: number;
  selected: boolean;
}

export interface CancelTreatmentModel {
  doctorId: string;
  patientId: string;
  reason: string;
}
export interface DenyTreatmentModel {
  doctorId: string;
  suggestedDoctorId?: string;
  patientId: string;
  reason: string;
}
export interface SendMessageModel {
  id: number;
  doctorId: string;
  doctorName: string;
  patientId: string;
  mailContent: string;
  isPublic: boolean;
  isRead: boolean;
  removed: boolean;
  createdOn: Date;
  uploadedFile: boolean;
}

export interface ApiTokens {
  token: string;
  refreshToken: string;
}

export interface AllocatedTask {
  id: number;
  webId: string;
  patientId: string;
  doctorId: string;
  taskId: number;
  kbWebId: string;
  rootIndex: number;
  startDate: Date;
  endDate: Date;
  frequencyId: number;
  allowDelete: boolean;
  physicalExercise: boolean;
  taskSheet: boolean;
  allocatedTaskInstance: AllocatedTaskInstance[];
}
export interface AllocatedTaskInstance {
  id: number;
  allocatedTaskId: number;
  dueDate: Date;
  startDate: Date;
}
export interface ActualTask {
  id: number;
  allocatedTaskId: number;
  dueDate: Date;
  endDate: Date;
  frequencyId: number;
  taskId: number;
  kbWebId: string;
  physicalExercise: boolean;
  rootIndex: number;
  startable: boolean;
  expired: boolean;
  performedTasks: AllocatedTaskInstance[];
  collapse: boolean;
}

export interface TaskEvaluation {
  id: number;
  gameType: string;
  subType: string;
  startTime: Date;
  elapsedTime: string;
  resultNumeric: number;
  resultCodeId: number;
  moodLevel?: number;
  completed: boolean;
  taskId: number;
  providerId?: number;
  img1?: string;
  img2?: string;
  img3?: string;

}

export interface ExternalAuthDto {
  provider: string;
  idToken: string;
}

@Injectable()
export class UnregisterFunc {
  baseUri = `${environment.apiUrl}`;
  constructor(private http: HttpClient) {
  }
  unregister(values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'Unregister/unregister', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  getUnregisterDate(id: string): Observable<StringResult> {
    return this.http.get<StringResult>(this.baseUri + 'Unregister/getUnregisterDate?userId=' + encodeURIComponent(id));
  }

  cancelUnregister(): Observable<StringResult> {
    return this.http.get<StringResult>(this.baseUri + 'Unregister/CancelUnregister');
  }

}

@Injectable()
export class TermsFunc {
  baseUri = `${environment.apiUrl}`;
  constructor(private http: HttpClient) {
  }
  // get(id: string): Observable<HealthcareProvider> {
  // 	return this.http.get<HealthcareProvider>(this.baseUri + 'HealthcareProviderFunc/Get?id=' + encodeURIComponent(id));
  // }

  getAll(): Observable<TermsAndConditions[]> {
    return this.http.get<TermsAndConditions[]>(this.baseUri + 'Terms/GetAll');
  }
  getActualTerms(): Observable<TermsAndConditions> {
    return this.http.get<TermsAndConditions>(this.baseUri + 'Terms/GetActualTerms');
  }

  insert(values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'Terms/Insert', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  // update(id: number, values: string): Observable<HttpResponse<string>> {
  // 	return this.http.put(this.baseUri + 'HealthcareProviderFunc/Put?id=' + encodeURIComponent(id), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text'});
  // }

  // delete(id: number): Observable<HttpResponse<string>> {
  // 	return this.http.delete(this.baseUri + 'HealthcareProviderFunc/Delete?id=' + encodeURIComponent(id), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text'  });
  // }

}

@Injectable()
export class RolesFunc {
  baseUri = `${environment.apiUrl}`;
  constructor(private http: HttpClient) {
  }

  /**
   * GET RolesFunc/GetAll
   */
  getAll(edit: boolean = false): Observable<RoleResult> {
    return this.http.get<RoleResult>(this.baseUri + 'RolesFunc/GetAll?edit=' + encodeURIComponent(edit));
  }
  /**
   * POST RolesFunc/Insert
   */
  insert(name: string): Observable<string> {
    return this.http.post<string>(this.baseUri + 'RolesFunc/Insert', JSON.stringify(name), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } });
  }
  /**
   * POST RolesFunc/Update?id={id}
   */
  update(id: string, name: string): Observable<string> {
    return this.http.put<string>(this.baseUri + 'RolesFunc/Update?id=' + encodeURIComponent(id), JSON.stringify(name), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } });
  }

  /**
   * DELETE RolesFunc/Delete?id={id}
   */
  delete(id: string): Observable<string> {
    return this.http.delete<string>(this.baseUri + 'RolesFunc/Delete?id=' + encodeURIComponent(id));
  }

}

@Injectable()
export class UsersFunc {

  baseUri: string;

  constructor(private http: HttpClient) {
    if(window.location.href.includes('predemplatform')){
      this.baseUri = `${environment.apiUrl}`;
    }else{
      this.baseUri = `${environment.apiUrlKog}`;
    }

  }

  /**
   * GET UsersFunc/GetAll
   */
  getAll(needAll: boolean = false): Observable<UserResult> {
    return this.http.get<UserResult>(this.baseUri + 'UsersFunc/GetAll?needAll=' + encodeURIComponent(needAll));
  }

    /**
   * GET UsersFunc/GetAllUSers
   */
    getAllUsers(loadOptions): Observable<User[]> {
      return this.http.get<User[]>(this.baseUri + 'UsersFunc/GetAllUsers?loadOptions=' + encodeURIComponent(loadOptions));
    }
  
  /**
   * POST UsersFunc/Insert
   */
  insert(values: string): Observable<string> {
    return this.http.post<string>(this.baseUri + 'UsersFunc/Insert', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } });
  }
  /**
   * PUT UsersFunc/Update?id={id}
   */
  update(id: string, values: string): Observable<string> {
    return this.http.put<string>(this.baseUri + 'UsersFunc/Update?id=' + encodeURIComponent(id), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } });
  }

  /**
   * DELETE UsersFunc/Delete?id={id}
   */
  delete(id: string): Observable<string> {
    return this.http.delete<string>(this.baseUri + 'UsersFunc/Delete?id=' + encodeURIComponent(id));
  }

  /**
   * POST UsersFunc/Registration
   */
  registration(values: string): Observable<string> {
    return this.http.post<string>(this.baseUri + 'UsersFunc/Registration', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } });
  }

  /**
   * Platinum POST UsersFunc/Registration
   */
  platinumRegistration(values: string): Observable<string> {
    return this.http.post<string>(this.baseUri + 'UsersFunc/PlatinumRegistration', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } });
  }

}


@Injectable()
export class AccountFunc {

  baseUri: string;

  constructor(private http: HttpClient) {
    if(window.location.href.includes('predemplatform')){
      this.baseUri = `${environment.apiUrl}`;
    }else{
      this.baseUri = `${environment.apiUrlKog}`;
    }

  }

  /**
   * POST AccountFunc/Authenticate
   */
  authenticate(model: AuthenticateModel): Observable<LoginUser> {
    return this.http.post<LoginUser>(this.baseUri + 'AccountFunc/Authenticate', JSON.stringify(model), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } });
  }

  /**
   * POST AccountFunc/Facebook
   */
  facebookAuthenticate(access_token: string): Observable<LoginUser> {
    return this.http.post<LoginUser>(this.baseUri + 'AccountFunc/Facebook', JSON.stringify(access_token), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } });
  }

  /**
   * POST AccountFunc/ExternalLogin
   */
  googleAuthenticate(externalAuth: ExternalAuthDto): Observable<LoginUser> {
    return this.http.post<LoginUser>(this.baseUri + 'AccountFunc/ExternalLogin', JSON.stringify(externalAuth), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } });
  }

  /**
    * POST AccountFunc/Authenticate
    */
  refreshToken(model: ApiTokens): Observable<ApiTokens> {
    return this.http.post<ApiTokens>(this.baseUri + 'AccountFunc/RefreshToken', JSON.stringify(model), { headers: { 'Content-Type': 'application/json;charset=UTF-8' } });
  }

  revokeToken(model: ApiTokens): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'AccountFunc/RevokeToken', JSON.stringify(model), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  /**
   * POST AccountFunc/ActivationChangePassword
   */
  // activationChangePassword(values: string): Observable<string> {
  // 	return this.http.post<string>(this.baseUri + 'AccountFunc/ActivationChangePassword', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }});
  // }

  activationChangePassword(values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'AccountFunc/ActivationChangePassword', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  /**
   * POST AccountFunc/ForgotPassword
   */
  forgotPassword(values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'AccountFunc/ForgotPassword', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  /**
   * POST AccountFunc/ResetPassword
   */
  resetPassword(values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'AccountFunc/ResetPassword', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }
  changePassword(values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'AccountFunc/ChangePassword', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  isInRole(userId: string, role: string): Observable<BoolResult> {
    return this.http.get<BoolResult>(this.baseUri + 'AccountFunc/IsInPole?userId=' + encodeURIComponent(userId) + '&role=' + encodeURIComponent(role));
  }

}

@Injectable()
export class PatientDataFunc {
  baseUri = `${environment.apiUrl}`;
  constructor(private http: HttpClient) {
  }

  setLanguage(userId: string, lang: string): Observable<HttpResponse<string>> {
    return this.http.get(this.baseUri + 'PatientDataFunc/SetLanguage?userId=' + encodeURIComponent(userId) + '&lang=' + encodeURIComponent(lang), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }


  get(id: string): Observable<PatientDataViewModel> {
    return this.http.get<PatientDataViewModel>(this.baseUri + 'PatientDataFunc/Get?id=' + encodeURIComponent(id));
  }

  getAll(): Observable<PatientData[]> {
    return this.http.get<PatientData[]>(this.baseUri + 'PatientDataFunc/GetAll');
  }
  upsert(values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'PatientDataFunc/Post', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  update(id: string, values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'PatientDataFunc/Put?id=' + encodeURIComponent(id), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  saveDoctorAndProviderId(id: string, values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'PatientDataFunc/SaveDoctorAndProviderId?id=' + encodeURIComponent(id), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  saveProviderConfirmation(id: string, values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'PatientDataFunc/SaveProviderConfirmation?id=' +
      encodeURIComponent(id), JSON.stringify(values), {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      observe: 'response', responseType: 'text'
    });
  }

  delete(id: string): Observable<HttpResponse<string>> {
    return this.http.delete(this.baseUri + 'PatientDataFunc/Delete?id=' + encodeURIComponent(id), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  getCatalogue(id: string, isGP: boolean): Observable<Catalogue[]> {
    return this.http.get<Catalogue[]>(this.baseUri + 'PatientDataFunc/GetPatientCatalogue?doctorId=' + encodeURIComponent(id) + '&isGP=' + encodeURIComponent(isGP));
  }

  getDoctorDashboardData(id: string): Observable<ExtendedDoctorDashboard> {
    return this.http.get<ExtendedDoctorDashboard>(this.baseUri + 'PatientDataFunc/GetDoctorDashboardData?doctorId=' + encodeURIComponent(id));
  }

  getLastMeasuredData(id: string): Observable<LastMeasuredData[]> {
    return this.http.get<LastMeasuredData[]>(this.baseUri + 'PatientDataFunc/GetLastMeasuredData?patientId=' + encodeURIComponent(id));
  }

  cancelTreatment(values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'PatientDataFunc/cancelTreatment', JSON.stringify(values), {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      observe: 'response', responseType: 'text'
    });
  }

  cancelGpConn(values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'PatientDataFunc/cancelGpConnection', JSON.stringify(values), {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      observe: 'response', responseType: 'text'
    });
  }

  denyTreatment(values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'PatientDataFunc/DenyTreatment', JSON.stringify(values), {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      observe: 'response', responseType: 'text'
    });
  }

  sendMessage(values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'PatientDataFunc/SendMessage', JSON.stringify(values), {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
      observe: 'response', responseType: 'text'
    });
  }

  getNotes(doctorId: string, patientId: string): Observable<SendMessageModel[]> {
    if(doctorId == null){
      return this.http.get<SendMessageModel[]>(this.baseUri + 'PatientDataFunc/GetNotes?patientId=' + encodeURIComponent(patientId));
    }else{
      return this.http.get<SendMessageModel[]>(this.baseUri + 'PatientDataFunc/GetNotes?doctorId=' + encodeURIComponent(doctorId) + '&patientId=' + encodeURIComponent(patientId));
    }
  }

  deleteNote(userId: string, id: number): Observable<HttpResponse<string>> {
    return this.http.delete(this.baseUri + 'PatientDataFunc/DeleteNote?userId=' + encodeURIComponent(userId) + '&Id=' + encodeURIComponent(id), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  // details

  getDetails(id: string, code: string): Observable<PatientDataDetails[]> {
    return this.http.get<PatientDataDetails[]>(this.baseUri + 'PatientDataFunc/GetDetails?id=' + encodeURIComponent(id) + '&code=' + encodeURIComponent(code));
  }
  getDetailsModel(patientId: string): Observable<PatientDataDetailsModel[]> {
    return this.http.get<PatientDataDetailsModel[]>(this.baseUri + 'PatientDataFunc/GetDetailsModel?patientId=' + encodeURIComponent(patientId));
  }

  insertDetail(id: string, code: string, values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'PatientDataFunc/InsertDetail?id=' + encodeURIComponent(id) + '&code=' + encodeURIComponent(code), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }
  updateDetail(id: string, values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'PatientDataFunc/UpdateDetail?id=' + encodeURIComponent(id), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }
  deleteDetail(id: string): Observable<HttpResponse<string>> {
    return this.http.delete(this.baseUri + 'PatientDataFunc/DeleteDetail?id=' + encodeURIComponent(id), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  // subDetails

  getSubDetails(id: string): Observable<PatientDataDetails[]> {
    return this.http.get<PatientDataDetails[]>(this.baseUri + 'PatientDataFunc/GetSubDetails?id=' + encodeURIComponent(id));
  }
  insertSubDetail(patientId: string, detailId: string, code: string, values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'PatientDataFunc/InsertSubDetail?patientId=' + encodeURIComponent(patientId) + '&detailId=' + encodeURIComponent(detailId) + '&code=' + encodeURIComponent(code), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }
  updateSubDetail(id: string, values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'PatientDataFunc/UpdateSubDetail?id=' + encodeURIComponent(id), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }
  deleteSubDetail(id: string): Observable<HttpResponse<string>> {
    return this.http.delete(this.baseUri + 'PatientDataFunc/DeleteSubDetail?id=' + encodeURIComponent(id), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  // Vérnyomás adatok

  getBloodPressureList(id: string, startDate: string, endDate: string): Observable<BloodPressure[]> {
    return this.http.get<BloodPressure[]>(this.baseUri + 'PatientDataFunc/GetBloodPressureList?userId=' + encodeURIComponent(id) + '&startDate=' + encodeURIComponent(startDate) + '&endDate=' + encodeURIComponent(endDate));
  }
  getBloodPressureAndWeightList(id: string, startDate: string, endDate: string): Observable<BloodPressureAndWeight[]> {
    return this.http.get<BloodPressureAndWeight[]>(this.baseUri + 'PatientDataFunc/GetBloodPressureAndWeightList?userId=' + encodeURIComponent(id) + '&startDate=' + encodeURIComponent(startDate) + '&endDate=' + encodeURIComponent(endDate));
  }
  getNormalBloodPressure(id: string): Observable<NormalBloodPressure> {
    return this.http.get<NormalBloodPressure>(this.baseUri + 'PatientDataFunc/GetNormalBloodPressure?userId=' + encodeURIComponent(id));
  }

  addBloodPressure(values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'PatientDataFunc/AddBloodPressure', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }
  updateBloodOxygen(id: string, values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'PatientDataFunc/UpdateBloodOxygen?userId=' + encodeURIComponent(id), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }
  deleteBloodOxygen(userId: string, id: number): Observable<HttpResponse<string>> {
    return this.http.delete(this.baseUri + 'PatientDataFunc/DeleteBloodOxygen?userId=' + encodeURIComponent(userId) + '&Id=' + encodeURIComponent(id), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  // Véroxygen adatok

  getBloodOxygenList(id: string, startDate: string, endDate: string, descOrder: boolean): Observable<BloodOxygen[]> {
    return this.http.get<BloodOxygen[]>(this.baseUri + 'PatientDataFunc/GetBloodOxygenList?userId=' + encodeURIComponent(id) + '&startDate=' + encodeURIComponent(startDate) + '&endDate=' + encodeURIComponent(endDate)+ '&descOrder=' + encodeURIComponent(descOrder));
  }
  addBloodOxygen(values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'PatientDataFunc/AddBloodOxygen', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  updateBloodPressure(id: string, values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'PatientDataFunc/UpdateBloodPressure?userId=' + encodeURIComponent(id), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }
  deleteBloodPressure(userId: string, id: number): Observable<HttpResponse<string>> {
    return this.http.delete(this.baseUri + 'PatientDataFunc/DeleteBloodPressure?userId=' + encodeURIComponent(userId) + '&Id=' + encodeURIComponent(id), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  // Labor eredmények

  getLaborResults(userId: string): Observable<LabResult[]> {
    return this.http.get<LabResult[]>(this.baseUri + 'PatientDataFunc/GetLaborResults?userId=' + encodeURIComponent(userId));
  }
  getLabSumData(userId: string): Observable<LabSumDataItem[]> {
    return this.http.get<LabSumDataItem[]>(this.baseUri + 'PatientDataFunc/GetLabSumData?userId=' + encodeURIComponent(userId));
  }
  getLabResultsCreate(userId: string): Observable<LabResultsEditModel> {
    return this.http.get<LabResultsEditModel>(this.baseUri + 'PatientDataFunc/GetLabResultsCreate?userId=' + encodeURIComponent(userId));
  }
  getLabResultsUpdate(userId: string, webId: string): Observable<LabResultsEditModel> {
    return this.http.get<LabResultsEditModel>(this.baseUri + 'PatientDataFunc/GetLabResultsUpdate?userId=' + encodeURIComponent(userId) + '&webId=' + encodeURIComponent(webId));
  }
  updateLabData(values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'PatientDataFunc/UpdateLabData', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }
  deleteLabData(userId: string, webId: string): Observable<HttpResponse<string>> {
    return this.http.delete(this.baseUri + 'PatientDataFunc/DeleteLabData?userId=' + encodeURIComponent(userId) + '&webId=' + encodeURIComponent(webId), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  checkLabDataRange(appReference: string, selectedUnit: string, labValue: number): Observable<BoolResult> {
    return this.http.get<BoolResult>(this.baseUri + 'PatientDataFunc/CheckLabDataRange?appReference=' + encodeURIComponent(appReference) + '&selectedUnit=' + encodeURIComponent(selectedUnit) + '&labValue=' + encodeURIComponent(labValue));
  }

  //Feladatok

  insertTask(values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'PatientDataFunc/InsertTask', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }
  getTasks(userId: string): Observable<AllocatedTask[]> {
    return this.http.get<AllocatedTask[]>(this.baseUri + 'PatientDataFunc/GetTasks?userId=' + encodeURIComponent(userId));
  }
  getActualTasks(userId: string, actual: boolean): Observable<ActualTask[]> {
    return this.http.get<ActualTask[]>(this.baseUri + 'PatientDataFunc/GetActualTasks?userId=' + encodeURIComponent(userId) + '&actual=' + encodeURIComponent(actual));
  }

  startTask(userId: string, id: number): Observable<HttpResponse<string>> {
    return this.http.get(this.baseUri + 'PatientDataFunc/StartTask?userId=' + encodeURIComponent(userId) + '&id=' + encodeURIComponent(id), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  setTaskToEvaluated(userId: string, taskId: number, providerId: string): Observable<HttpResponse<string>> {
    return this.http.get(this.baseUri + 'PatientDataFunc/SetTaskToEvaluated?userId=' + encodeURIComponent(userId) + '&taskId=' + encodeURIComponent(taskId) + '&providerId=' + encodeURIComponent(providerId), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  deleteTask(userId: string, id: number): Observable<HttpResponse<string>> {
    return this.http.delete(this.baseUri + 'PatientDataFunc/DeleteTask?userId=' + encodeURIComponent(userId) + '&Id=' + encodeURIComponent(id), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  getTasksForEvaluation(userId: string): Observable<TaskEvaluation[]> {
    return this.http.get<TaskEvaluation[]>(this.baseUri + 'PatientDataFunc/GetTasksForEvaluation?userId=' + encodeURIComponent(userId));
  }
  getTaskSheet(userId: string, taskId: number): Observable<TaskEvaluation[]> {
    return this.http.get<TaskEvaluation[]>(this.baseUri + 'PatientDataFunc/GetTaskSheet?userId=' + encodeURIComponent(userId) + '&taskId=' + encodeURIComponent(taskId));
  }

  getPrediction(userId: string, days: number): Observable<PredictionResult> {
    return this.http.get<PredictionResult>(this.baseUri + 'PatientDataFunc/GetPrediction?userId=' + encodeURIComponent(userId) + '&days=' + encodeURIComponent(days));
  }

  //mySignals

  getLastMeasurement(): Observable<MsData[]> {
    return this.http.get<MsData[]>(this.baseUri + 'PatientDataFunc/GetLastMeasurement');
  }

  saveMeasurements(userId: string, values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'PatientDataFunc/SaveMeasurements?userId=' + encodeURIComponent(userId), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  upload(myFile: FormData, Id: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'PatientDataFunc/Upload?Id=' + Id, myFile, {
      observe: 'response', responseType: 'text'
    });
  }

  getUploadedFileUrl(uploadFileId: string): Observable<StringResult> {
    return this.http.get<StringResult>(this.baseUri + 'PatientDataFunc/GetUploadedFileURL?uploadFileId=' + encodeURIComponent(uploadFileId));
  }

  deleteUploadFile(uploadFileId: string): Observable<HttpResponse<string>> {
    return this.http.delete(this.baseUri + 'PatientDataFunc/DeleteUploadedFile?uploadFileId=' + encodeURIComponent(uploadFileId), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

}
@Injectable()
export class HealthcareProviderFunc {
  baseUri = `${environment.apiUrl}`;
  constructor(private http: HttpClient) {
  }
  get(id: string): Observable<HealthcareProvider> {
    return this.http.get<HealthcareProvider>(this.baseUri + 'HealthcareProviderFunc/Get?id=' + encodeURIComponent(id));
  }

  /**
   * GET HealthcareProviderFunc/GetAll
   */
  getAll(): Observable<HealthcareProvider[]> {
    return this.http.get<HealthcareProvider[]>(this.baseUri + 'HealthcareProviderFunc/GetAll');
  }
  /**
   * POST HealthcareProviderFunc/Post
   */
  insert(values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'HealthcareProviderFunc/Post', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  /**
   * PUT HealthcareProviderFunc/Put?id={id}
   */
  update(id: number, values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'HealthcareProviderFunc/Put?id=' + encodeURIComponent(id), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  /**
   * DELETE HealthcareProviderFunc/Delete?id={id}
   */
  delete(id: number): Observable<HttpResponse<string>> {
    return this.http.delete(this.baseUri + 'HealthcareProviderFunc/Delete?id=' + encodeURIComponent(id), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  // Hc links

  getLinks(userId: string): Observable<HcProviderLink[]> {
    return this.http.get<HcProviderLink[]>(this.baseUri + 'HealthcareProviderFunc/GetLinks?userId=' + encodeURIComponent(userId));
  }

  insertLink(userId: string, values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'HealthcareProviderFunc/PostLink?userId=' + encodeURIComponent(userId), JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  deleteLink(userId: string, providerid: string): Observable<HttpResponse<string>> {
    return this.http.delete(this.baseUri + 'HealthcareProviderFunc/DeleteLink?userId=' + encodeURIComponent(userId) + '&providerId=' + encodeURIComponent(providerid), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }
  // Szolgáltóhoz tartozó orvosok
  getDoctorsByProvider(providerid: string): Observable<Doctor[]> {
    return this.http.get<Doctor[]>(this.baseUri + 'HealthcareProviderFunc/GetDoctorsByProvider?providerID=' + encodeURIComponent(providerid));
  }

}
@Injectable()
export class DiagnosisFunc {
  baseUri = `${environment.apiUrl}`;
  constructor(private http: HttpClient) {
  }
  getAll(patientId: string): Observable<Diagnosis[]> {
    return this.http.get<Diagnosis[]>(this.baseUri + 'Diagnosis/GetAll?patientId=' + encodeURIComponent(patientId));
  }

  insert(values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'Diagnosis/Post', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  update(values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'Diagnosis/Put', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  delete(id: number): Observable<HttpResponse<string>> {
    return this.http.delete(this.baseUri + 'Diagnosis/Delete?id=' + encodeURIComponent(id), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  // Mic links

  getLinks(diagnosisId: string): Observable<MicResultLink[]> {
    return this.http.get<MicResultLink[]>(this.baseUri + 'Diagnosis/GetLinks?diagnosisId=' + encodeURIComponent(diagnosisId));
  }

  insertLink(values: string): Observable<HttpResponse<string>> {
    return this.http.post(this.baseUri + 'Diagnosis/PostLink', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

  deleteLink(micResultId: string, diagnosisId: string): Observable<HttpResponse<string>> {
    return this.http.delete(this.baseUri + 'Diagnosis/DeleteLink?micResultId=' + encodeURIComponent(micResultId) + '&diagnosisId=' + encodeURIComponent(diagnosisId), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

}
@Injectable()
export class NotificationFunc {
  baseUri = `${environment.apiUrl}`;
  constructor(private http: HttpClient) {
  }
  getAll(userId: string, ts: string): Observable<NotificationViewModel[]> {
    return this.http.get<NotificationViewModel[]>(this.baseUri + 'Notification/GetAll?userId=' + encodeURIComponent(userId) + '&ts=' + encodeURIComponent(ts));
  }
  unsetNewMessages(values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'Notification/UnsetNewMessages', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }
  deactivate(values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'Notification/Deactivate', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }
  deactivateAll(values: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'Notification/DeactivateAll', JSON.stringify(values), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }
  getCategorySettings(userId: string): Observable<NotificationCategory[]> {
    return this.http.get<NotificationCategory[]>(this.baseUri + 'Notification/GetCategorySettings?userId=' + encodeURIComponent(userId));
  }
  updateCategorySetting(id: string, value: string): Observable<HttpResponse<string>> {
    return this.http.put(this.baseUri + 'Notification/UpdateCategorySetting?key=' + encodeURIComponent(id), JSON.stringify(value), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
  }

}





