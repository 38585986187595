import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { ClinicService } from '../clinic.service';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { loadMessages, locale } from 'devextreme/localization';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoginUser, Diagnosis } from 'src/app/services/webapiclient.service';
import { CodesFunc, CodeCombo } from 'src/app/services/codes.service';
import { ActivatedRoute } from '@angular/router';
import notify from 'devextreme/ui/notify';
import * as $ from 'jquery';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmDialogService } from 'src/app/confirm-dialog/confirm-dialog-service';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';

/**
 * Diagnózis rögzítés, orvos felhasználók részéről.
 */

@Component({
  selector: 'app-diagnosis',
  templateUrl: './diagnosis.component.html',
  styleUrls: ['./diagnosis.component.scss']
})
export class DiagnosisComponent implements OnInit, OnDestroy {

  @Input() patientId: string;

  @ViewChild('newDiagnosis', { static: true }) newDiagnosis: TemplateRef<any>;
  @ViewChild('editDiagnosis', { static: true }) editDiagnosis: TemplateRef<any>;

  modalRef: BsModalRef;
  stylingMode = "filled"
  dataSource: any;
  micSource: any;
  progressionSource: any;
  medicationSource: any;
  probabilitySource: any;
  currentUser: LoginUser;
  error: any;
  addMode: boolean = false;
  editItem: any;
  maxDate: Date = new Date();
  dateFormat: string;

  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private clinicService: ClinicService,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private codesFunc: CodesFunc,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private confirmDialogService: ConfirmDialogService,
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

    //Memóriaromlás valószínű oka adatforrás
    this.micSource = new CustomStore({
      key: "id",
      loadMode: "raw",
      cacheRawData: false,
      load: () => codesFunc.getCodesCombo('MemoryImpairmentCausalProbability')
    });
  }


  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });

    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      //this.patientId = this.currentUser.id;
      this.loadDataSource();
      this.loadCodesSources();
    }
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  simpleClone(obj: any) {
    return Object.assign({}, obj);
  }

  loadDataSource() {
    this.clinicService.getAll(this.patientId).then(
      result => {
        this.dataSource = <Diagnosis[]>result;
      }
    ).catch(err => {
      this.error = err;
    });

  }

  loadCodesSources() {
    this.codesFunc.getCodesCombo('ExpectedFutureProgression').then(
      result => {
        this.progressionSource = <CodeCombo[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
    this.codesFunc.getCodesCombo('PreDemDrug').then(
      result => {
        this.medicationSource = <CodeCombo[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
    this.codesFunc.getCodesCombo('MemoryImpairmentCausalProbability').then(
      result => {
        this.probabilitySource = <CodeCombo[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
  }

  clickEdit(index) {

    this.editItem = <Diagnosis>{};
    this.editItem = this.simpleClone(this.dataSource[index]);

    //this.selectedIndex = index; 
    //ez akkor kell, ha dátum ellenőrzés van a formon
    //át kell alakítani dátumra, másképp hibára fut az ellenőrzés
    this.editItem.diagnosisDate = new Date(this.editItem.diagnosisDate);
    this.modalRef = this.modalService.show(this.editDiagnosis, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  //  clickDelete(index){
  //   const that = this;  
  //   this.confirmDialogService.confirmThis(this.translation.translate('Core.ConfirmDeleting'), function () {  
  //       if(that.dataSource[index].id){
  //         that.patientService.deleteDetail(that.dataSource[index].id).then(
  //           result => {
  //             if(result == "OK"){
  //               that.closeModal();
  //               that.dataSource.splice(index,1);
  //               }else{
  //                 that.error = result;
  //             }
  //             }
  //           ).catch(err => {
  //               that.error = err;
  //           });
  //       }
  //   }, function () {  
  //     //alert("No clicked");  
  //   })  
  // }

  clickNew() {
    this.editItem = <Diagnosis>{};
    this.editItem.patientId = this.patientId;
    this.editItem.diagnosisDate = new Date();
    this.maxDate.setHours(24);
    if (this.dataSource[0]) {
      //már van előzmény, onnan átveszünk pár adatot
      this.editItem.medication = this.dataSource[0].medication;
    }
    this.addMode = true;
    this.modalRef = this.modalService.show(this.newDiagnosis, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  //Gyógyszerezés mező kitöltése kódtár alapján (PreDemDrug)
  //Kódtárból átvesszük a kiválasztott gyógyszerezést, de utána szabadon hozzás is írhatunk.
  onValueChanged(e) {
    let selectedText = this.medicationSource.filter(f => f.id == e.value)[0].descriptionTranslated;
    if (this.editItem.medication) {
      this.editItem.medication = this.editItem.medication + "\n" + selectedText;
    } else {
      this.editItem.medication = selectedText;
    }
  }

  closeModal() {
    this.modalRef.hide();
    if (this.addMode) {
      //Új bevitelt szakítunk meg
      this.addMode = false;
    }
  }

  onFormSubmit = function (e, index) {
    this.error = "";
    this.loading = true;
    //Ha csak dátumot kérünk be a devextreme date-box-nál, akkor 0 órát ad vissza
    //hozzá kell adni 1 órát, hogy ne az előző napra konvertája a JSON.stringify
    if (this.editItem.diagnosisDate) this.editItem.diagnosisDate.setHours(6);
    if (!this.addMode) {
      //módosítás
      this.dataSource[index] = this.simpleClone(this.editItem);

      this.clinicService.updateDiagnosis(JSON.stringify(this.dataSource[index])).then(
        result => {
          if (result == "OK") {
            notify({
              message: "Sikeres mentés!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.closeModal();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    } else {
      //új
      this.clinicService.insertDiagnosis(JSON.stringify(this.editItem)).then(
        result => {
          if (result == "OK") {
            notify({
              message: "Sikeres mentés!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.addMode = false;
            this.closeModal();
            this.loadDataSource();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    }
    //let result: any;

    e.preventDefault();
  }

}

