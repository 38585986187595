import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { JwtInterceptor } from '../helpers/jwt.interceptor';
import { ErrorInterceptor } from '../helpers/error.interceptor';

import { l10nConfig, initL10n, LocaleValidation, AppStorage, HttpTranslationLoader } from '../l10n-config';
import { L10nTranslationModule, L10nIntlModule } from 'angular-l10n';
import { ThryveConnectionComponent } from './thryve-connection/thryve-connection.component';
import {ThryveService} from './thryve.service';
import { ThryveDataComponent } from './thryve-data/thryve-data.component';

import { DxMenuModule, DxSelectBoxModule, DxCheckBoxModule, DxValidatorModule, DxValidationSummaryModule,
  DxTextBoxModule, DxFormModule, DxLoadPanelModule, DxChartModule, DxDateBoxModule, DxPieChartModule } from 'devextreme-angular';
import { DxDataGridModule,
   DxBulletModule,
   DxTemplateModule } from 'devextreme-angular';
import { ThryveRoutingModule } from './thryve-routing,module';
import { ThryveSpo2Component } from './thryve-spo2/thryve-spo2.component';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [ThryveConnectionComponent, ThryveDataComponent, ThryveSpo2Component],
  exports: [
    ThryveConnectionComponent,
    ThryveDataComponent
  ],
  imports: [
    ThryveRoutingModule,
    CommonModule,
    HttpClientModule,
    L10nTranslationModule.forRoot(
      l10nConfig,
      {
          storage: AppStorage,
          translationLoader: HttpTranslationLoader
      }
    ),
    L10nIntlModule,
    DxMenuModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxTextBoxModule,
    DxFormModule,
    DxLoadPanelModule,
    DxChartModule,
    DxDateBoxModule,
    DxPieChartModule,
    SharedModule

  ],
  providers: [
     { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
     { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
     ThryveService
   ]
})
/**
 * Thryve integrációs modul
 */

export class ThryveModule { }
