<!-- <dx-progress-bar #progressBar id="progress-bar-status" width="100%"
    [class.complete]="progressBar.value == correctWordsCount" [min]="0" [max]="correctWordsCount"
     [showStatus]="false"
    [value]="actualCount">
</dx-progress-bar> -->

<div class="m-2">
    <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-4">
        <div class="col p-2" *ngFor="let item of heatMap; let i = index"
            [style.background-color]="bgColor[item.correctHits]">
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <span *ngIf="item.wordToGuess" class="h3"> {{item.word}} </span>
                    <span *ngIf="!item.wordToGuess"> {{item.word}} </span>
                    <br>
                    <small>({{label}}: {{item.correctHits}})</small>
                </div>
                <div *ngIf="item.correct" class="h4" style="color: #701ABA;"><i class="bi bi-check-lg"></i>
                </div>
            </div>

        </div>
    </div>
</div>
<jw-pagination [items]="roundItems" [pageSize]="1" (changePage)="onChangePage($event)" class="mb-3"></jw-pagination>

<!-- <dx-chart id="chart" [dataSource]="chartDataSource">
    <dxi-series argumentField="round" valueField="result" name="Forduló" color="#0A6371">
        <dxo-point color="0A6371"></dxo-point>
    </dxi-series>


    <dxi-value-axis></dxi-value-axis>

    <dxo-common-series-settings type="line"></dxo-common-series-settings>

    <dxo-argument-axis [allowDecimals]="false">
        <dxo-grid [visible]="false"></dxo-grid>
        <dxo-minor-grid [visible]="false"></dxo-minor-grid>
    </dxo-argument-axis>

    <dxo-legend [visible]="true" verticalAlignment="bottom" horizontalAlignment="center">
    </dxo-legend>

    <dxo-tooltip [enabled]="true" [shared]="false">
    </dxo-tooltip>

    <dxo-common-pane-settings>
        <dxo-border [visible]="true"></dxo-border>
    </dxo-common-pane-settings>
</dx-chart> -->