import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { PatientService } from '../patient.service';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import messagesEs from 'node_modules/devextreme/localization/messages/es.json';
import { loadMessages, locale } from 'devextreme/localization';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoginUser, LabResult, LabResultsEditModel, PatientDataFunc, LabSumDataItem, LabDataItemEditModel } from 'src/app/services/webapiclient.service';
import { ActivatedRoute } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmDialogService } from 'src/app/confirm-dialog/confirm-dialog-service';
import { BoolResult } from 'src/app/module-thryve/thryve.service';
import { first } from 'rxjs/operators';
import { ErrorService } from 'src/app/services/error.service';
import { DxValidationGroupComponent } from 'devextreme-angular/ui/validation-group';
import { CorrectDate } from 'src/app/shared/utils';
import { Subscription } from 'rxjs';

/**
 * Labor adatok rögzítése. (vér, vizelet)
 * Páciens katalógusból hívható, orvos szerepkörrel.
 */

@Component({
  selector: 'app-lab-data',
  templateUrl: './lab-data.component.html',
  styleUrls: ['./lab-data.component.scss']
})
export class LabDataComponent implements OnInit, OnDestroy {
  @Input() inpPatientId: string;

  @ViewChild('newLabResult', { static: true }) newLabResult: TemplateRef<any>;
  @ViewChild('editLabResult', { static: true }) editLabResult: TemplateRef<any>;

  modalRef: BsModalRef;
  stylingMode = "filled"
  dataSource: any;
  sumDataSource: any;
  currentUser: LoginUser;
  error: any;
  addMode: boolean = false;
  editItem: any;
  maxDate: Date = new Date();
  patientId: any;
  groupInstance: any[] = {} as any[];
  options: any[];
  option: string;

  combo: any[];

  newMode: string;
  dateFormat: string;

  resultDateInstance: any;
  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private patientService: PatientService,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private modalService: BsModalService,
    private confirmDialogService: ConfirmDialogService,
    private patientDataFunc: PatientDataFunc,
    private errorService: ErrorService
  ) {
    //this.asyncRangeValidation = this.asyncRangeValidation.bind(this);

    //Részletes, vagy összesített megjelenítés
    //Összesítettnél minden mért értékből a legutolsó látszik
    this.options = [
      {
        "name": this.translation.translate('Project.Detailed'),
        "value": "detailed"
      },
      {
        "name": this.translation.translate('Project.Summary'),
        "value": "summary"
      }
    ];
    this.option = this.options[0].value;

    this.combo = [
      {
        "name": this.translation.translate('Project.Negative'),
        "value": 0
      },
      {
        "name": this.translation.translate('Project.Positive'),
        "value": 1
      }
    ];

    const huMessages = JSON.stringify(messagesHu['hu']);
    const esMessages = JSON.stringify(messagesEs['es']);
    loadMessages({
      'hu': JSON.parse(huMessages),
      'es': JSON.parse(esMessages),
    });
  }


  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });
    this.maxDate.setDate(this.maxDate.getDate() + 1);
    if (this.inpPatientId) this.patientId = this.inpPatientId;
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      if (!this.patientId) this.patientId = this.currentUser.id;
      this.loadDataSource();
    }
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  //Labor adatok betöltése (részletes)
  loadDataSource() {
    this.patientService.getLaborResults(this.patientId).then(
      result => {
        this.dataSource = <LabResult[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
  }

  //Összesített labor adatok betöltése
  loadSumDataSource() {
    this.patientService.getLabSumData(this.patientId).then(
      result => {
        this.sumDataSource = <LabSumDataItem[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
  }

  /// Új laborértékek rögzítéséhez előkészítjük a vezérlők létrehozásához szükséges adatokat.
  /// Mivel a laborértékek mennyisége változhat, ezért dinamikusan hozzuk létre majd a vezérlőket.
  loadLabCreate() {
    this.patientService.getLabResultsCreate(this.patientId).then(
      result => {
        this.editItem = <LabResultsEditModel[]>result;
        this.editItem.patientDataId = this.patientId;
        this.editItem.measuredOn = new Date();
      }
    ).catch(err => {
      this.error = err;
    });
  }

  // Szerkesztéshez előkészítjük az adott páciens, adott napra rögzített laboradatait.
  loadLabUpdate(webId) {
    this.patientService.getLabResultsUpdate(this.patientId, webId).then(
      result => {
        this.editItem = <LabResultsEditModel[]>result;
        this.editItem.patientDataId = this.patientId;
        //ez akkor kell, ha dátum ellenőrzés van a formon
        //át kell alakítani dátumra, másképp hibára fut az ellenőrzés
        this.editItem.measuredOn = new Date(this.editItem.measuredOn);
      }
    ).catch(err => {
      this.error = err;
    });
  }

  clickEdit(index) {

    this.loadLabUpdate(this.dataSource[index].webId);
    this.newMode = "edit";

    this.modalRef = this.modalService.show(this.editLabResult, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  clickNew(mode) {
    this.loadLabCreate();
    this.newMode = mode;
    this.addMode = true;
    this.modalRef = this.modalService.show(this.newLabResult, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  clickDelete(index) {
    const that = this;
    this.confirmDialogService.confirmThis(this.translation.translate('Core.ConfirmDeleting'), function () {
      if (that.dataSource[index].webId) {
        that.patientService.deleteLabData(that.patientId, that.dataSource[index].webId).then(
          result => {
            if (result == "OK") {
              that.dataSource.splice(index, 1);
            } else {
              that.error = result;
            }
          }
        ).catch(err => {
          that.error = err;
        });
      }
    }, function () {
      //alert("No clicked");
    })
  }

  closeModal() {
    this.modalRef.hide();
    if (this.addMode) {
      //Új bevitelt szakítunk meg
      this.addMode = false;
    }
  }

  //nem használjuk
  // asyncRangeValidation = function (params) {
  //   let ret: boolean = false;
  //   let boolResult: BoolResult;
  //   let id = params.validator._$element[0].id;
  //   let index = this.editItem.labDataList.findIndex(f => f.labCodeId == id);
  //   let item = this.editItem.labDataList[index];
  //   let result: BoolResult = {} as BoolResult;
  //   return new Promise((resolve, reject) => {
  //     this.patientDataFunc.checkLabDataRange(item.appReference, item.selectedUnit, item.value).pipe(first()).subscribe(
  //       (p) => {
  //         result = p;
  //         params.rule.message = result.error;
  //       },
  //       (e) => {
  //         this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
  //         reject(e.message + e.error.ExceptionMessage);
  //       },
  //       () => {
  //         resolve(result.result);
  //       }
  //     );
  //   });

  // }

  filter(itemList: LabDataItemEditModel[]): LabDataItemEditModel[] {
    //módisításnál az összes labor eredményt szerkesztjük
    if (this.newMode == 'edit') return itemList;
    //új bevitelnél vagy vizelet vagy vér
    return itemList.filter(s => s.type == this.newMode);
  }

  // validateGroup(e, i) {
  //   this.groupInstance[i].validate();
  // }

  //Ezt most nem használjuk
  onGroupInit(e, i) {
    this.groupInstance[i] = e.component.instance();
  }

  //Itt ellenőrizzük a laborérték tartományokat
  onValueChanged(e, id) {
    //  let id = e.element.id;
    let index = this.editItem.labDataList.findIndex(f => f.labCodeId == id);
    let item = this.editItem.labDataList[index];
    let boolResult: BoolResult = {} as BoolResult;

    this.patientService.checkLabDataRange(item.appReference, item.selectedUnit, item.value).then(
      result => {
        boolResult = <BoolResult>result;
        this.editItem.labDataList[index].outOfReference = !boolResult.result;
        this.editItem.labDataList[index].error = boolResult.error;
      }
    ).catch(err => {
      this.error = err;
    });

  }

  //Részletes vagy összesített megjelenítés
  onOptionChanged(e) {
    this.option = e.value;
    if (this.option == "summary") {
      this.loadSumDataSource();
    } else {
      this.loadDataSource();
    }
  }

  onFormSubmit = function (e, index) {
    this.error = "";
    this.loading = true;
    //Ha csak dátumot kérünk be a devextreme date-box-nál, akkor 0 órát ad vissza
    //hozzá kell adni 1 órát, hogy ne az előző napra konvertája a JSON.stringify
    if (this.editItem.measuredOn) this.editItem.measuredOn.setHours(6);

    if (!this.addMode) {
      //módosítás
      this.patientService.updateLabData(JSON.stringify(this.editItem)).then(
        result => {
          if (result == "OK") {
            notify({
              message: "Sikeres mentés!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.loadDataSource();
            this.closeModal();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    } else {
      //új
      this.patientService.updateLabData(JSON.stringify(this.editItem)).then(
        result => {
          if (result == "OK") {
            notify({
              message: "Sikeres mentés!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.addMode = false;
            this.closeModal();
            this.loadDataSource();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    }
    //let result: any;

    e.preventDefault();
  }

  simpleClone(obj: any) {
    return Object.assign({}, obj);
  }

  resultDateFocusOut(s) {
    CorrectDate(this.resultDateInstance);
  }

  initResultDate(e) {
    this.resultDateInstance = e.component;
  }

}
