<div class="container">
    <div class="m-3">
        <div class="d-flex align-items-center">
            <div class="general-icons-sm me-2">
                <img src="../../assets/images/badge.svg">
            </div>
            <h1 class="display-6 m-0">{{ 'Notification.MyAwards' | translate:locale.language }}</h1>
        </div>
        <hr>
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4 g-3">
            <div class="col" *ngIf=" badgesModel.level1">
                <div class="card h-100">
                    <div class="card-body d-flex flex-column align-items-center">
                        <div class="badge-style"> 
                            <img src="../../assets/badge/badge-1.svg">
                        </div>
                        <h5>
                            <span class="badge rounded-pill bg-primary">
                                {{badgesModel.date1 | date: dateFormat}}
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col" *ngIf=" !badgesModel.level1">
                <div class="card h-100">
                    <div class="card-body d-flex flex-column align-items-center">
                        <div class="disabled-badge-style"> 
                            <img src="../../assets/badge/badge-1.svg">
                        </div>
                        <h5>
                            <span class="badge rounded-pill bg-secondary">
                                még nincs meg
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col" *ngIf=" badgesModel.level2">
                <div class="card h-100">
                    <div class="card-body d-flex flex-column align-items-center">
                        <div class="badge-style"> 
                            <img src="../../assets/badge/badge-2.svg">
                        </div>
                        <h5>
                            <span class="badge rounded-pill bg-primary">
                                {{badgesModel.date2 | date: dateFormat}}
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col" *ngIf=" !badgesModel.level2">
                <div class="card h-100">
                    <div class="card-body d-flex flex-column align-items-center">
                        <div class="disabled-badge-style"> 
                            <img src="../../assets/badge/badge-2.svg">
                        </div>
                        <h5>
                            <span class="badge rounded-pill bg-secondary">
                                még nincs meg
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col" *ngIf=" badgesModel.level3">
                <div class="card h-100">
                    <div class="card-body d-flex flex-column align-items-center">
                        <div class="badge-style"> 
                            <img src="../../assets/badge/badge-3.svg">
                        </div>
                        <h5>
                            <span class="badge rounded-pill bg-primary">
                                {{badgesModel.date3 | date: dateFormat}}
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col" *ngIf=" !badgesModel.level3">
                <div class="card h-100">
                    <div class="card-body d-flex flex-column align-items-center">
                        <div class="disabled-badge-style"> 
                            <img src="../../assets/badge/badge-3.svg">
                        </div>
                        <h5>
                            <span class="badge rounded-pill bg-secondary">
                                még nincs meg
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col" *ngIf=" badgesModel.level4">
                <div class="card h-100">
                    <div class="card-body d-flex flex-column align-items-center">
                        <div class="badge-style"> 
                            <img src="../../assets/badge/badge-4.svg">
                        </div>
                        <h5>
                            <span class="badge rounded-pill bg-primary">
                                {{badgesModel.date4 | date: dateFormat}}
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col" *ngIf=" !badgesModel.level4">
                <div class="card h-100">
                    <div class="card-body d-flex flex-column align-items-center">
                        <div class="disabled-badge-style"> 
                            <img src="../../assets/badge/badge-4.svg">
                        </div>
                        <h5>
                            <span class="badge rounded-pill bg-secondary">
                                még nincs meg
                            </span>
                        </h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>