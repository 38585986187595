import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { loadMessages, locale } from 'devextreme/localization';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AllocatedTask, BloodPressure, CancelTreatmentModel, LoginUser } from 'src/app/services/webapiclient.service';
import notify from 'devextreme/ui/notify';
import { ConfirmDialogService } from 'src/app/confirm-dialog/confirm-dialog-service';
import { Subscription } from 'rxjs';
import { PatientService } from 'src/app/module-patient/patient.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CodeCombo, CodesFunc, CodeWarehouseEditModel } from 'src/app/services/codes.service';
import { CorrectDate } from 'src/app/utils';
import { TestEvaluation, TestEvaluations } from 'src/app/games/shared/constants';

@Component({
  selector: 'app-allocated-tasks',
  templateUrl: './allocated-tasks.component.html',
  styleUrls: ['./allocated-tasks.component.scss']
})
export class AllocatedTasksComponent implements OnInit, OnDestroy {

  @Input() patientId: string;
  @ViewChild('newTask', { static: true }) newTask: TemplateRef<any>;
  @ViewChild('newTaskSheet', { static: true }) newTaskSheet: TemplateRef<any>;
  @ViewChild('newExercise', { static: true }) newExercise: TemplateRef<any>;

  modalRef: BsModalRef;
  dataSource: any;
  taskSource: CodeWarehouseEditModel[];
  taskSheetSource: any;
  exerciseSource: any;
  frequencySource: any;
  frequencySourceAll: any;
  frequencySourceTask: any;
  frequencySourceExercise: any;
  stylingMode = "filled"
  currentUser: LoginUser;
  error: any;
  dateFormat: string;
  minDate: Date = new Date();
  loading = false;
  editItem: any;
  addMode: any;
  selectedIndex: any;
  startDateInstance: any;
  endDateInstance: any;
  compareMessage: string;
  
  testEvaluations: TestEvaluation[];

  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private patientService: PatientService,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private codesFunc: CodesFunc,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

  }


  ngOnInit(): void {
    this.testEvaluations = TestEvaluations;
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
        //Start és end dátum összehasonlító hibaüzenet
        this.compareMessage = this.translation.translate('Core.ValidationDateGreaterError')
          .replace('{0}', this.translation.translate('Task.End'))
          .replace('{1}', this.translation.translate('Task.Start'));
      });

    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      this.loadDataSource();
    }
    this.minDate.setDate( this.minDate.getDate() -1 );
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  //Adatok betöltése
  loadDataSource() {
    this.patientService.getTaskList(this.patientId).then(
      result => {
        this.dataSource = <AllocatedTask[]>result;
        this.loadCodesSources();
        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
    });

  }

  //Kódok betöltése
  loadCodesSources() {
    this.codesFunc.getCodesData('Games').then(
      result => {
        this.taskSource = <CodeWarehouseEditModel[]>result;
        this.taskSource = this.taskSource.filter(f=>f.active == true && f.customerModified);
      }
    ).catch(err => {
      this.error = err;
    });
    this.codesFunc.getCodesData('CognitiveTest').then(
      result => {
        this.taskSheetSource = <CodeWarehouseEditModel[]>result;
        this.taskSheetSource = this.taskSheetSource.filter(f=>f.providerId == null || f.providerId == this.currentUser.providerId);
        this.taskSheetSource = this.taskSheetSource.filter(f=>f.applicationReference != 'SDMT' &&
                                                              f.applicationReference != 'BVMT-R' &&
                                                              f.applicationReference != 'T25FW' &&
                                                              f.applicationReference != '9-HPT' &&
                                                              f.applicationReference != 'CVLT-II');
      }
    ).catch(err => {
      this.error = err;
    });
    this.codesFunc.getKbExercises().then(
      result => {
        this.exerciseSource = <CodeCombo[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
    this.codesFunc.getCodesData('TaskFrequency').then(
      result => {
        this.frequencySource = <CodeWarehouseEditModel[]>result;
        this.frequencySourceAll = <CodeWarehouseEditModel[]>result;
        this.frequencySourceTask = this.frequencySource.filter(s => +s.value > 1);
        this.frequencySourceExercise = this.frequencySource.filter(s => +s.value < 10);
      }
    ).catch(err => {
      this.error = err;
    });
  }

  clickNewTask() {
    this.editItem = <AllocatedTask>{};
    this.editItem.patientId = this.patientId;
    this.editItem.doctorId = this.currentUser.id;
    this.editItem.physicalExercise = false;
    this.editItem.taskSheet = false;
    this.frequencySource = this.frequencySourceTask;
    this.addMode = true;
    this.modalRef = this.modalService.show(this.newTask, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }
  clickNewTaskSheet() {
    this.editItem = <AllocatedTask>{};
    this.editItem.patientId = this.patientId;
    this.editItem.doctorId = this.currentUser.id;
    this.editItem.physicalExercise = false;
    this.editItem.taskSheet = true;
    this.frequencySource = this.frequencySourceTask;
    this.addMode = true;
    this.modalRef = this.modalService.show(this.newTaskSheet, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  clickNewExercise() {
    this.editItem = <AllocatedTask>{};
    this.editItem.patientId = this.patientId;
    this.editItem.doctorId = this.currentUser.id;
    this.editItem.physicalExercise = true;
    this.editItem.taskSheet = false;
    this.frequencySource = this.frequencySourceExercise;
    this.addMode = true;
    this.modalRef = this.modalService.show(this.newExercise, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }
  clickPilotTasks() {
    this.loading = true;
    let startDate = new Date();
    let ix = 0;
    const arr: Array<{ id1: number, id2: number }> = [{ id1: 0, id2: 3 },
    { id1: 1, id2: 4 },
    { id1: 2, id2: 5 },
    { id1: 3, id2: 6 },
    { id1: 4, id2: 0 },
    { id1: 5, id2: 1 },
    { id1: 6, id2: 2 }];
    // this.taskSource.forEach(task => {
    //   startDate.setDate(startDate.getDate() + ix + 1);

    //   this.editItem = <AllocatedTask>{};
    //   this.editItem.patientId = this.patientId;
    //   this.editItem.doctorId = this.currentUser.id;
    //   this.editItem.physicalExercise = false;
    //   this.editItem.startDate = startDate;
    //   this.editItem.endDate = new Date(2021, 11, 19, 10);
    //   this.editItem.frequencyId = 602;
    //   this.editItem.taskId = task.id;
    //   this.patientService.insertTask(JSON.stringify(this.editItem)).then(
    //     result => {
    //       ix++;
    //     }
    //   ).catch(err => {
    //     this.error = err;
    //     this.loading = false;
    //   });
    // });

    let startDate1 = new Date();
    //startDate1.setDate(startDate1.getDate() + 1);
    let iy = 0;

    for (let i = 0; i < this.taskSource.length; i++) {
      startDate1.setDate(startDate1.getDate() + 1);

      this.editItem = <AllocatedTask>{};
      this.editItem.patientId = this.patientId;
      this.editItem.doctorId = this.currentUser.id;
      this.editItem.physicalExercise = false;
      this.editItem.startDate = startDate1;
      this.editItem.endDate = new Date(2021, 11, 19, 10);
      this.editItem.frequencyId = 602;
      this.editItem.taskId = this.taskSource[arr[i].id1].id;
      this.patientService.insertTask(JSON.stringify(this.editItem)).then(
        result => {
          //iy++;
          // if (iy == this.taskSource.length-1) {
          //   notify({
          //     message: "Sikeres mentés!",
          //     position: {
          //       my: "center top",
          //       at: "center top"
          //     }
          //   }, "success", 3000);
          //   this.addMode = false;
          //   this.loadDataSource();
          // }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });

      this.editItem = <AllocatedTask>{};
      this.editItem.patientId = this.patientId;
      this.editItem.doctorId = this.currentUser.id;
      this.editItem.physicalExercise = false;
      this.editItem.startDate = startDate1;
      this.editItem.endDate = new Date(2021, 11, 19, 10);
      this.editItem.frequencyId = 602;
      this.editItem.taskId = this.taskSource[arr[i].id2].id;
      this.patientService.insertTask(JSON.stringify(this.editItem)).then(
        result => {
          iy++;
          if (iy == this.taskSource.length-1) {
            notify({
              message: "Sikeres mentés!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.addMode = false;
            this.loadDataSource();
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });

    };

  }

  clickDelete(index) {
    const that = this;
    this.confirmDialogService.confirmThis(this.translation.translate('Core.ConfirmDeleting'), function () {
      if (that.dataSource[index].id) {
        that.patientService.deleteTask(that.patientId, that.dataSource[index].id).then(
          result => {
            if (result == "OK") {
              that.dataSource.splice(index, 1);
            } else {
              that.error = result;
            }
          }
        ).catch(err => {
          that.error = err;
        });
      }
    }, function () {
      //alert("No clicked");  
    })
  }

  onShown() {
    setTimeout(() => {
      this.loading = false;
    }, 15000);
  }

  closeModal() {
    this.modalRef.hide();
    if (this.addMode) {
      //Új bevitelt szakítunk meg
      this.addMode = false;
    }
  }
  startDateFocusOut(s) {
    CorrectDate(this.startDateInstance);
  }

  initStartDate(e) {
    this.startDateInstance = e.component;
  }

  endDateFocusOut(s) {
    CorrectDate(this.endDateInstance);
  }

  initEndDate(e) {
    this.endDateInstance = e.component;
  }

  checkComparison = () => {
    return this.editItem.startDate;
  };

  onFormSubmit = function (e, index) {
    this.error = "";
    this.loading = true;
    //Ha csak dátumot kérünk be a devextreme date-box-nál, akkor 0 órát ad vissza
    //hozzá kell adni 1 órát, hogy ne az előző napra konvertája a JSON.stringify
    if (this.editItem.startDate) this.editItem.startDate.setHours(6);
    if (!this.addMode) {
      //módosítás
      this.dataSource[index] = this.simpleClone(this.editItem);

      this.patientService.updateDetail(this.dataSource[index].id, JSON.stringify(this.dataSource[index])).then(
        result => {
          if (result == "OK") {
            this.loadDataSource();
            notify({
              message: this.translation.translate('Core.SaveSuccessful'),
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.closeModal();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    } else {
      //új
      this.patientService.insertTask(JSON.stringify(this.editItem)).then(
        result => {
          if (result == "OK") {
            notify({
              message: this.translation.translate('Core.SaveSuccessful'),
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.addMode = false;
            this.closeModal();
            this.loadDataSource();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    }
    //let result: any;

    e.preventDefault();
  }

  save() {
    this.loading = true;
    this.editItem = <CancelTreatmentModel>{};
    this.editItem.doctorId = this.currentUser.id;
    this.editItem.patientId = this.patientId;

    this.patientService.cancelTreatment(JSON.stringify(this.editItem)).then(
      result => {
        if (result === 'OK') {
        }
        this.loading = false;
        this.router.navigate(['/patient-catalogue']);
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });

  }
}

