<!--harang értesítés gomb a menübe-->
<button *ngIf="notImportantMessagesCount>0 && !onToggle" (click)="onShow()"
    title=" {{ 'Project.Alerts' | translate:locale.language }}"
    class="btn btn-notification position-relative me-3" type="button">
    <i class="bi bi-bell"></i>
    <span *ngIf="newMessagesCount>0" style="font-size: 0.7rem;"
        class="position-absolute top-0 translate-middle badge rounded-pill bg-danger">
        {{newMessagesCount}}
    </span>
</button>

<!--kör alakú értesítés jobb alsó sarokban-->
<!-- <div class="floating-notification rounded-circle bg-primary d-flex justify-content-center pointer"
    *ngIf="messagesCount>0 && !onToggle" (click)="onShow()" title=" {{ 'Project.Alerts' | translate:locale.language }}">
    <div class="d-flex align-items-center">
        <i class="h2 text-white bi bi-bell"></i>
    </div>
    <div class="floating-notification-counter badge rounded-circle text-white bg-danger">{{newMessagesCount}}</div>
</div> -->

<!--kör alakú értesítés bezárás jobb alsó sarokban-->
<div *ngIf="onToggle" class="floating-notification rounded-circle bg-danger d-flex justify-content-center pointer"
    (click)="closeNotification()">
    <div class="d-flex align-items-center">
        <span class="h1 m-0 text-white"><i class="bi bi-x"></i></span>
    </div>
</div>


<div [ngClass]="{'notification-area': true, 'open': onToggle}">
    <!--értesítés panel-->
    <div class="notifications">
        <!--értesítés-->
        <div [className]="'accordion notification-item mb-2 notification-' + item.type" id="notificationItemCollapse"
            *ngFor="let item of displayItems; index as i">
            <div class="accordion-item d-flex align-items-center justify-content-between" aria-expanded="true"
                aria-controls="collapseOne">
                <!--error ikon-->
                <div *ngIf="item.type=='error'" title=" {{ 'Notification.error' | translate:locale.language }}"
                    class="indicator p-3 d-flex align-items-center justify-content-center align-self-stretch">
                    <i class="text-white bi bi-bug"></i>
                </div>
                <!--figyelmeztetés ikon-->
                <div *ngIf="item.type=='warning'" title=" {{ 'Notification.warning' | translate:locale.language }}"
                    class="indicator p-3 d-flex align-items-center justify-content-center align-self-stretch">
                    <i class="text-white bi bi-exclamation-triangle"></i>
                </div>
                <!--info ikon-->
                <div *ngIf="item.type=='info'" title=" {{ 'Notification.info' | translate:locale.language }}"
                    class="indicator p-3 d-flex align-items-center justify-content-center align-self-stretch">
                    <i class="text-white bi bi-info"></i>
                </div>
                <!--értesítés ikon-->
                <div *ngIf="item.type=='alert'" title="{{ 'Notification.alert' | translate:locale.language }}"
                    class="indicator p-3 d-flex align-items-center justify-content-center align-self-stretch">
                    <i class="text-white bi bi-bell"></i>
                </div>
                <div class="p-2 w-100" *ngIf="item.taskName==null">
                    <span class="h5 mb-0">{{ item.message | translate:locale.language }}</span><br>
                    <strong class="mb-0">{{item.displayName}}</strong><br>
                    <span class="mb-0">{{item.eventDate | date: dateFormat}}</span>
                    <span class="mb-0">Megnéz</span>
                </div>
                <div class="p-2 w-100" *ngIf="item.taskName!=null">
                    <span class="h5 mb-0">{{ item.message | translate:locale.language }}</span><br>
                    <strong class="mb-0">{{item.taskName | translate:locale.language}}</strong><br>
                    <span class="mb-0">{{item.eventDate | date: dateFormat}}</span><br>
                    <a *ngIf="item.route" routerLink="/notes">{{ 'Core.EditView' | translate:locale.language }}</a>
                </div>
                <div class="text-center p-3">
                    <i class="bi bi-x pointer" (click)="deactivate(i)"
                        title="{{ 'Project.DeleteContent' | translate:locale.language }}"></i> <br>
                    <i data-bs-toggle="collapse" title="{{ 'Core.Details' | translate:locale.language }}"
                        [attr.data-bs-target]="'#collapse' + i" class="bi bi-chevron-down accordion-item pointer"></i>

                </div>
            </div>
            <div [id]="'collapse' + i" class="collapse p-3 my-translated-paragraph" aria-labelledby="headingOne"
                data-parent="#notificationItemCollapse">
                {{item.content | translate:locale.language}}
            </div>
        </div>
    </div>
</div>
<!-- Aktuális hírek Modal -->
<ng-template #actualNewsTmpl>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'Notification.ActualNews' | translate:locale.language }}</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <section class="content" [innerHTML]="infoPanel">
        </section>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="deactivateNews()" class="btn btn-neutral" data-bs-dismiss="modal">
            {{ 'Notification.DontShowAgain' | translate:locale.language }}
        </button>
        <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
            {{ 'Core.Close' | translate:locale.language }}
        </button>
    </div>

</ng-template>

<!-- Badge Modal -->
<ng-template #badgeTmpl>
    <div class="modal-header">
        <h4 *ngIf="badgeLevel==1" class="modal-title">{{ 'Notification.BadgeReminder' | translate:locale.language }}</h4>
        <h4 *ngIf="badgeLevel!=1" class="modal-title">{{ 'Notification.BadgeReminderNext' | translate:locale.language }}</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <section class="content" [innerHTML]="badgePanel">
        </section>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="deactivateBadge()" class="btn btn-neutral" data-bs-dismiss="modal">
            {{ 'Notification.DontShowAgain' | translate:locale.language }}
        </button>
        <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
            {{ 'Core.Close' | translate:locale.language }}
        </button>
    </div>

</ng-template>

<!-- Azonnal popup-ban feljövő fontos figyelmeztetések -->
<ng-template #importantTmpl>
    <div class="modal-header">
        <h4 class="modal-title">{{ 'Notification.important_warning' | translate:locale.language }}</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <!--értesítés panel-->
        <div class="notifications">
        <!--értesítés-->
        <div [className]="'accordion notification-item mb-2 notification-' + item.type" id="notificationItemCollapse"
            *ngFor="let item of displayImportantItems; index as i">
            <div class="accordion-item d-flex align-items-center justify-content-between" aria-expanded="true"
                aria-controls="collapseOne">
                <!--fontos figyelmeztetés ikon-->
                <div title=" {{ 'Notification.warning' | translate:locale.language }}"
                    class="indicator p-3 d-flex align-items-center justify-content-center align-self-stretch">
                    <i class="text-white bi bi-exclamation-triangle"></i>
                </div>
                <div class="p-2 w-100" *ngIf="item.taskName==null">
                    <span class="h5 mb-0">{{ item.message | translate:locale.language }}</span><br>
                    <strong class="mb-0">{{item.displayName}}</strong><br>
                    <span class="mb-0">{{item.eventDate | date: dateFormat}}</span>
                </div>
                <div class="p-2 w-100" *ngIf="item.taskName!=null">
                    <span class="h5 mb-0">{{ item.message | translate:locale.language }}</span><br>
                    <strong class="mb-0">{{item.taskName | translate:locale.language}}</strong><br>
                    <span class="mb-0">{{item.eventDate | date: dateFormat}}</span>
                </div>
                <div class="text-center p-3">
                     <br>
                    <i data-bs-toggle="collapse" title="{{ 'Core.Details' | translate:locale.language }}"
                        [attr.data-bs-target]="'#collapse' + i" class="bi bi-chevron-down accordion-item pointer"></i>

                </div>
            </div>
            <div [id]="'collapse' + i" class="collapse p-3 my-translated-paragraph" aria-labelledby="headingOne"
                data-parent="#notificationItemCollapse">
                {{item.content | translate:locale.language}}
            </div>
        </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="deactivateImportantWarning()" class="btn btn-neutral" data-bs-dismiss="modal">
            {{ 'Notification.DontShowAgain' | translate:locale.language }}
        </button>
        <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
            {{ 'Core.Close' | translate:locale.language }}
        </button>
    </div>
</ng-template>

