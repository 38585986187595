<div *ngIf="message" class="modal" tabindex="-1" role="dialog" style="display:block!important">    
    <div class="modal-dialog modal-md">    
        <div class="modal-content">    
            <div *ngIf="message?.type == 'confirm'" class="modal-body">      
                <h4 class="text-center confirm-message mb-4">{{message.text}}</h4>       
                <div class="d-flex justify-content-center">    
                    <a class="me-2" (click)="message.yesFn()">    
                        <button class="btn btn-danger">{{ 'Core.Yes' | translate:locale.language }}</button>    
                    </a>    
                    <a (click)="message.noFn()">    
                        <button class="btn btn-neutral">{{ 'Core.No' | translate:locale.language }}</button>    
                    </a>    
                </div>
            </div>  
        </div>  
    </div>  
</div>  
