import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DoctorDashboard, ExtendedDoctorDashboard, LoginUser } from 'src/app/services/webapiclient.service';
import { locale } from 'devextreme/localization';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import messagesEs from 'node_modules/devextreme/localization/messages/es.json';
import { loadMessages } from 'devextreme/localization';
import { PatientService } from 'src/app/module-patient/patient.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-kpi-s',
  templateUrl: './kpi-s.component.html',
  styleUrls: ['./kpi-s.component.scss']
})
export class KpiSComponent implements OnInit, OnDestroy {
  
  needTrend: string = '0';
  currentUser: LoginUser;
  error = '';
  loading: boolean = false;
  doctorDashboard: DoctorDashboard;
  extendedDashboard: ExtendedDoctorDashboard;
  activePatientPercent: number = 0;
  taskSheetPercent: number = 0;
  gamePercent: number = 0;
  totalTests: number = 0;
  totalTestSheets: number = 0;
  
  uploadUrl = `${environment.uploadUrl}`;
  blobUrl: string;

  testTypes: any[] = [{
    type: 'TaskSheet',
    val: 0,
  }, {
    type: 'Test',
    val: 0,
  }];

  dataSource: any;
  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private route: ActivatedRoute,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private patientService: PatientService

  ) {
    this.customizeText = this.customizeText.bind(this);
    this.customizeTextClinic = this.customizeTextClinic.bind(this);

    const huMessages = JSON.stringify(messagesHu['hu']);
    const esMessages = JSON.stringify(messagesEs['es']);
    loadMessages({
      'hu': JSON.parse(huMessages),
      'es': JSON.parse(esMessages)
    });
    this.currentUser = this.authenticationService.currentUserValue;

  }

  ngOnInit(): void {
    this.needTrend = this.route.snapshot.paramMap.get('needTrend');
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });
    this.getDoctorDashboardData();
  }
  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }
  getDoctorDashboardData() {
    this.loading = true;
    this.patientService.getDoctorDashboardData(this.currentUser.id).then(
      result => {
        this.extendedDashboard = <ExtendedDoctorDashboard>result;
        this.doctorDashboard = this.extendedDashboard.dashBoard;

        this.blobUrl = this.uploadUrl + this.extendedDashboard.topGamers[0].avatarName;

        let x = this.extendedDashboard.gameList[0].db / 100;
        if (x > 0) {
          this.extendedDashboard.gameList.forEach(element => {
            element.percent = element.db / x;
          });
        }

        let y = this.extendedDashboard.patientsByNeuro[0].db / 100;
        if (y > 0) {
          this.extendedDashboard.patientsByNeuro.forEach(element => {
            element.percent = element.db / y;
          });

        }

        if (this.doctorDashboard.sumPatient > 0) {
          this.activePatientPercent = this.doctorDashboard.activePatient / (this.doctorDashboard.sumPatient / 100);
        }
        if (this.doctorDashboard.taskSheetsPrev > 0) {
          this.taskSheetPercent = this.doctorDashboard.taskSheetsAct / (this.doctorDashboard.taskSheetsPrev / 100);
        }
        if (this.doctorDashboard.gamesPrev > 0) {
          this.gamePercent = this.doctorDashboard.gamesAct / (this.doctorDashboard.gamesPrev / 100);
        }

        this.testTypes[0].val = this.doctorDashboard.taskSheetsAct;
        this.testTypes[1].val = this.doctorDashboard.gamesAct;
        this.testTypes[0].type = this.translation.translate('Task.TaskSheet');
        this.testTypes[1].type = this.translation.translate('Task.Task');

        this.totalTests = this.doctorDashboard.taskSheetsAct + this.doctorDashboard.gamesAct;

        this.extendedDashboard.taskSheets.forEach(element => {
          this.totalTestSheets += element.db;
        });

        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });
  }

  customizeTextClinic(pointInfo: any) {
    return pointInfo.pointName + ' (' + this.extendedDashboard.taskSheets[pointInfo.pointIndex].db + ')';
  }

  customizeText(pointInfo: any) {
    if (pointInfo.pointIndex == 0) return pointInfo.pointName + ' (' + this.doctorDashboard.taskSheetsAct + ')';
    return pointInfo.pointName + ' (' + this.doctorDashboard.gamesAct + ')';
  }

}
