<!-- Regisztáció form -->


<div class="container-narrow">
    <div class="my-3">
        <div class="d-md-flex justify-content-between align-items-center">
            <h1 class="display-6 d-block">{{ 'Core.Registration' | translate:locale.language }}</h1>
        </div>
        <hr>
        <div class="card">
            <div class="card-body">
                <form action="your-action" (submit)="onFormSubmit($event)">
                    <dx-form id="form" [formData]="user" validationGroup="registerData">

                        <dxi-item dataField="userName" [editorOptions]="{ stylingMode: 'filled' }">
                            <dxo-label text="{{ 'Core.Entity_UserUserName' | translate:locale.language }}">
                            </dxo-label>
                                <dxi-validation-rule type="stringLength" [max]="25"
                                    message="Felhasználó név max. 25 karakter lehet!"></dxi-validation-rule>
                                <dxi-validation-rule type="pattern" [pattern]="userNamePattern"
                                    message="{{ 'Subscription.SpecialCharactersNotAllowed' | translate:locale.language }}"></dxi-validation-rule>
                                <dxi-validation-rule type="async" ignoreEmptyValue="true"
                                    [validationCallback]="asyncNameValidation" width="40">
                                </dxi-validation-rule>

                                <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-item>

                        <dxi-item dataField="displayName" [editorOptions]="{ stylingMode: 'filled' }">
                            <dxo-label text="{{ 'Core.Entity_UserName' | translate:locale.language }}">
                            </dxo-label>
                            <dxi-validation-rule type="required">
                            </dxi-validation-rule>
                        </dxi-item>

                        <dxi-item dataField="email" [editorOptions]="{ stylingMode: 'filled' }">
                            <dxo-label text="{{ 'Core.Account_Register_Email' | translate:locale.language }}">
                            </dxo-label>
                            <dxi-validation-rule type="required">
                            </dxi-validation-rule>
                            <dxi-validation-rule type="email">
                            </dxi-validation-rule>
                        </dxi-item>

                    </dx-form>
                    <div [hidden]="captcha">
                        <re-captcha id="recaptcha-container" (resolved)="resolved($event)"
                            [siteKey]="siteKey"></re-captcha>
                    </div>

                    <hr>
                    <dx-form [hidden]="!captcha" id="form" [formData]="user" validationGroup="registerData">>
                        <dxi-item itemType="button" horizontalAlignment="right" [buttonOptions]="buttonOptions">
                        </dxi-item>
                    </dx-form>
                </form>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
            </div>
        </div>
    </div>
</div>





<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loadingVisible"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" (onShown)="onShown()"
    (onHidden)="onHidden()">
</dx-load-panel>

<!-- Sikeres regisztráció, erről informáljuk a felhasználót -->
<ng-template #registrationConfirmtmpl>
    <div class="modal-header">
        <h5 class="modal-title pull-left">{{ 'Core.RegistrationSuccess' | translate:locale.language }}</h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body col-sm-12">
        <h6>
            {{ 'Core.PleaseCheckRegistrationEmail' | translate:locale.language }}
        </h6>

        <hr>

        <div class="form-group">
            <button type="button" class="btn btn-primary mb-2" (click)="closeModal()">OK
            </button>
        </div>
    </div>

</ng-template>