import { Component, Inject, OnInit } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { PatientService } from '../patient.service';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import CustomStore from 'devextreme/data/custom_store';
import { loadMessages, locale } from 'devextreme/localization';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoginUser } from 'src/app/services/webapiclient.service';
import { CodesFunc } from 'src/app/services/codes.service';
import { ActivatedRoute } from '@angular/router';

/**
 * Grides változat az eü. adatok bevitelére.
 * Már nem használjuk.
 * Helyette a patiens-details komponens van.
 */

@Component({
  selector: 'app-patient-health-data',
  templateUrl: './patient-health-data.component.html',
  styleUrls: ['./patient-health-data.component.scss']
})
export class PatientHealthDataComponent implements OnInit {
  dataSource: any;
  codesSource: any;
  frequencySource: any;
  subDataSource: any;
  subCodesSource: any;
  medicationSource: any;
  currentUser: LoginUser;
  code: string;
  subCode: string;
  detailId: any;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private patientService: PatientService,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private codesFunc: CodesFunc,
    private route: ActivatedRoute,
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

    this.dataSource = new CustomStore({
      key: "id",
      load: () => this.patientService.getDetails(this.currentUser.id, this.code),
      insert: (values) => this.patientService.insertDetail(this.currentUser.id, this.code, JSON.stringify(values)),
      update: (key, values) => this.patientService.updateDetail(key, JSON.stringify(values)),
      remove: (key) => this.patientService.deleteDetail(key).then()
    });

    this.codesSource = new CustomStore({
      key: "id",
      loadMode: "raw",
      cacheRawData: false,
      load: () => this.codesFunc.getCodesData(this.code)
    });

    this.frequencySource = new CustomStore({
      key: "id",
      loadMode: "raw",
      cacheRawData: false,
      load: () => this.codesFunc.getCodesData("FrequencyOfSymptoms")
    });

    this.subDataSource = new CustomStore({
      key: "id",
      load: () => this.patientService.getSubDetails(this.detailId),
      insert: (values) => this.patientService.insertSubDetail(this.currentUser.id, this.detailId, this.subCode, JSON.stringify(values)),
      update: (key, values) => this.patientService.updateSubDetail(key, JSON.stringify(values)),
      remove: (key) => this.patientService.deleteSubDetail(key).then()
    });

    this.subCodesSource = new CustomStore({
      key: "id",
      loadMode: "raw",
      cacheRawData: false,
      load: () => this.codesFunc.getCodesData(this.subCode)
    });

  }

  ngOnInit(): void {
    this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });

    this.currentUser = this.authenticationService.currentUserValue;
    this.code = this.route.snapshot.paramMap.get('code');
    this.subCode = this.route.snapshot.paramMap.get('subCode');

    // get return url from route parameters or default to '/'
    //this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }
  getSubDataSource(key) {
    this.detailId = key;
    return this.subDataSource;
  }

}

