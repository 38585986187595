<div class="evGuide-container d-flex justify-content-center mt-3">
    <div class="accordion" id="evGuide">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingOne">
                <button class="accordion-button" type="button" data-bs-toggle="collapse"
                    data-bs-target="#evGuideCollapse" aria-expanded="true"
                    aria-controls="evGuideCollapse">
                    <i class="bi bi-info-circle-fill"></i> <span class="ms-2">{{ title }}</span>
                </button>
            </h2>
            <div id="evGuideCollapse" class="accordion-collapse collapse" aria-labelledby="headingOne"
                data-bs-parent="#evGuide">
                <div class="accordion-body">
                    <span class="content" [innerHTML]="infoPanel">
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>

