/**
 * 0: Jó TAJ szám
 *
 * -1: Rossz a kapott érték hossza (csak 9 /elválasztás nélkül/
 *     vagy 11 /elválasztással/ karakter lehet)
 *
 * -2: A kapott érték nem csak számjegyet tartalmaz (kivéve: elválasztás)
 *
 * -3: A kapott érték CDV hibás
 */
export const CheckTAJ = (cTAJ: string): any => {
  cTAJ = cTAJ.replace(/ /g, '');
  if (cTAJ.length !== 9) { return -1; }
  const y: number = +cTAJ;
  if (!y) { return -2; }
  let nCDV = 0;
  for (let i = 0; i < 8; i++) {
    nCDV += +(cTAJ[i].toString()) * (3 + 4 * (i % 2));
  }
  if (+(cTAJ[8].toString()) !== (nCDV % 10)) { return -3; }

  return 0;
};

/**
 * Blob-ot átkonvertál base64 string-é
 */
export const convertBlobToBase64 = (blob: Blob) => new Promise((resolve, reject) => {
  const reader = new FileReader;
  reader.onerror = reject;
  reader.onload = () => {
      resolve(reader.result.toString());
  };
  reader.readAsDataURL(blob);
});

/**
 * Base64 stringet átkonvertál Blob-ra
 */
 export function convertBase64toBlob(base64Data: string, contentType: string): Blob {
  contentType = contentType || '';
  const base64String: string = base64Data.replace("data:" + contentType + ";base64,", ""); 
  const sliceSize = 1024;
  const byteCharacters = atob(base64String);
  //const byteCharacters = base64Data;
  const bytesLength = byteCharacters.length;
  const slicesCount = Math.ceil(bytesLength / sliceSize);
  const byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    const begin = sliceIndex * sliceSize;
    const end = Math.min(begin + sliceSize, bytesLength);

    const bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
      bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

/**
 * Év alapján az évszak visszaadása
 */
export function getSeason(date: Date ): string {
  let month = date.getMonth() + 1 ;
  if (month == 12 || month < 3) return "Winter";
  if (month > 2 && month < 6) return "Spring";
  if (month > 5 && month < 9) return "Summer";
  return "Autumn";
}

/**
 * Visszaadaja a lokális időzónát
 */
export function getTimeZoneOffset(): string {
  const tzOffset = new Date().toTimeString().match(/([\+-]\d{4})/)[0];
  return [tzOffset.slice(0, 3), ':', tzOffset.slice(3)].join('');
}

/**
 * Két dátum különbségét adja vissza
 * formátum: seconds
 */
export const GetDateDiff = (start: Date, end: Date): any => {
  var diff = end.getTime() - start.getTime();
  var days = Math.floor(diff / (60 * 60 * 24 * 1000));
  var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
  var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
  var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));

  return seconds;
};

/**
 * dx-date-box segéd funkció, ami a dátum-ban lévő szünet-et és a végén lévő pont hiányát
 * korrigálja, és jó dátumként reprezentálja
 */
export const CorrectDate = (dateInstance: any): any => {
  const t = dateInstance.option('text');
  if (t[4] === '.') {
    const e = t.split('.').map(item => item.trim());
     let newDate = '';
    if (e.length >= 3 && e[0].length === 4) {
      newDate = e[0] + '/ ' + e[1] + '/ ' + e[2] + '/';
      const timestamp = Date.parse(newDate);

      if (isNaN(timestamp) === false) {
        if (newDate.length > 10) {
          dateInstance.reset();
          dateInstance.option('value', new Date(newDate));
          dateInstance.repaint();
        }
      }
    }
  }
};

const defaultAlphabet = 'aāáǎàâãąbcćčçdďðeēéěèêëęėfgğhıiīíǐìîïįjklłmnńňñoōóǒòôpqrřsśšştťuųūúǔùůûüǖǘǚǜvwxyýzźżžþæœøõåäö';

/**
 * Szöveg sorbarendezése ékezetet karakterek figyelembevételével.
 */
export function internationalSort(propList: any, alphabet: string) {
  alphabet = alphabet || defaultAlphabet;

  return (a: any, b: any) => {
    let i = -1;
    let aIndex: number;
    let bIndex: number;

    if (propList) {
      a = groperty(a, propList);
      b = groperty(b, propList);
    }

    a = String(a || '').toLowerCase();
    b = String(b || '').toLowerCase();

    do {
      i++;
      if (!a[i] && b[i]) {
        return -1;
      }
      if (a[i] && !b[i]) {
        return 1;
      }
      aIndex = alphabet.indexOf(a[i]);
      bIndex = alphabet.indexOf(b[i]);
      if (aIndex === -1 || bIndex === -1) {
        if (a[i] < b[i]) {
          return -1;
        }
        if (a[i] > b[i]) {
          return 1;
        }
      }
    } while (aIndex === bIndex && i < a.length && i < b.length);

    return aIndex - bIndex;
  };
}

function groperty(obj: any, propList: any) {
  if (typeof obj !== 'object') {
    return obj;
  }

  if (Object.prototype.toString.call(propList) !== '[object Array]') {
    propList = propList.replace(/^\./, '').replace(/\.$/, '').split('.');
  }

  propList.forEach(function walkThroughObj(propName) {
    obj = obj[propName] ? obj[propName] : obj;
  });

  return obj;
}

