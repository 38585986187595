    
<div id="memory-disorders" *ngIf="code=='PerceivedMemorySymptoms'">
<dx-data-grid id="gridContainer"
    [dataSource]="dataSource"
    [remoteOperations]="false"
    [rowAlternationEnabled]="true"
    [columnHidingEnabled]="true"
    [showBorders]="true"
    > 
    <dxo-editing 
    mode="row"
    [allowUpdating]="true"
    [allowDeleting]="true"
    [allowAdding]="true"
    [useIcons]="true">
   </dxo-editing>
       <dxo-filter-row 
            [visible]="false">
       </dxo-filter-row>

    <!-- <dxi-column dataField="id" dataType="string"></dxi-column> -->
    <!-- <dxi-column dataField="code" [visible]="true"></dxi-column>
    <dxi-column dataField="patientId" [visible]="true"></dxi-column> -->
    <dxi-column dataField="codeId" caption="{{ 'Project.MemoryDisorders' | translate:locale.language }}" dataType="string">
        <dxo-lookup [dataSource]="codesSource" valueExpr="id" displayExpr="descriptionTranslated"></dxo-lookup>
       <dxi-validation-rule 
            type="required">
        </dxi-validation-rule>
     </dxi-column>
     <dxi-column dataField="startDate" caption= "{{ 'Project.RecognitionDate' | translate:locale.language }}" dataType="date">
        <dxi-validation-rule 
            type="required">
        </dxi-validation-rule>
    </dxi-column>
     <dxi-column dataField="frequencyId" caption="{{ 'Project.FrequencyOfSymptoms' | translate:locale.language }}" dataType="string">
        <dxo-lookup [dataSource]="frequencySource" valueExpr="id" displayExpr="descriptionTranslated"></dxo-lookup>
       <dxi-validation-rule 
            type="required">
        </dxi-validation-rule>
     </dxi-column>
     <dxi-column dataField="comment" caption= "{{ 'Core.EntityNote' | translate:locale.language }}" dataType="string">
     </dxi-column>

</dx-data-grid>
</div>

<div id="memory-disorders" *ngIf="code=='KnownDiseases'">
    <dx-data-grid id="gridContainer"
        [dataSource]="dataSource"
        [masterDetail]="{ enabled: true, template: 'drugs' }"
        [remoteOperations]="false"
        [rowAlternationEnabled]="true"
        [columnHidingEnabled]="true"
        [showBorders]="true"
        > 
        <dxo-editing 
        mode="row"
        [allowUpdating]="true"
        [allowDeleting]="true"
        [allowAdding]="true"
        [useIcons]="true">
       </dxo-editing>
           <dxo-filter-row 
                [visible]="false">
           </dxo-filter-row>
    
         <dxi-column dataField="codeId" caption="{{ 'Project.KnownDiseases' | translate:locale.language }}" dataType="string">
            <dxo-lookup [dataSource]="codesSource" valueExpr="id" displayExpr="descriptionTranslated"></dxo-lookup>
           <dxi-validation-rule 
                type="required">
            </dxi-validation-rule>
         </dxi-column>
         <dxi-column dataField="startDate" caption= "{{ 'Project.RecognitionDate' | translate:locale.language }}" dataType="date">
            <dxi-validation-rule 
                type="required">
            </dxi-validation-rule>
        </dxi-column>
         <dxi-column dataField="comment" caption= "{{ 'Core.EntityNote' | translate:locale.language }}" dataType="string">
         </dxi-column>

    <div *dxTemplate="let drug of 'drugs'">
        <!-- <div class="master-detail-caption">{{code.data.codeName}}</div> -->
        <dx-data-grid 
            [dataSource]="getSubDataSource(drug.key)"
            [showBorders]="true"
            [columnHidingEnabled]="true"
            [columnAutoWidth]="true"
            >

        <dxo-editing 
            mode="row"
            [allowUpdating]="true"
            [allowDeleting]="true"
            [allowAdding]="true"
            [useIcons]="true">
        </dxo-editing>
    
       <dxo-filter-row 
            [visible]="false">
       </dxo-filter-row>
    
         <dxi-column dataField="codeId" caption="{{ 'Project.MedicationUsed' | translate:locale.language }}" dataType="string">
            <dxo-lookup [dataSource]="subCodesSource" valueExpr="id" displayExpr="descriptionTranslated"></dxo-lookup>
           <dxi-validation-rule 
                type="required">
            </dxi-validation-rule>
         </dxi-column>
         <dxi-column dataField="startDate" caption= "{{ 'Project.DrogUsageStart' | translate:locale.language }}" dataType="date">
            <dxi-validation-rule 
                type="required">
            </dxi-validation-rule>
        </dxi-column>
         <dxi-column dataField="comment" caption= "{{ 'Core.EntityNote' | translate:locale.language }}" dataType="string">
         </dxi-column>
      </dx-data-grid>       
    </div>    
   
    </dx-data-grid>
    </div>