import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {
    private ErrorMessageSource = new BehaviorSubject<string>('');
    currentErrorMessage = this.ErrorMessageSource.asObservable();

    constructor() {
    }

    changeErrorMessage(message: string) {
        this.ErrorMessageSource.next(message);
        setTimeout(() => {
            this.ErrorMessageSource.next('');
        }, 6000);
    }
}
