import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { loadMessages, locale } from 'devextreme/localization';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AllocatedTask, BloodPressure, CancelTreatmentModel, LoginUser, SendMessageModel } from 'src/app/services/webapiclient.service';
import notify from 'devextreme/ui/notify';
import { ConfirmDialogService } from 'src/app/confirm-dialog/confirm-dialog-service';
import { Subscription } from 'rxjs';
import { PatientService } from 'src/app/module-patient/patient.service';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { Role, RolePUD } from 'src/app/models/role';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit, OnDestroy {

  @Input() patientId: string;
  @ViewChild('newNote', { static: true }) newNote: TemplateRef<any>;
  @ViewChild('editNote', { static: true }) editNote: TemplateRef<any>;

  baseUri = `${environment.apiUrl}`;
  rolePUD: boolean = false;

  modalRef: BsModalRef;
  message: SendMessageModel = {} as SendMessageModel;

  notesSource: any;

  stylingMode = "filled"
  currentUser: LoginUser;
  error: any;
  dateFormat: string;
  loading = false;
  editItem: any;
  addMode: any;
  selectedIndex: any;

  mailContent: string;
  isPublic: boolean;
  viewLayout: boolean = false;

  uploadVisible = false;
  showUploadedDocument = false;
  uploadFileId: string;
  uploadFileURL: string;
  files: any[] = [];
  isRead: boolean = false;


  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private patientService: PatientService,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    this.uploadFile = this.uploadFile.bind(this);

    loadMessages({
      'hu': JSON.parse(huMessages)
    });

  }


  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });

    this.currentUser = this.authenticationService.currentUserValue;

    if (this.currentUser && this.currentUser.role) {
      this.rolePUD = RolePUD.includes(this.currentUser.role as Role)
    }

    if(!this.rolePUD){
      //Nincs jogosultsága, átirányítjuk az előfizetési csomagokhoz
      this.router.navigate(['subscription/packages']);
    }

    if (this.currentUser) {
      if(this.patientId == undefined){
        //páciens oldalról hívjuk
        this.patientId = this.currentUser.id;
        this.viewLayout = true;
      }
      this.loadNotesSource();
    }
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  //Jegyzetek beolvasása
  loadNotesSource() {
    let doctorId = null;
    if(this.currentUser.id != this.patientId){
      doctorId = this.currentUser.id;
    }
    this.patientService.getNotes(doctorId, this.patientId).then(
      result => {
        this.notesSource = <SendMessageModel[]>result;
        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
    });

  }

  clickNewNote() {
    this.addMode = true;

    this.isPublic = false;
    this.mailContent = "";

    this.modalRef = this.modalService.show(this.newNote, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  clickEdit(index) {
    this.addMode = false;
    this.selectedIndex = index;
    this.mailContent = this.notesSource[this.selectedIndex].mailContent;
    this.isPublic = this.notesSource[this.selectedIndex].isPublic;

    this.modalRef = this.modalService.show(this.editNote, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  clickDelete(index) {
    const that = this;
    this.confirmDialogService.confirmThis(this.translation.translate('Core.ConfirmDeleting'), function () {
      if (that.notesSource[index].id) {
        that.patientService.deleteNote(that.patientId, that.notesSource[index].id).then(
          result => {
            if (result == "OK") {
              that.notesSource.splice(index, 1);
            } else {
              that.error = result;
            }
          }
        ).catch(err => {
          that.error = err;
        });
      }
    }, function () {
      //alert("No clicked");  
    })
  }

  //Dokumentum csatolása a felmérési eredményhez
  showUploadPopUp(index) {
    this.selectedIndex = index;
    this.uploadFileId = 'NOT_' + this.patientId + '_' + this.notesSource[index].id;
    this.patientService.getUploadedFileURL(this.uploadFileId).then(
      result => {
        if (result != "NO") {
          this.uploadFileURL = this.baseUri + <string>result;
          if(this.viewLayout){
            this.uploadVisible = false;
            this.showUploadedDocument = false;
            this.showDocument();
          }else{
            this.isRead = this.notesSource[index].isRead;
            this.uploadVisible = false;
            this.showUploadedDocument = true;
          }
        } else {
          this.files = [];
          this.uploadVisible = true;
          this.showUploadedDocument = false;
        }
      }
    ).catch(err => {
      this.error = err;
    });
  }

  uploadFile(file, uploadInfoCallback) {
    let formData = new FormData();
    formData.append("file", file, file.name);
    uploadInfoCallback(100);
    this.patientService.uploadFile(formData, this.uploadFileId.toString()).then(
      result => {
        this.notesSource[this.selectedIndex].uploadedFile = true;
      }
    ).catch(err => {
      this.notesSource[this.selectedIndex].uploadedFile = false;
      this.error = err;
    });

    // return this.kbService
    //   .uploadFile(formData)
  }

  showDocument() {
    this.showUploadedDocument = false;
    window.open(this.uploadFileURL, "_blank", 'noreferrer');
  }

  deleteDocument() {
    const that = this;
    this.showUploadedDocument = false;
    this.confirmDialogService.confirmThis(this.translation.translate('Core.ConfirmDeleting'), function () {
      that.patientService.deleteUploadFile(that.uploadFileId).then(
        result => {
          that.showUploadedDocument = false;
          that.notesSource[that.selectedIndex].uploadedFile = false;
        }
      ).catch(err => {
        that.notesSource[that.selectedIndex].uploadedFile = true;
        that.error = err;
      });
    }, function () {
      that.showUploadedDocument = true;
    })
  }

  onShown() {
    setTimeout(() => {
      this.loading = false;
    }, 15000);
  }

  closeModal() {
    this.modalRef.hide();
    if (this.addMode) {
      //Új bevitelt szakítunk meg
      this.addMode = false;
    }
  }

  onFormSubmit = function (e, index) {
    this.error = "";
    this.loading = true;
    //Ha csak dátumot kérünk be a devextreme date-box-nál, akkor 0 órát ad vissza
    //hozzá kell adni 1 órát, hogy ne az előző napra konvertája a JSON.stringify
    if (this.editItem.startDate) this.editItem.startDate.setHours(6);
    if (!this.addMode) {
      //módosítás
      this.dataSource[index] = this.simpleClone(this.editItem);

      this.patientService.updateDetail(this.dataSource[index].id, JSON.stringify(this.dataSource[index])).then(
        result => {
          if (result == "OK") {
            this.loadDataSource();
            notify({
              message: "Sikeres mentés!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.closeModal();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    } else {
      //új
      this.patientService.insertTask(JSON.stringify(this.editItem)).then(
        result => {
          if (result == "OK") {
            notify({
              message: "Sikeres mentés!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.addMode = false;
            this.closeModal();
            this.loadDataSource();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    }
    //let result: any;

    e.preventDefault();
  }

  sendMessage() {
    if (this.mailContent === undefined || this.mailContent === "") return;
    this.loading = true;

    if(this.addMode){
      this.message.id = 0;
    }else{
      this.message.id = this.notesSource[this.selectedIndex].id;
    }

    this.message.doctorId = this.currentUser.id;
    this.message.patientId = this.patientId;
    this.message.mailContent = this.mailContent;
    this.message.isPublic = this.isPublic;

    this.patientService.sendMessage(JSON.stringify(this.message)).then(
      result => {
        if (result === 'OK') {
        }
        this.loadNotesSource();
        this.closeModal();
        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });

  }
}