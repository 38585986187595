import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { loadMessages, locale } from 'devextreme/localization';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoginUser, TaskEvaluation } from 'src/app/services/webapiclient.service';
import { Subscription } from 'rxjs';
import { PatientService } from 'src/app/module-patient/patient.service';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FreehandDrawingDirective } from 'src/app/drawing/freehand-drawing.directive';
import { GameResult, GameResultDetails, Img2Post } from 'src/app/games/shared/gameResult';
import { GamesReportService } from 'src/app/statistics/services/games-report.service';
import { GameService } from 'src/app/games/services/game.service';
import { convertBase64toBlob } from 'src/app/shared/utils';
import { DomSanitizer } from '@angular/platform-browser';
import { TestEvaluation, TestEvaluations } from 'src/app/games/shared/constants';
import { CodesFunc, CodeWarehouseEditModel } from 'src/app/services/codes.service';
import { environment } from 'src/environments/environment';
import * as htmlToImage from 'html-to-image';
import { StringResult } from 'src/app/module-thryve/thryve.service';


@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: ['./evaluation.component.scss']
})
export class EvaluationComponent implements OnInit, OnDestroy {

  @Output() closeViewEvent = new EventEmitter<boolean>();

  @Input() patientId: string;
  @Input() taskId: number;
  @Input() onlyView: boolean = false;
  @ViewChild('clockTestEvaluation', { static: true }) clockTestEvaluation: TemplateRef<any>;

  // Freehand Drawing Area
  @ViewChild(FreehandDrawingDirective, { static: false }) _freehand: FreehandDrawingDirective;

  assetsVersion = `${environment.assetsVersion}`;
  uploadUrl = `${environment.uploadUrl}`;

  uploadFileId: string;
  uploaded: boolean = true;;
  modalRef: BsModalRef;
  dataSource: any;
  stylingMode = "filled"
  currentUser: LoginUser;
  error: any;
  dateFormat: string;
  minDate: Date = new Date();
  loading = false;
  savedx: boolean = true;
  editItem: any;
  addMode: any;
  selectedIndex: any;
  loadedIndex: number;
  drawing: boolean = false;
  dataSaved: boolean;

  seasonCodes: CodeWarehouseEditModel[] = {} as CodeWarehouseEditModel[];
  monthCodes: CodeWarehouseEditModel[] = {} as CodeWarehouseEditModel[];
  dayNameCodes: CodeWarehouseEditModel[] = {} as CodeWarehouseEditModel[];


  //melyik képeket választottuk ki (sources)
  src: string[] = [];

  testEvaluations: TestEvaluation[];

  imagePath: string[] = ['assets/images/mood-form/face-3.png',
    'assets/images/mood-form/face-2.png',
    'assets/images/mood-form/face-1.png',
    'assets/images/mood-form/face0.png',
    'assets/images/mood-form/face1.png',
    'assets/images/mood-form/face2.png',
    'assets/images/mood-form/face3.png'];

  blobFile1: Blob;
  blobUrl1: string;
  audioUrl1: string;

  blobFile2: Blob;
  blobUrl2: string;
  audioUrl2: string;

  private translSubs$: Subscription;

  /**
    * Itt tároljuk a játék eredményét.
    */
  wrResult: any;

  wrResultList: GameResultDetails[] = [];

  gameResult: GameResult = <GameResult>{};
  gameResultList: TaskEvaluation[] = [];

  gameResultDetailsList: GameResultDetails[] = [];
  gameResultDetails: GameResultDetails = <GameResultDetails>{};

  private _num: number = 0;
  myInterval: any; // A setInterval változója.
  showInterval: any;

  evaluation: boolean = false;
  evaluationStep: number = -1;
  bvmtRound: number = 0;

  summaryLayout: boolean = false;

  showBack: boolean;
  showNext: boolean;

  viewLayout: boolean = false;

  bgSource: string;
  evaluationGuide: string;

  img2: Img2Post = <Img2Post>{};

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private patientService: PatientService,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private router: Router,
    public gameService: GameService,
    private gamesReportService: GamesReportService,
    private domSanitizer: DomSanitizer,
    private codesFunc: CodesFunc

  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });
    //kódok feltöltése a combokhoz
    codesFunc.getCodesData('Season').then(
      result => {
        this.seasonCodes = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
    codesFunc.getCodesData('Month').then(
      result => {
        this.monthCodes = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
    codesFunc.getCodesData('DayName').then(
      result => {
        this.dayNameCodes = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
  }


  ngOnInit(): void {
    //this._freehand.interactive = false;
    this.testEvaluations = TestEvaluations;
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
        this.evaluationGuide = this.translation.translate('Task.EvaluationGuide');
      });

    this.currentUser = this.authenticationService.currentUserValue;

    if (this.currentUser) {
      this.evaluation = true;
      this.loadTaskSheet();
      if (this.onlyView) {
        this.viewLayout = true;
      } else {
        this.viewLayout = false;
      }
    }
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  sanitize(url: string) {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }

  //Összesített adatok betöltése (tesztlap)
  loadTaskSheet() {
    this.loading = true;
    this.patientService.getTaskSheetList(this.patientId, this.taskId).then(
      result => {
        this.dataSource = <TaskEvaluation[]>result;
        this.loadTaskResults();
      }
    ).catch(err => {
      this.error = err;
    });
  }

  //Tesztlaphoz tartozó feladatok eredményei
  loadTaskResults() {
    this.gameService.getTaskResults(this.taskId).subscribe((data) => {
      this.gameResultList.push(...data);
      this.loadedIndex = 0;
      this.loadResultDetails();
    });
  }

  //Feladatok részletes eredményeinek betöltése
  loadResultDetails() {
    this.gamesReportService.getGameResultDetails(this.gameResultList[this.loadedIndex].id).subscribe((data) => {
      this.wrResultList.push(data);
      this.gameResultDetailsList.push(data);
      if (this.evaluationStep < this.wrResultList.length - 1) {
        this.showNext = true;
      } else {
        this.showNext = false;
      }
      if (this.loadedIndex == 0) {
        //Betöltötük az első feladat részleteit, meg lehet jeleníteni
        this.showTaskSheet();
      }
      this.loadedIndex++;
      if (this.loadedIndex < this.gameResultList.length) {
        this.loadResultDetails();
      }
    });
  }

  gotoEvaluation(index: number) {
    this.evaluation = true;
    this.summaryLayout = false;
    this.evaluationStep = index - 1;
    this.goNextEvaluation();
  }

  showTaskSheet() {
    this.loading = true;
    this.evaluationStep = -1;
    this.gameResult = this.dataSource[0];
    this.gameResult.result = this.dataSource[0].resultNumeric;

    this.evaluation = true;
    this.goNextEvaluation();
  }

  goNextEvaluation() {
    this.evaluationStep = this.evaluationStep + 1;

    if (this.evaluationStep > 0) {
      this.showBack = true;
    } else {
      this.showBack = false;
    }

    if (this.evaluationStep < this.wrResultList.length - 1) {
      this.showNext = true;
    } else {
      this.showNext = false;
    }

    if (this.evaluationStep < this.wrResultList.length) {
      this.wrResult = this.wrResultList[this.evaluationStep];
      if (this.wrResult.gameType == 'MMSE') {
        this.bgSource = 'assets/images/másoló-mmse-bg.png?v=' + this.assetsVersion;
      } else {
        this.bgSource = 'assets/images/másoló-bg.png?v=' + this.assetsVersion;
      }

      this.gameResultDetails = this.gameResultDetailsList[this.evaluationStep];
      if (this.gameResultDetails.subType == "") this.gameResultDetails.subType = this.gameResultDetails.gameType;

      //MOCA
      if (this.wrResult.numbers1 && this.wrResult.numbers1.length == 3) {
        this.src[0] = "../../../assets/images/picture-recognition/" + this.wrResult.numbers1[0] + ".jpg";
        this.src[1] = "../../../assets/images/picture-recognition/" + this.wrResult.numbers1[1] + ".jpg";
        this.src[2] = "../../../assets/images/picture-recognition/" + this.wrResult.numbers1[2] + ".jpg";
      }
      //MMSE
      if (this.wrResult.numbers1 && this.wrResult.numbers1.length == 2) {
        this.src[0] = "../../../assets/images/picture-recognition/" + this.wrResult.numbers1[0] + ".jpg?v=" + this.assetsVersion;
        this.src[1] = "../../../assets/images/picture-recognition/" + this.wrResult.numbers1[1] + ".jpg?v=" + this.assetsVersion;
      }

      this.blobFile1 = null;
      this.blobUrl1 = null;
      this.audioUrl1 = null;

      this.blobFile2 = null;
      this.blobUrl2 = null;
      this.audioUrl2 = null;

      //Régi audio-k amik még a DB-ben lettek tárolva
      if (this.wrResult.audio_sentence1 && this.wrResult.audio_sentence1.substring(0, 4) == 'data') {
        //van audio1 hangfile
        this.blobFile1 = convertBase64toBlob(this.wrResult.audio_sentence1, "audio/wav");
        this.blobUrl1 = URL.createObjectURL(this.blobFile1);
      }

      if (this.wrResult.audio_sentence2 && this.wrResult.audio_sentence2.substring(0, 4) == 'data') {
        //van audio2 hangfile
        this.blobFile2 = convertBase64toBlob(this.wrResult.audio_sentence2, "audio/wav");
        this.blobUrl2 = URL.createObjectURL(this.blobFile2);
      }

      //Új audio-k, ezek már file rendszerben vannak tárolva
      if (this.wrResult.audio_sentence1 && this.wrResult.audio_sentence1.substring(0, 4) != 'data') {
        //van audio1 hangfile URL
        if (this.wrResult.audio_sentence1.substring(0, 3) == 'FLU') {
          //Fluencia hangfile
          this.audioUrl1 = this.uploadUrl + 'FLU/' + this.wrResult.audio_sentence1;
        }
        if (this.wrResult.audio_sentence1.substring(0, 3) == 'REP') {
          //Repeat sentences hangfile
          this.audioUrl1 = this.uploadUrl + 'REP/' + this.wrResult.audio_sentence1;
        }
      }
      if (this.wrResult.audio_sentence2 && this.wrResult.audio_sentence2.substring(0, 4) != 'data') {
        if (this.wrResult.audio_sentence2.substring(0, 3) == 'REP') {
          //Repeat sentences hangfile
          this.audioUrl2 = this.uploadUrl + 'REP/' + this.wrResult.audio_sentence2;
        }
      }

      if (this.wrResult.bvmts && this.wrResult.bvmts[0]) {
        //BVMT-R rajzolások
        this.wrResult.freehandX = this.wrResult.bvmts[0].freehandX;
        this.wrResult.freehandY = this.wrResult.bvmts[0].freehandY;
      }

      if (this.wrResult.freehandX) {
        //Kell rajzolni

        this.showInterval = setInterval(() => {
          if (this._freehand && this._freehand.readyToDraw && this._freehand.backgroundReady) {
            this.showDrawing();
            clearInterval(this.showInterval);
            this.loading = false;
          }
        }, 25); // Egy tized másodpercenként számolunk vissza.    
      } else {
        this.loading = false;
      }

    } else {
      this.evaluation = false;
    }
  }

  setBvmtRound(round) {
    this.bvmtRound = round;
    this._freehand.clear();
    if (this.wrResult.bvmts && this.wrResult.bvmts[this.bvmtRound]) {
      //BVMT-R rajzolások
      this.wrResult.freehandX = this.wrResult.bvmts[this.bvmtRound].freehandX;
      this.wrResult.freehandY = this.wrResult.bvmts[this.bvmtRound].freehandY;
      this.showDrawing();
    }
  }

  goPreviousTask() {
    if (this.savedx && this.uploaded) {
      this.savedx = false;
      //this.uploaded = false;

      this.saveData();
      if (this.evaluationStep == 0) return;
      this.evaluationStep = this.evaluationStep - 2;
      this.goNextEvaluation();
    }
  }

  goNextTask() {
    if (this.savedx && this.uploaded) {
      this.savedx = false;
      //this.uploaded = false;

      this.saveData();
      if (!(this.evaluationStep + 1 < this.wrResultList.length)) return;
      this.goNextEvaluation();
    }
  }

  getTaskBg(gameType: string): string {
    let result = '';
    let te = this.testEvaluations.find(f => f.gameType == gameType);
    if (te) {
      result = te.evaluationCategory.bgClass;
    }
    return result;
  }

  getTaskClass(gameType: string): string {
    let result = '';
    let te = this.testEvaluations.find(f => f.gameType == gameType);
    if (te) {
      result = te.evaluationCategory.class;
    }
    return result;
  }

  onShown() {
    setTimeout(() => {
      this.loading = false;
    }, 15000);
  }

  showSummary() {
    this.evaluation = false;
    this.summaryLayout = true;
  }

  showEvaluation() {
    this.evaluation = true;
    this.summaryLayout = false;
  }

  setTaskToEvaluated() {
    this.loading = true;
    this.dataSaved = false;
    this.saveData();

    //Megvárjuk, amíg elmentődik az adat
    // while (!this.dataSaved) {
    // } 

    let taskId = this.dataSource[0].taskId;
    this.patientService.setTaskToEvaluated(this.patientId, taskId, this.currentUser.providerId).then(
      result => {
        this.closeModal();
        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });
  }

  closeModal() {
    this.saveData();
    this.evaluation = false;
    this.closeViewEvent.emit(true);
  }

  //ezt most nem használjuk, helyette van a saveData
  onFormSubmit(e, index) {

  }

  //Itt csak a teszt png. képének elérését mentjük
  //Azon teszteknél használjuk, ahol az eredményt nem szabad felülírni
  saveImg2() {

    this.img2.id = this.gameResultDetails.id;
    this.img2.patientId = this.patientId;
    if (this.uploadFileId != null) {
      this.img2.img2 = 'EVA/' + this.uploadFileId + '.png';
    } else {
      this.img2.img2 = null;
    }

    // Elküldjük a szerverre az adatokat.
    this.gameService.saveImg2(this.img2).subscribe(
      () => {
        this.uploaded = true;
        this.savedx = true;
      }
    );
  }

  saveData() {

    //Lementjük a tesztet .pmg képként is a feladatlap riporthoz
    //this.uploadTestImage();

    let tmpResult = 0;
    //Ezeknél nincs mentés
    if (this.gameResultDetails.subType == "WordRecall" ||
      this.gameResultDetails.subType == "WordRecallAc" ||
      this.gameResultDetails.subType == "WordListRecallAc" ||
      this.gameResultDetails.subType == "TimeOrientation" ||
      this.gameResultDetails.subType == "SDMT") {
      //this.saveImg2();
      this.dataSaved = true;
      this.savedx = true;

      return;
    }


    if (this.gameResultDetails.gameType != 'MMSE' && this.gameResultDetails.subType == 'CountDownTest') {
      //this.saveImg2();
      this.dataSaved = true;
      this.savedx = true;

      return;
    }

    // A szerver számára összeállítjuk az adatokat.
    //sendData.resultCodeId = this.resultCodeId;

    if (!(this.gameResultDetails.gameType == 'MMSE' && this.gameResultDetails.subType == 'CountDownTest')) {
      //Az MMSE visszaszámolás tesztnél a result1-ben tároljuk az eltalált számokat, így itt nem szabad felülírni
      this.wrResult.result1 = this.wrResult.result1 ? 1 : 0;
    }
    this.wrResult.result2 = this.wrResult.result2 ? 1 : 0;
    this.wrResult.result3 = this.wrResult.result3 ? 1 : 0;
    this.wrResult.result4 = this.wrResult.result4 ? 1 : 0;
    this.wrResult.result5 = this.wrResult.result5 ? 1 : 0;
    this.wrResult.result6 = this.wrResult.result6 ? 1 : 0;
    this.wrResult.result7 = this.wrResult.result7 ? 1 : 0;
    this.wrResult.result8 = this.wrResult.result8 ? 1 : 0;

    if (this.gameResultDetails.subType == 'DelayedRecall' &&
      this.wrResult.recallRounds &&
      this.wrResult.recallRounds[this.wrResult.recallRounds.length - 1].roundResult == 1) {
      //Késleltetett felidézés
      //Itt csak a segítség nélküli felidézésre adunk pontot (roundResult=1)
      let c = this.wrResult.recallRounds[this.wrResult.recallRounds.length - 1].guessWords.filter(f => f.correct);
      tmpResult = c.length;
    } else if (this.gameResultDetails.subType == 'Memorizing' && this.wrResult.recallRounds) {
      //Megjegyző emlékezés
      //Első próbálkozásra adott pontok
      let c = this.wrResult.recallRounds[0].guessWords.filter(f => f.correct);
      tmpResult = c.length;
      if (this.wrResult.recallRounds[this.wrResult.recallRounds.length - 1].roundResult == 1) {
        //Segítség nélküli felidézésre adott pontok
        c = this.wrResult.recallRounds[this.wrResult.recallRounds.length - 1].guessWords.filter(f => f.correct);
        tmpResult = tmpResult + c.length;
      }
    } else if (this.gameResultDetails.subType == 'NamingObjects' && this.wrResult.recallRounds) {
      //Tárgyak megnevezése
      let c = this.wrResult.recallRounds[0].guessWords.filter(f => !f.correct);
      let wa = c.length;

      if (wa < 3) {
        tmpResult = 0;
      } else if (wa < 6) {
        tmpResult = 1;
      } else if (wa < 9) {
        tmpResult = 2;
      } else if (wa < 12) {
        tmpResult = 3;
      } else if (wa < 15) {
        tmpResult = 4;
      } else {
        tmpResult = 5;
      }

    } else if (this.gameResultDetails.subType == 'Commands' ||
      this.gameResultDetails.subType == 'Construction' ||
      this.gameResultDetails.subType == 'TaskExecution') {
      //Parancsok
      tmpResult = 5 - (this.wrResult.result1 + this.wrResult.result2 + this.wrResult.result3 + this.wrResult.result4 + this.wrResult.result5);
    } else if (this.gameResultDetails.subType == 'OrientationAC') {
      //Orientáció (Adas-Cog)
      tmpResult = 8 - (this.wrResult.result1 + this.wrResult.result2 + this.wrResult.result3 + this.wrResult.result4 + this.wrResult.result5 + this.wrResult.result6 + this.wrResult.result7 + this.wrResult.result8);
    } else if (this.gameResultDetails.gameType == 'BVMT-R' || this.gameResultDetails.subType == 'BVMT-R') {
      //BVMT-R
      this.wrResult.bvmts.forEach(element => {
        element.bvmtResults.forEach(item => {
          if (item.shape) tmpResult++;
          if (item.position) tmpResult++;
        });
      });
    } else if (this.gameResultDetails.subType == 'SpeakingSkills' ||
      this.gameResultDetails.subType == 'SpeechUnderstanding' ||
      this.gameResultDetails.subType == 'WordFindingDifficulty' ||
      this.gameResultDetails.subType == 'RememberingInstructions'
    ) {
      //Beszédkészség, beszéd megértés
      tmpResult = this.wrResult.resultNumeric;
    }
    else {
      //Minden egyéb
      tmpResult = this.wrResult.result1 + this.wrResult.result2 + this.wrResult.result3 + this.wrResult.result4 + this.wrResult.result5 + this.wrResult.result6;
    }

    if (this.gameResultDetails.gameType == 'MMSE' &&
      this.gameResultDetails.subType == 'SpellBackwards') {
      //Betűzés visszafelé, megnézzük, hogy a visszaszámolás eredménye nagyobb-e
      //Ha igen, ennek az eredményét lenullázzuk
      let index = this.wrResultList.findIndex(x => x.subType === "CountDownTest");
      if (index != -1 && this.wrResultList[index].result1 >= tmpResult) {
        tmpResult = 0;
        if (index != -1 && this.wrResultList[index].resultNumeric == 0) {
          this.wrResultList[index].resultNumeric = this.wrResultList[index].result1;
          this.dataSource[0].resultNumeric += this.wrResultList[index].result1;
        }
      } else {
        if (index != -1 && this.wrResultList[index].resultNumeric != 0) {
          this.wrResultList[index].resultNumeric = 0;
          this.dataSource[0].resultNumeric -= this.wrResultList[index].result1;
        }
      }
    }

    if (this.gameResultDetails.gameType == 'MMSE' &&
      this.gameResultDetails.subType == 'CountDownTest' &&
      this.wrResult.result1 != this.wrResult.resultNumeric) {
      //visszaszámolás, megnézzük, hogy a Betűzés visszafelé eredménye nagyobb-e
      //Ha igen, ennek az eredményét lenullázzuk
      tmpResult = this.wrResult.result1;
      let index = this.wrResultList.findIndex(x => x.subType === "SpellBackwards");
      if (index != -1 && this.wrResultList[index].resultNumeric >= tmpResult) {
        tmpResult = 0;
      } else {
        //this.wrResult.resultNumeric = this.wrResult.result1;
      }
    }

    this.wrResult.evaluation = true;
    this.dataSource[0].resultNumeric += tmpResult - this.wrResult.resultNumeric;
    this.wrResult.resultNumeric = tmpResult;
    this.gameResult.result = tmpResult;
    this.gameResult.resultDetails = JSON.stringify(this.wrResult);
    this.gameResult.subType = this.wrResult.subType;
    this.gameResult.id = this.gameResultDetails.id;
    // if (this.uploadFileId != null) {
    //   this.gameResult.img2 = 'EVA/' + this.uploadFileId + '.png';
    // } else {
    //   this.gameResult.img2 = null;
    // }
    this.gameResult.img1 = this.gameResultList[this.evaluationStep].img1;
    this.gameResult.img2 = this.gameResultList[this.evaluationStep].img2;
    this.gameResult.img3 = this.gameResultList[this.evaluationStep].img3;


    // Elküldjük a szerverre az adatokat.
    this.gameService.send(this.gameResult).subscribe(
      () => {
        this.dataSaved = true;
        this.savedx = true;
      }
    );

  }

  uploadTestImage() {

    this.uploadFileId = null;
    var nodes = document.getElementsByClassName('png-canvas');
    var node: any = document.getElementById('png-canvas');
    if (node) {
      this.uploadFileId = 'EVA_' + this.currentUser.id + '_' + this.gameResultDetails.id.toString() + '_0';
      const that = this;
      const filter = (node: HTMLElement) => {
        const exclusionClasses = ['drawingContainer', 'secret-div'];
        return !exclusionClasses.some((classname) => node.classList?.contains(classname));
      }
      htmlToImage.toBlob(node)
        .then(function (blob) {
          that._uploadFile(blob, 'image/png', 'EVA.png', that.uploadFileId);
        });
    } else {
      this.uploaded = true;
    }

  }

  _uploadFile(data: any, type: string, filename: string, uploadFileId: string): any {
    const blob = new Blob([data], { type: type });
    const file = new File([blob], filename);

    const formData = new FormData();
    formData.append("file", file, file.name);
    this.gameService.uploadFile(formData, uploadFileId).then(
      result => {
        this.uploaded = true;
      }
    ).catch(err => {
    });
  }


  /**
  * Kirajzoljuk az órát
  */
  showDrawing() {

    let j: number;
    let m: number = this.wrResult.freehandX.length;
    for (j = 0; j < m; ++j) {
      let n: number = this.wrResult.freehandX[j].length;
      if (n < 3) {
        continue;
      }
      let i: number;
      this._freehand.beginStrokeAt(this.wrResult.freehandX[j][0], this.wrResult.freehandY[j][0], false);
      for (i = 1; i < n - 1; ++i) {
        this._freehand.updateStroke(this.wrResult.freehandX[j][i], this.wrResult.freehandY[j][i]);
      }
      this._freehand.endStrokeAt(this.wrResult.freehandX[j][n - 1], this.wrResult.freehandY[j][n - 1]);

    }

  }

  onSkillChanged(index) {
    this.wrResult.resultNumeric = index;
  }

  /**
  * Rajzolás folyamataát mutatjuk meg
  */
  async replayDrawing(index: number) {
    if (index == 0) {
      this.drawing = true;
      this._freehand.clear();
    }

    if (index >= this.wrResult.freehandX.length) {
      this.drawing = false;
      return;
    }

    this._num = 0;

    if (this.wrResult.freehandX[index].length < 3) {
      if (index <= this.wrResult.freehandX.length - 1) {
        this.replayDrawing(index + 1);
        return;
      } else {
        if (this.myInterval) {
          clearInterval(this.myInterval);
          return;
        }
      }
    }

    this._freehand.beginStrokeAt(this.wrResult.freehandX[index][0], this.wrResult.freehandY[index][0], false);

    this.myInterval = setInterval(() => {
      if (index <= this.wrResult.freehandX.length - 1) {
        this.updateDrawing(index);
      } else {
        return;
      }

    }, 15);

  }


  public updateDrawing(index: number): void {
    this._num++;
    if (this._num < this.wrResult.freehandX[index].length - 1) {
      this._freehand.updateStroke(this.wrResult.freehandX[index][this._num], this.wrResult.freehandY[index][this._num]);
    } else {
      this._freehand.endStrokeAt(this.wrResult.freehandX[index][this._num], this.wrResult.freehandY[index][this._num]);
      if (this.myInterval) {
        clearInterval(this.myInterval);
      }
      if (index <= this.wrResult.freehandX.length) {
        this.replayDrawing(index + 1);
      }
    }
  }

}

