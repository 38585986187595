import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
import { NotificationCategory, NotificationFunc, NotificationViewModel } from '../services/webapiclient.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private notificationFunc: NotificationFunc,
    private errorService: ErrorService
  ) { }

  async getAll(id: string, ts: string) {
    let notifications: NotificationViewModel[] = {} as NotificationViewModel[];
    return new Promise((resolve, reject) => {
        this.notificationFunc.getAll(id, ts).pipe(first()).subscribe(
            (p) => {
              notifications = p;
            },
            (e) => {
                this.errorService.changeErrorMessage(e.message);
                reject(e.message);
            },
            () => {
                resolve(notifications);
             }
        );
    });
  }
  async unsetNewMessages(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
        this.notificationFunc.unsetNewMessages(values).pipe(first()).subscribe(
          (p) => {
            result = p;
          },
          (e) => {
            this.errorService.changeErrorMessage(e);
            reject(e);
          },
          () => {
            if(result.body === "OK"){
              resolve("OK");
            }else{
              reject(result.body);
          }
        }
    );
    });
  }
  async deactivate(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
        this.notificationFunc.deactivate(values).pipe(first()).subscribe(
          (p) => {
            result = p;
          },
          (e) => {
            this.errorService.changeErrorMessage(e);
            reject(e);
          },
          () => {
            if(result.body === "OK"){
              resolve("OK");
            }else{
              reject(result.body);
          }
        }
    );
    });
  }
  async deactivateAll(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
        this.notificationFunc.deactivateAll(values).pipe(first()).subscribe(
          (p) => {
            result = p;
          },
          (e) => {
            this.errorService.changeErrorMessage(e);
            reject(e);
          },
          () => {
            if(result.body === "OK"){
              resolve("OK");
            }else{
              reject(result.body);
          }
        }
    );
    });
  }
  async getCategorySettings(userId: string) {
    let categories: NotificationCategory[] = {} as NotificationCategory[];
    return new Promise((resolve, reject) => {
        this.notificationFunc.getCategorySettings(userId).pipe(first()).subscribe(
            (p) => {

              categories = p;
            },
            (e) => {
                this.errorService.changeErrorMessage(e.message);
                reject(e.message );
            },
            () => {
                resolve(categories);
             }
        );
    });
  }

  async updateCategorySetting(id: string, values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
        this.notificationFunc.updateCategorySetting(id,values).pipe(first()).subscribe(
          (p) => {
            result = p;
          },
          (e) => {
            this.errorService.changeErrorMessage(e);
            reject(e);
          },
          () => {
            if(result.body === "OK"){
              resolve("OK");
            }else{
              reject(result.body);
          }
        }
    );
    });
  }
}
