import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CancelTreatmentModel, LoginUser, PatientDataViewModel } from 'src/app/services/webapiclient.service';
import { locale } from 'devextreme/localization';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import messagesEs from 'node_modules/devextreme/localization/messages/es.json';
import { loadMessages } from 'devextreme/localization';
import { ActivatedRoute, Router } from '@angular/router';
import { Role, RoleGU, RolePU, RolePUD, RoleU } from '../models/role';
import { PatientService } from '../module-patient/patient.service';
import { environment } from 'src/environments/environment';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog-service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {

  @ViewChild('deleteGpConnect', { static: true }) deleteGpConnect: TemplateRef<any>;

  modalRef: BsModalRef;
  currentUser: LoginUser;
  doctorUser: boolean = false;
  error = '';
  loading: boolean = false; dataSource: any;
  rolePU: boolean = false;
  rolePUD: boolean = false;
  roleGU: boolean = false;
  roleU: boolean = false;

  patientDataViewModel: PatientDataViewModel = {} as PatientDataViewModel;

  //Profil kép tárolása
  blobFile: Blob;
  blobUrl: string;
  pictureData: any;
  pictureExtension: string;
  uploadUrl = `${environment.uploadUrl}`;

  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private route: ActivatedRoute,
    private translation: L10nTranslationService,
    private modalService: BsModalService,
    private authenticationService: AuthenticationService,
    private patientService: PatientService,
    private confirmDialogService: ConfirmDialogService,
    private router: Router
  ) {

    const huMessages = JSON.stringify(messagesHu['hu']);
    const esMessages = JSON.stringify(messagesEs['es']);
    loadMessages({
      'hu': JSON.parse(huMessages),
      'es': JSON.parse(esMessages)
    });
    this.currentUser = this.authenticationService.currentUserValue;

  }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });
    if (this.currentUser && this.currentUser.role == Role.Doctor) {
      this.doctorUser = true;
    }
    this.roleGU = RoleGU.includes(this.currentUser.role as Role)
    this.rolePU = RolePU.includes(this.currentUser.role as Role)
    this.rolePUD = RolePUD.includes(this.currentUser.role as Role)
    this.roleU = RoleU.includes(this.currentUser.role as Role)
    this.getPatientData(this.currentUser.id);
  }
  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  getPatientData(id: string) {
    this.loading = true;
    this.patientService.getPatient(id).then(
      result => {
        this.patientDataViewModel = <PatientDataViewModel>result;
        this.blobUrl = this.uploadUrl + this.patientDataViewModel.gpAvatarName;
        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });
  }


  onSelect(item, pud: boolean = false) {
    if (this.rolePUD || !pud) {
      this.router.navigate([item]);
    } else {
      //Nincs jogosultsága, átirányítjuk az előfizetési csomagokhoz
      this.router.navigate(['subscription/packages']);
    }
  }

  cancelGpConnection(){
    this.modalRef = this.modalService.show(this.deleteGpConnect, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }  

  onCancelGpLink() {
    const that = this;
    this.confirmDialogService.confirmThis(this.translation.translate('Project.DeleteGpConfirm'), function () {
      that.onDeleteGpLink();
    }, function () {
      //that.showUploadedDocument = true;
    })
  }

  //Háziorvosi kapcsolat bontása
  onDeleteGpLink() {
    this.loading = true;
    let model = <CancelTreatmentModel>{};
    model.doctorId = this.patientDataViewModel.gpId;
    model.patientId = this.currentUser.id;
    //model.reason = this.cancellationReason;

    this.patientService.cancelGpConnection(JSON.stringify(model)).then(
      result => {
        if (result === 'OK') {
        }
        this.loading = false;
        this.router.navigate(['/home']);
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });

  }

  closeModal() {
    this.modalRef.hide();
  }

}
