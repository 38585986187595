<button class="btn btn-neutral google-login-btn me-2" type="reset"  (click)="loginWithGoogle()">
    <i class="bi bi-google"></i>
    Login with Google
</button>                        
<div *ngIf="failed" class="alert alert-danger" role="alert">
    <p><strong>Oops!</strong> Your google login failed.</p>
    <ul>
      <li>Error: {{error}}</li>
      <li>Description: {{errorDescription}}</li>
    </ul>
  </div>
