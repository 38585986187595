<!-- Megjelenítés (mérések) -->
<div class="container" *ngIf="!showPoolDevices">
  <div class="m-3">
    <div class="d-xl-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
          <div  class="general-icons-sm me-2">
              <img src="../../../assets/images/smart-set.svg">
          </div>
          <h1  class="display-6 m-0">{{ 'SmartSet.Measurement' | translate:locale.language }}</h1>
      </div>
      <div class="mt-2 mt-xl-0">
        <button type="button" class="btn btn-primary me-2" (click)="devices()">
          <i></i> {{ 'Thryve.Devices' | translate:locale.language }}
        </button>
        <button type="button" class="btn btn-success" (click)="clickNew()">
          <i class="bi bi-plus"></i> {{ 'SmartSet.AssignMeasurement' | translate:locale.language }}
        </button>
      </div>
  </div>
    <hr>
  </div>
  <app-thryve-data [inpPatientId]="patientId" [needRefresh]="needRefresh"></app-thryve-data>
</div>

<!-- Utoljára mért adatok -->
<ng-template #lastMeasurements>
  <div class="modal-header">
    <h5 class="modal-title">{{ 'MySignals.LastMeasurement' | translate:locale.language }}</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
    </button>
  </div>
  <div class="modal-body">
    <div class="m-3">
      <table class="table table-bordered text-center bg-white">
        <thead>
          <tr>
            <th style="width: 90px;"></th>
            <th style="width: 90px;">
              <dx-check-box [(value)]="selectAll" (onValueChanged)="clickSelectAll($event)"></dx-check-box>
            </th>
            <th>{{ 'Project.Type' | translate:locale.language }}</th>
            <th>{{ 'Core.Date' | translate:locale.language }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of lastMeasurement; index as i">
            <td class="text-start">
              <a (click)="clickDelete(item.id, i)" class="text-danger h5 mx-2 my-0 pointer"><i
                  class="bi bi-trash"></i></a>
            </td>
            <td>
              <dx-check-box [(value)]="item.selected"></dx-check-box>
            </td>
            <td>{{ 'SmartSet.' + item.code | translate:locale.language }}</td>
            <td>{{ item.startTime | date:'yyyy-MM-dd HH:mm'}}</td>
            <!-- <td>{{ item.dataSource | codeTranslate:smartDevices}}</td> -->

          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
  </div>
  <div class="modal-footer">
    <dx-button stylingMode="contained" (click)="clickSave()" text="{{ 'Core.EditSave' | translate:locale.language }}"
      type="default" useSubmitBehavior=true>
    </dx-button>

    <button type="button" (click)="refresh()" class="btn btn-success" data-bs-dismiss="modal">
      {{ 'Core.Refresh' | translate:locale.language }}
    </button>
    <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
      {{ 'Core.EditCancel' | translate:locale.language }}
    </button>
  </div>

</ng-template>

<!-- Pool eszközök -->
<div class="container" *ngIf="showPoolDevices">
  <div class="m-3">
    <div class="d-md-flex justify-content-between align-items-center">
      <h1 class="display-6 d-block">{{ 'Thryve.Devices' | translate:locale.language }}</h1>
      <div>
        <button type="button" class="btn btn-primary" (click)="backToMeasurements()">
          <i></i> {{ 'SmartSet.Measurement' | translate:locale.language }}
        </button>
      </div>
    </div>
    <hr>
  </div>
  <div class="m-3">
    <table class="table table-bordered text-center bg-white">
      <thead>
        <tr>
          <th>{{ 'Project.Type' | translate:locale.language }}</th>
          <th>{{ 'Thryve.IssueDate' | translate:locale.language }}</th>
          <th>{{ 'Thryve.TakeBack' | translate:locale.language }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of poolDevices; index as i">
          <td>{{item.deviceName}}</td>
          <td *ngIf="item.issueStart">{{item.issueStart | date:'yyyy-MM-dd HH:mm'}}</td>
          <td *ngIf="!item.issueStart">
            <dx-button (onClick)="issueDevice(item)" type="default" stylingMode="filled">{{
              'Thryve.Issue' | translate:locale.language }}</dx-button>
          </td>
          <td>
            <dx-button *ngIf="item.issueStart && !item.issueEnd" (onClick)="takeBackDevice(item)" type="success"
              stylingMode="contained">{{
              'Thryve.TakeBack' | translate:locale.language }}</dx-button>
            <div *ngIf="item.issueEnd">{{item.issueEnd | date:'yyyy-MM-dd HH:mm'}}</div>
          </td>

        </tr>
      </tbody>
    </table>
  </div>
  <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>

</div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
  [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" (onShown)="onShown()"
  (onHidden)="onHidden()">
</dx-load-panel>