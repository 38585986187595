import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import messagesEs from 'node_modules/devextreme/localization/messages/es.json';
import { loadMessages, locale } from 'devextreme/localization';
import notify from 'devextreme/ui/notify';
import { UnregisterViewModel } from '../services/webapiclient.service';
import { UnregisterService } from '../services/unregister.service';
import { AuthenticationService } from '../services/authentication.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

/**
 * Regisztráció megszüntetési kérelem.
 * Kérelem után még 2 hétig visszaállíthatja a regisztrációt, ha meggondolta magát.
 * Egyébként anonimizáljuk a felhasználó adatait
 */

@Component({
  selector: 'app-unregister',
  templateUrl: './unregister.component.html',
  styleUrls: ['./unregister.component.scss']
})
export class UnregisterComponent implements OnInit, OnDestroy {

  loadingVisible = false;
  password: string;
  model: any;
  error: string;
  stylingMode = "filled"
  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private unregisterService: UnregisterService
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    const esMessages = JSON.stringify(messagesEs['es']);
    loadMessages({
      'hu': JSON.parse(huMessages),
      'es': JSON.parse(esMessages),
    });

  }
  ngOnInit(): void {

    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  onShown() {
    setTimeout(() => {
      this.loadingVisible = false;
    }, 3000);
  }

  onHidden() {
    //this.employeeInfo = this.employee;
  }
  onFormSubmit = function (e) {
    this.error = "";
    this.loadingVisible = true;
    this.model = <UnregisterViewModel>{};
    this.model.password = this.password;
    this.model.statusMessage = "OK";

    this.unregisterService.unregister(JSON.stringify(this.model)).then(
      result => {
        if (result == "OK") {
          notify({
            message: this.translation.translate('Core.UnregisterRequestSaved'),
            position: {
              my: "center top",
              at: "center top"
            }
          }, "success", 3000);
          this.addMode = false;
          this.loadingVisible = false;
          this.authenticationService.logout();
          this.router.navigate(['/login']);
        } else {
          this.error = result;
          this.loadingVisible = false;
        }
      }
    ).catch(err => {
      this.error = err;
      this.loadingVisible = false;
    });

    e.preventDefault();
  }

}
