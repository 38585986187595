import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';

export interface SelectList {
	text: string;
	value: number;
	selected: boolean;
	disabled: boolean;
}

export interface CodesEditModel {
	Id: string;
	CodeName: string;
	ParentCodeName: string;
	DevRecord: boolean;
	CustomerCanModify: boolean;
	knowledgeBaseTag: boolean;
	CodeNameDefault: string;
	CodeNameTranslated: string;
}

export interface CodeWarehouseEditModel {
	id: string;
	warehouseWebId: string;
	descriptionFieldId: string;
	CodeName: string;
	active: boolean;
	applicationReference: string;
	Order: string;
	FactoryRecord: boolean;
	customerModified: boolean;
	value: string;
	GroupCodeWarehouseId: string;
	GroupCodeWarehouseCaption: string;
	IsCodeDevRecord: string;
	DescriptionDefault: string;
	descriptionTranslated: string;
	providerId?: number;
}
export interface WarehouseDetailEditModel {
	taskSheetId: number;
	warehouseWebId: string;
	taskId: number;
	step: number;
	descriptionDefault: string;
	descriptionTranslated: string;
}
export interface WarehouseDetailViewModel {
	taskSheetId: number;
	taskId: number;
	step: number;
	applicationReference: string;
	descriptionDefault: string;
	descriptionTranslated: string;
	taskSheetDescription:string;
}
export interface CodeCombo {
	id: number;
	descriptionTranslated: string;
	category: string;
	selected: boolean;
}
export interface CodeOrder {
	id: number;
	order: number;
}

@Injectable()
export class CodesFunc {
	baseUri: string = `${environment.apiUrl}`;
	constructor(private http: HttpClient) {
	}

	/**
	 * GET CodesFunc/GetCodes
	 */
	getCodes(): Observable<CodesEditModel[]> {
		return this.http.get<CodesEditModel[]>(this.baseUri + 'CodesFunc/GetCodes');
	}

	/**
	 * POST CodesFunc/PostCodeData
	 */
	insertCodeData(value: string): Observable<HttpResponse<string>> {
		return this.http.post(this.baseUri + 'CodesFunc/PostCodeData', JSON.stringify(value), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
	}
	/**
	 * PUT CodesFunc/PutCodeData?id={id}
	 */
	updateCodeData(id: string, value: string): Observable<HttpResponse<string>> {
		return this.http.put(this.baseUri + 'CodesFunc/PutCodeData?key=' + encodeURIComponent(id), JSON.stringify(value), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
	}

	/**
	 * DELETE CodesFunc/DeleteCodeData?id={id}
	 */
	deleteCodeData(id: string): Observable<HttpResponse<string>> {
		return this.http.delete(this.baseUri + 'CodesFunc/DeleteCodeData?key=' + encodeURIComponent(id), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
	}

	/**
	 * GET CodesFunc/GetCodeDetails
	 */
	getCodeDetails(codeName: string): Observable<CodeWarehouseEditModel[]> {
		return this.http.get<CodeWarehouseEditModel[]>(this.baseUri + 'CodesFunc/GetCodeDetails?codeName=' + encodeURIComponent(codeName));
	}

	getCodeCombo(codeName: string): Observable<CodeCombo[]> {
		return this.http.get<CodeCombo[]>(this.baseUri + 'CodesFunc/GetCodeCombo?codeName=' + encodeURIComponent(codeName));
	}

	getTaskCombo(codeName: string): Observable<CodeCombo[]> {
		return this.http.get<CodeCombo[]>(this.baseUri + 'CodesFunc/GetTaskCombo?codeName=' + encodeURIComponent(codeName));
	}

	getKbTitleCombo(): Observable<CodeCombo[]> {
		return this.http.get<CodeCombo[]>(this.baseUri + 'CodesFunc/GetKbTitleCombo');
	}

	getKbExercisesCombo(): Observable<CodeCombo[]> {
		return this.http.get<CodeCombo[]>(this.baseUri + 'CodesFunc/GetKbExercisesCombo');
	}

	getWarehouseDetails(warehouseWebId: string): Observable<WarehouseDetailEditModel[]> {
		return this.http.get<WarehouseDetailEditModel[]>(this.baseUri + 'CodesFunc/GetCodeWarehouseDetails?warehouseWebId=' + encodeURIComponent(warehouseWebId));
	}

	getWarehouseDetailsView(taskSheetId: number): Observable<WarehouseDetailViewModel[]> {
		return this.http.get<WarehouseDetailViewModel[]>(this.baseUri + 'CodesFunc/GetCodeWarehouseDetailsView?taskSheetId=' + encodeURIComponent(taskSheetId));
	}


	/**
	 * GET CodesFunc/GetParentCodeDetail
	 */
	getParentCodeDetail(codeName: string): Observable<SelectList[]> {
		return this.http.get<SelectList[]>(this.baseUri + 'CodesFunc/GetParentCodeDetail?codeName=' + encodeURIComponent(codeName));
	}

	/**
	 * POST CodesFunc/PostCodeDetail
	 */
	insertCodeDetail(value: string): Observable<HttpResponse<string>> {
		return this.http.post(this.baseUri + 'CodesFunc/PostCodeDetail', JSON.stringify(value), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
	}

	/**
	 * PUT CodesFunc/PutCodeDetail?id={id}
	 */
	updateCodeDetail(id: string, value: string): Observable<HttpResponse<string>> {
		return this.http.put(this.baseUri + 'CodesFunc/PutCodeDetail?key=' + encodeURIComponent(id), JSON.stringify(value), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
	}

	/**
	 * DELETE CodesFunc/DeleteCodeDetail?id={id}
	 */
	deleteCodeDetail(id: string): Observable<HttpResponse<string>> {
		return this.http.delete(this.baseUri + 'CodesFunc/DeleteCodeDetail?key=' + encodeURIComponent(id), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
	}

	/**
	 * POST CodesFunc/PostWarehouseDetail
	 */
	 insertWarehouseDetail(value: string): Observable<HttpResponse<string>> {
		return this.http.post(this.baseUri + 'CodesFunc/PostWarehouseDetail', JSON.stringify(value), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
	}

	/**
	 * DELETE CodesFunc/DeleteWarehouseDetail?id={id}
	 */
	 deleteWarehouseDetail(taskSheetId: number, taskId: number): Observable<HttpResponse<string>> {
		return this.http.delete(this.baseUri + 'CodesFunc/DeleteWarehouseDetail?taskSheetId=' + encodeURIComponent(taskSheetId) + '&taskId=' + encodeURIComponent(taskId), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
	}

	/**
	 * POST CodesFunc/AddNewTaskSheet
	 */
	addNewTaskSheet(value: string, providerId: number): Observable<HttpResponse<string>> {
		return this.http.post(this.baseUri + 'CodesFunc/AddNewTaskSheet?providerId=' + encodeURIComponent(providerId), JSON.stringify(value), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
	}


	/////////

	async getCodesData(code: string) {
		let codeData: CodeWarehouseEditModel[] = {} as CodeWarehouseEditModel[];
		return new Promise((resolve, reject) => {
			this.getCodeDetails(code).pipe(first()).subscribe(
				(p) => {
					codeData = p;
				},
				(e) => {
					//this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
					reject(e.message );
				},
				() => {
					resolve(codeData);
				}
			);
		});
	}

	async getCodesCombo(code: string) {
		let codeData: CodeCombo[] = {} as CodeCombo[];
		return new Promise((resolve, reject) => {
			this.getCodeCombo(code).pipe(first()).subscribe(
				(p) => {
					codeData = p;
				},
				(e) => {
					//this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
					reject(e.message );
				},
				() => {
					resolve(codeData);
				}
			);
		});
	}

	async getKbTitles() {
		let codeData: CodeCombo[] = {} as CodeCombo[];
		return new Promise((resolve, reject) => {
			this.getKbTitleCombo().pipe(first()).subscribe(
				(p) => {
					codeData = p;
				},
				(e) => {
					//this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
					reject(e.message);
				},
				() => {
					resolve(codeData);
				}
			);
		});
	}
	async getKbExercises() {
		let codeData: CodeCombo[] = {} as CodeCombo[];
		return new Promise((resolve, reject) => {
			this.getKbExercisesCombo().pipe(first()).subscribe(
				(p) => {
					codeData = p;
				},
				(e) => {
					//this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
					reject(e.message );
				},
				() => {
					resolve(codeData);
				}
			);
		});
	}

}





