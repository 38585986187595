<div class="container-narrow">
    <div class="my-3">
        <div class="d-md-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <div class="set-icons-sm mb-3 mb-lg-0 me-2">
                    <img src="../../../assets/images/set-password.svg">
                </div>
                <h1 class="display-6 d-block m-0">{{ 'Core.EditPassword' | translate:locale.language }}</h1>
            </div>
        </div>
        <hr>
        <div class="card">
            <div class="card-body">
                <form id="myForm" action="your-action" (submit)="onFormSubmit($event)">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <div class="form-label">{{ 'Core.Entity_OldPassword' | translate:locale.language }}
                                </div>
                                <dx-text-box
                                type="string"
                                [stylingMode]="stylingMode"
                                mode="password"
                                [(value)]="changePasswordViewModel.oldPassword">
                                    <dx-validator>
                                        <dxi-validation-rule type="required"></dxi-validation-rule>
                                        <dxi-validation-rule type="stringLength" min="4" message="{{ 'Core.PasswordTooShort' | translate:locale.language }}"></dxi-validation-rule>
                                    </dx-validator>
                                </dx-text-box>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <div class="form-label">{{ 'Core.NewPassword' | translate:locale.language }}
                                </div>
                                <dx-text-box
                                type="string"
                                [stylingMode]="stylingMode"
                                mode="password"
                                [(value)]="changePasswordViewModel.newPassword">
                                    <dx-validator>
                                        <dxi-validation-rule type="required"></dxi-validation-rule>
                                        <dxi-validation-rule type="stringLength" min="4" message="{{ 'Core.PasswordTooShort' | translate:locale.language }}"></dxi-validation-rule>
                                    </dx-validator>
                                </dx-text-box>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <div class="form-label">{{ 'Core.Entity_NewPasswordConfirm' | translate:locale.language }}
                                </div>
                                <dx-text-box
                                type="string"
                                [stylingMode]="stylingMode"
                                mode="password"
                                [(value)]="changePasswordViewModel.confirmPassword">
                                    <dx-validator>
                                        <dxi-validation-rule type="required"></dxi-validation-rule>
                                        <dxi-validation-rule type="stringLength" min="4" message="{{ 'Core.PasswordTooShort' | translate:locale.language }}"></dxi-validation-rule>
                                        <dxi-validation-rule
                                                type="compare"
                                                [comparisonTarget]="passwordComparison">
                                        </dxi-validation-rule>
                                        </dx-validator>
                                </dx-text-box>
                            </div>
                        </div>
                    </div>
                    <div>
                        <dx-button
                           class="w-100"
                            stylingMode="contained"
                            text="{{ 'Core.EditSave' | translate:locale.language }}"
                            type="default"
                            useSubmitBehavior=true>
                        </dx-button>
                    </div>

                </form>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
            </div>
        </div>
    </div>
</div>
