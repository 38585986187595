import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-facebook-login',
  templateUrl: './facebook-login.component.html',
  styleUrls: ['./facebook-login.component.scss']
})
export class FacebookLoginComponent implements OnInit {

  private authWindow: Window;
  errorDescription: string;
  failed: boolean;
  error: string;
  isRequesting: boolean;
  fbLoginUrl = `${environment.fbLoginUrl}`;
  rootUrl = `${environment.rootUrl}`;


  launchFbLogin() {
    this.authWindow = window.open(this.fbLoginUrl, null, 'width=600,height=400');
  }
  constructor(private authenticationService: AuthenticationService, private router: Router) {
    if (window.addEventListener) {
      window.addEventListener("message", this.handleMessage.bind(this), false);
    } else {
      (<any>window).attachEvent("onmessage", this.handleMessage.bind(this));
    }
  }

  ngOnInit(): void {
    this.launchFbLogin();
  }

  handleMessage(event: Event) {
    const message = event as MessageEvent;
    // Only trust messages from the below origin.
    if (message.origin !== this.rootUrl) return;
    if (!this.authWindow) return;

    this.authWindow.close();
    //console.log("1");
    const result = JSON.parse(message.data);
    if (!result.status) {
      this.failed = true;
      this.error = result.error;
      this.errorDescription = result.errorDescription;
      return;
    }
    else {
      this.failed = false;
      this.isRequesting = true;
    }

    this.authenticationService.facebookLogin(result.accessToken).then(data => {
      //this.router.navigate([this.returnUrl]);
      this.router.navigate(['/home']);
    }).catch(err => {
      this.error = err;
      this.failed = true;
      // //Ha új ÁSZF van, akkor el kell fogadni.
      // if (err == "Core.NewTerms") this.needAcceptance = true;
      // this.loading = false;
   });

  }
}
