<div class="gp-dashboard">
   <!-- PÁCIENS KATALÓGUS -->
   <div class="patient-catalog">
      <div class="m-3">
         <div class="d-md-flex justify-content-between align-items-center">
            <h1 class="display-6 d-block">{{ 'Project.PatientCatalogue' | translate:locale.language }}</h1>
            <div>
               <button type="button" class="btn btn-success" (click)="clickNewAdmission()">
                  <i class="bi bi-plus"></i> {{ 'Project.NewAdmission' | translate:locale.language }}
               </button>
            </div>
         </div>
         <hr>
         <div class="mb-3">
            <ul class="nav nav-pills">
               <li class="nav-item">
                  <a [ngClass]="filterOption == 'all' ? 'nav-link active' : 'nav-link '" (click)="onOptionChangedNew(0)"
                     aria-current="page" href="javascript:void(0)"><span>{{ 'Core.Allx' | translate:locale.language
                        }}</span></a>
               </li>
               <li class="nav-item">
                  <a [ngClass]="filterOption == 'eva' ? 'nav-link active position-relative' : 'nav-link position-relative'"
                     (click)="onOptionChangedNew(1)" href="javascript:void(0)">
                     <span>{{ 'Task.WaitingForEvaulation' | translate:locale.language }}</span>
                     <span *ngIf="needEvaluation > 0" class="badge rounded-pill bg-danger ms-1">
                        {{needEvaluation}}
                     </span>
                  </a>
               </li>
               <li class="nav-item">
                  <a [ngClass]="filterOption == 'new' ? 'nav-link active position-relative' : 'nav-link position-relative'"
                     (click)="onOptionChangedNew(2)" href="javascript:void(0)">
                     <span>{{ 'Project.NewPatient' | translate:locale.language }}</span>
                     <span *ngIf="newPatients > 0" class="badge rounded-pill bg-danger ms-1">
                        {{newPatients}}
                     </span>
                  </a>
               </li>
               <li class="nav-item">
                  <a [ngClass]="filterOption == 'wact' ? 'nav-link active position-relative' : 'nav-link position-relative'"
                     (click)="onOptionChangedNew(3)" href="javascript:void(0)">
                     <span>{{ 'Subscription.WaitingForActivation' | translate:locale.language }}</span>
                     <span *ngIf="needActivation > 0" class="badge rounded-pill bg-danger ms-1">
                        {{needActivation}}
                     </span>
                  </a>
               </li>

            </ul>
         </div>

         <div class="input-group w-100 mb-3">
            <input type="search" [(ngModel)]="searchText" class="form-control p-2"
               placeholder="{{'Project.PatientSearch' | translate:locale.language }}" aria-label="Páciens keresés"
               aria-describedby="patient-search" #searchInput>
            <!-- <button class="btn btn-neutral" type="button" id="patient-search">{{'KB.Search' | translate:locale.language }}</button> -->
         </div>

         <!-- Páciens card-ok -->
         <div class="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-3">
            <div class="col" *ngFor="let item of filteredDataSource | appFilter: searchText: filteredCount; index as i">
               <ng-container *ngIf="actualPageSize > i">
                  <div *ngIf="item.hasDoctor == 2" class="card h-100">
                     <div class="card-body d-flex flex-column align-items-center justify-content-between  position-relative">
                        <a href="#" *ngIf="item.status != ''" class="renewal-{{item.status}}" (click)="onSubscriptionInfoClick(item)"></a>
                        <span class="info-circle">
                           <a href="#" (click)="onInfoClick(item)"><i class="bi bi-info-circle-fill"></i></a>
                        </span>
                        <div class="avatar-image-sm"
                           [ngStyle]="{backgroundImage: 'url(' + uploadUrl + item.avatarName + ')'}"></div>

                        <div class="my-2 h4 fw-light text-center">
                           {{item.displayName}} 
                        </div>
                        <div class="my-2 w-100">
                           <button type="button" class="btn btn-primary w-100" (click)="selectPatient(item.id)">{{
                              'Project.Select' | translate:locale.language }}</button>
                        </div>
                     </div>
                  </div>
                  <div *ngIf="item.hasDoctor == 1" class="card h-100">
                     <div
                        class="card-body d-flex flex-column align-items-center justify-content-between  position-relative">
                        <span class="info-circle">
                           <a href="#" (click)="onInfoClick(item)"><i class="bi bi-info-circle-fill"></i></a>
                        </span>
                        <div class="avatar-image-sm"
                           [ngStyle]="{backgroundImage: 'url(' + uploadUrl + item.avatarName + ')'}"></div>
                        <div class="my-2 h4 fw-light text-center">
                           {{item.displayName}}
                        </div>
                        <div class="my-2 w-100">
                           <button *ngIf="item.doctorConfirmed == null" type="button" (click)="onClickStatus(item)" class="btn btn-success w-100">
                              {{'Project.Accept' | translate:locale.language }}
                           </button>
                           <button *ngIf="item.doctorConfirmed != null" type="button" (click)="addPlatinum(item)" class="btn btn-success w-100">
                              {{'Subscription.subscription' | translate:locale.language }}
                           </button>
                        </div>
                        <div class="my-2 w-100">
                           <button type="button" (click)="onDeny(item)" class="btn btn-danger w-100">{{'Project.Deny' |
                              translate:locale.language }}</button>
                        </div>
                     </div>

                  </div>
               </ng-container>
            </div>


         </div>
         <div class="row g-3">
            <!-- <div>AutoSearch Count: {{filteredCount.count}}</div> -->
            <div class="col-12 col-md-8 col-lg-6">
               <button *ngIf="filteredDataSource && filteredCount.count > actualPageSize" type="button"
                  class="btn btn-secondary w-100" (click)="clickMore()">
                  {{ 'Project.PatientCatalogueMore' | translate:locale.language }} <i class="bi bi-three-dots"></i>
               </button>
            </div>
         </div>
      </div>
   </div>
   <!-- SZAKRENDELŐ WIDGETEK-1-->
   <div *ngIf="doctorDashboard" class="clinic-widgets bg-widget">
      <div class="m-3">
         <div class="row row-cols-2 row-cols-md-1 g-3 pointer" (click)="selectKpis(0)">
            <div class="col">
               <div class="card bg-widget-card h-100">
                  <div class="card-body py-4">
                     <div class="d-flex justify-content-between align-items-center">
                     <h1 class="text-primary display-1 fw-bold">{{doctorDashboard.sumPatient}}</h1>
                     <h1>{{ 'Core.Patient2' | translate:locale.language }}</h1>
                  </div>
                     <div class="d-flex justify-content-between align-items-center">
                        <span>{{'Core.Activity' | translate:locale.language }}</span>
                        <div><span
                              class="text-success">{{doctorDashboard.activePatient}}</span>/<span class="text-primary">{{doctorDashboard.sumPatient}}</span>
                        </div>
                     </div>
                     <div class="progress bg-primary" style="height: 6px;">
                        <div class="progress-bar bg-success" role="progressbar" [style.width.%]="activePatientPercent"
                           [attr.aria-valuenow]="activePatientPercent" aria-valuemin="0" aria-valuemax="100"></div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col">
               <div class="card bg-widget-card h-100">
                  <div class="card-body py-4">
                     <h5 class="mb-4">{{'Project.NumberOfTests' | translate:locale.language }}</h5>
                     <div class="d-flex justify-content-between align-items-center">
                        <h3 class="display-1 fw-bold m-0">{{doctorDashboard.gamesAct}}</h3>
                           <div *ngIf="gamePercent > 100" class="widget-counter text-success border-success">
                              +{{gamePercent - 100 | number:'1.0-0'}}%
                           </div>
                           <div *ngIf="!(gamePercent > 100) && gamePercent !=0" class="widget-counter text-danger border-danger">
                              -{{100 - gamePercent | number:'1.0-0'}}%
                           </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="col" *ngIf="doctorDashboard && doctorDashboard.mostPopularName != null">
               <div class="card bg-widget-card h-100">
                  <div class="card-body py-4 d-flex flex-column">
                     <h5 class="mb-4">{{'Project.MostPopularTest' | translate:locale.language }}</h5>
                     <div class="d-flex w-100 justify-content-between align-items-center flex-fill">
                        <div class="{{doctorDashboard.mostPopularName}}_{{locale.language}}" title="{{ 'Game.' + doctorDashboard.mostPopularName | translate:locale.language }}"></div>
                        <div class="widget-counter text-success border-success">
                           {{doctorDashboard.mostPopularDb}}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- <div class="col">
               <div class="card bg-widget-card h-100">
                  <div class="card-body">
                     <h5>{{'Project.ClinicalTests' | translate:locale.language }}</h5>
                     <div class="d-flex justify-content-between align-items-center">
                        <h3 class="display-1 fw-bold me-3">{{doctorDashboard.taskSheetsAct}}</h3>
                        <h3 *ngIf="taskSheetPercent > 100"><span  class="badge rounded-pill bg-success">+{{taskSheetPercent - 100 |
                           number:'1.0-0'}}%</span></h3>
                        <h3 *ngIf="!(taskSheetPercent > 100) && taskSheetPercent !=0"><span  class="badge rounded-pill bg-danger">-{{100 -
                           taskSheetPercent | number:'1.0-0'}}%</span></h3>
                     </div>
                  </div>
               </div>
            </div> -->
            <div class="col">
               <div class="card bg-widget-card h-100">
                  <div class="card-body py-4 d-flex flex-column align-items-center flex-fill">
                     <h5 class="mb-4 w-100">{{'Project.Breakdown' | translate:locale.language }}</h5>
                        <dx-pie-chart id="pie" type="doughnut" 
                           [dataSource]="testTypes" centerTemplate="centerTemplate" horizontalAlignment="center">
                           <dxi-series argumentField="type" horizontalAlignment="center">
                           </dxi-series>
                           <dxo-legend horizontalAlignment="center" verticalAlignment="bottom" [customizeText]="customizeText"></dxo-legend>
                           <dxo-tooltip [enabled]="true">
                           </dxo-tooltip>
                           <dxo-size
                              [height]="250"
                              [width]="236">
                           </dxo-size>
                           <svg *dxTemplate="let pieChart of 'centerTemplate'">
                              <circle
                                cx="100"
                                cy="100"
                                [attr.r]="pieChart.getInnerRadius() - 6"
                                fill="#eee"
                              ></circle>
                              <text
                                text-anchor="middle"
                                style="font-size: 42px"
                                x="100"
                                y="120"
                                fill="#494949"
                              >
                                <tspan x="100" dy="-10px" style="font-weight: 700">{{totalTests}}</tspan>
                              </text>
                            </svg>
                      
                        </dx-pie-chart>
                    
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!--ELEMZÉSEK-->
   <div class="analytics-tiles m-3">
      <div class="d-md-flex justify-content-between align-items-center">
         <h1 class="display-6 d-block">{{ 'Project.Analytics' | translate:locale.language }}</h1>
      </div>
      <hr>
      <div class="row row-cols-1 row-cols-sm-2 g-3">
         <div class="col">
            <div class="card h-100 pointer" (click)="selectKpis(1)">
               <div class="card-body d-lg-flex">
                  <div class="analytics-icons">
                     <img src="../../../assets/images/analytics-provider.svg">
                  </div>
                  <div class="d-flex flex-column justify-content-center align-items-center flex-fill">
                        <h2 class="text-center mb-0">{{ 'Project.AnalyticsProvider' | translate:locale.language }}</h2>
                        <p class="text-center lead">{{ 'Project.AnalyticsWithTrend' | translate:locale.language }}</p>
                  </div>
               </div>
            </div>
         </div>
         <div class="col">
            <div class="card h-100 pointer" (click)="selectAnalytics()">
               <div class="card-body d-lg-flex">
                  <div class="analytics-icons">
                     <img src="../../../assets/images/analytics-clinical.svg">
                  </div>
                  <div class="d-flex flex-column justify-content-center align-items-center flex-fill">
                     <h2 class="text-center mb-0">{{ 'Project.AnalyticsClinical' | translate:locale.language }}</h2>
                     <p class="text-center lead">{{ 'Project.AnalyticsWithCharts' | translate:locale.language }}</p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
  <!-- TESZTLAPOK -->
   <div class="testsheet-review">
      <div class="m-3">
         <div class="d-md-flex justify-content-between align-items-center">
            <h1 class="display-6 d-block">{{ 'Task.TaskSheets' | translate:locale.language }}</h1>
            <div>
               <button type="button" class="btn btn-success" (click)="clickTaskSheets()">
                  <i class="bi bicams-task-card"></i> {{ 'Task.OwnTaskSheets' | translate:locale.language }}
               </button>
            </div>
         </div>
         <hr>
         <div class="row row-cols-md-2 row-cols-lg-4 g-3">
            <!-- ADAS-Cog block -->
            <div class="col">
               <div class="card adas-cog-block h-100" (click)="reviewTaskSheet('ADAS-Cog')">
                  <div class="card-body d-flex flex-column justify-content-between">
                     <div class="h4">ADAS-Cog</div>
                     <div class="mb-3 flex-fill">Alzheimer’s Disease Assessment Scale – Cognitive Subscale</div>
                     <div class="d-flex flex-wrap">
                        <span class="badge rounded-pill m-1">{{ 'Category.Dementia' | translate:locale.language
                           }}</span>
                        <span class="badge rounded-pill m-1">{{ 'Category.Alzheimers' | translate:locale.language
                           }}</span>
                        <span class="badge rounded-pill m-1">{{ 'Game.Problem' | translate:locale.language }}</span>
                        <span class="badge rounded-pill m-1">{{ 'Category.Parkinsons' | translate:locale.language
                           }}</span>
                     </div>
                  </div>
               </div>
            </div>
            <!-- BICAMS block -->
            <div class="col">
               <div class="card bicams-block h-100" (click)="reviewTaskSheet('BICAMS')">
                  <div class="card-body d-flex flex-column justify-content-between">
                     <div class="h4">BICAMS</div>
                     <div class="mb-3 flex-fill">Brief International Cognitive Assessment for MS</div>
                     <div class="d-flex flex-wrap">
                        <span class="badge rounded-pill m-1">{{ 'Category.MultipleSclerosis' | translate:locale.language
                           }}</span>
                        <span class="badge rounded-pill m-1">{{ 'Category.Alzheimers' | translate:locale.language
                           }}</span>
                        <span class="badge rounded-pill m-1">{{ 'Category.CognitiveDamage' | translate:locale.language
                           }}</span>
                        <span class="badge rounded-pill m-1">{{ 'Category.BrainDamage' | translate:locale.language
                           }}</span>
                     </div>
                  </div>
               </div>
            </div>
            <!-- MMSE block -->
            <div class="col">
               <div class="card mmse-block h-100" (click)="reviewTaskSheet('MMSE')">
                  <div class="card-body d-flex flex-column justify-content-between">
                     <div class="h4">MMSE</div>
                     <div class="mb-3 flex-fill">Mini-Mental State Examination</div>
                     <div class="d-flex flex-wrap">
                        <span class="badge rounded-pill m-1">{{ 'Category.Alzheimers' | translate:locale.language
                           }}</span>
                        <span class="badge rounded-pill m-1">{{ 'Category.Dementia' | translate:locale.language
                           }}</span>
                        <span class="badge rounded-pill m-1">{{ 'Game.Problem' | translate:locale.language }}</span>
                        <span class="badge rounded-pill m-1">{{ 'Category.Parkinsons' | translate:locale.language
                           }}</span>
                     </div>
                  </div>
               </div>
            </div>
            <!-- MOCA block -->
            <div class="col">
               <div class="card moca-block h-100" (click)="reviewTaskSheet('MOCA-A')">
                  <div class="card-body d-flex flex-column justify-content-between">
                     <div class="h4">MoCA</div>
                     <div class="mb-3 flex-fill">Montreal Cognitve Assessment</div>
                     <div class="d-flex flex-wrap">
                        <span class="badge rounded-pill m-1">{{ 'Category.Alzheimers' | translate:locale.language
                           }}</span>
                        <span class="badge rounded-pill m-1">{{ 'Category.Dementia' | translate:locale.language
                           }}</span>
                        <span class="badge rounded-pill m-1">{{ 'Game.Problem' | translate:locale.language }}</span>
                        <span class="badge rounded-pill m-1">{{ 'Category.Parkinsons' | translate:locale.language
                           }}</span>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
   <!-- JÁTÉKOK -->
   <div class="game-review">
      <div class="m-3">
         <div class="row">
            <div class="col-12">
               <h1 class="display-6 d-block">{{ 'Game.Games' | translate:locale.language }}</h1>
               <hr>
            </div>
         </div>
         <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-3">
            <!-- SDMT -->
            <div class="col">
               <div class="card position-relative h-100">
                  <img class="card-img" src="../assets/images/sdmt/img/sdmt-bg2.png">
                  <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center h-100">
                     <h2 class="card-title text-uppercase text-center m-0">
                        <span class="fw-bold">{{ 'Game.SDMT' | translate:locale.language }} </span>
                        <span class="fw-light">{{ 'Game.TypeGames' | translate:locale.language }}</span>
                     </h2>
                     <div class="d-flex justify-content-center w-100">
                        <div class="w-25 m-3 pointer">
                           <img src="../assets/images/sdmt/img/sdmt-icon.svg"
                              (click)="startTask('/games/sdmt-test/sdmt')">
                        </div>
                        <div class="w-25 m-3 pointer">
                           <img src="../assets/images/sdmt/img/ldst-icon.svg"
                              (click)="startTask('/games/sdmt-test/ldst')">
                        </div>
                        <div class="w-25 m-3 pointer">
                           <img src="../assets/images/dsst/img/dsst-icon.svg" (click)="startTask('/games/dsst-test')">
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- Memória -->
            <div class="col">
               <div class="card text-white position-relative h-100">
                  <img class="card-img" src="../assets/images/memory/memory-bg-2.png">
                  <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center h-100">
                     <h2 class="card-title text-uppercase text-center">
                        <span class="fw-bold">{{ 'Game.MemoryGame' | translate:locale.language }}</span>
                     </h2>
                     <div class="d-flex justify-content-center w-100">
                        <div class="w-25 m-1 pointer">
                           <img title="{{ 'Game.Classic' | translate:locale.language }}"
                              src="../assets/images/memory/memory-classic-icon.svg"
                              (click)="startTask('/games/memory/classic')">
                        </div>
                        <div class="w-25 m-1 pointer">
                           <img title="{{ 'Game.Semantic' | translate:locale.language }}"
                              src="../assets/images/memory/memory-semantic-icon.svg"
                              (click)="startTask('/games/memory/semantic')">
                        </div>
                        <div class="w-25 m-1 pointer">
                           <img title="{{ 'Game.Mathematical' | translate:locale.language }}"
                              src="../assets/images/memory/memory-math-icon.svg"
                              (click)="startTask('/games/memory/mathematical')">
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- Színfelismerő -->
            <div class="col">
               <div class="card text-white position-relative h-100">
                  <img class="card-img" src="../assets/images/color-recognition/color-recognition-bg-bg.png">
                  <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center h-100">
                     <h2 class="card-title text-uppercase text-center">
                        <span class="fw-bold">{{ 'Game.ColorRecognition' | translate:locale.language }}</span>
                     </h2>
                     <div class="d-flex justify-content-center w-100">
                        <div class="w-25 m-1 pointer">
                           <img title="{{ 'Game.BackColor' | translate:locale.language }}"
                              src="{{ 'Assets.color-recognition-bg-icon' | translate:locale.language }}"
                              (click)="startTask('/games/color-recognition/backColorRecognition')">
                        </div>
                        <div class="w-25 m-1 pointer">
                           <img title="{{ 'Game.ForeColor' | translate:locale.language }}"
                              src="{{ 'Assets.color-recognition-text-icon' | translate:locale.language }}"
                              (click)="startTask('/games/color-recognition/foreColorRecognition')">
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- Alakzat visszaidéző -->
            <div class="col">
               <div class="card text-white pointer position-relative" (click)="startTask('/games/shape-recall')">
                  <img class="card-img" src="../assets/images/shape-recall/shape-recall-bg.png">
                  <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center">
                     <h2 class="card-title text-uppercase text-center">
                        <span class="fw-bold">{{ 'Game.Shape' | translate:locale.language }}</span><br>
                        <span class="fw-light">{{ 'Game.Recall' | translate:locale.language }}</span>
                     </h2>
                     <img class="card-img" style="width:25%" src="../assets/images/shape-recall/shape-recall-icon.svg">
                  </div>
               </div>
            </div>
            <!-- IMCI teszt-->
            <div class="col">
               <div class="card text-white pointer position-relative" (click)="startTask('/games/ica-test')">
                  <img class="card-img" src="../assets/images/ica/imci-bg.png">
                  <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center">
                     <h2 class="card-title text-uppercase fw-bold">{{ 'Game.Imci' | translate:locale.language }}</h2>
                     <span>{{ 'Game.ExperimentalGame' | translate:locale.language }}</span>
                     <img class="card-img" src="../assets/images/ica/imci-icon.svg">
                  </div>
               </div>
            </div>
            <!-- TMT -->
            <div class="col">
               <div class="card text-white pointer position-relative" (click)="startTask('/games/tmt-test-b')">
                  <img class="card-img" src="../assets/images/tmt-bg.png">
                  <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center">
                     <h2 class="card-title text-uppercase text-center">
                        <span class="fw-bold">{{ 'Game.Tmt' | translate:locale.language }} </span>
                        <span class="fw-light">{{ 'Game.Test' | translate:locale.language }}</span>
                     </h2>
                     <img class="card-img" src="../assets/images/tmt-icon.svg">
                  </div>
               </div>
            </div>
            <!-- Szókitaláló -->
            <div class="col">
               <div class="card pointer position-relative" (click)="startTask('/games/word-guess')">
                  <img class="card-img" src="../assets/images/word-guess/word-guess-bg.png">
                  <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center">
                     <h2 class="card-title text-uppercase text-center">
                        <span class="fw-bold">{{ 'Game.WordGuess' | translate:locale.language }}</span><br>
                     </h2>
                     <img class="card-img" style="width:35%" src="../assets/images/word-guess/word-guess-icon.svg">
                  </div>
               </div>
            </div>
            <!-- Szó visszaidéző -->
            <div class="col">
               <div class="card text-white pointer position-relative" (click)="startTask('/games/word-list-recall')">
                  <img class="card-img" src="{{ 'Assets.word-list-recall-bg' | translate:locale.language }}">
                  <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center">
                     <h2 class="card-title text-uppercase text-center">
                        <span class="fw-bold">{{ 'Game.Word' | translate:locale.language }}</span><br>
                        <span class="fw-light">{{ 'Game.Recall' | translate:locale.language }}</span>
                     </h2>
                     <img class="card-img" style="width:25%"
                        src="../assets/images/word-list-recall/word-list-recall-icon.svg">
                  </div>
               </div>
            </div>
            <!-- Szófelismerő -->
            <div class="col">
               <div class="card text-white pointer position-relative" (click)="startTask('/games/word-list-recall-ac')">
                  <img class="card-img" src="{{ 'Assets.word-list-recall-ac-bg' | translate:locale.language }}">
                  <div class="card-img-overlay d-flex flex-column align-items-center justify-content-center">
                     <h2 class="card-title text-uppercase text-center">
                        <span class="fw-bold">{{ 'Game.Word' | translate:locale.language }}</span><br>
                        <span class="fw-light">{{ 'Game.Recognition' | translate:locale.language }}</span>
                     </h2>
                     <img class="card-img" style="width:25%"
                        src="../assets/images/word-list-recall/word-list-recall-ac-icon.svg">
                  </div>
               </div>
            </div>

         </div>
     <!-- Reklám helye -->
     <hr>
     <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-3">
       <div *ngIf="adSrc1 && adSrc1.imgSmall" class="col">
          <div class="card text-white pointer position-relative">
             <img class="card-img" [src]="uploadUrl + adSrc1.imgSmall">
             <a class="card-img-overlay d-flex flex-column align-items-center justify-content-center" [href]="adSrc1.link" target="_blank">
             </a>
          </div>
       </div>
       <div *ngIf="adSrc2 && adSrc2.imgSmall" class="col">
          <div class="card text-white pointer position-relative">
             <img class="card-img" [src]="uploadUrl + adSrc2.imgSmall">
             <a class="card-img-overlay d-flex flex-column align-items-center justify-content-center" [href]="adSrc2.link" target="_blank">
             </a>
          </div>
       </div>
       <div *ngIf="adSrc3 && adSrc3.imgSmall" class="col">
          <div class="card text-white pointer position-relative">
             <img class="card-img" [src]="uploadUrl + adSrc3.imgSmall">
             <a class="card-img-overlay d-flex flex-column align-items-center justify-content-center" [href]="adSrc3.link" target="_blank">
             </a>
          </div>
       </div>
  </div>
    </div>
   </div>
</div>
<!-- Új felvétel modal -->
<ng-template #newAdmission>
   <div class="modal-header">
      <h5 class="modal-title">{{ 'Project.NewAdmission' | translate:locale.language }}</h5>
      <button type="button" id="closeNewAdmissionModal" class="btn-close" data-bs-dismiss="modal"
         (click)="closeNewAdmissionModal(true)" aria-label="Close">
      </button>
   </div>
   <app-new-admission (closeModalEvent)="closeNewAdmissionModal($event)"></app-new-admission>
</ng-template>

<!-- Saját tesztlapok kezelése -->
<ng-template #taskSheets>
   <div class="modal-header">
      <h5 class="modal-title">{{ 'Task.OwnTaskSheets' | translate:locale.language }}</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal(true)" aria-label="Close">
      </button>
   </div>
   <!-- <app-task-sheet (closeModalEvent)="closeModal($event)"></app-task-sheet> -->
   <app-task-sheet [providerId]="currentUser.providerId"></app-task-sheet>
</ng-template>

<!-- Kezelési kérelem elutasítás modal -->
<ng-template #denyTreatment>
   <div class="modal-header">
      <h5 class="modal-title">{{ 'Project.DenyTreatment' | translate:locale.language }}</h5>
      <button type="button" id="closeDenyTreatmentModal" class="btn-close" data-bs-dismiss="modal"
         (click)="closeDenyTreatmentModal(true)" aria-label="Close">
      </button>
   </div>
   <app-deny-treatment (closeModalEvent)="closeDenyTreatmentModal($event)"
      [patientId]="selectedPatient"></app-deny-treatment>
</ng-template>

<!-- Info panel modal -->
<ng-template #infoPanel>
   <div class="modal-header">
      <h5 class="modal-title">{{ 'Core.PersonalData' | translate:locale.language }}</h5>
      <button type="button" id="closeModal" class="btn-close" data-bs-dismiss="modal" (click)="closeModal(true)"
         aria-label="Close">
      </button>
   </div>
   <app-patient-profile (closeModalEvent)="closeModal($event)" [inpPatientId]="selectedPatient"></app-patient-profile>
</ng-template>

<!-- Előfizetés info panel modal -->
<ng-template #subscriptionInfoPanel>
   <div class="modal-header">
      <h5 class="modal-title">{{ 'Subscription.subscription' | translate:locale.language }}</h5>
      <button type="button" id="closeModal" class="btn-close" data-bs-dismiss="modal" (click)="closeModal(true)"
         aria-label="Close">
      </button>
   </div>
   <div class="modal-body text-center">
      <h5 class="mb-3">{{'Core.Valid' | translate:locale.language }}: {{selectedItem.expireDate  | date: dateFormat}}</h5> 
      <input  class="btn btn-success w-100 " (click)="extendPlatinum();" type="button" 
          value="{{ 'Subscription.SubscriptionExtension' | translate:locale.language }}" />
   </div>
</ng-template>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loadingVisible"
   [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" (onShown)="onShown()"
   (onHidden)="onHidden()">
</dx-load-panel>
<div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>