// Kódtár elem fordítása

import { Pipe, PipeTransform } from '@angular/core';
import { CodeWarehouseEditModel } from '../../services/codes.service';

@Pipe({
  name: 'codeTranslate',
  pure: true
})
export class CodeTranslatePipe implements PipeTransform {
  transform(codeId: number, codes: CodeWarehouseEditModel[]): string {
    // console.log('CodeTranslatePipe', codeId, codes, Array.isArray(codes));
    if (!codeId || !codes || !Array.isArray(codes)) {
      return '?';
    }
    const cw = codes.find(x => x.id.toString() === codeId.toString());
    if (!cw) {
       return '??';
       }
    return cw.descriptionTranslated;
  }
}
