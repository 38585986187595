<!-- <p>Kódtár</p> -->
<div class="container pt-3">
    <dx-data-grid id="gridContainer"
        [dataSource]="dataSource"
        [masterDetail]="{ enabled: true, template: 'detail' }"
        [remoteOperations]="false"
        [rowAlternationEnabled]="true"
        [showBorders]="true"
        (onEditorPreparing)="editorPreparing($event)"
        (onEditorPrepared)="editorPrepared($event)"
        (onContentReady)="onContentReady_code($event)"
        (onRowExpanding)="onRowExpanding($event)"
        (onInitialized)="saveGridInstance($event)"
        > 
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager 
            [showPageSizeSelector]="true"
            [allowedPageSizes]="[9, 10, 20]"
            [showInfo]="true">
        </dxo-pager>

        <dxo-editing 
            mode="row"
            [allowUpdating]="true"
            [allowDeleting]="true"
            [allowAdding]="true"
            [useIcons]="true">
        </dxo-editing>

    <dxo-filter-row 
            [visible]="true">
    </dxo-filter-row>

    <dxo-header-filter 
            [visible]="false">
    </dxo-header-filter>


        <!-- <dxi-column dataField="id" dataType="string"></dxi-column> -->
        <dxi-column dataField="codeName" caption= "{{ 'Core.CodeName' | translate:locale.language }}" dataType="string">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column dataField="customerCanModify" caption= "{{ 'Core.CustomerCanModify' | translate:locale.language }}" dataType="boolean">
        </dxi-column>
        <dxi-column dataField="devRecord" caption= "{{ 'Core.DevRecord' | translate:locale.language }}" dataType="boolean">
        </dxi-column>
        <dxi-column dataField="knowledgeBaseTag" caption= "{{ 'KB.KbTitle' | translate:locale.language }}" dataType="boolean">
        </dxi-column>

        <dxi-column dataField="parentCodeName" caption="{{ 'Core.GroupCodeWarehouse' | translate:locale.language }}" dataType="string">
            <dxo-lookup [dataSource]="parentCodeData" valueExpr="codeName" displayExpr="codeName"></dxo-lookup>
        </dxi-column>

        <dxi-column dataField="codeNameDefault" caption= "{{ 'Core.DefaultCaption' | translate:locale.language }}" dataType="string">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>
        <dxi-column *ngIf="locale.language != 'hu-HU'"
            dataField="codeNameTranslated" caption= "{{ 'Core.Translation' | translate:locale.language }}" dataType="string">
            <dxi-validation-rule type="required"></dxi-validation-rule>
        </dxi-column>

        <div *dxTemplate="let code of 'detail'">
            <!-- <div class="master-detail-caption">{{code.data.codeName}}</div> -->
            <dx-data-grid 
                [dataSource]="getDetailsDS(code.key)"
                [showBorders]="true"
                [columnAutoWidth]="true"
                (onContentReady)="onContentReady_code($event)"
                (onInitNewRow)="onInitNewRow_detail($event,code.key)"
                >

            <dxo-editing 
                mode="row"
                [allowUpdating]="true"
                [allowDeleting]="true"
                [allowAdding]="true"
                [useIcons]="true">
            </dxo-editing>
        
        <dxo-filter-row 
                [visible]="true">
        </dxo-filter-row>
        
                    <dxi-column dataField="active" caption= "{{ 'Core.EditActive' | translate:locale.language }}" dataType="boolean"></dxi-column>
                    <dxi-column dataField="applicationReference" caption= "{{ 'Core.ReferenceId' | translate:locale.language }}" dataType="string">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>
                    <dxi-column dataField="order" caption= "{{ 'Core.Order' | translate:locale.language }}" dataType="numeric">
                        <dxi-validation-rule 
                            type="numeric">
                        </dxi-validation-rule>
                    </dxi-column>
                    <dxi-column dataField="value" caption= "{{ 'Core.Value' | translate:locale.language }}" dataType="numeric">
                        <dxi-validation-rule 
                            type="numeric">
                        </dxi-validation-rule>
                    </dxi-column>
                    <dxi-column dataField="factoryRecord" caption= "{{ 'Core.FactoryRecord' | translate:locale.language }}" dataType="boolean"></dxi-column>

                    <dxi-column *ngIf="code.data.parentCodeName != null"
                        dataField="groupCodeWarehouseId" caption="{{ 'Core.GroupCodeWarehouse' | translate:locale.language }}" dataType="string">
                        <dxo-lookup [dataSource]="parentCodeDetail" valueExpr="value" displayExpr="text">                             
                        </dxo-lookup>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>
                
                    <dxi-column dataField="descriptionDefault" caption= "{{ 'Core.DefaultCaption' | translate:locale.language }}" dataType="string">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>
                    <dxi-column *ngIf="locale.language != 'hu-HU'"
                        dataField="descriptionTranslated" caption= "{{ 'Core.Translation' | translate:locale.language }}" dataType="string">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-column>
                    </dx-data-grid>       
        </div>    

    </dx-data-grid>

</div>

