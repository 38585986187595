<!-- Megjelenítés -->
<div class="container">
  
    <div class="m-3">
        <div class="d-md-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <div class="general-icons-sm me-2">
                    <img src="../../../assets/images/messages.svg">
                </div>
                <h1 class="display-6 m-0">{{ 'Core.Messages' | translate:locale.language }}</h1>
            </div>
            <div class="mt-2 mt-md-0" *ngIf="!viewLayout">
                <button type="button" class="btn btn-success" (click)="clickNewNote()">
                    <i class="bi bi-plus"></i> {{ 'Project.NewNote' | translate:locale.language }}
                </button>
            </div>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <div [ngClass]="viewLayout ? 'row row-cols-1 row-cols-lg-2 g-3' : 'row row-cols-1  row-cols-xl-2 g-3'">
            <div *ngFor="let note of notesSource; index as i" class="col">
                <div class="card h-100">
                    <div class="card-body d-flex flex-column">
                        <div class="d-flex mb-2 align-items-center justify-content-between">
                            <div><i class="bi bi-calendar"></i> {{ note.createdOn | date: dateFormat }}</div>
                            <div *ngIf="!viewLayout">
                                <span *ngIf="note.isPublic" class="text-primary"><i class="bi bi-eye"></i> {{ 'Core.IsPublic' | translate:locale.language }}</span>
                                <span *ngIf="note.isRead" class="text-success ms-2"><i class="bi bi-check-lg"></i> {{ 'KB.ReadContent' | translate:locale.language }}</span>
                            </div>
                            <div *ngIf="viewLayout">
                                <span class="text-primary"><i class="bi bi-person"></i> {{note.doctorName}}</span>
                            </div>
                        
                        </div>
                        <div class="note p-3 rounded preserve-textarea-format mb-3 flex-fill" [innerHTML]="note.mailContent">
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <div>
                                <button *ngIf="!note.uploadedFile && !viewLayout  && !note.isRead" (click)="showUploadPopUp(i)" class="btn btn-primary me-2"><i class="bi bi-upload"></i> {{ 'Core.UploadAttachment' | translate:locale.language }}</button>
                                <button *ngIf="note.uploadedFile" (click)="showUploadPopUp(i)" class="btn btn-warning me-2"><i class="bi bi-paperclip"></i> {{ 'Core.Attachment' | translate:locale.language }}</button>
                            </div>
                            <div *ngIf="!viewLayout && !note.isRead">
                                <button (click)="clickEdit(i)" class="btn btn-primary me-2">{{ 'Core.EditEdit' | translate:locale.language }}</button>
                                <button (click)="clickDelete(i)" class="btn btn-danger"> {{ 'Core.EditDelete' | translate:locale.language }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Új Note Modal -->
<ng-template #newNote>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'Project.NewNote' | translate:locale.language }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <dx-text-area [autoResizeEnabled]="false" [height]="90" [(value)]="mailContent" [stylingMode]="stylingMode">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
        </dx-text-area>
        <div class="modal-footer px-0 d-flex align-items-center justify-content-between">
            <div>
                <dx-check-box [(value)]="isPublic"  text="{{ 'Core.IsPublic' | translate:locale.language }}">
                </dx-check-box>
            </div>
            <div>
                <dx-button stylingMode="contained" (onClick)="sendMessage()"
                text="{{ 'Core.EditSave' | translate:locale.language }}" type="default" useSubmitBehavior=true>
                </dx-button>
                <button type="button" (click)="closeModal()" class="btn btn-neutral ms-2" data-bs-dismiss="modal">
                    {{ 'Core.Close' | translate:locale.language }}
                </button>
            </div>
        </div>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</ng-template>

<!-- Edit Note Modal -->
<ng-template #editNote>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'KB.Edition' | translate:locale.language }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <dx-text-area [autoResizeEnabled]="false" [height]="90" [(value)]="mailContent" [stylingMode]="stylingMode">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
        </dx-text-area>
        <div class="modal-footer px-0 d-flex align-items-center justify-content-between">
            <div>
                <dx-check-box [(value)]="isPublic"  text="{{ 'Core.IsPublic' | translate:locale.language }}">
                </dx-check-box>
            </div>
            <div>
                <dx-button stylingMode="contained" (onClick)="sendMessage()"
                text="{{ 'Core.EditSave' | translate:locale.language }}" type="default" useSubmitBehavior=true>
                </dx-button>
                <button type="button" (click)="closeModal()" class="btn btn-neutral ms-2" data-bs-dismiss="modal">
                    {{ 'Core.Close' | translate:locale.language }}
                </button>
            </div>
        </div>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</ng-template>

<!-- Dokumentum feltöltő popup a felmérési eredményekhez -->

<dx-popup [showTitle]="true" title="{{ 'Core.UploadButtonTitle' | translate:locale.language }}" [height]="250"
    [width]="400" [(visible)]="uploadVisible" [showCloseButton]="true">
    <div>
        <dx-file-uploader name='myFile' #myFile [uploadFile]="uploadFile"
            [allowedFileExtensions]="['.jpeg','.jpg', '.png', '.docx', '.xlsx', '.doc', '.xls', '.pdf']"
            [minFileSize]="1024" [maxFileSize]="1024 * 1024" [multiple]="false" accept="*" [(value)]="files"
            uploadMode="instantly"></dx-file-uploader>
    </div>
</dx-popup>

<!-- Dokumentum megjelenító popup a felmérési eredményekhez -->

<dx-popup [showTitle]="true" title="{{ 'Core.UploadedFile' | translate:locale.language }}" [height]="200" [width]="300"
    [(visible)]="showUploadedDocument" [showCloseButton]="true">
    <div>
        <div class="content">
            <!-- <a href="{{uploadFileURL}}" target="_blank">Click here</a> -->
            <button type="button" (click)="showDocument()" class="btn btn-neutral me-2" data-bs-dismiss="modal">
                {{ 'Core.EditView' | translate:locale.language }}
            </button>
            <button *ngIf="!isRead" type="button" (click)="deleteDocument()" class="btn btn-danger">
                {{ 'Core.EditDelete' | translate:locale.language }}
            </button>
        </div>
    </div>
</dx-popup>


<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" (onShown)="onShown()">
</dx-load-panel>