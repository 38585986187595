<div class="container pt-3">

    <dx-data-grid id="gridContainer" [dataSource]="dataSource" [masterDetail]="{ enabled: true, template: 'detail' }"
        [remoteOperations]="true" [rowAlternationEnabled]="true" [showBorders]="true"
        (onEditorPreparing)="editorPreparing($event)" (onEditorPrepared)="editorPrepared($event)"
        (onContentReady)="onContentReady_code($event)" (onRowExpanding)="onRowExpanding($event)"
        (onInitialized)="saveGridInstance($event)">
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager [showPageSizeSelector]="true" [allowedPageSizes]="[9, 10, 20]" [showInfo]="true">
        </dxo-pager>

        <dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="false" [allowAdding]="providerId"
            [useIcons]="true">
        </dxo-editing>

        <dxo-filter-row [visible]="true">
        </dxo-filter-row>

        <dxo-header-filter [visible]="false">
        </dxo-header-filter>


        <dxi-column dataField="descriptionDefault" caption="{{ 'Task.TaskSheets' | translate:locale.language }}"
            dataType="string">
            <dxi-validation-rule type="required"></dxi-validation-rule>
            <dxi-validation-rule
              type="stringLength"
              [max]="12"
              message="Name must have max 12 symbols"
            ></dxi-validation-rule>

        </dxi-column>

        <div *dxTemplate="let code of 'detail'">
            <div class="master-detail-caption">{{ 'Task.Tasks' | translate:locale.language }}</div>
            <dx-data-grid [dataSource]="getDetailsDS(code.data.warehouseWebId)" [showBorders]="true" [columnAutoWidth]="true"
                (onContentReady)="onContentReady_code($event)" (onInitNewRow)="onInitNewRow_detail($event,code.key)">

                <dxo-editing mode="row" [allowUpdating]="false" [allowDeleting]="true" [allowAdding]="true"
                    [useIcons]="true">
                </dxo-editing>

                <dxo-filter-row [visible]="true">
                </dxo-filter-row>


                <dxi-column  dataField="taskId"
                    caption="{{ 'Task.Task' | translate:locale.language }}" dataType="string">
                    <dxo-lookup [dataSource]="parentCodeDetail" valueExpr="id" displayExpr="descriptionTranslated">
                    </dxo-lookup>
                    <dxi-validation-rule type="required"></dxi-validation-rule>
                </dxi-column>
                <dxi-column dataField="step" caption="{{ 'Core.Order' | translate:locale.language }}" dataType="numeric" sortOrder="asc">
                    <dxi-validation-rule type="numeric">
                    </dxi-validation-rule>
                </dxi-column>
    
            </dx-data-grid>

        </div>

    </dx-data-grid>
 
</div>