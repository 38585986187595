<!-- Megjelenítés -->
<div class="container">
    <div class="m-3">
        <div class="d-xl-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <div class="measurement-icons-sm mb-3 mb-lg-0 me-2">
                    <img src="../../../assets/images/measurement-lab.svg">
                </div>
                <h3 class="display-6 d-block m-0">{{ 'Project.LabResults' | translate:locale.language }}</h3>
            </div>
            <div class="d-md-flex align-items-center">
                <div class="me-2">
                    <dx-radio-group
                        [items]="options"
                        [value]="option"
                        displayExpr= "name"
                        valueExpr= "value"
                        layout="horizontal"
                        (onValueChanged)="onOptionChanged($event)">
                    </dx-radio-group>
                </div> 
                <div>
                    <button type="button"
                        class="btn btn-success me-2"
                        (click)="clickNew('Blood')">
                        <i class="bi bi-plus"></i> {{ 'Project.NewBloodLabResult' | translate:locale.language }}
                    </button>
                </div>    
                <div>
                    <button type="button"
                        class="btn btn-success me-2"
                        (click)="clickNew('Urine')">
                        <i class="bi bi-plus"></i> {{ 'Project.NewUrineLabResult' | translate:locale.language }}
                    </button>
                </div>    
                <div>
                    <button type="button"
                        class="btn btn-success"
                        (click)="clickNew('Liquor')">
                        <i class="bi bi-plus"></i> {{ 'Project.NewLiquorLabResult' | translate:locale.language }}
                    </button>
                </div>    
                  
            </div>
        </div>
        <hr>
    </div>
        <div *ngIf="option=='detailed'">
            <div class="card m-3" *ngFor="let day of dataSource; index as i">
                <div class="card-header d-flex justify-content-between">
                    <h4 class="m-0 text-primary">
                        {{ 'Project.ResultDate' | translate:locale.language }} : {{day.measuredOn  | date: dateFormat}}
                    </h4>
                    <div>
                        <a (click)="clickEdit(i)" class="h4 mx-2 my-0 text-info pointer"><i class="bi bi-pencil"></i></a>
                        <a (click)="clickDelete(i)" class="h4 mx-2 my-0 text-danger pointer"><i class="bi bi-trash"></i></a>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div *ngFor="let labItem of day.labDataList; index as x" class="col-6 col-xl-4 mb-2">
                            <label class="me-1">{{labItem.labTranslation}}:</label>
                            <span *ngIf="labItem.patientUnit != 'bit'" class="fw-bold me-1">{{labItem.value}} {{labItem.patientUnit}}</span>
                            <span *ngIf="labItem.patientUnit == 'bit'" class="fw-bold me-1">{{labItem.value | bitConversion  | translate:locale.language}}</span>
                            <span *ngIf="labItem.outOfReference" class="fw-bold" style="color:  red;">*</span>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="option!='detailed'">
            <div class="card mb-3">
                <div class="card-header d-flex justify-content-between">
                    <h4 class="m-0 text-primary">
                        {{ 'Project.LatestResults' | translate:locale.language }}
                    </h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div *ngFor="let lab of sumDataSource; index as x" class="col-6 col-xl-4 mb-2">
                            <label class="me-1">{{lab.labTranslation}}:</label>
                            <span *ngIf="lab.patientUnit != 'bit'" class="fw-bold me-1">{{lab.value}} {{lab.patientUnit}}</span>
                            <span *ngIf="lab.patientUnit == 'bit'" class="fw-bold me-1">{{lab.value | bitConversion | translate:locale.language}}</span>
                            <span *ngIf="lab.outOfReference" class="fw-bold" style="color:  red;">*</span>
                            <br>
                            <i>{{'Core.Date' | translate:locale.language }}: {{lab.measuredOn | date: dateFormat}}</i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>

    <!-- Új Modal -->
    <ng-template #newLabResult>
        <div class="modal-header">
        <h5 class="modal-title" id="NewMeasurementLabel">{{ 'Project.NewLabResult' | translate:locale.language }}</h5>
        <button type="button" 
                class="btn-close" 
                data-bs-dismiss="modal"
                (click)="closeModal()" 
                aria-label="Close">
        </button>
        </div>
        <div class="modal-body">
            <form action="your-action" (submit)="onFormSubmit($event,0)">
                <ng-container *ngTemplateOutlet="fieldsTemplate"></ng-container>
            </form>
            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
        </div>
    </ng-template>
    
    <!-- Szerkesztés Modal -->
    <ng-template #editLabResult>
        <div class="modal-header">
        <h5 class="modal-title" id="EditMeasurementLabel">{{ 'Core.DataEdit' | translate:locale.language }}</h5>
        <button type="button" 
                class="btn-close" 
                data-bs-dismiss="modal"
                (click)="closeModal()" 
                aria-label="Close">
        </button>
        </div>
        <div class="modal-body">
            <form action="your-action" (submit)="onFormSubmit($event,0)">
                <ng-container *ngTemplateOutlet="fieldsTemplate"></ng-container>
            </form>
            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
        </div>
    </ng-template>
    
    
 
<ng-template #fieldsTemplate>
    <div class="container" *ngIf="editItem">
        <div class="row">
            <div class="col-12 mb-3">
                <label>{{ 'Project.ResultDate' | translate:locale.language }}</label>
                 <dx-date-box
                type="date"
                [stylingMode]="stylingMode"
                width="100%"
                (onFocusOut)="resultDateFocusOut($event)"
                (onInitialized)="initResultDate($event)"
                [(value)]="editItem.measuredOn">
                    <dx-validator>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                        <dxi-validation-rule type="range" [max]="maxDate"></dxi-validation-rule>
                    </dx-validator>
                </dx-date-box>
            </div>

            <div class="col-lg-4 mb-3" *ngFor="let labItem of filter(editItem.labDataList); index as x">
             <dx-validation-group #targetGroup (onInitialized)="onGroupInit($event,x)"> 
               <label>{{labItem.laborTranslation}}: 
                    <span *ngIf="labItem.outOfReference" class="fw-bold" style="color:  red;">*</span>
                </label>
               <div *ngIf="labItem.selectedUnit != 'bit'" class="dx-value-unit-pair" >
                    <dx-number-box
                        [(value)]="labItem.value"
                        stylingMode="filled"
                        width="50%"
                        id={{labItem.labCodeId}}
                        (onValueChanged)="onValueChanged($event,labItem.labCodeId)"
                        [showSpinButtons]="false"
                        [showClearButton]="false">
                        <dx-validator>
                            <!-- <dxi-validation-rule
                                type="async"
                                ignoreEmptyValue="true"
                                [validationCallback]="asyncRangeValidation"
                                width="40">
                            </dxi-validation-rule> -->
                         </dx-validator>
                    </dx-number-box>
                    <dx-select-box [dataSource]="labItem.allSelectableUnits"
                                    valueExpr="category"
                                    stylingMode="filled"
                                    width="50%"
                                    displayExpr="descriptionTranslated"
                                    [(value)]="labItem.selectedUnit"
                                    (onValueChanged)="onValueChanged($event,labItem.labCodeId)">
                        <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
                <span *ngIf="labItem.outOfReference" class="" style="color: #f44336; font-size:0.75em">{{labItem.error}}</span>

                <div *ngIf="labItem.selectedUnit == 'bit'" class="dx-value-unit-pair" >
                    <dx-select-box [dataSource]="combo"
                                    valueExpr="value"
                                    stylingMode="filled"
                                    width="100%"
                                    displayExpr="name"
                                    [(value)]="labItem.value">
                    </dx-select-box>
                </div>
             </dx-validation-group>

            </div>
        </div>
    </div>
    <div class="modal-footer">
        <dx-button
             stylingMode="contained"
             text="{{ 'Core.EditSave' | translate:locale.language }}"
             type="default"
             useSubmitBehavior=true>
         </dx-button>
     
         <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
             {{ 'Core.EditCancel' | translate:locale.language }}
         </button>
    </div>
</ng-template>    