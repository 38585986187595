import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { AuthenticationService } from './services/authentication.service';
import { L10N_CONFIG, L10nConfig, L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';

import DxMenu from 'devextreme/ui/menu';
import { Menu, MenuService } from './services/menu.service';

import {
  LoginUser, PatientDataViewModel
} from './services/webapiclient.service';
import { locale } from 'devextreme/localization';
import { Subject } from 'rxjs/internal/Subject';
import { environment } from 'src/environments/environment';
import { PatientService } from './module-patient/patient.service';
import { Role, RolePU, RoleU } from '../app/models/role';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [MenuService]
})
export class AppComponent implements OnInit {
  @ViewChild('navbarToggler', { read: ElementRef, static: false }) navbarToggler: ElementRef;
  menuDs: Menu[];
  dxMenuInstance: DxMenu;
  showSubmenuModes: any;
  showFirstSubmenuModes: any;
  currentMenu: Menu;
  patientDataViewModel: PatientDataViewModel = {} as PatientDataViewModel;
  uploadUrl = `${environment.uploadUrl}`;
  blobUrl: string;

  schema = this.l10nConfig.schema;
  title = 'PreDem';
  currentUser: LoginUser;
  rolePU: boolean = false;
  roleU: boolean = false;
  homeUrlPre: string;
  homeUrlKog: string;
  rootUrl: string;

  routerEventSubscription: any;
  private destroy$: Subject<boolean> = new Subject<boolean>();


  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    @Inject(L10N_CONFIG) private l10nConfig: L10nConfig,
    private translation: L10nTranslationService,
    menuService: MenuService,

    private router: Router,
    private patientService: PatientService,
    private authenticationService: AuthenticationService
  ) {
    
    this.authenticationService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser) {
        this.rolePU = RolePU.includes(this.currentUser.role as Role)      
        this.roleU = RoleU.includes(this.currentUser.role as Role)      
        this.menuDs = menuService.getMenu(this.currentUser.role);
        this.getPatientData(this.currentUser.id);
      } else {
        this.menuDs = menuService.getMenu('');
      }
      this.translateMenu();
    });
    
    this.homeUrlPre = `${environment.homeUrlPre}`;
    this.homeUrlKog = `${environment.homeUrlKog}`;
    this.rootUrl = `${environment.rootUrl}`;

    this.showSubmenuModes = [{
      name: 'onHover',
      delay: { show: 0, hide: 500 }
    }, {
      name: 'onClick',
      delay: { show: 0, hide: 300 }
    }];
    this.showFirstSubmenuModes = this.showSubmenuModes[0];
  }

  getPatientData(id: string) {
    this.patientService.getPatient(id).then(
      result => {
        this.patientDataViewModel = <PatientDataViewModel>result;
        this.blobUrl = this.uploadUrl + this.patientDataViewModel.avatarName;
      }
    ).catch(err => {
    });
  }

  setPatientLanguage(id: string, lang: string) {
    this.patientService.setLanguage(id, lang).then(
      result => {
      }
    ).catch(err => {
    });
  }


  itemClick(data) {
    const item = data.itemData;
    if (!item.route) return;
    this.currentMenu = item;
    if (item.route === 'logout') {
      this.logout();
    } else {
      this.router.navigate([item.route]);
    }
  }

  startItem(r) {
    if (!r) return;
    if (r === 'logout') {
      this.logout();
    } else {
      this.navbarToggler.nativeElement.classList.remove('show');
      this.router.navigate([r]);
    }
  }
  
  saveMenuInstance(e) {
    this.dxMenuInstance = e.component;
  }

  async logout() {
    await this.authenticationService.logout();
    this.navbarToggler.nativeElement.classList.remove('show');
    //this.router.navigate(['/login']);
    //Nincs belépve a felhsználó, átirányítjuk a landing page-re
    this.goToLanding();

  }

  login() {
    this.navbarToggler.nativeElement.classList.remove('show');
    this.router.navigate(['/login']);
  }
  // fbLogin() {
  //   this.navbarToggler.nativeElement.classList.remove('show');
  //   this.router.navigate(['/facebook-login']);
  // }
  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

   //nyelv váltáshoz kéne

    // this.routerEventSubscription = this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     this.router.navigated = false;
    //   }
    // })
     
    this.setLocale(this.locale);

    this.translation.onChange().subscribe(
      x => {
        if (this.menuDs) {
          this.translateMenu();
          // this.dxMenuInstance.getDataSource().reload();
        }
      });

  }

  ngOnDestroy(): void {
    this.routerEventSubscription.unsubscribe();
    this.destroy$.next(true);
  }

  async setLocale(localeTr: L10nLocale): Promise<void> {
    await this.translation.setLocale(localeTr);
    locale(localeTr.language);

    if(this.currentUser && this.currentUser.id){
      this.setPatientLanguage(this.currentUser.id,localeTr.language);
     }
    // ez csak chrome-ban megy, firefoxban nem, ezért kivettem
    //if (this.locale.language != localeTr.language) this.reloadComponent();// window.location.href = window.location.toString(); // window.location.reload();// window.location.replace('/home'); // 
    
    //nyelv váltáshoz kéne
    //this.reloadComponent();
  }

  goToHome() {
    if (!this.currentUser || this.currentUser.displayName == null){
      //Nincs belépve a felhsználó, átirányítjuk a landing page-re
      this.goToLanding();
   }else{
    this.router.navigate(['/home']);
   }

  }

  goToLanding(){
    if(window.location.href.includes('predemplatform')){
      window.location.href = this.homeUrlPre;
    }else{
      window.location.href = this.homeUrlKog;
    }
  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    let currentUrl = this.router.url;
    this.router.navigate([currentUrl]);

  }

  /**
   * A menürendszer forítása. 3 szint van kezelve: Főmenü -> almenü -> almenü
   */
  translateMenu() {
    this.menuDs.forEach(element => {
      // Főmenü
      element.name = this.translation.translate(element.name_code);
      element.items.forEach(subElement => {
        // Almenü
        subElement.name = this.translation.translate(subElement.name_code);
        if (subElement.items) {
          // Almenü almenüje
          subElement.items.forEach(subElement2 => {
            subElement2.name = this.translation.translate(subElement2.name_code);
          });
        }
      });
    });
  }

}


