<!-- chart -->
<div class="mt-3">
        <div class="chart-size-fix">
            <dx-chart id="chart" [dataSource]="filteredDataSource" class="{{ifDoctor}}"
            (onLegendClick)="legendClick($event)" (onInitialized)="initChart($event)">
                <dxi-series argumentField="measurementDate" valueField="spo2" name="Spo2" color="blue">
                    <dxo-point symbol="circle" color="blue"></dxo-point>
                </dxi-series>
                <dxi-series argumentField="measurementDate" valueField="pulse"
                    name="{{ 'Project.Pulse' | translate:locale.language }}" color="green">
                    <dxo-point symbol="circle" color="green"></dxo-point>
                </dxi-series>
    
                <dxi-value-axis>
                    <dxi-constant-line [value]="95" color="#fc3535" dashStyle="dash" [width]="2">
                        <dxo-label [visible]="false"></dxo-label>
                    </dxi-constant-line>
                </dxi-value-axis>
    
                <dxo-common-series-settings type="scatter"></dxo-common-series-settings>
    
                <dxo-argument-axis [workdaysOnly]="false" [tickInterval]="1">
                    <dxo-label [customizeText]="customizeTimeText"></dxo-label>
                    <dxo-grid [visible]="true"></dxo-grid>
                    <dxo-minor-grid [visible]="true"></dxo-minor-grid>
                </dxo-argument-axis>
    
                <dxo-legend [visible]="true" verticalAlignment="bottom" horizontalAlignment="center">
                </dxo-legend>
    
                <dxo-tooltip [enabled]="true" [shared]="false">
                </dxo-tooltip>
    
                <dxo-common-pane-settings backgroundColor="#ffffff">
                    <dxo-border [visible]="true"></dxo-border>
                </dxo-common-pane-settings>
            </dx-chart>
        </div>
        
        <hr>

</div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>