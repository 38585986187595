<div class="col-md-6 offset-md-3 mt-5">
    <!-- <div class="alert alert-info">
        {{ 'Core.NewPassword' | translate:locale.language }}<br />
    </div> -->

    <div class="card">
        <h4 class="card-header">{{ 'Core.NewPassword' | translate:locale.language }}</h4>
        <div class="card-body">
            <form action="your-action" (submit)="onFormSubmit($event)">
                <dx-form id="form"
                    [formData]="resetPasswordViewModel"
                     validationGroup="registerData">

                     <dxi-item dataField="UserName">
                        <dxo-label
                            text="{{ 'Core.Entity_UserUserName' | translate:locale.language }}">
                        </dxo-label>
                        <dxi-validation-rule
                            type="required">
                        </dxi-validation-rule>

                    </dxi-item>

                     <dxi-item dataField="Password" [editorOptions]="{ mode: 'password' }">
                        <dxo-label
                            text="{{ 'Core.Entity_Password' | translate:locale.language }}">
                        </dxo-label>
                        <dxi-validation-rule
                            type="required">
                        </dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" min="4" message="{{ 'Core.PasswordTooShort' | translate:locale.language }}"></dxi-validation-rule>

                    </dxi-item>
                    <dxi-item dataField="ConfirmPassword" [editorOptions]="{ mode: 'password' }">
                        <dxo-label
                        text="{{ 'Core.Entity_PasswordConfirm' | translate:locale.language }}">
                        </dxo-label>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" min="4" message="{{ 'Core.PasswordTooShort' | translate:locale.language }}"></dxi-validation-rule>
                        <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison"
                            ></dxi-validation-rule>
                    </dxi-item>

                    <dxi-item
                        itemType="button"
                        horizontalAlignment="left"
                        [buttonOptions]="buttonOptions">
                    </dxi-item>

                </dx-form>
              </form>
              <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
          </div>
    </div>
</div>
