<!-- Megjelenítés -->
<div class="container">
    <div class="m-3">
        <div class="form-label">{{ 'Project.SuggestedDoctor' | translate:locale.language }}
        </div>
        <dx-select-box [dataSource]="doctorSource" (onValueChanged)="onDoctorChanged($event)" valueExpr="id"
            displayExpr="userName" [(value)]="selectedDoctor" stylingMode="filled">
        </dx-select-box>
    </div>
    <div class="m-3">
        <div class="col-md-12 mb-3 align-self-end">
            <div>
                <div class="form-label">{{ 'Project.MailContent' | translate:locale.language }}
                </div>
                <dx-text-area [autoResizeEnabled]="true" [height]="90" [(value)]="mailContent"
                    [stylingMode]="stylingMode">
                    <dx-validator>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-area>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <dx-button stylingMode="contained" text="{{ 'Core.EditSave' | translate:locale.language }}" type="default"
            (onClick)="save()" useSubmitBehavior=true>
        </dx-button>
        <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
            {{ 'Core.EditCancel' | translate:locale.language }}
        </button>

    </div>

</div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" (onShown)="onShown()">
</dx-load-panel>