import { Component, Inject, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { PatientService } from '../patient.service';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { loadMessages, locale } from 'devextreme/localization';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LoginUser, BloodOxygen } from 'src/app/services/webapiclient.service';
import { ActivatedRoute } from '@angular/router';
import notify from 'devextreme/ui/notify';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmDialogService } from 'src/app/confirm-dialog/confirm-dialog-service';
import { Subscription } from 'rxjs';
import { BoolResult, StringResult, ThryveService } from 'src/app/module-thryve/thryve.service';
import { CorrectDate } from 'src/app/utils';

/**
 * Mért adatok bevitele.
 * (véroxygén spo2 adatok)
 */

@Component({
  selector: 'app-blood-oxygen',
  templateUrl: './blood-oxygen.component.html',
  styleUrls: ['./blood-oxygen.component.scss']
})
export class BloodOxygenComponent implements OnInit, OnDestroy {
  @Input() inpPatientId: string;

  @ViewChild('newMeasurement', { static: true }) newMeasurement: TemplateRef<any>;
  @ViewChild('editMeasurement', { static: true }) editMeasurement: TemplateRef<any>;

  modalRef: BsModalRef;
  stylingMode = "filled"
  dataSource: any;
  currentUser: LoginUser;
  error: any;
  addMode: boolean = false;
  editItem: any;
  maxDate: Date = new Date();
  patientId: any;

  editIndex: any;

  thryveConnected = false;
  boolResult: BoolResult = {} as BoolResult;
  stringResult: StringResult = {} as StringResult;
  lastRefreshDate: string = null as string;
  loading: boolean = false;
  options: any[];
  option: string;
  selectedOption: any;
  showAll: boolean = true;

  startDate: Date = new Date(new Date().setMonth(new Date().getMonth() - 1));
  endDate: Date = new Date();
  dateTimeFormat: string;

  startDateInstance: any;
  endDateInstance: any;
  customDate: boolean = false;

  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private patientService: PatientService,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private thryveService: ThryveService,
    private modalService: BsModalService,
    private confirmDialogService: ConfirmDialogService,
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });
    //Periódus választó
    this.options = [
      {
        "name": this.translation.translate('Game.Month'),
        "value": 30
      },
      {
        "name": this.translation.translate('Game.QY'),
        "value": 90
      },
      {
        "name": this.translation.translate('Game.HY'),
        "value": 180
      },
      {
        "name": this.translation.translate('Game.Year'),
        "value": 365
      },
      {
        "name": this.translation.translate('Core.Custom'),
        "value": 0
      }
    ];
    this.option = this.options[0].value;
    this.selectedOption = this.options[0];
  }


  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });
    this.maxDate.setDate(this.maxDate.getDate() + 1);
    if (this.inpPatientId) this.patientId = this.inpPatientId;
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      if (!this.patientId) this.patientId = this.currentUser.id;
      this.loadDataSource();
      //Megnézzük, hogy van-e thryve kapcsolat
      //Ha van frissítünk
      // this.thryveService.checkThryveConnection(this.patientId)
      //   .subscribe(
      //     (data: BoolResult) => {
      //       if (this.boolResult.error) {
      //         this.thryveConnected = false;
      //         this.loading = true;
      //         this.loadDataSource();
      //       } else {
      //         this.thryveConnected = data.result;
      //         this.refresh();
      //       }
      //     }
      //   );
    }
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }
  onOptionChanged(days, index) {
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - days);
    this.selectedOption = this.options[index];
    this.customDate = false;
    this.loadDataSource();
  }
  onOptionChangedCustom() {
    this.customDate = true;
    this.selectedOption = this.options[4];
  }

  onShowAll(e) {
    this.showAll = e.value;
  }

  startDateFocusOut(s) {
    CorrectDate(this.startDateInstance);
  }

  initStartDate(e) {
    this.startDateInstance = e.component;
  }

  endDateFocusOut(s) {
    CorrectDate(this.endDateInstance);
  }

  initEndDate(e) {
    this.endDateInstance = e.component;
  }
  onQuery() {
    this.loadDataSource();
  }

  refresh(): void {
    this.loading = true;
    this.thryveService.refresh()
      .subscribe(
        (data: StringResult) => {
          if (this.stringResult.error) {
            this.lastRefreshDate = null;
            this.loading = false;
          } else {
            this.loadDataSource();
            this.stringResult.result = data.result;
            this.lastRefreshDate = this.stringResult.result;
          }
        }
      );
  }

  loadDataSource() {
    this.patientService.getBloodOxygenList(this.patientId, this.startDate.toDateString(), this.endDate.toDateString(), true).then(
      result => {
        this.dataSource = <BloodOxygen[]>result;
        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });

  }
  clickEdit(index) {

    this.editItem = <BloodOxygen>{};
    this.editItem = this.simpleClone(this.dataSource[index]);
    this.editIndex = index;

    //this.selectedIndex = index; 
    //ez akkor kell, ha dátum ellenőrzés van a formon
    //át kell alakítani dátumra, másképp hibára fut az ellenőrzés
    this.editItem.measurementDate = new Date(this.editItem.measurementDate);
    this.modalRef = this.modalService.show(this.editMeasurement, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  clickNew() {
    this.editItem = <BloodOxygen>{};
    this.editItem.patientId = this.patientId;
    this.editItem.measurementDate = new Date();
    this.addMode = true;
    this.modalRef = this.modalService.show(this.newMeasurement, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  clickDelete(index) {
    const that = this;
    this.confirmDialogService.confirmThis(this.translation.translate('Core.ConfirmDeleting'), function () {
      if (that.dataSource[index].id) {
        that.patientService.deleteBloodOxygen(that.patientId, that.dataSource[index].id).then(
          result => {
            if (result == "OK") {
              that.dataSource.splice(index, 1);
            } else {
              that.error = result;
            }
          }
        ).catch(err => {
          that.error = err;
        });
      }
    }, function () {
      //alert("No clicked");  
    })
  }

  closeModal() {
    this.modalRef.hide();
    if (this.addMode) {
      //Új bevitelt szakítunk meg
      this.addMode = false;
    }
  }
  onFormSubmit = function (e, index) {
    this.error = "";
    this.loading = true;
    //timezone miatt korrigálni kell
    if (this.editItem.measurementDate) this.editItem.measurementDate.setMinutes(this.editItem.measurementDate.getMinutes() - this.editItem.measurementDate.getTimezoneOffset())

    if (!this.addMode) {
      //módosítás
      this.dataSource[this.editIndex] = this.simpleClone(this.editItem);

      this.patientService.updateBloodOxygen(this.patientId, JSON.stringify(this.dataSource[this.editIndex])).then(
        result => {
          if (result == "OK") {
            notify({
              message: "Sikeres mentés!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.loading = false;
            this.endDate = new Date(this.endDate.getTime() + 100);
            this.closeModal();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    } else {
      //új
      this.patientService.addBloodOxygen(JSON.stringify(this.editItem)).then(
        result => {
          if (result == "OK") {
            notify({
              message: "Sikeres mentés!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);
            this.addMode = false;
            this.endDate = new Date(this.endDate.getTime() + 100);
            this.closeModal();
            this.loadDataSource();
          } else {
            this.error = result;
            this.loading = false;
          }
        }
      ).catch(err => {
        this.error = err;
        this.loading = false;
      });
    }
    //let result: any;

    e.preventDefault();
  }

  simpleClone(obj: any) {
    return Object.assign({}, obj);
  }
}
