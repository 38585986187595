import { Component, OnInit, Inject, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';

import { formatMessage, loadMessages, locale } from 'devextreme/localization';
import messagesHu from 'devextreme/localization/messages/hu.json';
import messagesEs from 'node_modules/devextreme/localization/messages/es.json';

import { DxFormComponent } from 'devextreme-angular';

import notify from 'devextreme/ui/notify';


import { AuthenticationService } from '../services/authentication.service';
import {
  ResetPasswordViewModel,
  AccountFunc
} from '../services/webapiclient.service';
import { Subscription } from 'rxjs';

/**
 * Új jelszó megadása.
 * Levélben elküldött link alapján éri el a felhasználó.
 * Előzőleg az elfelejtett jelszóval igényelt.
 */


@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  resetPasswordViewModel: ResetPasswordViewModel = {} as ResetPasswordViewModel;

  userId: string;
  code: string;

  @ViewChild(DxFormComponent, { static: false }) form: DxFormComponent
  password = "";
  passwordOptions: any = {
    mode: "password",
    value: this.password
  };

  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private accountFunc: AccountFunc,
    private translation: L10nTranslationService

  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    const esMessages = JSON.stringify(messagesEs['es']);
    loadMessages({
      'hu': JSON.parse(huMessages),
      'es': JSON.parse(esMessages),
    });

  }
  buttonOptions: any = {
    text: this.translation.translate('Core.EditSave'),
    type: "success",
    useSubmitBehavior: true
  }
  passwordComparison = () => {
    return this.form.instance.option("formData").Password;
  };
  checkComparison() {
    return true;
  }

  ngOnInit() {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });

    this.route.queryParams
      .subscribe(params => {
        this.code = params.code;
        this.userId = params.userId;
      }
      );

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }
  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  onFormSubmit = function (e) {
    this.error = "";
    this.loading = true;
    this.resetPasswordViewModel.Code = this.code;
    this.resetPasswordViewModel.Id = this.userId;

    let result: any;
    return new Promise((resolve, reject) => {
      this.accountFunc.resetPassword(JSON.stringify(this.resetPasswordViewModel)).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.error = e;
          this.loading = false;
          reject(e);
        },
        () => {
          if (result.body == "OK") {
            notify({
              message: "Sikeres jelszó változtatás!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);

            this.router.navigate(['/login']);
            resolve("OK");
          } else {
            this.error = result.body;
            this.loading = false;
            resolve(result.body);
            //reject(this.translation.translate('Core.' + result.errors[0].code));
          }
        }
      );
    });

  }

}