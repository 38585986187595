import { Component, OnInit, Inject, OnDestroy, Input } from '@angular/core';
import {
  CodesEditModel,
  CodeWarehouseEditModel,
  CodesFunc,
  SelectList,
  WarehouseDetailEditModel,
  CodeCombo
} from '../../services/codes.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';

import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { formatMessage, loadMessages, locale } from 'devextreme/localization';

import { ErrorService } from '../../services/error.service';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs/internal/Subscription';

/**
 * Tesztlapok összeállítása.
 */

@Component({
  selector: 'app-task-sheet',
  templateUrl: './task-sheet.component.html',
  styleUrls: ['./task-sheet.component.scss']
})
export class TaskSheetComponent implements OnInit, OnDestroy {

  @Input() providerId: number;

  codesList: CodeWarehouseEditModel[] = {} as CodeWarehouseEditModel[];
  selectList: CodeCombo[] = {} as CodeCombo[];
  codeDetails: WarehouseDetailEditModel[] = {} as WarehouseDetailEditModel[];
  error: string;
  baseUri: string = `${environment.apiUrl}`;
  dataSource: any;
  detailDataSource: any;
  parentCodeData: any;
  parentCodeDetail: any;
  timeSheetWebId: string;

  gridInstance: any;

  /**
   * A nyelvválasztó feliratkozáshoz.
   */
  private translSubs$: Subscription;


  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private codesFunc: CodesFunc,
    private errorService: ErrorService,
    private translation: L10nTranslationService,
    private http: HttpClient
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

    //Codes adatforrás a gridhez (master)
    this.dataSource = new CustomStore({
      key: "id",
      load: () => this.getAll("CognitiveTest"),
      totalCount: (opts) => {
        return new Promise((resolve, reject) => {
          resolve(1);
        });
      },
      insert: (values) => this.insertTaskSheet(JSON.stringify(values)),
      remove: (key) => this.deleteCodeDetail(key).then()
    });

    //CodesWarehouse adatforrás a gridhez (details)
    this.detailDataSource = new CustomStore({
      key: ["taskSheetId", "taskId"],
      load: () => this.getDetails(this.timeSheetWebId),
      insert: (values) => this.insertCodeDetail(JSON.stringify(values)),
      remove: (key) => this.deleteCodeDetail(key).then()
    });

    //Game codewarehouse adatok (task)
    this.parentCodeDetail = new CustomStore({
      key: "codeName",
      loadMode: "raw",
      cacheRawData: false,
      load: () => this.getParentCodeDetail("Games")
    });

  }


  ngOnInit(): void {

    // Feliratkozás a nyelv változtatására.
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });

  }
  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  //grid detail template adatforrása
  getDetailsDS(key) {
    this.timeSheetWebId = key;
    return this.detailDataSource;
  }

  getParentCodeDetail(codeName: string) {
    return new Promise((resolve, reject) => {
      this.codesFunc.getTaskCombo(codeName).pipe(first()).subscribe(
        (p) => {
          this.selectList = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.selectList);
        }
      );
    });
  }

  onRowExpanding(e) {
    e.component.collapseAll(-1);
  }

  //módosításnál a kódnév nem módosítható
  editorPreparing(e) {
    if (e.parentType === "dataRow" && e.dataField !== "codeName" && !e.row.isNewRow) {
      return;
    }
    if (e.row && !e.row.isNewRow) {
      e.editorOptions.disabled = true;
    }

  }

  editorPrepared(e) {
    if (e.parentType === "dataRow" && e.dataField !== "codeName" && e.dataField !== "codeNameDefault" && !e.row.isNewRow) {
      return;
    }
    if (e.row && !e.row.isNewRow && e.dataField === "codeName") {
      e.editorElement.getElementsByTagName('input')[0].style.background = "#fbe8b6" //"#E0E0E0"; //A Kód nem módosítható, ezért a háttér szürke
    } else if (e.row && !e.row.isNewRow && e.dataField === "codeNameDefault") {
      setTimeout(function () {
        // A Kód nem módosítható, ezért a szöveg mező lesz az aktív
        e.component.focus(e.editorElement);
      }, 100);
    }
  }

  onContentReady_code(e) {
    this.moveEditColumnToLeft(e.component); // A command column bal oldalon legyen. 
  }

  moveEditColumnToLeft(dataGrid) {
    dataGrid.columnOption("command:edit", {
      visibleIndex: -1
    });
  }

  onInitNewRow_detail(e, parent) {
    e.data.TaskSheetId = parent;
  }

  saveGridInstance(e) {
    this.gridInstance = e.component;
    //this.gridInstance.addRow();
    //this.gridInstance.refresh();
    // let dataGridDataSource = this.dataGridInstance.getDataSource();
    //  dataGridDataSource.load();
  }


  //Master part

  getAll(codeName: string) {
    return new Promise((resolve, reject) => {
      this.codesFunc.getCodeDetails(codeName).pipe(first()).subscribe(
        (p) => {
          this.codesList = p;
          if (this.providerId) {
            this.codesList = this.codesList.filter(f => f.providerId == this.providerId);
          } else {
            this.codesList = this.codesList.filter(f => f.providerId == null);
          }
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.codesList);
        }
      );
    });
  }

  //Details part

  getDetails(timeSheetWebId: string) {
    return new Promise((resolve, reject) => {
      this.codesFunc.getWarehouseDetails(timeSheetWebId).pipe(first()).subscribe(
        (p) => {
          this.codeDetails = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.codeDetails);
        }
      );
    });
  }


  insertTaskSheet(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.codesFunc.addNewTaskSheet(values, this.providerId).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  insertCodeDetail(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.codesFunc.insertWarehouseDetail(values).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(result.body);
          }
        }
      );
    });
  }

  deleteCodeDetail(key: any) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.codesFunc.deleteWarehouseDetail(key.taskSheetId, key.taskId).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.body === "OK") {
            resolve("OK");
          } else {
            reject(this.translation.translate('Core.ExceptionDeleteIsNotPossible'));
          }
        }
      );
    });
  }

  clickNew() {
  }

}
