import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { L10nLocale, L10N_LOCALE } from 'angular-l10n';

@Component({
  selector: 'app-not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss']
})
export class NotAuthorizedComponent implements OnInit{

  constructor(private router: Router,
             @Inject(L10N_LOCALE) public locale: L10nLocale,
            )
   { }

  ngOnInit(): void {
  }

  DeletelastVisitedPage() {
    sessionStorage.setItem('lastVisitedPage', null);
    sessionStorage.setItem('lastVisitedPage', null);
    this.router.navigate(['./']);
  }
}
