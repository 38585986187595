// Referencia tartományok megnevezései

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'bitConversion',
    pure: true
})
export class BitConversionPipe implements PipeTransform {

    constructor(
        ) {}    

    transform(value?: number): string {
        if (value == null) {
            return "n.a.";
        }
        let ret = "Project.Negative";
        if(value == 1) ret = "Project.Positive";
        return ret;
    }
}