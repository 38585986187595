<div class="login-screen h-100">
    <div class="container-narrow">
        <h2 class="fw-bold text-white pt-3 mb-1">{{ 'Core.WelcomeLogIn1' | translate:locale.language }}</h2>
        <h2 class="fw-bold text-white">{{ 'Core.WelcomeLogIn2' | translate:locale.language }}</h2>

        <!-- <div class="alert alert-info">
            Username: test01<br /> Password: test
        </div> -->
     
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <div class="form-group">
                <label class="form-label" for="username">{{ 'Core.Entity_UserUserName' |
                    translate:locale.language }}</label>
                <input type="text" formControlName="username" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">{{ 'Core.Exception_Validation_Required_Dx' |
                        translate:locale.language }}</div>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label" for="password">{{ 'Core.Entity_Password' | translate:locale.language
                    }}</label>
                <input type="password" formControlName="password" class="form-control"
                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">{{ 'Core.Exception_Validation_Required_Dx' |
                        translate:locale.language }}</div>
                </div>
            </div>
            <div *ngIf="needAcceptance" class="mb-3">
                <p class="mb-3">
                    {{'Core.AcceptTerms' | translate:locale.language}} <br>
                    <a href="javascript:void(0);" (click)="getTermsInfo()">{{'Core.EndUserLicenseAgreement' |
                        translate:locale.language}}</a>.
                </p>
                <label for="acceptConditions">{{ 'Core.AgreeTerms' | translate:locale.language }}</label>
                <!-- <input type="checkbox" formControlName="acceptConditions" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.acceptConditions.errors }" /> -->
                <input formControlName="acceptConditions" type="checkbox" class="ms-2 form-check-input" />
                <div *ngIf="submitted && f.acceptConditions.errors" class="invalid-feedback">
                    <!-- <div *ngIf="f.acceptConditions.errors.required">{{ 'Core.Exception_Validation_Required_Dx' | translate:locale.language }}</div> -->
                </div>

            </div>

            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
            <div class="d-flex justify-content-between">
                <div>
                    <label for="rememberMe">{{ 'Subscription.RememberMe' | translate:locale.language }}</label>
                    <input id="rememberMe" type="checkbox" class="ms-2 form-check-input" />
                </div>
                <button type="reset" [disabled]="loading" (click)="resetPassword()" class="link">
                    {{ 'Core.Account_Forgotten_Password' | translate:locale.language }}
                </button>
            </div>
            <div>
                <!-- <button class="btn btn-neutral google-login-btn me-2" type="reset" [disabled]="loading" (click)="launchGoogleLogin()">
                    <i class="bi bi-google"></i>
                    {{ 'Core.GoogleMenuLogon' | translate:locale.language }}
                </button>                        
                <button [disabled]="loading" style="background-color:#1877f2 !important" type="reset" (click)="launchFbLogin()" class="btn btn-primary text-end me-2">
                    <i class="bi bi-facebook"></i> 
                    {{ 'Core.FbMenuLogon' | translate:locale.language }}
                </button> -->
                <button [disabled]="loading" class="btn btn-lg btn-primary my-3 w-100">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{ 'Core.MenuLogon' | translate:locale.language }}
                </button>
            </div>
            <div class="text-center">
                <span class="me-3">{{ 'Subscription.NotYetUser' | translate:locale.language }} </span>
                <button type="reset" [disabled]="loading" (click)="registration()" class="link">
                    {{ 'Core.Registration' | translate:locale.language }}
                </button>
            </div>
        </form>
    </div>

    <!-- ÁSZF Infó -->
    <ng-template #termInfo>
        <div class="modal-header">
            <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
            </button>
        </div>
        <div innerHTML="{{actualTerms.terms}}" class="modal-body"></div>
        <div class="modal-footer">
            <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
                {{ 'Core.Close' | translate:locale.language }}
            </button>
        </div>
    </ng-template>

    <!-- Új jelszó igénylése belépési név alapján -->
    <ng-template #resetPasswordtmpl>
        <div class="modal-header">
            <h4>{{ 'Core.Account_New_Password' | translate:locale.language }}</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()"
                aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form (ngSubmit)="resetPasswordSubmit(userNameInput.value)">
                <label class="control-label" for="userName">{{ 'Core.EnterYourUserName' | translate:locale.language
                    }}</label>
                <input class="form-control form-control-sm mb-2" #userNameInput="ngModel" id="userName" name="userName"
                    [(ngModel)]="userName"
                    [ngClass]="userNameInput.invalid && (userNameInput.dirty || userNameInput.touched) ? 'inputError' : ''"
                    required>


                <div class="w-100 text-end">
                    <button [disabled]="modalLoading" type="submit" class="btn btn-primary mb-2 me-2">
                        <span *ngIf="modalLoading" class="spinner-border spinner-border-sm mr-1"></span>
                        {{ 'Core.Account_New_Password' | translate:locale.language }}
                    </button>
                    <button type="button" class="btn btn-neutral mb-2" (click)="closeModal()">{{ 'Core.EditCancel' |
                        translate:locale.language }}
                    </button>
                </div>
            </form>
            <div *ngIf="modalError" class="alert alert-danger mt-3 mb-0">{{modalError | translate:locale.language}}
            </div>
        </div>

    </ng-template>

    <!-- Tájékoztató a levél elküldéséről a jelszóváltoztatáshoz -->
    <ng-template #resetPasswordConfirmtmpl>
        <div class="modal-header">
            <h4>{{ 'Core.Account_New_Password' | translate:locale.language }}</h4>
            <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()"
                aria-label="Close"></button>
        </div>
        <div class="modal-body col-sm-12">
            <p>
                {{ 'Core.PleaseCheckEmail' | translate:locale.language }}
            </p>
            <hr>

            <div class="form-group">
                <button type="button" class="btn btn-primary mb-2" (click)="closeModal()">{{ 'Core.EditClose' |
                    translate:locale.language }}
                </button>
            </div>
        </div>

    </ng-template>
</div>