<div *ngIf="!selectNew" class="container">
    <div class="m-3">
        <div class="d-md-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <div class="general-icons-sm me-2">
                    <img src="../../../assets/images/health-data.svg">
                </div>
                <h1 *ngIf="doctorUser" class="display-6 m-0">{{ 'Project.ActualConditions' | translate:locale.language }}</h1>
                <h1 *ngIf="!doctorUser" class="display-6 m-0">{{ 'Project.HealthData' | translate:locale.language }}</h1>
            </div>
            <div>
                <button class="btn btn-success" (click)="selectionScreen()"><i class="bi bi-plus"></i>
                    {{ 'Project.NewAdmission' | translate:locale.language }}</button>
            </div>
        </div>
        <hr>
        <div *ngIf="dataSource && dataSource.length==0" class="blockquote mb-3">
            <span *ngIf="doctorUser">{{ 'Project.NoConditions' | translate:locale.language }}</span>
            <span *ngIf="!doctorUser">{{ 'Project.NoHealthData' | translate:locale.language }}</span>
        </div>
    </div>
    <div class="m-3" *ngFor="let state of actualState; index as i">
        <!--Észlelt memória tünetek-->
        <div *ngIf="state.code == 'PerceivedMemorySymptoms'" class="card">
            <div class="card-body overflow">
                <div class="d-md-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <div class="health-icons-sm posfix-05">
                            <img src="../../../assets/images/health-memory.svg">
                        </div>
                        <h4 class="m-0">{{ 'Project.MemoryDisorders' | translate:locale.language }}</h4>
                    </div>
                    <div>
                        <button class="btn btn-success circle m-2" (click)="newAnamnesis('PerceivedMemorySymptoms')">
                            <h3 class="m-0"><i class="bi bi-plus"></i>
                            </h3>
                        </button>
                    </div>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 250px;">{{ 'Project.Symptom' | translate:locale.language }}</th>
                            <th style="min-width: 120px;">{{ 'Project.RecognitionDate' | translate:locale.language }}</th>
                            <th style="width: 300px;">{{ 'Project.FrequencyOfSymptoms' | translate:locale.language }}</th>
                            <th>{{ 'Core.EntityNote' | translate:locale.language }}</th>
                            <th style="width: 104px;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let detail of state.details; index as x">
                            <tr>
                                <td>{{detail.codeDescription}}</td>
                                <td>{{detail.startDate | date: dateFormat}}</td>
                                <td>{{detail.frequencyDescription}}</td>
                                <td>
                                    <ng-container *ngIf="detail.comment >''">
                                        <button class="btn btn-light circle"
                                            title="{{ 'Core.EntityNote' | translate:locale.language }}"
                                            data-bs-toggle="collapse" [attr.data-bs-target]="'#c'+detail.id"
                                            aria-expanded="false" aria-controls="notes">
                                            <i class="bi bi-three-dots"></i>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="!detail.comment >''">
                                        <h5><i class="bi bi-dash"></i></h5>
                                    </ng-container>
                                </td>
                                <td class="text-end">
                                    <button class="btn btn-primary circle" (click)="clickEdit(state.code,detail.id)"
                                        title="{{ 'Core.EditEdit' | translate:locale.language }}">
                                        <i class="bi bi-pencil"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="collapse" id="c{{detail.id}}" colspan="5" style="background-color: #dee2e6">
                                    <span>{{detail.comment}}</span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
        <!--Ismert betegségek-->
        <div *ngIf="state.code == 'KnownDiseases'" class="card">
            <div class="card-body overflow">
                <div class="d-md-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <div class="health-icons-sm posfix-05">
                            <img src="../../../assets/images/health-diseases.svg">
                        </div>
                        <h4 class="m-0">{{ 'Project.KnownDiseases' | translate:locale.language }}</h4>
                    </div>
                    <div>
                        <button class="btn btn-success circle m-2" (click)="newAnamnesis('KnownDiseases')">
                            <h3 class="m-0"><i class="bi bi-plus"></i>
                            </h3>
                        </button>
                    </div>
                </div>
                <table class="table ">
                    <thead>
                        <tr>
                            <th style="width: 250px;">{{ 'Project.Disease' | translate:locale.language }}</th>
                            <th style="min-width: 120px;">{{ 'Project.RecognitionDate' | translate:locale.language }}</th>
                            <th style="width: 300px;">{{ 'Project.Medications' | translate:locale.language }}</th>
                            <th>{{ 'Core.EntityNote' | translate:locale.language }}</th>
                            <th class="edit-col"></th>
                            <th class="edit-col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let detail of state.details; index as x">
                            <tr>
                                <td>{{detail.codeDescription}}</td>
                                <td>{{detail.startDate | date: dateFormat}}</td>
                                <td>
                                    <ng-container *ngIf="detail.details">
                                        <ng-container *ngFor="let drug of detail.details; index as y">
                                            <span class="text-primary pointer" (click)="clickEditDrug(detail.id, drug.id)">
                                                {{drug.codeDescription}} <i class="bi bi-info-circle-fill "></i>
                                            </span><br>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="!detail.details">
                                        <h5><i class="bi bi-dash"></i></h5>
                                    </ng-container>
                                </td>
                                <td>
                                    <ng-container *ngIf="detail.comment >''">
                                        <button class="btn btn-light circle"
                                            title="{{ 'Core.EntityNote' | translate:locale.language }}"
                                            data-bs-toggle="collapse" [attr.data-bs-target]="'#c'+detail.id"
                                            aria-expanded="false" aria-controls="notes">
                                            <i class="bi bi-three-dots"></i>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="!detail.comment >''">
                                        <h5><i class="bi bi-dash"></i></h5>
                                    </ng-container>
                                </td>
                                <td>
                                    <button class="btn btn-success circle" (click)="newMedication('Drug', detail.id)"
                                        title="{{ 'Project.NewMedication' | translate:locale.language }}">
                                        <i class="bi bi-capsule-pill"></i>
                                    </button>
                                </td>
                                <td>
                                    <button class="btn btn-primary circle" (click)="clickEdit(state.code,detail.id)"
                                        title="{{ 'Core.EditEdit' | translate:locale.language }}">
                                        <i class="bi bi-pencil"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="collapse" id="c{{detail.id}}" colspan="6" style="background-color: #dee2e6">
                                    <span>{{detail.comment}}</span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
        <!--Kávé-->
        <div *ngIf="state.code == 'CoffeeTypes'" class="card">
            <div class="card-body overflow">
                <div class="d-md-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <div class="health-icons-sm posfix-05">
                            <img src="../../../assets/images/health-coffee.svg">
                        </div>
                        <h4 class="m-0">{{ 'Project.CoffeeUse' | translate:locale.language }}</h4>
                    </div>
                    
                    <div>
                        <button class="btn btn-success circle m-2" (click)="newAnamnesis('CoffeeTypes')">
                            <h3 class="m-0"><i class="bi bi-plus"></i>
                            </h3>
                        </button>
                    </div>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 250px;">{{ 'Project.Type' | translate:locale.language }}</th>
                            <th>{{ 'Project.DurationOfConsumption' | translate:locale.language }}</th>
                            <th>{{ 'Project.Regularity' | translate:locale.language }}</th>
                            <th>{{ 'Project.Quantity' | translate:locale.language }}</th>
                            <th>{{ 'Core.EntityNote' | translate:locale.language }}</th>
                            <th class="edit-col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let detail of state.details; index as x">
                            <tr>
                                <td>{{detail.codeDescription}}</td>
                                <td>{{detail.periodInYear}} {{ 'Game.Years' | translate:locale.language }}</td>
                                <td>{{detail.frequencyDescription}}</td>
                                <td>{{detail.quantity}} (dl)</td>

                                <td>
                                    <ng-container *ngIf="detail.comment >''">
                                        <button class="btn btn-light circle"
                                            title="{{ 'Core.EntityNote' | translate:locale.language }}"
                                            data-bs-toggle="collapse" [attr.data-bs-target]="'#c'+detail.id"
                                            aria-expanded="false" aria-controls="notes">
                                            <i class="bi bi-three-dots"></i>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="!detail.comment >''">
                                        <h5><i class="bi bi-dash"></i></h5>
                                    </ng-container>
                                </td>
                                <td>
                                    <button class="btn btn-primary circle" (click)="clickEdit(state.code,detail.id)"
                                        title="{{ 'Core.EditEdit' | translate:locale.language }}">
                                        <i class="bi bi-pencil"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="collapse" id="c{{detail.id}}" colspan="6" style="background-color: #dee2e6">
                                    <span>{{detail.comment}}</span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
        <!--Dohány-->
        <div *ngIf="state.code == 'TobaccoProduct'" class="card">
            <div class="card-body overflow">
                <div class="d-md-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <div class="health-icons-sm posfix-03">
                            <img src="../../../assets/images/health-tobacco.svg">
                        </div>
                        <h4 class="m-0">{{ 'Project.Smoking' | translate:locale.language }}</h4>
                    </div>
                    <div>
                        <button class="btn btn-success circle m-2" (click)="newAnamnesis('TobaccoProduct')">
                            <h3 class="m-0"><i class="bi bi-plus"></i>
                            </h3>
                        </button>
                    </div>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 250px;">{{ 'Project.Type' | translate:locale.language }}</th>
                            <th>{{ 'Project.DurationOfConsumption' | translate:locale.language }}</th>
                            <th>{{ 'Project.Regularity' | translate:locale.language }}</th>
                            <th>{{ 'Project.Quantity' | translate:locale.language }}</th>
                            <th>{{ 'Core.EntityNote' | translate:locale.language }}</th>
                            <th class="edit-col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let detail of state.details; index as x">
                            <tr>
                                <td>{{detail.codeDescription}}</td>
                                <td>{{detail.periodInYear}} {{ 'Game.Years' | translate:locale.language }}</td>
                                <td>{{detail.frequencyDescription}}</td>
                                <td>{{detail.quantity}} ({{ 'Game.Db' | translate:locale.language }})</td>

                                <td>
                                    <ng-container *ngIf="detail.comment >''">
                                        <button class="btn btn-light circle"
                                            title="{{ 'Core.EntityNote' | translate:locale.language }}"
                                            data-bs-toggle="collapse" [attr.data-bs-target]="'#c'+detail.id"
                                            aria-expanded="false" aria-controls="notes">
                                            <i class="bi bi-three-dots"></i>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="!detail.comment >''">
                                        <h5><i class="bi bi-dash"></i></h5>
                                    </ng-container>
                                </td>
                                <td>
                                    <button class="btn btn-primary circle" (click)="clickEdit(state.code,detail.id)"
                                        title="{{ 'Core.EditEdit' | translate:locale.language }}">
                                        <i class="bi bi-pencil"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="collapse" id="c{{detail.id}}" colspan="6" style="background-color: #dee2e6">
                                    <span>{{detail.comment}}</span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
        <!--Alkohol-->
        <div *ngIf="state.code == 'AlcoholProducts'" class="card">
            <div class="card-body overflow">
                <div class="d-md-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <div class="health-icons-sm posfix-05">
                            <img src="../../../assets/images/health-alcohol.svg">
                        </div>
                        <h4 class="m-0">{{ 'Project.Alcohol' | translate:locale.language }}</h4>
                    </div>
                    <div>
                        <button class="btn btn-success circle m-2" (click)="newAnamnesis('AlcoholProducts')">
                            <h3 class="m-0"><i class="bi bi-plus"></i>
                            </h3>
                        </button>
                    </div>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 250px;">{{ 'Project.Type' | translate:locale.language }}</th>
                            <th>{{ 'Project.DurationOfConsumption' | translate:locale.language }}</th>
                            <th>{{ 'Project.Regularity' | translate:locale.language }}</th>
                            <th>{{ 'Project.Quantity' | translate:locale.language }}</th>
                            <th>{{ 'Core.EntityNote' | translate:locale.language }}</th>
                            <th class="edit-col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let detail of state.details; index as x">
                            <tr>
                                <td>{{detail.codeDescription}}</td>
                                <td>{{detail.periodInYear}} {{ 'Game.Years' | translate:locale.language }}</td>
                                <td>{{detail.frequencyDescription}}</td>
                                <td>{{detail.quantity}} (dl)</td>

                                <td>
                                    <ng-container *ngIf="detail.comment >''">
                                        <button class="btn btn-light circle"
                                            title="{{ 'Core.EntityNote' | translate:locale.language }}"
                                            data-bs-toggle="collapse" [attr.data-bs-target]="'#c'+detail.id"
                                            aria-expanded="false" aria-controls="notes">
                                            <i class="bi bi-three-dots"></i>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="!detail.comment >''">
                                        <h5><i class="bi bi-dash"></i></h5>
                                    </ng-container>
                                </td>
                                <td>
                                    <button class="btn btn-primary circle" (click)="clickEdit(state.code,detail.id)"
                                        title="{{ 'Core.EditEdit' | translate:locale.language }}">
                                        <i class="bi bi-pencil"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="collapse" id="c{{detail.id}}" colspan="6" style="background-color: #dee2e6">
                                    <span>{{detail.comment}}</span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
        <!--Droghasználat-->
        <div *ngIf="state.code == 'HarmfulDrugs'" class="card">
            <div class="card-body overflow">
                <div class="d-md-flex justify-content-between align-items-center">
                    <div class="d-flex align-items-center">
                        <div class="health-icons-sm posfix-05">
                            <img src="../../../assets/images/health-drug.svg">
                        </div>
                        <h4 class="m-0">{{ 'Project.DrugUse' | translate:locale.language }}</h4>
                    </div>
                    <div>
                        <button class="btn btn-success circle m-2" (click)="newAnamnesis('HarmfulDrugs')">
                            <h3 class="m-0"><i class="bi bi-plus"></i>
                            </h3>
                        </button>
                    </div>
                </div>
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 250px;">{{ 'Project.Type' | translate:locale.language }}</th>
                            <th>{{ 'Project.DurationOfConsumption' | translate:locale.language }}</th>
                            <th>{{ 'Project.Regularity' | translate:locale.language }}</th>
                            <th>{{ 'Project.Quantity' | translate:locale.language }} </th>
                            <th>{{ 'Core.EntityNote' | translate:locale.language }}</th>
                            <th class="edit-col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let detail of state.details; index as x">
                            <tr>
                                <td>{{detail.codeDescription}}</td>
                                <td>{{detail.periodInYear}} {{ 'Game.Years' | translate:locale.language }}</td>
                                <td>{{detail.frequencyDescription}}</td>
                                <td>{{detail.quantity}} ({{ 'Game.Db' | translate:locale.language }})</td>

                                <td>
                                    <ng-container *ngIf="detail.comment >''">
                                        <button class="btn btn-light circle"
                                            title="{{ 'Core.EntityNote' | translate:locale.language }}"
                                            data-bs-toggle="collapse" [attr.data-bs-target]="'#c'+detail.id"
                                            aria-expanded="false" aria-controls="notes">
                                            <i class="bi bi-three-dots"></i>
                                        </button>
                                    </ng-container>
                                    <ng-container *ngIf="!detail.comment >''">
                                        <h5><i class="bi bi-dash"></i></h5>
                                    </ng-container>

                                </td>
                                <td>
                                    <button class="btn btn-primary circle" (click)="clickEdit(state.code,detail.id)"
                                        title="{{ 'Core.EditEdit' | translate:locale.language }}">
                                        <i class="bi bi-pencil"></i>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td class="collapse" id="c{{detail.id}}" colspan="6" style="background-color: #dee2e6">
                                    <span>{{detail.comment}}</span>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<!-- Új felvételek kiválasztási oldal -->
<div *ngIf="selectNew" class="container">
    <div class="m-3">
        <div class="d-md-flex justify-content-between align-items-center">
            <h1 class="display-6 d-block">{{ 'Project.AddActualConditions' | translate:locale.language }}</h1>
        </div>
        <hr>
        <div class="row row-cols-2 row-cols-lg-3 g-2">
            <div class="col">
                <div class="card h-100 pointer" (click)="newAnamnesis('PerceivedMemorySymptoms')">
                    <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center">
                        <div class="health-icons">
                        <img src="../../../assets/images/health-memory.svg">
                        </div>
                        <h4 class="text-center">{{ 'Project.MemoryDisorders' | translate:locale.language }}</h4>
                    </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card h-100 pointer" (click)="newAnamnesis('KnownDiseases')">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center">
                    <div class="health-icons">
                        <img src="../../../assets/images/health-diseases.svg">
                    </div>
                    <h4 class="text-center">{{ 'Project.KnownDiseases' | translate:locale.language }}</h4>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card h-100 pointer" (click)="newAnamnesis('CoffeeTypes')">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center">
                    <div class="health-icons">
                        <img src="../../../assets/images/health-coffee.svg">
                    </div>
                    <h4 class="text-center">{{ 'Project.CoffeeUse' | translate:locale.language }}</h4>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card h-100 pointer" (click)="newAnamnesis('TobaccoProduct')">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center">
                    <div class="health-icons">
                        <img src="../../../assets/images/health-tobacco.svg">
                    </div>
                    <h4 class="text-center">{{ 'Project.Smoking' | translate:locale.language }}</h4>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card h-100 pointer" (click)="newAnamnesis('AlcoholProducts')">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center">
                    <div class="health-icons">
                        <img src="../../../assets/images/health-alcohol.svg">
                    </div>
                    <h4 class="text-center">{{ 'Project.Alcohol' | translate:locale.language }}</h4>
                    </div>
                </div>
                </div>
            </div>
            <div class="col">
                <div class="card h-100 pointer" (click)="newAnamnesis('HarmfulDrugs')">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center">
                    <div class="health-icons">
                        <img src="../../../assets/images/health-drug.svg">
                    </div>
                    <h4 class="text-center">{{ 'Project.DrugUse' | translate:locale.language }}</h4>
                    </div>
                </div>
                </div>
            </div>
        </div> 
    </div>


</div>

<!-- Új Modal -->
<ng-template #newFormtmpl>
    <div class="modal-header">
        <h5 class="modal-title" id="newMemoryDisorderModalLabel">{{settings.newLabel}}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">
            <div class="mb-3">
                <div class="form-label">{{settings.codeName}}</div>
                <dx-select-box [dataSource]="codesSource" valueExpr="id" stylingMode="filled"
                    displayExpr="descriptionTranslated" [searchEnabled]="true" [(value)]="editItem.codeId">
                    <dx-validator>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dx-validator>
                </dx-select-box>
            </div>
            <ng-container *ngTemplateOutlet="fieldsTemplate"></ng-container>
            <div class="modal-footer">
                <dx-button stylingMode="contained" text="{{ 'Core.EditSave' | translate:locale.language }}"
                    type="default" useSubmitBehavior=true>
                </dx-button>
                <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
                    {{ 'Core.EditCancel' | translate:locale.language }}
                </button>
            </div>
        </form>
    </div>
</ng-template>

<!-- Szerkesztés Modal -->
<ng-template #editFormtmpl>
    <div *ngIf="editItem">
        <div class="modal-header">
            <!--Modal neve az észlelt memóriatünet-->
            <h5 class="modal-title" id="editMemoryDisorderModalLabel">{{ editItem.codeId | codeTranslate:codesSource
                }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
            </button>
        </div>
        <div class="modal-body">
            <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">
                <ng-container *ngTemplateOutlet="fieldsTemplate"></ng-container>
                <div class="modal-footer">
                    <dx-button stylingMode="contained" text="{{ 'Core.EditSave' | translate:locale.language }}"
                        type="default" useSubmitBehavior=true>
                    </dx-button>
                    <button type="button" (click)="clickDelete()" class="btn btn-danger">
                        {{ 'Core.EditDelete' | translate:locale.language }}
                    </button>
                    <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
                        {{ 'Core.EditCancel' | translate:locale.language }}
                    </button>
                </div>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
            </form>
        </div>
    </div>
</ng-template>


<ng-template #fieldsTemplate>
    <div *ngIf=settings.needStartDate class="mb-3">
        <div class="form-label">{{ settings.dateLabel }}</div>
        <dx-date-box type="date" stylingMode="filled" (onFocusOut)="startDateFocusOut($event)"
            (onInitialized)="initStartDate($event)" [(value)]="editItem.startDate">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxi-validation-rule type="range" [max]="maxDate"></dxi-validation-rule>
            </dx-validator>
        </dx-date-box>
    </div>
    <div *ngIf=settings.needStartDateTime class="mb-3">
        <div class="form-label">{{ settings.dateLabel }}</div>
        <dx-date-box type="datetime" stylingMode="filled" (onInitialized)="initStartDate($event)"
            [(value)]="editItem.startDate">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxi-validation-rule type="range" [max]="maxDate"></dxi-validation-rule>
                <dxi-validation-rule type="custom" [validationCallback]="validateStartDate"
                    message="{{ 'Project.StartDateError' | translate:locale.language }}">
                </dxi-validation-rule>
            </dx-validator>
        </dx-date-box>
    </div>
    <div *ngIf=settings.needPeriodInYear class="mb-3">
        <div class="form-label">{{ 'Project.PeriodInYear' | translate:locale.language }}</div>
        <dx-number-box [(value)]="editItem.periodInYear" stylingMode="filled" [showSpinButtons]="true"
            [showClearButton]="false" min=1>
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
        </dx-number-box>
    </div>
    <div *ngIf=settings.needFrequency class="mb-3">
        <div class="form-label">{{ 'Project.FrequencyOfSymptoms' | translate:locale.language }}</div>
        <dx-select-box [dataSource]="frequencySource" valueExpr="id" stylingMode="filled"
            displayExpr="descriptionTranslated" [(value)]="editItem.frequencyId">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
        </dx-select-box>
    </div>
    <div *ngIf=settings.needRegularity class="mb-3">
        <div class="form-label">{{ 'Project.Regularity' | translate:locale.language }}</div>
        <dx-select-box [dataSource]="regularitySource" valueExpr="id" stylingMode="filled"
            displayExpr="descriptionTranslated" [(value)]="editItem.frequencyId">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
        </dx-select-box>
    </div>
    <div *ngIf=settings.needQuantity class="mb-3">
        <div class="form-label">{{ 'Project.Quantity' | translate:locale.language }} ({{ settings.defaultUnit |
            codeTranslate:unitSource }})</div>
        <dx-number-box [(value)]="editItem.quantity" stylingMode="filled" [showSpinButtons]="true"
            [showClearButton]="false" min=1>
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
        </dx-number-box>
    </div>
    <div *ngIf=settings.needResult class="mb-3">
        <div class="form-label">{{ 'Core.Result' | translate:locale.language }} </div>
        <dx-number-box [(value)]="editItem.result" stylingMode="filled" [showSpinButtons]="true"
            [showClearButton]="false">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxi-validation-rule type="range" [min]="0" [max]="maxRange">
                </dxi-validation-rule>
            </dx-validator>
        </dx-number-box>
    </div>
    <div class="mb-3">
        <div class="form-label">{{ 'Core.EntityNote' | translate:locale.language }}</div>
        <dx-text-area [height]="90" stylingMode="filled" [(value)]="editItem.comment" [maxLength]="500">
        </dx-text-area>
    </div>
</ng-template>