<div class="container-narrow">
    <div class="my-3">
        <div class="d-md-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <div class="set-icons-sm mb-3 mb-lg-0 me-2">
                    <img src="../../../assets/images/set-notification.svg">
                </div>
                <h1 class="display-6 d-block m-0">{{ 'Notification.NotifSetting' | translate:locale.language }}</h1>
            </div>
        </div>
        <hr>
        <div class="card">
            <div class="card-body">
            <dx-data-grid
            [dataSource]="categoryDataSource"
            [hoverStateEnabled]="true"
   
            [scrolling]="{ mode: 'infinite' }"
            (onInitialized)="initDataGrid($event)"
            >
                <dxo-paging [enabled]="false"></dxo-paging>
                <dxo-editing mode="cell"
                                [allowUpdating]="true">
                </dxo-editing>

                <dxi-column dataField="message" [allowEditing]="false" caption= "{{ 'Notification.Notification' | translate:locale.language }}" dataType="string" cellTemplate="messageTemplate">
                </dxi-column>
                <dxi-column dataField="type" [width]="200" [allowEditing]="false" caption= "{{ 'Project.Type' | translate:locale.language }}" dataType="string"  cellTemplate="typeTemplate">
                </dxi-column>
                <dxi-column dataField="selected" [width]="100" caption= "{{ 'Core.EditActive' | translate:locale.language }}" dataType="boolean"></dxi-column>

                <div *dxTemplate="let d of 'messageTemplate'" class="text-wrap">
                    {{ d.value | translate:locale.language }}
                </div>
                <div *dxTemplate="let d of 'typeTemplate'">
                    {{ 'Notification.' + d.value | translate:locale.language }}
                </div>
                    
            </dx-data-grid>
            <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    
            <!-- <div  *ngIf="selectedProvider" class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4">
                </div>      
                <div class="card mb-3 h-100">
                    <h4 class="card-header">{{selectedProvider.name}}</h4>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-6 mb-3">
                                {{selectedProvider.postalCode}} <br>
                                {{selectedProvider.city}} <br>
                                {{selectedProvider.address}} <br>
                                
                            </div>
                            <div class="col-sm-6 mb-3">
                                <a href="{{selectedProvider.webSite}}" target="_blank">{{ 'Project.WebUrl' | translate:locale.language }}</a> <br>
                                <a href="{{selectedProvider.mapLink}}" target="_blank">{{ 'Project.MapLink' | translate:locale.language }}</a> <br>
                                <a href="mailto:{{selectedProvider.email}}" target="_blank">{{selectedProvider.email}}</a> <br>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col mb-3">
                                <textarea class="form-control" rows="5">{{selectedProvider.openingHours}}</textarea>
                            </div>
                        </div>
                        <div *ngIf="!doctorUser" class="row">
                            <div class="dx-field">
                                <div class="dx-field-label">{{ 'Project.SelectDoctor' | translate:locale.language }}</div>
                                <div class="dx-field-value">
                                    <dx-select-box [dataSource]="doctorSource"
                                                valueExpr="id"
                                                displayExpr="userName"
                                                 [(value)]="selectedDoctor">
                                        <dx-validator>
                                            <dxi-validation-rule type="required"></dxi-validation-rule>
                                        </dx-validator>
                                    </dx-select-box>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div> -->
            </div>
        </div>
</div>
</div>

<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#reg' }"
    [(visible)]="loadingVisible"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
    (onShown)="onShown()"
    (onHidden)="onHidden()">
</dx-load-panel>
