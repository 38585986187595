import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { loadMessages, locale } from 'devextreme/localization';
import { AuthenticationService } from '../services/authentication.service';
import { HcProviderService } from '../services/hc-provider.service';
import messagesHu from 'devextreme/localization/messages/hu.json';
import { Doctor, HcProviderLink, HealthcareProvider, LoginUser, PatientDataViewModel } from '../services/webapiclient.service';
import CustomStore from 'devextreme/data/custom_store';
//import notify from 'devextreme/ui/notify';
import { Role } from '../models/role';
import { PatientService } from '../module-patient/patient.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormComponent } from '../helpers/nav-preventer';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog-service';

/**
 * Eü. szolgáltató, illetve orvos kiválasztás a felhasználó részéről.
 * Előfeltétel a profil kitöltése.
 * Ha csak szolgáltató van kiválasztva, akkor a szolgáltató összes orvosához elmegy a kérelem
 * Ha van orvos is választva, akkor neki megy a kérelem
 * Kérelem a páciens katalógusban jelenik meg az orvosnak, levél nem megy róla.
 */

@Component({
  selector: 'app-hc-provider-select',
  templateUrl: './hc-provider-select.component.html',
  styleUrls: ['./hc-provider-select.component.scss']
})
export class HcProviderSelectComponent implements OnInit, FormComponent {

  formg: FormGroup;
  hasChanged: boolean = false;

  providersDataSource: any;
  selectedProviders: number[] = [];
  selectedProvider: HealthcareProvider = {} as HealthcareProvider;
  displayedProvider: any;
  displayedDoctor: any;
  providerList: HealthcareProvider[];
  doctorSource: any;
  selectedDoctor: string;
  selectionMode: any;
  currentUser: LoginUser;
  error = '';
  hcProviderLinks: HcProviderLink[] = {} as HcProviderLink[];
  dropDownInstance: any;
  gridInstance: any;
  doctorUser: boolean;
  loading = false;
  needProfileData = false;
  loadingVisible = false;
  termin: string;
  referral: string;
  searchText: string = "";
  filteredCount = { count: 0 };
  pageSize: number = 8;
  actualPageSize: number;

  searchTextDoctor: string = "";
  filteredCountDoctor = { count: 0 };
  pageSizeDoctor: number = 8;
  actualPageSizeDoctor: number;

  provider: any;
  uploadUrl = `${environment.uploadUrl}`;
  blobUrl: string;

  patientDataViewModel: PatientDataViewModel = {} as PatientDataViewModel;

  @ViewChild('statementTmpl', { static: true }) statementTmpl: TemplateRef<any>;
  @ViewChild('providerDetails', { static: true }) providerDetails: TemplateRef<any>;
  @ViewChild('providerDetailsModal', { static: true }) providerDetailsModal: TemplateRef<any>;

  modalRef: BsModalRef;
  private translSubs$: Subscription;

  constructor(
    private hcProviderService: HcProviderService,
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private route: ActivatedRoute,
    private translation: L10nTranslationService,
    private patientService: PatientService,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private confirmDialogService: ConfirmDialogService

  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

    this.providersDataSource = new CustomStore({
      key: "id",
      loadMode: "raw",
      cacheRawData: false,
      load: () => hcProviderService.getAll()
    });

  }

  buttonOptions: any = {
    text: this.translation.translate('Core.EditSave'),
    type: "success",
    useSubmitBehavior: true
  }

  ngOnInit(): void {
    this.actualPageSize = this.pageSize;
    this.actualPageSizeDoctor = this.pageSizeDoctor;
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });

    this.loadProviders();

    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      this.doctorUser = this.currentUser.role == Role.Doctor;
      this.getPatientData(this.currentUser.id);
    }

    // get return url from route parameters or default to '/'
    //this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }
  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  getPatientData(id: string) {
    this.loading = true;
    this.patientService.getPatient(id).then(
      result => {
        this.patientDataViewModel = <PatientDataViewModel>result;
        if (!this.patientDataViewModel.birthDate) this.needProfileData = true;
        this.selectedDoctor = this.patientDataViewModel.doctorId;
        this.loadSelectedProvider(this.patientDataViewModel.providerId, true);
        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });
  }

  loadProviders() {
    this.loading = true;
    this.hcProviderService.getAll().then(
      result => {
        this.providerList = <HealthcareProvider[]>result;
        this.filteredCount.count = this.providerList.length;
        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });

  }
  //Szolgáltató info panel
  onInfoClick(provider) {
    this.provider = provider;
    //Kell-e időpont foglalás, és beutaló szövegek kitöltése
    this.termin = this.translation.translate('Project.AskTermin');
    this.referral = this.translation.translate('Project.ReferralFromGp');
    if (this.provider.needTermin) {
      this.termin = this.termin + " " + this.translation.translate('Project.Need');
    } else {
      this.termin = this.termin + " " + this.translation.translate('Project.NoNeed');
    }
    if (this.provider.needReferral) {
      this.referral = this.referral + " " + this.translation.translate('Project.Need');
    } else {
      this.referral = this.referral + " " + this.translation.translate('Project.NoNeed');
    }
    this.modalRef = this.modalService.show(this.providerDetailsModal, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }
  //Szolgáltató kiválasztás
  onSelect(provider) {
    if (!this.doctorUser) {
      //Ha nem orvos, akkor betöltjük a szolgáltató orvosait
      this.loadDoctorSource(provider.id);
    }

    this.provider = provider;
    this.displayedProvider = provider;
    //Kell-e időpont foglalás, és beutaló szövegek kitöltése
    this.termin = this.translation.translate('Project.AskTermin');
    this.referral = this.translation.translate('Project.ReferralFromGp');
    if (this.provider.needTermin) {
      this.termin = this.termin + " " + this.translation.translate('Project.Need');
    } else {
      this.termin = this.termin + " " + this.translation.translate('Project.NoNeed');
    }
    if (this.provider.needReferral) {
      this.referral = this.referral + " " + this.translation.translate('Project.Need');
    } else {
      this.referral = this.referral + " " + this.translation.translate('Project.NoNeed');
    }
  }
  //Orvos kiválasztás
  onSelectDoctor(doctor) {
    this.displayedDoctor = doctor;
  }

  clickMore() {
    this.actualPageSize = this.actualPageSize + this.pageSize;
  }
  clickMoreDoctor() {
    this.actualPageSizeDoctor = this.actualPageSizeDoctor + this.pageSizeDoctor;
  }

  loadSelectedProvider(providerId, init) {
    if (providerId) {
      this.hcProviderService.get(providerId).then(
        result => {
          this.selectedProvider = <HealthcareProvider>result;
          this.displayedProvider = this.selectedProvider;
          this.provider = this.selectedProvider;
          if (!this.doctorUser) {
            //Ha nem orvos, akkor betöltjük a szolgáltató orvosait
            this.loadDoctorSource(this.selectedProvider.id);
          }
          //Kell-e időpont foglalás, és beutaló szövegek kitöltése
          this.termin = this.translation.translate('Project.AskTermin');
          this.referral = this.translation.translate('Project.ReferralFromGp');
          if (this.selectedProvider.needTermin) {
            this.termin = this.termin + " " + this.translation.translate('Project.Need');
          } else {
            this.termin = this.termin + " " + this.translation.translate('Project.NoNeed');
          }
          if (this.selectedProvider.needReferral) {
            this.referral = this.referral + " " + this.translation.translate('Project.Need');
          } else {
            this.referral = this.referral + " " + this.translation.translate('Project.NoNeed');
          }
        }
      ).catch(err => {
        this.error = err;
      });

    } else {
      this.selectedProvider = null;
      this.selectedDoctor = null;
      this.onCancel();
    }
  }

  loadDoctorSource(providerId) {
    this.hcProviderService.getDoctorsByProvider(providerId).then(
      result => {
        this.doctorSource = <Doctor[]>result;
        this.filteredCountDoctor.count = this.doctorSource.length;
        if (this.selectedDoctor != null) {
          this.displayedDoctor = this.doctorSource.filter(f => f.id == this.selectedDoctor)[0];
        }
      }
    ).catch(err => {
      this.error = err;
    });
  }

  onCancel() {
    this.displayedDoctor = null;
    if (!this.selectedProvider) {
      this.displayedProvider = null;
    }
  }

  onChangeProvider() {
    this.selectedProvider = null;
    this.provider = null;
    this.displayedProvider = null;
    this.onChangeDoctor();
  }

  onChangeDoctor() {
    this.selectedDoctor = null;
    this.displayedDoctor = null;
  }

  onDeleteProvider() {
    const that = this;
    this.confirmDialogService.confirmThis(this.translation.translate('Project.DeleteProviderConfirm'), function () {
      that.selectedProvider = null;
      that.provider = null;
      that.displayedProvider = null;
      that.onChangeDoctor();
      that.onSave();
    }, function () {
      //that.showUploadedDocument = true;
    })

  }

  onValueChanged(e) {
    this.hasChanged = true;
    this.loadSelectedProvider(e.value, false);
    this.dropDownInstance.close();
  }

  onSave() {
    this.loadingVisible = true;
    if (this.displayedDoctor) {
      this.selectedDoctor = this.displayedDoctor.id;
    } else {
      this.selectedDoctor = null;
    }
    this.selectedProvider = this.displayedProvider;
    this.patientDataViewModel.PatientDataId = this.currentUser.id;
    this.patientDataViewModel.doctorId = this.selectedDoctor;
    if (this.selectedProvider) {
      this.patientDataViewModel.providerId = this.selectedProvider.id.toString();
    } else {
      this.patientDataViewModel.providerId = null;
    }

    //let result: any;
    this.patientService.saveDoctorAndProviderId(this.currentUser.id, JSON.stringify(this.patientDataViewModel)).then(
      result => {
        if (result == "OK") {
        }
        this.loadingVisible = false;
        this.hasChanged = false;
      }
    ).catch(err => {
      this.error = err;
      this.loadingVisible = false;
    });

  }

  onFormSubmit(e) {
    this.loadingVisible = true;
    this.patientDataViewModel.PatientDataId = this.currentUser.id;
    this.patientDataViewModel.doctorId = this.selectedDoctor;
    if (this.selectedProviders) {
      this.patientDataViewModel.providerId = this.selectedProviders[0].toString();
    } else {
      this.patientDataViewModel.providerId = null;
    }

    //let result: any;
    this.patientService.saveDoctorAndProviderId(this.currentUser.id, JSON.stringify(this.patientDataViewModel)).then(
      result => {
        if (result == "OK") {
        }
        this.loadingVisible = false;
        this.hasChanged = false;
      }
    ).catch(err => {
      this.error = err;
      this.loadingVisible = false;
    });
  }

  initDropDown(e) {
    this.dropDownInstance = e.component;
  }

  showStatement() {
    this.modalRef = this.modalService.show(this.statementTmpl, { backdrop: 'static', keyboard: false });
  }

  closeModal() {
    this.modalRef.hide();
  }

  onShown() {
    setTimeout(() => {
      this.loadingVisible = false;
    }, 3000);
  }

  onHidden() {
    //this.employeeInfo = this.employee;
  }

}

