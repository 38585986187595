import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

import { AuthenticationService } from '../services/authentication.service';
import { getTimeZoneOffset } from '../shared/utils';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
   constructor(
      private authenticationService: AuthenticationService,
      @Inject(L10N_LOCALE) public locale: L10nLocale
      ) { }

   intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // add authorization header with jwt token if available
      const currentUser = this.authenticationService.currentUserValue;
      if (currentUser && currentUser.token) {
         request = request.clone({
            setHeaders: {
               Authorization: `Bearer ${currentUser.token}`
            }
         });
      }

      // Set the language
      request = request.clone({
         setHeaders: {
            "Content-Language" : this.locale.language
         }
      });

       // Set CORS
       request = request.clone({
         setHeaders: {
            "Access-Control-Allow-Origin":'*'
         }
      });

       // Time zone
       request = request.clone({
         setHeaders: {
            "tz-offset": getTimeZoneOffset()
         }
      });

      return next.handle(request);
   }
}
