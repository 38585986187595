<div class="container">
    <div *ngIf="!this.inpPatientId" class="m-3">
        <div class="d-md-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <div class="general-icons-sm me-2">
                    <img src="../../../assets/images/personal-data.svg">
                </div>
                <h1 class="display-6 m-0">{{ 'Core.PersonalData' | translate:locale.language }}</h1>
            </div>
            <div class="mt-2 mt-md-0">
                <button type="button" class="btn btn-primary" (click)="clickEdit()">
                    <i class="bi bi-pencil"></i> {{ 'Core.EditEdit' | translate:locale.language }}
                </button>
            </div>
        </div>
        <hr>
    </div>
    <div class="card m-3 border-0">
        <div class="card-body d-md-flex justify-content-between align-items-center">
            <div class="p-4">
                <div class="avatar-image-lg" [ngStyle]="{backgroundImage: 'url(' + blobUrl + ')'}"></div>
            </div>
            <div class="flex-grow-1">
                <div class="d-flex justify-content-between align-items-start">
                    <div>
                        <div class="my-2 h3 fw-light">{{patientDataViewModel.displayName}}</div>
                        <div><strong><i class="bi bi-envelope-fill"></i> {{ 'Core.Account_Register_Email' | translate:locale.language }}:</strong> {{patientDataViewModel.email}}</div>
                        <div><strong><i class="bi bi-telephone-fill"></i> {{ 'Core.Entity_ContactPhone' | translate:locale.language }}:</strong> {{patientDataViewModel.phone }}</div>
                    </div>
                    
                    <div *ngIf="this.inpPatientId" class="m-3">
                        <div class="d-flex justify-content-between align-items-center">
                            <h1 class="display-6 d-block"></h1>
                                <div *ngIf="!gpUser">
                                <button type="button" class="btn btn-primary" (click)="clickEdit()">
                                    <i class="bi bi-pencil"></i> {{ 'Core.EditEdit' | translate:locale.language }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div *ngIf="!(doctorUser || gpUser)" class="d-md-flex">
                    <div class="col-md-6">
                        <div><strong>{{ 'Project.TAJ' | translate:locale.language }}:</strong> {{patientDataViewModel.taj}}</div>
                        <div><strong>{{ 'Project.BirthDate' | translate:locale.language }}:</strong> {{patientDataViewModel.birthDate | date: dateFormat}}</div>
                        <div><strong>{{ 'Project.Sex' | translate:locale.language }}:</strong> {{patientDataViewModel.sexCodeId | codeTranslate:sexCodes}}</div>
                    </div>
                    <div class="col-md-6">
                        <div><strong>{{ 'Project.HighestEducation' | translate:locale.language }}:</strong>
                            {{patientDataViewModel.highestEducationId | codeTranslate:educationCodes}}</div>
                        <div><strong>{{ 'Project.ClinicalFamilyHistory' | translate:locale.language }}:</strong>
                            {{patientDataViewModel.familyHistoryId | codeTranslate:familyHistoryCodes}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Szerkesztés Modal -->
<ng-template #editFormtmpl>
    <div *ngIf="editItem">
        <div class="modal-header">
            <h4 *ngIf="init=='NO'" class="modal-title">{{ 'Project.PersonelData' | translate:locale.language }}</h4>
            <h4 *ngIf="init=='YES'" class="modal-title">{{ 'Project.AskPersonelData' | translate:locale.language }}</h4>
            <button *ngIf="init=='NO'" type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()"
                aria-label="Close">
            </button>
        </div>
        <div class="modal-body">
            <form id="myForm" action="your-action" (submit)="onFormSubmit($event)">
                <div class="row">
                    <div class="col-md-6 mb-3 align-self-end">
                        <div>
                            <div class="form-label">{{ 'Core.Entity_UserName' | translate:locale.language }}
                            </div>
                            <dx-text-box type="string" [stylingMode]="stylingMode" (onChange)="onChange($event)"
                                [(value)]="editItem.displayName">
                                <dx-validator>
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                    </div>
                    <div *ngIf="this.inpPatientId && !doctorUser" class="col-md-6 mb-3 align-self-end">
                        <div>
                            <div class="form-label">{{ 'Project.TAJ' | translate:locale.language }}
                            </div>
                            <dx-text-box *ngIf="locale.language.toLowerCase() == 'hu-hu'" type="string" [stylingMode]="stylingMode" (onChange)="onChange($event)"
                                [(value)]="editItem.taj" mask="000 000 000" [useMaskedValue]="true">
                                <dx-validator>
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                    <dxi-validation-rule type="custom" [validationCallback]="validateTaj"
                                        message="{{ 'Project.InvalidTaj' | translate:locale.language }}">
                                    </dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                            <dx-text-box *ngIf="locale.language.toLowerCase() != 'hu-hu'" type="string" [stylingMode]="stylingMode" (onChange)="onChange($event)"
                                [(value)]="editItem.taj" mask="000 000 0000" [useMaskedValue]="true">
                                <dx-validator>
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                    <dxi-validation-rule type="custom" [validationCallback]="validateTaj"
                                        message="{{ 'Project.InvalidTaj' | translate:locale.language }}">
                                    </dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3 align-self-end">
                        <div>
                            <div class="form-label">{{ 'Core.Account_Register_Email' | translate:locale.language }}
                            </div>
                            <dx-text-box type="string" [stylingMode]="stylingMode" (onChange)="onChange($event)"
                                [(value)]="editItem.email">
                                <dx-validator>
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                    <dxi-validation-rule type="email"></dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>
                        </div>
                    </div>
                    <div *ngIf="!doctorUser" class="col-md-6 mb-3 align-self-end">
                        <div>
                            <div class="form-label">{{ 'Project.BirthDate' | translate:locale.language }}
                            </div>
                            <dx-date-box type="date" [stylingMode]="stylingMode"
                                (onFocusOut)="birthDateFocusOut($event)" (onEnterKey)="birthDateFocusOut($event)"
                                (onInitialized)="initBirthDate($event)" (onValueChanged)="onChange($event)"
                                [(value)]="editItem.birthDate" [min]="min" [max]="now">
                                <dx-validator>
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                    <!-- <dxi-validation-rule type="range" [max]="maxDate"></dxi-validation-rule> -->
                                </dx-validator>
                            </dx-date-box>
                        </div>
                    </div>
                </div>
                <div *ngIf="!(doctorUser || gpUser)" class="row">
                    <div class="col-md-6 mb-3 align-self-end">
                        <div>
                            <div class="form-label">{{ 'Project.Sex' | translate:locale.language }}
                            </div>
                            <dx-select-box [dataSource]="sexCodes" valueExpr="id" displayExpr="descriptionTranslated"
                                (onValueChanged)="onChange($event)" [(value)]="editItem.sexCodeId"
                                [stylingMode]="stylingMode">
                                <dx-validator>
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-select-box>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3 align-self-end">
                        <div>
                            <div class="form-label">{{ 'Project.HighestEducation' | translate:locale.language }}
                            </div>
                            <dx-select-box [dataSource]="educationCodes" valueExpr="id"
                                displayExpr="descriptionTranslated" (onValueChanged)="onChange($event)"
                                [(value)]="editItem.highestEducationId" [stylingMode]="stylingMode">
                                <dx-validator>
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-select-box>
                        </div>
                    </div>
                </div>
                <div *ngIf="!(doctorUser || gpUser)" class="row">
                    <div class="col-md-6 mb-3 align-self-end">
                        <div>
                            <div class="form-label">{{ 'Project.ClinicalFamilyHistory' | translate:locale.language }}
                            </div>
                            <dx-select-box [dataSource]="familyHistoryCodes" valueExpr="id"
                                displayExpr="descriptionTranslated" (onValueChanged)="onChange($event)"
                                [(value)]="editItem.familyHistoryId" [stylingMode]="stylingMode">
                                <dx-validator>
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                </dx-validator>
                            </dx-select-box>
                        </div>
                    </div>
                    <div class="col-md-6 mb-3 align-self-end">
                        <div>
                            <div class="form-label">{{ 'Core.Entity_ContactPhone' | translate:locale.language }}
                            </div>
                            <dx-text-box type="string" [stylingMode]="stylingMode" (onChange)="onChange($event)"
                                [(value)]="editItem.phone">
                            </dx-text-box>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="form-label">{{ 'Core.UploadUserProfilePicture' | translate:locale.language }}
                    </div>

                    <div class="d-flex flex-column w-auto">
                        <div class="avatar-image-lg" [ngStyle]="{backgroundImage: 'url(' + blobUrl + ')'}"></div>
                        <div class="d-flex justify-content-around mt-2">
                            <label for="avatar-input-file">
                                <div class="btn btn-primary circle" title="Feltöltés">
                                    <input type="file" class="d-none" id="avatar-input-file" accept="image/*"
                                        (change)="onFileChange($event)" />
                                    <!-- <mat-icon class="icon" fontIcon="edit"></mat-icon> -->
                                    <i class="bi bi-pencil"></i>
                                </div>
                            </label>
                            <div class="btn btn-danger circle" *ngIf="editItem.avatarName > ''" title="Törlés"
                                (click)="deleteAvatar()">
                                <i class="bi bi-trash"></i>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="modal-footer">
                    <dx-button stylingMode="contained" text="{{ 'Core.EditSave' | translate:locale.language }}"
                        type="default" useSubmitBehavior=true>
                    </dx-button>
                    <button *ngIf="init=='NO' || inpPatientId" type="button" (click)="closeModal()" class="btn btn-neutral"
                        data-bs-dismiss="modal">
                        {{ 'Core.EditCancel' | translate:locale.language }}
                    </button>
                </div>
                <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
            </form>
        </div>
    </div>
</ng-template>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>