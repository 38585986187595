import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'doctorFilter' })
export class FilterDoctorPipe implements PipeTransform {
    /**
     * Pipe filters the list of objects based on displayName property and the search text provided
     *
     * @param items list of elements to search in
     * @param searchText search string
     * @returns list of elements filtered by search text or []
     */
    transform(items: any[], searchText: string, filteredCount: any): any[] {
        if (!items) {
            return [];
        }
        if (!searchText) {
            return items;
        }
        searchText = searchText.toLowerCase();
        // filtering
        let filteredItems;
        //Név keresés
        filteredItems = items.filter(it => it.userName.toLowerCase().includes(searchText));
        filteredCount.count = filteredItems.length;
        return filteredItems;
    }
}