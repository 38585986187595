
/**
 * A játékok indítási módja.
 *
 * Trial: Próbajáték.
 *
 * Normal: Rendes, nem próbajáték.
 */
export enum GameTypes { Trial, Normal }

/**
 * A játékok státusza. Ettől függ, hogy mit látunk a képernyőn.
 *
 * InfoPanel: Játék leírása.
 *
 * EmotionForm: Hangulati kérdőív.
 *
 * InputDataForm: Bemenő adatok rögzítése.
 *
 * CountDown: Visszaszámolás a játék előtt
 *
 * Board: A játéktér.
 *
 * GameOver: Vége a játéknak.
 */
export enum GameStatus { Init, InfoPanel, MoodForm, InputDataForm, CountDown, Board, GameOver }

/**
 * A memóriajátékoknál többféle képhalmazból is választhat.
 *
 */
export enum MemoryGameTypes { Classic, Semantic, Mathematical, Unknown }

/**
 * A szókitaláló szavai
 */
export let WordGuessWords = {
  hu: {
    letters: [],
    words: []
  },
  en: {
    letters: [],
    words: []
  },
  es: {
    letters: [],
    words: []
  }
};

/**
 * A szó visszaidéző kiválasztott szavai kategóriánként
 */
export interface WordRecallWords {
  category: string;
  words: string[];
};

export interface InfoPanelParameters {
  /**
   * Ennyi idő van normál játékban.
   */
  timerMaxNormal: number;
  /**
   * Ennyi idő van a próba játékban.
   */
  timerMaxTrial: number;
  /**
   * Kártyapárok száma normál játékban.
   */
  cardPairsNormal: number;
  /**
   * Kártyapárok száma próba játékban.
   */
  cardPairsTrial: number;

  /**
   * Szókitalálónál a max kisérletek száma.
   */
  maxAttemptsNormal: number;
  maxAttemptsTrial: number;

  // Alakzat visszaidéző

  /**
   * A rendelkezésre álló idő a normál játéknál az első képernyőn.
   */
  timerMaxMemoryNormal: number;

  /**
   *  A rendelkezésre álló idő a próba játéknál az első képernyőn.
   */
  timerMaxMemoryTrial: number;

  /**
   *  A rendelkezésre álló idő a normál játéknál a második képernyőn.
   */
  timerMaxRecallNormal: number;

  /**
   *  A rendelkezésre álló idő a próba játéknál a második képernyőn.
   */
  timerMaxRecallTrial: number;

  /**
   *  Az első képernyő alakzatainak száma normál játéknál
   */
  firstScreenShapesNormal: number;

  /**
   *  Az első képernyő alakzatainak száma próba játéknál
   */
  firstScreenShapesTrial: number;

  /**
   *  A második képernyőre felkerülő új alakzatok száma normál játéknál.
   */
  secondScreenAdditionalShapesNormal: number;

  /**
   *  A második képernyőre felkerülő új alakzatok száma próba játéknál.
   */
  secondScreenAdditionalShapesTrial: number;

  //Szó visszaidéző

  /**
  * Megjegyzendő szavak száma.
  */
  itemsCountNormal: number;
  itemsCountTrial: number;

  //Szöveges helyettesítésekhez
  text1: string;
  text2: string;
}

export interface SDMTSettingsDTO {
  /**
   * Az SDMT tesztre ennyi idő van normál játékban.
   */
  timerMaxNormal: number;
  /**
   * Az SDMT tesztre ennyi idő van a próba játékban.
   */
  timerMaxTrial: number;
  /**
   * Az SDMT tesztben ennyi kitalálandó elem van a normál játékban.
   */
  sdmtItemsCountNormal: number;
  /**
   * Az SDMT tesztben ennyi kitalálandó elem van a próba játékban.
   */
  sdmtItemsCountTrial: number;
}
export interface ColorRecognitionSettingsDTO {
  /**
   * Ennyi idő van normál játékban laponként.
   */
  timerMaxNormal: number;
  /**
   * Ennyi idő van a próba játékban laponként.
   */
  timerMaxTrial: number;
  /**
   * Ennyi kitalálandó elem van a normál játékban.
   */
  itemsCountNormal: number;
  /**
   * Ennyi kitalálandó elem van a próba játékban.
   */
  itemsCountTrial: number;
}
export interface MemoryGameSettingsDTO {
  /**
   * Ennyi idő van normál játékban.
   */
  timerMaxNormal: number;
  /**
   * Ennyi idő van normál játékban. (Klaszikusnál)
   */
  timerMaxNormalClassic: number;
  /**
  * Ennyi idő van a próba játékban.
  */
  timerMaxTrial: number;
  /**
   * Kártyapárok száma normál játékban.
   */
  cardPairsNormal: number;
  /**
   * Kártyapárok száma próba játékban.
   */
  cardPairsTrial: number;

  foldersClassic: string[];
  foldersSemantic: string[];
  foldersMathematical: string[];
  foldersThematic: string[];
}

export interface WordsGuessDTO {
  word: string;
  weight: number;
  category: string;
}


export interface WordsGuessSettingsDTO {
  letters: string;
  timerMaxNormal: number;
  timerMaxTrial: number;
  maxAttemptsNormal: number;
  maxAttemptsTrial: number;
}

export interface ShapeRecallSettingsDTO {
  /**
   * A rendelkezésre álló idő a normál játéknál az első képernyőn.
   */
  timerMaxMemoryNormal: number;

  /**
   *  A rendelkezésre álló idő a próba játéknál az első képernyőn.
   */
  timerMaxMemoryTrial: number;

  /**
   *  A rendelkezésre álló idő a normál játéknál a második képernyőn.
   */
  timerMaxRecallNormal: number;

  /**
   *  A rendelkezésre álló idő a próba játéknál a második képernyőn.
   */
  timerMaxRecallTrial: number;

  /**
   *  Az első képernyő alakzatainak száma normál játéknál
   */
  firstScreenShapesNormal: number;

  /**
   *  Az első képernyő alakzatainak száma próba játéknál
   */
  firstScreenShapesTrial: number;

  /**
   *  A második képernyőre felkerülő új alakzatok száma normál játéknál.
   */
  secondScreenAdditionalShapesNormal: number;

  /**
   *  A második képernyőre felkerülő új alakzatok száma próba játéknál.
   */
  secondScreenAdditionalShapesTrial: number;
}

export interface AgeAndDegree {
  age: number;
  hasDegree: boolean;
}

export interface TypoCorrector {
  word: string;
  correctWord: string;
}

export let ShapeRecallConstants = {
  shapes: [
    'bi-alarm',
    'bi-app',

    'bi-asterisk',
    'bi-bell',

    'bi-book',
    'bi-bookmark',

    'bi-bookshelf',
    'bi-border-all',

    'bi-box',
    'bi-bricks',

    'bi-bullseye',
    'bi-caret-down-fill',

    'bi-circle',
    'bi-circle-fill',

    'bi-cloud',
    'bi-cursor',

    'bi-diamond',
    'bi-droplet-fill',

    'bi-emoji-smile',
    'bi-flag-fill',

    'bi-flower2',
    'bi-globe',

    'bi-grid',
    'bi-hand-thumbs-up',

    'bi-hexagon-fill',
    'bi-house',

    'bi-lightning',
    'bi-minecart',

    'bi-moon',
    'bi-person',

    'bi-suit-heart-fill',
    'bi-sun',

    'bi-triangle',
    'bi-x-octagon-fill'
  ]
};

export interface WordRecallSettingsDTO {
  /**
   * Megjegyzendő szavak száma normál játéknál.
   */
  itemsCountNormal: number;

  /**
   *  Megjegyzendő szavak száma próba játéknál.
   */
  itemsCountTrial: number;

  /**
   *   A rendelkezésre álló idő a megjegyzett szavak leírásához. (normál játéknál)
   */
  timerMaxRecallNormal: number;

  /**
   *  A rendelkezésre álló idő a megjegyzett szavak leírásához. (próba játéknál)
   */
  timerMaxRecallTrial: number;

  /**
   * Fordulók száma normál játéknál.
   */
  roundCountNormal: number;

  /**
   *  Fordulók száma próba játéknál.
   */
  roundCountTrial: number;

};

export class EvaluationCategory {
  name: string;
  bgClass: string;
  class: string;
};

export let EvaluationCategories: EvaluationCategory[] = [
  {
    name: "Automatikus kiértékelés",
    bgClass: "icon-bg1",
    class: "bi bi-house"
  },
  {
    name: "Kiértékelés orvos által",
    bgClass: "icon-bg2",
    class: "bi bi-house"
  },
  {
    name: "Teszt a szakrendelőben",
    bgClass: "icon-bg2",
    class: "bi bi-hospital"
  }
];

export class TestEvaluation {
  gameType: string;
  evaluationCategory: EvaluationCategory;
};

export let TestEvaluations: TestEvaluation[] = [
  {
    gameType: "SDMT",
    evaluationCategory: EvaluationCategories[0]
  },
  {
    gameType: "SDMT-GAME",
    evaluationCategory: EvaluationCategories[0]
  },
  {
    gameType: "WordListRecallAc",
    evaluationCategory: EvaluationCategories[0]
  },
  {
    gameType: "TimeOrientation",
    evaluationCategory: EvaluationCategories[0]
  },
  {
    gameType: "WordRecall",
    evaluationCategory: EvaluationCategories[0]
  },
  {
    gameType: "BVMT-R",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "BVMT-RT",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "Fluencia",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "RepeatSentences",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "SpellBackwards",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "CountDownTest",
    evaluationCategory: EvaluationCategories[0]
  },
  {
    gameType: "LetterRecognition",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "RepeatNumbers",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "PictureRecognition",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "DelayedRecall",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "DelayedRecall2",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "Memorizing",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "Memorizing2",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "ClockTest",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "ShapeCopyingTest",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "TmtTest",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "Writing",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "NamingObjects",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "Construction",
    evaluationCategory: EvaluationCategories[1]
  },
  {
    gameType: "Bonus",
    evaluationCategory: EvaluationCategories[2]
  },
  {
    gameType: "Orientation",
    evaluationCategory: EvaluationCategories[2]
  },
  {
    gameType: "SpatialOrientation",
    evaluationCategory: EvaluationCategories[2]
  },
  {
    gameType: "OrientationAC",
    evaluationCategory: EvaluationCategories[2]
  },
  {
    gameType: "Abstraction",
    evaluationCategory: EvaluationCategories[2]
  },
  {
    gameType: "TripleCommand",
    evaluationCategory: EvaluationCategories[2]
  },
  {
    gameType: "Commands",
    evaluationCategory: EvaluationCategories[2]
  },
  {
    gameType: "TaskExecution",
    evaluationCategory: EvaluationCategories[2]
  },
  {
    gameType: "SpeakingSkills",
    evaluationCategory: EvaluationCategories[2]
  },
  {
    gameType: "SpeechUnderstanding",
    evaluationCategory: EvaluationCategories[2]
  },
  {
    gameType: "WordFindingDifficulty",
    evaluationCategory: EvaluationCategories[2]
  },
  {
    gameType: "RememberingInstructions",
    evaluationCategory: EvaluationCategories[2]
  },
  {
    gameType: "ReadingComprehension",
    evaluationCategory: EvaluationCategories[2]
  }
];


