import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LastMeasuredData, LoginUser, PatientDataViewModel } from 'src/app/services/webapiclient.service';
import { locale } from 'devextreme/localization';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import messagesEs from 'node_modules/devextreme/localization/messages/es.json';
import { loadMessages } from 'devextreme/localization';
import { ActivatedRoute, Router } from '@angular/router';
import { Role, RoleGU, RoleGUD, RoleRD, RoleSRD } from '../models/role';
import { PatientService } from '../module-patient/patient.service';
import { BoolResult, StringResult, ThryveService } from '../module-thryve/thryve.service';

@Component({
  selector: 'app-measures',
  templateUrl: './measures.component.html',
  styleUrls: ['./measures.component.scss']
})
export class MeasuresComponent implements OnInit, OnDestroy {

  patientDataViewModel: PatientDataViewModel = {} as PatientDataViewModel;
  lastMeasuredData: LastMeasuredData[];
  lastMeasuredDataModel: LastMeasuredData[];

  codesSource: any;
  dataSource: any;

  currentUser: LoginUser;
  dateFormat: string;
  diagnosisDate: Date;
  roleGUD: boolean = false;

  doctorUser: boolean = false;
  error = '';
  loading: boolean = false;
  thryveConnected = false;
  boolResult: BoolResult = {} as BoolResult;
  stringResult: StringResult = {} as StringResult;
  lastRefreshDate: string = null as string;
  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private route: ActivatedRoute,
    private patientService: PatientService,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private thryveService: ThryveService

  ) {

    const huMessages = JSON.stringify(messagesHu['hu']);
    const esMessages = JSON.stringify(messagesEs['es']);
    loadMessages({
      'hu': JSON.parse(huMessages),
      'es': JSON.parse(esMessages)
    });
    this.currentUser = this.authenticationService.currentUserValue;

  }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });

    if (this.currentUser && this.currentUser.role) {
      this.roleGUD = RoleGUD.includes(this.currentUser.role as Role)
    }

    if(!this.roleGUD){
      //Nincs jogosultsága, átirányítjuk az előfizetési csomagokhoz
      this.router.navigate(['subscription/packages']);
    }

    if (this.currentUser && this.currentUser.role == Role.Doctor) {
      this.doctorUser = true;
      this.getLastMeasuredData();
    } else {
      //Megnézzük, hogy van-e thryve kapcsolat
      //Ha van frissítünk
      this.thryveService.checkThryveConnection(this.currentUser.id)
        .subscribe(
          (data: BoolResult) => {
            if (this.boolResult.error) {
              this.thryveConnected = false;
              this.loading = true;
              this.getLastMeasuredData();
            } else {
              this.thryveConnected = data.result;
              this.refresh();
            }
          }
        );

    }

  }
  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  refresh(): void {
    this.loading = true;
    this.thryveService.refresh()
      .subscribe(
        (data: StringResult) => {
          if (this.stringResult.error) {
            this.lastRefreshDate = null;
            this.loading = false;
          } else {
            this.getLastMeasuredData();
            this.stringResult.result = data.result;
            this.lastRefreshDate = this.stringResult.result;
          }
        }
      );
  }

  onSelect(item) {
    if (item == 'sleep-chart') {
      if (this.lastMeasuredDataModel[4].startDate) {
        this.router.navigate(['sleep-chart', this.lastMeasuredDataModel[4].startDate]);
      } else {
        let gDate = new Date();
        this.router.navigate(['sleep-chart', gDate.toDateString()]);
      }
    } else {
      this.router.navigate([item]);
    }
  }

  getLastMeasuredData() {
    this.loading = true;
    this.patientService.getLastMeasuredData(this.currentUser.id).then(
      result => {
        let measuredData: LastMeasuredData = {} as LastMeasuredData;
        measuredData.code = 'NONE';
        let ix = -1;
        this.lastMeasuredData = <LastMeasuredData[]>result;
        ix = this.lastMeasuredData.findIndex(f => f.code == 'DiagnosisDate');
        if (ix != -1) {
          this.diagnosisDate = this.lastMeasuredData[ix].startDate;
        }

        ix = -1;
        this.lastMeasuredDataModel = [];
        for (let i = 0; i < 6; i++) {
          switch (i) {
            case 0:
              ix = this.lastMeasuredData.findIndex(f => f.code == 'Systolic');
              break;
            case 1:
              ix = this.lastMeasuredData.findIndex(f => f.code == 'Diastolic');
              break;
            case 2:
              ix = this.lastMeasuredData.findIndex(f => f.code == 'Pulse');
              break;
            case 3:
              ix = this.lastMeasuredData.findIndex(f => f.code == 'Spo2');
              break;
            case 4:
              ix = this.lastMeasuredData.findIndex(f => f.code == 'Sleep');
              break;
            case 5:
              ix = this.lastMeasuredData.findIndex(f => f.code == 'Lab');
              break;
          }
          if (ix != -1) {
            this.lastMeasuredDataModel.push(this.lastMeasuredData[ix]);
            if (i == 4) {
              //alvás óraszám
              this.lastMeasuredDataModel[4].hour = Math.trunc(this.lastMeasuredDataModel[4].value / 60);
            }
          } else {
            this.lastMeasuredDataModel.push(measuredData);
          }
        }
        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });
  }


}
