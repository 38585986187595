import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GamesReportComponent } from './games-report/games-report.component';
import { StatisticsRoutingModule } from './statistics.routing';
import { DxBulletModule, DxChartModule, DxCheckBoxModule, DxCircularGaugeModule, DxDateBoxModule, DxLoadPanelModule, DxPieChartModule, DxRadioGroupModule, DxSelectBoxComponent, DxSelectBoxModule, DxValidatorModule } from 'devextreme-angular';
import { L10nIntlModule, L10nTranslationModule } from 'angular-l10n';
import { SharedModule } from '../shared/shared.module';
import { BloodPressureChartComponent } from './blood-pressure-chart/blood-pressure-chart.component';
import { GameResultTrendsComponent } from './game-result-trends/game-result-trends.component';
import { BloodOxygenChartComponent } from './blood-oxygen-chart/blood-oxygen-chart.component';
import { ThryveSleepChartComponent } from './thryve-sleep-chart/thryve-sleep-chart.component';
import { DensityComponent } from './density/density.component';
import { PredictionComponent } from './prediction/prediction.component';


@NgModule({
  declarations: [
    GamesReportComponent,
    BloodPressureChartComponent,
    GameResultTrendsComponent,
    BloodOxygenChartComponent,
    ThryveSleepChartComponent,
    DensityComponent,
    PredictionComponent
  ],
  exports: [
    GamesReportComponent,
    BloodPressureChartComponent,
    BloodOxygenChartComponent,
    GameResultTrendsComponent,
    ThryveSleepChartComponent,
    PredictionComponent
  ],
  imports: [
    CommonModule,
    L10nTranslationModule,
    L10nIntlModule,
    DxChartModule,
    DxCheckBoxModule,
    DxDateBoxModule,
    DxValidatorModule,
    DxRadioGroupModule,
    DxLoadPanelModule,
    DxPieChartModule,
    DxSelectBoxModule,
    DxBulletModule,
    DxCircularGaugeModule,
    StatisticsRoutingModule,
    SharedModule
  ]
})
export class StatisticsModule { }
