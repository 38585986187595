import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  Role,
  RoleResult,
  RolesFunc
} from '../services/webapiclient.service';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';

import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { formatMessage, loadMessages, locale } from 'devextreme/localization';

import { ErrorService } from '../services/error.service';
import { first } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import CustomStore from 'devextreme/data/custom_store';
import { Subscription } from 'rxjs';

/**
 * Szerepkörök karbantartása. (admin szerepkörrel)
 */

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.scss']
})
export class RoleComponent implements OnInit, OnDestroy {

  roleResult: RoleResult = {} as RoleResult;
  error: string;
  baseUri: string = `${environment.apiUrl}`;
  dataSource: any;
  private translSubs$: Subscription;


  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private rolesFunc: RolesFunc,
    private errorService: ErrorService,
    private translation: L10nTranslationService,
    private http: HttpClient
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

    this.dataSource = new CustomStore({
      key: "id",
      load: () => this.getAll(),
      insert: (values) => this.insert(JSON.stringify(values)),
      update: (key, values) => this.update(key, JSON.stringify(values)),
      remove: (key) => this.delete(key).then()
    });
  }


  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  getAll() {
    return new Promise((resolve, reject) => {
      this.rolesFunc.getAll(true).pipe(first()).subscribe(
        (p) => {
          this.roleResult = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e.message );
          reject(e.message);
        },
        () => {
          if (this.roleResult.error === null) {
            resolve(this.roleResult.roles);
          } else {
            this.errorService.changeErrorMessage(this.roleResult.error);
            reject(this.roleResult.error);
          }
        }
      );
    });
  }

  insert(role: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.rolesFunc.insert(role).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.succeeded) {
            resolve("OK");
          } else {
            reject(result.errors[0].description);
          }
        }
      );
    });
  }

  update(key: string, role: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.rolesFunc.update(key, role).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.succeeded) {
            resolve("OK");
          } else {
            reject(result.errors[0].description);
          }
        }
      );
    });
  }

  delete(key: string) {
    let result: any;
    return new Promise((resolve, reject) => {
      this.rolesFunc.delete(key).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.errorService.changeErrorMessage(e);
          reject(e);
        },
        () => {
          if (result.succeeded) {
            resolve("OK");
          } else {
            reject(this.translation.translate('Core.ErrorMessage_DeleteNotPossible'));
          }
        }
      );
    });
  }
}
