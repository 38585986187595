<!-- Megjelenítés -->
<div class="container">
    <div class="m-3">
        <div class="d-md-flex justify-content-between align-items-center">
            <h1 class="display-6 d-block">{{ 'Project.CancelTreatment' | translate:locale.language }}</h1>
        </div>
        <hr>
    </div>
    <div class="m-3">
        {{ 'Project.CancelTreatmentWarning' | translate:locale.language }}
    </div>
    <div class="m-3">
        <div class="col-md-12 mb-3 align-self-end">
            <div>
                <div class="form-label">{{ 'Project.CancellationReason' | translate:locale.language }}
                </div>
                <dx-text-area [autoResizeEnabled]="true" [height]="90" [(value)]="cancellationReason" [stylingMode]="stylingMode">
                    <dx-validator>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-area>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <dx-button
             stylingMode="contained"
             text="{{ 'Core.EditSave' | translate:locale.language }}"
             type="default"
             (onClick)="save()"
             useSubmitBehavior=true>
         </dx-button>
 
      </div>
 
</div>

<dx-load-panel
#loadPanel
shadingColor="rgba(0,0,0,0.4)"
[position]="{ of: '#reg' }"
[(visible)]="loading"
[showIndicator]="true"
[showPane]="true"
[shading]="true"
[hideOnOutsideClick]="false"
(onShown)="onShown()">
</dx-load-panel>
