<!-- nav -->
<!-- <nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="currentUser">
    <div class="navbar-nav">
        <a class="nav-item nav-link" routerLink="/">Home</a>
        <a class="nav-item nav-link" (click)="logout()">Logout</a>
    </div>
</nav> -->
<header>
  <div class="topnav">
    <nav class="navbar navbar-expand-lg navbar-dark justify-content-between py-1 px-2" style="z-index: 10;">
      <div class="predem-logo pointer" (click)="goToHome()">
        <img src="../assets/images/predem logo.svg">
      </div>
      <div class="d-flex align-items-start">
        <div class="collapse navbar-collapse" id="navbarToggler" #navbarToggler>
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item dropdown" *ngFor="let menu of menuDs; index as i">
              <a *ngIf="menu.items && menu.items.length > 1" class="nav-link top1 dropdown-toggle" href="#"
                role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {{menu.name}}
              </a>
              <a *ngIf="!(menu.items && menu.items.length > 1)" class="nav-link top1" (click)="startItem(menu.route)"
                href="javascript: void(0);" role="button" aria-expanded="false">
                {{menu.name}}
              </a>
              <ul class="dropdown-menu dropdown-menu-end" *ngIf="menu.items">
                <li *ngFor="let item of menu.items"><a class="dropdown-item" href="javascript: void(0);"
                    (click)="startItem(item.route)">{{item.name}}</a></li>
              </ul>
            </li>
          </ul>
        </div>

      </div>
      <div class="d-flex justify-content-end" style="width: 200px;">

        <app-notification></app-notification>
        <button class="btn btn-profile me-2" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="bi bi-person-circle"></i></button>
        <ul class="profile-menu dropdown-menu dropdown-menu-end">
          <ng-container *ngIf="!(currentUser && currentUser.displayName != null)">
            <li>
              <div class="d-flex justify-content-around align-items-center p-3">
                <div class="d-flex flex-column align-items-center pointer" *ngFor="let item of schema"
                  (click)="setLocale(item.locale)">
                  <span *ngIf="item.locale.language.length>1">
                    <div>
                      <img src=".../../../assets/flags/flag.{{item.locale.language}}.png">
                    </div>
                    <span class="small">{{ item.text}}</span>
                  </span>
                </div>
              </div>
            </li>
            <li>
              <div class="d-flex justify-content-center align-items-center p-3">
                <a class="btn btn-primary" (click)="login()">
                  <i class="bi bi-box-arrow-in-right"></i> {{'Core.MenuLogon' | translate:locale.language }}
                </a>
              </div>
            </li>
          </ng-container>
          <ng-container *ngIf="currentUser && currentUser.displayName != null">
            <li>
              <div class="d-flex align-items-center p-3">
                <div>
                  <div class="avatar-image-sm me-3" [ngStyle]="{'background-image': 'url(' + blobUrl + ')'}">
                  </div>
                </div>
                <div>
                  <h6>{{ 'Core.UserGreetings' | translate:locale.language }}</h6>
                  <h6>{{currentUser.displayName }}</h6>
                </div>
              </div>
            </li>
            <li>
              <hr class="dropdown-divider ">
            </li>
            <li>
              <div class="d-flex justify-content-around align-items-center p-3">
                <div class="d-flex flex-column align-items-center pointer" *ngFor="let item of schema"
                  (click)="setLocale(item.locale)">
                  <div>
                    <img src=".../../../assets/flags/flag.{{item.locale.language}}.png">
                  </div>
                  <span class="small">{{ item.text}}</span>
                </div>
              </div>
            </li>
            <li>
              <hr class="dropdown-divider ">
            </li>
            <li>
              <a class="dropdown-item d-flex align-items-center pointer" (click)="startItem('patient-profile/NO')">
                <span class="dropdown-icon"><img src="../../../assets/images/side-icon-personal-data.svg"></span>
                <span class="dropdown-title">{{'Core.PersonalData' | translate:locale.language }}</span>
              </a>
              <ng-container *ngIf="roleU">
                <a class="dropdown-item d-flex align-items-center pointer" (click)="startItem('actual-condition')">
                  <span class="dropdown-icon"><img src="../../../assets/images/side-icon-anamnesis.svg"></span>
                  <span class="dropdown-title">{{'Project.HealthData' | translate:locale.language }}</span>
                </a>
              </ng-container>
              <a class="dropdown-item d-flex align-items-center pointer" (click)="startItem('settings')">
                <span class="dropdown-icon"><img src="../../../assets/images/side-icon-settings.svg"></span>
                <span class="dropdown-title">{{'Project.Settings' | translate:locale.language }}</span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider ">
            </li>
            <li *ngIf="roleU">
              <a class="dropdown-item d-flex align-items-center pointer" (click)="startItem('subscription/packages')">
                <span class="dropdown-icon"><img src="../../../assets/images/packages.svg"></span>
                <span class="dropdown-title">{{'Subscription.Packages' | translate:locale.language }}</span>
              </a>
            </li>
            <li *ngIf="roleU">
              <a class="dropdown-item d-flex align-items-center pointer" (click)="startItem('/subscription/coupon-usage')">
                <span class="dropdown-icon"><img src="../../../assets/images/coupon.svg"></span>
                <span class="dropdown-title">{{'Subscription.CouponUsage' | translate:locale.language }}</span>
              </a>
            </li>
            <li>
              <a class="dropdown-item d-flex align-items-center pointer" (click)="startItem('predem-info-page')">
                <span class="dropdown-icon"><img src="../../../assets/images/side-icon-app-info.svg"></span>
                <span class="dropdown-title">{{'Core.AppName' | translate:locale.language }}</span>
              </a>
            </li>
            <li>
              <hr class="dropdown-divider ">
            </li>
            <li>
              <div class="d-flex justify-content-center align-items-center p-3">
                <a class="btn btn-danger" (click)="logout()">
                  <i class="bi bi-box-arrow-right"></i> {{'Core.MenuLogoff' | translate:locale.language }}
                </a>
              </div>
            </li>
          </ng-container>
        </ul>
        <button class="navbar-toggler px-1 me-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler"
          aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
    </nav>
    <!-- <dx-menu #menu [dataSource]="menuDs" displayExpr="name" [showFirstSubmenuMode]="showFirstSubmenuModes"
      orientation="horizontal" adaptivityEnabled="true" submenuDirection="auto" [hideSubmenuOnMouseLeave]="false"
      (onItemClick)="itemClick($event)" (onInitialized)="saveMenuInstance($event)">
    </dx-menu>
    <div class="dropdown">
      <button class="btn btn-profile me-3" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown"
        aria-expanded="false">
        <i class="bi bi-person-circle"></i>
      </button>
      <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
        <li *ngIf="currentUser && currentUser.displayName != null">
          <h6 class="dropdown-header">{{ 'Core.Welcome' | translate:locale.language:
            {displayName:currentUser.displayName} }}

          </h6>
        </li>
        <li>
          <a class="dropdown-item pointer" *ngFor="let item of schema" (click)="setLocale(item.locale)"><i
              class="bi bi-flag"></i> {{ item.text }}</a>
        </li>
        <li>
          <hr class="dropdown-divider ">
        </li>
        <li *ngIf="currentUser && currentUser.displayName != null">

          <a class="dropdown-item pointer" (click)="logout()"><i class="bi bi-box-arrow-right"></i> {{ 'Core.MenuLogoff'
            | translate:locale.language }}</a>
        </li>
        <li *ngIf="!(currentUser && currentUser.displayName != null)">
          <a class="dropdown-item pointer" (click)="login()"><i class="bi bi-box-arrow-in-right"></i> {{
            'Core.MenuLogon' | translate:locale.language }}</a>
        </li>
      </ul>
    </div> -->

  </div>
</header>
<div class="app">
  <router-outlet></router-outlet>
  <app-confirm-dialog></app-confirm-dialog>
  <!-- <app-thryve-connection></app-thryve-connection> -->
</div>