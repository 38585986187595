import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { loadMessages, locale } from 'devextreme/localization';
import { AuthenticationService } from '../services/authentication.service';
import messagesHu from 'devextreme/localization/messages/hu.json';
import { LoginUser } from '../services/webapiclient.service';
import CustomStore from 'devextreme/data/custom_store';
import { NotificationService } from '../notification/notification-service.service';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';

/**
 * Fel és leiratkozás a különböző értesítésekre
 */

@Component({
  selector: 'app-notification-setting',
  templateUrl: './notification-setting.component.html',
  styleUrls: ['./notification-setting.component.scss']
})
export class NotificationSettingComponent implements OnInit, OnDestroy {
  categoryDataSource: any;
  selectedCategories: string[] = [];
  loadingVisible = false;
  currentUser: LoginUser;
  error = '';
  dataGridInstance: any;
  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private notificationService: NotificationService
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

    this.categoryDataSource = new CustomStore({
      key: "code",
      loadMode: "raw",
      cacheRawData: false,
      update: (key, values) => notificationService.updateCategorySetting(key, JSON.stringify(values)),
      load: () => notificationService.getCategorySettings(this.currentUser.id)
    });

  }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });

    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      //this.getPatientData(this.currentUser.id);
    }
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  initDataGrid(e) {
    this.dataGridInstance = e.component;
    this.selectedCategories[0] = "CT_Termin";
  }

  onShown() {
    setTimeout(() => {
      this.loadingVisible = false;
    }, 3000);
  }

  onHidden() {
    //this.employeeInfo = this.employee;
  }
}
