

<div class="chart-size-fix">
    <dx-chart id="chart" [dataSource]="filteredDataSource" class="{{ifDoctor}}"
    (onLegendClick)="legendClick($event)" (onInitialized)="initChart($event)">
        <dxi-series argumentField="measurementDate" valueField="systolic" name="Systolic" color="red">
            <dxo-point color="red"></dxo-point>
        </dxi-series>
        <dxi-series argumentField="measurementDate" valueField="diastolic" name="Diastolic" color="blue">
            <dxo-point symbol="circle" color="blue"></dxo-point>
        </dxi-series>
        <dxi-series argumentField="measurementDate" valueField="pulse"
            name="{{ 'Project.Pulse' | translate:locale.language }}" color="green">
            <dxo-point symbol="circle" color="green"></dxo-point>
        </dxi-series>
        <dxi-series argumentField="weightDate" valueField="weight"
            name="{{ 'Project.Weight' | translate:locale.language }}" color="purple" type="spline">
            <dxo-point symbol="circle" color="purple"></dxo-point>
        </dxi-series>

        <dxi-value-axis>
            <dxi-constant-line [(value)]="normalBloodPressure.systolic" color="red" dashStyle="dash" extendAxis="true">
            </dxi-constant-line>
            <dxi-constant-line [(value)]="normalBloodPressure.diastolic"  color="blue" dashStyle="dash"></dxi-constant-line>
        </dxi-value-axis>

        <dxo-common-series-settings type="scatter"></dxo-common-series-settings>

        <dxo-argument-axis [workdaysOnly]="false" [tickInterval]="1">
            <dxo-label [customizeText]="customizeTimeText"></dxo-label>
            <dxo-grid [visible]="true"></dxo-grid>
            <dxo-minor-grid [visible]="true"></dxo-minor-grid>
        </dxo-argument-axis>

        <dxo-legend [visible]="true" verticalAlignment="bottom" horizontalAlignment="center">
        </dxo-legend>

        <dxo-tooltip [enabled]="true" [shared]="false">
        </dxo-tooltip>

        <dxo-common-pane-settings backgroundColor="#ffffff">
            <dxo-border [visible]="true"></dxo-border>
        </dxo-common-pane-settings>
    </dx-chart>
</div>
 <hr>

