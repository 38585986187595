import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class CaptchaService {

  baseUri: string = `${environment.apiUrl}`;

  constructor(private http: HttpClient) { }

  send(data: Object): Observable<any> {
    const options = {
      headers: new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'})
    };

    return this.http.post(
        this.baseUri + 'CaptchaFunc', 
      data, options);
  }
}