import { HttpClient } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-instruction',
  templateUrl: './instruction.component.html',
  styleUrls: ['./instruction.component.scss']
})
/**
 * Kiértékeléshez instrukciók nyelvfüggő megjelenítése.
 */
export class InstructionComponent implements OnInit, OnDestroy {
  /**
   * Az instrukció leírása, nyelvfüggő, htmlből felolvasva.
   */
  infoPanel: SafeHtml;

  htmlString: string;

  /**
   * A nyelvválasztó feliratkozáshoz.
   */
  private translSubs$: Subscription;
  /**
   * A betöltendő szöveg azonosítója, a html fájl nevét ez alapján állítjuk elő.
   */
  @Input() testName: string;
  @Input() title: string;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    private http: HttpClient,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    // Feliratkozás a nyelv változtatására.
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        // Ha változott a nyelv, akkor újratöltjük az infopanelt.
        this.getInfoPanelContent();
      });
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  /**
   * Betöltjük a nyelvfüggő infopanel tartalmat, ami az assets/instructions mappában van.
   * Pl. magyar: sdmt.hu-hu.html, angol: sdmt.en-us.html
   */
  private getInfoPanelContent() {
    let fileName = this.testName;
    fileName += '.' + this.locale.language.toLowerCase() + '.html';
    const path = 'assets/instructions/' + fileName;
    this.http.get(path, { responseType: 'text' }).subscribe(
      data => {
        this.infoPanel = this.sanitizer.bypassSecurityTrustHtml(data);
      });
  }
}
