/**
 * 0: Jó TAJ szám
 *
 * -1: Rossz a kapott érték hossza (csak 9 /elválasztás nélkül/
 *     vagy 11 /elválasztással/ karakter lehet)
 *
 * -2: A kapott érték nem csak számjegyet tartalmaz (kivéve: elválasztás)
 *
 * -3: A kapott érték CDV hibás
 */
export const CheckTAJ = (cTAJ: string): any => {
    cTAJ = cTAJ.replace(/ /g, '');
    if (cTAJ.length !== 9) { return -1; }
    const y: number = +cTAJ;
    if (!y) { return -2; }
    let nCDV = 0;
    for (let i = 0; i < 8; i++)
    {
        nCDV += +(cTAJ[i].toString()) * (3 + 4 * (i % 2));
    }
    if (+(cTAJ[8].toString()) !== (nCDV % 10)) { return -3; }

    return 0;
  };

/**
 * dx-date-box segéd funkció, ami a dátum-ban lévő szünet-et és a végén lévő pont hiányát
 * korrigálja, és jó dátumként reprezentálja
 */
export const CorrectDate = (dateInstance: any): any => {
    const t = dateInstance.option('text');
    if (t[4] === '.') {
      const e = t.split('.').map(item => item.trim());
      let newDate = '';
      if (e.length >= 3 && e[0].length === 4) {
          newDate = e[0] + '. ' + e[1] + '. ' + e[2] + '.';
          const timestamp = Date.parse(newDate);

          if (isNaN(timestamp) === false) {
              if (newDate.length > 10) {
                dateInstance.reset();
                dateInstance.option('value', new Date(newDate));
                dateInstance.repaint();
              }
          }
      }
  }
  };
