<div class="container">
    <div class="m-3">
        <div class="d-flex align-items-center">
            <div class="general-icons-sm me-2">
                <img src="../../../assets/images/settings.svg">
            </div>
            <h1 class="display-6 m-0">{{ 'Project.Settings' | translate:locale.language }}</h1>
        </div>
        <hr>
        <div class="row row-cols-2 g-3">
            <!-- Jelszó módosítás -->
            <div class="col">
                <div class="card h-100 pointer" (click)="onSelect('change-password')">
                    <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center">
                        <div class="settings-icons">
                        <img src="../../../assets/images/set-password.svg">
                        </div>
                        <h4 class="text-center">{{ 'Core.EditPassword' | translate:locale.language }}</h4>
                    </div>
                    </div>
                </div>
            </div>
            <!-- Díjaim -->
            <div *ngIf="roleU" class="col">
                <div class="card h-100 pointer" (click)="onSelect('my-awards')">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center">
                    <div class="settings-icons">
                        <img src="../../../assets/images/badge.svg">
                    </div>
                    <h4 class="text-center">{{ 'Notification.MyAwards' | translate:locale.language }}</h4>
                    </div>
                </div>
                </div>
            </div>
            <!-- Szolgáltató kapcsolat -->
            <div *ngIf="roleU" class="col">
                <div class="card h-100 pointer" (click)="onSelect('hc-provider-select')">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center">
                    <div class="settings-icons">
                        <img src="../../../assets/images/set-provider-links.svg">
                    </div>
                    <h4 class="text-center">{{ 'Project.ProviderLinks' | translate:locale.language }}</h4>
                    </div>
                </div>
                </div>
            </div>
            <!-- Háziorvosi kapcsolat -->
            <div *ngIf="patientDataViewModel.gpName" class="col">
                <div class="card h-100 pointer" (click)="cancelGpConnection()">
                    <div class="card-body">
                        <div class="d-flex flex-column justify-content-start align-items-center">
                        <div class="settings-icons">
                            <img src="../../../assets/images/set-gp.svg">
                        </div>
                        <h4 class="text-center">{{ 'Project.GpLink' | translate:locale.language }}</h4>
                        </div>
                    </div>
                    </div>
            </div>
            <!-- Kupon beváltás -->
            <div *ngIf="roleU" class="col">
                <div class="card h-100 pointer" (click)="onSelect('/subscription/coupon-usage')">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center">
                    <div class="settings-icons">
                        <img src="../../../assets/images/coupon.svg">
                    </div>
                    <h4 class="text-center">{{ 'Subscription.CouponUsage' | translate:locale.language }}</h4>
                    </div>
                </div>
                </div>
            </div>
            <!-- Okos eszközök -->
            <div *ngIf="this.currentUser.role != 'GP'"  class="col">
                <div class="card h-100 pointer" (click)="onSelect('t-connection')">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center">
                    <div class="settings-icons">
                        <img src="../../../assets/images/set-smart-devices.svg">
                    </div>
                    <h4 class="text-center">{{ 'Thryve.ThryveSources' | translate:locale.language }}</h4>
                    </div>
                </div>
                </div>
            </div>
            <!-- EULA -->
            <div *ngIf="roleU" class="col">
                <div class="card h-100 pointer" (click)="onSelect('terms-info/YES')">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center">
                    <div class="settings-icons">
                        <img src="../../../assets/images/set-eula.svg">
                    </div>
                    <h4 class="text-center">{{ 'Core.EndUserLicenseAgreement' | translate:locale.language }}</h4>
                    </div>
                </div>
                </div>
            </div>
            <!-- Adatvédelmi szabályzat -->
            <div *ngIf="roleU" class="col">
                <div class="card h-100 pointer" (click)="onSelect('shared/gdpr')">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center">
                    <div class="settings-icons">
                        <img src="../../../assets/images/set-privacy.svg">
                    </div>
                    <h4 class="text-center">{{ 'Core.PrivacyPolicy' | translate:locale.language }}</h4>
                    </div>
                </div>
                </div>
            </div>
            <!-- Értesítési beállítások -->
            <div  class="col">
                <div class="card h-100 pointer" (click)="onSelect('notification-setting', true)">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center">
                    <div class="settings-icons">
                        <img src="../../../assets/images/set-notification.svg">
                    </div>
                    <h4 class="text-center">{{ 'Notification.NotifSetting' | translate:locale.language }}</h4>
                    </div>
                </div>
                </div>
            </div>
           <!-- Regisztráció megszüntetése -->
           <div *ngIf="roleU" class="col">
            <div class="card h-100 pointer" (click)="onSelect('unregister')">
            <div class="card-body">
                <div class="d-flex flex-column justify-content-start align-items-center">
                <div class="settings-icons">
                    <img src="../../../assets/images/set-delete-registration.svg">
                </div>
                <h4 class="text-center">{{ 'Core.Unregister' | translate:locale.language }}</h4>
                </div>
            </div>
            </div>
        </div>
    </div> 
    </div>


</div>

<ng-template #deleteGpConnect>
    <div *ngIf="patientDataViewModel.gpName" class="col">
        <div class="modal-header">
            <h4 class="modal-title">{{ 'Project.GpLink' | translate:locale.language }}</h4>
            <button  type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()"
                aria-label="Close">
            </button>
        </div>
        <div class="card-body">
            <div class="d-flex flex-column justify-content-start align-items-center">
            </div>
            <div>
                <div class="d-md-flex  align-items-center">
                    <div class="d-flex flex-column p-4">
                        <div class="avatar-image-lg"
                            [ngStyle]="{backgroundImage: 'url(' + uploadUrl + patientDataViewModel.gpAvatarName + ')'}">
                        </div>
                    </div>
                    <div class="d-flex flex-column flex-fill">
                        <div class="row row-cols-1 g-3 my-3 w-100">
                            <div class="col">
                                <div class="h4 fw-light">{{patientDataViewModel.gpName}}</div>
                            </div>
                            <div class="col">
                                <button  class="btn btn-primary w-50"
                                (click)="onCancelGpLink()">{{ 'Core.EditDelete' | translate:locale.language }}
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </div>

</ng-template>

