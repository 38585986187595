import { Component, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { DxFormComponent } from 'devextreme-angular';
import { loadMessages, locale } from 'devextreme/localization';
import messagesHu from 'devextreme/localization/messages/hu.json';
import messagesEs from 'devextreme/localization/messages/es.json';
import notify from 'devextreme/ui/notify';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CodesFunc, CodeWarehouseEditModel } from 'src/app/services/codes.service';

import { LoginUser, PatientDataViewModel } from 'src/app/services/webapiclient.service';
import { PatientService } from '../patient.service';
import { CheckTAJ, CorrectDate, convertBlobToBase64 } from '../../shared/utils';
import { FormGroup } from '@angular/forms';
import { FormComponent } from 'src/app/helpers/nav-preventer';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Role } from 'src/app/models/role';

/**
 * Felhasználói profil adatok kezelése.
 */


@Component({
  selector: 'app-patient-profile',
  templateUrl: './patient-profile.component.html',
  styleUrls: ['./patient-profile.component.scss']
})
export class PatientProfileComponent implements OnInit, FormComponent {

  //A componenst a patient-catalogue-ból hívjuk, és küldünk neki egy esemény, ha be kell zárnia a modalt
  @Output() closeModalEvent = new EventEmitter<boolean>();

  @Input() inpPatientId: string;
  @ViewChild('editFormtmpl', { static: true }) editFormtmpl: TemplateRef<any>;

  formg: FormGroup;
  hasChanged: boolean = false;

  modalRef: BsModalRef;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  patientId: any;
  stylingMode = "filled"
  dateFormat: string;

  patientDataViewModel: PatientDataViewModel = {} as PatientDataViewModel;
  sexCodes: CodeWarehouseEditModel[] = {} as CodeWarehouseEditModel[];
  educationCodes: CodeWarehouseEditModel[] = {} as CodeWarehouseEditModel[];
  familyHistoryCodes: CodeWarehouseEditModel[] = {} as CodeWarehouseEditModel[];

  currentUser: LoginUser;
  doctorUser: boolean = false;
  gpUser: boolean = false;
  birthDateInstance: any;
  now: Date = new Date();
  min: Date = new Date(1920, 0, 1);

  editItem: any;

  //Profil kép tárolása
  blobFile: Blob;
  blobUrl: string;
  pictureData: any;
  pictureExtension: string;
  uploadUrl = `${environment.uploadUrl}`;

  //Első kötelező feltöltés?
  init: string = 'NO';

  @ViewChild(DxFormComponent, { static: false }) form: DxFormComponent
  password = "";
  passwordOptions: any = {
    mode: "password",
    value: this.password
  };

  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private route: ActivatedRoute,
    private router: Router,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService,
    private patientService: PatientService,
    private codesFunc: CodesFunc

  ) {
    this.onChange = this.onChange.bind(this);
    const huMessages = JSON.stringify(messagesHu['hu']);
    const esMessages = JSON.stringify(messagesEs['es']);
    loadMessages({
      'hu': JSON.parse(huMessages),
      'es': JSON.parse(esMessages),
    });

    //kódok feltöltése a combokhoz
    codesFunc.getCodesData('Sex').then(
      result => {
        this.sexCodes = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
    codesFunc.getCodesData('HighestEducation').then(
      result => {
        this.educationCodes = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
    codesFunc.getCodesData('ClinicalFamilyHistory').then(
      result => {
        this.familyHistoryCodes = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });

  }

  buttonOptions: any = {
    text: this.translation.translate('Core.EditSave'),
    type: "success",
    useSubmitBehavior: true
  }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });

    this.init = this.route.snapshot.paramMap.get('init');

    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      if (this.inpPatientId) {
        this.patientId = this.inpPatientId;
      } else {
        this.patientId = this.currentUser.id;
        if (this.currentUser.role == Role.Doctor) {
          this.doctorUser = true;
        }
      }
      if (this.currentUser.role == Role.GP) {
        this.gpUser = true;
      }
    this.getPatientData(this.patientId);
    }

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  clickEdit() {
    this.editItem = <PatientDataViewModel>{};
    this.editItem = this.simpleClone(this.patientDataViewModel);

    this.modalRef = this.modalService.show(this.editFormtmpl, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }
  closeModal() {
    this.closeModalEvent.emit(true);
    this.modalRef.hide();
  }
  simpleClone(obj: any) {
    return Object.assign({}, obj);
  }

  onFormSubmit = function (e) {
    this.error = "";
    this.loading = true;
    this.patientDataViewModel = this.simpleClone(this.editItem);
    this.patientDataViewModel.patientDataId = this.patientId;
    this.patientDataViewModel.picture = this.pictureData;
    //Ha csak dátumot kérünk be a devextreme date-box-nál, akkor 0 órát ad vissza
    //hozzá kell adni 1 órát, hogy ne az előző napra konvertája a JSON.stringify
    if (this.patientDataViewModel.birthDate) {
      this.patientDataViewModel.birthDate = new Date(this.patientDataViewModel.birthDate);
      this.patientDataViewModel.birthDate.setHours(6);
    }

    //let result: any;
    this.patientService.upsertPatient(JSON.stringify(this.patientDataViewModel)).then(
      result => {
        if (result == "OK") {
          notify({
            message: "Sikeres mentés!",
            position: {
              my: "center top",
              at: "center top"
            }
          }, "success", 3000);
          this.hasChanged = false;
          this.closeModal();

          this.router.navigate(['/']);
        } else {
          this.error = result;
          this.loading = false;
        }
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });

    e.preventDefault();
  }

  validateTaj(e) {
    if (this.locale.language.toLowerCase() == "hu-hu") {
      return CheckTAJ(e.value) == 0;
    } else {
      return true;
    }

  }

  getPatientData(id: string) {
    this.loading = true;
    this.patientService.getPatient(id).then(
      result => {
        this.patientDataViewModel = <PatientDataViewModel>result;
        this.blobUrl = this.uploadUrl + this.patientDataViewModel.avatarName;
        this.loading = false;
        if (this.init == 'YES') this.clickEdit();
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });
  }

  birthDateFocusOut(s) {
    CorrectDate(this.birthDateInstance);
  }

  initBirthDate(e) {
    this.birthDateInstance = e.component;
  }
  onChange = function (e) {
    if (e.event) this.hasChanged = true;
  }

  async onFileChange(event: any) {
    const files = event.target.files as FileList;

    if (files.length > 0) {
      const _file = URL.createObjectURL(files[0]);
      this.blobUrl = _file;
      this.blobFile = await fetch(this.blobUrl).then(r => r.blob());
      this.pictureData = await convertBlobToBase64(this.blobFile);
      this.editItem.avatarName = files[0].name;
      this.resetInput();
    }
  }

  resetInput() {
    const input = document.getElementById('avatar-input-file') as HTMLInputElement;
    if (input) {
      input.value = "";
    }
  }

  deleteAvatar() {
    this.editItem.avatarName = null;
    this.blobUrl = null;
    this.blobFile = null;
    this.pictureData = null;
  }
}
