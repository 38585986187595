<!-- Óra teszt kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType == 'ClockTest'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.ClockTest' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">
                <div id="png-canvas">
                    <div class="d-flex justify-content-center align-items-center">
                        <div class="m-1">
                            <dx-check-box [(value)]="wrResult.result1"
                                text="{{ 'Task.Conture' | translate:locale.language }}"></dx-check-box>
                        </div>
                        <div class="m-1">
                            <dx-check-box [(value)]="wrResult.result2"
                                text="{{ 'Task.Numbers' | translate:locale.language }}">
                            </dx-check-box>
                        </div>
                        <div class="m-1">
                            <dx-check-box [(value)]="wrResult.result3"
                                text="{{ 'Task.Pointers' | translate:locale.language }}">
                            </dx-check-box>
                        </div>
                        <!-- <dx-button *ngIf="!drawing" class="m-1" stylingMode="contained"
                        text="{{ 'Core.EditSave' | translate:locale.language }}" type="default"
                        useSubmitBehavior=true>
                    </dx-button> -->

                        <button *ngIf="!drawing" type="button" (click)="replayDrawing(0)" class="btn btn-link">
                            <i class="bi bi-repeat"></i> {{ 'Task.Replay' | translate:locale.language }}
                        </button>
                    </div>
                    <div
                        class="clock-test-info alert alert-primary d-flex justify-content-center align-items-center mt-2 mb-0">
                        <b>{{ 'Task.Time' | translate:locale.language }} </b>
                        <b class="text-primary h4 ms-2 m-0 ">{{ wrResult.timeToDraw }}</b>
                    </div>
                </div>
                <div class="drawingContainer mb-2" freehand [interactive]="false"></div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Tmt teszt kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType == 'TmtTest'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.TmtTest' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>

    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">

                <div class="drawingContainer my-2" freehand [interactive]="false"
                    [backGroundSource]="'assets/images/összekötő-bg.png'"></div>
                <div class="d-flex justify-content-center align-items-center">
                    <div class="m-1">
                        <dx-check-box [(value)]="wrResult.result1"
                            text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                    </div>
                    <button *ngIf="!drawing" type="button" (click)="replayDrawing(0)" class="btn btn-link">
                        <i class="bi bi-repeat"></i> {{ 'Task.Replay' | translate:locale.language }}
                    </button>
                    <!-- <ng-container *ngTemplateOutlet="buttons"></ng-container> -->
                </div>

            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Alakzat másoló teszt kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType == 'ShapeCopyingTest'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.ShapeCopyingTest' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">

                <div class="drawingContainer my-2" freehand [interactive]="false" [backGroundSource]="bgSource">
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <div class="m-1">
                        <dx-check-box [(value)]="wrResult.result1"
                            text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                    </div>

                    <button *ngIf="!drawing" type="button" (click)="replayDrawing(0)" class="btn btn-link">
                        <i class="bi bi-repeat"></i> {{ 'Task.Replay' | translate:locale.language }}
                    </button>
                    <!-- <ng-container *ngTemplateOutlet="buttons"></ng-container>-->
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Konstrukciós, alakzat másoló teszt kiértékelés (Adas-Cog) -->

<div *ngIf="evaluation && gameResultDetails.subType == 'Construction'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.Construction' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">

                <div class="d-flex mb-3">
                    <div class="constr-drawingContainer" freehand [interactive]="false">
                    </div>
                    <div class="constr-img-container d-md-bloc">
                        <div class="const-img d-flex flex-column">
                            <img src="../../../assets/images/kör.png">
                            <dx-check-box [(value)]="wrResult.result2"
                                text="{{ 'Task.Solved' | translate:locale.language }}">
                            </dx-check-box>
                        </div>
                        <div class="const-img d-flex flex-column ">
                            <img src="../../../assets/images/rombusz.png">
                            <dx-check-box [(value)]="wrResult.result3"
                                text="{{ 'Task.Solved' | translate:locale.language }}">
                            </dx-check-box>
                        </div>
                        <div class="const-img d-flex flex-column ">
                            <img src="../../../assets/images/2négyszög.png">
                            <dx-check-box [(value)]="wrResult.result4"
                                text="{{ 'Task.Solved' | translate:locale.language }}">
                            </dx-check-box>
                        </div>
                        <div class="const-img d-flex flex-column">
                            <img src="../../../assets/images/kocka.png">
                            <dx-check-box [(value)]="wrResult.result5"
                                text="{{ 'Task.Solved' | translate:locale.language }}">
                            </dx-check-box>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <div class="m-1">
                        <dx-check-box [(value)]="wrResult.result1"
                            text="{{ 'Game.PartlySolved' | translate:locale.language }}"></dx-check-box>
                    </div>

                    <button *ngIf="!drawing" type="button" (click)="replayDrawing(0)" class="btn btn-link">
                        <i class="bi bi-repeat"></i> {{ 'Task.Replay' | translate:locale.language }}
                    </button>
                </div>

            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Betű felismerő teszt kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType == 'LetterRecognition'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.LetterRecognition' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div id="png-canvas" class="d-flex flex-column align-items-center">

                <div class="drawingContainer-flexi my-2 bg-white d-flex flex-column justify-content-between">
                    <div class="p-2 d-flex flex-wrap justify-content-center">
                        <div class="card lr-card" *ngFor="let item of wrResult.letters; let i = index">
                            <div *ngIf="item.correct" class="card-body bg-success">
                                <span class="h3 text-white">{{item.letter}}</span>
                            </div>
                            <div *ngIf="!item.correct && item.hit" class="card-body bg-danger">
                                <span class="h3 text-white">{{item.letter}}</span>
                            </div>
                            <div *ngIf="item.letter === 'A' && !item.hit" class="card-body bg-info">
                                <span class="h3">{{item.letter}}</span>
                            </div>
                            <div *ngIf="item.letter !== 'A' && !item.hit" class="card-body bg-light">
                                <span class="h3">{{item.letter}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="bg-light p-2">
                        <h5>{{ 'Game.Legend' | translate:locale.language }}</h5>
                        <div class="d-flex">
                            <div class="card m-1 p-1 bg-success text-white">{{ 'Game.Correct' |
                                translate:locale.language }}</div>
                            <div class="card m-1 p-1 bg-danger text-white">{{ 'Game.Incorrect' |
                                translate:locale.language }}</div>
                            <div class="card m-1 p-1 bg-info">{{ 'Game.Missing' | translate:locale.language }}</div>
                            <!-- <div class="card m-1 p-1 bg-light">semleges</div> -->
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <div class="m-1">
                        <dx-check-box [(value)]="wrResult.result1"
                            text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                    </div>

                    <!-- <ng-container *ngTemplateOutlet="buttons"></ng-container> -->
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Mondat ismétlő teszt kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType == 'RepeatSentences'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.RepeatSentences' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">

                <div id="png-canvas" class="drawingContainer-flexi bg-white p-3 my-2">
                    <div class="d-flex flex-column align-items-center mb-3">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <strong>{{wrResult.sentence1}}</strong>
                            <div class="m-1">
                                <dx-check-box [(value)]="wrResult.result1"
                                    text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                            </div>
                        </div>

                        <audio controls="" *ngIf="blobUrl1" class="w-100">
                            <source [src]="sanitize(blobUrl1)" type="audio/wav">
                        </audio>
                        <audio controls="" *ngIf="audioUrl1" class="w-100">
                            <source [src]="audioUrl1" type="audio/wav">
                        </audio>
                    </div>
                    <div *ngIf="wrResult.sentence2" class="d-flex flex-column align-items-center mb-3">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <strong>{{wrResult.sentence2}}</strong>
                            <div class="m-1">
                                <dx-check-box [(value)]="wrResult.result2"
                                    text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                            </div>
                        </div>
                        <audio controls="" *ngIf="blobUrl2" class="w-100">
                            <source [src]="sanitize(blobUrl2)" type="audio/wav">
                        </audio>
                        <audio controls="" *ngIf="audioUrl2" class="w-100">
                            <source [src]="audioUrl2" type="audio/wav">
                        </audio>
                    </div>
                </div>

            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Betűzés visszafelé teszt kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType == 'SpellBackwards'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.SpellBackwards' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">

                <div class="drawingContainer-flexi bg-white p-3 my-2">
                    <div class="d-flex flex-column align-items-center mb-3">
                        <div class="d-flex justify-content-center align-items-center w-100">
                            <h5>{{wrResult.sentence1}}</h5>
                        </div>

                        <audio controls="" *ngIf="blobUrl1" class="w-100">
                            <source [src]="sanitize(blobUrl1)" type="audio/wav">
                        </audio>
                    </div>
                    <div class="d-block d-sm-flex justify-content-center align-items-center">
                        <div class="card w-50 m-2">
                            <div class="card-body text-center">
                                <h4>{{wrResult.sentence1[4]}}</h4>
                                <div>
                                    <dx-check-box [(value)]="wrResult.result1">
                                    </dx-check-box>
                                </div>
                            </div>
                        </div>
                        <div class="card w-50 m-2">
                            <div class="card-body text-center">
                                <h4>{{wrResult.sentence1[3]}}</h4>
                                <div>
                                    <dx-check-box [(value)]="wrResult.result2">
                                    </dx-check-box>
                                </div>
                            </div>
                        </div>
                        <div class="card w-50 m-2">
                            <div class="card-body text-center">
                                <h4>{{wrResult.sentence1[2]}}</h4>
                                <div>
                                    <dx-check-box [(value)]="wrResult.result3">
                                    </dx-check-box>
                                </div>
                            </div>
                        </div>
                        <div class="card w-50 m-2">
                            <div class="card-body text-center">
                                <h4>{{wrResult.sentence1[1]}}</h4>
                                <div>
                                    <dx-check-box [(value)]="wrResult.result4">
                                    </dx-check-box>
                                </div>
                            </div>
                        </div>
                        <div class="card w-50 m-2">
                            <div class="card-body text-center">
                                <h4>{{wrResult.sentence1[0]}}</h4>
                                <div>
                                    <dx-check-box [(value)]="wrResult.result5">
                                    </dx-check-box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Fluencia teszt kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType == 'Fluencia'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.Fluencia' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>

    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div id="png-canvas" class="d-flex flex-column align-items-center">

                <div class="drawingContainer-flexi bg-white p-3 my-2">
                    <div>
                        <audio controls="" *ngIf="blobUrl1" class="w-100">
                            <source [src]="sanitize(blobUrl1)" type="audio/wav">
                        </audio>
                        <audio controls="" *ngIf="audioUrl1" class="w-100">
                            <source [src]="audioUrl1" type="audio/wav">
                        </audio>
                    </div>
                </div>
                <div class="d-flex justify-content-center align-items-center">
                    <div class="m-1">
                        <dx-check-box [(value)]="wrResult.result1"
                            text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                    </div>
                    <!-- <ng-container *ngTemplateOutlet="buttons"></ng-container> -->

                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>


<!-- Szám ismétlő teszt kiértékelés -->
<div *ngIf="evaluation && gameResultDetails.subType == 'RepeatNumbers'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.RepeatNumbers' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>

    </div>
    <div class="m-3">

        <div class="d-flex flex-column align-items-center">
            <div id="png-canvas">
                <div class="drawingContainer-flexi bg-white p-3 my-2">
                    <div class="d-flex justify-content-between align-items-center w-100">
                        <strong>{{ 'Game.RepeatNumbers' | translate:locale.language }}</strong>
                        <div class="m-1">
                            <dx-check-box [(value)]="wrResult.result1"
                                text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                        </div>
                    </div>
                    <table class="table table-bordered text-center bg-white">
                        <tbody>
                            <tr>
                                <td style="width:200px">{{ 'Task.NumbersFact' | translate:locale.language }}:</td>
                                <td *ngFor="let number of wrResult.numbers1">{{number}}</td>
                            </tr>
                            <tr>
                                <td style="width:200px">{{ 'Task.PatientAnswers' | translate:locale.language }}:</td>
                                <td *ngFor="let answer of wrResult.answer1"
                                    [ngClass]="answer.correct ? 'table-success' : 'table-danger'">{{answer.answer}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="drawingContainer-flexi bg-white p-3 my-2">
                    <div class="d-flex justify-content-between align-items-center">
                        <strong>{{ 'Game.RepeatNumbersReverse' | translate:locale.language }}</strong>
                        <div class="m-1">
                            <dx-check-box [(value)]="wrResult.result2"
                                text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                        </div>
                    </div>
                    <table class="table table-bordered text-center bg-white">
                        <tbody>
                            <tr>
                                <td style="width:200px">{{ 'Task.NumbersFact' | translate:locale.language }}:</td>
                                <td *ngFor="let number of wrResult.numbers2">{{number}}</td>
                            </tr>
                            <tr>
                                <td style="width:200px">{{ 'Task.PatientAnswers' | translate:locale.language }}:</td>
                                <td *ngFor="let answer of wrResult.answer2"
                                    [ngClass]="answer.correct ? 'table-success' : 'table-danger'">{{answer.answer}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">
                <div class="d-flex justify-content-center align-items-center">
                    <!-- <ng-container *ngTemplateOutlet="buttons"></ng-container> -->
                </div>
            </form>
        </div>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Visszaszámláló teszt kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType == 'CountDownTest'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.CountDownTest' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>

    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div id="png-canvas" class="d-flex flex-column align-items-center">

                <div class="drawingContainer-flexi bg-white p-3 my-2">
                    <table class="table table-bordered text-center bg-white">
                        <tbody>
                            <tr>
                                <td style="width:200px">{{ 'Task.NumbersExpected' | translate:locale.language }}:
                                </td>
                                <td *ngFor="let number of wrResult.numbers1">{{number}}</td>
                            </tr>
                            <tr>
                                <td style="width:200px">{{ 'Task.PatientAnswers' | translate:locale.language }}:
                                </td>
                                <td *ngFor="let answer of wrResult.answer1"
                                    [ngClass]="answer.correct ? 'table-success' : 'table-danger'">{{answer.answer}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="d-flex justify-content-between align-items-center" style="width:610px">
                    <div class="me-1 text-center"> {{ 'Game.GoodAnswers' | translate:locale.language }}:<br>
                        {{wrResult.result1}}
                    </div>
                    <div class="me-1 text-center"> {{ 'Core.Result' | translate:locale.language }}:<br>
                        {{wrResult.resultNumeric}}
                    </div>
                </div>

            </div>

        </form>

        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Kép felismerő teszt kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType == 'PictureRecognition'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.PictureRecognition' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>

    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">

                <div id="png-canvas" class="row row cols-1 row-cols-md-3 g-3">
                    <div class="col">
                        <div class="card m-2 shadow h-100">
                            <div class="card-body">
                                <div>
                                    <dx-check-box [(value)]="wrResult.result1"
                                        text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                                </div>
                            </div>
                            <img src="{{src[0]}}" class="card-img-top" alt="card">
                            <div class="card-body">
                                <h3 class="text-center">
                                    {{wrResult.words[0]}}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div class="card m-2 shadow h-100">
                            <div class="card-body">
                                <div>
                                    <dx-check-box [(value)]="wrResult.result2"
                                        text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                                </div>
                            </div>
                            <img src="{{src[1]}}" class="card-img-top" alt="card">
                            <div class="card-body">
                                <h3 class="text-center">
                                    {{wrResult.words[1]}}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="col">
                        <div *ngIf="src[2]" class="card m-2 shadow h-100">
                            <div class="card-body">
                                <div>
                                    <dx-check-box [(value)]="wrResult.result3"
                                        text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                                </div>
                            </div>
                            <img src="{{src[2]}}" class="card-img-top" alt="card">
                            <div class="card-body">
                                <h3 class="text-center">
                                    {{wrResult.words[2]}}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center m-3">
                    <!-- <ng-container *ngTemplateOutlet="buttons"></ng-container> -->
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Tárgyak megnevezése teszt kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType == 'NamingObjects'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.PictureRecognition' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>

    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center container">

                <div class="row row-cols-2 row-cols-xl-4 g-3">
                    <div *ngFor="let word of wrResult.words; let i = index" class="col">
                        <div class="card h-100">
                            <div class="card-body">
                                <div>
                                    <dx-check-box [(value)]="wrResult.recallRounds[0].guessWords[i].correct"
                                        text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                                </div>
                            </div>
                            <img src="../../../assets/images/picture-recognition/adas-cog/{{i}}.jpg"
                                class="card-img-top" alt="card">
                            <div class="card-body">
                                <h3 class="text-center">
                                    {{wrResult.recallRounds[0].guessWords[i].guessWord}}
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-center m-3">
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Emlékezet (Késleltetett felidézés kiértékelés) -->

<div *ngIf="evaluation && gameResultDetails.subType == 'DelayedRecall'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.DelayedRecall' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>

    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">

                <div class="drawingContainer-flexi bg-white p-3">
                    <table id="png-canvas" class="table table-bordered text-center bg-white m-0">
                        <tbody>
                            <tr>
                                <th>{{ 'Task.Task' | translate:locale.language }}</th>
                                <th *ngFor="let word of wrResult.words">
                                    {{word}}
                                </th>
                            </tr>
                            <tr *ngFor="let round of wrResult.recallRounds; let i = index">
                                <td *ngIf="i == 0">{{ 'Game.Probe1' | translate:locale.language }}</td>
                                <td *ngIf="i == 1">{{ 'Game.Probe2' | translate:locale.language }}</td>
                                <td *ngIf="i == 2">{{ 'Game.WithoutHelp' | translate:locale.language }}</td>
                                <td *ngFor="let answer of round.guessWords"
                                    [ngClass]="answer.correct ? 'table-success' : 'table-danger'">
                                    {{answer.guessWord}}</td>
                            </tr>
                            <tr *ngIf="wrResult.recallRounds[2]">
                                <td>{{ 'Task.Solved' | translate:locale.language }}</td>
                                <td *ngFor="let answer of wrResult.recallRounds[2].guessWords">
                                    <dx-check-box [(value)]="answer.correct"></dx-check-box>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Nyelv-Absztrakció kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType == 'Abstraction'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.Abstraction' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>

    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">

                <div id="png-canvas" class="drawingContainer-flexi bg-light p-3 my-2">
                    <div class="d-block d-sm-flex justify-content-center align-items-center">
                        <div class="card w-50 m-2">
                            <div class="card-body text-center">
                                <h4>{{ 'Game.A1' | translate:locale.language }}</h4>
                                <div>
                                    <dx-check-box [(value)]="wrResult.result1"
                                        text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                                </div>
                            </div>
                        </div>
                        <div class="card w-50 m-2">
                            <div class="card-body text-center">
                                <h4>{{ 'Game.A2' | translate:locale.language }}</h4>
                                <div>
                                    <dx-check-box [(value)]="wrResult.result2"
                                        text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </form>

        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>

        <app-instruction [testName]="'abstraction'" [title]="evaluationGuide">
        </app-instruction>
    </div>
</div>

<!-- Orientáció kiértékelés -->
<div *ngIf="evaluation && gameResultDetails.subType == 'Orientation'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.Orientation' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">

                <div id="png-canvas" class="drawingContainer-flexi bg-light p-3">
                    <div class="d-block d-sm-flex justify-content-center align-items-center">
                        <div class="card m-2 w-33">
                            <div class="card-body text-center">
                                <h4>{{ 'Core.Date' | translate:locale.language }}</h4>
                                <div>
                                    <dx-check-box [(value)]="wrResult.result1"
                                        text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                                </div>
                            </div>
                        </div>
                        <div class="card m-2 w-33">
                            <div class="card-body text-center">
                                <h4>{{ 'Game.Month' | translate:locale.language }}</h4>
                                <div>
                                    <dx-check-box [(value)]="wrResult.result2"
                                        text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                                </div>
                            </div>
                        </div>
                        <div class="card m-2 w-33">
                            <div class="card-body text-center">
                                <h4>{{ 'Game.Year' | translate:locale.language }}</h4>
                                <div>
                                    <dx-check-box [(value)]="wrResult.result3"
                                        text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-block d-sm-flex justify-content-center align-items-center">
                        <div class="card m-2 w-33">
                            <div class="card-body text-center">
                                <h4>{{ 'Game.DayOfWeek' | translate:locale.language }}</h4>
                                <div>
                                    <dx-check-box [(value)]="wrResult.result4"
                                        text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                                </div>
                            </div>
                        </div>
                        <div class="card m-2 w-33">
                            <div class="card-body text-center">
                                <h4>{{ 'Game.Location' | translate:locale.language }}</h4>
                                <div>
                                    <dx-check-box [(value)]="wrResult.result5"
                                        text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                                </div>
                            </div>
                        </div>
                        <div class="card m-2 w-33">
                            <div class="card-body text-center">
                                <h4>{{ 'Project.City' | translate:locale.language }}</h4>
                                <div>
                                    <dx-check-box [(value)]="wrResult.result6"
                                        text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>

        <app-instruction [testName]="'orientation'" [title]="evaluationGuide">
        </app-instruction>

    </div>
</div>

<!-- Orientáció (Adas-Cog) kiértékelés -->
<div *ngIf="evaluation && gameResultDetails.subType == 'OrientationAC'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.Orientation' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>

    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">

                <div class="drawingContainer-flexi bg-light p-3">
                    <table class="table table-bordered text-center bg-white">
                        <tbody>
                            <tr>
                                <th>{{ 'Game.Questions' | translate:locale.language }}
                                </th>
                                <th>{{ 'Task.Solved' | translate:locale.language }}
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.FirstName' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result1">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.LastName' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result2">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.WhatDayName' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result3">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.WhatDay' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result4">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.WhatMonth' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result5">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.WhatYear' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result6">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.PartOfDay' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result7">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.WhereWeAre' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result8">
                                    </dx-check-box>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Bónusz pontok -->
<div *ngIf="evaluation && gameResultDetails.subType == 'Bonus'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.Bonus' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>

    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">

                <div id="png-canvas" class="drawingContainer-flexi bg-light p-3">
                    <div class="d-block d-sm-flex justify-content-center align-items-center">
                        <div class="card m-2 w-75">
                            <div class="card-body text-center">
                                <h4>{{ 'Game.LowEducation' | translate:locale.language }}</h4>
                                <div>
                                    <dx-check-box [(value)]="wrResult.result1"
                                        text="{{ 'Core.Add' | translate:locale.language }}"></dx-check-box>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- BVMT kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType == 'BVMT-R'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.BVMT-R' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form>

            <div class="d-flex flex-column align-items-center">

                <div class="drawingContainer-flexi-wide p-3 my-2">
                    <div class="d-flex">
                        <div>
                            <ul class="nav nav-tabs" id="trialNav" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" (click)="setBvmtRound(0)" id="trial1-tab"
                                        data-bs-toggle="tab" data-bs-target="#trial1" type="button" role="tab"
                                        aria-controls="home" aria-selected="true">{{ 'Task.Round1' |
                                        translate:locale.language }}</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" (click)="setBvmtRound(1)" id="trial2-tab"
                                        data-bs-toggle="tab" data-bs-target="#trial1" type="button" role="tab"
                                        aria-controls="profile" aria-selected="false">{{ 'Task.Round2' |
                                        translate:locale.language }}</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" (click)="setBvmtRound(2)" id="trial3-tab"
                                        data-bs-toggle="tab" data-bs-target="#trial1" type="button" role="tab"
                                        aria-controls="profile" aria-selected="false">{{ 'Task.Round3' |
                                        translate:locale.language }}</button>
                                </li>
                            </ul>
                            <div class="d-flex">
                                <div class="tab-content" id="trialContent">
                                    <div class="tab-pane fade show active" id="trial1" role="tabpanel"
                                        aria-labelledby="trial1-tab">
                                        <div class="bvmt-drawingContainer mb-2" freehand [interactive]="false">
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="bvmt-refimg-container px-4 pb-4">
                                        <h5>{{ 'Task.ReferenceView' | translate:locale.language }}</h5>
                                        <div>
                                            <img class="w-100" src="../assets/images/bvmt/bvmt-sheet1-notation.svg">
                                            <!-- <img class="w-100" src="../assets/images/bvmt/bvmt-sheet2-notation.svg"> -->
                                        </div>
                                    </div>
                                    <div class="px-4">
                                        <table class="table table-sm w-100">
                                            <tbody>
                                                <tr>
                                                    <th>{{ 'Task.Figure' | translate:locale.language }}</th>
                                                    <th>1.</th>
                                                    <th>2.</th>
                                                    <th>3.</th>
                                                    <th>4.</th>
                                                    <th>5.</th>
                                                    <th>6.</th>
                                                </tr>
                                                <tr>
                                                    <td>{{ 'Task.Correctness' | translate:locale.language }} </td>
                                                    <td
                                                        *ngFor="let result of wrResult.bvmts[bvmtRound].bvmtResults; index as i">
                                                        <dx-check-box [(value)]="result.shape"></dx-check-box>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{{ 'Task.Position' | translate:locale.language }}</td>
                                                    <td *ngFor="let result of wrResult.bvmts[bvmtRound].bvmtResults">
                                                        <dx-check-box [(value)]="result.position"></dx-check-box>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>

            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- SDMT kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType == 'SDMT'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.SDMTtest' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">

        <div class="d-flex flex-column align-items-center">
            <div class="end-screen">
                <div class="card  mt-4" style="width: 600px;">
                    <div class="card-body text-white text-center bg-info">
                        <h2 class="card-title">
                            <span>
                                {{ 'Core.Result' | translate:locale.language }}
                            </span>
                        </h2>
                        <ul class="list-group list-group-flush mb-3">
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <span>{{ 'Game.TotalShapes' | translate:locale.language }}:</span>
                                <b>{{wrResult.allItems}}</b>
                            </li>
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <span>{{ 'Game.GoodAnswers' | translate:locale.language }}:</span>
                                <b>{{wrResult.correctAnswers}}</b>
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <span>{{ 'Game.WrongAnswers' | translate:locale.language }}:</span>
                                <b>{{wrResult.errors}}</b>
                            <li class="list-group-item d-flex justify-content-between align-items-start">
                                <span>{{ 'Game.MissingAnswers' | translate:locale.language }}:</span>
                                <b>{{wrResult.empty}}</b>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Szó visszaidéző (word-list recall) kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType =='WordRecall'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.WordListRecall' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">

        <div class="d-flex flex-column align-items-center">
            <div class="alert alert-success w-100">
                <h5>{{ 'Game.TotalWords1' | translate:locale.language }}:</h5>
                <span class="badge bg-success m-1" *ngFor="let word of wrResult.words; index as r">{{word}}</span>
            </div>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                <div *ngFor="let round of wrResult.recallRounds; index as r" class="col mb-3">
                    <ul class="list-group">
                        <li class="list-group-item">
                            <span>{{ 'Game.Round' | translate:locale.language }}: </span>
                            <b>{{r+1}}</b>
                        </li>
                        <li class="list-group-item">
                            <span>{{ 'Game.FoundWords' | translate:locale.language }}: </span>
                            <b>{{round.correctHits}}</b>
                        </li>
                        <li class="list-group-item">
                            <span>{{ 'Game.Score1' | translate:locale.language }}: </span>
                            <b>{{round.roundResult | number : '1.2-2'}}</b>
                        </li>
                        <li class="list-group-item">
                            <ul class="result-details">
                                <li *ngFor="let word of round.guessWords"
                                    [ngClass]="word.correct ? 'correct-list-item' : 'wrong-list-item'">
                                    {{word.guessWord}}
                                </li>
                            </ul>
                        </li>

                    </ul>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- Szó visszaidéző, felismerő (word-list recall Adas-Cog) kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType =='WordListRecallAc'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.WordListRecallAc' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">

        <div class="d-flex flex-column align-items-center">
            <div class="alert alert-success w-100 mb-3">
                <span>{{ 'Game.TotalWords1' | translate:locale.language }}: </span>
                <b>{{wrResult.itemCount}}</b>
            </div>
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
                <div *ngFor="let round of wrResult.recallRounds; index as r" class="col mb-3">
                    <ul class="list-group">
                        <li class="list-group-item">
                            <span>{{ 'Game.Round' | translate:locale.language }}: </span>
                            <b>{{r+1}}</b>
                        </li>
                        <!-- <li class="list-group-item">
                            <span>{{ 'Game.FoundWords' | translate:locale.language }}: </span>
                            <b>{{round.correctHits}}</b>
                        </li> -->
                        <li class="list-group-item">
                            <span>{{ 'Game.Score1' | translate:locale.language }}:</span>
                            <b>{{round.roundResult | number : '1.2-2'}}</b>
                        </li>
                        <li class="list-group-item">
                            <ul class="result-details">
                                <li *ngFor="let word of round.guessWords"
                                    [ngClass]="word.correct ? 'correct-list-item' : word.answer != undefined ? 'wrong-list-item' : ''"
                                    [style.background-color]="word.correctedWord == 'A' ? '#e4f1d5' : ''">
                                    {{word.guessWord}}
                                </li>
                            </ul>
                        </li>

                    </ul>
                </div>
            </div>
        </div>

    </div>
</div>

<!-- Időbeli orientáció kiértékelés -->
<div *ngIf="evaluation && gameResultDetails.subType == 'TimeOrientation'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.TimeOrientation' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">
                <div class="drawingContainer-flexi bg-light p-3">
                    <table class="table table-bordered text-center bg-white">
                        <tbody>
                            <tr>
                                <td colspan="5">
                                    {{ 'Core.Created' | translate:locale.language }}: {{
                                    dataSource[evaluationStep].startTime | date: dateFormat}}
                                </td>
                            </tr>
                            <tr>
                                <td>{{ 'Game.Year' | translate:locale.language }}
                                </td>
                                <td>{{ 'Game.Season' | translate:locale.language }}
                                </td>
                                <td>{{ 'Game.Month' | translate:locale.language }}
                                </td>
                                <td>{{ 'Core.Days' | translate:locale.language }}
                                </td>
                                <td>{{ 'Game.DayName' | translate:locale.language }}
                                </td>
                            </tr>
                            <tr>
                                <td [ngClass]="wrResult.answer1[0].correct ? 'table-success' : 'table-danger'">
                                    {{wrResult.answer1[0].answer}}
                                </td>
                                <td [ngClass]="wrResult.answer1[1].correct ? 'table-success' : 'table-danger'">
                                    {{wrResult.answer1[1].answer | codeTranslate:seasonCodes}}
                                </td>
                                <td [ngClass]="wrResult.answer1[2].correct ? 'table-success' : 'table-danger'">
                                    {{wrResult.answer1[2].answer | codeTranslate:monthCodes}}
                                </td>
                                <td [ngClass]="wrResult.answer1[3].correct ? 'table-success' : 'table-danger'">
                                    {{wrResult.answer1[3].answer}}
                                </td>
                                <td [ngClass]="wrResult.answer1[4].correct ? 'table-success' : 'table-danger'">
                                    {{wrResult.answer1[4].answer | codeTranslate:dayNameCodes}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Térbeli orientáció kiértékelés -->
<div *ngIf="evaluation && gameResultDetails.subType == 'SpatialOrientation'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.SpatialOrientation' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">
                <div class="drawingContainer-flexi bg-light p-3">
                    <table class="table table-bordered text-center bg-white">
                        <tbody>
                            <tr>
                                <th>{{ 'Game.Questions' | translate:locale.language }}
                                </th>
                                <th>{{ 'Task.Solved' | translate:locale.language }}
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.WhatCounty' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result1">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.WhatCountry' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result2">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.WhatCity' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result3">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.NamePlace' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result4">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.WhatFloor' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result5">
                                    </dx-check-box>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Hármas parancs kiértékelés -->
<div *ngIf="evaluation && gameResultDetails.subType == 'TripleCommand'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.TripleCommand' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">
                <div class="drawingContainer-flexi bg-light p-3">
                    <div>
                        {{ 'Game.TripleCommandText' | translate:locale.language }}
                    </div>
                    <br>
                    <table class="table table-bordered text-center bg-white">
                        <tbody>
                            <tr>
                                <th>{{ 'Game.Commands' | translate:locale.language }}
                                </th>
                                <th>{{ 'Task.Solved' | translate:locale.language }}
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Command1' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result1">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Command2' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result2">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Command3' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result3">
                                    </dx-check-box>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
        <app-instruction [testName]="'tripleCommand'" [title]="evaluationGuide">
        </app-instruction>
    </div>
</div>

<!-- Parancs kiértékelés -->
<div *ngIf="evaluation && gameResultDetails.subType == 'Commands'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.Commands' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>

    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">
                <div class="drawingContainer-flexi bg-light p-3">
                    <table class="table table-bordered text-center bg-white">
                        <tbody>
                            <tr>
                                <th>
                                </th>
                                <th>{{ 'Task.Solved' | translate:locale.language }}
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Command11' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result1">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Command12' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result2">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Command13' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result3">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Command14' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result4">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Command15' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result5">
                                    </dx-check-box>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
        <app-instruction [testName]="'commands'" [title]="evaluationGuide">
        </app-instruction>
    </div>
</div>

<!-- Feladat végrahajtás kiértékelés -->
<div *ngIf="evaluation && gameResultDetails.subType == 'TaskExecution'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.TaskExecution' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>

    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">
                <div class="drawingContainer-flexi bg-light p-3">
                    <table class="table table-bordered text-center bg-white">
                        <tbody>
                            <tr>
                                <th>
                                    {{ 'Game.Commands' | translate:locale.language }}
                                </th>
                                <th>{{ 'Task.Solved' | translate:locale.language }}
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Command21' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result1">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Command22' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result2">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Command23' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result3">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Command24' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result4">
                                    </dx-check-box>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Command25' | translate:locale.language }}
                                </td>
                                <td>
                                    <dx-check-box [(value)]="wrResult.result5">
                                    </dx-check-box>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
        <app-instruction [testName]="'taskExecution'" [title]="evaluationGuide">
        </app-instruction>
    </div>
</div>

<!-- Emlékezet (Megjegyző emlékezés kiértékelés) -->

<div *ngIf="evaluation && gameResultDetails.subType == 'Memorizing'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.Memorizing' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">
                <div class="drawingContainer-flexi bg-white p-3">
                    <table class="table table-bordered text-center bg-white m-0">
                        <tbody>
                            <tr>
                                <th>{{ 'Task.Task' | translate:locale.language }}</th>
                                <th *ngFor="let word of wrResult.words">
                                    {{word}}
                                </th>
                            </tr>
                            <tr *ngIf="wrResult.recallRounds[0]">
                                <td>{{ 'Task.Solved' | translate:locale.language }}</td>
                                <td *ngFor="let answer of wrResult.recallRounds[0].guessWords">
                                    <dx-check-box [(value)]="answer.correct"></dx-check-box>
                                </td>
                            </tr>

                            <tr *ngFor="let round of wrResult.recallRounds; let i = index">
                                <td *ngIf="round.roundResult == 0">{{i+1}}.{{ 'Game.Probe' |
                                    translate:locale.language }}</td>
                                <td *ngIf="round.roundResult == 1">{{ 'Game.WithoutHelp' |
                                    translate:locale.language }}</td>
                                <td *ngFor="let answer of round.guessWords"
                                    [ngClass]="answer.correct ? 'table-success' : 'table-danger'">
                                    {{answer.guessWord}}</td>
                            </tr>
                            <tr *ngIf="wrResult.recallRounds[wrResult.recallRounds.length - 1].roundResult == 1">
                                <td>{{ 'Task.Solved' | translate:locale.language }}</td>
                                <td
                                    *ngFor="let answer of wrResult.recallRounds[wrResult.recallRounds.length - 1].guessWords">
                                    <dx-check-box [(value)]="answer.correct"></dx-check-box>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Olvasás megértés -->
<div *ngIf="evaluation && gameResultDetails.subType == 'ReadingComprehension'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.ReadingComprehension' | translate:locale.language }}
            </h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">
                <div class="drawingContainer-flexi bg-light p-3">
                    <div class="d-block d-sm-flex justify-content-center align-items-center">
                        <div class="card m-2 w-75">
                            <div class="card-body text-center">
                                <h5>{{ 'Task.TaskSolved' | translate:locale.language }}</h5>
                                <div>
                                    <dx-check-box [(value)]="wrResult.result1"
                                        text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </form>

        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
        <app-instruction [testName]="'readingComprehension'" [title]="evaluationGuide">
        </app-instruction>

    </div>
</div>

<!-- Írás készség teszt kiértékelés -->

<div *ngIf="evaluation && gameResultDetails.subType == 'Writing'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.Writing' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">
                <div class="drawingContainer-flexi bg-white p-3 my-2">
                    <div class="d-flex flex-column align-items-center mb-3">
                        <div class="d-flex justify-content-between align-items-center w-100">
                            <strong>{{wrResult.sentence1}}</strong>
                            <div class="m-1">
                                <dx-check-box [(value)]="wrResult.result1"
                                    text="{{ 'Task.Solved' | translate:locale.language }}"></dx-check-box>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
        <app-instruction [testName]="'writing'" [title]="evaluationGuide">
        </app-instruction>
    </div>
</div>

<!-- Beszédkészség kiértékelés -->
<div *ngIf="evaluation && gameResultDetails.subType == 'SpeakingSkills'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.SpeakingSkills' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">
                <div class="drawingContainer-flexi bg-light p-3">
                    <table class="table table-bordered text-center bg-white">
                        <tbody>
                            <tr>
                                <td>
                                    {{ 'Game.Skill0' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 0" value="0"
                                        (click)="onSkillChanged(0)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault0" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill1' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 1" value="1"
                                        (click)="onSkillChanged(1)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault1" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill2' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 2" value="2"
                                        (click)="onSkillChanged(2)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault2" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill3' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 3" value="3"
                                        (click)="onSkillChanged(3)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault3" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill4' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 4" value="4"
                                        (click)="onSkillChanged(4)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault4" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill5' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 5" value="5"
                                        (click)="onSkillChanged(5)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault5" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Beszéd megértés kiértékelés -->
<div *ngIf="evaluation && gameResultDetails.subType == 'SpeechUnderstanding'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.SpeechUnderstanding' | translate:locale.language }}</h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">
                <div class="drawingContainer-flexi bg-light p-3">
                    <table class="table table-bordered text-center bg-white">
                        <tbody>
                            <tr>
                                <td>
                                    {{ 'Game.Skill10' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 0" value="0"
                                        (click)="onSkillChanged(0)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault0" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill11' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 1" value="1"
                                        (click)="onSkillChanged(1)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault1" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill12' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 2" value="2"
                                        (click)="onSkillChanged(2)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault2" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill13' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 3" value="3"
                                        (click)="onSkillChanged(3)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault3" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill14' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 4" value="4"
                                        (click)="onSkillChanged(4)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault4" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill15' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 5" value="5"
                                        (click)="onSkillChanged(5)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault5" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Szókeresési nehézség kiértékelés -->
<div *ngIf="evaluation && gameResultDetails.subType == 'WordFindingDifficulty'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.WordFindingDifficulty' | translate:locale.language }}
            </h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>

    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">
                <div class="drawingContainer-flexi bg-light p-3">
                    <table class="table table-bordered text-center bg-white">
                        <tbody>
                            <tr>
                                <td>
                                    {{ 'Game.Skill20' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 0" value="0"
                                        (click)="onSkillChanged(0)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault0" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill21' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 1" value="1"
                                        (click)="onSkillChanged(1)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault1" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill22' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 2" value="2"
                                        (click)="onSkillChanged(2)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault2" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill23' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 3" value="3"
                                        (click)="onSkillChanged(3)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault3" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill24' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 4" value="4"
                                        (click)="onSkillChanged(4)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault4" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill25' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 5" value="5"
                                        (click)="onSkillChanged(5)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault5" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Vizsgálati utasítások megjegyzése kiértékelés -->
<div *ngIf="evaluation && gameResultDetails.subType == 'RememberingInstructions'">
    <div class="m-3">
        <ng-container *ngTemplateOutlet="header"></ng-container>
        <div class="d-flex justify-content-between align-items-center mb-3">
            <ng-container *ngTemplateOutlet="previousTask"></ng-container>
            <h2 class="fw-light mb-0 mx-4 text-center">{{ 'Game.RememberingInstructions' | translate:locale.language }}
            </h2>
            <ng-container *ngTemplateOutlet="nextTask"></ng-container>
        </div>
        <hr>
    </div>
    <div class="m-3">
        <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">

            <div class="d-flex flex-column align-items-center">
                <div class="drawingContainer-flexi bg-light p-3">
                    <table class="table table-bordered text-center bg-white">
                        <tbody>
                            <tr>
                                <td>
                                    {{ 'Game.Skill30' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 0" value="0"
                                        (click)="onSkillChanged(0)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault0" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill31' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 1" value="1"
                                        (click)="onSkillChanged(1)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault1" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill32' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 2" value="2"
                                        (click)="onSkillChanged(2)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault2" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill33' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 3" value="3"
                                        (click)="onSkillChanged(3)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault3" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill34' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 4" value="4"
                                        (click)="onSkillChanged(4)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault4" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {{ 'Game.Skill35' | translate:locale.language }}
                                </td>
                                <td>
                                    <input class="form-check-input" [checked]="wrResult.resultNumeric === 5" value="5"
                                        (click)="onSkillChanged(5)" type="radio" name="flexRadioDefault"
                                        id="flexRadioDefault5" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</div>

<!-- Összesítő nézet modal -->
<div class="modal fade" id="summaryModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ 'Project.Summary' | translate:locale.language }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">

                <div class="list-group">
                    <button *ngFor="let result of wrResultList; index as i" type="button"
                        class="list-group-item list-group-item-action ps-2" (click)="gotoEvaluation(i)"
                        data-bs-dismiss="modal" aria-current="true">
                        <div class=" d-flex justify-content-between align-items-center">
                            <div class="d-flex align-items-center">
                                <div [ngClass]="getTaskBg(result.subType)"><i
                                        [ngClass]="getTaskClass(result.subType)"></i></div>
                                <span>{{ 'Game.' + result.subType | translate:locale.language }}</span>
                            </div>
                            <span> {{result.resultNumeric | number : '1.2-2'}}</span>
                        </div>
                    </button>

                </div>
                <div *ngIf="dataSource" class=" d-flex justify-content-between p-3 fw-bold" data-bs-dismiss="modal">
                    <span>{{ 'Core.Summ' | translate:locale.language }}</span>
                    <span>{{dataSource[0].resultNumeric | number : '1.2-2'}}</span>
                </div>
                <!-- <div class="card rounded m-2" *ngFor="let result of wrResultList; index as i">
                    <div class="card-body d-flex justify-content-between" (click)="gotoEvaluation(i)"
                        data-bs-dismiss="modal">
                        <span>{{ 'Game.' + result.subType | translate:locale.language }}</span>
                        <span> {{result.resultNumeric | number : '1.2-2'}}</span>
                    </div>
                </div> 
                <div class="card rounded m-2">
                    <div *ngIf="dataSource" class="card-body d-flex justify-content-between" data-bs-dismiss="modal">

                        <span>{{ 'Core.Summ' | translate:locale.language }}</span>
                        <span>{{dataSource[0].resultNumeric | number : '1.2-2'}}</span>
                    </div>
                </div>-->
            </div>
        </div>
    </div>
</div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" (onShown)="onShown()">
</dx-load-panel>

<!-- <ng-template #buttons>
    <div>
        <button *ngIf="showBack && !drawing && savedx && uploaded" type="button" (click)="previousTask()" class="btn btn-neutral m-1">
            {{ 'Core.Previous' | translate:locale.language }} - {{savedx}}

        </button>
        <button *ngIf="showNext && !drawing && savedx && uploaded" type="button" (click)="nextTask()" class="btn btn-neutral m-1">
            {{ 'Core.Next' | translate:locale.language }} -  {{savedx}}

        </button>
    </div>
</ng-template> -->

<ng-template #header>
    <div class="d-md-flex justify-content-between align-items-center">
        <h1 *ngIf="!viewLayout" class="display-6 d-block">{{ 'Task.TestEvaluation' | translate:locale.language }}</h1>
        <h1 *ngIf="viewLayout" class="display-6 d-block">{{ 'Task.ViewTaskSheet' | translate:locale.language }}</h1>
        <div class="text-end">
            <button type="button" (click)="closeModal()" class="btn btn-neutral m-1">
                {{ 'Core.EditBack' | translate:locale.language }}
            </button>
            <!-- <button *ngIf="!summaryLayout" type="button" (click)="showSummary()" class="btn btn-warning m-1">
                {{ 'Project.Summary' | translate:locale.language }}
            </button> -->
            <button *ngIf="gameResultDetails.gameType != 'BICAMS'" type="button" class="btn btn-primary"
                data-bs-toggle="modal" data-bs-target="#summaryModal">
                {{ 'Project.Summary' | translate:locale.language }}
            </button>

            <button *ngIf="summaryLayout" type="button" (click)="showEvaluation()" class="btn btn-warning m-1">
                {{ 'Task.Evaluation' | translate:locale.language }}
            </button>
            <button *ngIf="!viewLayout" type="button" (click)="setTaskToEvaluated()" class="btn btn-success m-1">
                {{ 'Game.EvaluationDone' | translate:locale.language }}
            </button>
        </div>
    </div>
    <hr>
    <!-- <div class="d-flex flex-wrap">
        <span *ngFor="let result of wrResultList; index as i" class="badge bg-info text-dark m-1"
            (click)="gotoEvaluation(i)"> {{ 'Game.' + result.subType | translate:locale.language }}</span>
    </div> -->

</ng-template>

<ng-template #previousTask>
    <div style="min-width: 180px;">
        <ng-container *ngIf="showBack && !drawing && savedx">
            <button (click)="goPreviousTask()" class="btn btn-rounded btn-primary" type="button">
                <i class="bi bi-arrow-left"></i>
                <span> {{ 'Core.Previous' | translate:locale.language }}</span>
                <span> {{ 'Game.Test' | translate:locale.language }}</span>
            </button>
        </ng-container>
    </div>
</ng-template>
<ng-template #nextTask>
    <div style="min-width: 180px; text-align: right;">
        <ng-container *ngIf="showNext && !drawing && savedx">
            <button (click)="goNextTask()" useSubmitBehavior=true class="btn btn-rounded btn-primary" type="button">
                <span>{{ 'Core.Next' | translate:locale.language }} </span>
                <span>{{ 'Game.Test' | translate:locale.language }} </span>
                <i class="bi bi-arrow-right"></i>
            </button>
        </ng-container>
    </div>
</ng-template>
