import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BloodPressure, BloodPressureAndWeight, NormalBloodPressure } from 'src/app/services/webapiclient.service';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { Subscription } from 'rxjs';
import { loadMessages, locale } from 'devextreme/localization';
import { PatientService } from 'src/app/module-patient/patient.service';
import { CorrectDate } from 'src/app/utils';

type NewType = Subscription;

@Component({
  selector: 'app-blood-pressure-chart',
  templateUrl: './blood-pressure-chart.component.html',
  styleUrls: ['./blood-pressure-chart.component.scss']
})
export class BloodPressureChartComponent implements OnInit, OnDestroy {
  @Input() inpPatientId: string;
  @Input() startDate: Date;
  @Input() endDate: Date;

  modalRef: BsModalRef;
  dataSource: BloodPressureAndWeight[];
  filteredDataSource: BloodPressureAndWeight[];
  normalBloodPressure: NormalBloodPressure = { systolic: 0, diastolic: 0 } as NormalBloodPressure;
  private translSubs$: NewType;
  patientId: any;
  currentUser: any;
  showAll: boolean = true;
  showBloodPressure: boolean = true;
  showPulse: boolean = true;
  showWeight: boolean = false;

  options: any[];
  option: string;
  selectedOption: any;
  ifDoctor: string;


  stylingMode = "filled";
  maxDate: Date = new Date();
  dateTimeFormat: string;

  chartInstance: any;
  seriesCollection: any = [];
  error: any;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private patientService: PatientService,
    private translation: L10nTranslationService,
    private datePipe: DatePipe,
    private modalService: BsModalService,
    private authenticationService: AuthenticationService
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

  }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateTimeFormat = this.translation.translate('Core.DateTimeFormat');
      });

    if (this.inpPatientId) {
      //Katalógusból hívjuk
      this.patientId = this.inpPatientId;
      this.ifDoctor = "if-doctor";
    }

    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      if (!this.patientId) this.patientId = this.currentUser.id;
      this.loadNormalBloodPressure();
      this.loadDataSource();
    }

  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.startDate) this.startDate = changes.startDate.currentValue;
    if (changes.endDate) this.endDate = changes.endDate.currentValue;
    if(this.patientId){
      this.loadDataSource();
    }
    // You can also use categoryId.previousValue and 
    // categoryId.firstChange for comparing old and new values

  }

  loadDataSource() {
    this.patientService.getBloodPressureListAndWeight(this.patientId, this.startDate.toDateString(), this.endDate.toDateString()).then(
      result => {
        this.dataSource = <BloodPressureAndWeight[]>result;
        this.setFilteredDataSource();
      }
    ).catch(err => {
      this.error = err;
    });
  }

  loadNormalBloodPressure() {
    this.patientService.getNormalBloodPressure(this.patientId).then(
      result => {
        this.normalBloodPressure = <NormalBloodPressure>result;
      }
    ).catch(err => {
      this.error = err;
    });
  }

  legendClick(e: any) {
    const series = e.target;
    if (series.isVisible()) {
      series.hide();
    } else {
      series.show();
    }
    this.setFilter(series.index, series.isVisible());
    this.setFilteredDataSource();
  }

  setFilter(index, visible) {
    switch (index) {
      case 0:
        this.showBloodPressure = visible;
        break;
      case 1:
        this.showPulse = visible;
        break;
      case 2:
        this.showWeight = visible;
        break;
    }

  }
  setFilteredDataSource() {
    //minden mutatjuk
    this.filteredDataSource = this.dataSource;
    //Játék típusa szerinti szűrések
    // if (!this.showBloodPressure) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'SDMTtest');
    // if (!this.showPulse) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'MemoryGameClassic');
    // if (!this.showWeight) this.filteredDataSource = this.filteredDataSource.filter(f => f.gameType != 'MemoryGameSemantic');
  }
  //Szöveg az időpont tengelyhez
  customizeTimeText = (info: any) => {
    if (info.valueText == null) return;
    let ret: Date;
    ret = new Date(info.valueText);
    return this.datePipe.transform(ret, 'MM.dd. HH:mm');
  }

  onShowAll(e) {
    this.seriesCollection = this.chartInstance.getAllSeries();
    this.seriesCollection.forEach((serie, index) => {
      if (!e.value) {
        serie.hide();
      } else {
        serie.show();
      }
    });

    this.showAll = e.value;
    this.showBloodPressure = e.value;
    this.showPulse = e.value;
    this.showWeight = e.value;
    this.setFilteredDataSource();
  }

  onShowBloodPressure(e) {
    this.showBloodPressure = e.value;
    this.setFilteredDataSource();
  }

  onShowPulse(e) {
    this.showPulse = e.value;
    this.setFilteredDataSource();
  }
  onShowWeight(e) {
    this.showWeight = e.value;
    this.setFilteredDataSource();
  }


  initChart(e) {
    this.chartInstance = e.component;
  }
  closeModal() {
    this.modalRef.hide();
  }
}
