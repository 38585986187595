import { Component, OnInit, Inject, OnDestroy, Input } from '@angular/core';
import { first } from 'rxjs/operators';

import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { loadMessages, locale } from 'devextreme/localization';
import CustomStore from 'devextreme/data/custom_store';
import { ThryveService, Spo2ViewModel, SleepAndSpo2ViewModel, SleepViewModel } from '../../module-thryve/thryve.service';
import { AuthenticationService } from '../../services/authentication.service';
import { LoginUser } from '../../services/webapiclient.service';
import { Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CorrectDate } from 'src/app/utils';
import { ActivatedRoute } from '@angular/router';

/**
 * Thryve spo2 és alvási fázis adatok megjelítése chart. 
 */

@Component({
  selector: 'app-thryve-sleep-chart',
  templateUrl: './thryve-sleep-chart.component.html',
  styleUrls: ['./thryve-sleep-chart.component.scss']
})
export class ThryveSleepChartComponent implements OnInit, OnDestroy {
  @Input() inpPatientId: string;
  @Input() inpQueryDate: Date;

  patientId: any;
  thryveConnected = false;
  chartInstance: any;
  pieChartInstance: any;
  sleepChartInstance: any;
  dateInstance: any;
  chartVisible = true;
  dataLoaded = false;
  stylingMode = "filled";
  currentUser: LoginUser;
  queryDate: Date = new Date();
  lastRefreshDate: string = null as string;
  sleepAndSpo2ViewModel: SleepAndSpo2ViewModel[] = {} as SleepAndSpo2ViewModel[];
  sleepViewModel: SleepViewModel[] = {} as SleepViewModel[];

  rem: string;
  deep: string;
  mid: string;
  light: string;
  awake: string;
  missing: string;

  totalSleepTime: string;
  avgSleepTime: string;

  options: any[];
  option: string;
  selectedOption: any;

  startDate: Date = new Date();
  endDate: Date = new Date();
  dateFormat: string;
  goodSleep: number = 8;

  dataSource: any;
  pieDataSource: any;
  sleepDataSource: any;
  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private translation: L10nTranslationService,
    private thryveService: ThryveService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });
    //Periódus választó
    this.options = [
      {
        "name": "1 " + this.translation.translate('Core.Days'),
        "value": 1
      },
      {
        "name": "7 " + this.translation.translate('Core.Days'),
        "value": 7
      },
      {
        "name": "30 " + this.translation.translate('Core.Days'),
        "value": 30
      },
      {
        "name": "180 " + this.translation.translate('Core.Days'),
        "value": 180
      },
    ];
    this.option = this.options[0].value;
    this.selectedOption = this.options[0];

    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });
    if (this.inpPatientId) {
      //Katalógusból hívjuk
      this.patientId = this.inpPatientId;
    }
    const qDate = this.route.snapshot.paramMap.get('qDate');

    if (qDate) {
      this.queryDate = new Date(qDate);
    }

    if (this.inpQueryDate) {
      this.queryDate = new Date(this.inpQueryDate);
    }

    if (this.currentUser) {
      if (!this.patientId) this.patientId = this.currentUser.id;
    }

    this.startDate = new Date(this.queryDate);
    this.endDate = new Date(this.queryDate);

    //Adatforrás a step area chart-hoz
    this.dataSource = new CustomStore({
      loadMode: "raw",
      key: ["patientDataId", "phaseStart", "sleepType"],
      load: () => this.getSleepAndSpo2Data(this.queryDate)
    });

    //Adatforrás a pie chart-hoz
    this.pieDataSource = new CustomStore({
      loadMode: "raw",
      key: ["id"],
      load: () => this.getSleepData(this.queryDate)
    });
    //Adatforrás a több napos chart-hoz
    this.sleepDataSource = new CustomStore({
      loadMode: "raw",
      key: ["id, dayAt"],
      load: () => this.getSleepData(this.queryDate)
    });

    this.getSleepData(this.queryDate);
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  onOptionChanged(days, index) {
    this.dataLoaded = false;
    this.selectedOption = this.options[index];
    //this.totalSleepTime = "";
    if (index == 0) {
      this.startDate = new Date(this.queryDate);
      this.sleepChartInstance = null;
    } else {
      this.startDate = new Date(this.queryDate);
      this.startDate.setDate(this.startDate.getDate() - days);
      this.startDate = new Date(this.startDate);
      this.chartInstance = null;
      this.pieChartInstance = null;
      //this.getSleepData(this.queryDate);
    }
    this.getSleepData(this.queryDate);
    this.getSleepAndSpo2Data(this.queryDate);
    this.queryChart();
  }

  pointClick(e: any) {
    const point = e.target;
    if (point.isSelected()) {
      point.clearSelection();
    } else {
      point.select();
    }
  }

  //Szöveg az alvási fázisok tengelyhez
  customizePhasesText = (info: any) => {
    let ret: string = "";
    switch (info.valueText) {
      case "100": {
        ret = this.translation.translate('Thryve.SleepAwake');
        break;
      }
      case "80": {
        ret = this.translation.translate('Thryve.SleepLight');
        break;
      }
      case "60": {
        ret = "";
        break;
      }
      case "40": {
        ret = this.translation.translate('Thryve.SleepDeep');
        break;
      }
      case "20": {
        ret = this.translation.translate('Thryve.SleepREM');
        break;
      }
    }
    return ret;
  }
  //Szöveg az időpont tengelyhez
  customizeTimeText = (info: any) => {
    let ret: Date;
    ret = new Date(info.valueText);
    return this.datePipe.transform(ret, 'MM.dd. HH:mm');
  }

  customizeLabel(pointInfo: any) {
    return pointInfo.value < 8 ? { backgroundColor: 'red' } : { backgroundColor: 'green' }
  }

  //Chart-hoz
  getSleepAndSpo2Data(queryDate) {
    let d = this.datePipe.transform(queryDate, 'yyyy.MM.dd');
    return new Promise((resolve, reject) => {
      this.thryveService.getSleepAndSpo2Data(this.patientId, d).pipe(first()).subscribe(
        (p) => {
          this.sleepAndSpo2ViewModel = p;
        },
        (e) => {
          //this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.sleepAndSpo2ViewModel);
        }
      );
    });
  }
  //Pie chart-hoz
  getSleepData(queryDate) {
    let f = this.datePipe.transform(this.startDate, 'yyyy.MM.dd');
    let t = this.datePipe.transform(queryDate, 'yyyy.MM.dd');
    return new Promise((resolve, reject) => {
      this.thryveService.getSleepData(this.patientId, f, t).pipe(first()).subscribe(
        (p) => {
          this.sleepViewModel = p;
          this.setTotalSleepTime();
          if (this.sleepViewModel.length > 0 && this.selectedOption.value == 1) {
            //Átkonvertáljuk az alvási fázis időtartamát %-ra
            this.convertToPercentage();
          } else {
            this.calculateAvgSleepTime();
          }
        },
        (e) => {
          //this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message + e.error.ExceptionMessage);
        },
        () => {
          resolve(this.sleepViewModel);
        }
      );
    });
  }

  //Össz alvásidő beállítása
  setTotalSleepTime() {
    this.totalSleepTime = '';
    this.missing = "";
    let sum = 0;
    if (this.sleepViewModel.length > 0) {
      sum = this.sleepViewModel.reduce((sum, current) => sum + current.sleepDuration, 0);
      if(sum<480){
        this.missing = this.formatMinutes(480 - sum);
      }

      this.totalSleepTime = this.formatMinutes(sum);
      this.dataLoaded = true;
    }
  }

  formatMinutes(minutes: number) :string{
    let tempDate = new Date(1970, 0, 1).setMinutes(minutes);
    let hour = this.datePipe.transform(tempDate, 'H');
    let min = this.datePipe.transform(tempDate, 'm');
    if(hour == '0'){
      hour= '';
    }else{
      hour = hour + this.translation.translate('Project.o');
    }
    if(min == '0'){
      min= '';
    }else{
      min = min + this.translation.translate('Project.p');
    }
  return hour + min;
  } 

  clearPhases() {
    this.rem = '';
    this.deep = '';
    this.awake = '';
    this.light = '';
    this.mid = '';
  }

  //Átkonvertáljuk az alvási fázis időtartamát %-ra
  convertToPercentage() {
    this.clearPhases();
    const sum = this.sleepViewModel.reduce((sum, current) => sum + current.sleepDuration, 0);
    this.sleepViewModel.forEach((currentValue, index) => {
      switch (currentValue.sleepType) {
        case 'REM': {
          this.rem = this.formatMinutes(currentValue.sleepDuration);
          currentValue.sleepTypeTxt = this.translation.translate('Thryve.SleepREM');
          break;
        }
        case 'DEEP': {
          this.deep = this.formatMinutes(currentValue.sleepDuration);
          currentValue.sleepTypeTxt = this.translation.translate('Thryve.SleepDeep');
          break;
        }
        case 'MID': {
          this.mid = this.formatMinutes(currentValue.sleepDuration);
          currentValue.sleepTypeTxt = this.translation.translate('Thryve.SleepMid');
          break;
        }
        case 'LIGHT': {
          this.light = this.formatMinutes(currentValue.sleepDuration);
          currentValue.sleepTypeTxt = this.translation.translate('Thryve.SleepLight');
          break;
        }
        case 'AWAKE': {
          this.awake = this.formatMinutes(currentValue.sleepDuration);
          currentValue.sleepTypeTxt = this.translation.translate('Thryve.SleepAwake');
          break;
        }
      }
      currentValue.formattedTime = this.formatMinutes(currentValue.sleepDuration);

      let num = (currentValue.sleepDuration / (sum / 100)) / 100;
      currentValue.sleepDuration = num; //Math.round((num + Number.EPSILON) * 100) / 100;
    });
  }

  //Átlagos alvásidő kiszámítása
  calculateAvgSleepTime() {

    //sum sleepDuration, group by dayAt
    const calculated = this.sleepViewModel.reduce((acc, item) => {

      let accItem = acc.find(ai => ai.dayAt === item.dayAt)

      if (accItem) {
        accItem.sleepDuration += item.sleepDuration
      } else {
        acc.push(item)
      }

      return acc;
    }, [])

    const sum = calculated.reduce((acc, cur) => acc + cur.sleepDuration, 0);
    const avgSleep = sum / calculated.filter(f=>f.sleepDuration > 0).length;
    if(isNaN(avgSleep)){
      this.avgSleepTime = '';
    }else{
      this.avgSleepTime = this.formatMinutes(avgSleep);
    }
    this.dataLoaded = true;
 
  }
  //Pie chart cimkék
  customizePieLabel = (arg: any) => {
    return {
      visible: true,
      backgroundColor: "#ff7c7c",
      customizeText: function (e: any) {
        return e.valueText + "&#176F";
      }
    }
  }

  //Beállítjuk a pie chart szineket, hogy szinkronba legyen a fő diagrammal.
  customizePoint = (arg: any) => {
    switch (arg.data.sleepType) {
      case "REM": {
        return { color: "#716eff", hoverStyle: { color: "#716eff" } };
      }
      case "DEEP": {
        return { color: "#7d8ff9", hoverStyle: { color: "#7d8ff9" } };
      }
      case "MID": {
        return { color: "#80b4ff", hoverStyle: { color: "#80b4ff" } };
      }
      case "LIGHT": {
        return { color: "#99d1ff", hoverStyle: { color: "#99d1ff" } };
      }
      case "AWAKE": {
        return { color: "#8dff95", hoverStyle: { color: "#8dff95" } };
      }
    }
  }

  customizePointBarChart = (arg: any) => {
    if (!(arg.value < 8)) {
      return { color: '#00cc44'};
    }
  };

  initChart(e) {
    this.chartInstance = e.component;
  }

  initPieChart(e) {
    this.pieChartInstance = e.component;
  }

  initChartSleep(e) {
    this.sleepChartInstance = e.component;
  }

  initDate(e) {
    this.dateInstance = e.component;
  }

  dateFocusOut(s) {
    CorrectDate(this.dateInstance);
  }
  queryChart() {
    this.chartVisible = true;
    //Chart lekérdezés
    if (this.chartInstance) {
      let dso = this.chartInstance.getDataSource();
      if(dso) dso.reload();
      //this.chartInstance.refresh();
    }
    //Pie chart lekérdezés
    if (this.pieChartInstance) {
      let pDso = this.pieChartInstance.getDataSource();
      if(pDso) pDso.reload();
    }
    //Több napos chart lekérdezés
    if (this.sleepChartInstance) {
      let ptDso = this.sleepChartInstance.getDataSource();
      if(ptDso) ptDso.reload();
    }

  }
  //Szöveg az dátum tengelyhez
  customizeDateText = (info: any) => {
    if (info.valueText == null) return;
    let ret: Date;
    ret = new Date(info.valueText);
    return this.datePipe.transform(ret, 'MM.dd.');
  }

  goToday(){
    this.dataLoaded = false;
    this.queryDate = new Date();
    this.startDate = new Date();
    this.clearPhases();
    this.queryChart();
  }

  back() {
    this.dataLoaded = false;
    this.queryDate.setDate(this.queryDate.getDate() - this.selectedOption.value);
    this.queryDate = new Date(this.queryDate);
    this.startDate.setDate(this.startDate.getDate() - this.selectedOption.value);
    this.startDate = new Date(this.startDate);
    //this.dateInstance.repaint();
    this.clearPhases();
    this.queryChart();
  }

  next() {
    this.dataLoaded = false;
    this.queryDate.setDate(this.queryDate.getDate() + this.selectedOption.value);
    this.queryDate = new Date(this.queryDate);
    this.startDate.setDate(this.startDate.getDate() + this.selectedOption.value);
    this.startDate = new Date(this.startDate);
    //this.dateInstance.repaint();
    this.clearPhases();
    this.queryChart();
  }
  //Csak az spo2-ra írunk ki tooltipet (időpont, érték)
  //Chart
  customizeTooltip = (info: any) => {
    let ret: Date;
    ret = new Date(info.argumentText);
    if (!info.points[5]) return { html: "" };
    return {
      html: "<div><div class='tooltip-header'>" +
        this.datePipe.transform(ret, 'HH:mm') + "</div>" +
        "<div class='tooltip-body'><div class='series-name'>" +
        "<span class='top-series-name'>" + info.points[5].seriesName + "</span>" +
        ": </div><div class='value-text'>" +
        "<span class='top-series-value'>" + info.points[5].valueText + "</span>" +
        "</div></div></div></div>"
    };
  }

  //Pie chart tooltip
  customizePieTooltip = (info: any) => {
    return {
      html: "<div><div class='tooltip-header'>" +
        info.argumentText + "</div></div>"
    };
  }
  customizeSleepTooltip = (info: any) => {
    return {
      html: "<div><div class='tooltip-header'>" +
        this.formatMinutes(info.value * 60) + "</div></div>"
    };
  }

}
