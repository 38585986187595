<div class="container">
    <!-- Felmérési eremények pivot tábla -->
    <div *ngIf="code=='CognitiveTest' && !showTaskSheet">
        <div class="m-3">
                   <div class="d-md-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                    <div  class="general-icons-sm me-2">
                        <img src="../../../assets/images/clinical-tests.svg">
                    </div>
                    <h1  class="display-6 m-0">{{ 'Project.ClinicalTests' | translate:locale.language }}</h1>
                </div>
                <div class="mt-2 mt-md-0">
                    <button type="button" class="btn btn-success" (click)="addRow(0)">
                        <i class="bi bi-plus"></i> {{ 'Project.NewMeasurement' | translate:locale.language }}
                    </button>
                </div>
            </div>
            <hr>
        </div>
        <!-- felmérések új verzió  -->
        <div *ngFor="let test of pivotData" class="card mb-3">
            <div class="card-body d-lg-flex p-0">
                <div class="d-flex flex-column border-end p-3" style="width: 283px;">
                    <div class="d-flex align-items-center justify-content-between">
                        <div>
                            <h4 class="m-0">{{ test.testType | codeTranslate:codesSource }}</h4>
                        </div>
                        <div>
                            <button class="btn btn-success circle m-1" (click)="addRow(test.testType)">
                                <h3 class="m-0"><i class="bi bi-plus"></i></h3>
                            </button>
                        </div>
                    </div>
                    <div class="mb-2">{{'Game.' + (test.testType | codeTranslate:codesSource)  + '_TEXT' | translate:locale.language}}</div>
                    <div [style.background-color]="tendencyBackground(test.tendency)" style="width:250px; height:250px"
                        class="d-flex align-items-center justify-content-center rounded sparkline-shadow">
                        <dx-sparkline [dataSource]="test.spData" argumentField="testDate" valueField="result"
                            type="line" [showMinMax]="true" minColor="#666" maxColor="#666" [pointSize]="6">
                            <dxo-size [width]="200" [height]="200"></dxo-size>
                            <dxo-tooltip [enabled]="true"></dxo-tooltip>
                        </dx-sparkline>
                    </div>
                </div>
                <div class="p-3 w-100">
                    <div>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th style="width:130px">{{'Task.Time' | translate:locale.language}}</th>
                                    <th style="width:130px">{{'Game.Score1' | translate:locale.language}}</th>
                                    <th>{{'Task.Evaluation' | translate:locale.language}}</th>
                                    <th style="width:180px">{{'Category.ChangeOfState' | translate:locale.language}}</th>
                                    <th style="width:100px">{{'Project.Type' | translate:locale.language}}</th>
                                    <th style="width:100px"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of filterByType(test.testType)">
                                    <td><span>{{item.startDate | date: dateFormat}}</span></td>
                                    <td><span class="badge bg-secondary m-1">{{item.result}} {{ 'Game.Point' |
                                            translate:locale.language }}</span></td>
                                    <td><span class="h5 m-1">{{'Category.' + item.resultCategory |
                                            translate:locale.language}}</span></td>
                                    <td>
                                        <div *ngIf="item.tendency == -1">
                                            <span>{{'Category.Decadence' | translate:locale.language}} <span
                                                    class="text-danger"><i
                                                        class="bi bi-arrow-down-right-circle-fill"></i></span></span>
                                        </div>
                                        <div *ngIf="item.tendency == 0">
                                            <h5 class="mb-0"><i class="bi bi-dash-lg"></i></h5>
                                        </div>
                                       
                                        <div *ngIf="item.tendency == 1">
                                            <span>{{'Category.Improving' | translate:locale.language}} <span
                                                    class="text-success"><i
                                                        class="bi bi-arrow-up-right-circle-fill"></i></span></span>
                                        </div>
                                    </td>
                                    <td>
                                        <span *ngIf="item.taskId == null">
                                            kézi
                                        </span>
                                        <span *ngIf="item.taskId != null">
                                            gépi
                                        </span>
                                    </td>
                                    <td>
                                        <div class="d-flex justify-content-end">
                                            <button *ngIf="item.taskId != null" class="btn btn-primary circle m-1"
                                                (click)="clickPrint(item.id)"><i class="bi bi-printer"></i></button>
                                                <button class="btn btn-primary circle m-1"
                                                (click)="clickEditPivot(item.id)"><i class="bi bi-pencil"></i></button>
                                            <button class="btn btn-primary circle m-1"
                                                (click)="showUploadPopUp(item.id)"><i
                                                    class="bi bi-paperclip"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <app-evaluation *ngIf="showTaskSheet" (closeViewEvent)="closeViewEvent($event)" [patientId]="patientId"
        [taskId]="editItem.taskId" [onlyView]=true></app-evaluation>
    

    <!-- Minden egyéb -->

    <!-- Szerkesztés Modal -->
    <ng-template #editFormtmpl>
        <div *ngIf="editItem">
            <div class="modal-header">
                <!--Modal neve az észlelt memóriatünet-->
                <h5 class="modal-title" id="editMemoryDisorderModalLabel">{{ editItem.codeId | codeTranslate:codesSource
                    }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()"
                    aria-label="Close">
                </button>
            </div>
            <div class="modal-body">
                <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">
                    <ng-container *ngTemplateOutlet="fieldsTemplate"></ng-container>
                    <div class="modal-footer">
                        <dx-button stylingMode="contained" text="{{ 'Core.EditSave' | translate:locale.language }}"
                            type="default" useSubmitBehavior=true>
                        </dx-button>
                        <button type="button" (click)="clickDelete(selectedIndex)" class="btn btn-danger">
                            {{ 'Core.EditDelete' | translate:locale.language }}
                        </button>
                        <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
                            {{ 'Core.EditCancel' | translate:locale.language }}
                        </button>
                    </div>
                    <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
                </form>
            </div>
        </div>
    </ng-template>

    <!-- Új Modal -->
    <ng-template #newFormtmpl>
        <div class="modal-header">
            <h5 class="modal-title" id="newMemoryDisorderModalLabel">{{settings.newLabel}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
            </button>
        </div>
        <div class="modal-body">
            <form action="your-action" (submit)="onFormSubmit($event,selectedIndex)">
                <div class="mb-3">
                    <div class="form-label">{{settings.codeName}}</div>
                    <dx-select-box [dataSource]="codesSource" valueExpr="id" stylingMode="filled"
                        displayExpr="descriptionTranslated" (onValueChanged)="changeCode($event)" [searchEnabled]="true"
                        [(value)]="editItem.codeId">
                        <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
                <ng-container *ngTemplateOutlet="fieldsTemplate"></ng-container>
                <div class="modal-footer">
                    <dx-button stylingMode="contained" text="{{ 'Core.EditSave' | translate:locale.language }}"
                        type="default" useSubmitBehavior=true>
                    </dx-button>
                    <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
                        {{ 'Core.EditCancel' | translate:locale.language }}
                    </button>
                </div>
            </form>
        </div>
    </ng-template>

    <!-- Szerkesztés alkártya Modal -->
    <ng-template #editSubFormtmpl>
        <div *ngIf="editItem">

            <div class="modal-header">
                <!--Modal neve az szerkesztett kód megnevezése-->
                <h5 class="modal-title" id="editMemoryDisorderModalLabel">{{ editItem.codeId |
                    codeTranslate:subCodesSource }}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()"
                    aria-label="Close">
                </button>
            </div>
            <div class="modal-body">
                <form action="your-action" (submit)="onSubFormSubmit($event,selectedSubIndex)">
                    <ng-container *ngTemplateOutlet="subFieldsTemplate"></ng-container>
                    <div class="modal-footer">
                        <dx-button stylingMode="contained" text="{{ 'Core.EditSave' | translate:locale.language }}"
                            type="default" useSubmitBehavior=true>
                        </dx-button>
                        <button type="button" (click)="clickSubDelete(selectedSubIndex)" class="btn btn-danger">
                            {{ 'Core.EditDelete' | translate:locale.language }}
                        </button>
                        <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
                            {{ 'Core.EditCancel' | translate:locale.language }}
                        </button>

                    </div>
                </form>
            </div>
        </div>

    </ng-template>

    <!-- Új alkártya Modal -->
    <ng-template #newSubFormtmpl>
        <div class="modal-header">
            <h5 class="modal-title" id="newSubFormModalLabel">{{settings.newSubLabel}}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
            </button>
        </div>
        <div class="modal-body">
            <form action="your-action" (submit)="onSubFormSubmit($event,selectedIndex)">
                <div class="mb-3">
                    <div class="form-label">{{settings.subCodeName}}</div>
                    <dx-select-box [dataSource]="groupedCodesSource" valueExpr="id" stylingMode="filled"
                        displayExpr="descriptionTranslated" [searchEnabled]="true" [grouped]="true"
                        [(value)]="editItem.codeId">
                        <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-select-box>
                </div>
                <ng-container *ngTemplateOutlet="subFieldsTemplate"></ng-container>

                <div class="modal-footer">
                    <dx-button stylingMode="contained" text="{{ 'Core.EditSave' | translate:locale.language }}"
                        type="default" useSubmitBehavior=true>
                    </dx-button>

                    <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
                        {{ 'Core.EditCancel' | translate:locale.language }}
                    </button>

                </div>
            </form>
        </div>
    </ng-template>

    <!-- Kártyák megjelenítése -->
    <div *ngIf=showCards>
        <div class="m-3">
            <div class="d-flex justify-content-between align-items-center">
                <h1 class="display-6 d-block">{{settings.codeName}}</h1>
                <div>
                    <button class="btn btn-success" (click)="addRow(0)"><i class="bi bi-plus"></i>
                        {{settings.newLabel}}</button>
                </div>
            </div>
            <hr>
        </div>
        <div class="row row-cols-1 row-cols-lg-2 g-4 px-3" id="mainCards">
            <div class="col" *ngFor="let item of dataSource; index as i">
                <div class="card mb-3 h-100">
                    <div class="card-header p-3">
                        <h4 class="m-0 text-primary">{{ item.codeId | codeTranslate:codesSource }}</h4>
                    </div>
                    <div class="card-body">

                        <p *ngIf=settings.needStartDate class="card-text"><small>{{ settings.dateLabel
                                }}:</small><strong> {{item.startDate | date: dateFormat }}</strong></p>
                        <p *ngIf=settings.needPeriodInYear class="card-text"><small>{{ 'Project.PeriodInYear' |
                                translate:locale.language }}:</small><strong> {{item.periodInYear}}</strong></p>
                        <p *ngIf=settings.needFrequency><small>{{ 'Project.FrequencyOfSymptoms' |
                                translate:locale.language }}:</small><strong> {{ item.frequencyId |
                                codeTranslate:frequencySource }}</strong></p>
                        <p *ngIf=settings.needRegularity><small>{{ 'Project.Regularity' | translate:locale.language
                                }}:</small><strong> {{ item.frequencyId | codeTranslate:regularitySource }}</strong></p>
                        <p *ngIf=settings.needQuantity class="card-text">
                            <small>{{ 'Project.Quantity' | translate:locale.language }} ({{ settings.defaultUnit |
                                codeTranslate:unitSource }}):</small>
                            <strong> {{item.quantity}}</strong>
                        </p>
                        <p *ngIf=settings.needResult class="card-text">
                            <small>{{ 'Core.Result' | translate:locale.language }}:</small>
                            <strong> {{item.result}}</strong>
                        </p>
                        <p class="card-text"><small>{{ 'Core.EntityNote' | translate:locale.language }}:</small><strong>
                                {{item.comment}}</strong></p>
                    </div>
                    <div class="d-flex justify-content-end mx-3 py-3 border-top">

                        <!-- Szerkesztés modal trigger -->
                        <button type="button" class="btn btn-primary m-1 mb-3" (click)="clickEdit(i)">
                            {{ 'Core.EditEdit' | translate:locale.language }}
                        </button>

                        <!-- Alkártyák, ha vannak -->
                        <button *ngIf="subCode!='NO'" type="button" class="btn btn-neutral m-1 mb-3"
                            (click)="clickSubCards(i)">
                            {{settings.subCodeName}}
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Alkártyák megjelenítése -->
    <div *ngIf=showSubCards id="subCards">
        <div class="m-3">
            <div class="d-xl-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                    <div class="me-2">
                        <button type="button" (click)="clickBack()" class="btn btn-neutral">
                            <i class="bi bi-arrow-left"></i>
                        </button>
                    </div>
                    <h1 class="display-6 d-block">
                        {{ dataSource[selectedIndex].codeId | codeTranslate:codesSource }} - {{settings.subCodeNameExt}}
                    </h1>
                </div>
                <div>
                    <button class="btn btn-success" (click)="addSubRow()"><i class="bi bi-plus"></i>
                        {{settings.newSubLabel}}</button>
                </div>
            </div>
            <hr>
        </div>


        <!-- Alkártyák -->
        <div class="row row-cols-1 row-cols-lg-2 g-4 px-3">
            <div class="col" *ngFor="let item of subDataSource; index as i">
                <div class="card mb-3 h-100">
                    <div class="card-header p-3">
                        <h4 class="m-0 text-primary">{{ item.codeId | codeTranslate:subCodesSource }}</h4>
                    </div>
                    <div class="card-body">
                        <p class="card-text"><small>{{settings.subDateLabel}}: </small> <br><strong>{{item.startDate |
                                date: dateFormat}}</strong> </p>
                        <p *ngIf=settings.needFrequency class="card-text"><small>{{ 'Project.FrequencyOfSymptoms' |
                                translate:locale.language }}: </small><strong>{{ item.frequencyId |
                                codeTranslate:frequencySource }}</strong></p>
                        <p class="card-text"><small>{{ 'Core.EntityNote' | translate:locale.language }}:
                            </small><strong>{{item.comment}}</strong></p>
                    </div>

                    <div class="d-flex justify-content-end mx-3 py-3 border-top">
                        <!-- Szerkesztés modal trigger -->
                        <button type="button" class="btn btn-primary m-1 mb-3" (click)="clickSubEdit(i)">
                            {{ 'Core.EditEdit' | translate:locale.language }}
                        </button>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ng-template #fieldsTemplate>
    <div *ngIf=settings.needStartDate class="mb-3">
        <div class="form-label">{{ settings.dateLabel }}</div>
        <dx-date-box type="date" stylingMode="filled" (onFocusOut)="startDateFocusOut($event)"
            (onInitialized)="initStartDate($event)" [(value)]="editItem.startDate">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxi-validation-rule type="range" [max]="maxDate"></dxi-validation-rule>
            </dx-validator>
        </dx-date-box>
    </div>
    <div *ngIf=settings.needStartDateTime class="mb-3">
        <div class="form-label">{{ settings.dateLabel }}</div>
        <dx-date-box type="datetime" stylingMode="filled" (onInitialized)="initStartDate($event)"
            [(value)]="editItem.startDate">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxi-validation-rule type="range" [max]="maxDate"></dxi-validation-rule>
                <dxi-validation-rule type="custom" [validationCallback]="validateStartDate"
                    message="{{ 'Project.StartDateError' | translate:locale.language }}">
                </dxi-validation-rule>
            </dx-validator>
        </dx-date-box>
    </div>
    <div *ngIf=settings.needPeriodInYear class="mb-3">
        <div class="form-label">{{ 'Project.PeriodInYear' | translate:locale.language }}</div>
        <dx-number-box [(value)]="editItem.periodInYear" stylingMode="filled" [showSpinButtons]="true"
            [showClearButton]="false" min=1>
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
        </dx-number-box>
    </div>
    <div *ngIf=settings.needFrequency class="mb-3">
        <div class="form-label">{{ 'Project.FrequencyOfSymptoms' | translate:locale.language }}</div>
        <dx-select-box [dataSource]="frequencySource" valueExpr="id" stylingMode="filled"
            displayExpr="descriptionTranslated" [(value)]="editItem.frequencyId">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
        </dx-select-box>
    </div>
    <div *ngIf=settings.needRegularity class="mb-3">
        <div class="form-label">{{ 'Project.Regularity' | translate:locale.language }}</div>
        <dx-select-box [dataSource]="regularitySource" valueExpr="id" stylingMode="filled"
            displayExpr="descriptionTranslated" [(value)]="editItem.frequencyId">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
        </dx-select-box>
    </div>
    <div *ngIf=settings.needQuantity class="mb-3">
        <div class="form-label">{{ 'Project.Quantity' | translate:locale.language }} ({{ settings.defaultUnit |
            codeTranslate:unitSource }})</div>
        <dx-number-box [(value)]="editItem.quantity" stylingMode="filled" [showSpinButtons]="true"
            [showClearButton]="false" min=1>
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
        </dx-number-box>
    </div>
    <div *ngIf=settings.needResult class="mb-3">
        <div class="form-label">{{ 'Core.Result' | translate:locale.language }} </div>
        <dx-number-box [(value)]="editItem.result" stylingMode="filled" [showSpinButtons]="true"
            [showClearButton]="false">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxi-validation-rule type="range" [min]="0" [max]="maxRange">
                </dxi-validation-rule>
            </dx-validator>
        </dx-number-box>
    </div>
    <div class="mb-3">
        <div class="form-label">{{ 'Core.EntityNote' | translate:locale.language }}</div>
        <dx-text-area [height]="90" stylingMode="filled" [(value)]="editItem.comment"  [maxLength]="500">
        </dx-text-area>
    </div>
</ng-template>

<ng-template #subFieldsTemplate>
    <div class="mb-3">
        <div class="form-label">{{settings.subDateLabel}}</div>
        <dx-date-box [(value)]="editItem.startDate" (onFocusOut)="subDateFocusOut($event)"
            (onInitialized)="initSubDate($event)" stylingMode="filled">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxi-validation-rule type="range" [max]="maxDate"></dxi-validation-rule>
            </dx-validator>
        </dx-date-box>
    </div>
    <div *ngIf=settings.needFrequency class="mb-3">
        <div class="form-label">{{ 'Project.FrequencyOfSymptoms' | translate:locale.language }}</div>
        <dx-select-box [dataSource]="frequencySource" valueExpr="id" stylingMode="filled"
            displayExpr="descriptionTranslated" [(value)]="editItem.frequencyId">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
        </dx-select-box>
    </div>
    <div class="mb-3">
        <div class="form-label">{{ 'Core.EntityNote' | translate:locale.language }}</div>
        <dx-text-area [height]="90" stylingMode="filled" [(value)]="editItem.comment">
        </dx-text-area>
    </div>
</ng-template>

<!-- Dokumentum feltöltő popup a felmérési eredményekhez -->

<dx-popup [showCloseButton]="true" [showTitle]="true" title="{{ 'Core.UploadButtonTitle' | translate:locale.language }}" [height]="250"
    [width]="400" [(visible)]="uploadVisible" [showCloseButton]="true">
    <div>
        <dx-file-uploader name='myFile' #myFile [uploadFile]="uploadFile"
            [allowedFileExtensions]="['.jpeg','.jpg', '.png', '.docx', '.xlsx', '.doc', '.xls', '.pdf']"
            [minFileSize]="1024" [maxFileSize]="1024 * 1024" [multiple]="false" accept="*" [(value)]="files"
            uploadMode="instantly"></dx-file-uploader>
    </div>
</dx-popup>

<!-- Dokumentum megjelenító popup a felmérési eredményekhez -->

<dx-popup  [showCloseButton]="true"
[showTitle]="true" title="{{ 'Core.UploadedFile' | translate:locale.language }}" [height]="200" [width]="300"
    [(visible)]="showUploadedDocument">
    <div>
        <div class="content">
            <!-- <a href="{{uploadFileURL}}" target="_blank">Click here</a> -->
            <button type="button" (click)="showDocument()" class="btn btn-neutral me-2" data-bs-dismiss="modal">
                {{ 'Core.EditView' | translate:locale.language }}
            </button>
            <button type="button" (click)="deleteDocument()" class="btn btn-danger">
                {{ 'Core.EditDelete' | translate:locale.language }}
            </button>
        </div>
    </div>
</dx-popup>