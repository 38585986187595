import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';
import { StringResult } from '../module-thryve/thryve.service';

export interface Ads {
    id: number;
    description: string;
    forDoctor: boolean;
    lang: string;
    validFrom: Date;
    validTo: Date;
    pos: number;
    imgSmall: string;
    imgBig: string;
    link: string;
}


@Injectable()
export class AdsService {
    baseUri: string = `${environment.apiUrl}`;
    constructor(private http: HttpClient) {
    }

    /**
     * GET Ads/GetAll
     */
    getAll(): Observable<Ads[]> {
        return this.http.get<Ads[]>(this.baseUri + 'Ads/GetAll');
    }
    /**
     * GET Ads/GetActualAdSrc
     */
    getActualAdSrc(lang: string, pos: number, forDoctor: boolean): Observable<Ads> {
        return this.http.get<Ads>(this.baseUri + 'Ads/GetActualAdSrc?lang=' + encodeURIComponent(lang) + '&pos=' + encodeURIComponent(pos) + '&forDoctor=' + encodeURIComponent(forDoctor));
    }

    /**
     * POST Ads/Insert
     */
    insertData(value: string): Observable<HttpResponse<string>> {
        return this.http.post(this.baseUri + 'Ads/Insert', JSON.stringify(value), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
    }
    /**
     * PUT Ads/Update?id={id}
     */
    updateData(id: number, value: string): Observable<HttpResponse<string>> {
        return this.http.put(this.baseUri + 'Ads/Update?Id=' + encodeURIComponent(id), JSON.stringify(value), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
    }

    /**
     * DELETE Ads/Delete?id={id}
     */
    deleteData(id: number): Observable<HttpResponse<string>> {
        return this.http.delete(this.baseUri + 'Ads/Delete?Id=' + encodeURIComponent(id), { headers: { 'Content-Type': 'application/json;charset=UTF-8' }, observe: 'response', responseType: 'text' });
    }



    /////////

    async getAllAds() {
        let adsData: Ads[] = {} as Ads[];
        return new Promise((resolve, reject) => {
            this.getAll().pipe(first()).subscribe(
                (p) => {
                    adsData = p;
                },
                (e) => {
                    //this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
                    reject(e.message);
                },
                () => {
                    resolve(adsData);
                }
            );
        });
    }

    async insert(values: string) {
        let result: any;
        return new Promise((resolve, reject) => {
            this.insertData(values).pipe(first()).subscribe(
                (p) => {
                    result = p;
                },
                (e) => {
                    //this.errorService.changeErrorMessage(e);
                    reject(e);
                },
                () => {
                    if (result.body === "OK") {
                        resolve("OK");
                    } else {
                        reject(result.body);
                    }
                }
            );
        });
    }
    async update(id: number, values: string) {
        let result: any;
        return new Promise((resolve, reject) => {
            this.updateData(id, values).pipe(first()).subscribe(
                (p) => {
                    result = p;
                },
                (e) => {
                    //this.errorService.changeErrorMessage(e);
                    reject(e);
                },
                () => {
                    if (result.body === "OK") {
                        resolve("OK");
                    } else {
                        reject(result.body);
                    }
                }
            );
        });
    }

}





