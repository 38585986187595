<!-- Tabs navs -->
<aside [ngClass]="hamburgerState ? ' sidenav-container opened ' : 'sidenav-container'">
    <div class="patient-name d-flex justify-content-between align-items-center">
        <div>
            <h5 class="mb-0">{{patientDataViewModel.displayName}}</h5>
            <span>{{'Core.VirtualPatient' | translate:locale.language }}</span>
        </div>
        <div (click)="hamburgerToggle()"
            [ngClass]="hamburgerState ? 'hamburger-menu opened d-flex d-md-none' : 'hamburger-menu d-flex d-md-none'">
            <span class="menu-line line-1"></span>
            <span class="menu-line line-2"></span>
            <span class="menu-line line-3"></span>
        </div>
    </div>

    <div class="sidenav">
        <ul class="nav" id="ex1" role="tablist">
            <li class="nav-item" role="presentation">
                <a class="nav-link active" (click)="tabClick('ex1-tabs-1'); hamburgerToggle()" id="ex1-tab-1"
                    data-bs-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">
                    <span class="nav-link-icon"><img src="../../../assets/images/side-icon-overview.svg"></span>
                    <span class="nav-link-title">{{ 'Core.Overview' | translate:locale.language }}</span>
                </a>
            </li>
            <li class="nav-item " role="presentation">
                <hr>
            </li>
            <li class="nav-item " role="presentation">
                <span class="nav-group-title">{{ 'Core.Results' | translate:locale.language}}</span>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="ex1-tab-22" data-bs-toggle="tab" href="#ex1-tabs-22"
                    (click)="tabClick('ex1-tabs-22'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-22"
                    aria-selected="false">
                    <span class="nav-link-icon"><img src="../../../assets/images/side-icon-game-results.svg"></span>
                    <span class="nav-link-title">{{ 'Game.GameResults' | translate:locale.language }}</span>
                </a>
            </li>
            <li class="nav-item " role="presentation">
                <span class="nav-group-title">{{ 'Project.Measurements' |translate:locale.language }}</span>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="ex1-tab-31" data-bs-toggle="tab" href="#ex1-tabs-31"
                    (click)="tabClick('ex1-tabs-31'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-31"
                    aria-selected="false">
                    <span class="nav-link-icon"><img src="../../../assets/images/side-icon-blood-pressure.svg"></span>
                    <span class="nav-link-title">{{ 'Project.BloodPressureData' | translate:locale.language }}</span>
                </a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="ex1-tab-32" data-bs-toggle="tab" href="#ex1-tabs-32"
                    (click)="tabClick('ex1-tabs-32'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-32"
                    aria-selected="false">
                    <span class="nav-link-icon"><img src="../../../assets/images/side-icon-spo.svg"></span>
                    <span class="nav-link-title">{{ 'Project.BloodOxygenData' | translate:locale.language }}</span>
                </a>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="ex1-tab-33" data-bs-toggle="tab" href="#ex1-tabs-33"
                    (click)="tabClick('ex1-tabs-33'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-33"
                    aria-selected="false">
                    <span class="nav-link-icon"><img src="../../../assets/images/side-icon-sleep.svg"></span>
                    <span class="nav-link-title">{{ 'Project.SleepData' | translate:locale.language }}</span>
                </a>
            </li>

            <li class="nav-item " role="presentation">
                <hr>
            </li>
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="ex1-tab-4" data-bs-toggle="tab" href="#ex1-tabs-4"
                    (click)="tabClick('ex1-tabs-4'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-4"
                    aria-selected="false">
                    <span class="nav-link-icon"><img src="../../../assets/images/side-icon-tasks.svg"></span>
                    <span class="nav-link-title">{{ 'Task.Tasks' | translate:locale.language}}</span>
                </a>
            </li>
            <hr class="my-4">
            <li class="nav-item" role="presentation">
                <a class="nav-link" id="ex1-tab-7" data-bs-toggle="tab" href="#ex1-tabs-7"
                    (click)="tabClick('ex1-tabs-7'); hamburgerToggle()" role="tab" aria-controls="ex1-tabs-7"
                    aria-selected="false">
                    <span class="nav-link-icon"><img src="../../../assets/images/side-icon-prediction.svg"></span>
                    <span class="nav-link-title">{{ 'Project.Prediction' | translate:locale.language }}</span>
                </a>
            </li>
        </ul>
    </div>


</aside>


<main>
    <!-- Tabs content -->

    <div class="tab-content" id="ex1-content">
        <div *ngIf="activeTab=='ex1-tabs-1'" class="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel"
            aria-labelledby="ex1-tab-1">
            <app-patient-profile *ngIf="!showEvaluation" [inpPatientId]="patientId"></app-patient-profile>
            <div *ngIf="!showEvaluation" class="container">
                <!-- Utolsó mérési adatok csempék -->
                <div *ngIf="lastMeasuredDataModel" class="row row-cols-2 row-cols-lg-4 g-3 m-2" role="tablist">
                    <!--vérnyomás csempe-->
                    <div class="col">
                        <a class="nav-link h-100 pointer" id="xex1-tab-31" data-bs-toggle="tab" href="#ex1-tabs-31"
                            (click)="tabClick('ex1-tabs-31')" role="tab" aria-controls="xex1-tabs-31"
                            aria-selected="false">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="d-flex flex-column justify-content-start align-items-center h-100">
                                        <div class="measurement-icons">
                                            <img src="../../../assets/images/measurement-heart.svg">
                                        </div>
                                        <div class="text-center mb-2">
                                            <span class="h3 fw-light">{{ 'Project.BloodPressure' |
                                                translate:locale.language }}</span>
                                        </div>
                                        <div
                                            class="d-flex flex-column justify-content-start align-items-center flex-fill">
                                            <div *ngIf="lastMeasuredDataModel[0].code != 'NONE'"
                                                class="row row-cols-2 g-0">
                                                <div class="col text-end">
                                                    <span
                                                        class="h3 fw-bold m-0">{{lastMeasuredDataModel[0].value}}</span>
                                                </div>
                                                <div class="col text-start d-flex align-items-center">
                                                    <span class="ms-2">sys</span>
                                                </div>
                                                <div class="col text-end">
                                                    <span
                                                        class="h3 fw-bold  m-0">{{lastMeasuredDataModel[1].value}}</span>
                                                </div>
                                                <div class="col text-start d-flex align-items-center">
                                                    <span class="ms-2">dia</span>
                                                </div>
                                                <div class="col text-end">
                                                    <span
                                                        class="h3 fw-bold  m-0">{{lastMeasuredDataModel[2].value}}</span>
                                                </div>
                                                <div class="col text-start d-flex align-items-center">
                                                    <span class="ms-2">pulse</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="lastMeasuredDataModel[0].code == 'NONE'"
                                            class="d-flex align-items-center flex-fill">
                                            <h1 class="mb-4"><i class="bi bi-dash"></i></h1>
                                        </div>
                                        <div class="text-center mt-3">
                                            <span class="small">{{lastMeasuredDataModel[0].startDate | date:
                                                dateFormat}}</span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </a>
                    </div>
                    <!--véroxigén csempe-->
                    <div class="col">
                        <a class="nav-link h-100 pointer" id="xex1-tab-32" data-bs-toggle="tab" href="#ex1-tabs-32"
                            (click)="tabClick('ex1-tabs-32')" role="tab" aria-controls="xex1-tabs-32"
                            aria-selected="false">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="d-flex flex-column justify-content-start align-items-center h-100">
                                        <div class="measurement-icons">
                                            <img src="../../../assets/images/measurement-spo2.svg">
                                        </div>
                                        <div class="text-center mb-2">
                                            <span class="h3 fw-light">{{ 'Project.BloodOxygen' |
                                                translate:locale.language }}</span>
                                        </div>
                                        <div *ngIf="lastMeasuredDataModel[3].code != 'NONE'"
                                            class="d-flex flex-column justify-content-start align-items-center flex-fill">
                                            <span class="h3 fw-bold m-0">{{lastMeasuredDataModel[3].value}}</span>
                                            <span>SpO2</span>
                                        </div>
                                        <div *ngIf="lastMeasuredDataModel[3].code == 'NONE'"
                                            class="d-flex align-items-center flex-fill">
                                            <h1 class="mb-4"><i class="bi bi-dash"></i></h1>
                                        </div>
                                        <div class="mt-3">
                                            <span class="small">{{lastMeasuredDataModel[3].startDate | date:
                                                dateFormat}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                    <!--alvás csempe-->
                    <div class="col">
                        <a class="nav-link h-100 pointer" id="xex1-tab-33" data-bs-toggle="tab" href="#ex1-tabs-33"
                            (click)="tabClick('ex1-tabs-33')" role="tab" aria-controls="xex1-tabs-33"
                            aria-selected="false">
                            <div class="card h-100">
                                <div class="card-body">
                                    <div class="d-flex flex-column justify-content-start align-items-center h-100">
                                        <div class="measurement-icons">
                                            <img src="../../../assets/images/measurement-sleep.svg">
                                        </div>
                                        <div class="text-center mb-2">
                                            <span class="h3 fw-light">{{ 'Project.SleepData' | translate:locale.language
                                                }}</span>
                                        </div>
                                        <div *ngIf="lastMeasuredDataModel[4].code != 'NONE'"
                                            class="d-flex flex-column justify-content-start align-items-center flex-fill">
                                            <span class="h3 fw-bold m-0">{{lastMeasuredDataModel[4].hour }} óra</span>
                                            <span class="h3 fw-bold m-0">{{lastMeasuredDataModel[4].value % 60}}
                                                perc</span>
                                        </div>
                                        <div *ngIf="lastMeasuredDataModel[4].code == 'NONE'"
                                            class="d-flex align-items-center flex-fill">
                                            <h1 class="mb-4"><i class="bi bi-dash"></i></h1>
                                        </div>
                                        <div class="mt-3">
                                            <span class="small">{{lastMeasuredDataModel[4].startDate | date:
                                                dateFormat}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>

                <!-- Állapotfigyelés   -->
                <app-game-result-trends *ngIf="!showEvaluation" [inpPatientId]="patientId"></app-game-result-trends>
            </div>

        </div>
        <!-- Ball oldali menüpontok-->
        <!--eredmények-->
        <div class="tab-pane fade" id="ex1-tabs-22" role="tabpanel" aria-labelledby="ex1-tab-22">
            <app-games-report *ngIf="activeTab=='ex1-tabs-22'" [inpPatientId]="patientId"></app-games-report>
        </div>
        <!--mérések-->
        <div class="tab-pane fade" id="ex1-tabs-31" role="tabpanel" aria-labelledby="ex1-tab-31">
            <app-measured-data *ngIf="activeTab=='ex1-tabs-31'" [inpPatientId]="patientId"></app-measured-data>
        </div>
        <div class="tab-pane fade" id="ex1-tabs-32" role="tabpanel" aria-labelledby="ex1-tab-32">
            <app-blood-oxygen *ngIf="activeTab=='ex1-tabs-32'" [inpPatientId]="patientId"></app-blood-oxygen>
        </div>
        <!--ALVÁS kerül ide-->
        <div class="tab-pane fade" id="ex1-tabs-33" role="tabpanel" aria-labelledby="ex1-tab-33">
            <app-thryve-sleep-chart *ngIf="activeTab=='ex1-tabs-33'" [inpPatientId]="patientId"
                [inpQueryDate]="lastMeasuredDataModel[4].startDate"></app-thryve-sleep-chart>
        </div>

        <!-- Feladatok -->
        <div class="tab-pane fade" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
            <app-allocated-tasks *ngIf="activeTab=='ex1-tabs-4'" [patientId]="patientId"></app-allocated-tasks>
        </div>
        <!-- Predikció -->
        <div class="tab-pane fade" id="ex1-tabs-7" role="tabpanel" aria-labelledby="ex1-tab-7">
            <app-prediction *ngIf="activeTab=='ex1-tabs-7'" [patientId]="patientId"></app-prediction>
        </div>
    </div>

</main>

<ng-template #patientProfile>
    <div class="card m-3">
        <div class="card-body">
            <div class="d-flex justify-content-between align-items-center">
                <h4>{{patientDataViewModel.displayName}}</h4>
                <div>
                    <button type="button" class="btn btn-danger m-1">Kezelés megszűntetése</button>
                    <button type="button" class="btn btn-primary m-1"><i class="bi bi-pencil"></i> {{ 'Core.EditEdit' |
                        translate:locale.language }}</button>
                </div>
            </div>
            <div class="d-block d-md-flex justify-content-between align-items-center">

                <div>
                    <div><strong>{{ 'TAJ' | translate:locale.language }}:</strong> {{patientDataViewModel.taj}}</div>
                    <div><strong>{{ 'Project.BirthDate' | translate:locale.language }}:</strong>
                        {{patientDataViewModel.birthDate
                        | date: dateFormat}}</div>
                    <div><strong>{{ 'Core.Account_Register_Email' | translate:locale.language }}:</strong>
                        {{patientDataViewModel.email}}</div>
                    <div><strong>{{ 'Core.Entity_ContactPhone' | translate:locale.language }}:</strong>
                        {{patientDataViewModel.phone }}</div>

                </div>
                <div>
                    <div><strong>{{ 'Project.Sex' | translate:locale.language }}:</strong>
                        {{patientDataViewModel.sexCodeId |
                        codeTranslate:sexCodes}}</div>
                    <div><strong>{{ 'Project.HighestEducation' | translate:locale.language }}:</strong>
                        {{patientDataViewModel.highestEducationId | codeTranslate:educationCodes}}</div>
                    <div><strong>{{ 'Project.ClinicalFamilyHistory' | translate:locale.language }}:</strong>
                        {{patientDataViewModel.familyHistoryId | codeTranslate:familyHistoryCodes}}</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #openEvaluation>
    <!-- desktop nézet -->
    <table class="open-evaluation-table d-md-table w-100 d-none">
        <thead>
            <tr>
                <td>{{ 'Core.Entity_Name' | translate:locale.language }}</td>
                <td>{{ 'Core.Date' | translate:locale.language }}</td>
                <!-- <td>{{ 'Core.ElapsedTime' | translate:locale.language }}</td> -->
                <td>{{ 'Game.Mood' | translate:locale.language }}</td>
                <td>{{ 'Game.Score1' | translate:locale.language }}</td>
                <td></td>
            </tr>
        </thead>
        <tbody *ngFor="let item of dataSource; index as i">
            <tr>
                <!-- <td>{{ 'Game.' + item.gameType | translate:locale.language }}</td> -->
                <td>{{ ('Game.' + item.gameType | translate) !== 'Game.' + item.gameType ? ('Game.' + item.gameType |
                    translate)
                    : item.gameType }}</td>
                <td>{{ item.startTime | date: dateFormat }}</td>
                <!-- <td>{{ item.elapsedTime}}</td> -->
                <td><img [src]='imagePath[item.moodLevel+3]'>
                </td>
                <td>{{ item.resultNumeric | number : '1.2-2'}}</td>
                <td><button class="w-100 btn btn-success" (click)="clickEvaluation(i)">{{ 'Task.Evaluation' |
                        translate:locale.language }}</button></td>
            </tr>
        </tbody>
    </table>
    <!-- mobil nézet -->
    <div class="card mb-2 d-md-none" *ngFor="let item of dataSource; index as i">
        <div class="card-body">
            <h4>{{ 'Game.' + item.gameType | translate:locale.language }}</h4>
            <!-- <div class="d-flex justify-content-between align-items-center">
              <span>{{ 'Project.Type' | translate:locale.language }}</span>
              <strong><i class="bi bi-file-check"></i> Teszt</strong>
          </div> -->
            <div class="d-flex justify-content-between align-items-center">
                <span>{{ 'Core.Date' | translate:locale.language }}</span>
                <strong>{{ item.startTime | date: dateFormat }}</strong>
            </div>
            <!-- <div class="d-flex justify-content-between align-items-center">
          <span>{{ 'Core.ElapsedTime' | translate:locale.language }}</span>
          <strong>{{ item.elapsedTime}}</strong>
        </div> -->
            <div class="d-flex justify-content-between align-items-center">
                <span>{{ 'Game.Mood' | translate:locale.language }}</span>
                <strong><img [src]='imagePath[item.moodLevel+3]'></strong>
            </div>
            <div class="d-flex justify-content-between align-items-center">
                <span>{{ 'Game.Score1' | translate:locale.language }}</span>
                <strong>{{ item.resultNumeric | number : '1.2-2'}}</strong>
            </div>
            <button class="mt-2 w-100 btn btn-success" (click)="clickEvaluation(i)">{{ 'Task.Evaluation' |
                translate:locale.language }}</button>
        </div>
    </div>
</ng-template>