<div class="container">
    <div class="m-3">
        <div class="d-md-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <div  class="general-icons-sm me-2">
                    <img src="../../../assets/images/diagnose.svg">
                </div>
                <h1  class="display-6 m-0">{{ 'Project.Diagnosis' | translate:locale.language }}</h1>
            </div>
            <div class="mt-2 mt-md-0">
                <button type="button" class="btn btn-success" (click)="clickNew()">
                    <i class="bi bi-plus"></i> {{ 'Project.NewDiagnosis' | translate:locale.language }}
                </button>
            </div>
        </div>
        <hr>
    </div>
    <!--Megjelenítés-->
    <div *ngIf="dataSource && dataSource.length==0">
        <div class="blockquote m-3">
            {{ 'Project.NoDiagnosis' | translate:locale.language }}
        </div>
    </div>

    <div *ngFor="let item of dataSource; index as i" class="m-3">
        <div class="card mb-3">
            <div class="card-body">
                <div class="row row-cols-1 row-cols-md-2 row-cols-xl-4 g-3 mb-3">
                    <div class="col">
                        <div class="form-group">
                            <label class="form-label">{{ 'Core.Date' | translate:locale.language }}</label>
                            <span class="form-value">{{item.diagnosisDate | date: dateFormat}}</span>
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label class="form-label">{{ 'Project.EfProgression' | translate:locale.language }}</label>
                            <span class="form-value">{{ item.micProgressionId | codeTranslate:progressionSource
                                }}</span>
                        </div>
                    </div>
                    <div *ngIf="item.ctDate" class="col">
                        <div class="form-group">
                            <label class="form-label">{{ 'Project.CtDate' | translate:locale.language }}</label>
                            <span class="form-value">{{item.ctDate | date: dateFormat}}</span>
                        </div>
                    </div>
                    <div *ngIf="item.mrDate" class="col">
                        <div class="form-group">
                            <label class="form-label">{{ 'Project.MrDate' | translate:locale.language }}</label>
                            <span class="form-value">{{item.mrDate | date: dateFormat}}</span>
                        </div>
                    </div>
                    <div class="col-md-1 mb-1 align-self-end test-result-cell pointer" (click)="clickEdit(i)">
                        <div class="position-absolute text-info" style="right:0.3em; top:0"><i class="bi bi-pencil"></i>
                        </div>
                    </div>
                </div>
                <div class="row row-cols-1 row-cols-xl-2 g-3 mb-3">
                    <div class="col align-self-stretch">
                        <div class="form-group preserve-textarea-format p-3 h-100 border rounded">
                            <label class="form-label">{{ 'Project.MicProbability' | translate:locale.language }}</label>
                            <span class="form-value" *ngFor="let mic of item.selectedMics; index as x">{{ mic |
                                codeTranslate:probabilitySource }}</span>
                        </div>
                    </div>
                    <div class="col align-self-stretch">
                        <div class="form-group preserve-textarea-format p-3 h-100 border rounded">
                            <label class="form-label">{{ 'Project.MedicationUsed' | translate:locale.language }}</label>
                            <span class="form-value">{{item.medication}}</span>
                        </div>
                    </div>
                    <div *ngIf="item.imgTestResult" class="col align-self-stretch">
                        <div class="form-group preserve-textarea-format p-3 h-100 border rounded">
                            <label class="form-label">{{ 'Project.ImgResult' | translate:locale.language }}</label>
                            <span class="form-value">{{item.imgTestResult}}</span>
                        </div>
                    </div>
                    <div *ngIf="item.physiotherapy" class="col align-self-stretch">
                        <div class="form-group preserve-textarea-format p-3 h-100 border rounded">
                            <label class="form-label">{{ 'Project.PhySuggestion' | translate:locale.language }}</label>
                            <span class="form-value">{{item.physiotherapy}}</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!-- Új Modal -->
<ng-template #newDiagnosis>
    <div class="modal-header">
        <h5 class="modal-title" id="newMemoryDisorderModalLabel">{{ 'Project.NewDiagnosis' | translate:locale.language
            }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <form action="your-action" (submit)="onFormSubmit($event,0)">
            <ng-container *ngTemplateOutlet="fieldsTemplate"></ng-container>
        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</ng-template>

<!-- Szerkesztés Modal -->
<ng-template #editDiagnosis>
    <div class="modal-header">
        <h5 class="modal-title" id="newMemoryDisorderModalLabel">{{ 'Core.DataEdit' | translate:locale.language }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <form action="your-action" (submit)="onFormSubmit($event,0)">
            <ng-container *ngTemplateOutlet="fieldsTemplate"></ng-container>
        </form>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
    </div>
</ng-template>


<ng-template #fieldsTemplate>
    <div class="row">
        <div class="col-md-6 mb-3 align-self-end">
            <div>
                <div class="form-label">{{ 'Core.Date' | translate:locale.language }}
                </div>
                <dx-date-box type="date" [stylingMode]="stylingMode" [(value)]="editItem.diagnosisDate">
                    <dx-validator>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                        <dxi-validation-rule type="range" [max]="maxDate"></dxi-validation-rule>
                    </dx-validator>
                </dx-date-box>
            </div>
        </div>
        <div class="col-md-6 mb-3 align-self-end">
            <div>
                <div class="form-label">{{ 'Project.EfProgression' | translate:locale.language }}
                </div>
                <dx-select-box [dataSource]="progressionSource" valueExpr="id" displayExpr="descriptionTranslated"
                    [(value)]="editItem.micProgressionId" [stylingMode]="stylingMode">
                </dx-select-box>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mb-3 align-self-end">
            <div>
                <div class="form-label">{{ 'Project.MicProbability' | translate:locale.language }}
                </div>
                <dx-drop-down-box [(value)]="editItem.selectedMics" [opened]="false" valueExpr="id"
                    displayExpr="descriptionTranslated" placeholder="{{ 'Core.SelectItem' | translate:locale.language }}" [showClearButton]="true"
                    [dataSource]="micSource" [stylingMode]="stylingMode">
                    <div *dxTemplate="let data of 'content'">
                        <dx-data-grid [dataSource]="micSource"
                            [selection]="{ mode: 'multiple',showCheckBoxesMode:'always', allowSelectAll: false }"
                            [hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 10 }"
                            [filterRow]="{ visible: false }" [scrolling]="{ mode: 'infinite' }" [height]="345"
                            [(selectedRowKeys)]="editItem.selectedMics">
                            <dxi-column dataField="descriptionTranslated"
                                caption="{{ 'Core.Entity_Name' | translate:locale.language }}" dataType="string">
                            </dxi-column>
                        </dx-data-grid>
                    </div>
                </dx-drop-down-box>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mb-3 align-self-end">
            <div>
                <div class="form-label">{{ 'Project.MedicationUsed' | translate:locale.language }}
                </div>
                <dx-select-box [dataSource]="medicationSource" valueExpr="id" displayExpr="descriptionTranslated"
                    [(value)]="editItem.medicationId" (onValueChanged)="onValueChanged($event)"
                    [stylingMode]="stylingMode">
                </dx-select-box>
                <hr>
                <dx-text-area [autoResizeEnabled]="true" [(value)]="editItem.medication" [stylingMode]="stylingMode">
                    <!-- <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dx-validator> -->
                </dx-text-area>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6 mb-3 align-self-end">
            <div>
                <div class="form-label">{{ 'Project.CtDate' | translate:locale.language }}
                </div>
                <dx-date-box type="date" [stylingMode]="stylingMode" [(value)]="editItem.ctDate">
                </dx-date-box>
            </div>
        </div>
        <div class="col-md-6 mb-3 align-self-end">
            <div>
                <div class="form-label">{{ 'Project.MrDate' | translate:locale.language }}
                </div>
                <dx-date-box type="date" [stylingMode]="stylingMode" [(value)]="editItem.mrDate">
                </dx-date-box>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 mb-3 align-self-end">
            <div>
                <div class="form-label">{{ 'Project.ImgResult' | translate:locale.language }}
                </div>
                <dx-text-area [autoResizeEnabled]="true" [(value)]="editItem.imgTestResult" [stylingMode]="stylingMode">
                    <!-- <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dx-validator> -->
                </dx-text-area>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 mb-3 align-self-end">
            <div>
                <div class="form-label">{{ 'Project.PhySuggestion' | translate:locale.language }}
                </div>
                <dx-text-area [autoResizeEnabled]="true" [(value)]="editItem.physiotherapy" [stylingMode]="stylingMode">
                    <!-- <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dx-validator> -->
                </dx-text-area>
            </div>
        </div>
    </div>

    <div class="modal-footer">
        <dx-button stylingMode="contained" text="{{ 'Core.EditSave' | translate:locale.language }}" type="default"
            useSubmitBehavior=true>
        </dx-button>

        <button type="button" (click)="closeModal()" class="btn btn-neutral" data-bs-dismiss="modal">
            {{ 'Core.EditCancel' | translate:locale.language }}
        </button>
    </div>

</ng-template>