<div class="container">
    <div class="m-3">
        <div class="d-flex align-items-center">
          <div class="general-icons-sm me-2">
              <img src="../../../assets/images/measurements.svg">
          </div>
            <h1 class="display-6 m-0">{{ 'Project.Measures' | translate:locale.language }}</h1>
        </div>
        <hr>
        <!-- {{lastRefreshDate}} -->
        <div *ngIf="lastMeasuredDataModel" class="row row-cols-2 row-cols-lg-4 g-3 m-2" role="tablist">
            <!--vérnyomás csempe-->
            <div class="col">
              <a class="nav-link h-100 pointer" (click)="onSelect('measured-data')">
                <div class="card h-100">
                  <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center h-100">
                      <div class="measurement-icons">
                        <img src="../../../assets/images/measurement-heart.svg">
                      </div>
                      <div class="text-center mb-2">
                        <span class="h3 fw-light">{{ 'Project.BloodPressure' | translate:locale.language }}</span>
                      </div>
                      <div class="d-flex flex-column justify-content-start align-items-center flex-fill">
                        <div *ngIf="lastMeasuredDataModel[0].code != 'NONE'" class="row row-cols-2 g-0">
                          <div class="col text-end">
                            <span class="h3 fw-bold m-0">{{lastMeasuredDataModel[0].value}}</span>
                          </div>
                          <div class="col text-start d-flex align-items-center">
                            <span class="ms-2">sys</span>
                          </div>
                          <div class="col text-end">
                            <span class="h3 fw-bold  m-0">{{lastMeasuredDataModel[1].value}}</span>
                          </div>
                          <div class="col text-start d-flex align-items-center">
                            <span class="ms-2">dia</span>
                          </div>
                          <div class="col text-end">
                            <span class="h3 fw-bold  m-0">{{lastMeasuredDataModel[2].value}}</span>
                          </div>
                          <div class="col text-start d-flex align-items-center">
                            <span class="ms-2">pulse</span>
                          </div>
                        </div>
                        <div *ngIf="lastMeasuredDataModel[0].code == 'NONE'" class="d-flex align-items-center flex-fill">
                          <h1 class="mb-4"><i class="bi bi-dash"></i></h1>
                        </div>
                      </div>
                      <div class="text-center mt-3">
                        <span class="small">{{lastMeasuredDataModel[0].startDate | date: dateFormat}}</span>
                      </div>
                    </div>
  
                  </div>
                </div>
              </a>
            </div>
            <!--véroxigén csempe-->
            <div class="col">
                <a class="nav-link h-100 pointer" (click)="onSelect('blood-oxygen')">
                    <div class="card h-100">
                  <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center h-100">
                      <div class="measurement-icons">
                        <img src="../../../assets/images/measurement-spo2.svg">
                      </div>
                      <div class="text-center mb-2">
                        <span class="h3 fw-light">{{ 'Project.BloodOxygen' | translate:locale.language }}</span>
                      </div>
                      <div *ngIf="lastMeasuredDataModel[3].code != 'NONE'"
                        class="d-flex flex-column justify-content-start align-items-center flex-fill">
                        <span class="h3 fw-bold m-0">{{lastMeasuredDataModel[3].value}}</span>
                        <span>SpO2</span>
                      </div>
                      <div *ngIf="lastMeasuredDataModel[3].code == 'NONE'" class="d-flex align-items-center flex-fill">
                        <h1 class="mb-4"><i class="bi bi-dash"></i></h1>
                      </div>
                      <div class="mt-3">
                        <span class="small">{{lastMeasuredDataModel[3].startDate | date: dateFormat}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
            <!--alvás csempe-->
            <div class="col">
                <a class="nav-link h-100 pointer" (click)="onSelect('sleep-chart')">
                    <div class="card h-100">
                  <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center h-100">
                      <div class="measurement-icons">
                        <img src="../../../assets/images/measurement-sleep.svg">
                      </div>
                      <div class="text-center mb-2">
                        <span class="h3 fw-light">{{ 'Project.Sleep' | translate:locale.language }}</span>
                      </div>
                      <div *ngIf="lastMeasuredDataModel[4].code != 'NONE'"
                        class="d-flex flex-column justify-content-start align-items-center flex-fill">
                        <span class="h3 fw-bold m-0">{{lastMeasuredDataModel[4].hour }} óra</span>
                        <span class="h3 fw-bold m-0">{{lastMeasuredDataModel[4].value % 60}} perc</span>
                      </div>
                      <div *ngIf="lastMeasuredDataModel[4].code == 'NONE'" class="d-flex align-items-center flex-fill">
                        <h1 class="mb-4"><i class="bi bi-dash"></i></h1>
                      </div>
                      <div class="mt-3">
                        <span class="small">{{lastMeasuredDataModel[4].startDate | date: dateFormat}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
  
            <!--labor csempe-->
            <div class="col">
                <a class="nav-link h-100 pointer" (click)="onSelect('lab-data')">
                    <div class="card h-100">
                  <div class="card-body">
                    <div class="d-flex flex-column justify-content-start align-items-center h-100">
                      <div class="measurement-icons">
                        <img src="../../assets/images/measurement-lab.svg">
                      </div>
                      <div class="text-center mb-2">
                        <span class="h3 fw-light">{{ 'Project.Lab' | translate:locale.language }}</span>
                      </div>
                      <div *ngIf="lastMeasuredDataModel[5].code != 'NONE'"
                        class="d-flex flex-column justify-content-start align-items-center flex-fill">
                        <span class="h3 fw-bold m-0">{{ 'Project.' +
                          lastMeasuredDataModel[5].category |
                          translate:locale.language }}</span>
                      </div>
                      <div *ngIf="lastMeasuredDataModel[5].code == 'NONE'" class="d-flex align-items-center flex-fill">
                        <h1 class="mb-4"><i class="bi bi-dash"></i></h1>
                      </div>
                      <div class="mt-3">
                        <span class="small">{{lastMeasuredDataModel[5].startDate | date: dateFormat}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>
  
    </div>


</div>
<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
    [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false">
</dx-load-panel>
