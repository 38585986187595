<div class="container">
    <!-- Már van Thryve csatlakoztatásunk -->
    <div *ngIf="thryveConnected==true" class="m-3">
        <div class="d-md-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <div class="set-icons-sm mb-3 mb-lg-0 me-2">
                    <img src="../../../assets/images/set-smart-devices.svg">
                </div>
                <h1 class="display-6 d-block m-0">{{ 'Thryve.DeviceConnected' | translate:locale.language }}</h1>
            </div>
        </div>
        <hr>
        <div class="d-md-flex">
            <div class="flex-grow-1">
                <div class="connection-icon w-100 mb-4">
                    <img src="~/../assets/images/thryve_connected.svg" />
                </div>
                <p>{{ 'Thryve.ConnectSmartDeviceInfo' | translate:locale.language }}</p>
                <div class="d-lg-flex justify-content-between align-items-center">
                    <div>
                        <button id="thryveSources" type="button" class="btn btn-success me-1 mb-2"
                            (click)="thryveSources()"><i class="bi bi-list"></i>{{ 'Thryve.ThryveSources' |
                            translate:locale.language }}</button>
                        <button id="syncDevices" type="button" class="btn btn-success me-1 mb-2"
                            (click)="syncDeviceCodeAssign()"><i class="bi bi-arrow-repeat"></i>{{ 'Core.Refresh' |
                            translate:locale.language }}</button>
                    </div>
                    <div *ngIf="doctorUser">
                        <span class="me-2">{{ 'Thryve.ShareDevices' | translate:locale.language }}</span>
                        <dx-check-box [(value)]="shareDevices" (onValueChanged)="onValueChanged($event)"></dx-check-box>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div *ngIf="codesAssignData[0]">
            <table class="table table-bordered text-center bg-white">
                <thead>
                    <tr>
                        <th>{{ 'Core.Category' | translate:locale.language }}</th>
                        <th>{{ 'Thryve.ThryveSources' | translate:locale.language }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of codesAssignData; index as i">
                        <td>{{ item.codeId | codeTranslate:codesSource }}</td>
                        <td>
                            <div *ngFor="let device of item.thryveDevices" class="pointer"
                                (click)="setActive(item.codeId, device.deviceId)">
                                <strong *ngIf="device.active" class="text-success"><i class="bi bi-check-lg"></i>
                                    {{device.device}}</strong>
                                <span *ngIf="!device.active" class="text-secondary"><i class="bi bi-dash-circle"></i>
                                    {{device.device}}</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
    <!-- Még nincs Thryve csatlakoztatásunk -->
    <div *ngIf="thryveConnected==false" class="m-3">
        <div class="d-md-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <div class="set-icons-sm mb-3 mb-lg-0 me-2">
                    <img src="../../../assets/images/set-smart-devices.svg">
                </div>
                <h1 class="display-6 d-block m-0">{{ 'Thryve.NoDeviceConnected' | translate:locale.language }}</h1>
            </div>
        </div>
        <hr>
        <div class="d-md-flex">
            <div class="flex-grow-1">
                <div class="connection-icon w-100 mb-4">
                    <img src="~/../assets/images/thryve_noconnection.svg" />
                </div>
                <p>{{ 'Thryve.ConnectSmartDeviceInfo' | translate:locale.language }}</p>
                <div class="text-right">
                    <button id="thryveConnect" type="button" class="btn btn-success me-1 mb-2"
                        (click)="thryveConnect()"><i class="bi bi-wifi"></i>{{ 'Thryve.ConnectDevice' |
                        translate:locale.language }}</button>
                    <!-- <button id="thryveSources" style="display: none;" type="button" class="btn btn-success mr-1 mb-2" onclick="thryveSources()"><i class="bi bi-list"></i> @LocalizedResources.CoreLocalization.ThryveSources</button> -->
                </div>
            </div>
        </div>
    </div>
</div>

<dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [position]="{ of: '#reg' }"
    [(visible)]="loading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false">
</dx-load-panel>
