import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { TermsAndConditions, TermsFunc } from './webapiclient.service';

@Injectable({
  providedIn: 'root'
})
export class TermsService {

  constructor(
    private termsFunc: TermsFunc,
    private errorService: ErrorService

  ) { }


  async getAll() {
    let termsAndConditions: TermsAndConditions[] = {} as TermsAndConditions[];
    return new Promise((resolve, reject) => {
        this.termsFunc.getAll().pipe(first()).subscribe(
            (p) => {
  
              termsAndConditions = p;
            },
            (e) => {
                this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
                reject(e.message + e.error.ExceptionMessage);
            },
            () => {
                    resolve(termsAndConditions);
             }
        );
    });
  }

  async getActualTerms() {
    let termsAndConditions: TermsAndConditions = {} as TermsAndConditions;
    return new Promise((resolve, reject) => {
        this.termsFunc.getActualTerms().pipe(first()).subscribe(
            (p) => {
  
              termsAndConditions = p;
            },
            (e) => {
                this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
                reject(e.message + e.error.ExceptionMessage);
            },
            () => {
                    resolve(termsAndConditions);
             }
        );
    });
  }

  async insert(values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
        this.termsFunc.insert(values).pipe(first()).subscribe(
          (p) => {
            result = p;
          },
          (e) => {
            this.errorService.changeErrorMessage(e);
            reject(e);
          },
          () => {
            if(result.body === "OK"){
              resolve("OK");
            }else{
              reject(result.body);
          }
        }
    );
    });
    }
  

}
