import { Component, Inject, Input, OnInit } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { ConfirmDialogService } from './confirm-dialog-service';

/**
 * Megerősítő popup.
 * Minta hívás törlés megerősítéshez
 * clickDelete(index){
 * const that = this;  
 * this.confirmDialogService.confirmThis(this.translation.translate('Core.ConfirmDeleting'), function () {  
 *     igen ág  
 *     that.patientService.deleteBloodPressure(that.patientId).then ...
 *     }
 * }, function () {  
 *   nem ág  
 * })  
 *}
 */

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: 'confirm-dialog.component.html',
    styleUrls: ['confirm-dialog.component.scss']
})

export class ConfirmDialogComponent implements OnInit {
    message: any;
    constructor(
        private confirmDialogService: ConfirmDialogService,
        @Inject(L10N_LOCALE) public locale: L10nLocale,
        private translation: L10nTranslationService

    ) { }

    ngOnInit(): any {
        /** 
         *   This function waits for a message from alert service, it gets 
         *   triggered when we call this from any other component 
         */
        this.confirmDialogService.getMessage().subscribe(message => {
            this.message = message;
        });
    }
}