import { Injectable } from '@angular/core';
import { first } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { Doctor, HcProviderLink, HealthcareProvider, HealthcareProviderFunc } from './webapiclient.service';

@Injectable({
  providedIn: 'root'
})
export class HcProviderService {

  constructor(
    private healthcareProviderFunc: HealthcareProviderFunc,
    private errorService: ErrorService

  ) { }
  async get(id: string) {
    let healthcareProvider: HealthcareProvider = {} as HealthcareProvider;
    return new Promise((resolve, reject) => {
        this.healthcareProviderFunc.get(id).pipe(first()).subscribe(
            (p) => {
  
              healthcareProvider = p;
            },
            (e) => {
                this.errorService.changeErrorMessage(e.message);
                reject(e.message);
            },
            () => {
                    resolve(healthcareProvider);
             }
        );
    });
  }

  async getAll() {
    let healthcareProvider: HealthcareProvider[] = {} as HealthcareProvider[];
    return new Promise((resolve, reject) => {
        this.healthcareProviderFunc.getAll().pipe(first()).subscribe(
            (p) => {
  
              healthcareProvider = p;
            },
            (e) => {
                this.errorService.changeErrorMessage(e.message);
                reject(e.message );
            },
            () => {
                    resolve(healthcareProvider);
             }
        );
    });
  }

  //hc provider links
  async getLinks(id: string) {
    let hcProviderLink: HcProviderLink[] = {} as HcProviderLink[];
    return new Promise((resolve, reject) => {
        this.healthcareProviderFunc.getLinks(id).pipe(first()).subscribe(
            (p) => {
  
              hcProviderLink = p;
            },
            (e) => {
                this.errorService.changeErrorMessage(e.message);
                reject(e.message);
            },
            () => {
                    resolve(hcProviderLink);
             }
        );
    });
  }

   async insertLink(userId: string,values: string) {
    let result: any;
    return new Promise((resolve, reject) => {
        this.healthcareProviderFunc.insertLink(userId,values).pipe(first()).subscribe(
          (p) => {
            result = p;
          },
          (e) => {
            this.errorService.changeErrorMessage(e);
            reject(e);
          },
          () => {
            if(result.body === "OK"){
              resolve("OK");
            }else{
              reject(result.body);
          }
        }
    );
    });
  }
  
  async deleteLink(userId: string, providerId: string) {
    let result: any;
    return new Promise((resolve, reject) => {
        this.healthcareProviderFunc.deleteLink(userId,providerId).pipe(first()).subscribe(
          (p) => {
            result = p;
          },
          (e) => {
            this.errorService.changeErrorMessage(e);
            reject(e);
          },
          () => {
            if(result.body === "OK"){
              resolve("OK");
            }else{
              reject(result.body);
          }
        }
    );
    });
  }

  async getDoctorsByProvider(providerId: string) {
    let doctors: Doctor[] = {} as Doctor[];
    return new Promise((resolve, reject) => {
        this.healthcareProviderFunc.getDoctorsByProvider(providerId).pipe(first()).subscribe(
            (p) => {
  
              doctors = p;
            },
            (e) => {
                this.errorService.changeErrorMessage(e.message );
                reject(e.message );
            },
            () => {
                    resolve(doctors);
             }
        );
    });
  }

}
