<dx-data-grid
  id="gridContainer"
  [dataSource]="dataSource"
  [remoteOperations]="false"
  [rowAlternationEnabled]="true"
  [showBorders]="true"
>
  <dxo-editing
    mode="row"
    [allowUpdating]="true"
    [allowDeleting]="true"
    [allowAdding]="true"
    [useIcons]="true"
  >
  </dxo-editing>
  <dxo-filter-row [visible]="true"> </dxo-filter-row>

  <dxi-column
    dataField="userName"
    caption="{{ 'Core.Entity_UserUserName' | translate: locale.language }}"
    dataType="string"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column
    dataField="displayName"
    caption="{{ 'Core.Entity_UserName' | translate: locale.language }}"
    dataType="string"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column
    dataField="email"
    caption="{{ 'Core.Account_Register_Email' | translate: locale.language }}"
    dataType="string"
  >
    <dxi-validation-rule type="required"></dxi-validation-rule>
    <dxi-validation-rule type="email"></dxi-validation-rule>
  </dxi-column>
  <dxi-column
    dataField="role"
    caption="{{ 'Core.Role' | translate: locale.language }}"
    dataType="string"
  >
    <dxo-lookup
      [dataSource]="rolesData"
      valueExpr="name"
      displayExpr="translation"
    ></dxo-lookup>
    <dxi-validation-rule type="required"></dxi-validation-rule>
  </dxi-column>
  <dxi-column
    *ngIf="sysAdmin"
    dataField="providerId"
    caption="{{ 'Project.Provider' | translate: locale.language }}"
    dataType="string"
  >
    <dxo-lookup
      [dataSource]="providersData"
      valueExpr="id"
      displayExpr="name"
    ></dxo-lookup>
  </dxi-column>
  <dxi-column
    dataField="active"
    caption="{{ 'Core.EditActive' | translate: locale.language }}"
    dataType="boolean"
  >
  </dxi-column>
</dx-data-grid>
