import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { AuthenticationService } from '../services/authentication.service';
import { L10nTranslationService } from 'angular-l10n';
import { LoggingService, LogLevel, LogModel } from '../services/logging.service';
import { Router } from '@angular/router';

/**
 * Ez egy ErrorInterceptor.
 *
 * Erre is jó, meg arra is jó.
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * Ez a logModel.
   *
   * Autentikációnál használva.
   */
  logModel: LogModel = {} as LogModel;

  constructor(private authenticationService: AuthenticationService,
    private translation: L10nTranslationService,
    private logging: LoggingService,
    private router: Router) { }

  /**
   * Beavatkozunk a HttpRequestbe.
   *
   * ToDo: Még nincs befejezve! (teszt szöveg)
   *
   * @param {HttpRequest<any>} request  Valami szöveg
   * @param {HttpHandler} next  Ez is egy szöveg
   * @returns {Observable<HttpEvent<any>>} The processed target number
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        const error = err.message || err.statusText || err.url;
        // Hibát ki kell írni log-ba
        this.logModel.exception = err?.error?.title;
        this.logModel.message = error;
        this.logModel.logLevel = LogLevel.Error;
        this.logModel.module = 'ErrorInterceptor';


        // console.log('ErrorInterceptor 0', err);
        if (err.status !== undefined && err.status !== 0) {
          // console.log('ErrorInterceptor 000 -> LOG', err);
          // ha a hiba nem ismeretlen eredetű akkor logolunk
          // post the log data to the backend

          // Ez küldi folyamatosan a requestet?
          this.logging.send(this.logModel)
            .subscribe(
              response => {
              }
            );
        }

        // if(err.status === 401){
        //   //Lehet, hogy csak a token járt  le, megpróbálunk frissíteni
        //   this.authenticationService.refreshToken().subscribe();
        //   this.router.navigate(['/home']);
        //   return throwError("Lejárt token!");
        // }

        if (err.status === 401 || err.status === 403 || (request.url.includes('RefreshToken') && !(!err.status || err.status === 0))) {
          // auto logout if 401 response returned from api
          
          //this.authenticationService.logout();
          this.router.navigate(['/login'], { queryParams: { returnUrl: "/error" } });
          return of(err.message);
          //return next.handle(request);
        }

        if (!err.status || err.status === 0) {
          //console.log('ErrorInterceptor 2', err);
          // nem múködik a backend
          //return throwError(this.translation.translate('Core.SystemNotAvailable'));
          this.router.navigate(['/info-page', { errorMessage: this.translation.translate('Core.SystemNotAvailable') }]);
        } else {
          //return next.handle(request);

        }
          return throwError(err);

      })
    );
  }
}
