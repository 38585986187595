import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { loadMessages, locale } from 'devextreme/localization';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CancelTreatmentModel, LoginUser } from 'src/app/services/webapiclient.service';
//import notify from 'devextreme/ui/notify';
import { ConfirmDialogService } from 'src/app/confirm-dialog/confirm-dialog-service';
import { Subscription } from 'rxjs';
import { PatientService } from 'src/app/module-patient/patient.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cancel-treatment',
  templateUrl: './cancel-treatment.component.html',
  styleUrls: ['./cancel-treatment.component.scss']
})
export class CancelTreatmentComponent implements OnInit, OnDestroy {

  @Input() patientId: string;

  stylingMode = "filled"
  currentUser: LoginUser;
  error: any;
  dateFormat: string;
  cancellationReason: string;
  loading = false;
  editItem: any;

  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private patientService: PatientService,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private confirmDialogService: ConfirmDialogService,
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

  }


  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });

    this.currentUser = this.authenticationService.currentUserValue;
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  onShown() {
    setTimeout(() => {
      this.loading = false;
    }, 15000);
  }

  save() {
    if(this.cancellationReason === undefined || this.cancellationReason === "") return;
    this.loading = true;
    this.editItem = <CancelTreatmentModel>{};
    this.editItem.doctorId = this.currentUser.id;
    this.editItem.patientId = this.patientId;
    this.editItem.reason = this.cancellationReason;

    this.patientService.cancelTreatment(JSON.stringify(this.editItem)).then(
      result => {
        if (result === 'OK') {
        }
        this.loading = false;
        this.router.navigate(['/patient-catalogue']);
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });

  }
}
