import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../helpers/auth.guard';
import { Role, RoleRD } from '../models/role';
import { BloodOxygenChartComponent } from './blood-oxygen-chart/blood-oxygen-chart.component';
import { BloodPressureChartComponent } from './blood-pressure-chart/blood-pressure-chart.component';
import { GameResultTrendsComponent } from './game-result-trends/game-result-trends.component';
import { GamesReportComponent } from './games-report/games-report.component';
import { DensityComponent } from './density/density.component';
import { PredictionComponent } from './prediction/prediction.component';



const routes: Routes = [
  {
    path: 'games-report',
    component: GamesReportComponent,
    canActivate: [AuthGuard],
    data: {
      roles: RoleRD
    }
  },
  {
    path: 'game-result-trends',
    component: GameResultTrendsComponent,
    canActivate: [AuthGuard],
    data: {
      roles: RoleRD
    }
  },
  {
    path: 'prediction',
    component: PredictionComponent,
    canActivate: [AuthGuard],
    data: {
      roles: RoleRD
    }
  },
  {
    path: 'blood-pressure-chart',
    component: BloodPressureChartComponent,
    canActivate: [AuthGuard],
    data: {
      roles: RoleRD
    }
  },
  {
    path: 'blood-oxygen-chart',
    component: BloodOxygenChartComponent,
    canActivate: [AuthGuard],
    data: {
      roles: RoleRD
    }
  },
  {
    path: 'density',
    component: DensityComponent
  }

];

/**
 * Routing tábla a Statistics modulnak, mert lazy loadinggal töltjük be.
 */
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StatisticsRoutingModule { }
