<div class="modal-header">
    <h5 class="modal-title pull-left">{{ 'Core.UnauthorizedMessage' | translate:locale.language }}</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="DeletelastVisitedPage()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body col-sm-12">
    <h6>
      
    </h6>
 
    <hr>

    <div class="form-group">
        <button type="button" class="btn btn-primary mb-2"
            (click)="DeletelastVisitedPage()">OK
        </button>
    </div>
  </div>

  
