import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appFilter' })
export class FilterPipe implements PipeTransform {
  /**
   * Pipe filters the list of objects based on displayName property and the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */
  transform(items: any[], searchText: string, filteredCount: any): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    // filtering
    let filteredItems;
    if(searchText[0] >= '0' && searchText[0] <= '9'){
      //TAJ keresés
      filteredItems = items.filter(it => it.taj != null && it.taj.replace(' ','').includes(searchText));
    }else{
      //Név keresés
      filteredItems = items.filter(it => it.displayName.toLowerCase().includes(searchText));
    }
     filteredCount.count = filteredItems.length;
    return filteredItems;
  }
}