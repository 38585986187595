import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { RoleComponent } from './role/role.component';
import { AuthGuard } from './helpers/auth.guard';
import { Role, RoleAll, RoleSD, RoleSR, RoleSRCD, RoleSRD } from './models/role';
import { UserComponent } from './user/user.component';
import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegistrationComponent } from './registration/registration.component';
import { NotAuthorizedComponent } from './shared/not-authorized/not-authorized.component';
import { CodesComponent } from './codes/codes.component';
import { InfoPageComponent } from './info-page/info-page.component';
import { HcProviderComponent } from './hc-provider/hc-provider.component';
import { ThryveConnectionComponent } from './module-thryve/thryve-connection/thryve-connection.component';
import { ThryveDataComponent } from './module-thryve/thryve-data/thryve-data.component';
import { PatientProfileComponent } from './module-patient/patient-profile/patient-profile.component';
import { PatientHealthDataComponent } from './module-patient/patient-health-data/patient-health-data.component';
// import { HcProviderLinkComponent } from './hc-provider-link/hc-provider-link.component';
import { PatientAddictionsComponent } from './module-patient/patient-addictions/patient-addictions.component';
import { PatientDetailsComponent } from './module-patient/patient-details/patient-details.component';
import { HcProviderSelectComponent } from './hc-provider-select/hc-provider-select.component';

import { NewAdmissionComponent } from './module-clinic/new-admission/new-admission.component';
import { PatientCatalogueComponent } from './module-clinic/patient-catalogue/patient-catalogue.component';
import { PatientEditComponent } from './module-clinic/patient-edit/patient-edit.component';
import { DiagnosisComponent } from './module-clinic/diagnosis/diagnosis.component';

import { NotificationComponent } from './notification/notification.component';
import { NotificationSettingComponent } from './notification-setting/notification-setting.component';
import { MeasuredDataComponent } from './module-patient/measured-data/measured-data.component';
import { LabDataComponent } from './module-patient/lab-data/lab-data.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NavPreventerService } from './helpers/nav-preventer';
import { TermsComponent } from './terms/terms.component';
import { UnregisterComponent } from './unregister/unregister.component';
import { ThryveSpo2Component } from './module-thryve/thryve-spo2/thryve-spo2.component';
import { L10nResolver } from 'angular-l10n';
import { ThryveSleepChartComponent } from './statistics/thryve-sleep-chart/thryve-sleep-chart.component';
import { CancelTreatmentComponent } from './module-clinic/cancel-treatment/cancel-treatment.component';
import { PredemInfoPageComponent } from './predem-info-page/predem-info-page.component';
import { FacebookLoginComponent } from './Logins/facebook-login/facebook-login.component';
import { GoogleLoginComponent } from './Logins/google-login/google-login.component';
import { TaskSheetComponent } from './shared/task-sheet/task-sheet.component';
import { TestComponent } from './test/test.component';
import { NotesComponent } from './module-patient/notes/notes.component';
import { KpiSComponent } from './module-clinic/kpi-s/kpi-s.component';
import { ActualConditionComponent } from './module-clinic/actual-condition/actual-condition.component';
import { SettingsComponent } from './settings/settings.component';
import { MeasuresComponent } from './measures/measures.component';
import { BloodOxygenComponent } from './module-patient/blood-oxygen/blood-oxygen.component';
import { GdprComponent } from './shared/gdpr/gdpr.component';
import { TuseComponent } from './shared/tuse/tuse.component';
import { SubscriptionAdminComponent } from './subscription-admin/subscription-admin.component';
import { LoginComponent } from './Logins/login/login.component';
import { MyAwardsComponent } from './my-awards/my-awards.component';
import { AdsComponent } from './ads/ads.component';
import { PatientEditGpComponent } from './module-clinic/patient-edit-gp/patient-edit-gp.component';
// import { KnowledgeBaseComponent } from './module-kb/knowledge-base/knowledge-base.component';
// import { KnowledgeBaseViewComponent } from './module-kb/knowledge-base-view/knowledge-base-view.component';

const routes: Routes = [

  { path: 'test', component: TestComponent },

  { path: 'role', component: RoleComponent, canActivate: [AuthGuard], data: { roles: [Role.SysAdmin] } },
  { path: 'task-sheet', component: TaskSheetComponent, canActivate: [AuthGuard], data: { roles: RoleSD } },
  { path: 'predem-info-page', component: PredemInfoPageComponent },
  { path: 'terms/:onlyInfo', component: TermsComponent, canActivate: [AuthGuard], data: { roles: [Role.SysAdmin] } },
  { path: 'terms-info/:onlyInfo', component: TermsComponent },
  { path: 'shared/gdpr', component: GdprComponent },
  { path: 'shared/tuse', component: TuseComponent },
  { path: 'user', component: UserComponent, canActivate: [AuthGuard], data: { roles: [Role.SysAdmin, Role.PraxisAdmin] } },
  { path: 'subscription-admin', component: SubscriptionAdminComponent, canActivate: [AuthGuard], data: { roles: [Role.SysAdmin] } },
  { path: 'codes', component: CodesComponent, canActivate: [AuthGuard], data: { roles: [Role.SysAdmin] } },
  { path: 'ads', component: AdsComponent, canActivate: [AuthGuard], data: { roles: [Role.SysAdmin] } },
  { path: 'hc-provider', component: HcProviderComponent, canActivate: [AuthGuard], data: { roles: [Role.SysAdmin] } },
  //  { path: 'hc-provider-link', component: HcProviderLinkComponent, canActivate: [AuthGuard], data: { roles: [Role.SysAdmin,Role.RegisteredUser, Role.Doctor,Role.Assistant] } },
  { path: 'hc-provider-select', component: HcProviderSelectComponent, canDeactivate: [NavPreventerService], canActivate: [AuthGuard], data: { roles: RoleSRD } },
  //  { path: 'thryve-connection',loadChildren: () => import('./module-thryve/thryve.module').then(m => m.ThryveModule), canActivate: [AuthGuard], data: { roles: RoleSR } },
  //  { path: 'thryve-data', loadChildren: () => import('./module-thryve/thryve.module').then(m => m.ThryveModule), canActivate: [AuthGuard], data: { roles: RoleSR } },
  { path: 't-connection', component: ThryveConnectionComponent, canActivate: [AuthGuard], data: { roles: RoleSRD } },
  { path: 't-data', component: ThryveDataComponent, canActivate: [AuthGuard], data: { roles: RoleSR } },
  { path: 't-spo2', component: ThryveSpo2Component, canActivate: [AuthGuard], data: { roles: RoleSRD } },
  { path: 'sleep-chart/:qDate', component: ThryveSleepChartComponent, canActivate: [AuthGuard], data: { roles: RoleSRD } },
  { path: 'patient-profile/:init', component: PatientProfileComponent, canDeactivate: [NavPreventerService], canActivate: [AuthGuard], data: { roles: RoleAll } },
  { path: 'measured-data', component: MeasuredDataComponent, canActivate: [AuthGuard], data: { roles: RoleSRD } },
  { path: 'blood-oxygen', component: BloodOxygenComponent, canActivate: [AuthGuard], data: { roles: RoleSRD } },
  { path: 'cancel-treatment', component: CancelTreatmentComponent, canActivate: [AuthGuard], data: { roles: RoleSRD } },
  { path: 'lab-data', component: LabDataComponent, canActivate: [AuthGuard], data: { roles: RoleSRD } },
  { path: 'patient-known-diseases/:code/:subCode', component: PatientHealthDataComponent, canActivate: [AuthGuard], data: { roles: RoleSR } },
  { path: 'patient-memory-disorders/:code/:subCode', component: PatientHealthDataComponent, canActivate: [AuthGuard], data: { roles: RoleSR } },
  { path: 'memory-disorders/:code/:subCode', component: PatientDetailsComponent, canActivate: [AuthGuard], data: { roles: RoleSR } },
  { path: 'known-diseases/:code/:subCode', component: PatientDetailsComponent, canActivate: [AuthGuard], data: { roles: RoleSR } },
  { path: 'smoking-addiction/:code/:subCode', component: PatientDetailsComponent, canActivate: [AuthGuard], data: { roles: RoleSR } },
  { path: 'alcohol-addiction/:code/:subCode', component: PatientDetailsComponent, canActivate: [AuthGuard], data: { roles: RoleSR } },
  { path: 'coffee-addiction/:code/:subCode', component: PatientDetailsComponent, canActivate: [AuthGuard], data: { roles: RoleSR } },
  { path: 'drug-addiction/:code/:subCode', component: PatientDetailsComponent, canActivate: [AuthGuard], data: { roles: RoleSR } },
  { path: 'actual-condition', component: ActualConditionComponent, canActivate: [AuthGuard], data: { roles: RoleSRD } },

  { path: 'diagnosis', component: DiagnosisComponent, canActivate: [AuthGuard], data: { roles: RoleSD } },
  { path: 'new-admission', component: NewAdmissionComponent, canActivate: [AuthGuard], data: { roles: RoleSD } },
  { path: 'kpi-s/:needTrend', component: KpiSComponent, canActivate: [AuthGuard], data: { roles: RoleSD } },
  { path: 'kpi-s/:needTrend', component: KpiSComponent, canActivate: [AuthGuard], data: { roles: RoleSD } },
  { path: 'patient-edit/:patientId', component: PatientEditComponent, canActivate: [AuthGuard], data: { roles: RoleSD } },
  { path: 'patient-edit-gp/:patientId', component: PatientEditGpComponent, canActivate: [AuthGuard], data: { roles: RoleSD } },

  { path: 'login', component: LoginComponent },
  { path: 'facebook-login', component: FacebookLoginComponent },
  { path: 'google-login', component: GoogleLoginComponent },
  { path: 'confirm-registration', component: ConfirmRegistrationComponent },
  { path: 'info-page', component: InfoPageComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard], data: { roles: RoleAll } },
  { path: 'unregister', component: UnregisterComponent, canActivate: [AuthGuard], data: { roles: RoleAll } },
  { path: 'registration', component: RegistrationComponent },
  { path: 'not-authorized', component: NotAuthorizedComponent },
  { path: 'notification', component: NotificationComponent },
  { path: 'notification-setting', component: NotificationSettingComponent, canActivate: [AuthGuard], data: { roles: RoleAll } },
  { path: 'notes', component: NotesComponent, canActivate: [AuthGuard], data: { roles: RoleSR } },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], data: { roles: RoleAll } },
  { path: 'measures', component: MeasuresComponent, canActivate: [AuthGuard], data: { roles: RoleSRD } },
  { path: 'my-awards', component: MyAwardsComponent, canActivate: [AuthGuard], data: { roles: RoleSR } },

  {
    path: 'kb',
    loadChildren: () => import('./module-kb/module-kb.module').then(mod => mod.ModuleKbModule),
    resolve: { l10n: L10nResolver },
    data: {
      l10nProviders: [{ name: 'app', asset: './assets/i18n/app', options: { version: '9.0.0' } }]
    }
  },
  {
    path: 'games',
    loadChildren: () => import('./games/games.module').then(mod => mod.GamesModule),
    resolve: { l10n: L10nResolver },
    data: {
      l10nProviders: [{ name: 'app', asset: './assets/i18n/app', options: { version: '9.0.0' } }]
    }
  },
  {
    path: 'subscription',
    loadChildren: () => import('./module-subscription/subscription.module').then(mod => mod.SubscriptionModule),
    resolve: { l10n: L10nResolver },
    data: {
      l10nProviders: [{ name: 'app', asset: './assets/i18n/app', options: { version: '9.0.0' } }]
    }
  },
  {
    path: 'statistics',
    loadChildren: () => import('./statistics/statistics.module').then(mod => mod.StatisticsModule),
    resolve: { l10n: L10nResolver },
    data: {
      l10nProviders: [{ name: 'app', asset: './assets/i18n/app', options: { version: '9.0.0' } }]
    }
  },


  // {
  //   path: 'clinic',
  //   loadChildren: () => import('./module-clinic/clinic.module').then(mod => mod.ClinicModule),
  //   resolve: { l10n: L10nResolver },
  //   data: {
  //     l10nProviders: [{ name: 'app', asset: './assets/i18n/app', options: { version: '9.0.0' } }]
  //   }
  // },
  { path: '', component: HomeComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
