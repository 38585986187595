import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { L10N_LOCALE, L10nLocale } from 'angular-l10n';

@Component({
  selector: 'app-tuse',
  templateUrl: './tuse.component.html',
  styleUrls: ['./tuse.component.scss']
})
export class TuseComponent implements OnInit {

  infoPanel: SafeHtml;
  htmlString: string;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private http: HttpClient,
    private sanitizer: DomSanitizer

  ) { }

  ngOnInit(): void {
    this.getTuseContent();
  }

  getTuseContent() {
    let fileName = "tuse";
    fileName += '.' + this.locale.language + '.html';
    const path = 'assets/tuse/' + fileName;
    this.http.get(path, { responseType: 'text' }).subscribe(
      data => {
        this.htmlString = data;
        this.infoPanel = this.sanitizer.bypassSecurityTrustHtml(this.htmlString);
      });
  }
}
