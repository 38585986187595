<div class="container mt-3">
  <div class="m-3">
    <div class="d-flex align-items-center">
      <div class="general-icons-sm me-2">
        <img src="../../../assets/images/side-icon-prediction.svg">
      </div>
      <h1 class="display-6 m-0">{{ 'Project.Prediction' | translate:locale.language }}</h1>
    </div>
    <hr>
    <div class="d-lg-flex justify-content-between mb-3">
      <ul class="nav nav-pills rounded-selector mb-3 mb-lg-0">
        <li class="nav-item">
          <a [ngClass]="selectedOption.value == 60 ? 'nav-link active' : 'nav-link '"
             (click)="onOptionChanged(60,0)" aria-current="page" href="javascript:void(0)">
            <span>
              60 {{
                      'Core.Days' | translate:locale.language
              }}
            </span>
          </a>
        </li>
        <li class="nav-item">
          <a [ngClass]="selectedOption.value == 90 ? 'nav-link active position-relative' : 'nav-link position-relative'"
             (click)="onOptionChanged(90,1)" href="javascript:void(0)">
            <span>90 {{ 'Core.Days' | translate:locale.language }}</span>
          </a>
        </li>
        <li class="nav-item">
          <a [ngClass]="selectedOption.value == 180 ? 'nav-link active position-relative' : 'nav-link position-relative'"
             (click)="onOptionChanged(180,2)" href="javascript:void(0)">
            <span>180 {{ 'Core.Days' | translate:locale.language }}</span>
          </a>
        </li>
        <li class="nav-item">
          <a [ngClass]="selectedOption.value == 365 ? 'nav-link active position-relative' : 'nav-link position-relative'"
             (click)="onOptionChanged(365,3)" href="javascript:void(0)">
            <span>365 {{ 'Core.Days' | translate:locale.language }}</span>
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf="showChart" class="d-flex flex-column align-items-center">

      <dx-circular-gauge id="gauge" [value]="dataSource.avgResult"
                          [subvalues]="[dataSource.probabilityF, dataSource.probabilityG]">
        <dxo-subvalue-indicator type="triangleMarker" color="#779ECB" length="20" width="20" offset="5">
        </dxo-subvalue-indicator>
        <dxo-scale [startValue]="0" [endValue]="100" [tickInterval]="10">
          <dxo-label [customizeText]="customizeText"></dxo-label>
        </dxo-scale>
        <dxo-range-container>
          <dxi-range [startValue]="0" [endValue]="50" color="#228B22"></dxi-range>
          <dxi-range [startValue]="50" [endValue]="80" color="#FFD700"></dxi-range>
          <dxi-range [startValue]="80" [endValue]="100" color="#CE2029"></dxi-range>
        </dxo-range-container>
        <dxo-export [enabled]="false"></dxo-export>
        <dxo-title text="">
          <dxo-font [size]="40"></dxo-font>
        </dxo-title>
      </dx-circular-gauge>
      <div class="gauge-slider card bg-white mt-3">
        <div class="card-body d-flex justify-content-between align-items-center p-2">
        <div class="p-2">{{ 'Project.Unanimous' | translate:locale.language }}</div>
        <div class="progress flex-grow-1">
          <div class="progress-bar" role="progressbar" [style.width]="unanimous + '%'" [aria-valuenow]="unanimous" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="p-2">{{ 'Project.NonUnanimous' | translate:locale.language }}</div>
        </div>
      </div>

    </div>
    <div *ngIf="noData">
      <section class="content" [innerHTML]="infoPanel">
      </section>
    </div>
  </div>
</div>

<dx-load-panel #loadPanel shadingColor="rgba(0,0,0,0.4)" [position]="{ of: '#reg' }" [(visible)]="loading"
               [showIndicator]="true" [showPane]="true" [shading]="true" [hideOnOutsideClick]="false" (onShown)="onShown()">
</dx-load-panel>
