import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './Logins/login/login.component';
import { HomeComponent } from './home/home.component';

import { l10nConfig, initL10n, AppStorage, HttpTranslationLoader } from './l10n-config';
import { L10nTranslationModule, L10nIntlModule, L10nLoader } from 'angular-l10n';

import {
  DxMenuModule, DxSelectBoxModule, DxCheckBoxModule, DxValidatorModule,
  DxValidationSummaryModule, DxTextBoxModule,
  DxFormModule, DxLoadPanelModule, DxTreeViewModule, DxDropDownBoxModule, DxListModule,
  DxButtonModule, DxHtmlEditorModule, DxDateBoxModule
} from 'devextreme-angular';
import {
  DxDataGridModule,
  DxBulletModule,
  DxTemplateModule
} from 'devextreme-angular';

import {
  AccountFunc, UsersFunc, RolesFunc, HealthcareProviderFunc, PatientDataFunc, DiagnosisFunc, NotificationFunc, TermsFunc, UnregisterFunc
} from './services/webapiclient.service';
import { RoleComponent } from './role/role.component';
import { UserComponent } from './user/user.component';
import { ConfirmRegistrationComponent } from './confirm-registration/confirm-registration.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { RegistrationComponent } from './registration/registration.component';
import { BotDetectCaptchaModule } from 'angular-captcha';
import { LoggingService } from './services/logging.service';
import { NotAuthorizedComponent } from './shared/not-authorized/not-authorized.component';
import { CodesComponent } from './codes/codes.component';
import { CodesFunc } from './services/codes.service';
import { HcProviderComponent } from './hc-provider/hc-provider.component';
import { InfoPageComponent } from './info-page/info-page.component';
import { PatientModule } from './module-patient/patient.module';
import { GridCellDataPipe } from './shared/pipes/grid-cell-data';
import { HcProviderLinkComponent } from './hc-provider-link/hc-provider-link.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog-service';
import { HcProviderSelectComponent } from './hc-provider-select/hc-provider-select.component';
import { NotificationComponent } from './notification/notification.component';
import { NotificationSettingComponent } from './notification-setting/notification-setting.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { NavPreventerService } from './helpers/nav-preventer';
import { TermsComponent } from './terms/terms.component';
import { DatePipe } from '@angular/common';
import { UnregisterComponent } from './unregister/unregister.component';
import { ClinicModule } from './module-clinic/clinic.module';
import { appInitializer } from './helpers/app.initializer';
import { AuthenticationService } from './services/authentication.service';
import { SharedModule } from './shared/shared.module';
import { PredemInfoPageComponent } from './predem-info-page/predem-info-page.component';
import { FacebookLoginComponent } from './Logins/facebook-login/facebook-login.component';
import { GoogleLoginComponent } from './Logins/google-login/google-login.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { TestComponent } from './test/test.component';
import { SettingsComponent } from './settings/settings.component';
import { MeasuresComponent } from './measures/measures.component';
import { PackagesComponent } from './packages/packages.component';
import { SubscriptionAdminComponent } from './subscription-admin/subscription-admin.component';
import { RecaptchaModule, RECAPTCHA_LANGUAGE } from 'ng-recaptcha';
import { MyAwardsComponent } from './my-awards/my-awards.component';
import { AdsComponent } from './ads/ads.component';
import { AdsService } from './services/ads.service';

@NgModule({
  declarations: [
    AppComponent,
    ConfirmDialogComponent,
    LoginComponent,
    HomeComponent,
    RoleComponent,
    UserComponent,
    ConfirmRegistrationComponent,
    ResetPasswordComponent,
    RegistrationComponent,
    NotAuthorizedComponent,
    CodesComponent,
    HcProviderComponent,
    InfoPageComponent,
    GridCellDataPipe,
    HcProviderLinkComponent,
    ConfirmDialogComponent,
    HcProviderSelectComponent,
    NotificationComponent,
    NotificationSettingComponent,
    ChangePasswordComponent,
    TermsComponent,
    UnregisterComponent,
    PredemInfoPageComponent,
    FacebookLoginComponent,
    GoogleLoginComponent,
    TestComponent,
    SettingsComponent,
    PackagesComponent,
    MeasuresComponent,
    SubscriptionAdminComponent,
    MyAwardsComponent,
    AdsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    HttpClientModule,
    // import the Angular Captcha Module
    BotDetectCaptchaModule,
    ModalModule.forRoot(),
    ReactiveFormsModule,
    SocialLoginModule,
    L10nTranslationModule.forRoot(
      l10nConfig,
      {
        storage: AppStorage,
        translationLoader: HttpTranslationLoader
      }
    ),
    L10nIntlModule,
    DxMenuModule,
    DxSelectBoxModule,
    DxCheckBoxModule,
    DxDataGridModule,
    DxTemplateModule,
    DxBulletModule,
    DxValidatorModule,
    DxValidationSummaryModule,
    DxTextBoxModule,
    DxDropDownBoxModule,
    DxListModule,
    DxButtonModule,
    DxFormModule,
    DxDateBoxModule,
    DxLoadPanelModule,
    DxHtmlEditorModule,
    DxTreeViewModule,
    BrowserAnimationsModule,
    // ThryveModule,
    PatientModule,
    ClinicModule,
    SharedModule,
    RecaptchaModule
    // GamesModule,   // Nem kell, mert lazy loading van.
    // ModuleKbModule // Nem kell, mert lazy loading van.
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '880007076407-gn2616kci7qc6bjnbdlcus4de9hirb64.apps.googleusercontent.com'
            )
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(
              '556380688969614'
            )
          }

        ]
      } as SocialAuthServiceConfig,
    },  
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      deps: [AuthenticationService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initL10n,
      deps: [L10nLoader],
      multi: true
    },
    AccountFunc,
    DatePipe,
    UsersFunc,
    RolesFunc,
    LoggingService,
    UnregisterFunc,
    CodesFunc,
    TermsFunc,
    AdsService,
    HealthcareProviderFunc,
    PatientDataFunc,
    ConfirmDialogService,
    DiagnosisFunc,
    NotificationFunc,
    NavPreventerService
    // provider used to create fake backend
    // fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
