export const environment = {
  production: true,
  assetsVersion: '1.0.0',
  
  apiUrl: 'https://appAPI.predemplatform.com/',
  apiUrlKog: 'https://appAPI.kognitivtesztek.hu/',
  uploadUrl: 'https://appAPI.predemplatform.com/upload/',
  rootUrl: "https://app.predemplatform.com",
  homeUrlPre: "https://predemplatform.com",
  homeUrlKog: "https://kognitivtesztek.hu",
  captchaSiteKey: "6Le-kYkpAAAAACtXtz7RpEIMuyHesD6HbA69TZnH",
  captchaSicretKey: "6Le-kYkpAAAAAPUxEnV9VqMJothO_Ffv6KVH24kf",

  fbLoginUrl: 'https://www.facebook.com/v12.0/dialog/oauth?&response_type=token&display=popup&client_id=1268684670268405&display=popup&redirect_uri=https://predem.netis.hu/facebook-auth.html&scope=email'
};
