import { Component, OnInit, Inject, ViewChild, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';

import { formatMessage, loadMessages, locale } from 'devextreme/localization';
import messagesHu from 'devextreme/localization/messages/hu.json';
import messagesEs from 'node_modules/devextreme/localization/messages/es.json';


import { DxFormComponent, DxLoadPanelModule } from 'devextreme-angular';

import notify from 'devextreme/ui/notify';


import { AuthenticationService } from '../services/authentication.service';
import {
  User,
  UsersFunc
} from '../services/webapiclient.service';
import { Role } from '../models/role';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

import { CaptchaComponent } from 'angular-captcha';
import { environment } from 'src/environments/environment';
import { CaptchaService } from '../services/captcha.service';
import { Subscription } from 'rxjs';
import { BoolResult } from '../module-thryve/thryve.service';
import { SubscriptionService } from '../module-subscription/subscription-service';

/**
 * Regisztráció.
 * Sikeres regisztráció után mailt küldünk, amiben megtalálható
 * az aktiváló link.
 */

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  providers: [CaptchaService]
})
export class RegistrationComponent implements OnInit {
  loadingVisible = false;

  submitted = false;
  returnUrl: string;
  error = '';
  user: User = {} as User;

  @ViewChild(DxFormComponent, { static: false }) form: DxFormComponent
  password = "";
  passwordOptions: any = {
    mode: "password",
    value: this.password
  };

  userNamePattern = "^[a-zA-Z0-9@._-]+$";

  captcha: string;  // empty = not yet proven to be a human, anything else = human
  siteKey: string;

  @ViewChild('registrationConfirmtmpl', { static: true }) registrationConfirmtmpl: TemplateRef<any>;

  modalRef: BsModalRef;

  @ViewChild(CaptchaComponent, { static: true }) captchaComponent: CaptchaComponent;
  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private usersFunc: UsersFunc,
    private translation: L10nTranslationService,
    private modalService: BsModalService,
    private subscriptionService: SubscriptionService

  ) {
    this.captcha = '';
    this.siteKey = `${environment.captchaSiteKey}`;
    this.asyncNameValidation = this.asyncNameValidation.bind(this);

    const huMessages = JSON.stringify(messagesHu['hu']);
    const esMessages = JSON.stringify(messagesEs['es']);
    loadMessages({
      'hu': JSON.parse(huMessages),
      'es': JSON.parse(esMessages),
    });

  }
  buttonOptions: any = {
    text: this.translation.translate('Core.EditSave'),
    type: "default",
    useSubmitBehavior: true
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.setCaptchaLang(document.getElementById("recaptcha-container"), this.locale.language), 100);

  }

  asyncNameValidation = function (params) {
    let userName = params.value;
    let result: BoolResult = {} as BoolResult;
    return new Promise((resolve, reject) => {
      this.subscriptionService.isUserNameAvailable(userName).pipe(first()).subscribe(
        (p) => {
          result = p;
          params.rule.message = result.error;
        },
        (e) => {
          //this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message);
        },
        () => {
          resolve(result.result);
        }
      );
    });

  }


  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
    //console.log('resolved captcha with response: ' + this.captcha);
  }
  setCaptchaLang(recaptchaContainer, lang) {
    lang = lang || "hu";

    // 1. Search for the ReCaptcha iframe
    const iframeGoogleCaptcha = recaptchaContainer.querySelector('iframe');

    // 2. Retrieve the current language
    const currentLang = iframeGoogleCaptcha.getAttribute("src").match(/hl=(.*?)&/).pop();

    // 3. Verify if the language that you want to set is different to the current one
    if (currentLang !== lang) {
      // 4. If it is, change it
      iframeGoogleCaptcha.setAttribute(
        "src",
        iframeGoogleCaptcha.getAttribute("src").replace(
          /hl=(.*?)&/,
          'hl=' + lang + '&'
        )
      );
    }
  }


  onShown() {
    setTimeout(() => {
      this.loadingVisible = false;
    }, 3000);
  }

  onHidden() {
    //this.employeeInfo = this.employee;
  }

  passwordComparison = () => {
    return this.form.instance.option("formData").Password;
  };
  checkComparison() {
    return true;
  }

  ngOnInit() {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  closeModal() {
    this.modalRef.hide();
    this.router.navigate(['/login']);
  }
  onFormSubmit = function (e) {
    this.error = "";
    this.loadingVisible = true;
    this.user.role = Role.RegisteredUser;

    let result: any;
    return new Promise((resolve, reject) => {
      this.usersFunc.registration(JSON.stringify(this.user)).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.error = e;
          this.loading = false;
          reject(e);
        },
        () => {
          this.loadingVisible = false;
          if (result.succeeded) {
            //Sikeres regisztráció, erről informáljuk a felhasználót
            this.modalRef = this.modalService.show(this.registrationConfirmtmpl, { backdrop: 'static', keyboard: false });
            resolve("OK");
          }
          else {
            this.loading = false;
            this.error = this.translation.translate('Core.' + result.errors[0].code);

            resolve(this.translation.translate('Core.' + result.errors[0].code));
          }

        }
      );
    });
  }

}

