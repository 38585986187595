<div class="col-md-6 offset-md-3 mt-5">
    <div class="alert alert-info">
        {{ 'Core.ThanksConfirm' | translate:locale.language }}<br />
        {{ 'Core.EmailActivationChangePasswordInfo' | translate:locale.language }}
    </div>

    <div class="card">
        <h4 class="card-header">{{ 'Core.RegistrationConfirm' | translate:locale.language }}</h4>
        <div class="card-body">
            <div *ngIf="platinumUser" class="text-center">
                <span>{{ 'Core.AcceptPlatinumStatement' | translate:locale.language }} </span>
                <span>
                    <a href="javascript:void(0);" (click)="showStatement();">{{ 'Core.Statement' |
                        translate:locale.language }}</a>
                </span>
            </div>
            <hr>
            <form action="your-action" (submit)="onFormSubmit($event)">
                <dx-form id="form"
                    [formData]="changePasswordAfterActivationEditModel"
                     validationGroup="registerData">
        
                    <dxi-item dataField="NewPassword" [editorOptions]="{ mode: 'password' }">
                        <dxo-label
                            text="{{ 'Core.Entity_Password' | translate:locale.language }}">
                        </dxo-label>
                        <dxi-validation-rule 
                            type="required">
                        </dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" min="4" message="{{ 'Core.PasswordTooShort' | translate:locale.language }}"></dxi-validation-rule>
    
                    </dxi-item>
                    <dxi-item dataField="ConfirmPassword" [editorOptions]="{ mode: 'password' }">
                        <dxo-label
                        text="{{ 'Core.Entity_PasswordConfirm' | translate:locale.language }}">
                        </dxo-label>
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                        <dxi-validation-rule type="stringLength" min="4" message="{{ 'Core.PasswordTooShort' | translate:locale.language }}"></dxi-validation-rule>
                        <dxi-validation-rule type="compare" [comparisonTarget]="passwordComparison"
                            ></dxi-validation-rule>
                    </dxi-item>
                    <div *ngIf="platinumUser" class="text-center">
                        <span>{{ 'Core.AcceptStatement' | translate:locale.language }} </span>
                        <span>
                            <a href="javascript:void(0);" (click)="showStatement();">{{ 'Core.Statement' |
                                translate:locale.language }}</a>
                        </span>
                    </div>
               
                    <dxi-item 
                        itemType="button"
                        horizontalAlignment="left"
                        [buttonOptions]="buttonOptions">
                    </dxi-item>
          
                </dx-form>
              </form>
              <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error | translate:locale.language}}</div>
          </div>
    </div>
</div>

<ng-template #statementTmpl>
    <div class="modal-header">
        <h5 class="modal-title"> {{ 'Core.Statement' | translate:locale.language }} </h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <div class="p-3">
            <p class="pb-4">
                Elfogadom, hogy a PreDEM rendszer felhasználói adatbázisában tárolt alábbi személyes adataim átadásra
                kerüljenek az általam választott szolgáltató, mint adatkezelő részére.
                A továbbított adatok köre: név, e-mail cím, telefonszám, születési idő, születéskori nem, legmagasabb
                iskolai végzettség és családi kórtörténet. Az adattovábbítás célja: demencia és
                Parkinson-kór korai detektálásához kutatási adatok gyűjtése a folyamatos figyelmet, koncentrációt és
                feldolgozási sebességet mérő tesztek végrehajtásával.
            </p>

            <p>
                By clicking the Redirect button the Customer accepts PreDEM at the following personal data stored in its
                user database to the service provider of my choice as data controller.
                Provided data: full name, e-mail address, telephone number, date of birth, sex at birth, highest level
                of education and family medical history.
                The purpose of data transmission: to collect research data for the early detection of dementia and
                Parkinson's disease by performing tests measuring continuous attention, concentration and processing
                speed.
            </p>
        </div>
    </div>
</ng-template>

