<app-game-result-trends *ngIf="needTrend == '1'"></app-game-result-trends>
<div class="container mb-5">
    <div class="m-3">
        <div class="d-md-flex justify-content-between align-items-center">
            <h1 class="display-6 d-block">{{ 'Project.Kpis' | translate:locale.language }}</h1>
        </div>
        <hr>
    </div>
    <div class="row g-3">
        <div *ngIf="doctorDashboard" class="col-12 col-md-6 col-lg-4 d-flex flex-column">
            <div class="card mb-2 flex-fill">
                <div class="card-body">
                    <h3><span class="text-primary">{{doctorDashboard.sumPatient}}</span> {{ 'Core.Patient2' | translate:locale.language }}
                    </h3>
                    <div class="d-flex justify-content-between align-items-center">
                        <span>{{'Core.Activity' | translate:locale.language }}</span>
                        <div><span class="text-success">{{doctorDashboard.activePatient}}</span>/<span
                                class="text-primary">{{doctorDashboard.sumPatient}}</span>
                        </div>
                    </div>
                    <div class="progress bg-primary" style="height: 6px;">
                        <div class="progress-bar bg-success" role="progressbar"
                            [style.width.%]="activePatientPercent" [attr.aria-valuenow]="activePatientPercent"
                            aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>
            <div class="card mb-2 flex-fill">
                <div class="card-body">
                    <h3>{{'Project.Breakdown' | translate:locale.language }}</h3>
                    <dx-pie-chart id="pie" type="doughnut" [dataSource]="testTypes"
                        centerTemplate="centerTemplate" horizontalAlignment="center">
                        <dxi-series argumentField="type" horizontalAlignment="center">
                        </dxi-series>
                        <dxo-legend horizontalAlignment="center" verticalAlignment="bottom"
                            [customizeText]="customizeText"></dxo-legend>
                        <dxo-tooltip [enabled]="true">
                        </dxo-tooltip>
                        <!-- <dxo-size [height]="250" [width]="236">
                        </dxo-size> -->
                        <svg *dxTemplate="let pieChart of 'centerTemplate'">
                            <circle cx="100" cy="100" [attr.r]="pieChart.getInnerRadius() - 6" fill="#eee">
                            </circle>
                            <text text-anchor="middle" style="font-size: 42px" x="100" y="120" fill="#494949">
                                <tspan x="100" dy="-10px" style="font-weight: 700">{{totalTests}}</tspan>
                            </text>
                        </svg>

                    </dx-pie-chart>

                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-8 d-flex flex-column">
        <!-- TOP Játékok -->
            <div *ngIf="extendedDashboard" class="card mb-2 flex-fill">
                <div class="card-body">
                <h3>{{ 'Game.Games' | translate:locale.language }}</h3>
                    <div *ngFor="let game of extendedDashboard.gameList; index as i">
                        <div class="d-flex justify-content-between align-items-center">
                            <span>{{ 'Game.' + game.typeName | translate:locale.language }}</span>
                            <span>{{game.db}}</span>
                        </div>
                        <div class="progress mb-2" style="height: 6px;"> 
                            <div class="progress-bar" role="progressbar" [style.width.%]='game.percent' aria-valuenow="{{game.db}}" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row g-3">
        <div class="col-12 col-md-6 col-lg-4 d-flex flex-column">
            <!-- Kezelt neuro betegségek -->
            <div *ngIf="extendedDashboard && extendedDashboard.patientsByNeuro" class="card mb-2 flex-fill">
                <div class="card-body">
                <h3>{{ 'Project.PatientsByNeuro' | translate:locale.language }}</h3>
                    <div *ngFor="let type of extendedDashboard.patientsByNeuro; index as i">
                        <div class="d-flex justify-content-between align-items-center">
                            <span>{{ type.typeName }}</span>
                            <span>{{type.db}}</span>
                        </div>
                        <div class="progress mb-2" style="height: 6px;"> 
                            <div class="progress-bar" role="progressbar" [style.width.%]='type.percent' aria-valuenow="{{type.db}}" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- TOP Játékosok -->
            <div *ngIf="extendedDashboard && extendedDashboard.topGamers" class="card flex-fill">
                <div class="card-body d-flex flex-column">
                    <h3>{{ 'Project.TopGamer' | translate:locale.language }}</h3>
                    <div class="d-flex justify-content-around align-items-center flex-fill">
                        <div class="avatar-image-lg position-relative" [ngStyle]="{'background-image': 'url(' + blobUrl + ')'}" >
                            <h1 class="text-warning me-2 award"><i class="bi bi-award-fill"></i></h1>
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                            <h2 class="mb-0">{{extendedDashboard.topGamers[0].displayName}}</h2>
                            <span>{{extendedDashboard.topGamers[0].db}} {{ 'Game.Test2' | translate:locale.language }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-lg-8 d-flex flex-column">
            <!-- Tesztlapok megoszlása -->
            <div *ngIf="extendedDashboard && extendedDashboard.taskSheets" class="card flex-fill">
                <div class="card-body">
                    <h3>{{ 'Project.ClinicalTests' | translate:locale.language }}</h3>
                    <dx-pie-chart id="pie" type="doughnut" [dataSource]="extendedDashboard.taskSheets"
                        centerTemplate="centerTemplate" horizontalAlignment="center">
                        <dxi-series argumentField="typeName" valueField="db" horizontalAlignment="center">
                            <dxo-label [visible]="false">
                                <dxo-connector [visible]="true"></dxo-connector>
                            </dxo-label>
                        </dxi-series>
                        <dxo-legend [customizeText]="customizeTextClinic"></dxo-legend>
                        <dxo-tooltip [enabled]="true">
                        </dxo-tooltip>
                        <!-- <dxo-size [height]="250" [width]="236">
                        </dxo-size> -->
                        <svg *dxTemplate="let pieChart of 'centerTemplate'">
                            <circle cx="100" cy="100" [attr.r]="pieChart.getInnerRadius() - 6" fill="#eee"></circle>
                            <text text-anchor="middle" style="font-size: 42px" x="100" y="120" fill="#494949">
                                <tspan x="100" dy="-10px" style="font-weight: 700">{{totalTestSheets}}</tspan>
                            </text>
                        </svg>

                    </dx-pie-chart>

                </div>
            </div>
        </div>
    </div>
</div>