<div class="container" *ngIf="filteredDataSource">
    <div class="m-3">
        <div *ngIf="!doctorUser" class="d-flex align-items-center">
            <div  class="general-icons-sm  me-2">
                <img src="../../../assets/images/trend-watch-patient.svg">
            </div>
            <h1  class="display-6 m-0">{{ 'Project.TrendWatchPatient' | translate:locale.language}}</h1>
        </div>
        <div *ngIf="doctorUser" class="d-flex align-items-center">
            <div  class="general-icons-sm  me-2">
                <img src="../../../assets/images/trend-watch-doctor.svg">
            </div>
            <h1  class="display-6 m-0">{{ 'Project.TrendWatch' | translate:locale.language}}</h1>
        </div>
        <hr>
        <!-- Keresési panel         -->
        <div class="d-lg-flex justify-content-between mb-3">
            <ul class="nav nav-pills rounded-selector mb-3 mb-lg-0">
                <li class="nav-item">
                    <a [ngClass]="selectedOption.value == 30 ? 'nav-link active' : 'nav-link '"
                        (click)="onOptionChanged(30,0)" aria-current="page" href="javascript:void(0)"><span>30 {{
                            'Core.Days' | translate:locale.language
                            }}</span></a>
                </li>
                <li class="nav-item">
                    <a [ngClass]="selectedOption.value == 90 ? 'nav-link active position-relative' : 'nav-link position-relative'"
                        (click)="onOptionChanged(90,1)" href="javascript:void(0)">
                        <span>90 {{ 'Core.Days' | translate:locale.language }}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [ngClass]="selectedOption.value == 180 ? 'nav-link active position-relative' : 'nav-link position-relative'"
                        (click)="onOptionChanged(180,2)" href="javascript:void(0)">
                        <span>180 {{ 'Core.Days' | translate:locale.language }}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [ngClass]="selectedOption.value == 365 ? 'nav-link active position-relative' : 'nav-link position-relative'"
                        (click)="onOptionChanged(365,3)" href="javascript:void(0)">
                        <span>365 {{ 'Core.Days' | translate:locale.language }}</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a [ngClass]="selectedOption.value == 0 ? 'nav-link active position-relative' : 'nav-link position-relative'"
                        role="button" aria-expanded="false" aria-controls="customDate" (click)="onOptionChangedCustom()"
                        href="javascript:void(0)">
                        {{ 'Core.Custom' | translate:locale.language }}
                    </a>
                </li>
            </ul>
            <div class="d-flex">
                <ul class="nav nav-pills rounded-selector">
                    <li class="nav-item d-flex  align-items-center px-2">
                        <div class="me-1"> {{ 'Task.AllocatedTasks' | translate:locale.language }}</div>
                        <dx-check-box [(value)]="allocatedTasks"
                            (onValueChanged)="onTasksChanged($event)"></dx-check-box>
                    </li>
                </ul>
            </div>
        </div>
        <div *ngIf="customDate">
            <div class="d-md-flex justify-content-between align-items-top">
                <div class="me-3 mb-2">
                    <dx-date-box type="date" [stylingMode]="stylingMode" (onFocusOut)="startDateFocusOut($event)"
                        (onEnterKey)="startDateFocusOut($event)" (onInitialized)="initStartDate($event)"
                        [(value)]="startDate">
                        <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                            <dxi-validation-rule type="range" [max]="maxDate"></dxi-validation-rule>
                        </dx-validator>
                    </dx-date-box>
                </div>

                <div class="me-3 mb-2">
                    <dx-date-box type="date" [stylingMode]="stylingMode" (onFocusOut)="endDateFocusOut($event)"
                        (onEnterKey)="endDateFocusOut($event)" (onInitialized)="initEndDate($event)"
                        [(value)]="endDate">
                        <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                            <dxi-validation-rule type="range" [max]="maxDate"></dxi-validation-rule>
                            <dxi-validation-rule type="compare" [comparisonTarget]="checkComparison" comparisonType=">"
                                [message]="compareMessage" [reevaluate]="true"></dxi-validation-rule>
                        </dx-validator>
                    </dx-date-box>
                </div>

                <button type="button" class="btn btn-primary mb-2" (click)="onQuery()">
                    <i class="bi bi-search"></i> {{ 'Core.Query' | translate:locale.language }}
                </button>

            </div>
        </div>
    
    <!-- Páciens Eredmény táblázat -->
    <div *ngIf="!doctorUser">
        <div *ngFor="let item of filteredDataSource; index as i">
            <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-3 mb-3">
                <div class="col">
                    <div class="card text-white pointer position-relative h-100"
                        (click)="getDetails(item.patientId, 'SDMT')">
                        <div class="sdmt card-body d-flex justify-content-between p-1">
                            <div class="d-flex flex-column align-items-center justify-content-center w-50 py-2">
                                <img class="card-icon icon-h" src="../../../assets/images/sdmt/img/sdmt-mini-icon.svg">
                                <!-- <p class="card-title text-uppercase text-center">
                                    {{ 'Game.SDMTtest' | translate:locale.language }}
                                </p> -->
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <div class="score" [style.background-color]="bgColor[item.sdmtBg]">
                                    <h1 *ngIf="allocatedTasks" class="mb-0">{{item.sdmT_Allocated_Db}}/</h1>
                                    <h1 class="mb-0">{{item.sdmT_ActDb}} </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card text-white pointer position-relative h-100"
                        (click)="getDetails(item.patientId, 'LDST')">
                        <div class="ldst card-body d-flex justify-content-between p-1">
                            <div class="d-flex flex-column align-items-center justify-content-center w-50 py-2">
                                <img class="card-icon icon-h" src="../../../assets/images/sdmt/img/ldst-mini-icon.svg">
                                <!-- <p class="card-title text-uppercase text-center">
                                    {{ 'Game.LDSTtest' | translate:locale.language }}
                                </p> -->
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <div class="score" [style.background-color]="bgColor[item.ldstBg]">
                                    <h1 *ngIf="allocatedTasks" class="mb-0">{{item.ldsT_Allocated_Db}}/</h1>
                                    <h1 class="mb-0">{{item.ldsT_ActDb}} </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card text-white pointer position-relative h-100"
                        (click)="getDetails(item.patientId, 'DSST')">
                        <div class="dsst card-body d-flex justify-content-between p-1">
                            <div class="d-flex flex-column align-items-center justify-content-center w-50 py-2">
                                <img class="card-icon icon-h" src="../../../assets/images/dsst/img/dsst-mini-icon.svg">
                                <!-- <p class="card-title text-uppercase text-center">
                                    {{ 'Game.DSSTtest' | translate:locale.language }}
                                </p> -->
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <div class="score" [style.background-color]="bgColor[item.dsstBg]">
                                    <h1 *ngIf="allocatedTasks" class="mb-0">{{item.dssT_Allocated_Db}}/</h1>
                                    <h1 class="mb-0">{{item.dssT_ActDb}} </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card text-white pointer position-relative h-100"
                        (click)="getDetails(item.patientId, 'Classic')">
                        <div class="memory-classic card-body d-flex justify-content-between p-1">
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <strong class="card-title text-uppercase text-center">
                                    {{ 'Game.Classic' | translate:locale.language }}
                                </strong>
                                <img class="card-icon icon-h"
                                    src="../../../assets/images/memory/memory-classic-icon.svg">
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <div class="score" [style.background-color]="bgColor[item.memoryGameClassicBg]">
                                    <h1 *ngIf="allocatedTasks" class="mb-0">{{item.memoryGameClassic_Allocated_Db}}/
                                    </h1>
                                    <h1 class="mb-0">{{item.memoryGameClassic_ActDb}} </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card text-white pointer position-relative h-100"
                        (click)="getDetails(item.patientId, 'Semantic')">
                        <div class="memory-semantic card-body d-flex justify-content-between p-1">
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <strong class="card-title text-uppercase text-center">
                                    {{ 'Game.Semantic' | translate:locale.language }}
                                </strong>
                                <img class="card-icon icon-h"
                                    src="../../../assets/images/memory/memory-semantic-icon.svg">
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <div class="score" [style.background-color]="bgColor[item.memoryGameSemanticBg]">
                                    <h1 *ngIf="allocatedTasks" class="mb-0">{{item.memoryGameSemantic_Allocated_Db}}/
                                    </h1>
                                    <h1 class="mb-0">{{item.memoryGameSemantic_ActDb}} </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card text-white pointer position-relative h-100"
                        (click)="getDetails(item.patientId, 'Mathematical')">
                        <div class="memory-math card-body d-flex justify-content-between p-1">
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <strong class="card-title text-uppercase text-center">
                                    {{ 'Game.Mathematical' | translate:locale.language }}
                                </strong>
                                <img class="card-icon icon-h" src="../../../assets/images/memory/memory-math-icon.svg">
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <div class="score" [style.background-color]="bgColor[item.memoryGameMathematicalBg]">
                                    <h1 *ngIf="allocatedTasks" class="mb-0">
                                        {{item.memoryGameMathematical_Allocated_Db}}/</h1>
                                    <h1 class="mb-0">{{item.memoryGameMathematical_ActDb}} </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card text-white pointer position-relative h-100"
                        (click)="getDetails(item.patientId, 'ColorRecognition')">
                        <div class="color-recognition-bg card-body d-flex justify-content-between p-1">
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <strong class="card-title text-uppercase text-center">
                                    {{ 'Game.ColorRecognition' | translate:locale.language }}
                                </strong>
                                <img class="card-icon icon-h"
                                    src="{{ 'Assets.color-recognition-bg-icon' | translate:locale.language }}">
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <div class="score" [style.background-color]="bgColor[item.colorRecognitionBg]">
                                    <h1 *ngIf="allocatedTasks" class="mb-0">{{item.colorRecognition_Allocated_Db}}/</h1>
                                    <h1 class="mb-0">{{item.colorRecognition_ActDb}} </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card text-white pointer position-relative h-100"
                        (click)="getDetails(item.patientId, 'ShapeRecall')">
                        <div class="shape-recall card-body d-flex justify-content-between p-1">
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <strong class="card-title text-uppercase text-center">
                                    {{ 'Game.ShapeRecall' | translate:locale.language }}
                                </strong>
                                <img class="card-icon icon-h-sm"
                                    src="../../../assets/images/shape-recall/shape-recall-icon.svg">
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <div class="score" [style.background-color]="bgColor[item.shapeRecallBg]">
                                    <h1 *ngIf="allocatedTasks" class="mb-0">{{item.shapeRecall_Allocated_Db}}/</h1>
                                    <h1 class="mb-0">{{item.shapeRecall_ActDb}} </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card text-white pointer position-relative h-100"
                        (click)="getDetails(item.patientId, 'IcaTest')">
                        <div class="imci card-body d-flex justify-content-between p-1">
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <strong class="card-title text-uppercase text-center mb-0">
                                    {{ 'Game.IcaTest' | translate:locale.language }}</strong>
                                <span>{{ 'Game.ExperimentalGame' | translate:locale.language }}</span>
                                <img class="card-icon icon-h" src="../../../assets/images/ica/imci-mini-icon.svg">
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <div class="score" [style.background-color]="bgColor[item.icaTestBg]">
                                    <h1 *ngIf="allocatedTasks" class="mb-0">{{item.icaTest_Allocated_Db}}/</h1>
                                    <h1 class="mb-0">{{item.icaTest_ActDb}} </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card text-white pointer position-relative h-100"
                        (click)="getDetails(item.patientId, 'TmtTestA')">
                        <div class="tmt card-body d-flex justify-content-between p-1">
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <strong class="card-title text-uppercase text-center">
                                    {{ 'Game.TmtTestA' | translate:locale.language }}
                                </strong>
                                <img class="card-icon icon-h-sm" src="../../../assets/images/tmt-a-mini-icon.svg">
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <div class="score" [style.background-color]="bgColor[item.tmtTestABg]">
                                    <h1 *ngIf="allocatedTasks" class="mb-0">{{item.tmtTestA_Allocated_Db}}/</h1>
                                    <h1 class="mb-0">{{item.tmtTestA_ActDb}} </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card text-white pointer position-relative h-100"
                        (click)="getDetails(item.patientId, 'TmtTestB')">
                        <div class="tmt card-body d-flex justify-content-between p-1">
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <strong class="card-title text-uppercase text-center">
                                    {{ 'Game.TmtTestB' | translate:locale.language }}
                                </strong>
                                <img class="card-icon icon-h-sm" src="../../../assets/images/tmt-b-mini-icon.svg">
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <div class="score" [style.background-color]="bgColor[item.tmtTestBBg]">
                                    <h1 *ngIf="allocatedTasks" class="mb-0">{{item.tmtTestB_Allocated_Db}}/</h1>
                                    <h1 class="mb-0">{{item.tmtTestB_ActDb}} </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card text-white pointer position-relative h-100"
                        (click)="getDetails(item.patientId, 'WordGuess')">
                        <div class="word-guess card-body d-flex justify-content-between p-1">
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <strong class="card-title text-uppercase text-center text-dark">
                                    {{ 'Game.WordGuess' | translate:locale.language }}
                                </strong>
                                <img class="card-icon icon-h"
                                    src="../../../assets/images/word-guess/word-guess-icon.svg">
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <div class="score" [style.background-color]="bgColor[item.wordGuessBg]">
                                    <h1 *ngIf="allocatedTasks" class="mb-0">{{item.wordGuess_Allocated_Db}}/</h1>
                                    <h1 class="mb-0">{{item.wordGuess_ActDb}} </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card text-white pointer position-relative h-100"
                        (click)="getDetails(item.patientId, 'WordRecall')">
                        <div class="word-list-recall card-body d-flex justify-content-between p-1">
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <strong class="card-title text-uppercase text-center">
                                    {{ 'Game.WordRecall' | translate:locale.language }}
                                </strong>
                                <img class="card-icon icon-h-sm"
                                    src="../../../assets/images/word-list-recall/word-list-recall-icon.svg">
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <div class="score" [style.background-color]="bgColor[item.wordRecallBg]">
                                    <h1 *ngIf="allocatedTasks" class="mb-0">{{item.wordRecall_Allocated_Db}}/</h1>
                                    <h1 class="mb-0">{{item.wordRecall_ActDb}} </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card text-white pointer position-relative h-100"
                        (click)="getDetails(item.patientId, 'WordListRecallAc')">
                        <div class="word-list-recall-ac card-body d-flex justify-content-between p-1">
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <strong class="card-title text-uppercase text-center">
                                    {{ 'Game.WordRecallAc' | translate:locale.language }}
                                </strong>
                                <img class="card-icon icon-h-sm"
                                    src="../../../assets/images/word-list-recall/word-list-recall-ac-icon.svg">
                            </div>
                            <div class="d-flex flex-column align-items-center justify-content-center w-50">
                                <div class="score" [style.background-color]="bgColor[item.wordListRecallAcBg]">
                                    <h1 *ngIf="allocatedTasks" class="mb-0">{{item.wordListRecallAc_Allocated_Db}}/</h1>
                                    <h1 class="mb-0">{{item.wordListRecallAc_ActDb}} </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <!--
                <div class="col">
                    <div class="card pointer p-0 h-100" (click)="getDetails(item.patientId, 'sdmt')">
                        <div class="card-body text-center" [style.background-color]="bgColor[item.sdmtBg]">
                            <h5>{{ 'Game.SDMTtest' | translate:locale.language }}</h5>
                            <h3><span *ngIf="allocatedTasks">{{item.sdmT_Allocated_Db}} {{ 'Game.Db' |
                                    translate:locale.language }}/</span>
                                <span>{{item.sdmT_ActDb}} {{ 'Game.Db' | translate:locale.language }}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card pointer p-0 h-100" (click)="getDetails(item.patientId, 'ldst')">
                        <div class="card-body text-center" [style.background-color]="bgColor[item.ldstBg]">
                            <h5>{{ 'Game.LDSTtest' | translate:locale.language }}</h5>
                            <h3><span *ngIf="allocatedTasks">{{item.ldsT_Allocated_Db}} {{ 'Game.Db' |
                                    translate:locale.language }}/</span>
                                <span>{{item.ldsT_ActDb}} {{ 'Game.Db' | translate:locale.language }}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card pointer p-0 h-100" (click)="getDetails(item.patientId, 'dsst')">
                        <div class="card-body text-center" [style.background-color]="bgColor[item.dsstBg]">
                            <h5>{{ 'Game.DSSTtest' | translate:locale.language }}</h5>
                            <h3><span *ngIf="allocatedTasks">{{item.dssT_Allocated_Db}} {{ 'Game.Db' |
                                    translate:locale.language }}/</span>
                                <span>{{item.dssT_ActDb}} {{ 'Game.Db' | translate:locale.language }}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card pointer p-0 h-100" (click)="getDetails(item.patientId, 'classic')">
                        <div class="card-body text-center"
                            [style.background-color]="bgColor[item.memoryGameClassicBg]">
                            <h5>{{ 'Game.MemoryGameClassic' | translate:locale.language }}</h5>
                            <h3><span *ngIf="allocatedTasks">{{item.memoryGameClassic_Allocated_Db}} {{ 'Game.Db' |
                                    translate:locale.language }}/</span>
                                <span>{{item.memoryGameClassic_ActDb}} {{ 'Game.Db' | translate:locale.language
                                    }}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card pointer p-0 h-100" (click)="getDetails(item.patientId, 'semantic')">
                        <div class="card-body text-center"
                            [style.background-color]="bgColor[item.memoryGameSemanticBg]">
                            <h5>{{ 'Game.MemoryGameSemantic' | translate:locale.language }}</h5>
                            <h3><span *ngIf="allocatedTasks">{{item.memoryGameSemantic_Allocated_Db}} {{ 'Game.Db' |
                                    translate:locale.language }}/</span>
                                <span>{{item.memoryGameSemantic_ActDb}} {{ 'Game.Db' | translate:locale.language
                                    }}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card pointer p-0 h-100" (click)="getDetails(item.patientId, 'mathematical')">
                        <div class="card-body text-center"
                            [style.background-color]="bgColor[item.memoryGameMathematicalBg]">
                            <h5>{{ 'Game.MemoryGameMathematical' | translate:locale.language }}</h5>
                            <h3><span *ngIf="allocatedTasks">{{item.memoryGameMathematical_Allocated_Db}} {{
                                    'Game.Db' | translate:locale.language }}/</span>
                                <span>{{item.memoryGameMathematical_ActDb}} {{ 'Game.Db' | translate:locale.language
                                    }}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card pointer p-0 h-100" (click)="getDetails(item.patientId, 'colorRecognition')">
                        <div class="card-body text-center"
                            [style.background-color]="bgColor[item.colorRecognitionBg]">
                            <h5>{{ 'Game.ColorRecognition' | translate:locale.language }}</h5>
                            <h3><span *ngIf="allocatedTasks">{{item.colorRecognition_Allocated_Db}} {{ 'Game.Db' |
                                    translate:locale.language }}/</span>
                                <span>{{item.colorRecognition_ActDb}} {{ 'Game.Db' | translate:locale.language
                                    }}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card pointer p-0 h-100" (click)="getDetails(item.patientId, 'shapeRecall')">
                        <div class="card-body text-center" [style.background-color]="bgColor[item.shapeRecallBg]">
                            <h5>{{ 'Game.ShapeRecall' | translate:locale.language }}</h5>
                            <h3><span *ngIf="allocatedTasks">{{item.shapeRecall_Allocated_Db}} {{ 'Game.Db' |
                                    translate:locale.language }}/</span>
                                <span>{{item.shapeRecall_ActDb}} {{ 'Game.Db' | translate:locale.language }}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card pointer p-0 h-100" (click)="getDetails(item.patientId, 'icaTest')">
                        <div class="card-body text-center" [style.background-color]="bgColor[item.icaTestBg]">
                            <h5>{{ 'Game.IcaTest' | translate:locale.language }}</h5>
                            <h3><span *ngIf="allocatedTasks">{{item.icaTest_Allocated_Db}} {{ 'Game.Db' |
                                    translate:locale.language }}/</span>
                                <span>{{item.icaTest_ActDb}} {{ 'Game.Db' | translate:locale.language }}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card pointer p-0 h-100" (click)="getDetails(item.patientId, 'tmtTestA')">
                        <div class="card-body text-center" [style.background-color]="bgColor[item.tmtTestABg]">
                            <h5>{{ 'Game.TmtTestA' | translate:locale.language }}</h5>
                            <h3><span *ngIf="allocatedTasks">{{item.tmtTestA_Allocated_Db}} {{ 'Game.Db' |
                                    translate:locale.language }}/</span>
                                <span>{{item.tmtTestA_ActDb}} {{ 'Game.Db' | translate:locale.language }}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card pointer p-0 h-100" (click)="getDetails(item.patientId, 'tmtTestB')">
                        <div class="card-body text-center" [style.background-color]="bgColor[item.tmtTestBBg]">
                            <h5>{{ 'Game.TmtTestB' | translate:locale.language }}</h5>
                            <h3><span *ngIf="allocatedTasks">{{item.tmtTestB_Allocated_Db}} {{ 'Game.Db' |
                                    translate:locale.language }}/</span>
                                <span>{{item.tmtTestB_ActDb}} {{ 'Game.Db' | translate:locale.language }}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card pointer p-0 h-100" (click)="getDetails(item.patientId, 'wordGuess')">
                        <div class="card-body text-center" [style.background-color]="bgColor[item.wordGuessBg]">
                            <h5>{{ 'Game.WordGuess' | translate:locale.language }}</h5>
                            <h3><span *ngIf="allocatedTasks">{{item.wordGuess_Allocated_Db}} {{ 'Game.Db' |
                                    translate:locale.language }}/</span>
                                <span>{{item.wordGuess_ActDb}} {{ 'Game.Db' | translate:locale.language }}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card pointer p-0 h-100" (click)="getDetails(item.patientId, 'wordRecall')">
                        <div class="card-body text-center" [style.background-color]="bgColor[item.wordRecallBg]">
                            <h5>{{ 'Game.WordRecall' | translate:locale.language }}</h5>
                            <h3><span *ngIf="allocatedTasks">{{item.wordRecall_Allocated_Db}} {{ 'Game.Db' |
                                    translate:locale.language }}/</span>
                                <span>{{item.wordRecall_ActDb}} {{ 'Game.Db' | translate:locale.language }}</span>
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card pointer p-0 h-100" (click)="getDetails(item.patientId, 'wordListRecallAc')">
                        <div class="card-body text-center"
                            [style.background-color]="bgColor[item.wordListRecallAcBg]">
                            <h5>{{ 'Game.WordListRecallAc' | translate:locale.language }}</h5>
                            <h3><span *ngIf="allocatedTasks">{{item.wordListRecallAc_Allocated_Db}} {{ 'Game.Db' |
                                    translate:locale.language }}/</span>
                                <span>{{item.wordListRecallAc_ActDb}} {{ 'Game.Db' | translate:locale.language
                                    }}</span>
                            </h3>
                        </div>
                    </div>
                </div>
            -->
            </div>
        </div>
        <br>
    </div>
    <!-- Orvos Eredmény táblázat -->
    <div *ngIf="doctorUser" class="overflow mb-3">
        <table class="table text-center bg-white mb-0">
            <thead>
                <tr class="border-0 align-top">
                    <th class="border-thick-right text-start" rowspan="2" style="min-width: 8em;">{{ 'Core.Name' |
                        translate:locale.language }}</th>
                    <th class="border-end pb-0" colspan="3">{{ 'Game.SDMT' | translate:locale.language }} {{
                        'Game.TypeGames' | translate:locale.language }}</th>
                    <th class="border-end pb-0" colspan="3">{{ 'Game.MemoryGames' | translate:locale.language }}</th>
                    <th class="border-end" rowspan="2">{{ 'Game.ColorRecognition' | translate:locale.language }}</th>
                    <th class="border-end" rowspan="2">{{ 'Game.ShapeRecall' | translate:locale.language }}</th>
                    <th class="border-end" rowspan="2">{{ 'Game.IcaTest' | translate:locale.language }}</th>
                    <th class="border-end" rowspan="2">{{ 'Game.TmtTestA' | translate:locale.language }}</th>
                    <th class="border-end" rowspan="2">{{ 'Game.TmtTestB' | translate:locale.language }}</th>
                    <th class="border-end" rowspan="2">{{ 'Game.WordGuess' | translate:locale.language }}</th>
                    <th class="border-end" rowspan="2">{{ 'Game.WordRecall' | translate:locale.language }}</th>
                    <th rowspan="2">{{ 'Game.WordListRecallAc' | translate:locale.language }}</th>

                </tr>
                <tr class="border-0 align-top">
                    <th class="pt-0">{{ 'Game.SDMTtest' | translate:locale.language }}</th>
                    <th class="pt-0">{{ 'Game.DSSTtest' | translate:locale.language }}</th>
                    <th class="border-end pt-0">{{ 'Game.LDSTtest' | translate:locale.language }}</th>
                    <th class="pt-0">{{ 'Game.Classic' | translate:locale.language }}</th>
                    <th class="pt-0">{{ 'Game.Semantic' | translate:locale.language }}</th>
                    <th class="border-end pt-0">{{ 'Game.Mathematical' | translate:locale.language }}</th>

                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of filteredDataSource; index as i">
                    <td class="pointer border-thick-right text-start" (click)="goVirtualPatient(item.patientId)">{{item.displayName}}</td>
                    <td [style.background-color]="bgColor[item.sdmtBg]" class="pointer"
                        (click)="getDetails(item.patientId, 'SDMT')">
                        <span *ngIf="allocatedTasks">{{item.sdmT_Allocated_Db}}/</span>{{item.sdmT_ActDb}}
                    </td>
                    <td [style.background-color]="bgColor[item.dsstBg]" class="pointer"
                        (click)="getDetails(item.patientId, 'DSST')">
                        <span *ngIf="allocatedTasks">{{item.dssT_Allocated_Db}}/</span>{{item.dssT_ActDb}}
                    </td>
                    <td [style.background-color]="bgColor[item.ldstBg]" class="pointer"
                        (click)="getDetails(item.patientId, 'LDST')">
                        <span *ngIf="allocatedTasks">{{item.ldsT_Allocated_Db}}/</span>{{item.ldsT_ActDb}}
                    </td>
                    <td [style.background-color]="bgColor[item.memoryGameClassicBg]" class="pointer"
                        (click)="getDetails(item.patientId, 'Classic')">
                        <span
                            *ngIf="allocatedTasks">{{item.memoryGameClassic_Allocated_Db}}/</span>{{item.memoryGameClassic_ActDb}}
                    </td>
                    <td [style.background-color]="bgColor[item.memoryGameSemanticBg]" class="pointer"
                        (click)="getDetails(item.patientId, 'Semantic')">
                        <span
                            *ngIf="allocatedTasks">{{item.memoryGameSemantic_Allocated_Db}}/</span>{{item.memoryGameSemantic_ActDb}}
                    </td>
                    <td [style.background-color]="bgColor[item.memoryGameMathematicalBg]" class="pointer"
                        (click)="getDetails(item.patientId, 'Mathematical')">
                        <span
                            *ngIf="allocatedTasks">{{item.memoryGameMathematical_Allocated_Db}}/</span>{{item.memoryGameMathematical_ActDb}}
                    </td>
                    <td [style.background-color]="bgColor[item.colorRecognitionBg]" class="pointer"
                        (click)="getDetails(item.patientId, 'ColorRecognition')">
                        <span
                            *ngIf="allocatedTasks">{{item.colorRecognition_Allocated_Db}}/</span>{{item.colorRecognition_ActDb}}
                    </td>
                    <td [style.background-color]="bgColor[item.shapeRecallBg]" class="pointer"
                        (click)="getDetails(item.patientId, 'ShapeRecall')">
                        <span *ngIf="allocatedTasks">{{item.shapeRecall_Allocated_Db}}/</span>{{item.shapeRecall_ActDb}}
                    </td>
                    <td [style.background-color]="bgColor[item.icaTestBg]" class="pointer"
                        (click)="getDetails(item.patientId, 'IcaTest')">
                        <span *ngIf="allocatedTasks">{{item.icaTest_Allocated_Db}}/</span>{{item.icaTest_ActDb}}
                    </td>
                    <td [style.background-color]="bgColor[item.tmtTestABg]" class="pointer"
                        (click)="getDetails(item.patientId, 'TmtTestA')">
                        <span *ngIf="allocatedTasks">{{item.tmtTestA_Allocated_Db}}/</span>{{item.tmtTestA_ActDb}}
                    </td>
                    <td [style.background-color]="bgColor[item.tmtTestBBg]" class="pointer"
                        (click)="getDetails(item.patientId, 'TmtTestB')">
                        <span *ngIf="allocatedTasks">{{item.tmtTestB_Allocated_Db}}/</span>{{item.tmtTestB_ActDb}}
                    </td>
                    <td [style.background-color]="bgColor[item.wordGuessBg]" class="pointer"
                        (click)="getDetails(item.patientId, 'WordGuess')">
                        <span *ngIf="allocatedTasks">{{item.wordGuess_Allocated_Db}}/</span>{{item.wordGuess_ActDb}}
                    </td>
                    <td [style.background-color]="bgColor[item.wordRecallBg]" class="pointer"
                        (click)="getDetails(item.patientId, 'WordRecall')">
                        <span *ngIf="allocatedTasks">{{item.wordRecall_Allocated_Db}}/</span>{{item.wordRecall_ActDb}}
                    </td>
                    <td [style.background-color]="bgColor[item.wordListRecallAcBg]" class="pointer"
                        (click)="getDetails(item.patientId, 'WordListRecallAc')">
                        <span
                            *ngIf="allocatedTasks">{{item.wordListRecallAc_Allocated_Db}}/</span>{{item.wordListRecallAc_ActDb}}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <!-- Színek magyarázata -->
    <h3>{{ 'Game.ColorLegend' | translate:locale.language }}</h3>
    <div class="d-flex mb-3">
        <div style="width:3%; background-color: gainsboro;" class="me-2"></div>
        <div style="width:97%;">{{ 'Game.TrendGray' | translate:locale.language }}</div>
    </div>
    <div class="d-flex mb-3">
        <div style="width:3%; background-color: white;" class="me-2"></div>
        <div style="width:97%;">{{ 'Game.TrendWhite' | translate:locale.language }}</div>
    </div>
    <div class="d-flex mb-3">
        <div style="width:3%; background-color: #FAAAA0" class="me-2"></div>
        <div style="width:97%;">{{ 'Game.TrendRed' | translate:locale.language }}
        </div>
    </div>
    <div class="d-flex mb-3">
        <div style="width:3%; background-color: #CAFAA0" class="me-2"></div>
        <div style="width:97%;">{{ 'Game.TrendGreen' | translate:locale.language }}
        </div>
    </div>
</div>
</div>
<!-- <div class="container" *ngIf="!filteredDataSource">
    <div class="m-3">
        <div class="d-md-flex justify-content-between align-items-center ">
            <h1 *ngIf="doctorUser" class="display-6 d-block">{{ 'Project.TrendWatch' | translate:locale.language }}</h1>
            <h1 *ngIf="!doctorUser" class="display-6 d-block">{{ 'Project.TrendWatchPatient' | translate:locale.language
                }}</h1>
        </div>
        <hr>

        {{ 'Project.NoData' | translate:locale.language }}
    </div>
</div> -->
<!-- Eredmény részletek -->
<ng-template #detailsTemplate>
    <div *ngIf="detailSource" class="modal-header">
        <h5 class="modal-title" id="NewMeasurementLabel">{{ 'Core.Result' | translate:locale.language }} - {{ 'Game.' + selectedGameType | translate:locale.language }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="closeModal()" aria-label="Close">
        </button>
    </div>
    <div class="modal-body">
        <table class="table table-bordered text-center bg-white">
            <thead>
                <tr>
                    <th>{{ 'Core.Date' | translate:locale.language }}</th>
                    <th>{{ 'Core.Result' | translate:locale.language }}</th>
                    <th>{{ 'Task.AllocatedTasks' | translate:locale.language }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of detailSource; index as i">
                    <td>{{item.startTime | date: dateTimeFormat}}</td>
                    <td>{{item.resultNumeric }}</td>
                    <td>
                        <h3 class="text-primary m-0"><i *ngIf="item.taskId > 0" class="bi bi-check"></i></h3>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</ng-template>