import { Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { loadMessages } from 'devextreme/localization';
import { ErrorService } from '../services/error.service';
import { TermsAndConditions, TermsFunc } from '../services/webapiclient.service';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { locale } from 'devextreme/localization';
import { first } from 'rxjs/operators';
import { TermsService } from '../services/terms.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import notify from 'devextreme/ui/notify';
import { ActivatedRoute } from '@angular/router';
import { CorrectDate } from '../shared/utils';
import { Subscription } from 'rxjs';

/**
 * EULA karbantartása. (admin szerepkörrel)
 * Felhasználók számára csak megtekintés (onlyInfo=YES paraméterrel)
 */


@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit, OnDestroy {

  @ViewChild('newTerm', { static: true }) newTerm: TemplateRef<any>;
  @ViewChild('termInfo', { static: true }) termInfo: TemplateRef<any>;

  modalRef: BsModalRef;
  termsAndConditions: TermsAndConditions[] = {} as TermsAndConditions[];
  error: string;
  dataSource: any;
  editItem: TermsAndConditions;
  addMode: boolean = false;
  dateFormat: string;
  stylingMode = "filled";
  onlyInfo: string;
  validFromDateInstance: any;
  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private termsService: TermsService,
    private errorService: ErrorService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private translation: L10nTranslationService
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

  }

  ngOnInit(): void {

    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });
    this.onlyInfo = this.route.snapshot.paramMap.get('onlyInfo');
    this.loadDataSource();
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  loadDataSource() {
    this.termsService.getAll().then(
      result => {
        this.dataSource = <TermsAndConditions[]>result;
        //csak megtekintéshez
        if (this.onlyInfo == "YES") this.editItem = this.dataSource[this.dataSource.length - 1];
      }
    ).catch(err => {
      this.error = err;
    });

  }

  //Megtekintés
  clickInfo(i) {
    this.editItem = this.dataSource[i];
    this.addMode = false;
    this.modalRef = this.modalService.show(this.termInfo, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  clickNew() {
    let last: any = this.dataSource[this.dataSource.length - 1];
    this.editItem = <TermsAndConditions>{};
    this.editItem.acceptNeed = true;
    this.editItem.validFrom = new Date();
    this.editItem.validFrom.setDate(this.editItem.validFrom.getDate() + 1);
    if (last) {
      this.editItem = last;
    }
    this.addMode = true;
    this.modalRef = this.modalService.show(this.newTerm, { class: 'modal-lg', backdrop: 'static', keyboard: false });
  }

  closeModal() {
    this.modalRef.hide();
    if (this.addMode) {
      //Új bevitelt szakítunk meg
      this.addMode = false;
    }
  }
  simpleClone(obj: any) {
    return Object.assign({}, obj);
  }

  dateFocusOut(s) {
    CorrectDate(this.validFromDateInstance);
  }

  initDate(e) {
    this.validFromDateInstance = e.component;
  }


  onFormSubmit = function (e) {
    this.error = "";
    this.loading = true;
    //Ha csak dátumot kérünk be a devextreme date-box-nál, akkor 0 órát ad vissza
    //hozzá kell adni 1 órát, hogy ne az előző napra konvertája a JSON.stringify
    if (this.editItem.validFrom) {
      this.editItem.validFrom = new Date(this.editItem.validFrom);
      this.editItem.validFrom.setHours(6);
    }

    //új
    this.termsService.insert(JSON.stringify(this.editItem)).then(
      result => {
        if (result == "OK") {
          notify({
            message: "Sikeres mentés!",
            position: {
              my: "center top",
              at: "center top"
            }
          }, "success", 3000);
          this.addMode = false;
          this.closeModal();
          this.loadDataSource();
        } else {
          this.error = result;
          this.loading = false;
        }
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });

    e.preventDefault();
  }

}
