<dx-data-grid id="gridContainer"
    [dataSource]="dataSource"
    [remoteOperations]="false"
    [rowAlternationEnabled]="true"
    [columnHidingEnabled]="true"
    [showBorders]="true"
    > 
    <dxo-editing 
    mode="form"
    [allowUpdating]="true"
    [allowDeleting]="true"
    [allowAdding]="true"
    [useIcons]="true">
   </dxo-editing>
       <dxo-filter-row 
            [visible]="true">
       </dxo-filter-row>

    <!-- <dxi-column dataField="id" dataType="string"></dxi-column> -->
    <dxi-column dataField="name" caption= "{{ 'Project.Provider' | translate:locale.language }}" dataType="string">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="postalCode" caption= "{{ 'Project.PostalCode' | translate:locale.language }}" dataType="string">
        <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxi-validation-rule 
                    type="pattern"
                    [pattern]="postalCodePattern">
                </dxi-validation-rule>

    </dxi-column>
    <dxi-column dataField="city" caption= "{{ 'Project.City' | translate:locale.language }}" dataType="string">
        <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxi-validation-rule 
                    type="pattern"
                    [pattern]="cityPattern">
                </dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="address" caption= "{{ 'Project.Address' | translate:locale.language }}" dataType="string">
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="email" caption= "{{ 'Core.Account_Register_Email' | translate:locale.language }}" dataType="string" cellTemplate="mailTemplate">
        <dxi-validation-rule type="required"></dxi-validation-rule>
                        <dxi-validation-rule
                             type="email">
                        </dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="phone" caption= "{{ 'Core.Entity_ContactPhone' | translate:locale.language }}" dataType="string">
        <dxi-validation-rule type="required"></dxi-validation-rule>
               <dxi-validation-rule 
                    type="pattern"
                    [pattern]="phonePattern">
                </dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="needTermin" caption= "{{ 'Project.NeedTermin' | translate:locale.language }}" dataType="boolean">
    </dxi-column>
    <dxi-column dataField="needReferral" caption= "{{ 'Project.NeedReferral' | translate:locale.language }}" dataType="boolean">
    </dxi-column>
    <dxi-column dataField="webUrl" caption= "{{ 'Project.WebUrl' | translate:locale.language }}" dataType="string" cellTemplate="linkTemplate">
        <dxi-validation-rule type="required"></dxi-validation-rule>
               <dxi-validation-rule 
                    type="pattern"
                    [pattern]="urlPattern">
                </dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="mapLink" caption= "{{ 'Project.MapLink' | translate:locale.language }}" dataType="string" cellTemplate="mapTemplate">
        <dxi-validation-rule type="required"></dxi-validation-rule>
               <dxi-validation-rule 
                    type="pattern"
                    [pattern]="urlPattern">
                </dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="openingHours" caption= "{{ 'Project.OpeningHours' | translate:locale.language }}" dataType="string" cellTemplate="memoTemplate">
            <dxo-form-item
                [colSpan]="1"
                editorType="dxTextArea"
                [editorOptions]="{ height: 100 }">
            </dxo-form-item>
        <dxi-validation-rule type="required"></dxi-validation-rule>
    </dxi-column>
    <dxi-column dataField="remark" caption= "{{ 'Core.EntityNote' | translate:locale.language }}" dataType="string" cellTemplate="memoTemplate">
            <dxo-form-item
                [colSpan]="1"
                editorType="dxTextArea"
                [editorOptions]="{ height: 100 }">
            </dxo-form-item>
    </dxi-column>

    <div *dxTemplate="let d of 'mapTemplate'">
        <a href="{{d.value}}" target="_blank"> {{ 'Project.View' | translate:locale.language }}</a>
     </div>
     <div *dxTemplate="let d of 'linkTemplate'">
        <a href="{{d.value}}" target="_blank">{{d.value}}</a>
     </div>
     <div *dxTemplate="let d of 'memoTemplate'">
        <div class="form-group preserve-textarea-format" >
            <p class="form-value">{{d.value}}</p>
        </div>
     </div>
     <div *dxTemplate="let d of 'mailTemplate'">
        <a href="mailto:{{d.value}}"> {{d.value}}</a>
     </div>

</dx-data-grid>
 <!-- <hr>
 <h3>Térkép</h3>
 <div #map style="width:100%;height:400px"></div> -->

