import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import messagesHu from 'node_modules/devextreme/localization/messages/hu.json';
import { loadMessages, locale } from 'devextreme/localization';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DenyTreatmentModel, Doctor, LoginUser } from 'src/app/services/webapiclient.service';
//import { ConfirmDialogService } from 'src/app/confirm-dialog/confirm-dialog-service';
import { Subscription } from 'rxjs';
import { PatientService } from 'src/app/module-patient/patient.service';
import { Router } from '@angular/router';
import { HcProviderService } from 'src/app/services/hc-provider.service';

@Component({
  selector: 'app-deny-treatment',
  templateUrl: './deny-treatment.component.html',
  styleUrls: ['./deny-treatment.component.scss']
})
export class DenyTreatmentComponent implements OnInit, OnDestroy {
  
  //A componenst a patient-catalogue-ból hívjuk, és küldünk neki egy esemény, ha be kell zárnia a modalt
  @Output() closeModalEvent = new EventEmitter<boolean>();

  @Input() patientId: string;

  stylingMode = "filled"
  currentUser: LoginUser;
  error: any;
  dateFormat: string;
  mailContent: string;
  loading = false;
  editItem: any;
  doctorSource: Doctor[];
  selectedDoctor: string;
  selectedDoctorName: string;

  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private hcProviderService: HcProviderService,
    private patientService: PatientService,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

  }


  ngOnInit(): void {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
        this.dateFormat = this.translation.translate('Core.DateFormat');
      });

    this.currentUser = this.authenticationService.currentUserValue;
    this.mailContent = this.translation.translate('Project.MailContentWithoutDoctor');
    this.loadDoctorSource(1);
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  onDoctorChanged(e){
    this.selectedDoctorName = this.doctorSource.find(f=>f.id == e.value).userName;
    if(this.selectedDoctorName) this.mailContent = this.translation.translate('Project.MailContentWithDoctor').replace("#doctor#", this.selectedDoctorName);
  }
  loadDoctorSource(providerId) {
    this.hcProviderService.getDoctorsByProvider(providerId).then(
      result => {
        this.doctorSource = <Doctor[]>result;
      }
    ).catch(err => {
      this.error = err;
    });
  }
  
  onShown() {
    setTimeout(() => {
      this.loading = false;
    }, 15000);
  }

  closeModal() {
    this.closeModalEvent.emit(true);
  }

  save() {
    if(this.mailContent === undefined || this.mailContent === "") return;
    this.loading = true;
    this.editItem = <DenyTreatmentModel>{};
    this.editItem.doctorId = this.currentUser.id;
    this.editItem.suggestedDoctorId = this.selectedDoctor;
    this.editItem.patientId = this.patientId;
    this.editItem.reason = this.mailContent;

    this.patientService.denyTreatment(JSON.stringify(this.editItem)).then(
      result => {
        if (result === 'OK') {
        }
        this.loading = false;
        this.closeModalEvent.emit(true);
        //this.router.navigate(['/patient-catalogue']);
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });

  }
}
