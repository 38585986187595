import { Injectable } from '@angular/core';

const referenceRages: any[] = [
    {
    Type: 'MMSE',
    Ranges: [
        {
          ApplicationReference: 'NoDisease',
          Min: 30,
          Max: 30,
          WarningReference: ''
        },
        {
          ApplicationReference: 'MildCognitiveImpairment',
          Min: 26,
          Max: 29,
          WarningReference: ''
        },
        {
          ApplicationReference: 'MildDementia',
          Min: 20,
          Max: 25,
          WarningReference: ''
        },
        {
          ApplicationReference: 'ModerateDementia',
          Min: 10,
          Max: 19,
          WarningReference: ''
        },
        {
          ApplicationReference: 'SeriousDementia',
          Min: 0,
          Max: 9,
          WarningReference: ''
        }
      ]
    },
    {
    Type: 'MOCA-A',
    Ranges: [
      {
        ApplicationReference: 'NoDisease',
        Min: 30,
        Max: 30,
        WarningReference: ''
      },
      {
        ApplicationReference: 'MildCognitiveImpairment',
        Min: 26,
        Max: 29,
        WarningReference: ''
      },
      {
        ApplicationReference: 'MildDementia',
        Min: 20,
        Max: 25,
        WarningReference: ''
      },
      {
        ApplicationReference: 'ModerateDementia',
        Min: 10,
        Max: 19,
        WarningReference: ''
      },
      {
        ApplicationReference: 'SeriousDementia',
        Min: 0,
        Max: 9,
        WarningReference: ''
      }
      ]
    },
    {
    Type: 'ADAS-Cog',
    Ranges: [
      {
        ApplicationReference: 'Normal',
        Min: 0,
        Max: 8,
        WarningReference: ''
      },
      {
        ApplicationReference: 'Mild',
        Min: 9,
        Max: 15,
        WarningReference: ''
      },
      {
        ApplicationReference: 'Serious',
        Min: 16,
        Max: 70,
        WarningReference: ''
      }
      ]
    },
    {
    Type: 'BICAM_1',
    Ranges:  [
      {
        ApplicationReference: 'VeryGood',
        Min: 81,
        Max: 100,
        WarningReference: ''
      },
      {
        ApplicationReference: 'Good',
        Min: 71,
        Max: 80,
        WarningReference: ''
      },
      {
        ApplicationReference: 'AboveAverage',
        Min: 61,
        Max: 70,
        WarningReference: ''
      },
      {
        ApplicationReference: 'Average',
        Min: 40,
        Max: 60,
        WarningReference: ''
      },
      {
        ApplicationReference: 'BelowAverage',
        Min: 30,
        Max: 39,
        WarningReference: ''
      },
      {
        ApplicationReference: 'Low',
        Min: 20,
        Max: 29,
        WarningReference: ''
      },
      {
        ApplicationReference: 'VeryLow',
        Min: 0,
        Max: 19,
        WarningReference: ''
      }
    ]
    },
    {
    Type: 'BICAM_2',
    Ranges:  [
      {
        ApplicationReference: 'Normal',
        Min: 40,
        Max: 100,
        WarningReference: ''
      },
      {
        ApplicationReference: 'SlightDamage',
        Min: 30,
        Max: 39,
        WarningReference: ''
      },
      {
        ApplicationReference: 'ModerateDamage',
        Min: 20,
        Max: 29,
        WarningReference: ''
      },
      {
        ApplicationReference: 'SeriousDamage',
        Min: 0,
        Max: 19,
        WarningReference: ''
      }
    ]
    }
  ];


@Injectable()
export class ReferenceService {

  getResultCategory(type: string, result: number): string {
    const refType = referenceRages.find(f => f.Type === type);
    if (!refType) { return '?'; }
    const refRange = refType.Ranges.find(f => f.Min <= result && f.Max >= result);
    if (!refRange) { return '?'; }
    return refRange.ApplicationReference;
  }
}
