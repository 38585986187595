import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { L10nLocale, L10nTranslationService, L10N_LOCALE } from 'angular-l10n';
import { DxFormComponent } from 'devextreme-angular';
import { loadMessages, locale } from 'devextreme/localization';
import messagesHu from 'devextreme/localization/messages/hu.json';
import notify from 'devextreme/ui/notify';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CodesFunc, CodeWarehouseEditModel } from 'src/app/services/codes.service';

import { LoginUser, PatientDataViewModel } from 'src/app/services/webapiclient.service';
import { PatientService } from 'src/app/module-patient/patient.service';
import {
  User,
  UsersFunc
} from 'src/app/services/webapiclient.service';
import { Role } from 'src/app/models/role';
import { CheckTAJ, CorrectDate } from '../../shared/utils';
import { Subscription } from 'rxjs';
import { BoolResult } from 'src/app/module-thryve/thryve.service';
import { SubscriptionService } from 'src/app/module-subscription/subscription-service';

/**
 * Új beteg felvétele a klinikai modulban az orvos által.
 * Regisztráljuk az új beteget, és a profil adatát is létrehozzuk
 * Regisztrációt aktiválhatja a user (megkapja mail-en), de nem fontos
 */

@Component({
  selector: 'app-new-admission',
  templateUrl: './new-admission.component.html',
  styleUrls: ['./new-admission.component.scss']
})
export class NewAdmissionComponent implements OnInit, OnDestroy {

  //A componenst a patient-catalogue-ból hívjuk, és küldünk neki egy esemény, ha be kell zárnia a modalt
  @Output() closeModalEvent = new EventEmitter<boolean>();

  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  user: User = {} as User;
  stylingMode = "filled";
  userNamePattern = "^[a-zA-Z0-9@._-]+$";

  birthDateInstance: any;
  now: Date = new Date();
  min: Date = new Date(1920, 0, 1);

  patientDataViewModel: PatientDataViewModel = {} as PatientDataViewModel;
  sexCodes: CodeWarehouseEditModel[] = {} as CodeWarehouseEditModel[];
  educationCodes: CodeWarehouseEditModel[] = {} as CodeWarehouseEditModel[];
  familyHistoryCodes: CodeWarehouseEditModel[] = {} as CodeWarehouseEditModel[];

  currentUser: LoginUser;

  private translSubs$: Subscription;


  @ViewChild(DxFormComponent, { static: false }) form: DxFormComponent
  password = "";
  passwordOptions: any = {
    mode: "password",
    value: this.password
  };

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private route: ActivatedRoute,
    private translation: L10nTranslationService,
    private authenticationService: AuthenticationService,
    private patientService: PatientService,
    private subscriptionService: SubscriptionService,
    private usersFunc: UsersFunc,
    private codesFunc: CodesFunc,
    private router: Router

  ) {
    const huMessages = JSON.stringify(messagesHu['hu']);

    this.asyncNameValidation = this.asyncNameValidation.bind(this);

    loadMessages({
      'hu': JSON.parse(huMessages)
    });

    codesFunc.getCodesData('Sex').then(
      result => {
        this.sexCodes = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });

    codesFunc.getCodesData('HighestEducation').then(
      result => {
        this.educationCodes = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });

    codesFunc.getCodesData('ClinicalFamilyHistory').then(
      result => {
        this.familyHistoryCodes = <CodeWarehouseEditModel[]>result;
      }
    ).catch(err => {
      this.error = err;
    });

  }

  buttonOptions: any = {
    text: this.translation.translate('Core.EditSave'),
    type: "success",
    useSubmitBehavior: true
  }
  ngOnInit(): void {
    // Feliratkozás a nyelv változtatására.
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      //this.getPatientData(this.currentUser.id);
    }
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }
  
  asyncNameValidation = function (params) {
    let userName = params.value;
    let result: BoolResult = {} as BoolResult;
    return new Promise((resolve, reject) => {
      this.subscriptionService.isUserNameAvailable(userName).pipe(first()).subscribe(
        (p) => {
          result = p;
          params.rule.message = result.error;
        },
        (e) => {
          //this.errorService.changeErrorMessage(e.message + ' - ' + e.error.ExceptionMessage);
          reject(e.message);
        },
        () => {
          resolve(result.result);
        }
      );
    });

  }

  birthDateFocusOut(s) {
    CorrectDate(this.birthDateInstance);
  }

  initBirthDate(e) {
    this.birthDateInstance = e.component;
  }

  closeModal() {
    this.closeModalEvent.emit(true);
  }

  onFormSubmit = function (e) {
    this.loading = true;
    this.error = "";
    this.user.role = Role.RegisteredUser;
    this.user.displayName = this.patientDataViewModel.displayName;
    this.user.email = this.patientDataViewModel.email;
    this.user.userName = this.patientDataViewModel.userName;
    this.user.providerId = this.currentUser.providerId;
    this.user.doctorId = this.currentUser.id;

    let result: any;
    e.preventDefault();
    let pr = new Promise((resolve, reject) => {
      this.usersFunc.platinumRegistration(JSON.stringify(this.user)).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.error = e.message;
          this.loading = false;
          reject(e);
        },
        () => {
          //this.loading = false;
          if (result.succeeded) {
            //regisztráció után a profil adatokat is elmentjük
            this.savePatientData();
            resolve("OK");
          }
          else {
            this.loading = false;
            this.error = this.translation.translate('Core.' + result.errors[0].code);

            resolve(this.translation.translate('Core.' + result.errors[0].code));
          }

        }
      );
    });

  }

  savePatientData = function (e) {
    this.loading = true;
    this.patientDataViewModel.patientDataId = null;
    this.patientDataViewModel.doctorId = this.currentUser.id;

    //let result: any;
    this.patientService.upsertPatient(JSON.stringify(this.patientDataViewModel)).then(
      result => {
        if (result == "OK") {
          notify({
            message: "Sikeres mentés!",
            position: {
              my: "center top",
              at: "center top"
            }
          }, "success", 3000);

          this.loading = false;
          //ez zárja be a modalt
          this.closeModalEvent.emit(true);
          //document.getElementById('closeNewAdmissionModal').click();
          this.router.navigate(['/home']);
        } else {
          this.error = result;
          this.loading = false;
        }
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });

  }

  getPatientData(id: string) {
    this.loading = true;
    this.patientService.getPatient(id).then(
      result => {
        this.patientDataViewModel = <PatientDataViewModel>result;
        this.loading = false;
      }
    ).catch(err => {
      this.error = err;
      this.loading = false;
    });
  }

  validateTaj(e) {
    return CheckTAJ(e.value) == 0;
  }
  onShown() {
    // setTimeout(() => {
    //     this.loading = false;
    // }, 15000);
  }

  onHidden() {
    //this.employeeInfo = this.employee;
  }
}
