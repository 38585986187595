export enum Role {
   RegisteredUser = 'RegisteredUser',
   GoldUser = 'GoldUser',
   PlatinumUser = 'PlatinumUser',
   SysAdmin = 'SysAdmin',
   PraxisAdmin = 'PraxisAdmin',
   Doctor = 'Doctor',
   ContentEditor = 'ContentEditor',
   USER_WITH_PENDING_REGISTRATION = 'USER WITH PENDING REGISTRATION',
   GP = 'GP'
}
//Éles üzemhez majd ez kell!!
export let RoleU: Role[] = [Role.RegisteredUser, Role.GoldUser, Role.PlatinumUser];
export let RoleSRC: Role[] = [Role.SysAdmin, Role.RegisteredUser, Role.ContentEditor, Role.GoldUser, Role.PlatinumUser];
export let RoleSR: Role[] = [Role.SysAdmin, Role.RegisteredUser, Role.GoldUser, Role.PlatinumUser];
export let RoleSRD: Role[] = [Role.SysAdmin, Role.RegisteredUser, Role.Doctor, Role.GoldUser, Role.PlatinumUser];
export let RoleSD: Role[] = [Role.SysAdmin, Role.Doctor, Role.GP];
export let RoleRD: Role[] = [Role.RegisteredUser, Role.Doctor, Role.GoldUser, Role.PlatinumUser, Role.GP];
export let RoleSRCD: Role[] = [Role.SysAdmin, Role.RegisteredUser, Role.ContentEditor, Role.Doctor, Role.GoldUser, Role.PlatinumUser];
export let RoleAll: Role[] = [Role.SysAdmin, Role.RegisteredUser, Role.Doctor, Role.PraxisAdmin, Role.ContentEditor, Role.GoldUser, Role.PlatinumUser, Role.GP];

export let RolePU: Role[] = [Role.PlatinumUser];
export let RoleGU: Role[] = [Role.GoldUser,Role.PlatinumUser];
export let RoleGUD: Role[] = [Role.GoldUser,Role.PlatinumUser,Role.Doctor, Role.GP];
export let RolePUD: Role[] = [Role.PlatinumUser, Role.Doctor, Role.GP];

//Amíg nem élesítünk, ezt kell kipublikálni
// 
// export let RoleU: Role[] = [Role.RegisteredUser, Role.GoldUser, Role.PlatinumUser];
// export let RoleSRC: Role[] = [Role.SysAdmin, Role.RegisteredUser, Role.ContentEditor, Role.GoldUser, Role.PlatinumUser];
// export let RoleSR: Role[] = [Role.SysAdmin, Role.RegisteredUser, Role.GoldUser, Role.PlatinumUser];
// export let RoleSRD: Role[] = [Role.SysAdmin, Role.RegisteredUser, Role.Doctor, Role.GoldUser, Role.PlatinumUser];
// export let RoleSD: Role[] = [Role.SysAdmin, Role.Doctor];
// export let RoleRD: Role[] = [Role.RegisteredUser, Role.Doctor, Role.GoldUser, Role.PlatinumUser];
// export let RoleSRCD: Role[] = [Role.SysAdmin, Role.RegisteredUser, Role.ContentEditor, Role.Doctor, Role.GoldUser, Role.PlatinumUser];
// export let RoleAll: Role[] = [Role.SysAdmin, Role.RegisteredUser, Role.Doctor, Role.PraxisAdmin, Role.ContentEditor, Role.GoldUser, Role.PlatinumUser];

// export let RolePU: Role[] = [Role.RegisteredUser, Role.GoldUser, Role.PlatinumUser];
// export let RoleGU: Role[] = [Role.RegisteredUser, Role.GoldUser,Role.PlatinumUser];
// export let RolePUD: Role[] = [Role.RegisteredUser,Role.PlatinumUser, Role.Doctor];
// export let RoleGUD: Role[] = [Role.RegisteredUser,Role.GoldUser,Role.PlatinumUser,Role.Doctor];

