import { Component, OnInit, Inject, ViewChild, OnDestroy, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { L10N_LOCALE, L10nLocale, L10nTranslationService } from 'angular-l10n';

import { formatMessage, loadMessages, locale } from 'devextreme/localization';
import messagesHu from 'devextreme/localization/messages/hu.json';


import { DxFormComponent } from 'devextreme-angular';

import notify from 'devextreme/ui/notify';


import { AuthenticationService } from '../services/authentication.service';
import {
  ChangePasswordAfterActivationEditModel,
  AccountFunc
} from '../services/webapiclient.service';
import { Subscription } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

/**
  * Regisztráció megerősítés.
  * A levélben elküldött link alapján éri el a felhasználó
  * itt tudja beállítani a kezdeti jelszavát is
  */

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.scss']
})

export class ConfirmRegistrationComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  changePasswordAfterActivationEditModel: ChangePasswordAfterActivationEditModel = {} as ChangePasswordAfterActivationEditModel;

  userId: string;
  code: string;

  platinumUser: boolean = false;

  @ViewChild(DxFormComponent, { static: false }) form: DxFormComponent
  @ViewChild('statementTmpl', { static: true }) statementTmpl: TemplateRef<any>;

  modalRef: BsModalRef;

  password = "";
  passwordOptions: any = {
    mode: "password",
    value: this.password
  };
  private translSubs$: Subscription;

  constructor(
    @Inject(L10N_LOCALE) public locale: L10nLocale,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private accountFunc: AccountFunc,
    private translation: L10nTranslationService

  ) {
    // redirect to home if already logged in
    // if (this.authenticationService.currentUserValue) {
    //    this.router.navigate(['/']);
    // }
    const huMessages = JSON.stringify(messagesHu['hu']);
    loadMessages({
      'hu': JSON.parse(huMessages)
    });

  }
  buttonOptions: any = {
    text: this.translation.translate('Core.RegistrationConfirm'),
    type: "success",
    useSubmitBehavior: true
  }
  passwordComparison = () => {
    return this.form.instance.option("formData").NewPassword;
  };
  checkComparison() {
    return true;
  }

  ngOnInit() {
    this.translSubs$ = this.translation.onChange().subscribe(
      x => {
        locale(x.language);
      });


    //url paraméterből kiolvassuk a felhasználó kódját és a megerősítő kódot
    this.route.queryParams
      .subscribe(params => {
        this.code = params.code;
        this.userId = params.userId;
        this.accountFunc.isInRole(this.userId,'PlatinumUser').subscribe((res) => this.platinumUser = res.result);
      }
      );

    // this.userId = this.route.snapshot.paramMap.get('userId');
    // this.code = this.route.snapshot.paramMap.get('code');

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }
  ngOnDestroy() {
    if (this.translSubs$) {
      this.translSubs$.unsubscribe();
    }
  }

  showStatement() {
    this.modalRef = this.modalService.show(this.statementTmpl, { backdrop: 'static', keyboard: false });
  }
  
  closeModal() {
    this.modalRef.hide();
  }
  
  onFormSubmit = function (e) {
    this.loading = true;
    this.changePasswordAfterActivationEditModel.Code = this.code;
    this.changePasswordAfterActivationEditModel.Id = this.userId;

    let result: any;
    return new Promise((resolve, reject) => {
      this.accountFunc.activationChangePassword(JSON.stringify(this.changePasswordAfterActivationEditModel)).pipe(first()).subscribe(
        (p) => {
          result = p;
        },
        (e) => {
          this.error = e.message;
          this.loading = false;
          reject(e);
        },
        () => {
          if (result.body == "OK") {
            notify({
              message: "Sikeres aktiválás!",
              position: {
                my: "center top",
                at: "center top"
              }
            }, "success", 3000);

            this.router.navigate(['/login']);
            resolve("OK");
          } else {
            this.error = result.body;
            this.loading = false;
            resolve(result.body);
            //reject(this.translation.translate('Core.' + result.errors[0].code));
          }
        }
      );
    });

  }

  // convenience getter for easy access to form fields
  //get f() { return this.loginForm.controls; }


}
